"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RevenueClauseTypeToConfigType = exports.RevenueClauseType = void 0;
const api_models_1 = require("@portal/api-models");
var RevenueClauseType;
(function (RevenueClauseType) {
    RevenueClauseType["CostPerCreditCardApproved"] = "cost_per_credit_card/approved";
    RevenueClauseType["CostPerCreditCardFunded"] = "cost_per_credit_card/funded";
    RevenueClauseType["CostPerCreditCardOpened"] = "cost_per_credit_card/opened";
    RevenueClauseType["CostPerFundedLoan"] = "cost_per_loan/funded";
    RevenueClauseType["CostPerLoanApproved"] = "cost_per_loan/approved";
    RevenueClauseType["CostPerLoanFundedBySupplySubAccountId"] = "cost_per_loan_funded_by_supply_sub_account_id";
    RevenueClauseType["CostPerLoanLenderQualifiedLead"] = "cost_per_loan/lender_qualified_lead";
    RevenueClauseType["CostPerOtherConversion"] = "cost_per_other/conversion";
    RevenueClauseType["CostPerSavingsClick"] = "cost_per_savings_click";
    RevenueClauseType["CostPerSavingsFunded"] = "cost_per_savings/funded";
    RevenueClauseType["CostPerSavingsOpened"] = "cost_per_savings/opened";
    RevenueClauseType["CostPerSavingsOpenedBySupplySubAccountId"] = "cost_per_savings_opened_by_supply_sub_account_id";
    RevenueClauseType["CostPerUniqueLoanClick15"] = "cost_per_unique_loan_click_15";
    RevenueClauseType["CostPerUniqueOtherClickBySubAccountId"] = "cost_per_unique_other_click_by_sub_account_id";
    RevenueClauseType["CostPerUniqueSavingsClick"] = "cost_per_unique_savings_click";
    RevenueClauseType["CostPerUniqueSavingsClickByOfferId"] = "cost_per_unique_savings_click_by_offer_id";
    RevenueClauseType["CostPerUniqueSpecialOfferClick"] = "cost_per_unique_special_offer_click";
    RevenueClauseType["CostPerUniqueSpecialOfferClickWithDynamicPayout"] = "cost_per_unique_special_offer_click_with_dynamic_payout";
    RevenueClauseType["LifeInsurancePolicyPayments"] = "life_insurance_policy_payments";
    RevenueClauseType["OtherRevenue"] = "other_revenue";
    RevenueClauseType["PercentFundedLoanAmountByFundedLoanAmountTier"] = "percent_funded_loan_amount_by_funded_loan_amount_tier";
    RevenueClauseType["PercentFundedLoanAmountWithThresholdMarginalPercentage"] = "percent_funded_loan_amount_with_threshold_marginal_percentage";
    RevenueClauseType["PercentGrossFundedLoanAmount"] = "percent_gross_funded_loan_amount";
    RevenueClauseType["PercentGrossFundedLoanAmountBySupply"] = "percent_gross_funded_loan_amount_by_supply";
    RevenueClauseType["PercentGrossFundedLoanAmountFlatFeeThreshold"] = "percent_gross_funded_loan_amount_flat_fee_threshold";
    RevenueClauseType["PercentOriginationFee"] = "percent_origination_fee";
    RevenueClauseType["PercentOtherConversionAmount"] = "percent_other_conversion_amount";
    RevenueClauseType["SavingsOfferClickPayout"] = "savings_offer_click_payout";
})(RevenueClauseType = exports.RevenueClauseType || (exports.RevenueClauseType = {}));
exports.RevenueClauseTypeToConfigType = {
    [RevenueClauseType.CostPerCreditCardApproved]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.CostPerCreditCardFunded]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.CostPerCreditCardOpened]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.CostPerFundedLoan]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.CostPerLoanApproved]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.CostPerLoanFundedBySupplySubAccountId]: api_models_1.DemandPlatformFeeConfigType.SupplySubAccount,
    [RevenueClauseType.CostPerLoanLenderQualifiedLead]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.CostPerOtherConversion]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.CostPerSavingsClick]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.CostPerSavingsFunded]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.CostPerSavingsOpened]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.CostPerSavingsOpenedBySupplySubAccountId]: api_models_1.DemandPlatformFeeConfigType.SupplySubAccount,
    [RevenueClauseType.CostPerUniqueLoanClick15]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.CostPerUniqueOtherClickBySubAccountId]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.CostPerUniqueSavingsClick]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.CostPerUniqueSavingsClickByOfferId]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.CostPerUniqueSpecialOfferClick]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.CostPerUniqueSpecialOfferClickWithDynamicPayout]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.LifeInsurancePolicyPayments]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.OtherRevenue]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.PercentFundedLoanAmountByFundedLoanAmountTier]: api_models_1.DemandPlatformFeeConfigType.Tiered,
    [RevenueClauseType.PercentFundedLoanAmountWithThresholdMarginalPercentage]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.PercentGrossFundedLoanAmount]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.PercentGrossFundedLoanAmountBySupply]: api_models_1.DemandPlatformFeeConfigType.SupplySubAccount,
    [RevenueClauseType.PercentGrossFundedLoanAmountFlatFeeThreshold]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.PercentOriginationFee]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.PercentOtherConversionAmount]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
    [RevenueClauseType.SavingsOfferClickPayout]: api_models_1.DemandPlatformFeeConfigType.PercentageOf,
};
