"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserRolesByType = exports.getUserAccessRoles = exports.getUserPartnerRoles = exports.getUserTypeRole = exports.isUserAccessRole = exports.isUserPartnerRole = exports.isUserTypeRole = exports.isNonInternalUserTypeRole = void 0;
const arrays_1 = require("../roles/arrays");
const isNonInternalUserTypeRole = (role) => Boolean(role) && arrays_1.UserTypeNonInternalRolesArray.includes(role);
exports.isNonInternalUserTypeRole = isNonInternalUserTypeRole;
const isUserTypeRole = (role) => arrays_1.UserTypeRolesArray.includes(role);
exports.isUserTypeRole = isUserTypeRole;
const isUserPartnerRole = (role) => arrays_1.UserPartnerRolesArray.includes(role);
exports.isUserPartnerRole = isUserPartnerRole;
const isUserAccessRole = (role) => arrays_1.UserAccessRolesArray.includes(role);
exports.isUserAccessRole = isUserAccessRole;
const getUserTypeRole = (roles) => roles.find(exports.isUserTypeRole);
exports.getUserTypeRole = getUserTypeRole;
const getUserPartnerRoles = (roles) => roles.filter(exports.isUserPartnerRole);
exports.getUserPartnerRoles = getUserPartnerRoles;
const getUserAccessRoles = (roles) => roles.filter(exports.isUserAccessRole);
exports.getUserAccessRoles = getUserAccessRoles;
const getUserRolesByType = (roles) => ({
    accessRoles: (0, exports.getUserAccessRoles)(roles),
    partnerRoles: (0, exports.getUserPartnerRoles)(roles),
    typeRole: (0, exports.getUserTypeRole)(roles),
});
exports.getUserRolesByType = getUserRolesByType;
