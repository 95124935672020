import { HYDRATE } from 'next-redux-wrapper';
import type { Reducer } from 'redux';

import type { HydrateAction } from '@/store/actions';
import { reconcileState, replaceById } from '@/store/utils';

import { BillingCycleActionType } from './types';
import type { BillingCycleAction, BillingCycleState } from './types';

export const initialState: BillingCycleState = {
  createDrawerVisible: false,
  rows: [],
};

export const reducer: Reducer<
  BillingCycleState,
  BillingCycleAction | HydrateAction
> = (
  state: BillingCycleState = initialState,
  action: BillingCycleAction | HydrateAction
) => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...reconcileState(action.payload.billingCycles, initialState),
      };
    }

    case BillingCycleActionType.GET_ALL_SUCCESS: {
      return {
        ...state,
        rows: action.payload,
      };
    }
    case BillingCycleActionType.TOGGLE_CREATE: {
      return {
        ...state,
        createDrawerVisible: !state.createDrawerVisible,
        ledgerItemType: action.payload.type,
      };
    }
    case BillingCycleActionType.CREATE_SUCCESS_CLIENT: {
      return {
        ...state,
        rows: [action.payload, ...state.rows],
      };
    }
    case BillingCycleActionType.END_SUCCESS_CLIENT: {
      return {
        ...state,
        rows: replaceById(state.rows, action.payload),
      };
    }
    case BillingCycleActionType.DELETE_SUCCESS_CLIENT: {
      return {
        ...state,
        rows: state.rows.filter((row) => row.id !== action.payload.id),
      };
    }
    default: {
      return state;
    }
  }
};
