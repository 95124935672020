"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteBulkSupplyDemandSubAccountInventoryCap = exports.updateSupplyDemandSubAccountInventoryCap = exports.createBulkSupplyDemandSubAccountInventoryCap = exports.getSupplyDemandSubAccountInventoryCaps = void 0;
var client_1 = require("../client");
var baseURL = '/inventory/supplyDemandSubAccountInventoryCaps';
var getSupplyDemandSubAccountInventoryCaps = function (params) {
    return client_1.client
        .get(baseURL, { params: params })
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.getSupplyDemandSubAccountInventoryCaps = getSupplyDemandSubAccountInventoryCaps;
var createBulkSupplyDemandSubAccountInventoryCap = function (body) { return client_1.client.post("".concat(baseURL, "/bulk"), body).then(function (_a) {
    var data = _a.data;
    return data;
}); };
exports.createBulkSupplyDemandSubAccountInventoryCap = createBulkSupplyDemandSubAccountInventoryCap;
var updateSupplyDemandSubAccountInventoryCap = function (_a) {
    var id = _a.id, body = __rest(_a, ["id"]);
    return client_1.client.put("".concat(baseURL, "/").concat(id), body).then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.updateSupplyDemandSubAccountInventoryCap = updateSupplyDemandSubAccountInventoryCap;
var deleteBulkSupplyDemandSubAccountInventoryCap = function (params) { return client_1.client.delete("".concat(baseURL, "/bulk"), { params: params }).then(function (_a) {
    var data = _a.data;
    return data;
}); };
exports.deleteBulkSupplyDemandSubAccountInventoryCap = deleteBulkSupplyDemandSubAccountInventoryCap;
