import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useOktaAuth } from '@okta/okta-react';

import type { EngineUserClaims } from '@portal/common';

import { selectIsAuthenticationCallbackDone } from '@/store/authentication';
import { authenticationCallbackAction } from '@/store/authentication/actions';

import { Router } from '../routes';
import { nonAuthenticatedRoutes } from '../utils/non-authenticated-routes';

/**
 * Show children only if the current route does not require authentication
 * or if a user is authenticated.
 */
export const AuthenticationGuard: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  const isAuthenticationCallbackDone = useSelector(
    selectIsAuthenticationCallbackDone
  );

  const isAuthenticatedRoute = !nonAuthenticatedRoutes.has(Router.pathname);

  const { oktaAuth, authState } = useOktaAuth();

  const authenticate = async () => {
    const isAuthenticated = await oktaAuth.isAuthenticated();

    if (!isAuthenticated) {
      return oktaAuth.signInWithRedirect();
    }

    const claims = await oktaAuth.getUser<EngineUserClaims>();
    const accessToken = oktaAuth.getAccessToken();

    if (!claims.email_verified) {
      return oktaAuth.signOut({
        postLogoutRedirectUri: `${window.location.origin}/unverified`,
      });
    }

    if (!accessToken) {
      return oktaAuth.signOut({
        postLogoutRedirectUri: `${window.location.origin}/login-error`,
      });
    }

    dispatch(authenticationCallbackAction({ accessToken, claims }));
  };

  useEffect(() => {
    if (isAuthenticatedRoute) {
      authenticate();
    }
  }, [Router.pathname]);

  useEffect(() => {
    // Wait for authState to populate before continuing.
    if (!authState) {
      return;
    }

    // Assume session has timed out if authentication callback has completed and
    // user becomes unauthenticated.
    if (
      isAuthenticatedRoute &&
      isAuthenticationCallbackDone &&
      !authState.isAuthenticated
    ) {
      Router.push('/session-timeout');
      return;
    }
  }, [isAuthenticationCallbackDone, authState?.isAuthenticated]);

  if (isAuthenticatedRoute && !isAuthenticationCallbackDone) {
    return null;
  }

  return <>{children}</>;
};
