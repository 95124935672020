import { produce } from 'immer';
import { cloneDeep, isBoolean, keyBy } from 'lodash';
import { createReducer } from 'typesafe-actions';

import type {
  PortalRuleDemandApiFilter,
  PortalRuleDemandApiFilterCreateData,
} from '@portal/api-models';

import { nextHydrate } from '../actions';
import type { HydrateAction } from '../actions';
import { reconcileState } from '../utils';

import * as originatorActions from './actions';
import * as demandInclusionActions from './demand-inclusion/actions';
import { RecordStatus } from './types';
import type { OriginatorAction, OriginatorState } from './types';

export const initialState: OriginatorState = {
  deduplicationConfigurations: [],
  demandEnablement: null,
  demandOfferCounts: {},
  haveSupplyTemplatesBeenRequested: false,
  offerRulesBySubaccountId: [],
  offerRulesProductByProductType: {},
  partnerMappingsBySubAccountUuid: {},
  rateTablesByLeadUuid: {},
  ruleDemandApiFilters: {
    bySubAccountUuid: {},
    haveAllRowsBeenRequested: false,
    rows: [],
    showCreateForm: false,
  },
  stateExclusionsBySubAccountUuid: {},
  supplyDemandInclusionCounts: {},
  supplyDemandInclusions: [],
  supplyTemplateApplications: {},
  supplyTemplates: null,
};
export function convertRuleFilter({
  name,
  demandSubAccountUuid,
  supplySubAccountUuids,
  states,
  leadFilterRules,
}: PortalRuleDemandApiFilter): PortalRuleDemandApiFilterCreateData {
  return {
    demandSubAccountUuid,
    leadFilterRules: cloneDeep(leadFilterRules),
    name: name ?? '',
    states,
    supplySubAccountUuids,
  };
}

export const reducer = createReducer<
  OriginatorState,
  OriginatorAction | HydrateAction
>(initialState)
  .handleAction(nextHydrate, (state, { payload }) => ({
    ...state,
    ...reconcileState(payload.originator, initialState),
  }))
  .handleAction(
    originatorActions.demandRuleApiGetAllAction.success,
    (state, { payload: rows }) =>
      produce(state, (draft) => {
        draft.ruleDemandApiFilters.haveAllRowsBeenRequested = true;
        draft.ruleDemandApiFilters.rows = rows;
      })
  )
  .handleAction(
    originatorActions.partnerMappingBySubAccountUuidRequestAction.success,
    (state, { payload: { response, uuid } }) =>
      produce(state, (draft) => {
        draft.partnerMappingsBySubAccountUuid[uuid] = response;
      })
  )
  .handleAction(
    originatorActions.demandOfferCountsRequestAction.success,
    (state, { payload: demandOfferCounts }) =>
      produce(state, (draft) => {
        draft.demandOfferCounts = demandOfferCounts;
      })
  )
  .handleAction(
    originatorActions.supplyDemandInclusionCountsRequestAction.success,
    (state, { payload: supplyDemandInclusionCounts }) =>
      produce(state, (draft) => {
        draft.supplyDemandInclusionCounts = supplyDemandInclusionCounts;
      })
  )
  .handleAction(
    originatorActions.supplyTemplatesRequestAction.success,
    (state, { payload: supplyTemplates }) =>
      produce(state, (draft) => {
        const newTemplates = keyBy(supplyTemplates, 'uuid');

        draft.supplyTemplates = { ...draft.supplyTemplates, ...newTemplates };
        draft.haveSupplyTemplatesBeenRequested = true;
      })
  )
  .handleAction(
    originatorActions.getSupplyTemplateApplicationsAction.success,
    (state, { payload: { response, uuid } }) =>
      produce(state, (draft) => {
        draft.supplyTemplateApplications[uuid] = response;
      })
  )
  .handleAction(
    originatorActions.deduplicationConfigurationsRequestAction.success,
    (state, { payload: deduplicationConfigurations }) =>
      produce(state, (draft) => {
        draft.deduplicationConfigurations = deduplicationConfigurations;
      })
  )
  .handleAction(
    originatorActions.stateExclusionsBySubAccountUuidRequestAction.success,
    (state, { payload: { response, uuid } }) =>
      produce(state, (draft) => {
        draft.stateExclusionsBySubAccountUuid[uuid] = response;
      })
  )
  .handleAction(
    originatorActions.ruleDemandApiFiltersBySubAccountUuidRequestAction.success,
    (
      state,
      {
        payload: {
          activeItems: active,
          inactiveItems: inactive,
          demandSubAccountUuid,
        },
      }
    ) =>
      produce(state, (draft) => {
        draft.ruleDemandApiFilters.bySubAccountUuid[demandSubAccountUuid] = {
          [RecordStatus.ACTIVE]: active,
          [RecordStatus.INACTIVE]: inactive,
        };
      })
  )
  .handleAction(
    originatorActions.deleteRuleDemandAPIFilter.success,
    (
      state,
      { payload: { demandSubAccountUuid, deletedRuleDemandAPIFilter } }
    ) => {
      const { active, inactive = [] } =
        state.ruleDemandApiFilters.bySubAccountUuid[demandSubAccountUuid];

      const nextActiveItems = active?.filter(
        (item) => item.id !== deletedRuleDemandAPIFilter.id
      );

      const nextInactiveItems = [deletedRuleDemandAPIFilter, ...inactive];

      return produce(state, (draft) => {
        draft.ruleDemandApiFilters.bySubAccountUuid[demandSubAccountUuid] = {
          [RecordStatus.ACTIVE]: nextActiveItems,
          [RecordStatus.INACTIVE]: nextInactiveItems,
          [RecordStatus.WORKING]: undefined,
        };
      });
    }
  )
  .handleAction(
    originatorActions.createRuleDemandAPIFilter.success,
    (
      state,
      { payload: { demandSubAccountUuid, createdRuleDemandAPIFilter } }
    ) => {
      return produce(state, (draft) => {
        const newHome =
          draft.ruleDemandApiFilters.bySubAccountUuid[demandSubAccountUuid][
            RecordStatus.ACTIVE
          ];

        draft.ruleDemandApiFilters.showCreateForm = false;

        if (Array.isArray(newHome)) {
          newHome.unshift(createdRuleDemandAPIFilter);
        } else {
          draft.ruleDemandApiFilters.bySubAccountUuid[demandSubAccountUuid][
            RecordStatus.ACTIVE
          ] = [createdRuleDemandAPIFilter];
        }
      });
    }
  )
  .handleAction(
    originatorActions.toggleCreateRuleDemandAPIFilterForm,
    (state, { payload }) =>
      produce(state, (draft) => {
        draft.ruleDemandApiFilters.showCreateForm = isBoolean(payload)
          ? payload
          : !state.ruleDemandApiFilters.showCreateForm;
      })
  )
  .handleAction(
    originatorActions.getOfferRulesProductByProductType.success,
    (state, { payload: { response: rows, productType } }) =>
      produce(state, (draft) => {
        draft.offerRulesProductByProductType[productType] = {
          haveAllRowsBeenRequested: true,
          rows,
        };
      })
  )
  .handleAction(
    demandInclusionActions.getOfferRulesBySubaccountIdAction.success,
    (state, { payload }) =>
      produce(state, (draft) => {
        draft.offerRulesBySubaccountId = payload;
      })
  )
  .handleAction(
    originatorActions.getRateTablesByUuid.success,
    (state, { payload: { uuid, response } }) =>
      produce(state, (draft) => {
        draft.rateTablesByLeadUuid[uuid] = {
          haveAllRowsBeenRequested: true,
          rows: response?.rateTables ?? [],
        };
      })
  )
  .handleAction(
    originatorActions.duplicateRuleDemandAPIFilterRow,
    (state, { payload: { demandSubAccountUuid: uuid, id } }) =>
      produce(state, (draft) => {
        const records = state.ruleDemandApiFilters.bySubAccountUuid[uuid];
        // find rule to clone
        const existingRecord =
          records?.[RecordStatus.ACTIVE]?.find((record) => record.id === id) ||
          records?.[RecordStatus.INACTIVE]?.find((record) => record.id === id);

        if (!existingRecord) {
          throw new Error("Can't find that Record");
        }

        const newRecord = convertRuleFilter(existingRecord);

        // clone rule into WORKING
        draft.ruleDemandApiFilters.bySubAccountUuid[uuid][
          RecordStatus.WORKING
        ] = newRecord;
      })
  )
  .handleAction(
    originatorActions.initWorkingFilterRow,
    (state, { payload: { uuid } }) =>
      produce(state, (draft) => {
        draft.ruleDemandApiFilters.bySubAccountUuid[uuid][
          RecordStatus.WORKING
        ] = convertRuleFilter({
          demandSubAccountUuid: uuid,
          leadFilterRules: [],
          name: '',
          states: [],
        } as unknown as PortalRuleDemandApiFilter);
      })
  )
  .handleAction(
    demandInclusionActions.demandInclusionsRequestAction.success,
    (state, { payload }) =>
      produce(state, (draft) => {
        draft.supplyDemandInclusions = payload;
      })
  )
  .handleAction(
    demandInclusionActions.demandEnablementsRequestAction.success,
    (state, { payload }) =>
      produce(state, (draft) => {
        draft.demandEnablement = payload;
      })
  )
  .handleAction(
    demandInclusionActions.demandInclusionsCreateBulkAction.success,
    (state, { payload }) =>
      produce(state, (draft) => {
        draft.supplyDemandInclusions.push(...payload);
      })
  )
  .handleAction(
    demandInclusionActions.demandInclusionsDeleteBulkAction.success,
    (state, { payload }) =>
      produce(state, (draft) => {
        draft.supplyDemandInclusions = draft.supplyDemandInclusions.filter(
          (inclusion) => !payload.includes(inclusion.id)
        );
      })
  );
