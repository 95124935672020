import * as React from 'react';
import { useContext } from 'react';

export interface IPortalFormFieldContext {
  setFieldValue: (value: any, validate: boolean) => void;
}

export const PortalFormFieldContext =
  React.createContext<IPortalFormFieldContext>({
    setFieldValue: () => {},
  });

export const useField = () => useContext(PortalFormFieldContext);
