import * as React from 'react';

import { Button } from 'antd';
import classNames from 'classnames';

import { OnboardingStatus } from '@portal/api-models';

import type { OpenActionModal } from '@/components/self-service/home/integrations-card';
import type { ImplementationFeePaymentStatus } from '@/resources/integrations/types';
import { isFeePaymentPreApprovalRequired } from '@/resources/self-service/payment/util-methods';
import type { IntegrationCategory } from '@/resources/sub-accounts-shared/utils';
import { Link } from '@/routes';

export const formatButton = (
  onboardingStatus: OnboardingStatus,
  integrationType: IntegrationCategory,
  implementationFeePaymentStatus: ImplementationFeePaymentStatus,
  id: string,
  key: string,
  subAccountUuid: string,
  callback: OpenActionModal
) => {
  switch (onboardingStatus) {
    case OnboardingStatus.Draft:
      return (
        <Button
          type="primary"
          onClick={() =>
            callback(
              onboardingStatus,
              integrationType,
              implementationFeePaymentStatus,
              id,
              key,
              subAccountUuid
            )
          }
        >
          Submit
        </Button>
      );
    case OnboardingStatus.IntegrationFeeUnpaid:
      return (
        <Link route={`/implementation-fee?id=${id}`}>
          <Button type="primary">Pay now</Button>
        </Link>
      );
    case OnboardingStatus.SubmittedForReview:
      return (
        <Link route={`/implementation-fee?id=${id}`}>
          <Button
            className={classNames({
              'integrations-button-columm-disabled':
                isFeePaymentPreApprovalRequired(),
            })}
            type="primary"
          >
            Pay now
          </Button>
        </Link>
      );
    case OnboardingStatus.SubmittedForReviewPaid:
      return (
        <Button className="integrations-button-columm-disabled" type="primary">
          Publish
        </Button>
      );
    case OnboardingStatus.Ready:
      return (
        <Button
          type="primary"
          onClick={() =>
            callback(
              onboardingStatus,
              integrationType,
              implementationFeePaymentStatus,
              id,
              key,
              subAccountUuid
            )
          }
        >
          Publish
        </Button>
      );
    default:
      return null;
  }
};
