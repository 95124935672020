import * as React from 'react';
import { useState } from 'react';

import { faChartColumn, faChartLine } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { BarSvgProps } from '@nivo/bar';
import type { LineSvgProps } from '@nivo/line';

import { ChartType } from '@/components/reporting/chart';
import BarChart from '@/components/reporting/chart/bar';
import LineChart from '@/components/reporting/chart/line';
import type { ChartDatum } from '@/resources/performance-summary/chart-helpers';

interface LineAndBarChartProps {
  barProps: BarSvgProps<ChartDatum>;
  defaultChartType?: ChartType;
  lineProps: LineSvgProps;
  onChartTypeChange?: (type: ChartType) => void;
}

const LineAndBarChart: React.FC<LineAndBarChartProps> = ({
  barProps,
  lineProps,
  defaultChartType,
  onChartTypeChange,
}) => {
  const [chartStyle, setChartStyle] = useState(
    defaultChartType || ChartType.LINE
  );

  const isLineChartVisible: boolean = chartStyle === ChartType.LINE;
  const isBarChartVisible: boolean = chartStyle === ChartType.BAR;

  const lineChartActiveClass = isLineChartVisible ? 'is-active' : '';
  const barChartActiveClass = isBarChartVisible ? 'is-active' : '';

  const onChartTypeToggle = (type: ChartType) => {
    setChartStyle(type);
    onChartTypeChange && onChartTypeChange(type);
  };

  return (
    <div className="line-and-bar-chart">
      <div className="line-and-bar-chart-toggler">
        <div
          className={`line-and-bar-chart-toggler-toggle ${lineChartActiveClass}`}
          onClick={() => onChartTypeToggle(ChartType.LINE)}
        >
          <FontAwesomeIcon icon={faChartLine} />
        </div>
        <div
          className={`line-and-bar-chart-toggler-toggle ${barChartActiveClass}`}
          onClick={() => onChartTypeToggle(ChartType.BAR)}
        >
          <FontAwesomeIcon icon={faChartColumn} />
        </div>
      </div>
      {isLineChartVisible && <LineChart lineProps={lineProps} />}
      {isBarChartVisible && <BarChart barProps={barProps} />}
    </div>
  );
};

export default LineAndBarChart;
