import { ThemeColor } from '@evenfinancial/even-ts-static';

export const colors = [ThemeColor.blue];

export const theme = {
  axis: {
    domain: {
      line: {
        stroke: ThemeColor.gray5,
        strokeWidth: 1,
      },
    },
    legend: {
      text: {
        fontSize: 14,
      },
    },
    ticks: {
      line: {
        stroke: ThemeColor.gray5,
      },
    },
  },
  background: ThemeColor.white,
  crosshair: {
    line: {
      stroke: ThemeColor.gray5,
      strokeDasharray: '',
      strokeOpacity: 1,
      strokeWidth: 1,
    },
  },
  fontSize: 14,
  fontWeight: 'bold',
  grid: {
    line: {
      stroke: ThemeColor.gray3,
      strokeDasharray: 3,
      strokeWidth: 1,
    },
  },
  textColor: ThemeColor.gray9,
  tooltip: {
    container: {
      backgroundColor: 'transparent',
      boxShadow: '0',
      margin: 0,
      padding: 0,
    },
  },
};
