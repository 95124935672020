import { createAction, createAsyncAction } from 'typesafe-actions';

import type {
  DeduplicationConfiguration,
  NewDeduplicationConfiguration,
} from '@evenfinancial/lead-client';
import type {
  SupplyTemplate,
  SupplyTemplateApplication,
} from '@evenfinancial/originator-client';

import type {
  PartnerMapping,
  PortalRuleDemandApiFilter,
  StateExclusion,
} from '@portal/api-models';
import type { PortalError } from '@portal/common';

import type { IsomorphicRequestPayload } from '../types';

import { OriginatorActionType } from './actionTypes';
import type {
  CreateRuleDemandAPIFilterRequestPayload,
  CreateRuleDemandAPIFilterSuccessPayload,
  CreateSupplyTemplateApplicationPayload,
  DeduplicationConfigurationBySubAccountUuidRequest,
  DeleteRuleDemandAPIFilterPayload,
  DeleteRuleDemandAPIFilterSuccessPayload,
  GetOfferRulesByProductTypeRequestPayload,
  GetSupplyTemplatesRequestPayload,
  HydratedSupplyTemplateApplication,
  IsomorphicUUIDOnlyPayload,
  OfferRuleProductResponsePayload,
  RateTablesData,
  ResponseWithUUID,
  RuleDemandAPIFiltersRequestPayload,
  RuleDemandAPIFiltersSuccessPayload,
  SupplyTemplateCreatePayload,
  SupplyTemplateUpdatePayload,
  UUIDOnlyPayload,
  UpdateStateExclusionsRequestPayload,
} from './types';

export const partnerMappingBySubAccountUuidRequestAction = createAsyncAction(
  OriginatorActionType.PARTNER_MAPPINGS_BY_SUBACCOUNT_UUID_REQUEST,
  OriginatorActionType.PARTNER_MAPPINGS_BY_SUBACCOUNT_UUID_SUCCESS,
  OriginatorActionType.PARTNER_MAPPINGS_BY_SUBACCOUNT_UUID_FAILURE
)<IsomorphicUUIDOnlyPayload, ResponseWithUUID<PartnerMapping>, Error>();

export const supplyTemplateCreateAction = createAsyncAction(
  OriginatorActionType.CREATE_SUPPLY_TEMPLATE_REQUEST,
  OriginatorActionType.CREATE_SUPPLY_TEMPLATE_SUCCESS,
  OriginatorActionType.CREATE_SUPPLY_TEMPLATE_FAILURE
)<SupplyTemplateCreatePayload, SupplyTemplate, Error>();

export const supplyTemplateUpdateAction = createAction(
  OriginatorActionType.UPDATE_SUPPLY_TEMPLATE_REQUEST
)<SupplyTemplateUpdatePayload>();

export const supplyTemplatesRequestAction = createAsyncAction(
  OriginatorActionType.GET_SUPPLY_TEMPLATES_ALL_REQUEST,
  OriginatorActionType.GET_SUPPLY_TEMPLATES_ALL_SUCCESS,
  OriginatorActionType.GET_SUPPLY_TEMPLATES_ALL_FAILURE
)<GetSupplyTemplatesRequestPayload, SupplyTemplate[], unknown>();

export const deactivateSupplyTemplateAction = createAsyncAction(
  OriginatorActionType.DEACTIVATE_SUPPLY_TEMPLATE_REQUEST,
  OriginatorActionType.DEACTIVATE_SUPPLY_TEMPLATE_SUCCESS,
  OriginatorActionType.DEACTIVATE_SUPPLY_TEMPLATE_FAILURE
)<{ supplyTemplateId: number }, void, unknown>();

export const createSupplyTemplateApplicationAction = createAsyncAction(
  OriginatorActionType.CREATE_SUPPLY_TEMPLATE_APPLICATION_REQUEST,
  OriginatorActionType.CREATE_SUPPLY_TEMPLATE_APPLICATION_SUCCESS,
  OriginatorActionType.CREATE_SUPPLY_TEMPLATE_APPLICATION_FAILURE
)<CreateSupplyTemplateApplicationPayload, SupplyTemplateApplication, Error>();

export const demandRuleApiGetAllAction = createAsyncAction(
  OriginatorActionType.DEMAND_RULE_API_GET_ALL_REQUEST,
  OriginatorActionType.DEMAND_RULE_API_GET_ALL_SUCCESS,
  OriginatorActionType.DEMAND_RULE_API_GET_ALL_FAILURE
)<IsomorphicRequestPayload, PortalRuleDemandApiFilter[], Error>();

export const demandOfferCountsRequestAction = createAsyncAction(
  OriginatorActionType.DEMAND_OFFER_COUNTS_REQUEST,
  OriginatorActionType.DEMAND_OFFER_COUNTS_SUCCESS,
  OriginatorActionType.DEMAND_OFFER_COUNTS_FAILURE
)<IsomorphicRequestPayload, Record<string, number>, Error>();

export const supplyDemandInclusionCountsRequestAction = createAsyncAction(
  OriginatorActionType.SUPPLY_DEMAND_INCLUSION_COUNTS_REQUEST,
  OriginatorActionType.SUPPLY_DEMAND_INCLUSION_COUNTS_SUCCESS,
  OriginatorActionType.SUPPLY_DEMAND_INCLUSION_COUNTS_FAILURE
)<IsomorphicRequestPayload, Record<string, number>, Error>();

export const deduplicationConfigurationsRequestAction = createAsyncAction(
  OriginatorActionType.DEDUPLICATION_CONFIGURATIONS_REQUEST,
  OriginatorActionType.DEDUPLICATION_CONFIGURATIONS_SUCCESS,
  OriginatorActionType.DEDUPLICATION_CONFIGURATIONS_FAILURE
)<
  DeduplicationConfigurationBySubAccountUuidRequest,
  DeduplicationConfiguration[],
  Error
>();

export const deduplicationConfigurationsCreateAction = createAsyncAction(
  OriginatorActionType.DEDUPLICATION_CONFIGURATION_CREATE_REQUEST_CLIENT,
  OriginatorActionType.DEDUPLICATION_CONFIGURATION_CREATE_SUCCESS_CLIENT,
  OriginatorActionType.DEDUPLICATION_CONFIGURATION_CREATE_FAILURE_CLIENT
)<
  NewDeduplicationConfiguration & IsomorphicRequestPayload,
  DeduplicationConfiguration,
  Error
>();

export const deduplicationConfigurationsDeleteAction = createAsyncAction(
  OriginatorActionType.DEDUPLICATION_CONFIGURATION_DELETE_REQUEST_CLIENT,
  OriginatorActionType.DEDUPLICATION_CONFIGURATION_DELETE_SUCCESS_CLIENT,
  OriginatorActionType.DEDUPLICATION_CONFIGURATION_DELETE_FAILURE_CLIENT
)<DeduplicationConfigurationBySubAccountUuidRequest, { id: number }, Error>();

export const stateExclusionsBySubAccountUuidRequestAction = createAsyncAction(
  OriginatorActionType.STATE_EXCLUSIONS_BY_SUBACCOUNT_UUID_REQUEST,
  OriginatorActionType.STATE_EXCLUSIONS_BY_SUBACCOUNT_UUID_SUCCESS,
  OriginatorActionType.STATE_EXCLUSIONS_BY_SUBACCOUNT_UUID_FAILURE
)<IsomorphicUUIDOnlyPayload, ResponseWithUUID<StateExclusion[]>, Error>();

export const updateStateExclusionsBySubAccountUuidRequestAction =
  createAsyncAction(
    OriginatorActionType.UPDATE_STATE_EXCLUSIONS_BY_SUBACCOUNT_UUID_REQUEST,
    OriginatorActionType.UPDATE_STATE_EXCLUSIONS_BY_SUBACCOUNT_UUID_SUCCESS,
    OriginatorActionType.UPDATE_STATE_EXCLUSIONS_BY_SUBACCOUNT_UUID_FAILURE
  )<
    UpdateStateExclusionsRequestPayload,
    ResponseWithUUID<StateExclusion[]>,
    Error
  >();

export const ruleDemandApiFiltersBySubAccountUuidRequestAction =
  createAsyncAction(
    OriginatorActionType.RULE_DEMAND_API_FILTERS_BY_SUB_ACCOUNT_UUID_REQUEST,
    OriginatorActionType.RULE_DEMAND_API_FILTERS_BY_SUB_ACCOUNT_UUID_SUCCESS,
    OriginatorActionType.RULE_DEMAND_API_FILTERS_BY_SUB_ACCOUNT_UUID_FAILURE
  )<
    RuleDemandAPIFiltersRequestPayload,
    RuleDemandAPIFiltersSuccessPayload,
    Error
  >();

export const createRuleDemandAPIFilter = createAsyncAction(
  OriginatorActionType.CREATE_RULE_DEMAND_API_FILTER_REQUEST_CLIENT,
  OriginatorActionType.CREATE_RULE_DEMAND_API_FILTER_SUCCESS_CLIENT,
  OriginatorActionType.CREATE_RULE_DEMAND_API_FILTER_FAILURE_CLIENT
)<
  CreateRuleDemandAPIFilterRequestPayload,
  CreateRuleDemandAPIFilterSuccessPayload,
  PortalError
>();

export const deleteRuleDemandAPIFilter = createAsyncAction(
  OriginatorActionType.DELETE_RULE_DEMAND_API_FILTERS_REQUEST,
  OriginatorActionType.DELETE_RULE_DEMAND_API_FILTER_SUCCESS,
  OriginatorActionType.DELETE_RULE_DEMAND_API_FILTERS_FAILURE
)<
  DeleteRuleDemandAPIFilterPayload,
  DeleteRuleDemandAPIFilterSuccessPayload,
  Error
>();

// Offer Rules Product
export const getOfferRulesProductByProductType = createAsyncAction(
  OriginatorActionType.GET_OFFER_RULES_PRODUCT_BY_PRODUCT_TYPE_REQUEST,
  OriginatorActionType.GET_OFFER_RULES_PRODUCT_BY_PRODUCT_TYPE_SUCCESS,
  OriginatorActionType.GET_OFFER_RULES_PRODUCT_BY_PRODUCT_TYPE_FAILURE
)<
  GetOfferRulesByProductTypeRequestPayload,
  OfferRuleProductResponsePayload,
  PortalError
>();

// Rate Table
export const getRateTablesByUuid = createAsyncAction(
  OriginatorActionType.GET_RATE_TABLES_BY_UUID_REQUEST,
  OriginatorActionType.GET_RATE_TABLES_BY_UUID_SUCCESS,
  OriginatorActionType.GET_RATE_TABLES_BY_UUID_FAILURE
)<IsomorphicUUIDOnlyPayload, ResponseWithUUID<RateTablesData>, PortalError>();

export const getSupplyTemplateApplicationsAction = createAsyncAction(
  OriginatorActionType.GET_SUPPLY_TEMPLATE_APPLICATIONS_REQUEST,
  OriginatorActionType.GET_SUPPLY_TEMPLATE_APPLICATIONS_SUCCESS,
  OriginatorActionType.GET_SUPPLY_TEMPLATE_APPLICATIONS_FAILURE
)<
  UUIDOnlyPayload,
  ResponseWithUUID<HydratedSupplyTemplateApplication>,
  Error
>();

export const toggleCreateRuleDemandAPIFilterForm = createAction(
  OriginatorActionType.TOGGLE_CREATE_RULE_FORM
)<boolean | undefined>();

export const duplicateRuleDemandAPIFilterRow = createAction(
  OriginatorActionType.DUPLICATE_RULE_DEMAND_API_FILTER_ROW
)<DeleteRuleDemandAPIFilterPayload>();

export const beginDuplicateRule = createAction(
  OriginatorActionType.BEGIN_DUPLICATE_RULE
)<DeleteRuleDemandAPIFilterPayload>();

export const initWorkingFilterRow = createAction(
  OriginatorActionType.INITIALIZE_WORKING_RULE_FILTER_ROW
)<UUIDOnlyPayload>();
