"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CmsActionType = void 0;
var CmsActionType;
(function (CmsActionType) {
    CmsActionType["GET_CMS_LEGAL_AGREEMENT_FAILURE"] = "@@cms/GET_CMS_LEGAL_AGREEMENT_FAILURE";
    CmsActionType["GET_CMS_LEGAL_AGREEMENT_LOADER"] = "@@cms/GET_CMS_LEGAL_AGREEMENT_LOADING";
    CmsActionType["GET_CMS_LEGAL_AGREEMENT_REQUEST"] = "@@cms/GET_CMS_LEGAL_AGREEMENT_REQUEST";
    CmsActionType["GET_CMS_LEGAL_AGREEMENT_SUCCESS"] = "@@cms/GET_CMS_LEGAL_AGREEMENT_SUCCESS";
    CmsActionType["GET_CMS_WALKTHROUGH_FAILURE"] = "@@cms/GET_CMS_WALKTHROUGH_FAILURE";
    CmsActionType["GET_CMS_WALKTHROUGH_REQUEST"] = "@@cms/GET_CMS_WALKTHROUGH_REQUEST";
    CmsActionType["GET_CMS_WALKTHROUGH_SCREENS_FAILURE"] = "@@cms/GET_CMS_WALKTHROUGH_SCREENS_FAILURE";
    CmsActionType["GET_CMS_WALKTHROUGH_SCREENS_REQUEST"] = "@@cms/GET_CMS_WALKTHROUGH_SCREENS_REQUEST";
    CmsActionType["GET_CMS_WALKTHROUGH_SCREENS_SUCCESS"] = "@@cms/GET_CMS_WALKTHROUGH_SCREENS_SUCCESS";
    CmsActionType["GET_CMS_WALKTHROUGH_SUCCESS"] = "@@cms/GET_CMS_WALKTHROUGH_SUCCESS";
})(CmsActionType = exports.CmsActionType || (exports.CmsActionType = {}));
