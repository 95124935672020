import type { ApplicationState } from '@/store';

export const selectPersonalLoans = (state: ApplicationState) =>
  state.compliance.personalLoans;

export const selectCreditCards = (state: ApplicationState) =>
  state.compliance.creditCards;

export const selectSavings = (state: ApplicationState) =>
  state.compliance.savings;

export const selectChannels = (state: ApplicationState) =>
  state.compliance.channels;

export const selectComplianceRows = (state: ApplicationState) =>
  state.compliance.rows;
