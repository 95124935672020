import flow from 'lodash/flow';
import groupBy from 'lodash/fp/groupBy';
import mapValues from 'lodash/fp/mapValues';
import omit from 'lodash/fp/omit';
import lowerCase from 'lodash/lowerCase';
import sortBy from 'lodash/sortBy';

import type { PortalSubAccount } from '@portal/api-models';

export interface SubAccountsByAccountUuid {
  [accountUuid: string]: PortalSubAccount[];
}

export default function GroupSubAccountsByAccountUuid(
  subAccounts: PortalSubAccount[]
): SubAccountsByAccountUuid {
  return flow([
    groupBy('accountUuid'),
    omit(['undefined']),
    mapValues((subAccount: PortalSubAccount[]) =>
      sortBy(subAccount, ({ name }) => lowerCase(name))
    ),
  ])(subAccounts);
}
