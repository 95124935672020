import { message } from 'antd';
import type { AxiosResponse } from 'axios';
import uniq from 'lodash/uniq';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import type { ActionType } from 'typesafe-actions';

import type { RichAccessToken } from '@evenfinancial/auth-client';
import { ProductType } from '@evenfinancial/originator-client';
import type { StaticOffer } from '@evenfinancial/originator-client';

import { client } from '@portal/api-client';
import type { OfferDeactivation, ProductOffer } from '@portal/common';
import { richAccessTokenByUuidRequestAction } from '@portal/store/dist/user';

import { error } from '../message/actions';

import * as actions from './actions';

/**
 * @deprecated
 * Deprecated in favor of getStaticOffersByProductType React Query in api-client.
 */
export function* getStaticOffersByProductType({
  payload,
}: ActionType<typeof actions.staticOfferRequestAction.request>) {
  const { productType } = payload;

  try {
    const { data }: AxiosResponse<StaticOffer[]> = yield call(
      [client, 'get'],
      '/originator/static_offers',
      { params: { productType } }
    );

    yield put(
      actions.staticOfferRequestAction.success({
        productType,
        staticOffers: data,
      })
    );
  } catch {
    yield put(error({}));
  }
}

export function* getEnabledStaticOffers({
  payload,
}: ActionType<typeof actions.enabledStaticOffersRequestAction.request>) {
  const { productType, supplySubAccountUuid } = payload;

  try {
    const [
      staticOffersResponse,
      staticOffers2LResponse,
      staticOfferOfferCatalog,
    ]: [
      AxiosResponse<StaticOffer[]>,
      AxiosResponse<StaticOffer[]>,
      AxiosResponse<StaticOffer[]>
    ] = yield all([
      call([client, 'get'], '/originator/demandEnablements/staticOffers', {
        params: { productType, supplySubAccountUuid },
      }),
      call([client, 'get'], '/originator/demandEnablements/staticOffers', {
        params: { productType: ProductType.Other, supplySubAccountUuid },
      }),
      call([client, 'get'], '/originator/demandEnablements/staticOffers', {
        params: { productType: ProductType.Generic, supplySubAccountUuid },
      }),
    ]);

    const staticOffers = staticOffersResponse.data;
    const staticOffers2L = staticOffers2LResponse.data;
    const staticOfferOfferCatalogs = staticOfferOfferCatalog.data;

    const allStaticOffers = staticOffers
      .concat(staticOffers2L)
      .concat(staticOfferOfferCatalogs);

    yield put(
      actions.enabledStaticOffersRequestAction.success(allStaticOffers)
    );
  } catch (err: any) {
    yield put(actions.enabledStaticOffersRequestAction.failure(err));
  }
}

export function* patchBulkStaticOffers({
  payload,
}: ActionType<typeof actions.patchBulkStaticOffersRequestAction.request>) {
  try {
    const responses = yield all(
      payload.map(({ staticOfferId, supplySubAccountIds }) =>
        call(
          [client, 'patch'],
          `/originator/demandEnablements/staticOffers/${staticOfferId}`,
          {
            supplySubAccountIds,
          }
        )
      )
    );

    const data = responses.map((response) => response.data);

    yield put(actions.patchBulkStaticOffersRequestAction.success(data));
  } catch (err: any) {
    yield put(actions.patchBulkStaticOffersRequestAction.failure(err));
  }
}

function* getProductOffers(
  action: ActionType<typeof actions.productOfferRequestAction.request>
) {
  const { productType } = action.payload;

  try {
    const productOffers: ProductOffer[] = yield call(async () => {
      return client
        .get<ProductOffer[]>('/originator/product_offers', {
          params: { limit: 200, productType },
        })
        .then((res) => res.data);
    });
    const accessTokenUuids = uniq(
      productOffers.map((o) => o.updatedBy || o.createdBy)
    );
    const rats = yield call(async () =>
      client
        .post<RichAccessToken>('/auth/rich_access_tokens', {
          accessTokenUuids,
        })
        .then((res) => res.data)
    );

    yield put(richAccessTokenByUuidRequestAction.success(rats));

    yield put(
      actions.productOfferRequestAction.success({ productOffers, productType })
    );
  } catch (err: any) {
    yield put(actions.productOfferRequestAction.failure(err));
  }
}

function* getDeactivationOffer() {
  try {
    const offersDeactivation: OfferDeactivation[] = yield call(async () => {
      return client
        .get<OfferDeactivation[]>('/originator/offer_deactivations')
        .then((res) => res.data);
    });

    yield put(
      actions.offerDeactivationRequestAction.success(offersDeactivation)
    );
  } catch (err: any) {
    yield put(actions.offerDeactivationRequestAction.failure(err));
  }
}

function* createOfferDeactivations(
  action: ActionType<typeof actions.createOfferDeactivationsAction.request>
) {
  const { offerIds } = action.payload;

  try {
    const createDeactivations: OfferDeactivation[] = yield call(async () =>
      client
        .post('/originator/offer_deactivations', { offerIds })
        .then((res) => res.data)
    );

    yield put(
      actions.createOfferDeactivationsAction.success(createDeactivations)
    );
    setTimeout(
      () => message.success('The Offer Deactivations Successfully Updated'),
      100
    );
  } catch (err: any) {
    yield put(actions.createOfferDeactivationsAction.failure(err));
  }
}

function* deleteOfferDeactivation(
  action: ActionType<typeof actions.deleteOfferDeactivationAction.request>
) {
  const { deactivationIds } = action.payload;

  try {
    const deleteDeactivations: OfferDeactivation[] = yield call(async () =>
      client
        .put('/originator/offer_deactivations', { deactivationIds })
        .then((res) => res.data)
    );

    yield put(
      actions.deleteOfferDeactivationAction.success(deleteDeactivations)
    );
    setTimeout(
      () => message.success('The Offer Deactivations Successfully Updated'),
      100
    );
  } catch (err: any) {
    yield put(actions.deleteOfferDeactivationAction.failure(err));
  }
}

export function* offerSaga() {
  yield all([
    takeLatest(
      getType(actions.productOfferRequestAction.request),
      getProductOffers
    ),
    takeLatest(
      getType(actions.offerDeactivationRequestAction.request),
      getDeactivationOffer
    ),
    takeLatest(
      getType(actions.createOfferDeactivationsAction.request),
      createOfferDeactivations
    ),
    takeLatest(
      getType(actions.deleteOfferDeactivationAction.request),
      deleteOfferDeactivation
    ),
    takeEvery(
      getType(actions.staticOfferRequestAction.request),
      getStaticOffersByProductType
    ),
    takeLatest(
      getType(actions.enabledStaticOffersRequestAction.request),
      getEnabledStaticOffers
    ),
    takeLatest(
      getType(actions.patchBulkStaticOffersRequestAction.request),
      patchBulkStaticOffers
    ),
  ]);
}
