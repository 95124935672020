import { createAsyncAction } from 'typesafe-actions';

import type { ExpectedEarningsShare } from '@evenfinancial/finance-client';

import type { IsomorphicRequestPayload } from '../types';

import { ExpectedEarningsShareActionType } from './types';

interface GetBySubAccountUuidResponsePayload {
  response: ExpectedEarningsShare[];
  uuid: string;
}

interface GetBySubAccountUuidRequestPayload extends IsomorphicRequestPayload {
  uuid: string;
}

export const getExpectedEarningsSharesBySubAccountUuidAction =
  createAsyncAction(
    ExpectedEarningsShareActionType.GET_EXPECTED_EARNINGS_SHARES_BY_SUB_ACCOUNT_ID_REQUEST,
    ExpectedEarningsShareActionType.GET_EXPECTED_EARNINGS_SHARES_BY_SUB_ACCOUNT_ID_SUCCESS,
    ExpectedEarningsShareActionType.GET_EXPECTED_EARNINGS_SHARES_BY_SUB_ACCOUNT_ID_FAILURE
  )<
    GetBySubAccountUuidRequestPayload,
    GetBySubAccountUuidResponsePayload,
    Error
  >();

export const createExpectedEarningsShareAction = createAsyncAction(
  ExpectedEarningsShareActionType.CREATE_EXPECTED_EARNINGS_SHARE_REQUEST,
  ExpectedEarningsShareActionType.CREATE_EXPECTED_EARNINGS_SHARE_SUCCESS,
  ExpectedEarningsShareActionType.CREATE_EXPECTED_EARNINGS_SHARE_FAILURE
)<IsomorphicRequestPayload, ExpectedEarningsShare, Error>();

export const getExpectedEarningsSharesAction = createAsyncAction(
  ExpectedEarningsShareActionType.GET_EXPECTED_EARNINGS_SHARES_REQUEST,
  ExpectedEarningsShareActionType.GET_EXPECTED_EARNINGS_SHARES_SUCCESS,
  ExpectedEarningsShareActionType.GET_EXPECTED_EARNINGS_SHARES_FAILURE
)<IsomorphicRequestPayload, ExpectedEarningsShare[], Error>();
