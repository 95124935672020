"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleCreateAccount = exports.toggleDuplicateAccount = exports.accountsSave = exports.accountsSelfServiceRequestAction = exports.deleteAccountDeactivationAction = exports.createAccountDeactivationsAction = exports.accountDeactivationRequestAction = exports.accountByUuidAction = exports.accountUpdatePayoutSettingsRequestAction = exports.accountUpdateRequestAction = exports.accountCreateRequestAction = exports.accountRequestAction = void 0;
var typesafe_actions_1 = require("typesafe-actions");
var actionTypes_1 = require("./actionTypes");
exports.accountRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.AccountActionType.GET_ALL_REQUEST, actionTypes_1.AccountActionType.GET_ALL_SUCCESS, actionTypes_1.AccountActionType.GET_ALL_FAILURE)();
exports.accountCreateRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.AccountActionType.CREATE_REQUEST_CLIENT, actionTypes_1.AccountActionType.CREATE_SUCCESS_CLIENT, actionTypes_1.AccountActionType.CREATE_FAILURE_CLIENT)();
exports.accountUpdateRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.AccountActionType.UPDATE_REQUEST_CLIENT, actionTypes_1.AccountActionType.UPDATE_SUCCESS_CLIENT, actionTypes_1.AccountActionType.UPDATE_FAILURE_CLIENT)();
exports.accountUpdatePayoutSettingsRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.AccountActionType.UPDATE_PAYOUT_SETTINGS_REQUEST_CLIENT, actionTypes_1.AccountActionType.UPDATE_PAYOUT_SETTINGS_SUCCESS_CLIENT, actionTypes_1.AccountActionType.UPDATE_PAYOUT_SETTINGS_FAILURE_CLIENT)();
exports.accountByUuidAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.AccountActionType.BY_UUID_REQUEST, actionTypes_1.AccountActionType.BY_UUID_SUCCESS, actionTypes_1.AccountActionType.BY_UUID_FAILURE)();
exports.accountDeactivationRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.AccountActionType.GET_ALL_DEACTIVATION_REQUEST, actionTypes_1.AccountActionType.GET_ALL_DEACTIVATION_SUCCESS, actionTypes_1.AccountActionType.GET_ALL_DEACTIVATION_FAILURE)();
exports.createAccountDeactivationsAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.AccountActionType.CREATE_ACCOUNT_DEACTIVATIONS_REQUEST, actionTypes_1.AccountActionType.CREATE_ACCOUNT_DEACTIVATIONS_SUCCESS, actionTypes_1.AccountActionType.CREATE_ACCOUNT_DEACTIVATIONS_FAILURE)();
exports.deleteAccountDeactivationAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.AccountActionType.DELETE_ACCOUNT_DEACTIVATIONS_REQUEST, actionTypes_1.AccountActionType.DELETE_ACCOUNT_DEACTIVATIONS_SUCCESS, actionTypes_1.AccountActionType.DELETE_ACCOUNT_DEACTIVATIONS_FAILURE)();
exports.accountsSelfServiceRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.AccountActionType.GET_ALL_SELF_SERVICE_REQUEST, actionTypes_1.AccountActionType.GET_ALL_SELF_SERVICE_SUCCESS, actionTypes_1.AccountActionType.GET_ALL_SELF_SERVICE_FAILURE)();
exports.accountsSave = (0, typesafe_actions_1.createAction)((0, typesafe_actions_1.getType)(exports.accountRequestAction.success))();
exports.toggleDuplicateAccount = (0, typesafe_actions_1.createAction)(actionTypes_1.AccountActionType.TOGGLE_DUPLICATE)();
exports.toggleCreateAccount = (0, typesafe_actions_1.createAction)(actionTypes_1.AccountActionType.TOGGLE_CREATE)();
