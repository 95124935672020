"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.headerSelector = void 0;
var reselect_1 = require("reselect");
var selectors_1 = require("../user/selectors");
exports.headerSelector = (0, reselect_1.createSelector)([selectors_1.selectPermissions], function (_a) {
    var showFeedbackHeaderItem = _a.showFeedbackHeaderItem, showHelpCenterHeaderItemFeatureFlag = _a.showHelpCenterHeaderItemFeatureFlag, showHelpCenterHeaderItemIfFeatureFlag = _a.showHelpCenterHeaderItemIfFeatureFlag, showNotificationsHeaderItemFeatureFlag = _a.showNotificationsHeaderItemFeatureFlag, showNotificationsHeaderItemIfFeatureFlag = _a.showNotificationsHeaderItemIfFeatureFlag;
    return ({
        showGiveFeedbackHeaderItem: showFeedbackHeaderItem,
        showHelpCenterHeaderItem: showHelpCenterHeaderItemFeatureFlag &&
            showHelpCenterHeaderItemIfFeatureFlag,
        showNotificationsHeaderItem: showNotificationsHeaderItemFeatureFlag &&
            showNotificationsHeaderItemIfFeatureFlag,
    });
});
