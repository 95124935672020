"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectLegalAgreementByType = exports.getLegalAgreementLoadingState = exports.getLegalAgreementRows = void 0;
var reselect_1 = require("reselect");
var getLegalAgreementRows = function (state) {
    return state.cms.legalAgreements.rows;
};
exports.getLegalAgreementRows = getLegalAgreementRows;
var getLegalAgreementLoadingState = function (state) {
    return state.cms.legalAgreements.loading;
};
exports.getLegalAgreementLoadingState = getLegalAgreementLoadingState;
exports.selectLegalAgreementByType = (0, reselect_1.createSelector)([
    function (_state, agreementType) {
        return agreementType;
    },
    exports.getLegalAgreementRows,
    exports.getLegalAgreementLoadingState,
], function (agreementType, rows, loading) {
    var agreement = rows.find(function (item) { return item.id === agreementType; });
    return {
        agreement: agreement,
        loading: loading,
    };
});
