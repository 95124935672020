"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.demandScopes = void 0;
var enum_1 = require("../enum");
exports.demandScopes = [
    enum_1.Scopes.AccessTokensRead,
    enum_1.Scopes.AccountsRead,
    enum_1.Scopes.AccountsUpdate,
    enum_1.Scopes.ApplicationsRead,
    enum_1.Scopes.BenchmarkReportsRead,
    enum_1.Scopes.CompaniesRead,
    enum_1.Scopes.CurrentUserAccountRead,
    enum_1.Scopes.DeactivationsRead,
    enum_1.Scopes.DemandApisRead,
    enum_1.Scopes.DemandContractsRead,
    enum_1.Scopes.EmailDemandContactUs,
    enum_1.Scopes.FeedbacksCreate,
    enum_1.Scopes.LedgerItemsRead,
    enum_1.Scopes.MciReportsRead,
    enum_1.Scopes.OfferRulesRead,
    enum_1.Scopes.PartnerPagesRead,
    enum_1.Scopes.PartnerPagesUpdate,
    enum_1.Scopes.RevenueReportsRead,
    enum_1.Scopes.RichAccessTokensRead,
    enum_1.Scopes.SubAccountsRead,
    enum_1.Scopes.SubAccountsRead,
    enum_1.Scopes.SubAccountsUpdate,
    enum_1.Scopes.SupplyContractsRead,
    enum_1.Scopes.SupplyDemandInclusionsRead,
    enum_1.Scopes.SupplyInclusionsTemplatesRead,
    enum_1.Scopes.SupplySummariesRead,
    enum_1.Scopes.UserDeactivationsRead,
    enum_1.Scopes.UsersRead,
    enum_1.Scopes.UsersUpdate,
];
