import Hotjar from '@hotjar/browser';
import { useRouter } from 'next/router';

import { useTracker } from './use-tracker';

export const useHotjar = () => {
  const { events } = useRouter();

  useTracker(({ engineUser, config, roles }) => {
    Hotjar.init(config.NEXT_PUBLIC_HOTJAR_ID, config.NEXT_PUBLIC_HOTJAR_SV);

    Hotjar.identify(engineUser.uuid, {
      email: engineUser.emailAddress,
      firstName: engineUser.firstName,
      lastName: engineUser.lastName,
      roles: roles.join(', '),
      uuid: engineUser.uuid,
    });

    events.on('routeChangeComplete', Hotjar.stateChange);
  });
};
