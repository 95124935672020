"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeedbackActionType = void 0;
var FeedbackActionType;
(function (FeedbackActionType) {
    FeedbackActionType["CREATE_FAILURE_CLIENT"] = "@@feedback/CREATE_FAILURE_CLIENT";
    FeedbackActionType["CREATE_REQUEST_CLIENT"] = "@@feedback/CREATE_REQUEST_CLIENT";
    FeedbackActionType["CREATE_SUCCESS_CLIENT"] = "@@feedback/CREATE_SUCCESS_CLIENT";
    FeedbackActionType["TOGGLE_FEEDBACK_DRAWER"] = "@@feedback/TOGGLE_FEEDBACK_DRAWER";
})(FeedbackActionType = exports.FeedbackActionType || (exports.FeedbackActionType = {}));
