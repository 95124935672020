"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.tutorialsReducer = exports.initialTutorialsState = void 0;
var immer_1 = require("immer");
var typesafe_actions_1 = require("typesafe-actions");
var actions_1 = require("./actions");
exports.initialTutorialsState = {
    tutorialSeries: {},
    tutorialVideos: {},
};
exports.tutorialsReducer = (0, typesafe_actions_1.createReducer)(exports.initialTutorialsState)
    .handleAction(actions_1.getTutorialVideoDetails.success, function (state, _a) {
    var _b = _a.payload, type = _b.type, rest = __rest(_b, ["type"]);
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.tutorialVideos[type] = rest;
    });
})
    .handleAction(actions_1.getTutorialVideos.success, function (state, _a) {
    var _b = _a.payload, type = _b.type, videos = _b.videos;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.tutorialSeries[type] = videos || [];
    });
});
