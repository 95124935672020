"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Permissions = void 0;
var Permissions;
(function (Permissions) {
    Permissions["CanCreateAnyUserType"] = "can_create_any_user_type";
    Permissions["CanCreateOwnUserType"] = "can_create_own_user_type";
    Permissions["CanIgnoreCustomGrants"] = "can_ignore_custom_grants";
    Permissions["CanManageAllAccounts"] = "can_manage_all_accounts";
})(Permissions = exports.Permissions || (exports.Permissions = {}));
