import * as React from 'react';
import type { ReactNode } from 'react';

import {
  faCar,
  faCircleQuestion,
  faCreditCard,
  faGraduationCap,
  faHandHoldingDollar,
  faPiggyBank,
  faUmbrella,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Statistic from 'antd/lib/statistic';
import type { Formatter } from 'antd/lib/statistic/utils';
import sumBy from 'lodash/sumBy';

import { ConfigProductType } from '@evenfinancial/even-ts-static';
import type { ProductType } from '@evenfinancial/finance-client';

import type { FunnelSummary, PortalSupplyContract } from '@portal/api-models';

import type { TopTotalsPanelItem } from '@/components/reporting/top-totals-panel';
import { getContractType } from '@/resources/channels/contracts/util-methods';
import { funnelPanelConfig } from '@/resources/channels/performance-summary/panel-configs';
import type { Integration } from '@/resources/integrations/types';

export interface SummaryStat {
  formatter?: Formatter;
  key: string;
  label: string;
  tooltip: string;
  value?: ReactNode;
}

export const mapPanelConfigToSummaryStateConfig = (
  panelConfig: TopTotalsPanelItem[]
): SummaryStat[] => {
  return panelConfig.map(
    (item) =>
      ({
        formatter: item.formatter,
        key: item.key,
        label: item.title,
        tooltip: item.tooltip,
      } as SummaryStat)
  );
};

export const generateStatBarConfig = (options: {
  funnelSummary?: FunnelSummary[];
  productType: ProductType;
  supplyContracts?: PortalSupplyContract[];
}): SummaryStat[] => {
  const { funnelSummary, productType, supplyContracts } = options;

  const config = mapPanelConfigToSummaryStateConfig(
    funnelPanelConfig(
      productType,
      getContractType(productType, supplyContracts)
    )
  );

  return config.map((item) => {
    return {
      ...item,
      value: (
        <Statistic
          formatter={item.formatter}
          value={sumBy(funnelSummary, item.key)}
        />
      ),
    };
  });
};

export const getProductTypeBadgeIcon = (integration: Integration) => {
  const productType = integration?.integrationProduct;

  switch (productType) {
    case ConfigProductType.AutomobileRefinance:
      return faCar;
    case ConfigProductType.Loan:
      return faHandHoldingDollar;
    case ConfigProductType.StudentLoanRefinance:
      return faGraduationCap;
    case ConfigProductType.CreditCard:
      return faCreditCard;
    case ConfigProductType.LifeInsurance:
      return faUmbrella;
    case ConfigProductType.Deposits:
      return faPiggyBank;
  }
};

export const getProductTypeBadges = (integrations: Integration[]) => {
  return integrations
    .map((integration) => getProductTypeBadgeIcon(integration))
    .map((icon, i) =>
      icon ? (
        <FontAwesomeIcon
          key={(icon?.iconName ?? 'custom-icon') + i}
          className="product-badge"
          icon={icon}
          size="1x"
        />
      ) : (
        <FontAwesomeIcon
          key={`question-circle-${i}`}
          className="product-badge"
          icon={faCircleQuestion}
          size="1x"
        />
      )
    );
};
