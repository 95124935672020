import type { ActionType } from 'typesafe-actions';

import type { ProductType } from '@evenfinancial/finance-client';
import type { StaticOffer } from '@evenfinancial/originator-client';

import type { OfferDeactivation, ProductOffer } from '@portal/common';

import type * as actions from './actions';

export type OfferAction = ActionType<typeof actions>;
export enum OfferActionType {
  CREATE_OFFER_DEACTIVATIONS_FAILURE = '@@offer/CREATE_OFFER_DEACTIVATIONS_FAILURE',
  CREATE_OFFER_DEACTIVATIONS_REQUEST = '@@offer/CREATE_OFFER_DEACTIVATIONS_REQUEST',
  CREATE_OFFER_DEACTIVATIONS_SUCCESS = '@@offer/CREATE_OFFER_DEACTIVATIONS_SUCCESS',

  DELETE_OFFER_DEACTIVATIONS_FAILURE = '@@offer/DELETE_OFFER_DEACTIVATIONS_FAILURE',
  DELETE_OFFER_DEACTIVATIONS_REQUEST = '@@offer/DELETE_OFFER_DEACTIVATIONS_REQUEST ',
  DELETE_OFFER_DEACTIVATIONS_SUCCESS = '@@offer/DELETE_OFFER_DEACTIVATIONS_SUCCESS',

  GET_ALL_OFFER_DEACTIVATION_FAILURE = '@@offer/GET_ALL_OFFER_DEACTIVATION_FAILURE',
  GET_ALL_OFFER_DEACTIVATION_REQUEST = '@@offer/GET_ALL_OFFER_DEACTIVATION_REQUEST',
  GET_ALL_OFFER_DEACTIVATION_SUCCESS = '@@offer/GET_ALL_OFFER_DEACTIVATION_SUCCESS',

  GET_ALL_PRODUCT_OFFERS_FAILURE = '@@offer/GET_ALL_PRODUCT_OFFERS_FAILURE',
  GET_ALL_PRODUCT_OFFERS_REQUEST = '@@offer/GET_ALL_PRODUCT_OFFERS_REQUEST',
  GET_ALL_PRODUCT_OFFERS_SUCCESS = '@@offer/GET_ALL_PRODUCT_OFFERS_SUCCESS',

  GET_ALL_STATIC_OFFERS_FAILURE = '@@offer/GET_ALL_STATIC_OFFERS_FAILURE',
  GET_ALL_STATIC_OFFERS_REQUEST = '@@offer/GET_ALL_STATIC_OFFERS_REQUEST',
  GET_ALL_STATIC_OFFERS_SUCCESS = '@@offer/GET_ALL_STATIC_OFFERS_SUCCESS',

  GET_STATIC_OFFERS_FAILURE = '@@offer/GET_STATIC_OFFERS_FAILURE',
  GET_STATIC_OFFERS_REQUEST = '@@offer/GET_STATIC_OFFERS_REQUEST',
  GET_STATIC_OFFERS_SUCCESS = '@@offer/GET_STATIC_OFFERS_SUCCESS',

  PATCH_STATIC_OFFERS_FAILURE = '@@offer/PATCH_STATIC_OFFERS_FAILURE',
  PATCH_STATIC_OFFERS_REQUEST = '@@offer/PATCH_STATIC_OFFERS_REQUEST',
  PATCH_STATIC_OFFERS_SUCCESS = '@@offer/PATCH_STATIC_OFFERS_SUCCESS',
}

export interface OfferState {
  deactivationRows: OfferDeactivation[];
  enabledStaticOffers: StaticOffer[];
  haveAllOffersDeactivationBeenRequested: boolean;
  productOffersByProductType: {
    [key in ProductType]?: {
      haveAllRowsBeenRequested: boolean;
      rows: ProductOffer[];
    };
  };
  staticOffersByProductType: Record<ProductType, StaticOffer[]>;
}
