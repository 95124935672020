"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPortalError = void 0;
const api_models_1 = require("@portal/api-models");
const getPortalError = (err) => {
    var _a, _b;
    const portalError = Object.assign({}, err);
    const errorPayload = (_b = (_a = err.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message;
    if (errorPayload &&
        Object.values(api_models_1.ErrorType).includes(errorPayload)) {
        portalError.customError = errorPayload;
    }
    return portalError;
};
exports.getPortalError = getPortalError;
