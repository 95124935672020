"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterInventoryCapsByStatus = void 0;
const api_models_1 = require("@portal/api-models");
const filterInventoryCapsByStatus = (inventoryCaps) => {
    const activeInventoryCaps = [];
    const upcomingInventoryCaps = [];
    const previousInventoryCaps = [];
    inventoryCaps.forEach((inventoryCap) => {
        switch (inventoryCap.status) {
            case api_models_1.CapStatus.Active:
                activeInventoryCaps.push(inventoryCap);
                break;
            case api_models_1.CapStatus.Upcoming:
                upcomingInventoryCaps.push(inventoryCap);
                break;
            case api_models_1.CapStatus.Ended:
                previousInventoryCaps.push(inventoryCap);
                break;
        }
    });
    return { activeInventoryCaps, previousInventoryCaps, upcomingInventoryCaps };
};
exports.filterInventoryCapsByStatus = filterInventoryCapsByStatus;
