"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubRoleObjects = exports.Experience = void 0;
const index_1 = require("../index");
const get_flat_role_from_roles_1 = require("./get-flat-role-from-roles");
var Experience;
(function (Experience) {
    Experience["Demand"] = "Demand";
    Experience["Supply"] = "Supply";
})(Experience = exports.Experience || (exports.Experience = {}));
const getSubRoleObjects = (roles) => {
    const matches = {};
    const rolesLowercased = roles.map((role) => role.toLowerCase());
    const supplyCombos = index_1.supplyRoleObjectArray.map((roleObject) => roleObject.roles);
    const demandCombos = index_1.demandRoleObjectArray.map((roleObject) => roleObject.roles);
    const matchingSupplySubarray = supplyCombos.find((combo) => combo.every((role) => rolesLowercased.includes(role.toLowerCase())));
    const matchingDemandSubarray = demandCombos.find((combo) => combo.every((role) => rolesLowercased.includes(role.toLowerCase())));
    if (matchingDemandSubarray) {
        matches.demandRoleObject = {
            flatRole: (0, get_flat_role_from_roles_1.getFlatRoleFromRoles)(matchingDemandSubarray),
            roles: matchingDemandSubarray,
        };
    }
    if (matchingSupplySubarray) {
        matches.supplyRoleObject = {
            flatRole: (0, get_flat_role_from_roles_1.getFlatRoleFromRoles)(matchingSupplySubarray),
            roles: matchingSupplySubarray,
        };
    }
    return matches;
};
exports.getSubRoleObjects = getSubRoleObjects;
