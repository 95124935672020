import * as React from 'react';

export interface SubmitForReviewMessageProps {
  integrationTypeLabel: string;
  isPaid?: boolean;
}

export const SubmitForReviewMessage: React.FC<SubmitForReviewMessageProps> = ({
  integrationTypeLabel,
  isPaid,
}) => (
  <div className="submit-for-review-message">
    <p>
      Your {integrationTypeLabel} will be saved as a draft and can be reviewed
      from the Integrations module or Homepage.
    </p>
    <p>
      The website where you will share the integration URL needs to be reviewed
      and confirmed that it follows all our partners guidelines. This usually
      takes 3-5 work days. You will receive an&nbsp;
      <strong className="text-semibold">email confirmation</strong> once
      your&nbsp;
      {integrationTypeLabel} is compliance approved.
    </p>
    {!isPaid ? (
      <p>
        Meanwhile, to complete your account setup, please purchase a plan to go
        live on the Even network.
      </p>
    ) : null}
  </div>
);
