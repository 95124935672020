import type { ColumnProps } from 'antd/lib/table/Column';

import { ProductType } from '@evenfinancial/finance-client';
import type { SummaryGroupBy } from '@evenfinancial/supply-reporting-client';

import type { TopTotalsPanelItem } from '@/components/reporting/top-totals-panel';
import {
  earningsGroupByConfig,
  funnelGroupByConfig,
} from '@/resources/channels/performance-summary/group-by-configs';
import {
  earningsPanelConfig,
  funnelPanelConfig,
} from '@/resources/channels/performance-summary/panel-configs';
import {
  earningsTableConfig,
  funnelTableConfig,
} from '@/resources/channels/performance-summary/table-configs';
import type { ContractApplicationType } from '@/store/demand-contracts/types';

export enum PerformanceSummariesTabsEnum {
  Earnings = 'Earnings',
  Funnel = 'Funnel',
}

export type PerformanceSummaryTab = {
  groupByOptions: SummaryGroupBy[];
  infoBoxText: string;
  panelConfig: TopTotalsPanelItem[];
  tableConfig: (_groupBy: string) => ColumnProps<any>[];
};

export type PerformanceSummaryTabsType = {
  [key in PerformanceSummariesTabsEnum]?: PerformanceSummaryTab;
};

export interface PerformanceSummariesPageConfig {
  message?: string;
  tabs?: PerformanceSummaryTabsType;
}
const getPageConfigForSubAccount = (
  productType: ProductType,
  contractApplicationType: ContractApplicationType
): PerformanceSummariesPageConfig => {
  switch (productType) {
    case ProductType.Loan:
    case ProductType.Savings:
    case ProductType.CreditCard:
    case ProductType.LifeInsurance:
      return {
        tabs: {
          [PerformanceSummariesTabsEnum.Earnings]: {
            groupByOptions: earningsGroupByConfig(productType),
            infoBoxText:
              'Total earnings based on the monetization event that will be paid to a Channel partner in the selected date range',
            panelConfig: earningsPanelConfig(productType),
            tableConfig: earningsTableConfig(productType),
          },
          [PerformanceSummariesTabsEnum.Funnel]: {
            groupByOptions: funnelGroupByConfig(productType),
            infoBoxText:
              'Funnel Performance Summary shows the lead-based funnel metrics per Integration for the selected time period',
            panelConfig: funnelPanelConfig(
              productType,
              contractApplicationType
            ),
            tableConfig: funnelTableConfig(
              productType,
              contractApplicationType
            ),
          },
        },
      };

    //NOTICE: Placeholder for missing product types' support
    default:
      return {
        message: `Performance Summary is not yet supported for subaccount with product type: "${productType}"!`,
      };
  }
};

export default getPageConfigForSubAccount;
