import { createSelector } from 'reselect';

import { selectFirstAccountUuid } from '@portal/store/dist/account/selectors';

import { selectLayout } from '@/store/interface/selectors';

export const sideNavStateSelector = createSelector(
  [selectFirstAccountUuid, selectLayout],
  (accountUuid = '', { fullscreenMode, sidenavCollapsed }) => {
    return {
      accountUuid,
      fullscreenMode,
      sidenavCollapsed,
    };
  }
);
