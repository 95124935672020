"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRolePermissions = void 0;
const api_models_1 = require("@portal/api-models");
const permissions_1 = require("../permissions");
const getRolePermissions = (roles) => {
    let config = {};
    if (roles.has(api_models_1.Roles.InternalUser) && roles.has(api_models_1.Roles.Manager)) {
        config = Object.assign(Object.assign({}, config), permissions_1.internalManagerConfig);
    }
    if (roles.has(api_models_1.Roles.InternalUser) && roles.has(api_models_1.Roles.InternalViewer)) {
        config = Object.assign(Object.assign({}, config), permissions_1.internalViewerConfig);
    }
    if (roles.has(api_models_1.Roles.SupplyUser) && roles.has(api_models_1.Roles.ExternalUser)) {
        config = Object.assign(Object.assign({}, config), permissions_1.migrationConfig);
    }
    if (roles.has(api_models_1.Roles.SupplyUser)) {
        config = Object.assign(Object.assign({}, config), permissions_1.supplyConfig);
    }
    if (roles.has(api_models_1.Roles.SelfService)) {
        config = Object.assign(Object.assign({}, config), permissions_1.selfServiceConfig);
    }
    if (roles.has(api_models_1.Roles.Starter)) {
        config = Object.assign(Object.assign({}, config), permissions_1.starterLegacyConfig);
    }
    if (roles.has(api_models_1.Roles.StarterLegacy)) {
        config = Object.assign(Object.assign({}, config), permissions_1.starterLegacyConfig);
    }
    if (roles.has(api_models_1.Roles.DemandUser)) {
        config = Object.assign(Object.assign({}, config), permissions_1.demandConfig);
    }
    if (roles.has(api_models_1.Roles.CRM)) {
        config = Object.assign(Object.assign({}, config), permissions_1.crmConfig);
    }
    if (roles.has(api_models_1.Roles.ExternalUser)) {
        config = Object.assign(Object.assign({}, config), permissions_1.externalConfig);
    }
    if (roles.has(api_models_1.Roles.Scale)) {
        config = Object.assign(Object.assign({}, config), permissions_1.scaleConfig);
    }
    return config;
};
exports.getRolePermissions = getRolePermissions;
