import { useEffect } from 'react';

const useOnResize = (callback: () => void, onLoad: boolean = true) => {
  useEffect(() => {
    const handleResize = () => callback();

    window.addEventListener('resize', handleResize);

    if (onLoad) {
      callback();
    }

    return () => window.removeEventListener('resize', handleResize);
  }, []);
};

export default useOnResize;
