import { createAction, createAsyncAction } from 'typesafe-actions';

import type {
  AccountAdjustment,
  LedgerItemEvent,
  LedgerItemRecreationBatch,
  LedgerItemRecreationBatchCreateData,
} from '@evenfinancial/finance-client';

import type {
  PortalLedgerItem,
  TempReceivableSummary,
} from '@portal/api-models';
import type { AccountAddAdjustmentsForm } from '@portal/store/dist/payable/types';

import { ReceivableActionType } from '@/store/receivable/actionTypes';

import type { IsomorphicRequestPayload } from '../types';

export const initReceivableDetailPage = createAction(
  ReceivableActionType.INIT_DETAIL_PAGE
)<{ id: number }>();

export const receivableRequestAction = createAsyncAction(
  ReceivableActionType.GET_ALL_REQUEST,
  ReceivableActionType.GET_ALL_SUCCESS,
  ReceivableActionType.GET_ALL_FAILURE
)<IsomorphicRequestPayload, TempReceivableSummary[], Error>();

export const receivableByIdRequestAction = createAsyncAction(
  ReceivableActionType.GET_BY_ID_REQUEST,
  ReceivableActionType.GET_BY_ID_SUCCESS,
  ReceivableActionType.GET_BY_ID_FAILURE
)<{ id: number } & IsomorphicRequestPayload, PortalLedgerItem, Error>();

export const receivableAdjustmentsRequestAction = createAsyncAction(
  ReceivableActionType.GET_ALL_ADJUSTMENTS_REQUEST,
  ReceivableActionType.GET_ALL_ADJUSTMENTS_SUCCESS,
  ReceivableActionType.GET_ALL_ADJUSTMENTS_FAILURE
)<IsomorphicRequestPayload, AccountAdjustment[], Error>();

export const receivableAdjustmentDeleteAction = createAsyncAction(
  ReceivableActionType.DELETE_ADJUSTMENT_REQUEST,
  ReceivableActionType.DELETE_ADJUSTMENT_SUCCESS,
  ReceivableActionType.DELETE_ADJUSTMENT_FAILURE
)<{ id: number } & IsomorphicRequestPayload, any, Error>();

export const regenerateLedgerItemsRequestAction = createAsyncAction(
  ReceivableActionType.REGENERATE_LEDGER_ITEMS_REQUEST,
  ReceivableActionType.REGENERATE_LEDGER_ITEMS_SUCCESS,
  ReceivableActionType.REGENERATE_LEDGER_ITEMS_FAILURE
)<
  LedgerItemRecreationBatchCreateData & IsomorphicRequestPayload,
  LedgerItemRecreationBatch,
  Error
>();

export type ToggleAdjustmentsFormPayload = Omit<
  AccountAddAdjustmentsForm,
  'adjustments'
>;
export const toggleAddAdjustmentsForm = createAction(
  ReceivableActionType.TOGGLE_ADD_ADJUSTMENTS_FORM
)<ToggleAdjustmentsFormPayload>();

export const updateAddAdjustmentsForm = createAction(
  ReceivableActionType.UPDATE_ADD_ADJUSTMENTS_FORM
)<AccountAddAdjustmentsForm>();

export const receivableAddAdjustmentAction = createAsyncAction(
  ReceivableActionType.ADD_ADJUSTMENT_REQUEST,
  ReceivableActionType.ADD_ADJUSTMENT_SUCCESS,
  ReceivableActionType.ADD_ADJUSTMENT_FAILURE
)<
  AccountAddAdjustmentsForm & IsomorphicRequestPayload,
  AccountAdjustment[],
  Error
>();

export const receivableEventsByIdRequestAction = createAsyncAction(
  ReceivableActionType.GET_EVENTS_BY_ID_REQUEST,
  ReceivableActionType.GET_EVENTS_BY_ID_SUCCESS,
  ReceivableActionType.GET_EVENTS_BY_ID_FAILURE
)<{ id: number } & IsomorphicRequestPayload, LedgerItemEvent[], Error>();
