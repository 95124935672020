import * as React from 'react';

import { faCircleDollar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import numeral from 'numeral';

import type { TopTotalsPanelItem } from '@/components/reporting/top-totals-panel';

export const commonEarningsPanelConfig: TopTotalsPanelItem[] = [
  {
    formatter: (value) => numeral(value).format('$0,0.00'),
    icon: <FontAwesomeIcon icon={faCircleDollar} />,
    key: 'totalPayout',
    title: 'Total Earnings',
    tooltip:
      'This amount reflects the sum of new payouts for your integration over the selected date range. Invoices are snapshots of what you get paid out in a period and may fluctuate as Financial Institutions correct their reporting. All future invoices will reflect the difference over time based on corrections to payout reporting from Financial Institutions.',
  },
];
