"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMostRecentMciReports = void 0;
const luxon_1 = require("luxon");
const getMostRecentMciReports = (mciReports) => mciReports
    .map((mciReport) => (Object.assign(Object.assign({}, mciReport), { dateEnd: luxon_1.DateTime.fromISO(mciReport.dateEnd).toFormat('yyyy-LL-dd'), dateStart: luxon_1.DateTime.fromISO(mciReport.dateStart).toFormat('yyyy-LL-dd') })))
    .sort((a, b) => a.dateEnd === b.dateEnd
    ? a.subAccountName.localeCompare(b.subAccountName)
    : b.dateEnd.localeCompare(a.dateEnd))
    .slice(0, 3);
exports.getMostRecentMciReports = getMostRecentMciReports;
