import { createWrapper } from 'next-redux-wrapper';
import type { AnyAction, Store } from 'redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { composeWithDevTools as composeWithDevToolsDevOnly } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import type { StateType } from 'typesafe-actions';

import {
  cmsReducer,
  demandReportingReducer,
  demandReportingSaga,
  legalAgreementSaga,
  originatorExternalReducer,
  originatorExternalSaga,
  portalWalkthroughSaga,
} from '@portal/store/dist';
import { accountReducer as AccountsReducer } from '@portal/store/dist/account/reducer';
import { accountSaga } from '@portal/store/dist/account/sagas';
import type { AccountState } from '@portal/store/dist/account/types';
import { analyticsReducer as AnalyticsReducer } from '@portal/store/dist/analytics';
import { analyticsSaga } from '@portal/store/dist/analytics/sagas';
import type { AnalyticsState } from '@portal/store/dist/analytics/types';
import {
  configurationReducer as ConfigReducer,
  initialConfigState,
} from '@portal/store/dist/configuration';
import type { ConfigState } from '@portal/store/dist/configuration/types';
import { featureFlagReducer as FeatureFlagReducer } from '@portal/store/dist/feature-flag/reducer';
import { featureFlagSaga } from '@portal/store/dist/feature-flag/sagas';
import {
  feedbackReducer as FeedbackReducer,
  feedbackSaga,
} from '@portal/store/dist/feedback';
import type { FeedbackState } from '@portal/store/dist/feedback/types';
import { leadReducer as LeadReducer, leadSaga } from '@portal/store/dist/lead';
import type { LeadState } from '@portal/store/dist/lead/types';
import {
  leadHydrationReducer as LeadHydrationReducer,
  leadHydrationSaga,
} from '@portal/store/dist/lead-hydration';
import { loadingReducer } from '@portal/store/dist/loading';
import { notificationReducer as NotificationReducer } from '@portal/store/dist/notifications';
import type { NotificationState } from '@portal/store/dist/notifications';
import { notificationsSagas } from '@portal/store/dist/notifications/sagas';
import {
  onboardingContractReducer as OnboardingContractReducer,
  onboardingContractSaga,
} from '@portal/store/dist/onboarding-contract';
import type { OnboardingContractState } from '@portal/store/dist/onboarding-contract/types';
import {
  payableReducer as PayablesReducer,
  payableSaga,
} from '@portal/store/dist/payable';
import type { PayableState } from '@portal/store/dist/payable/types';
import {
  pricingControlsReducer as PricingControlsReducer,
  pricingControlsSaga,
} from '@portal/store/dist/pricing-controls';
import type { PricingControlsState } from '@portal/store/dist/pricing-controls/types';
import {
  tutorialsReducer as TutorialsReducer,
  tutorialsSaga,
} from '@portal/store/dist/tutorials';
import type { TutorialsState } from '@portal/store/dist/tutorials/types';
import type {
  CmsState,
  DemandReportingState,
  FeatureFlagState,
  LeadHydrationState,
  LoadingState,
  OriginatorExternalState,
  SupplyMigrationState,
} from '@portal/store/dist/types';
import { userReducer as UserReducer, usersSaga } from '@portal/store/dist/user';
import type { UserState } from '@portal/store/dist/user/types';
import { whitelistReducer as WhitelistReducer } from '@portal/store/dist/whitelist';
import type { WhitelistState } from '@portal/store/dist/whitelist';
import { whitelistSaga } from '@portal/store/dist/whitelist/sagas';

import { Router } from '@/routes';
import {
  authenticationReducer,
  authenticationSaga,
} from '@/store/authentication';
import type { AuthenticationState } from '@/store/authentication';
import { complianceSaga } from '@/store/compliance/sagas';
import type { ComplianceState } from '@/store/compliance/types';
import { dataImportsSaga } from '@/store/data-imports/sagas';
import type { DataImportsState } from '@/store/data-imports/types';
import { homeSaga } from '@/store/home/sagas';
import { importedChannelEventsSaga } from '@/store/imported-channel-events/sagas';
import type { ImportedChannelEventsState } from '@/store/imported-channel-events/types';
import { offerRulesSaga } from '@/store/originator/sagas/offer-rules';
import { rateTablesSaga } from '@/store/originator/sagas/rate-tables';
import { supplyTemplateSaga } from '@/store/originator/sagas/supply-template';
import { supplyTemplateApplicationsSaga } from '@/store/originator/sagas/supply-template-application';
import { initializeTemplateCenterSaga } from '@/store/pages/template-center/sagas';
import { userPagesSaga } from '@/store/pages/users/sagas';
import { premiumLendersReducer } from '@/store/premium-lenders/reducer';
import { premiumLendersSaga } from '@/store/premium-lenders/sagas';
import type { PremiumLendersState } from '@/store/premium-lenders/types';
import { reducer as PreSelectRulesReducer } from '@/store/preselect-rule/reducer';
import { preSelectRuleSaga } from '@/store/preselect-rule/sagas';
import type { PreSelectRuleState } from '@/store/preselect-rule/types';
import { reducer as SaasReducer } from '@/store/saas/reducer';
import { saasSaga } from '@/store/saas/sagas';
import type { SaasState } from '@/store/saas/types';
import { sagaDispatcherPipeSaga } from '@/store/saga-dispatcher-flow';
import { LoggerFactory } from '@/utils/logger';

import { getEnv } from '../envWrapper';
import { cmsPublicRestClientFactoryFn } from '../utils/rest-client-factory';

import { reducer as BillingCycleReducer } from './billing-cycle/reducer';
import { billingCycleSaga } from './billing-cycle/sagas';
import type { BillingCycleState } from './billing-cycle/types';
import { reducer as ComplianceReducer } from './compliance/reducer';
import { reducer as DataImportsReducer } from './data-imports/reducer';
import { reducer as DemandConfigurationsReducer } from './demand-configurations/reducer';
import { demandConfigurationsSaga } from './demand-configurations/sagas';
import type { DemandConfigurationsState } from './demand-configurations/types';
import { demandReducer as DemandContractsReducer } from './demand-contracts/reducer';
import { demandContractsSaga } from './demand-contracts/sagas';
import type { DemandContractsState } from './demand-contracts/types';
import { reducer as ExpectedEarningsShareReducer } from './expected-earnings-share/reducer';
import { expectedEarningsShareSaga } from './expected-earnings-share/sagas';
import type { ExpectedEarningsShareState } from './expected-earnings-share/types';
import { reducer as ImportedChannelEventsReducer } from './imported-channel-events/reducer';
import { reducer as ImportedLeadEventsReducer } from './imported-lead-events/reducer';
import { importedLeadEventsSaga } from './imported-lead-events/sagas';
import type { ImportedLeadEventsState } from './imported-lead-events/types';
import { interfaceReducer as InterfaceReducer } from './interface/reducer';
import type { InterfaceState } from './interface/reducer';
import { reducer as MessageReducer } from './message/reducer';
import { messageSaga } from './message/sagas';
import type { MessageState } from './message/types';
import { reducer as OffersReducer } from './offers/reducer';
import { offerSaga } from './offers/sagas';
import type { OfferState } from './offers/types';
import { reducer as OriginatorApiReducer } from './originator/reducer';
import { originatorSaga } from './originator/sagas';
import type { OriginatorState } from './originator/types';
import { reducer as PartnerPageReducer } from './partner-page/reducer';
import { partnerPageSaga } from './partner-page/sagas';
import type { PartnerPageState } from './partner-page/types';
import { reducer as PerformanceSummariesReducer } from './performance-summary/reducer';
import { performanceSummariesSaga } from './performance-summary/sagas';
import type { PerformanceSummariesState } from './performance-summary/types';
import { receivableReducer as ReceivablesReducer } from './receivable/reducer';
import { receivableSaga } from './receivable/sagas';
import type { ReceivableState } from './receivable/types';
import { reducer as SubAccountsReducer } from './subaccount/reducer';
import { subAccountSaga } from './subaccount/sagas';
import type { SubAccountState } from './subaccount/types';
import { reducer as SupplyContractsReducer } from './supply-contracts/reducer';
import { supplyContractsSaga } from './supply-contracts/sagas';
import type { SupplyContractsState } from './supply-contracts/types';
import { reducer as SupplyMigrationReducer } from './supply-migration/reducer';
import { supplyMigrationSaga } from './supply-migration/sagas';
import { reducer as TrustedPartnersReducer } from './trusted-partners/reducer';
import { trustedPartnersSaga } from './trusted-partners/sagas';
import type { TrustedPartnersState } from './trusted-partners/types';
import type { StoreWithSagaTask } from './types';

export interface ApplicationState {
  accounts: AccountState;
  analytics: AnalyticsState;
  authentication: AuthenticationState;
  billingCycles: BillingCycleState;
  cms: CmsState;
  compliance: ComplianceState;
  config: ConfigState;
  dataImports: DataImportsState;
  demandConfigurations: DemandConfigurationsState;
  demandContracts: DemandContractsState;
  demandReporting: DemandReportingState;
  expectedEarningsShare: ExpectedEarningsShareState;
  featureFlags: FeatureFlagState;
  feedback: FeedbackState;
  importedChannelEvents: ImportedChannelEventsState;
  importedLeadEvents: ImportedLeadEventsState;
  interface: InterfaceState;
  lead: LeadState;
  leadHydration: LeadHydrationState;
  loading: LoadingState;
  message: MessageState;
  notifications: NotificationState;
  offers: OfferState;
  onboardingContract: OnboardingContractState;
  originator: OriginatorState;
  originatorExternal: OriginatorExternalState;
  partnerPages: PartnerPageState;
  payables: PayableState;
  performanceSummaries: PerformanceSummariesState;
  preSelectRules: PreSelectRuleState;
  premiumLenders: PremiumLendersState;
  pricingControls: PricingControlsState;
  receivables: ReceivableState;
  saas: SaasState;
  subAccounts: SubAccountState;
  supplyContracts: SupplyContractsState;
  supplyMigration: SupplyMigrationState;
  trustedPartners: TrustedPartnersState;
  tutorials: TutorialsState;
  user: UserState;
  whitelist: WhitelistState;
}

// The root reducer is composed of the reducer(s) of each individual store.
const rootReducer = combineReducers<ApplicationState>({
  accounts: AccountsReducer,
  analytics: AnalyticsReducer,
  authentication: authenticationReducer,
  billingCycles: BillingCycleReducer,
  cms: cmsReducer,
  compliance: ComplianceReducer,
  config: ConfigReducer,
  dataImports: DataImportsReducer,
  demandConfigurations: DemandConfigurationsReducer,
  demandContracts: DemandContractsReducer,
  demandReporting: demandReportingReducer,
  expectedEarningsShare: ExpectedEarningsShareReducer,
  featureFlags: FeatureFlagReducer,
  feedback: FeedbackReducer,
  importedChannelEvents: ImportedChannelEventsReducer,
  importedLeadEvents: ImportedLeadEventsReducer,
  interface: InterfaceReducer,
  lead: LeadReducer,
  leadHydration: LeadHydrationReducer,
  loading: loadingReducer,
  message: MessageReducer,
  notifications: NotificationReducer,
  offers: OffersReducer,
  onboardingContract: OnboardingContractReducer,
  originator: OriginatorApiReducer,
  originatorExternal: originatorExternalReducer,
  partnerPages: PartnerPageReducer,
  payables: PayablesReducer,
  performanceSummaries: PerformanceSummariesReducer,
  preSelectRules: PreSelectRulesReducer,
  premiumLenders: premiumLendersReducer,
  pricingControls: PricingControlsReducer,
  receivables: ReceivablesReducer,
  saas: SaasReducer,
  subAccounts: SubAccountsReducer,
  supplyContracts: SupplyContractsReducer,
  supplyMigration: SupplyMigrationReducer,
  trustedPartners: TrustedPartnersReducer,
  tutorials: TutorialsReducer,
  user: UserReducer,
  whitelist: WhitelistReducer,
});

// We `fork()` these tasks so they execute in the background.
export function* rootSaga() {
  yield all([
    fork(accountSaga),
    fork(analyticsSaga),
    fork(billingCycleSaga),
    fork(complianceSaga),
    fork(dataImportsSaga),
    fork(demandConfigurationsSaga),
    fork(demandContractsSaga),
    fork(expectedEarningsShareSaga),
    fork(featureFlagSaga),
    fork(feedbackSaga),
    fork(importedChannelEventsSaga),
    fork(importedLeadEventsSaga),
    fork(leadHydrationSaga),
    fork(leadSaga),
    fork(messageSaga),
    fork(notificationsSagas),
    fork(offerRulesSaga),
    fork(offerSaga),
    fork(onboardingContractSaga),
    fork(originatorSaga),
    fork(partnerPageSaga),
    fork(payableSaga),
    fork(performanceSummariesSaga),
    fork(portalWalkthroughSaga),
    fork(legalAgreementSaga),
    fork(preSelectRuleSaga),
    fork(pricingControlsSaga),
    fork(rateTablesSaga),
    fork(receivableSaga),
    fork(saasSaga),
    fork(sagaDispatcherPipeSaga),
    fork(subAccountSaga),
    fork(originatorExternalSaga),
    fork(supplyContractsSaga),
    fork(supplyMigrationSaga),
    fork(supplyTemplateApplicationsSaga),
    fork(supplyTemplateSaga),
    fork(trustedPartnersSaga),
    fork(usersSaga),
    fork(whitelistSaga),
    fork(tutorialsSaga),
    fork(premiumLendersSaga),
    fork(userPagesSaga),
    fork(homeSaga),
    fork(demandReportingSaga),
    fork(initializeTemplateCenterSaga),
    fork(authenticationSaga),
  ]);
}

// eslint-disable-next-line import/no-mutable-exports
export let store: Store<ApplicationState, AnyAction>;

// Middleware is any function that matches:
// ({ getState, dispatch }) => next => action
//
// https://redux.js.org/api/applymiddleware

// Enhancer is any function that matches:
// (next: StoreCreator) => StoreCreator
//
// Intuitively: composable store factories that
// you are discouraged from using because you can
// probably achieve your goal by making middleware.
//
// https://redux.js.org/glossary#store-enhancer

// Factory function to create the Redux store, decorate middleware, etc.
// TODO: figure out how to import options interface from pkg

export enum ClientFactoryTypes {
  CMSPublicRest = 'CMSPublicRest',
}

const makeStore = (): StoreWithSagaTask<ApplicationState, AnyAction> => {
  const sagaLogger = LoggerFactory('Saga Logger', initialConfigState);

  const sagaMiddleware = createSagaMiddleware({
    context: {
      [ClientFactoryTypes.CMSPublicRest]: cmsPublicRestClientFactoryFn,
      Router,
    },
    onError: (err) => sagaLogger.error(err),
  });

  const middlewareEnhancer = applyMiddleware(sagaMiddleware);

  const composeEnhancersProd = composeWithDevTools;
  const composeEnhancersDev = composeWithDevToolsDevOnly({
    trace: true,
    traceLimit: 25,
  });

  /*
    We discovered that the Dev version of Redux Dev Tools works on localhost,
    but not the Dev Env. I'm not sure what Env Var (if any) can be used to
    distinguish between "dev" (localhost) and "dev" (controls-dev). I did,
    however see that we set AUTH_COOKIE_DOMAIN to 'localhost' when developing
    locally. I'm using this temporarily so that Redux Dev Tools will work
    properly on Dev, Stage, QA, and Prod and continue to work properly on
    localhost. The commented out version of "enhancers" should ultimately
    be used, but with something like:

    getEnv().IS_PRODUCTION_ENV || getEnv().IS_DEV_ENV

    // const enhancers = getEnv().IS_PRODUCTION_ENV
    //   ? composeEnhancersProd(middlewareEnhancer)
    //   : composeEnhancersDev(middlewareEnhancer);

  */

  const enhancers =
    getEnv().AUTH_COOKIE_DOMAIN === 'localhost'
      ? composeEnhancersDev(middlewareEnhancer)
      : composeEnhancersProd(middlewareEnhancer);

  store = createStore(rootReducer, enhancers);
  const rootSagaTask = sagaMiddleware.run(rootSaga);

  return { ...store, rootSagaTask };
};

export type RootState = StateType<typeof rootReducer>;

export const wrapper = createWrapper<ApplicationState>(makeStore);

export default makeStore;
