"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getActiveDemandContract = exports.getRevenueClauseTypeDisplayText = exports.isValidRevenueClause = exports.revenueClauseTypeToDisplayText = void 0;
const lodash_1 = require("lodash");
const luxon_1 = require("luxon");
const utils_1 = require("../utils");
const types_1 = require("./types");
const filterInvalid = ({ effectiveAt, deletedAt }) => {
    if (!effectiveAt || deletedAt) {
        return false;
    }
    return true;
};
const sortByEffectiveAt = (a, b) => (0, utils_1.sortISODate)(a.effectiveAt, b.effectiveAt);
exports.revenueClauseTypeToDisplayText = new Map([
    [
        types_1.RevenueClauseType.CostPerCreditCardApproved,
        'Cost Per Credit Card Approved',
    ],
    [types_1.RevenueClauseType.CostPerCreditCardOpened, 'Cost Per Credit Card Opened'],
    [types_1.RevenueClauseType.CostPerCreditCardFunded, 'Cost Per Credit Card Funded'],
    [types_1.RevenueClauseType.CostPerLoanApproved, 'Cost Per Loan Approved'],
    [types_1.RevenueClauseType.CostPerFundedLoan, 'Cost Per Funded Loan'],
    [
        types_1.RevenueClauseType.CostPerLoanFundedBySupplySubAccountId,
        'Cost Per Funded Loan by Supply Source',
    ],
    [
        types_1.RevenueClauseType.CostPerLoanLenderQualifiedLead,
        'Cost Per Loan Lender Qualified Lead',
    ],
    [types_1.RevenueClauseType.CostPerOtherConversion, 'Cost Per Other Conversion'],
    [types_1.RevenueClauseType.CostPerSavingsClick, 'Cost Per Savings Click'],
    [types_1.RevenueClauseType.CostPerSavingsFunded, 'Cost Per Savings Funded'],
    [types_1.RevenueClauseType.CostPerSavingsOpened, 'Cost Per Savings Opened'],
    [
        types_1.RevenueClauseType.CostPerSavingsOpenedBySupplySubAccountId,
        'Cost Per Savings Opened by Supply Source',
    ],
    [types_1.RevenueClauseType.CostPerUniqueLoanClick15, 'Cost Per Unique Loan Click 15'],
    [
        types_1.RevenueClauseType.CostPerUniqueOtherClickBySubAccountId,
        'Cost Per Unique Other Click by Sub Account ID',
    ],
    [
        types_1.RevenueClauseType.CostPerUniqueSavingsClick,
        'Cost Per Unique Savings Click',
    ],
    [
        types_1.RevenueClauseType.CostPerUniqueSavingsClickByOfferId,
        'Cost Per Unique Savings Click by Offer ID',
    ],
    [
        types_1.RevenueClauseType.CostPerUniqueSpecialOfferClick,
        'Cost Per Unique Special Offer Click',
    ],
    [
        types_1.RevenueClauseType.CostPerUniqueSpecialOfferClickWithDynamicPayout,
        'Cost Per Unique Special Offer Click With Dynamic Payout',
    ],
    [
        types_1.RevenueClauseType.LifeInsurancePolicyPayments,
        'Life Insurance Policy Payments',
    ],
    [types_1.RevenueClauseType.OtherRevenue, 'Other Revenue'],
    [
        types_1.RevenueClauseType.PercentGrossFundedLoanAmount,
        'Percent of Gross Funded Loan Amount',
    ],
    [
        types_1.RevenueClauseType.PercentGrossFundedLoanAmountBySupply,
        'Percent of Gross Funded Loan Amount by Supply Source',
    ],
    [
        types_1.RevenueClauseType.PercentGrossFundedLoanAmountFlatFeeThreshold,
        'Percent of Gross Funded Loan Amount With Flat Fee Threshold',
    ],
    [types_1.RevenueClauseType.PercentOriginationFee, 'Percent of Origination Fee'],
    [
        types_1.RevenueClauseType.PercentFundedLoanAmountByFundedLoanAmountTier,
        'Percent of Funded Loan Amount by Size of Loan',
    ],
    [
        types_1.RevenueClauseType.PercentFundedLoanAmountWithThresholdMarginalPercentage,
        'Percent of Funded Loan Amount With Threshold Marginal Percentage',
    ],
    [
        types_1.RevenueClauseType.PercentOtherConversionAmount,
        'Percent of Special Offer Conversion Amount',
    ],
    [types_1.RevenueClauseType.SavingsOfferClickPayout, 'Savings Offer Click Payout'],
]);
const isValidRevenueClause = (key) => Object.values(types_1.RevenueClauseType).includes(key);
exports.isValidRevenueClause = isValidRevenueClause;
const getRevenueClauseTypeDisplayText = (key) => {
    var _a;
    if ((0, exports.isValidRevenueClause)(key)) {
        return (_a = exports.revenueClauseTypeToDisplayText.get(key)) !== null && _a !== void 0 ? _a : '';
    }
    return (0, lodash_1.startCase)(key);
};
exports.getRevenueClauseTypeDisplayText = getRevenueClauseTypeDisplayText;
const getActiveDemandContract = (demandContracts) => {
    return demandContracts
        .filter(filterInvalid)
        .filter(({ effectiveAt }) => luxon_1.DateTime.fromISO(effectiveAt) <= luxon_1.DateTime.now())
        .sort(sortByEffectiveAt)
        .shift();
};
exports.getActiveDemandContract = getActiveDemandContract;
