"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderedSubAccountProductTypeArray = void 0;
const finance_client_1 = require("@evenfinancial/finance-client");
exports.OrderedSubAccountProductTypeArray = [
    finance_client_1.ProductType.Loan,
    finance_client_1.ProductType.CreditCard,
    finance_client_1.ProductType.Insurance,
    finance_client_1.ProductType.LifeInsurance,
    finance_client_1.ProductType.Mortgage,
    finance_client_1.ProductType.Savings,
    finance_client_1.ProductType.Other,
];
