import { IntegrationType } from '@evenfinancial/auth-client';

export enum IntegrationCategory {
  API = 'API',
  Embed = 'Embed',
  PartnerPage = 'Partner Page',
  Unknown = 'Unknown',
}

export const getIntegrationCategory = (integrationType?: string) => {
  switch (integrationType) {
    case IntegrationType.ApiCherryPick:
    case IntegrationType.ApiMobile:
    case IntegrationType.ApiOfferWall:
      return IntegrationCategory.API;

    case IntegrationType.MpsEmbedEven:
    case IntegrationType.MpsEmbedFiona:
    case IntegrationType.ThreeStepMpsEmbedEven:
    case IntegrationType.ThreeStepMpsEmbedFiona:
      return IntegrationCategory.Embed;

    case IntegrationType.MpsPartnerPageEven:
    case IntegrationType.MpsPartnerPageFiona:
      return IntegrationCategory.PartnerPage;

    default:
      return IntegrationCategory.Unknown;
  }
};
