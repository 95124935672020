"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nonLegacyPayoutClauses = exports.legacyPayoutClauseSet = exports.PayoutClauseType = void 0;
var PayoutClauseType;
(function (PayoutClauseType) {
    PayoutClauseType["Bidder"] = "bidder";
    PayoutClauseType["BidderDemandExclusions"] = "bidder_demand_exclusions";
    PayoutClauseType["CostPerCompletedApplication"] = "cost_per_completed_application";
    PayoutClauseType["CostPerCompletedApplication60ByCreditRating"] = "cost_per_completed_application_60_by_credit_rating";
    PayoutClauseType["CostPerCompletedApplicationByCreditRating"] = "cost_per_completed_application_by_credit_rating";
    PayoutClauseType["CostPerCompletedApplicationByCreditRatingAndDeviceType"] = "cost_per_completed_application_by_credit_rating_and_device_type";
    PayoutClauseType["CostPerCompletedApplicationBySubId"] = "cost_per_completed_application_by_sub_id";
    PayoutClauseType["CostPerLead"] = "cost_per_lead";
    PayoutClauseType["CostPerLeadByCreditRating"] = "cost_per_lead_by_credit_rating";
    PayoutClauseType["CostPerLeadByCreditRatingAndDeviceType"] = "cost_per_lead_by_credit_rating_and_device_type";
    PayoutClauseType["CostPerLeadByCreditRatingBySubId"] = "cost_per_lead_by_credit_rating_by_sub_id";
    PayoutClauseType["CostPerLeadByDeviceType"] = "cost_per_lead_by_device_type";
    PayoutClauseType["CostPerLeadByLifeInsuranceApprovalProbabilityScore"] = "cost_per_lead_by_life_insurance_approval_probability_score";
    PayoutClauseType["CostPerLeadBySubId"] = "cost_per_lead_by_sub_id";
    PayoutClauseType["CostPerLeadByVantageScore"] = "cost_per_lead_by_vantage_score";
    PayoutClauseType["CostPerLifeInsuranceApplied"] = "cost_per_life_insurance/applied";
    PayoutClauseType["CostPerPreSelectedCreditCardOfferByDemand"] = "cost_per_pre_selected_credit_card_offer_by_demand";
    PayoutClauseType["CostPerUniqueFundedLoan"] = "cost_per_unique_funded_loan";
    PayoutClauseType["CostPerUniqueFundedLoanBySubId"] = "cost_per_unique_funded_loan_by_sub_id";
    PayoutClauseType["CostPerUniqueLoanClick"] = "cost_per_unique_loan_click";
    PayoutClauseType["CostPerUniqueLoanClickByDemand"] = "cost_per_unique_loan_click_by_demand";
    PayoutClauseType["CostPerUniqueLoanClickBySubId"] = "cost_per_unique_loan_click_by_sub_id";
    PayoutClauseType["CostPerUniqueOtherClick"] = "cost_per_unique_other_click";
    PayoutClauseType["CostPerUniqueOtherClickByOfferId"] = "cost_per_unique_other_click_by_offer_id";
    PayoutClauseType["CostPerUniqueSavingsClick"] = "cost_per_unique_savings_click";
    PayoutClauseType["EmailCampaign"] = "email_campaign";
    PayoutClauseType["EvenOwned"] = "even_owned";
    PayoutClauseType["GenericRevenueShare"] = "revenue_share_generic";
    PayoutClauseType["LifeInsurancePayout"] = "life_insurance_payout";
    PayoutClauseType["MediaBuy"] = "media_buy";
    PayoutClauseType["PercentOfFundedLoanAmount"] = "percent_of_funded_loan_amount";
    PayoutClauseType["RevenueShareCreditCard"] = "revenue_share_credit_card";
    PayoutClauseType["RevenueShareInsurance"] = "revenue_share_insurance";
    PayoutClauseType["RevenueShareLoan"] = "revenue_share_loan";
    PayoutClauseType["RevenueShareLoanByDemand"] = "revenue_share_loan_by_demand";
    PayoutClauseType["RevenueShareMortgage"] = "revenue_share_mortgage";
    PayoutClauseType["RevenueShareOther"] = "revenue_share_other";
    PayoutClauseType["RevenueShareSavings"] = "revenue_share_savings";
})(PayoutClauseType = exports.PayoutClauseType || (exports.PayoutClauseType = {}));
exports.legacyPayoutClauseSet = new Set([
    PayoutClauseType.CostPerLeadByLifeInsuranceApprovalProbabilityScore,
    PayoutClauseType.CostPerLifeInsuranceApplied,
    PayoutClauseType.CostPerPreSelectedCreditCardOfferByDemand,
    PayoutClauseType.CostPerUniqueFundedLoanBySubId,
    PayoutClauseType.CostPerUniqueLoanClickByDemand,
    PayoutClauseType.CostPerUniqueLoanClickBySubId,
    PayoutClauseType.CostPerUniqueOtherClickByOfferId,
    PayoutClauseType.EmailCampaign,
    PayoutClauseType.EvenOwned,
    PayoutClauseType.LifeInsurancePayout,
    PayoutClauseType.MediaBuy,
    PayoutClauseType.PercentOfFundedLoanAmount,
]);
exports.nonLegacyPayoutClauses = Object.values(PayoutClauseType)
    .filter((value) => !exports.legacyPayoutClauseSet.has(value))
    .sort();
