import * as React from 'react';
import { Component } from 'react';

import { Select } from 'antd';
import { startCase } from 'lodash';

import { ProductType } from '@evenfinancial/finance-client';

export interface SubAccountProductTypeDropdownProvidedProps {
  defaultValue?: string | string[];
  disabled?: boolean;
  multipleselectmode?: boolean;
  onChange?: (
    value: string | string[],
    option: React.ReactNode | React.ReactNode[]
  ) => void;
  placeholder?: string;
  value?: string | string[];
}

export class SubAccountProductTypeDropdown extends Component<SubAccountProductTypeDropdownProvidedProps> {
  constructor(props: SubAccountProductTypeDropdownProvidedProps) {
    super(props);
  }

  public render() {
    const {
      value,
      onChange,
      placeholder,
      multipleselectmode,
      defaultValue,
      disabled,
    } = this.props;

    return (
      <Select
        allowClear
        filterOption
        showSearch
        defaultValue={defaultValue}
        disabled={disabled}
        getPopupContainer={(triggerNode) =>
          triggerNode.parentNode as HTMLElement
        }
        mode={multipleselectmode ? 'multiple' : undefined}
        optionFilterProp="children"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      >
        {this.accountRows}
      </Select>
    );
  }

  private get accountRows(): JSX.Element[] {
    return Object.values(ProductType).map((productType) => (
      <Select.Option key={`dsa-c-${productType}`} value={productType}>
        {startCase(productType)}
      </Select.Option>
    ));
  }
}

export default SubAccountProductTypeDropdown;
