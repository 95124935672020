import type { Dispatch } from 'react';
import * as React from 'react';

import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Router from 'next/router';
import type { Action } from 'typesafe-actions';

import { ComplianceStatus, IntegrationStatus } from '@portal/api-models';
import { FinancialPartnerStatus } from '@portal/common/dist/subaccount';
import { StatusDot, StatusToIntent } from '@portal/ui-lib';

import { ImplementationFeeWaived } from '@/components/self-service/implementation-fee-waived';
import PublishIntegrationInstructions from '@/components/self-service/integrations/publish-integration-instructions';
import type { PortalStep } from '@/components/steps';
import { getComplianceStatusLabel } from '@/components/table/compliance-columns';
import { ImplementationFeePaymentStatus } from '@/resources/integrations/types';
import type { Integration } from '@/resources/integrations/types';
import { GettingStartedBoxSteps } from '@/resources/self-service/getting-started/getting-started-box-steps';
import { isFeePaymentPreApprovalRequired } from '@/resources/self-service/payment/util-methods';
import * as partnerPageAction from '@/store/partner-page/actions';
import { addParamToQuery } from '@/utils/router';

export const getAfterPlanPurchasedActiveStep = (integration: Integration) => {
  if (
    integration.complianceStatus !== ComplianceStatus.closed &&
    isFeePaymentPreApprovalRequired()
  ) {
    return GettingStartedBoxSteps.COMPLIANCE_REVIEW;
  }

  if (
    integration.implementationFeePaymentStatus !==
      ImplementationFeePaymentStatus.Paid &&
    integration.implementationFeePaymentStatus !==
      ImplementationFeePaymentStatus.Waived
  ) {
    return GettingStartedBoxSteps.PAY_IMPLEMENTATION_FEE;
  }
};

export const getAfterPlanPurchasedSteps = (
  integration: Integration,
  activeStep: GettingStartedBoxSteps | undefined
): PortalStep[] => {
  const steps: PortalStep[] = [];

  const isCompliancePending =
    integration.complianceStatus !== ComplianceStatus.closed;

  steps.push({
    alwaysShowAction: true,
    // display only if status is pending review
    customActionContent: isCompliancePending ? (
      <StatusDot
        className="margin-none"
        status={StatusToIntent[FinancialPartnerStatus.PendingComplianceReview]}
      >
        {getComplianceStatusLabel(ComplianceStatus.internalReview)}
      </StatusDot>
    ) : undefined,

    description:
      'Even is reviewing your partner page and will provide approval in one to three days',

    hideTail: true,

    icon: <FontAwesomeIcon icon={faCheck} />,

    id: GettingStartedBoxSteps.COMPLIANCE_REVIEW.toString(),

    title: 'Pending Compliance',

    titleCompleted: isCompliancePending
      ? 'Pending Compliance'
      : 'Compliance approved',
  });

  if (
    integration.implementationFeePaymentStatus !==
      ImplementationFeePaymentStatus.Paid &&
    integration.implementationFeePaymentStatus !==
      ImplementationFeePaymentStatus.Waived
  ) {
    steps.push({
      action: {
        onClick: () => {
          Router.push(`/implementation-fee?id=${integration.id}`);
        },
        title: 'Pay now',
      },
      alwaysShowAction: true,
      description: 'Submit your partner page for review',
      icon: <FontAwesomeIcon icon={faCheck} />,
      id: GettingStartedBoxSteps.PAY_IMPLEMENTATION_FEE.toString(),
      title: 'Pay Your Implementation Fee',
    });
  }

  if (
    integration.implementationFeePaymentStatus ===
    ImplementationFeePaymentStatus.Waived
  ) {
    steps.push({
      alwaysShowAction: true,
      customActionContent: <ImplementationFeeWaived />,
      description: 'Submit your partner page for review',
      icon: <FontAwesomeIcon icon={faCheck} />,
      id: GettingStartedBoxSteps.IMPLEMENTATION_FEE_WAIVED.toString(),
      title: 'Implementation Fee Waived',
    });
  }

  steps.push({
    action: {
      confirmable: {
        cancelText: 'Cancel',
        message:
          'Your Partner page will be live on Even network as soon as click on Confirm.',
        okText: 'Confirm',
        title: 'Ready to go live?',
      },
      onClick: (dispatch: Dispatch<Action>) => {
        dispatch(
          partnerPageAction.partnerKeyUpdateIntegrationStatusAction.request({
            id: integration.id,
            key: integration.partner_key,
            redirectPath:
              window.location.pathname +
              addParamToQuery(window.location.search, 'publishSuccess', true),
            status: IntegrationStatus.Live,
          })
        );
      },
      title: 'Publish',
    },
    description:
      activeStep === GettingStartedBoxSteps.PUBLISH_PARTNER_PAGE ? (
        <>
          <PublishIntegrationInstructions
            integrationUrl={integration.integrationUrl}
          />
        </>
      ) : (
        'Send users to your partner page by adding a hyperlink or CTA button that links out to the URL'
      ),
    icon: <FontAwesomeIcon icon={faCheck} />,
    id: GettingStartedBoxSteps.PUBLISH_PARTNER_PAGE.toString(),
    title: 'Implement Your Partner Page',
  });

  return steps;
};
