"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.leadReducer = exports.leadInitialState = void 0;
var immer_1 = require("immer");
var typesafe_actions_1 = require("typesafe-actions");
var hydrate_1 = require("../hydrate");
var utils_1 = require("../utils");
var actions_1 = require("./actions");
exports.leadInitialState = {
    leads: {
        byUuid: {},
        error: false,
        loading: false,
        rows: [],
    },
    summaries: {
        error: false,
        loading: false,
        rows: [],
    },
};
exports.leadReducer = (0, typesafe_actions_1.createReducer)(exports.leadInitialState)
    .handleAction(hydrate_1.nextHydrate, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draft) {
        draft.leads = payload.lead.leads;
        draft.summaries = payload.lead.summaries;
    });
})
    .handleAction(actions_1.getLeadsAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draft) {
        draft.leads.rows = payload;
    });
})
    .handleAction(actions_1.getLeadByUuidAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draft) {
        var _a;
        draft.leads.byUuid = __assign(__assign({}, state.leads.byUuid), (_a = {}, _a[payload.uuid] = payload.lead, _a));
    });
})
    .handleAction(actions_1.getLeadSummariesAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draft) {
        draft.summaries.rows = payload;
    });
})
    .handleAction(actions_1.getLeadSummaryByUuidAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draft) {
        draft.summaries.rows = (0, utils_1.replaceByUuid)(state.summaries.rows, [
            payload,
        ]);
    });
})
    .handleAction(actions_1.leadsUtilStateChangeAction, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draft) {
        draft.leads.loading = payload.loading;
        draft.leads.error = payload.error;
    });
})
    .handleAction(actions_1.leadSummariesUtilStateChangeAction, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draft) {
        draft.summaries.loading = payload.loading;
        draft.summaries.error = payload.error;
    });
});
