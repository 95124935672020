import * as React from 'react';

import { useOktaAuth } from '@okta/okta-react';

const isClient = typeof window !== 'undefined';

/**
 * If user is authenticated, do not render children and sign out.
 *
 * This is useful in cases where sign out can not be initiated
 * using oktaAuth.signOut() and we must rely on hitting the endpoint.
 */
export const SignOutGuard: React.FC = ({ children }) => {
  const [showChildren, setShowChildren] = React.useState(false);
  const { oktaAuth } = useOktaAuth();

  const signOutIfAuthenticated = async () => {
    const isAuthenticated = await oktaAuth.isAuthenticated();

    if (isAuthenticated) {
      oktaAuth.signOut({
        postLogoutRedirectUri: isClient ? `${window.location.href}` : '',
      });
    } else {
      setShowChildren(true);
    }
  };

  React.useEffect(() => {
    signOutIfAuthenticated();
  }, []);

  if (!showChildren) {
    return null;
  }

  return <>{children}</>;
};
