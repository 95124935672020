import { message } from 'antd';
import type { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import type { ActionType } from 'typesafe-actions';

import type { ExpectedEarningsShare } from '@evenfinancial/finance-client';

import { client } from '@portal/api-client';

import * as actions from './actions';
import { ExpectedEarningsShareActionType } from './types';

function* getExpectedEarningsShareBySubAccountUuid(
  action: ActionType<
    typeof actions.getExpectedEarningsSharesBySubAccountUuidAction.request
  >
) {
  try {
    const { data: response }: AxiosResponse<ExpectedEarningsShare[]> =
      yield call(
        client.get,
        `/finance/expected-earnings-shares/subaccounts/${action.payload.uuid}`
      );

    yield put(
      actions.getExpectedEarningsSharesBySubAccountUuidAction.success({
        response,
        uuid: action.payload.uuid,
      })
    );
  } catch (err: any) {
    yield put(
      actions.getExpectedEarningsSharesBySubAccountUuidAction.failure(err)
    );
  }
}

function* createExpectedEarningsShare(
  action: ActionType<typeof actions.createExpectedEarningsShareAction.request>
) {
  const formParams = action.payload;

  try {
    const newExpectedEarningsShare = yield call(async () =>
      client
        .post('/finance/expected-earnings-shares/', {
          ...formParams,
          share: Number.parseFloat(formParams.share),
        })
        .then((res) => res.data)
    );

    yield put(
      actions.createExpectedEarningsShareAction.success(
        newExpectedEarningsShare
      )
    );

    if (formParams.hideUpdateAlert !== true) {
      setTimeout(
        () =>
          message.success(
            'The expected earnings share was updated successfully'
          ),
        100
      );
    }

    yield put(
      actions.getExpectedEarningsSharesBySubAccountUuidAction.request({
        uuid: action.payload.subAccountUuid,
      })
    );
  } catch (err: any) {
    yield put(actions.createExpectedEarningsShareAction.failure(err));
  }
}

function* getExpectedEarningsShares() {
  try {
    const response: ExpectedEarningsShare[] = yield call(async () => {
      return client
        .get<ExpectedEarningsShare[]>('/finance/expected-earnings-shares')
        .then((res) => res.data);
    });

    yield put(actions.getExpectedEarningsSharesAction.success(response));
  } catch (err: any) {
    yield put(actions.getExpectedEarningsSharesAction.failure(err));
  }
}

function* watchGetBySubAccountUuidRequest() {
  yield takeEvery(
    ExpectedEarningsShareActionType.GET_EXPECTED_EARNINGS_SHARES_BY_SUB_ACCOUNT_ID_REQUEST,
    getExpectedEarningsShareBySubAccountUuid
  );
}

function* watchCreateRequest() {
  yield takeEvery(
    ExpectedEarningsShareActionType.CREATE_EXPECTED_EARNINGS_SHARE_REQUEST,
    createExpectedEarningsShare
  );
}

function* watchGetRequest() {
  yield takeEvery(
    ExpectedEarningsShareActionType.GET_EXPECTED_EARNINGS_SHARES_REQUEST,
    getExpectedEarningsShares
  );
}

export function* expectedEarningsShareSaga() {
  yield all([
    fork(watchGetBySubAccountUuidRequest),
    fork(watchCreateRequest),
    fork(watchGetRequest),
  ]);
}
