import * as React from 'react';

import {
  faHome,
  faReceipt,
  faSquareUser,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';

import { SidebarIcon } from '@portal/ui-lib';

import { RouterAwareMenuItem } from '@/components/side-nav/router-aware-menu-item';

export interface MenuItemProps {
  disabled?: boolean;
}

export const HomeMenuItem = (props: MenuItemProps) => (
  <RouterAwareMenuItem
    key="menu-item-home"
    href="/home"
    icon={<SidebarIcon icon={faHome} />}
    text="Home"
    {...props}
  />
);

export const AccountMenuItem = ({ disabled, ...props }: MenuItemProps) => (
  <RouterAwareMenuItem
    key="menu-item-account"
    disabled={disabled}
    href="/account"
    icon={<SidebarIcon icon={faSquareUser} />}
    showDisabledIcon={disabled}
    text="Account"
    {...props}
  />
);

export const UsersMenuItem = ({ disabled, ...props }: MenuItemProps) => (
  <RouterAwareMenuItem
    key="menu-item-users"
    disabled={disabled}
    href="/users"
    icon={<SidebarIcon icon={faUser} />}
    showDisabledIcon={disabled}
    text="Users"
    {...props}
  />
);

export const InvoicesMenuItem = ({ disabled, ...props }: MenuItemProps) => (
  <RouterAwareMenuItem
    key="menu-item-billing"
    disabled={disabled}
    href="/invoices"
    icon={<SidebarIcon icon={faReceipt} />}
    showDisabledIcon={disabled}
    text="Invoices"
    {...props}
  />
);
