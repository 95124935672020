import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  faReceipt,
  faSquareUser,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { Menu } from 'antd';
import classNames from 'classnames';

import { selectPermissions } from '@portal/store/dist/user/selectors';
import {
  EvenLogo,
  InternalDevelopmentItemGroup,
  InternalOfferCatalogItemGroup,
  InternalOfferManagementItemGroup,
  KitIconNames,
  SidebarIcon,
  useSideNavContext,
} from '@portal/ui-lib';

import { ChannelsSection } from '@/components/side-nav/channels-section';
import { HelpCenterSection } from '@/components/side-nav/help-center-section';
import { RouterAwareMenuItem } from '@/components/side-nav/router-aware-menu-item';

const { SubMenu } = Menu;

export const InternalUserSideNav = () => {
  const { isCollapsed } = useSideNavContext();

  const {
    hasDevToolsFeatureFlag,
    canReadOfferCatalogOffers,
    canReadOfferCatalogProductTypes,
    canManageFeatureFlags,
  } = useSelector(selectPermissions);

  const showOfferCatalogOffersMenuItem = canReadOfferCatalogOffers;

  const showOfferCatalogProductTypesMenuItem = canReadOfferCatalogProductTypes;

  const showFeatureFlagsMenuItem =
    hasDevToolsFeatureFlag && canManageFeatureFlags;

  return (
    <Menu
      className={classNames('portal-sider-menu', {
        'portal-sider-menu-collapsed': isCollapsed,
      })}
      defaultOpenKeys={[]}
      mode="inline"
      selectedKeys={[]}
      theme="dark"
    >
      <Menu.Item
        className={isCollapsed ? 'portal-logo-collapsed' : 'portal-logo'}
      >
        <EvenLogo showLargeLogo={!isCollapsed} />
      </Menu.Item>
      <SubMenu
        key="submenu-accounts"
        className="nav-menu-submenu"
        data-qa="submenu-accounts"
        title={
          <span>
            <SidebarIcon icon={faSquareUser} />
            <span>Accounts</span>
          </span>
        }
      >
        <RouterAwareMenuItem
          key="menu-item-accounts"
          eventKey="accounts"
          href="/accounts"
          text="All Accounts"
        />
        <RouterAwareMenuItem
          key="menu-item-self-serve-accounts"
          eventKey="accounts-self-service"
          href="/analytics/self-service"
          text="Self Serve Accounts"
        />
      </SubMenu>
      <SubMenu
        key="submenu-billing"
        className="nav-menu-submenu"
        data-qa="submenu-billing"
        title={
          <span>
            <SidebarIcon icon={faReceipt} />
            <span>Billing</span>
          </span>
        }
      >
        <RouterAwareMenuItem
          key="submenu-item-payables"
          eventKey="payables"
          href="/billing/payables"
          text="Payables"
        />
        <RouterAwareMenuItem
          key="submenu-item-payable-adjustments"
          eventKey="payable-adjustments"
          href="/billing/payable-adjustments"
          text="Payable Adjustments"
        />
        <RouterAwareMenuItem
          key="submenu-item-receivables"
          eventKey="receivables"
          href="/billing/receivables"
          text="Receivables"
        />
        <RouterAwareMenuItem
          key="submenu-item-receivable-adjustments"
          eventKey="receivable-adjustments"
          href="/billing/receivable-adjustments"
          text="Receivable Adjustments"
        />
        <SubMenu
          key="submenu-menu-tools"
          className="nav-menu-submenu"
          data-qa="submenu-tools"
          title={
            <span>
              <span>Tools</span>
            </span>
          }
        >
          <RouterAwareMenuItem
            key="sub-submenu-item-data-imports"
            eventKey="data-imports"
            href="/billing/tools/data-imports"
            text="Data Imports"
          />
          <RouterAwareMenuItem
            key="sub-submenu-item-imported-lead-events"
            eventKey="imported-lead-events"
            href="/billing/tools/imported-lead-events"
            text="Imported Lead Events"
            tooltipText="Imported Lead Events"
          />
          <RouterAwareMenuItem
            key="sub-submenu-item-imported-channel-events"
            eventKey="imported-channel-events"
            href="/billing/tools/imported-channel-events"
            text="Imported Channel Events"
            tooltipText="Imported Channel Events"
          />
        </SubMenu>
      </SubMenu>
      <RouterAwareMenuItem
        key="menu-item-users"
        eventKey="users"
        href="/users"
        icon={<SidebarIcon icon={faUser} />}
        text="Users"
      />
      <RouterAwareMenuItem
        key="menu-item-leads"
        eventKey="leads"
        href="/leads"
        icon={<SidebarIcon icon={KitIconNames.UserSearchSolid} />}
        text="Leads"
      />

      <ChannelsSection
        showTemplateCenterMenuItem
        sidenavCollapsed={isCollapsed}
      />

      <InternalOfferManagementItemGroup />

      <InternalOfferCatalogItemGroup
        showOffers={showOfferCatalogOffersMenuItem}
        showProductTypes={showOfferCatalogProductTypesMenuItem}
      />

      <InternalDevelopmentItemGroup
        showFeatureFlags={showFeatureFlagsMenuItem}
      />

      <HelpCenterSection />
    </Menu>
  );
};
