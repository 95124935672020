"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProductSubtypeFromPartnerPage = exports.getLoanProductSubtypeFromLoanPurpose = exports.getSupplyTemplateProductTypeFromPartnerPage = exports.getSupplyTemplateProductSubTypeByLoanPurpose = void 0;
const even_ts_static_1 = require("@evenfinancial/even-ts-static");
const originator_client_1 = require("@evenfinancial/originator-client");
const getSupplyTemplateProductSubTypeByLoanPurpose = (loanPurposeOptions) => {
    if (loanPurposeOptions === null || loanPurposeOptions === void 0 ? void 0 : loanPurposeOptions.includes(even_ts_static_1.LoanPurposeOptions.AUTO_REFINANCE)) {
        return originator_client_1.SupplyTemplateProductType.AutomobileRefinance;
    }
    if (loanPurposeOptions === null || loanPurposeOptions === void 0 ? void 0 : loanPurposeOptions.includes(even_ts_static_1.LoanPurposeOptions.STUDENT_REFINANCE)) {
        return originator_client_1.SupplyTemplateProductType.StudentLoanRefinance;
    }
    return undefined;
};
exports.getSupplyTemplateProductSubTypeByLoanPurpose = getSupplyTemplateProductSubTypeByLoanPurpose;
const getSupplyTemplateProductTypeFromPartnerPage = (partnerPageFull) => {
    switch (partnerPageFull.productType) {
        case even_ts_static_1.ConfigProductType.CreditCard:
            return originator_client_1.SupplyTemplateProductType.CreditCard;
        case even_ts_static_1.ConfigProductType.Deposits:
            return originator_client_1.SupplyTemplateProductType.Savings;
        case even_ts_static_1.ConfigProductType.LifeInsurance:
            return originator_client_1.SupplyTemplateProductType.LifeInsurance;
        case even_ts_static_1.ConfigProductType.Loan:
            return ((0, exports.getSupplyTemplateProductSubTypeByLoanPurpose)(partnerPageFull.loanPurposeOptions) || originator_client_1.SupplyTemplateProductType.PersonalLoan);
    }
};
exports.getSupplyTemplateProductTypeFromPartnerPage = getSupplyTemplateProductTypeFromPartnerPage;
const getLoanProductSubtypeFromLoanPurpose = (loanPurposeOptions) => {
    if (loanPurposeOptions === null || loanPurposeOptions === void 0 ? void 0 : loanPurposeOptions.includes(even_ts_static_1.LoanPurposeOptions.AUTO_REFINANCE)) {
        return originator_client_1.ProductSubType.AutomobileRefinance;
    }
    if (loanPurposeOptions === null || loanPurposeOptions === void 0 ? void 0 : loanPurposeOptions.includes(even_ts_static_1.LoanPurposeOptions.STUDENT_REFINANCE)) {
        return originator_client_1.ProductSubType.StudentLoanRefinance;
    }
    return undefined;
};
exports.getLoanProductSubtypeFromLoanPurpose = getLoanProductSubtypeFromLoanPurpose;
const getProductSubtypeFromPartnerPage = (partnerPageFull) => {
    switch (partnerPageFull.productType) {
        case even_ts_static_1.ConfigProductType.Loan:
            return (0, exports.getLoanProductSubtypeFromLoanPurpose)(partnerPageFull.loanPurposeOptions);
        default:
            return undefined;
    }
};
exports.getProductSubtypeFromPartnerPage = getProductSubtypeFromPartnerPage;
