import type { ActionType } from 'typesafe-actions';

import type { ApplicationState } from '@/store';
import { sagaDispatcherPipeAction } from '@/store/saga-dispatcher-flow';
import type { NextPageContextWithStore } from '@/store/types';

type Context = NextPageContextWithStore<ApplicationState>;

type SkipCallback = (state: ApplicationState) => boolean;
type Skip = boolean | SkipCallback;

type PayloadCallback<T> = (state: ApplicationState) => T;
type Payload<T> = T | PayloadCallback<T>;

interface AddActionOptions<T> {
  /** If true, all subsequent dispatchers will wait on saga to finish. */
  await?: boolean;
  /** Set payload using object or callback with current state. */
  payload?: Payload<T>;
  /** If true, skip action */
  skip?: Skip;
}

export interface ActionData<T> {
  action: ActionType<any>;
  options: AddActionOptions<T>;
}

/**
 * @template T
 * @param {(Payload<T> | undefined)} payload
 * @param {Context} context
 * @return {*}  {(T | undefined)}
 */
export const resolvePayload = <T>(
  payload: Payload<T> | undefined,
  context: Context
): T | undefined => {
  if (typeof payload === 'function') {
    return (payload as PayloadCallback<T>)(context.store.getState());
  }

  return payload;
};

/**
 * @param {(Skip | undefined)} skip
 * @param {Context} context
 * @return {*}  {(boolean | undefined)}
 */
export const resolveSkip = (
  skip: Skip | undefined,
  context: Context
): boolean | undefined => {
  if (typeof skip === 'function') {
    return (skip as SkipCallback)(context.store.getState());
  }

  return skip;
};

/**
 * @deprecated
 * No longer in use. Use react-query or useDispatch.
 */
class SagaDispatcherPipe {
  private actions: ActionData<any>[] = [];
  private context: Context;

  constructor(context: Context) {
    this.context = context;
  }

  /**
   * @template T
   * @param {ActionType<any>} action
   * @param {AddActionOptions<T>} [options]
   * @return {*}  {SagaDispatcherPipe}
   * @memberof SagaDispatcherPipe
   */
  public action<T>(
    action: ActionType<any>,
    options: AddActionOptions<T> = {}
  ): SagaDispatcherPipe {
    this.actions.push({
      action,
      options,
    });

    return this;
  }

  /**
   * @return {*}  {Promise<void>}
   * @memberof SagaDispatcherPipe
   */
  public async dispatch(): Promise<void> {
    const actions = this.actions;
    const context = this.context;

    return new Promise((resolve, reject) => {
      this.context.store.dispatch(
        sagaDispatcherPipeAction({
          actions,
          context,
          reject,
          resolve,
        })
      );
    });
  }
}

/**
 * @deprecated
 * No longer in use. Use react-query or useDispatch.
 */
export const sagaDispatcherPipe = (context: Context): SagaDispatcherPipe =>
  new SagaDispatcherPipe(context);
