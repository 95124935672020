import * as React from 'react';

import Layout from './layout';

interface ContentWrapperProps extends React.PropsWithChildren<{}> {
  withoutLayout: boolean;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({
  children,
  withoutLayout,
}) => {
  if (!withoutLayout) {
    return <Layout>{children}</Layout>;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default ContentWrapper;
