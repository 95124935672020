import { useEffect, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { useField } from '@/components/form/portal-form';
import type { ValidationResult } from '@/components/form/portal-form/types';
import usePrevious from '@/utils/hooks/use-previous';

interface UseControllableOptions<ValueType> {
  defaultValue: ValueType;
  /** Event callback to execute when controlled value is changed through setValue */
  onChange?: (value: ValueType) => void;
  /** Event callback to execute when "validate" method fails */
  onError?: (message: string) => void;
  /** Callback to execute on value change to determine if a value is valid */
  validate?: (
    value: ValueType
  ) => string | null | ValidationResult<any> | undefined;
  /** Should the value be validated on change, defaults to "true" */
  validateOnChange?: boolean;
  value?: ValueType;
}

type UseControllablePayload<ValueType> = [
  ValueType,
  (value: ValueType) => void
];

/**
 * React hook to allow for a field component to easily become controllable.
 *
 * @template ValueType
 *
 * @param {ValueType} value
 * @param {UseControllableOptions<ValueType>} [options]
 *
 * @returns {UseControllablePayload<ValueType>}
 */
export const useControllable = <ValueType extends unknown>(
  options: UseControllableOptions<ValueType>
): UseControllablePayload<ValueType> => {
  const {
    value,
    defaultValue: defaultValueRef,
    onChange,
    onError,
    validate: validator,
    validateOnChange = true,
  } = options ?? {};

  const defaultValue = cloneDeep(defaultValueRef);

  const [localValue, setLocalValue] = useState<ValueType>(
    () => value ?? defaultValue
  );

  const previousValue = usePrevious(value);

  const { setFieldValue } = useField();

  useEffect(() => {
    const fieldValue = value ?? defaultValue;

    if (fieldValue !== undefined) {
      setFieldValue(fieldValue, false);
    }
  }, []);

  const validate = (nextValue: ValueType) => {
    if (validator) {
      const error = validator(nextValue);

      if (typeof error === 'string') {
        onError?.(error);
      } else if (typeof error === 'object' && error && error.errorCount > 0) {
        onError?.('Portal form validation errors');
      }
    }
  };

  const setValue = (nextValue: ValueType) => {
    setLocalValue(() => nextValue);
    onChange?.(nextValue);

    if (validateOnChange) {
      validate(nextValue);
    }
  };

  useEffect(() => {
    if (previousValue === undefined && value !== undefined) {
      setLocalValue(() => value);
    } else if (
      previousValue !== undefined &&
      value !== undefined &&
      !isEqual(previousValue, value)
    ) {
      setLocalValue(() => value);

      if (validateOnChange) {
        validate(value);
      }
    }
  }, [value]);

  return [localValue, setValue];
};
