import * as React from 'react';
import { useEffect, useLayoutEffect } from 'react';
import { QueryClientProvider } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { FocusStyleManager } from '@blueprintjs/core';
import { config } from '@fortawesome/fontawesome-svg-core';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import type { AppContext, AppProps } from 'next/app';
import Error from 'next/error';
import Script from 'next/script';
import numeral from 'numeral';

import { client } from '@portal/api-client';

import { LegalAgreementModal } from '@/components/legal-agreement-modal';
import { AccessGuard } from '@/guards/access.guard';

import GlobalLoader from '../components/global-loader';
import { queryClient } from '../config/query-client';
import { getEnv } from '../envWrapper';
import { AuthenticatedRouteGuard, AuthenticationGuard } from '../guards';
import { selectConfig } from '../resources/config/selects';
import { Router } from '../routes';
import { setIsServer } from '../store/config';
import { wrapper } from '../store/index';
import { setAGGridLicense } from '../utils/ag-grid';
import {
  useDataDog,
  useGoogleAnalytics,
  useHotjar,
  useInspectlet,
  useSentry,
} from '../utils/hooks';
import { LoggerFactory } from '../utils/logger';
import { createErrorInterceptor } from '../utils/rest-client-factory';

import ContentWrapper from './contentWrapper';
import Feedback from './feedback/feedback';

import '@fortawesome/fontawesome-svg-core/styles.css';
import '@/styles/globalStyles.scss';

// Numeral.js defaults
numeral.nullFormat('--');

// Singleton which removes focus outline on click most elements.
// Focus remains on input text fields as it is useful.
// Focus remains for elements for tab focus.
FocusStyleManager.onlyShowFocusOnTabs();

config.autoAddCss = false;

const env = getEnv();
client.defaults.baseURL = env.PORTAL_API_URL;
client.interceptors.response.use(
  undefined,
  createErrorInterceptor(LoggerFactory('CCApiClient', env))
);

interface AppStateProps {
  statusCode?: number;
}

const isClient = typeof window !== 'undefined';

const oktaAuth = new OktaAuth({
  clientId: env.NEXT_PUBLIC_OKTA_CLIENT_ID,
  issuer: `${env.NEXT_PUBLIC_OKTA_ORG_URL}/oauth2/default`,
  postLogoutRedirectUri: isClient ? `${window.location.origin}/sign-out` : '',
  redirectUri: isClient ? `${window.location.origin}/sign-in-callback` : '',
});

const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
  if (isClient) {
    Router.pushRoute(toRelativeUrl(originalUri || '/', window.location.origin));
  }
};

const Dynamic = ({ children }: { children: React.ReactNode }) => {
  const [hasMounted, setHasMounted] = React.useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return <>{children}</>;
};

function App({ Component, pageProps, statusCode }: AppProps & AppStateProps) {
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);

  setAGGridLicense(config);

  useLayoutEffect(() => {
    dispatch(setIsServer(false));
  }, [dispatch]);

  useDataDog();
  useGoogleAnalytics();
  useHotjar();
  useInspectlet();
  useSentry();

  const { withoutLayout } = pageProps;

  return (
    <Dynamic>
      <Script
        crossOrigin="anonymous"
        src="https://kit.fontawesome.com/c3fc4c95a1.js"
      ></Script>

      <AuthenticationGuard>
        <AccessGuard>
          <AuthenticatedRouteGuard>
            <GlobalLoader />
            <LegalAgreementModal />
            <Feedback />
          </AuthenticatedRouteGuard>

          <ContentWrapper withoutLayout={withoutLayout}>
            {statusCode === 404 ? (
              <Error statusCode={404} />
            ) : (
              <Component {...pageProps} />
            )}
          </ContentWrapper>
        </AccessGuard>
      </AuthenticationGuard>

      {/* https://stackoverflow.com/questions/14389566/stop-css-transition-from-firing-on-page-load/42969608#42969608 */}
      {/* eslint:disable-next-line */}
      <script> </script>
    </Dynamic>
  );
}

export const AppWithProviders = (props: AppProps & AppStateProps) => {
  return (
    <React.StrictMode>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <QueryClientProvider client={queryClient}>
          <App {...props} />
        </QueryClientProvider>
      </Security>
    </React.StrictMode>
  );
};

AppWithProviders.getInitialProps = async (context: AppContext) => {
  const {
    Component,
    ctx,
    ctx: { req, res },
  } = context;

  if (res && req?.headers.host?.includes('portal')) {
    res.writeHead(307, { Location: 'https://controls.engine.tech' }).end();
  }

  const pageProps = {
    ...(Component.getInitialProps ? await Component.getInitialProps(ctx) : {}),
  };

  return {
    pageProps,
    statusCode: ctx?.res?.statusCode,
  };
};

export default wrapper.withRedux(AppWithProviders);
