import * as React from 'react';
import { useMemo } from 'react';

import type { ActionType } from 'typesafe-actions';

import type { Notification } from '@portal/api-models';
import type * as notificationsActions from '@portal/store/dist/notifications/actions';
import { NotificationFilter } from '@portal/store/dist/notifications/types';

import NotificationItem from '@/components/notification/notification-item';

interface NotificationsListProps {
  filter: NotificationFilter;
  notifications: Notification[];
  setRead: (
    uuid: string,
    read?: boolean
  ) => ActionType<
    typeof notificationsActions.updateNotificationReadAction.request
  >;
}

export const NotificationsList: React.FC<NotificationsListProps> = ({
  notifications,
  filter,
  setRead,
}) => {
  const filteredNotifications = useMemo(
    () =>
      notifications?.filter(({ read }) => {
        return filter === NotificationFilter.UNREAD ? !read : true;
      }),
    [notifications, filter]
  );

  return (
    <div className="notifications-list">
      {filteredNotifications?.map(
        ({ uuid, read, type, topic, title, message, createdAt }) => (
          <NotificationItem
            key={`notification-${uuid}`}
            message={message}
            read={read}
            time={createdAt}
            title={title}
            topic={topic}
            type={type}
            onReadAction={() => setRead(uuid, !read)}
          />
        )
      )}
      {!filteredNotifications?.length && (
        <div className="notifications-not-found">
          <span>No new notifications</span>
        </div>
      )}
    </div>
  );
};
