import * as React from 'react';
import { useEffect, useState } from 'react';

import Button from 'antd/lib/button';
import classNames from 'classnames';

import ConfirmableAction from '@/components/confirmable-action';
import type { ConfirmableActionProps } from '@/components/confirmable-action';

export enum PortalFormAction {
  Update,
  Create,
}

export interface PortalFormActionsProps {
  actionText?: {
    cancel?: string;
    submit?: string;
  };
  afterActions?: React.ReactFragment;
  beforeActions?: React.ReactFragment;
  className?: string;
  confirmationProps?: Omit<Partial<ConfirmableActionProps<{}>>, 'action'>;
  disableSubmit?: boolean;
  enableConfirmation?: boolean;
  hideSubmit?: boolean;
  onCancel?: () => void;
  onSubmit: () => Promise<void> | void;
  type?: PortalFormAction;
}

/**
 * @deprecated
 * Use react-hook-forms instead for forms.
 */
const PortalFormActions: React.FC<PortalFormActionsProps> = ({
  className,
  type,
  onSubmit,
  disableSubmit,
  hideSubmit,
  onCancel,
  beforeActions,
  afterActions,
  actionText,
  enableConfirmation,
  confirmationProps,
}) => {
  const submitText =
    actionText?.submit ||
    (type === PortalFormAction.Update && 'Update') ||
    (type === PortalFormAction.Create && 'Create') ||
    'Submit';

  const [timeoutID, setTimeoutID] = useState<number>();
  const handleLoadingState = () => {
    const currentID = window.setTimeout(() => setTimeoutID(undefined), 1000);

    setTimeoutID(currentID);
  };

  useEffect(() => () => clearInterval(timeoutID), []);

  return (
    <div className={classNames('portal-form-actions', className)}>
      {beforeActions}
      {!hideSubmit &&
        (enableConfirmation ? (
          <ConfirmableAction
            action={onSubmit}
            okText={submitText}
            {...confirmationProps}
          >
            <Button
              data-qa={`${submitText.toLowerCase()}-btn`}
              disabled={disableSubmit}
              type="primary"
            >
              {submitText}
            </Button>
          </ConfirmableAction>
        ) : (
          <Button
            data-qa={`${submitText.toLowerCase()}-btn`}
            disabled={disableSubmit}
            loading={timeoutID !== undefined}
            type="primary"
            onClick={() => {
              onSubmit();
              handleLoadingState();
            }}
          >
            {submitText}
          </Button>
        ))}
      {onCancel && <Button onClick={() => onCancel()}>Cancel</Button>}
      {afterActions}
    </div>
  );
};

export default PortalFormActions;
