import moment from 'moment';

import type {
  SaasPlan,
  SaasSubscription,
  SaasTrial,
} from '@evenfinancial/finance-client';
import type { AccountTierType } from '@evenfinancial/originator-client';

export function getFreeTrialDaysLeft(endDate?: string): number {
  if (endDate === undefined) {
    return -1;
  }

  const currentDate = new Date();

  return Math.ceil(
    (new Date(endDate).getTime() - currentDate.getTime()) /
      (1000 * 60 * 60 * 24)
  );
}

export function hasTrialExpired(endDate?: string): boolean {
  return getFreeTrialDaysLeft(endDate) < 0;
}

export const isInMigrationTrial = (settings?: SaasTrial): boolean => {
  if (!settings || !settings?.fromMigration) {
    return false;
  }

  return !hasTrialExpired(settings.endDate);
};

export const disabledByTier = (
  tier: AccountTierType,
  saasTrial?: SaasTrial,
  saasPlan?: SaasPlan
) => {
  const isMigrationTrial = isInMigrationTrial(saasTrial);

  if (saasPlan) {
    return saasPlan.tier === tier;
  }

  if (isMigrationTrial) {
    return saasTrial?.expectedTier === tier;
  }

  return true;
};

export const isActiveOrNotExpiredSubscription = (
  subscription?: SaasSubscription
): boolean => {
  if (!subscription) {
    return false;
  }

  return (
    !subscription.deletedAt ||
    (subscription.deletedAt &&
      moment().isSameOrBefore(subscription.periodEndDate, 'day'))
  );
};
