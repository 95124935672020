import type { AxiosError } from 'axios';

import type { LogLevel } from '@/utils/logger/logger';

export interface ParsedLog {
  context?: Record<string, any>;
  error?: Error;
  message: string;
  stack?: string;
}

export const parseArgs = (
  name: string,
  level: LogLevel,
  args: unknown[]
): ParsedLog => {
  let stack: string | undefined;

  const messageArray: string[] = [`[${name}][${level}]`];
  const context: Record<string, any> = {};

  args.forEach((value) => {
    if (typeof value === 'string') {
      messageArray.push(value);
    } else if (value && value instanceof Error) {
      stack = value.stack;
      messageArray.push(value.message);
      context['error.stack'] = stack;

      if ('config' in value) {
        const error = value as AxiosError;

        messageArray.push(error?.config?.url ?? '');

        context['axios.config.baseUrl'] = error.config?.baseURL;
        context['axios.config.data'] = error.config?.data;
        context['axios.config.headers'] = error.config?.headers;
        context['axios.config.method'] = error.config?.method;
        context['axios.config.params'] = error.config?.params;
        context['axios.config.url'] = error.config?.url;

        context['axios.response.data'] = error.response?.data;
        context['axios.response.status'] = error.response?.status;
        context['axios.response.statusText'] = error.response?.statusText;
      }
    } else if (typeof value === 'object') {
      messageArray.push(JSON.stringify(value));
    } else if (value === null) {
      messageArray.push('Null Error Occurred');
    } else if (value === undefined) {
      messageArray.push('Undefined Error Occurred');
    }
  });

  const message = messageArray.join(' | ');

  return {
    context,
    message,
    stack,
  };
};
