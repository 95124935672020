"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFlatRoleFromRoles = void 0;
const api_models_1 = require("@portal/api-models");
const map_roles_1 = require("./map-roles");
const isInternalManager = (roles) => roles.has(api_models_1.Roles.InternalUser) && roles.has(api_models_1.Roles.Manager);
const isInternalViewer = (roles) => roles.has(api_models_1.Roles.InternalUser) && roles.has(api_models_1.Roles.InternalViewer);
const isStarterLegacy = (roles) => roles.has(api_models_1.Roles.SelfService) && roles.has(api_models_1.Roles.Starter);
const getFlatRoleFromRoles = (rolesArr) => {
    const mappedRoles = rolesArr.map(map_roles_1.matchToRoleType);
    const roles = new Set(mappedRoles);
    let flatRole;
    if (isInternalManager(roles)) {
        flatRole = api_models_1.FlatRole.InternalManager;
    }
    else if (isInternalViewer(roles)) {
        flatRole = api_models_1.FlatRole.InternalViewer;
    }
    else if (isStarterLegacy(roles)) {
        flatRole = api_models_1.FlatRole.StarterLegacy;
    }
    else if (roles.has(api_models_1.Roles.Starter)) {
        flatRole = api_models_1.FlatRole.StarterFree;
    }
    else if (roles.has(api_models_1.Roles.Growth)) {
        flatRole = api_models_1.FlatRole.Growth;
    }
    else if (roles.has(api_models_1.Roles.Scale)) {
        flatRole = api_models_1.FlatRole.Scale;
    }
    else if (roles.has(api_models_1.Roles.ScaleAPI)) {
        flatRole = api_models_1.FlatRole.ScaleAPI;
    }
    else if (roles.has(api_models_1.Roles.CRM)) {
        flatRole = api_models_1.FlatRole.CRM;
    }
    else if (roles.has(api_models_1.Roles.SupplyUser)) {
        flatRole = api_models_1.FlatRole.SupplyLite;
    }
    else if (roles.has(api_models_1.Roles.DemandUser)) {
        flatRole = api_models_1.FlatRole.Demand;
    }
    return flatRole;
};
exports.getFlatRoleFromRoles = getFlatRoleFromRoles;
