// Message actions

// Request actions

import type { ModalFuncProps } from 'antd/lib/modal';
import type { ActionType } from 'typesafe-actions';

import {
  getDemandApisAction,
  getDemandApisBySubAccountUuidAction,
} from '@portal/store/dist';
import {
  accountByUuidAction,
  accountCreateRequestAction,
  accountRequestAction,
} from '@portal/store/dist/account/actions';
import { feedbackCreateRequestAction } from '@portal/store/dist/feedback';
import {
  userAccessRequestAction,
  userCreateRequestAction,
  userRequestAction,
} from '@portal/store/dist/user';

import { billingCycleCreateRequestAction } from '../billing-cycle/actions';
import {
  deduplicationConfigurationsCreateAction,
  deduplicationConfigurationsDeleteAction,
  deduplicationConfigurationsRequestAction,
  demandOfferCountsRequestAction,
  partnerMappingBySubAccountUuidRequestAction,
  supplyDemandInclusionCountsRequestAction,
} from '../originator/actions';
import {
  partnerPageByKeyAction,
  partnerPageCreateRequestAction,
  partnerPageUpdateRequestAction,
} from '../partner-page/actions';
import {
  demandSubAccountCreateRequestAction,
  demandSubAccountRequestAction,
  subAccountApplicationsByUuidAction,
  subAccountByUuidAction,
  supplySubAccountApplicationTokenCreateRequestAction,
  supplySubAccountCreateRequestAction,
  supplySubAccountRequestAction,
} from '../subaccount/actions';

import type * as actions from './actions';

const requestActions = {
  accountByUuidAction,
  accountCreateRequestAction,
  accountRequestAction,

  billingCycleCreateRequestAction,

  deduplicationConfigurationsCreateAction,
  deduplicationConfigurationsDeleteAction,
  deduplicationConfigurationsRequestAction,

  demandOfferCountsRequestAction,

  demandSubAccountCreateRequestAction,
  demandSubAccountRequestAction,

  feedbackCreateRequestAction,

  getDemandApisAction,
  getDemandApisBySubAccountUuidAction,

  partnerMappingBySubAccountUuidRequestAction,
  partnerPageByKeyAction,
  partnerPageCreateRequestAction,

  partnerPageUpdateRequestAction,
  subAccountApplicationsByUuidAction,
  subaccountByUuidAction: subAccountByUuidAction,
  supplyDemandInclusionCountsRequestAction,
  supplySubAccountApplicationTokenCreateRequestAction,
  supplySubAccountCreateRequestAction,
  supplySubAccountRequestAction,
  userAccessRequestAction,
  userCreateRequestAction,

  userRequestAction,
};

export type RequestAction = ActionType<typeof requestActions>;
export type MessageAction = ActionType<typeof actions>;

export enum MessageActionType {
  ERROR = '@@message/ERROR',
  LOADING_END = '@@message/LOADING_END',
  LOADING_START = '@@message/LOADING_START',
  OVERLAY_LOADER = '@@message/OVERLAY_LOADER',
  SUCCESS = '@@message/SUCCESS',
  UPDATE_TEXT = '@@message/UPDATE_TEXT',
}

export type OverlayLoaderType = Partial<ModalFuncProps> & {
  className?: string;
  content: String;
  title?: string;
};

export interface MessageState {
  loading: number;
  overlay?: OverlayLoaderType;
  text: string;
}
