export enum GettingStartedBoxSteps {
  ACTIVATE_PLAN = 'ACTIVATE_PLAN',
  COMPLIANCE_REVIEW = 'COMPLIANCE_REVIEW',
  CREATE_FIRST_INTEGRATION = 'CREATE_FIRST_INTEGRATION',
  IMPLEMENTATION_FEE_WAIVED = 'IMPLEMENTATION_FEE_WAIVED',
  PAY_IMPLEMENTATION_FEE = 'PAY_IMPLEMENTATION_FEE',
  PUBLISH_PARTNER_PAGE = 'PUBLISH_PARTNER_PAGE',
  SIGN_UP = 'SIGN_UP',
  SUBMIT_FOR_REVIEW = 'SUBMIT_FOR_REVIEW',
}
