import { END } from 'redux-saga';
import { fork, put, take, takeEvery } from 'redux-saga/effects';

import type { ApplicationState } from '@/store';
import type {
  IsomorphicRequestPayload,
  NextPageContextWithStore,
} from '@/store/types';
import { resolvePayload, resolveSkip } from '@/utils/saga-dispatcher-class';
import type { ActionData } from '@/utils/saga-dispatcher-class';

const SAGA_DISPATCHER_PIPE = 'SAGA_DISPATCHER_PIPE';

type SagaDispatcherPipePayload = IsomorphicRequestPayload & {
  actions: ActionData<any>[];
  context: NextPageContextWithStore<ApplicationState>;
  reject: (error: unknown) => void;
  resolve: (value: undefined) => void;
};

export const sagaDispatcherPipeAction = (
  payload: SagaDispatcherPipePayload
) => ({
  payload,
  type: SAGA_DISPATCHER_PIPE,
});

function* sagaDispatcherPipe(action: {
  payload: SagaDispatcherPipePayload;
  type: typeof SAGA_DISPATCHER_PIPE;
}) {
  const { context, actions, resolve, reject } = action.payload;

  const cookie = context?.req?.headers?.cookie;

  const end = (callback: () => void) => {
    if (context.req) {
      context.store.dispatch(END);
      context.store.rootSagaTask.toPromise().then(callback);
    } else {
      callback();
    }
  };

  try {
    for (const actionMeta of actions) {
      const { action, options } = actionMeta;

      const skip = resolveSkip(options.skip, context);
      const payload = resolvePayload(options.payload, context);

      if (skip) {
        continue;
      }

      yield put(action({ cookie, ...payload }));

      if (options.await) {
        yield take([
          String(action.getType()).replace('REQUEST', 'SUCCESS'),
          String(action.getType()).replace('REQUEST', 'FAILURE'),
        ]);
      }
    }

    end(() => resolve(undefined));
  } catch (err: any) {
    console.error(err);
    end(() => reject(err));
  }
}

function* watchSagaDispatcherPipe() {
  yield takeEvery(SAGA_DISPATCHER_PIPE, sagaDispatcherPipe);
}

export function* sagaDispatcherPipeSaga() {
  yield fork(watchSagaDispatcherPipe);
}
