import type { ActionType } from 'typesafe-actions';

import type { PortalSupplyContract } from '@portal/api-models';
import type { SupplyContractFormData } from '@portal/ui-lib';

import type * as actions from './actions';

export type SupplyContractsAction = ActionType<typeof actions>;

export enum SupplyContractsActionType {
  GET_ALL_FAILURE = '@@supply-contracts/GET_ALL_FAILURE',
  GET_ALL_REQUEST = '@@supply-contracts/GET_ALL_REQUEST',
  GET_ALL_SUCCESS = '@@supply-contracts/GET_ALL_SUCCESS',

  GET_SUPPLY_CONTRACTS_BY_SUBACCOUNT_UUID_FAILURE = '@@supply-contracts/GET_SUPPLY_CONTRACTS_BY_SUBACCOUNT_UUID_FAILURE',
  GET_SUPPLY_CONTRACTS_BY_SUBACCOUNT_UUID_REQUEST = '@@supply-contracts/GET_SUPPLY_CONTRACTS_BY_SUBACCOUNT_UUID_REQUEST',
  GET_SUPPLY_CONTRACTS_BY_SUBACCOUNT_UUID_SUCCESS = '@@supply-contracts/GET_SUPPLY_CONTRACTS_BY_SUBACCOUNT_UUID_SUCCESS',

  SUPPLY_CONTRACT_DUPLICATE = '@@supply-contract/SUPPLY_CONTRACT_DUPLICATE',

  SUPPLY_CONTRACT_TOGGLE_CREATE = '@@supply-contracts/SUPPLY_CONTRACT_TOGGLE_CREATE',
}

export interface SupplyContractsState {
  bySubAccountUuid: { [key: string]: PortalSupplyContract[] };
  duplicateSupplyContract?: SupplyContractFormData;
  rows: PortalSupplyContract[];
  supplyContractCreateVisible: boolean;
}
