import type { ColumnProps } from 'antd/lib/table';

import { ProductType } from '@evenfinancial/finance-client';

import { creditCardFunnelTableConfig } from '@/resources/channels/performance-summary/table-configs/credit-card/funnel';
import { LifeInsuranceFunnelTableConfig } from '@/resources/channels/performance-summary/table-configs/life-insurance/funnel';
import { loansFunnelTableConfig } from '@/resources/channels/performance-summary/table-configs/loans/funnel';
import { savingsFunnelTableConfig } from '@/resources/channels/performance-summary/table-configs/savings/funnel';
import { commonEarningsTableConfig } from '@/resources/channels/performance-summary/table-configs/shared/earnings';
import type { ContractApplicationType } from '@/store/demand-contracts/types';

const defaultTableConfig = () => [] as ColumnProps<any>[];

export const earningsTableConfig = (productType: ProductType) => {
  switch (productType) {
    case ProductType.Loan:
    case ProductType.Savings:
    case ProductType.CreditCard:
    case ProductType.LifeInsurance:
      return commonEarningsTableConfig;

    default:
      return defaultTableConfig;
  }
};

export const funnelTableConfig = (
  productType: ProductType,
  contractApplicationType: ContractApplicationType
) => {
  switch (productType) {
    case ProductType.Loan:
      return loansFunnelTableConfig(contractApplicationType);

    case ProductType.Savings:
      return savingsFunnelTableConfig;

    case ProductType.CreditCard:
      return creditCardFunnelTableConfig;

    case ProductType.LifeInsurance:
      return LifeInsuranceFunnelTableConfig;

    default:
      return defaultTableConfig;
  }
};
