"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleCreateWhitelist = exports.deleteWhitelistAction = exports.createMultipleWhitelistAction = exports.createWhitelistAction = exports.getAllWhitelistAction = void 0;
var typesafe_actions_1 = require("typesafe-actions");
var actionTypes_1 = require("./actionTypes");
exports.getAllWhitelistAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.WhitelistActionType.GET_ALL_WHITELIST_REQUEST, actionTypes_1.WhitelistActionType.GET_ALL_WHITELIST_SUCCESS, actionTypes_1.WhitelistActionType.GET_ALL_WHITELIST_FAILURE)();
exports.createWhitelistAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.WhitelistActionType.CREATE_WHITELIST_REQUEST, actionTypes_1.WhitelistActionType.CREATE_WHITELIST_SUCCESS, actionTypes_1.WhitelistActionType.CREATE_WHITELIST_FAILURE)();
exports.createMultipleWhitelistAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.WhitelistActionType.CREATE_MULTIPLE_WHITELIST_REQUEST, actionTypes_1.WhitelistActionType.CREATE_MULTIPLE_WHITELIST_SUCCESS, actionTypes_1.WhitelistActionType.CREATE_MULTIPLE_WHITELIST_FAILURE)();
exports.deleteWhitelistAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.WhitelistActionType.DELETE_WHITELIST_REQUEST, actionTypes_1.WhitelistActionType.DELETE_WHITELIST_SUCCESS, actionTypes_1.WhitelistActionType.DELETE_WHITELIST_FAILURE)();
exports.toggleCreateWhitelist = (0, typesafe_actions_1.createAction)(actionTypes_1.WhitelistActionType.TOGGLE_CREATE)();
