import { createSelector } from 'reselect';

import { ConfigProductType } from '@evenfinancial/even-ts-static';

import type { PartnerPageAlooma } from '@portal/api-models';
import { selectPermissions } from '@portal/store/dist/user/selectors';

import { getIntegrationSubProductType } from '@/resources/integrations/selectors';
import { selectPartnerPages } from '@/resources/partner-keys/selects';
import { getPartnerPagesByPartnerKey } from '@/resources/partner-page/selectors';
import { getFreeTrialDaysLeft } from '@/resources/saas/helpers';
import {
  saasUserPlanSelector,
  saasUserTrialSelector,
} from '@/resources/saas/selectors';
import { selectSaasSubscription } from '@/resources/saas/selects';
import type { ApplicationState } from '@/store';

type ProductTypeMap = {
  [key in ConfigProductType]: boolean;
};

const getProductOfferStatuses = (
  partnerPages: PartnerPageAlooma[],
  partnerPagesConfigs: Record<string, any>,
  isPaid: boolean,
  freeTrialExpired: boolean
) => {
  const productTypeKeys: ConfigProductType[] = [
    ...Object.values(ConfigProductType),
  ];

  const defaultDisabled = !isPaid;

  const productTypeMap = productTypeKeys.reduce((acc, curr) => {
    acc[curr] = defaultDisabled;

    return acc;
  }, {} as ProductTypeMap);

  if (isPaid || !freeTrialExpired) {
    partnerPages.forEach((partnerPage) => {
      const productSubType = getIntegrationSubProductType(
        partnerPage,
        partnerPagesConfigs
      );

      if (productSubType && productTypeMap[productSubType]) {
        // is SLR
        productTypeMap[productSubType] = false;
      } else if (productTypeMap[partnerPage.product]) {
        // is product
        productTypeMap[partnerPage.product] = false;
      }
    });
  }

  return productTypeMap;
};

const shouldDisableNavbarItemFactory =
  (base: boolean) => (condition: boolean) =>
    base && condition;

// TODO: Refactor this once we introduce rrd v6 layouts.
export const selfServiceDataSelector = createSelector(
  [
    selectPartnerPages,
    getPartnerPagesByPartnerKey,
    saasUserTrialSelector,
    selectSaasSubscription,
    saasUserPlanSelector,
    selectPermissions,
    ({ supplyMigration }: ApplicationState) => supplyMigration?.settings,
  ],
  (
    partnerPages,
    fullConfigByIdMap,
    userTrial,
    subscription,
    currentPlan,
    { shouldEnablePerformanceSummary, shouldOverrideNavLocks },
    supplyMigration
  ) => {
    const isPaid = Boolean(subscription);
    const freeTrialDaysLeft = getFreeTrialDaysLeft(userTrial?.endDate);
    const freeTrialExpired = freeTrialDaysLeft < 0;
    const productOfferStatuses = getProductOfferStatuses(
      partnerPages,
      fullConfigByIdMap,
      isPaid,
      freeTrialExpired
    );

    const shouldDisableNavbarItem = shouldDisableNavbarItemFactory(
      !shouldOverrideNavLocks
    );

    const disabledBySupplyMigration =
      Boolean(supplyMigration) && !isPaid && freeTrialExpired;

    const disabledByDefaultRules =
      !supplyMigration && (!isPaid || (!isPaid && freeTrialExpired));

    const integrationsDisabled =
      (partnerPages.length === 0 && !isPaid) || (!isPaid && freeTrialExpired);

    let performanceSummaryDisabled =
      disabledBySupplyMigration || disabledByDefaultRules;

    if (shouldEnablePerformanceSummary) {
      performanceSummaryDisabled = false;
    }

    return {
      accountDisabled: shouldDisableNavbarItem(disabledBySupplyMigration),
      autoLoanRefDisabled: shouldDisableNavbarItem(
        productOfferStatuses.automobile_refinance || disabledBySupplyMigration
      ),
      complianceDisabled: shouldDisableNavbarItem(
        disabledBySupplyMigration || disabledByDefaultRules
      ),
      creditCardsDisabled: shouldDisableNavbarItem(
        productOfferStatuses.credit_card || disabledBySupplyMigration
      ),
      currentPlan,
      customOffersDisabled: shouldDisableNavbarItem(
        disabledBySupplyMigration || disabledByDefaultRules
      ),
      integrationsDisabled: shouldDisableNavbarItem(
        disabledBySupplyMigration || integrationsDisabled
      ),
      invoicesDisabled: shouldDisableNavbarItem(
        disabledBySupplyMigration || disabledByDefaultRules
      ),
      lifeInsuranceDisabled: shouldDisableNavbarItem(
        productOfferStatuses.life_insurance || disabledBySupplyMigration
      ),
      performanceSummaryDisabled: shouldDisableNavbarItem(
        performanceSummaryDisabled
      ),
      personalLoanDisabled: shouldDisableNavbarItem(
        productOfferStatuses.loan || disabledBySupplyMigration
      ),
      savingsDisabled: shouldDisableNavbarItem(
        productOfferStatuses.savings || disabledBySupplyMigration
      ),
      studentLoanRefDisabled: shouldDisableNavbarItem(
        productOfferStatuses.student_loan_refinance || disabledBySupplyMigration
      ),
      usersDisabled: shouldDisableNavbarItem(disabledBySupplyMigration),
    };
  }
);
