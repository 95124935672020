import { PortalFormFieldType } from '@/components/form/portal-form/types';
import type { PortalFormField } from '@/components/form/portal-form/types';

export type SafeFieldProps = {
  className?: string;
  defaultChecked?: boolean;
  defaultValue?: unknown;
  onChange?: (...args: unknown[]) => void;
};

/**
 * On an event change, extract value from arguments.
 *
 * @param {PortalFormFieldType} type
 * @param {any[]} args
 *
 * @returns
 */
export const getValueFromOnChangeEvent = (
  type: PortalFormFieldType,
  args: any[]
) => {
  switch (type) {
    case PortalFormFieldType.Input:
    case PortalFormFieldType.TextArea:
    case PortalFormFieldType.InputPassword:
      return args[0].target.value;

    case PortalFormFieldType.Checkbox:
      return args[0].target.checked;

    default:
      return args[0];
  }
};

/**
 * Return the "value" prop based on the field type.
 *
 * @template T
 *
 * @param {PortalFormField<T>} field
 * @param {unknown} value
 *
 * @returns
 */
export const getValue = <T extends {}>(
  field: PortalFormField<T>,
  value: unknown
) => {
  const { type } = field;

  switch (type) {
    case PortalFormFieldType.Checkbox:
    case PortalFormFieldType.Switch:
      return { checked: value };

    default:
      return { value };
  }
};

/**
 * Return the "defaultValue" prop based on the field type.
 *
 * @template T
 *
 * @param {PortalFormField<T>} field
 * @param {unknown} providedDefault
 *
 * @returns
 */
export const getDefaultValue = <T extends {}>(
  field: PortalFormField<T>,
  providedDefault: unknown
) => {
  const { type } = field;
  const fieldProps = field?.fieldProps as SafeFieldProps;

  switch (type) {
    case PortalFormFieldType.Checkbox:
    case PortalFormFieldType.Switch:
      return { defaultChecked: providedDefault ?? fieldProps?.defaultChecked };

    default:
      return { defaultValue: providedDefault ?? fieldProps?.defaultValue };
  }
};
