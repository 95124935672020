import * as React from 'react';

import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

export function dateRangeFilterDropdown({ setSelectedKeys, confirm }: any) {
  return (
    <div>
      <RangePicker
        autoFocus
        id="dca-tbl-dp"
        onChange={(_dates: any, iso: string[]) => {
          setSelectedKeys([iso.join(',')]);
          confirm();
        }}
      />
    </div>
  );
}
