import { action, createAsyncAction } from 'typesafe-actions';

import type { ProductType } from '@evenfinancial/finance-client';

import type {
  PayoutSummary,
  PerformanceSummaryQuery,
  SummaryData,
} from '@portal/api-models';
import type { PortalError } from '@portal/common';

import { PerformanceSummaryActionType } from '@/store/performance-summary/types';
import type { PerformanceReportsFilterState } from '@/store/performance-summary/types';
import type { IsomorphicRequestPayload } from '@/store/types';

interface GetAccountPerformanceSummariesResponsePayload {
  accountUuid: string;
  data: {
    earnings: PayoutSummary[];
  };
  productTypes: ProductType[];
}

interface GetSubAccountPerformanceSummariesResponsePayload {
  data: SummaryData;
  productType: ProductType;
  subAccountUuid: string;
}

interface GetSubAccountPerformanceSummariesRequestPayload
  extends IsomorphicRequestPayload {
  earningsParams?: PerformanceSummaryQuery;
  funnelParams?: PerformanceSummaryQuery;
}

export const setFilters = (filters: PerformanceReportsFilterState) =>
  action(PerformanceSummaryActionType.SET_FILTERS, filters);

export const setLoading = (loading: boolean) =>
  action(PerformanceSummaryActionType.SET_LOADING, loading);

export const clearAccountSummaries = (accountUuid: string) =>
  action(PerformanceSummaryActionType.CLEAR_SUMMARIES_FOR_ACCOUNT_UUID, {
    accountUuid,
  });

export const clearSubAccountSummaries = (subAccountUuid: string) =>
  action(PerformanceSummaryActionType.CLEAR_SUMMARIES_FOR_SUBACCOUNT_UUID, {
    subAccountUuid,
  });

export const getByAccountId = createAsyncAction(
  PerformanceSummaryActionType.GET_ALL_BY_ACCOUNT_UUID_REQUEST,
  PerformanceSummaryActionType.GET_ALL_BY_ACCOUNT_UUID_SUCCESS,
  PerformanceSummaryActionType.GET_ALL_BY_ACCOUNT_UUID_FAILURE
)<
  IsomorphicRequestPayload,
  GetAccountPerformanceSummariesResponsePayload,
  PortalError
>();

export const getBySubAccountId = createAsyncAction(
  PerformanceSummaryActionType.GET_ALL_BY_SUBACCOUNT_UUID_REQUEST,
  PerformanceSummaryActionType.GET_ALL_BY_SUBACCOUNT_UUID_SUCCESS,
  PerformanceSummaryActionType.GET_ALL_BY_SUBACCOUNT_UUID_FAILURE
)<
  GetSubAccountPerformanceSummariesRequestPayload,
  GetSubAccountPerformanceSummariesResponsePayload,
  PortalError
>();
