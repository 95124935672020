import { ProductType } from '@evenfinancial/finance-client';

interface ProductTypeMapper {
  [k: string]: string;
}

export const productTypeTitleMap: ProductTypeMapper = {
  [ProductType.Loan]: 'Personal Loan Earnings',
  [ProductType.Savings]: 'Savings Earnings',
  [ProductType.CreditCard]: 'Credit Card Earnings',
  [ProductType.LifeInsurance]: 'Life Insurance Earnings',
  [ProductType.Other]: 'Other Earnings',
};
export const productTypeTooltipMap: ProductTypeMapper = {
  [ProductType.Loan]: 'This is Personal Loan Earnings',
  [ProductType.Savings]: 'This is Savings Earnings',
  [ProductType.CreditCard]: 'This is Credit Card Earnings',
  [ProductType.LifeInsurance]: 'This is Life Insurance Earnings',
  [ProductType.Other]: 'This is Other Earnings',
};
export const productTypeKeyMap: ProductTypeMapper = {
  [ProductType.Loan]: 'loanPayout',
  [ProductType.Savings]: 'savingsPayout',
  [ProductType.CreditCard]: 'creditCardPayout',
  [ProductType.LifeInsurance]: 'lifeInsurancePayout',
  [ProductType.Other]: 'otherPayout',
};
