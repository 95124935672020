"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sidenavSelector = void 0;
var reselect_1 = require("reselect");
var selectors_1 = require("../user/selectors");
exports.sidenavSelector = (0, reselect_1.createSelector)([selectors_1.selectPermissions], function (_a) {
    var showCrmUserSideNav = _a.showCrmUserSideNav, showBenchmarkReportsMenuItemFeatureFlag = _a.showBenchmarkReportsMenuItemFeatureFlag, showBenchmarkReportsMenuItemIfFeatureFlag = _a.showBenchmarkReportsMenuItemIfFeatureFlag, showDemandUserSideNavIfFeatureFlag = _a.showDemandUserSideNavIfFeatureFlag, showDemandUserSideNavFeatureFlag = _a.showDemandUserSideNavFeatureFlag, showSelfServiceUserSideNavIfFeatureFlag = _a.showSelfServiceUserSideNavIfFeatureFlag, showSelfServiceUserSideNavFeatureFlag = _a.showSelfServiceUserSideNavFeatureFlag, showMCIReportsMenuItemIfFeatureFlag = _a.showMCIReportsMenuItemIfFeatureFlag, showMCIReportsMenuItemFeatureFlag = _a.showMCIReportsMenuItemFeatureFlag, showPerformanceSummaryMenuItem = _a.showPerformanceSummaryMenuItem, showInternalUserSideNav = _a.showInternalUserSideNav, showHomeMenuItem = _a.showHomeMenuItem, showHomeMenuItemIfFeatureFlag = _a.showHomeMenuItemIfFeatureFlag, showHomeMenuItemFeatureFlag = _a.showHomeMenuItemFeatureFlag, showExternalUserSideNavIfFeatureFlag = _a.showExternalUserSideNavIfFeatureFlag, showExternalUserSideNavFeatureFlag = _a.showExternalUserSideNavFeatureFlag, showSelfServiceUserSideNavbar = _a.showSelfServiceUserSideNavbar;
    return ({
        showBenchmarkReportsMenuItem: showBenchmarkReportsMenuItemFeatureFlag &&
            showBenchmarkReportsMenuItemIfFeatureFlag,
        showCrmUserSideNav: showCrmUserSideNav,
        showDemandUserSideNav: showDemandUserSideNavIfFeatureFlag && showDemandUserSideNavFeatureFlag,
        showExternalUserSideNav: showExternalUserSideNavIfFeatureFlag &&
            !showExternalUserSideNavFeatureFlag,
        showHomeMenuItem: showHomeMenuItem ||
            (showHomeMenuItemIfFeatureFlag && showHomeMenuItemFeatureFlag),
        showInternalUserSideNav: showInternalUserSideNav,
        showMCIReportsMenuItem: showMCIReportsMenuItemIfFeatureFlag && showMCIReportsMenuItemFeatureFlag,
        showPerformanceSummaryMenuItem: showPerformanceSummaryMenuItem,
        showSelfServiceUserSideNav: (!showCrmUserSideNav &&
            showSelfServiceUserSideNavIfFeatureFlag &&
            showSelfServiceUserSideNavFeatureFlag) ||
            showSelfServiceUserSideNavbar,
    });
});
