import { HYDRATE } from 'next-redux-wrapper';
import type { Reducer } from 'redux';

import type { HydrateAction } from '@/store/actions';
import { reconcileState } from '@/store/utils';

import { ImportedChannelEventsActionType } from './types';
import type {
  ImportedChannelEventsAction,
  ImportedChannelEventsState,
} from './types';

export const initialState: ImportedChannelEventsState = {
  haveAllChannelEventsBeenRequested: false,
  rows: [],
};

export const reducer: Reducer<
  ImportedChannelEventsState,
  ImportedChannelEventsAction | HydrateAction
> = (
  state: ImportedChannelEventsState = initialState,
  action: ImportedChannelEventsAction | HydrateAction
) => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...reconcileState(action.payload.importedChannelEvents, initialState),
      };
    }

    case ImportedChannelEventsActionType.GET_ALL_IMPORTED_CHANNEL_EVENTS_SUCCESS: {
      return {
        ...state,
        haveAllChannelEventsBeenRequested: true,
        rows: action.payload.response,
      };
    }
    default: {
      return state;
    }
  }
};
