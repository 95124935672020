import { action, createAsyncAction } from 'typesafe-actions';

import type { LedgerItemType } from '@evenfinancial/finance-client';

import type { PortalBillingCycle } from '@portal/api-models';

import type { BillingCycleCreateForm } from '@/pages/accounts/internal/tabs/settings/components/billing-cycle-create';

import type { IsomorphicRequestPayload } from '../types';

import { BillingCycleActionType } from './types';

export const billingCycleRequestAction = createAsyncAction(
  BillingCycleActionType.GET_ALL_REQUEST,
  BillingCycleActionType.GET_ALL_SUCCESS,
  BillingCycleActionType.GET_ALL_FAILURE
)<IsomorphicRequestPayload, PortalBillingCycle[], Error>();

export const billingCycleCreateRequestAction = createAsyncAction(
  BillingCycleActionType.CREATE_REQUEST_CLIENT,
  BillingCycleActionType.CREATE_SUCCESS_CLIENT,
  BillingCycleActionType.CREATE_FAILURE_CLIENT
)<BillingCycleCreateForm, PortalBillingCycle, Error>();

export const billingCycleEndRequestAction = createAsyncAction(
  BillingCycleActionType.END_REQUEST_CLIENT,
  BillingCycleActionType.END_SUCCESS_CLIENT,
  BillingCycleActionType.END_FAILURE_CLIENT
)<{ id: number }, PortalBillingCycle, Error>();

export const billingCycleDeleteRequestAction = createAsyncAction(
  BillingCycleActionType.DELETE_REQUEST_CLIENT,
  BillingCycleActionType.DELETE_SUCCESS_CLIENT,
  BillingCycleActionType.DELETE_FAILURE_CLIENT
)<{ id: number }, { id: number }, Error>();

export const toggleCreate = (type?: LedgerItemType) =>
  action(BillingCycleActionType.TOGGLE_CREATE, { type });
