export enum SubAccountSharedActionType {
  BY_UUID_FAILURE = '@@subaccount/BY_UUID_FAILURE',
  BY_UUID_REQUEST = '@@subaccount/BY_UUID_REQUEST',
  BY_UUID_SUCCESS = '@@subaccount/BY_UUID_SUCCESS',

  COMPLIANCE_ITEMS_BY_UUID_FAILURE = '@@subaccount/COMPLIANCE_ITEMS_BY_UUID_FAILURE',
  COMPLIANCE_ITEMS_BY_UUID_REQUEST = '@@subaccount/COMPLIANCE_ITEMS_BY_UUID_REQUEST',
  COMPLIANCE_ITEMS_BY_UUID_SUCCESS = '@@subaccount/COMPLIANCE_ITEMS_BY_UUID_SUCCESS',
}

export enum SupplySubAccountActionType {
  APPLICATIONS_BY_UUID_FAILURE = '@@subaccount/APPLICATIONS_BY_UUID_FAILURE',
  APPLICATIONS_BY_UUID_REQUEST = '@@subaccount/APPLICATIONS_BY_UUID_REQUEST',
  APPLICATIONS_BY_UUID_SUCCESS = '@@subaccount/APPLICATIONS_BY_UUID_SUCCESS',

  APPLICATION_TOKEN_CONFIRM = '@@subaccount/APPLICATION_TOKEN_CONFIRM',
  APPLICATION_TOKEN_CREATE_FAILURE_CLIENT = '@@subaccount/APPLICATION_TOKEN_CREATE_FAILURE_CLIENT',
  APPLICATION_TOKEN_CREATE_REQUEST_CLIENT = '@@subaccount/APPLICATION_TOKEN_CREATE_REQUEST_CLIENT',

  APPLICATION_TOKEN_CREATE_SUCCESS_CLIENT = '@@subaccount/APPLICATION_TOKEN_CREATE_SUCCESS_CLIENT',
  APPLICATION_TOKEN_TOGGLE_CREATE = '@@subaccount/APPLICATION_TOKEN_TOGGLE_CREATE',
  CREATE_FAILURE_CLIENT = '@@subaccount/SUPPLY_CREATE_FAILURE_CLIENT',
  CREATE_REQUEST_CLIENT = '@@subaccount/SUPPLY_CREATE_REQUEST_CLIENT',
  CREATE_SUCCESS_CLIENT = '@@subaccount/SUPPLY_CREATE_SUCCESS_CLIENT',
  CREATE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_FAILURE = '@@subaccount/CREATE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_FAILURE',

  CREATE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_REQUEST = '@@subaccount/CREATE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_REQUEST',
  CREATE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_SUCCESS = '@@subaccount/CREATE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_SUCCESS',
  DELETE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_FAILURE = '@@subaccount/DELETE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_FAILURE',

  DELETE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_REQUEST = '@@subaccount/DELETE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_REQUEST',
  DELETE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_SUCCESS = '@@subaccount/DELETE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_SUCCESS',
  GET_ALL_FAILURE = '@@subaccount/SUPPLY_GET_ALL_FAILURE',
  GET_ALL_REQUEST = '@@subaccount/SUPPLY_GET_ALL_REQUEST',

  GET_ALL_SUCCESS = '@@subaccount/SUPPLY_GET_ALL_SUCCESS',

  GET_ALL_SUPPLY_SUB_ACCOUNT_DEACTIVATION_FAILURE = '@@subaccount/GET_ALL_SUPPLY_SUB_ACCOUNT_DEACTIVATION_FAILURE',

  GET_ALL_SUPPLY_SUB_ACCOUNT_DEACTIVATION_REQUEST = '@@subaccount/GET_ALL_SUPPLY_SUB_ACCOUNT_DEACTIVATION_REQUEST',
  GET_ALL_SUPPLY_SUB_ACCOUNT_DEACTIVATION_SUCCESS = '@@subaccount/GET_ALL_SUPPLY_SUB_ACCOUNT_DEACTIVATION_SUCCESS',

  TOGGLE_CREATE = '@@subaccount/SUPPLY_TOGGLE_CREATE',
  TOGGLE_DUPLICATE = '@@subaccount/SUPPLY_TOGGLE_DUPLICATE',
  TOGGLE_DUPLICATE_OPEN = '@@subaccount/SUPPLY_TOGGLE_DUPLICATE_OPEN',

  UPDATE_FAILURE = '@@subaccount/SUPPLY_UPDATE_FAILURE',
  UPDATE_REQUEST = '@@subaccount/SUPPLY_UPDATE_REQUEST',
  UPDATE_SUCCESS = '@@subaccount/SUPPLY_UPDATE_SUCCESS',
}

export enum DemandSubAccountActionType {
  CREATE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_FAILURE = '@@subaccount/CREATE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_FAILURE',
  CREATE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_REQUEST = '@@subaccount/CREATE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_REQUEST',
  CREATE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_SUCCESS = '@@subaccount/CREATE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_SUCCESS',

  CREATE_FAILURE_CLIENT = '@@subaccount/DEMAND_CREATE_FAILURE_CLIENT',
  CREATE_REQUEST_CLIENT = '@@subaccount/DEMAND_CREATE_REQUEST_CLIENT',
  CREATE_SUCCESS_CLIENT = '@@subaccount/DEMAND_CREATE_SUCCESS_CLIENT',

  DELETE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_FAILURE = '@@subaccount/DELETE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_FAILURE',
  DELETE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_REQUEST = '@@subaccount/DELETE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_REQUEST',
  DELETE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_SUCCESS = '@@subaccount/DELETE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_SUCCESS',

  GET_ALL_DEMAND_SUB_ACCOUNT_DEACTIVATION_FAILURE = '@@subaccount/GET_ALL_DEMAND_SUB_ACCOUNT_DEACTIVATION_FAILURE',
  GET_ALL_DEMAND_SUB_ACCOUNT_DEACTIVATION_REQUEST = '@@subaccount/GET_ALL_DEMAND_SUB_ACCOUNT_DEACTIVATION_REQUEST',
  GET_ALL_DEMAND_SUB_ACCOUNT_DEACTIVATION_SUCCESS = '@@subaccount/GET_ALL_DEMAND_SUB_ACCOUNT_DEACTIVATION_SUCCESS',

  GET_ALL_FAILURE = '@@subaccount/DEMAND_GET_ALL_FAILURE',
  GET_ALL_LENDERS_FAILURE = '@@subaccount/DEMAND_GET_ALL_LENDERS_FAILURE',
  GET_ALL_LENDERS_REQUEST = '@@subaccount/DEMAND_GET_ALL_LENDERS_REQUEST',
  GET_ALL_LENDERS_SUCCESS = '@@subaccount/DEMAND_GET_ALL_LENDERS_SUCCESS',
  GET_ALL_MIGRATED_LENDERS_FAILURE = '@@subaccount/GET_ALL_MIGRATED_LENDERS_FAILURE',

  GET_ALL_MIGRATED_LENDERS_REQUEST = '@@subaccount/GET_ALL_MIGRATED_LENDERS_REQUEST',
  GET_ALL_MIGRATED_LENDERS_SUCCESS = '@@subaccount/GET_ALL_MIGRATED_LENDERS_SUCCESS',
  GET_ALL_REQUEST = '@@subaccount/DEMAND_GET_ALL_REQUEST',
  GET_ALL_SUCCESS = '@@subaccount/DEMAND_GET_ALL_SUCCESS',

  INIT_DEMAND_SUB_ACCOUNT_DETAILS_PAGE = '@@subaccount/INIT_DEMAND_SUB_ACCOUNT_DETAILS_PAGE',

  SUPPLY_PARTNERS_BY_UUID_FAILURE = '@@subaccount/DEMAND_SUPPLY_PARTNERS_BY_UUID_FAILURE',
  SUPPLY_PARTNERS_BY_UUID_REQUEST = '@@subaccount/DEMAND_SUPPLY_PARTNERS_REQUEST',
  SUPPLY_PARTNERS_BY_UUID_SUCCESS = '@@subaccount/DEMAND_SUPPLY_PARTNERS_BY_UUID_SUCCESS',

  TOGGLE_CREATE = '@@subaccount/DEMAND_TOGGLE_CREATE',
  TOGGLE_DUPLICATE = '@@subaccount/DEMAND_TOGGLE_DUPLICATE',

  UPDATE_FAILURE = '@@subaccount/DEMAND_UPDATE_FAILURE',
  UPDATE_REQUEST = '@@subaccount/DEMAND_UPDATE_REQUEST',
  UPDATE_SUCCESS = '@@subaccount/DEMAND_UPDATE_SUCCESS',
}
