import * as React from 'react';
import { useSelector } from 'react-redux';

import Menu from 'antd/lib/menu';
import classNames from 'classnames';

import { selectSidenavCollapsed } from '@/resources/interface/selects';

interface PortalItemGroupProps extends React.PropsWithChildren<{}> {
  className?: string;
  title: string;
}

const PortalItemGroup: React.FC<PortalItemGroupProps> = ({
  title,
  children,
  className,
  ...props
}) => {
  const sidenavCollapsed = useSelector(selectSidenavCollapsed);

  return (
    <Menu.ItemGroup
      className={classNames('nav-menu-item-group', className)}
      title={sidenavCollapsed ? '' : title.toUpperCase()}
      {...props}
    >
      {children}
    </Menu.ItemGroup>
  );
};

export default PortalItemGroup;
