"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.supplyConfig = void 0;
exports.supplyConfig = {
    canReadIntegrations: true,
    hasSupplyUserRole: true,
    shouldGetSubAccountCompliance: true,
    shouldRedirectIndexEntryPointToHomePage: true,
    shouldRestrictUserSettingsEditing: true,
    showExternalSupplyAccountDetailsPropertyFields: true,
    showHomeMenuItem: true,
    showPerformanceSummaryMenuItem: true,
    showRebrandGuideAndAssetsLink: true,
    showSupplyHomePage: true,
    showSupplySubAccountsCard: true,
};
