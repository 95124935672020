import type { ActionType } from 'typesafe-actions';

import type { ExpectedEarningsShare } from '@evenfinancial/finance-client';

import type * as actions from './actions';

export type ExpectedEarningsShareAction = ActionType<typeof actions>;

export enum ExpectedEarningsShareActionType {
  CREATE_EXPECTED_EARNINGS_SHARE_FAILURE = '@@expected-earnings-share/CREATE_EXPECTED_EARNINGS_SHARE_FAILURE',
  CREATE_EXPECTED_EARNINGS_SHARE_REQUEST = '@@expected-earnings-share/CREATE_EXPECTED_EARNINGS_SHARE_REQUEST',
  CREATE_EXPECTED_EARNINGS_SHARE_SUCCESS = '@@expected-earnings-share/CREATE_EXPECTED_EARNINGS_SHARE_SUCCESS',

  GET_EXPECTED_EARNINGS_SHARES_BY_SUB_ACCOUNT_ID_FAILURE = '@@expected-earnings-share/GET_EXPECTED_EARNINGS_SHARES_BY_SUB_ACCOUNT_ID_FAILURE',
  GET_EXPECTED_EARNINGS_SHARES_BY_SUB_ACCOUNT_ID_REQUEST = '@@expected-earnings-share/GET_EXPECTED_EARNINGS_SHARES_BY_SUB_ACCOUNT_ID_REQUEST',
  GET_EXPECTED_EARNINGS_SHARES_BY_SUB_ACCOUNT_ID_SUCCESS = '@@expected-earnings-share/GET_EXPECTED_EARNINGS_SHARES_BY_SUB_ACCOUNT_ID_SUCCESS',

  GET_EXPECTED_EARNINGS_SHARES_FAILURE = '@@expected-earnings-share/GET_EXPECTED_EARNINGS_SHARES_FAILURE',
  GET_EXPECTED_EARNINGS_SHARES_REQUEST = '@@expected-earnings-share/GET_EXPECTED_EARNINGS_SHARES_REQUEST',
  GET_EXPECTED_EARNINGS_SHARES_SUCCESS = '@@expected-earnings-share/GET_EXPECTED_EARNINGS_SHARES_SUCCESS',
}

export interface ExpectedEarningsShareState {
  bySubAccountUuid: { [key: string]: ExpectedEarningsShare[] };
  rows: ExpectedEarningsShare[];
}
