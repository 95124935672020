import { action, createAsyncAction } from 'typesafe-actions';

import type { PortalSupplyContract } from '@portal/api-models';
import type { PortalError } from '@portal/common';
import type { SupplyContractFormData } from '@portal/ui-lib';

import type { IsomorphicRequestPayload } from '../types';

import { SupplyContractsActionType } from './types';

interface GetAllResponsePayload {
  response: PortalSupplyContract[];
}

interface GetByUuidResponsePayload {
  response: PortalSupplyContract[];
  uuid: string;
}

interface GetByUuidRequestPayload extends IsomorphicRequestPayload {
  uuid: string;
}

export const getSupplyContractsAll = createAsyncAction(
  SupplyContractsActionType.GET_ALL_REQUEST,
  SupplyContractsActionType.GET_ALL_SUCCESS,
  SupplyContractsActionType.GET_ALL_FAILURE
)<IsomorphicRequestPayload, GetAllResponsePayload, PortalError>();

export const getSupplyContractsBySubaccountUuidAction = createAsyncAction(
  SupplyContractsActionType.GET_SUPPLY_CONTRACTS_BY_SUBACCOUNT_UUID_REQUEST,
  SupplyContractsActionType.GET_SUPPLY_CONTRACTS_BY_SUBACCOUNT_UUID_SUCCESS,
  SupplyContractsActionType.GET_SUPPLY_CONTRACTS_BY_SUBACCOUNT_UUID_FAILURE
)<GetByUuidRequestPayload, GetByUuidResponsePayload, PortalError>();

export const supplyContractToggleCreate = () =>
  action(SupplyContractsActionType.SUPPLY_CONTRACT_TOGGLE_CREATE);

export const supplyContractDuplicate = (form: SupplyContractFormData) =>
  action(SupplyContractsActionType.SUPPLY_CONTRACT_DUPLICATE, form);
