"use strict";
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.leadHydrationSaga = exports.deleteCreditCardLeadHydrationConfig = exports.createCreditCardLeadHydrationConfig = exports.createLeadHydrationConfig = exports.getLeadHydrationConfigBySubAccountUuid = void 0;
var antd_1 = require("antd");
var effects_1 = require("redux-saga/effects");
var typesafe_actions_1 = require("typesafe-actions");
var api_client_1 = require("@portal/api-client");
var actions_1 = require("./actions");
function getLeadHydrationConfigBySubAccountUuid(_a) {
    var response, err_1;
    var subAccountUuid = _a.payload.subAccountUuid;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 5]);
                return [4, (0, effects_1.call)([api_client_1.client, 'get'], "/third-party-data/lead-hydration/".concat(subAccountUuid))];
            case 1:
                response = (_b.sent()).data;
                return [4, (0, effects_1.put)(actions_1.getLeadHydrationConfigsBySubAccountUuidAction.success({
                        response: response,
                        subAccountUuid: subAccountUuid,
                    }))];
            case 2:
                _b.sent();
                return [3, 5];
            case 3:
                err_1 = _b.sent();
                return [4, (0, effects_1.put)(actions_1.getLeadHydrationConfigsBySubAccountUuidAction.failure(err_1))];
            case 4:
                _b.sent();
                return [3, 5];
            case 5: return [2];
        }
    });
}
exports.getLeadHydrationConfigBySubAccountUuid = getLeadHydrationConfigBySubAccountUuid;
function createLeadHydrationConfig(_a) {
    var newLeadHydrationConfig, err_2;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4, (0, effects_1.call)([api_client_1.client, 'post'], '/third-party-data/lead-hydration/', payload)];
            case 1:
                newLeadHydrationConfig = (_b.sent()).data;
                return [4, (0, effects_1.put)(actions_1.createLeadHydrationConfigAction.success(newLeadHydrationConfig))];
            case 2:
                _b.sent();
                return [4, (0, effects_1.put)(actions_1.getLeadHydrationConfigsBySubAccountUuidAction.request({
                        subAccountUuid: payload.subAccountUuid || '',
                    }))];
            case 3:
                _b.sent();
                return [3, 6];
            case 4:
                err_2 = _b.sent();
                return [4, (0, effects_1.put)(actions_1.createLeadHydrationConfigAction.failure(err_2))];
            case 5:
                _b.sent();
                return [3, 6];
            case 6: return [2];
        }
    });
}
exports.createLeadHydrationConfig = createLeadHydrationConfig;
function createCreditCardLeadHydrationConfig(_a) {
    var newLeadHydrationConfigCC, err_3;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 5]);
                return [4, (0, effects_1.call)([api_client_1.client, 'post'], '/third-party-data/credit-card-lead-hydration/', payload)];
            case 1:
                newLeadHydrationConfigCC = (_b.sent()).data;
                return [4, (0, effects_1.put)(actions_1.createCreditCardLeadHydrationConfigAction.success(newLeadHydrationConfigCC))];
            case 2:
                _b.sent();
                return [3, 5];
            case 3:
                err_3 = _b.sent();
                return [4, (0, effects_1.put)(actions_1.createCreditCardLeadHydrationConfigAction.failure(err_3))];
            case 4:
                _b.sent();
                return [3, 5];
            case 5: return [2];
        }
    });
}
exports.createCreditCardLeadHydrationConfig = createCreditCardLeadHydrationConfig;
function deleteCreditCardLeadHydrationConfig(_a) {
    var err_4;
    var _b = _a.payload, id = _b.id, subAccountUuid = _b.subAccountUuid;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 4, , 6]);
                return [4, (0, effects_1.call)([api_client_1.client, 'delete'], "/third-party-data/credit-card-lead-hydration/".concat(id))];
            case 1:
                _c.sent();
                return [4, (0, effects_1.put)(actions_1.getLeadHydrationConfigsBySubAccountUuidAction.request({
                        subAccountUuid: subAccountUuid,
                    }))];
            case 2:
                _c.sent();
                return [4, (0, effects_1.call)(antd_1.message.success, 'The credit card lead hydration configuration was deleted')];
            case 3:
                _c.sent();
                return [3, 6];
            case 4:
                err_4 = _c.sent();
                return [4, (0, effects_1.put)(actions_1.deleteCreditCardLeadHydrationConfigAction.failure(err_4))];
            case 5:
                _c.sent();
                return [3, 6];
            case 6: return [2];
        }
    });
}
exports.deleteCreditCardLeadHydrationConfig = deleteCreditCardLeadHydrationConfig;
function leadHydrationSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, (0, effects_1.takeLatest)((0, typesafe_actions_1.getType)(actions_1.getLeadHydrationConfigsBySubAccountUuidAction.request), getLeadHydrationConfigBySubAccountUuid)];
            case 1:
                _a.sent();
                return [4, (0, effects_1.takeEvery)((0, typesafe_actions_1.getType)(actions_1.createLeadHydrationConfigAction.request), createLeadHydrationConfig)];
            case 2:
                _a.sent();
                return [4, (0, effects_1.takeEvery)((0, typesafe_actions_1.getType)(actions_1.createCreditCardLeadHydrationConfigAction.request), createCreditCardLeadHydrationConfig)];
            case 3:
                _a.sent();
                return [4, (0, effects_1.takeLeading)((0, typesafe_actions_1.getType)(actions_1.deleteCreditCardLeadHydrationConfigAction.request), deleteCreditCardLeadHydrationConfig)];
            case 4:
                _a.sent();
                return [2];
        }
    });
}
exports.leadHydrationSaga = leadHydrationSaga;
