import { createSelector } from 'reselect';

import type { ApplicationState } from '..';

export const selectOriginatorSlice = (state: ApplicationState) =>
  state.originator;

export const getPropsUUID = (_state: ApplicationState, { uuid }) => uuid;

export const selectRateTableByLeadUuid = createSelector(
  [getPropsUUID, selectOriginatorSlice],
  (uuid, originator) => originator.rateTablesByLeadUuid?.[uuid]?.rows ?? []
);

export const getWorkingDemandApiFilters = createSelector(
  [getPropsUUID, selectOriginatorSlice],
  (uuid, originator) => {
    return originator.ruleDemandApiFilters.bySubAccountUuid?.[uuid]?.working;
  }
);
export const selectSupplyTemplatesKeyedByUuid = createSelector(
  [selectOriginatorSlice],
  (originator) => {
    return originator.supplyTemplates;
  }
);

export const selectSupplyTemplates = createSelector(
  [selectSupplyTemplatesKeyedByUuid],
  (supplyTemplates) => Object.values(supplyTemplates ?? {})
);

export const selectActiveSupplyTemplates = createSelector(
  [selectSupplyTemplates],
  (supplyTemplates) => supplyTemplates?.filter(({ deletedAt }) => !deletedAt)
);

export const selectSupplyTemplateByUUID = createSelector(
  [getPropsUUID, selectSupplyTemplatesKeyedByUuid],
  (uuid, supplyTemplates) => {
    return supplyTemplates?.[uuid];
  }
);

export const selectSupplyTemplateApplicationsBySubAccountUUID = createSelector(
  [getPropsUUID, selectOriginatorSlice],
  (uuid, originator) => {
    return originator.supplyTemplateApplications[uuid];
  }
);

export const selectHaveSupplyTemplatesBeenRequested = createSelector(
  [selectOriginatorSlice],
  ({ haveSupplyTemplatesBeenRequested }) => haveSupplyTemplatesBeenRequested
);
