import type { ReactNode } from 'react';
import * as React from 'react';

import { ResponsiveBar } from '@nivo/bar';
import type { BarSvgProps, BarTooltipProps, ValueFormatter } from '@nivo/bar';
import merge from 'lodash/merge';

import ChartTooltip from '@/components/reporting/chart/tooltip';
import type { ChartDatum } from '@/resources/performance-summary/chart-helpers';
import { colors, theme } from '@/resources/performance-summary/nivo-theme';

interface BarChartProps {
  barProps: BarSvgProps<ChartDatum>;
}

const barTooltipFormat = (
  value: number,
  formatter: string | ValueFormatter | undefined
): ReactNode => {
  if (formatter && typeof formatter === 'string') {
    return formatter;
  }

  if (formatter && typeof formatter === 'function') {
    return formatter(value);
  }

  return value;
};

const BarChart: React.FC<BarChartProps> = ({ barProps }) => {
  const { axisLeft, valueFormat } = barProps;

  const defaults = {
    animate: false,
    axisBottom: {
      tickPadding: 25,
      tickRotation: 0,
      tickSize: 10,
    },
    axisLeft: { legendOffset: -100, legendPosition: 'middle', tickSize: 10 },
    colors,
    enableLabel: false,
    indexBy: 'x',
    keys: ['y'],
    margin: { bottom: 70, left: 120, right: 50, top: 10 },
    padding: 0.5,
    theme,
    tooltip: ({ indexValue, value }: BarTooltipProps<ChartDatum>) => (
      <ChartTooltip
        label={indexValue}
        legend={axisLeft?.legend || ''}
        value={barTooltipFormat(value, valueFormat)}
      />
    ),
  };

  return (
    <div className="chart bar-chart">
      <div className="chart-inner bar-chart-inner">
        <ResponsiveBar {...merge(defaults, barProps)} />
      </div>
    </div>
  );
};

export default BarChart;
