import * as React from 'react';
import { useEffect } from 'react';

import AntdSelect from 'antd/lib/select';
import type { SelectValue } from 'antd/lib/select';
import has from 'lodash/has';
import startCase from 'lodash/startCase';

import { resolvePredicate, useForm } from '@/components/form/portal-form';
import { useControllable } from '@/components/form/portal-form/lib/use-controllable';
import type {
  Option,
  OptionOrGroup,
  Options,
  OptionsGroup,
  SelectFieldProps,
} from '@/components/form/portal-form/types';
import { sorter } from '@/components/table/util-methods';

const { Option: AntdOption, OptGroup: AntdOptionGroup } = AntdSelect;

export const enumValuesToOptions = <
  E extends { [P in keyof E]: string | number }
>(
  Enum: E,
  format?: ((value: string) => string) | false,
  disableOptions?: string[],
  isNumeric?: boolean
): Options<E[keyof E]> =>
  Object.values(Enum)
    .filter((value) => {
      if (isNumeric) {
        return typeof value === 'number';
      }

      return true;
    })
    .map((value) => ({
      disabled: disableOptions?.includes(value as string),
      label:
        format === false
          ? (value as string)
          : format?.(`${value}`) ?? startCase(`${value}`),
      value: value as E[keyof E],
    }));

export const enumLabelMapToOptions = <E extends string | number>(
  labelMap: Record<E, string>
): Options<E> =>
  Object.entries(labelMap).map(([value, label]) => ({
    label: label as string,
    value: value as E,
  }));

export const labelSorter = (a: Option, b: Option) => sorter(a.label, b.label);
const renderOption = ({ value, label, disabled }: Option) => (
  <AntdOption key={value} disabled={disabled} value={value}>
    {label}
  </AntdOption>
);
const renderOptionsGroup = ({ key, label, options }: OptionsGroup) => (
  <AntdOptionGroup key={key} label={label}>
    {options.map((option) => renderOption(option))}
  </AntdOptionGroup>
);

/**
 * @template FormType
 * @template OptionType
 * @param {OptionOrGroup<FormType, OptionType>[]} options
 * @param {FormType} formValues
 * @return {OptionOrGroup<FormType, OptionType>[]}
 */
const filterOptions = <FormType extends {}, OptionType = any>(
  options: OptionOrGroup<FormType, OptionType>[],
  formValues: FormType
): OptionOrGroup<FormType, OptionType>[] =>
  options.reduce(
    (
      accum: OptionOrGroup<FormType, OptionType>[],
      optionOrGroup: OptionOrGroup<FormType, OptionType>
    ) => {
      if (
        optionOrGroup.predicate === undefined ||
        (optionOrGroup.predicate !== undefined &&
          resolvePredicate(optionOrGroup.predicate, formValues))
      ) {
        if (has(optionOrGroup, 'options')) {
          const group = optionOrGroup as OptionsGroup<OptionType, FormType>;

          accum.push({
            ...group,
            options: filterOptions(group.options, formValues),
          } as OptionsGroup<OptionType, FormType>);
        } else {
          accum.push({ ...optionOrGroup });
        }
      }

      return accum;
    },
    []
  );

/**
 * @template FormType
 * @template OptionType
 * @param {SelectValue} searchValue
 * @param {OptionOrGroup<FormType, OptionType>[]} options
 * @return {boolean}
 */
const findValueInOptions = <FormType extends {}, OptionType = any>(
  searchValue: SelectValue,
  options: OptionOrGroup<FormType, OptionType>[]
): boolean =>
  options.some((optionOrGroup) => {
    if (has(optionOrGroup, 'options')) {
      return (optionOrGroup as OptionsGroup).options.some(
        (option) => option.value === searchValue
      );
    }

    return (optionOrGroup as Option).value === searchValue;
  });

const Select = <FormType extends {} = {}, OptionType = any>({
  options = [],
  formDeps,
  ...props
}: SelectFieldProps<FormType, OptionType>) => {
  const { values: formValues } = useForm();

  const [value, setValue] = useControllable<SelectValue>({
    defaultValue: props.defaultValue as SelectValue,
    onChange: props.onChange,
    value: props.value,
  });

  const filteredOptions = filterOptions(options, formValues);

  const deps = formDeps?.map((formDep) => formValues[formDep]) ?? [];

  useEffect(() => {
    if (value) {
      let hasOptionWithValue;

      if (Array.isArray(value)) {
        hasOptionWithValue = (value as string[]).every((val) =>
          findValueInOptions(val, filteredOptions)
        );
      } else {
        hasOptionWithValue = findValueInOptions(value, filteredOptions);
      }

      if (!hasOptionWithValue) {
        setValue(undefined as any);
      }
    }
  }, deps);

  return (
    <AntdSelect
      {...props}
      value={value}
      onChange={(nextValue) => setValue(nextValue)}
    >
      {filteredOptions.map((optionOrGroup) => {
        if (has(optionOrGroup, 'options')) {
          return renderOptionsGroup(optionOrGroup as OptionsGroup);
        }

        return renderOption(optionOrGroup as Option);
      })}
    </AntdSelect>
  );
};

export default Select;
