import type { ServerResponse } from 'http';

import { action, createAction, createAsyncAction } from 'typesafe-actions';

import type {
  AccessTokenWithSecret,
  Application,
} from '@evenfinancial/auth-client';
import type {
  AccountTierType,
  DemandSubAccountDeactivation,
  ProductType,
  SupplySubAccountDeactivation,
} from '@evenfinancial/finance-client';
import type { SupplyTemplateProductType } from '@evenfinancial/originator-client';

import type {
  ComplianceItem,
  DemandSupplyPartner,
  PartnerPageAlooma,
  PortalSubAccount,
} from '@portal/api-models';
import type {
  ApplicationTokenCreateFormState,
  SupplySubAccountCreateFormData,
} from '@portal/ui-lib';

import type { UUIDOnlyPayload } from '@/store/originator/types';

import type { DemandSubAccountCreateForm } from '../../pages/demand-sub-accounts/create';
import type { IsomorphicRequestPayload } from '../types';

import {
  DemandSubAccountActionType,
  SubAccountSharedActionType,
  SupplySubAccountActionType,
} from './actionTypes';

/**
 * Shared Actions
 */

export type SubAccountUuidRequestPayload = IsomorphicRequestPayload &
  UUIDOnlyPayload;

export interface SubAccountComplianceItemsResponse extends UUIDOnlyPayload {
  complianceItems: ComplianceItem[];
}
export interface InitDemandSubAccountsDetailPayload extends UUIDOnlyPayload {
  productType: ProductType;
  suffix: string;
  tab: string;
}

export interface SubAccountApplicationsByUuidResponse extends UUIDOnlyPayload {
  accessTokens: AccessTokenWithSecret[];
  availableScopes: string[];
  partnerKeys: PartnerPageAlooma[];
  primaryApplication: Application;
}

export interface SubAccountCreatedTokenResponse extends UUIDOnlyPayload {
  tokens: Record<string, AccessTokenWithSecret>;
}

type SupplyPartnersByUuidRequestPayload = IsomorphicRequestPayload &
  UUIDOnlyPayload;

interface SupplyPartnersByUuidSuccessPayload extends UUIDOnlyPayload {
  partners: DemandSupplyPartner[];
}

export interface DemandPartnersBySupplyTemplateProductType
  extends IsomorphicRequestPayload {
  query: {
    productType?: SupplyTemplateProductType;
    tiers?: AccountTierType[];
  };
}

export interface DemandPartnersBySupplySubAccountUuidType
  extends IsomorphicRequestPayload {
  subaccount_uuid: string;
}

export const subAccountByUuidAction = createAsyncAction(
  SubAccountSharedActionType.BY_UUID_REQUEST,
  SubAccountSharedActionType.BY_UUID_SUCCESS,
  SubAccountSharedActionType.BY_UUID_FAILURE
)<
  SubAccountUuidRequestPayload & { res?: ServerResponse },
  PortalSubAccount,
  Error
>();

export const subAccountComplianceRequestAction = createAsyncAction(
  SubAccountSharedActionType.COMPLIANCE_ITEMS_BY_UUID_REQUEST,
  SubAccountSharedActionType.COMPLIANCE_ITEMS_BY_UUID_SUCCESS,
  SubAccountSharedActionType.COMPLIANCE_ITEMS_BY_UUID_FAILURE
)<SubAccountUuidRequestPayload, SubAccountComplianceItemsResponse, Error>();

/**
 * Demand Actions
 */

export const demandSubAccountRequestAction = createAsyncAction(
  DemandSubAccountActionType.GET_ALL_REQUEST,
  DemandSubAccountActionType.GET_ALL_SUCCESS,
  DemandSubAccountActionType.GET_ALL_FAILURE
)<IsomorphicRequestPayload, PortalSubAccount[], Error>();

export const lenderDemandSubAccountRequestAction = createAsyncAction(
  DemandSubAccountActionType.GET_ALL_LENDERS_REQUEST,
  DemandSubAccountActionType.GET_ALL_LENDERS_SUCCESS,
  DemandSubAccountActionType.GET_ALL_LENDERS_FAILURE
)<DemandPartnersBySupplyTemplateProductType, PortalSubAccount[], Error>();

export const migratedLenderDemandSubAccountRequestAction = createAsyncAction(
  DemandSubAccountActionType.GET_ALL_MIGRATED_LENDERS_REQUEST,
  DemandSubAccountActionType.GET_ALL_MIGRATED_LENDERS_SUCCESS,
  DemandSubAccountActionType.GET_ALL_MIGRATED_LENDERS_FAILURE
)<DemandPartnersBySupplySubAccountUuidType, PortalSubAccount[], Error>();

export const demandSubAccountCreateRequestAction = createAsyncAction(
  DemandSubAccountActionType.CREATE_REQUEST_CLIENT,
  DemandSubAccountActionType.CREATE_SUCCESS_CLIENT,
  DemandSubAccountActionType.CREATE_FAILURE_CLIENT
)<DemandSubAccountCreateForm, PortalSubAccount, Error>();

export const demandSubAccountUpdateRequestAction = createAsyncAction(
  DemandSubAccountActionType.UPDATE_REQUEST,
  DemandSubAccountActionType.UPDATE_SUCCESS,
  DemandSubAccountActionType.UPDATE_FAILURE
)<
  IsomorphicRequestPayload & Partial<PortalSubAccount>,
  PortalSubAccount,
  Error
>();

export const demandSubAccountSupplyPartnersByUuidRequestAction =
  createAsyncAction(
    DemandSubAccountActionType.SUPPLY_PARTNERS_BY_UUID_REQUEST,
    DemandSubAccountActionType.SUPPLY_PARTNERS_BY_UUID_SUCCESS,
    DemandSubAccountActionType.SUPPLY_PARTNERS_BY_UUID_FAILURE
  )<
    SupplyPartnersByUuidRequestPayload,
    SupplyPartnersByUuidSuccessPayload,
    Error
  >();

export const demandSubAccountDeactivationRequestAction = createAsyncAction(
  DemandSubAccountActionType.GET_ALL_DEMAND_SUB_ACCOUNT_DEACTIVATION_REQUEST,
  DemandSubAccountActionType.GET_ALL_DEMAND_SUB_ACCOUNT_DEACTIVATION_SUCCESS,
  DemandSubAccountActionType.GET_ALL_DEMAND_SUB_ACCOUNT_DEACTIVATION_FAILURE
)<IsomorphicRequestPayload, DemandSubAccountDeactivation[], Error>();

export const createDemandSubAccountDeactivationsAction = createAsyncAction(
  DemandSubAccountActionType.CREATE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_REQUEST,
  DemandSubAccountActionType.CREATE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_SUCCESS,
  DemandSubAccountActionType.CREATE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_FAILURE
)<{ demandSubAccountIds: number[] }, DemandSubAccountDeactivation[], Error>();

export const deleteDemandSubAccountDeactivationAction = createAsyncAction(
  DemandSubAccountActionType.DELETE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_REQUEST,
  DemandSubAccountActionType.DELETE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_SUCCESS,
  DemandSubAccountActionType.DELETE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_FAILURE
)<{ deactivationIds: number[] }, DemandSubAccountDeactivation[], Error>();

export const demandToggleCreate = createAction(
  DemandSubAccountActionType.TOGGLE_CREATE
)<undefined>();
export const demandToggleDuplicate = createAction(
  DemandSubAccountActionType.TOGGLE_DUPLICATE
)<string>();

/**
 * Supply Actions
 */

export const supplySubAccountRequestAction = createAsyncAction(
  SupplySubAccountActionType.GET_ALL_REQUEST,
  SupplySubAccountActionType.GET_ALL_SUCCESS,
  SupplySubAccountActionType.GET_ALL_FAILURE
)<IsomorphicRequestPayload, PortalSubAccount[], Error>();

export const supplySubAccountCreateRequestAction = createAsyncAction(
  SupplySubAccountActionType.CREATE_REQUEST_CLIENT,
  SupplySubAccountActionType.CREATE_SUCCESS_CLIENT,
  SupplySubAccountActionType.CREATE_FAILURE_CLIENT
)<SupplySubAccountCreateFormData, PortalSubAccount, Error>();

export const supplySubAccountUpdateRequestAction = createAsyncAction(
  SupplySubAccountActionType.UPDATE_REQUEST,
  SupplySubAccountActionType.UPDATE_SUCCESS,
  SupplySubAccountActionType.UPDATE_FAILURE
)<
  IsomorphicRequestPayload & Partial<PortalSubAccount>,
  PortalSubAccount,
  Error
>();

export const supplySubAccountApplicationTokenCreateRequestAction =
  createAsyncAction(
    SupplySubAccountActionType.APPLICATION_TOKEN_CREATE_REQUEST_CLIENT,
    SupplySubAccountActionType.APPLICATION_TOKEN_CREATE_SUCCESS_CLIENT,
    SupplySubAccountActionType.APPLICATION_TOKEN_CREATE_FAILURE_CLIENT
  )<ApplicationTokenCreateFormState, SubAccountCreatedTokenResponse, Error>();

export const subAccountApplicationsByUuidAction = createAsyncAction(
  SupplySubAccountActionType.APPLICATIONS_BY_UUID_REQUEST,
  SupplySubAccountActionType.APPLICATIONS_BY_UUID_SUCCESS,
  SupplySubAccountActionType.APPLICATIONS_BY_UUID_FAILURE
)<SubAccountUuidRequestPayload, SubAccountApplicationsByUuidResponse, Error>();

export const supplySubAccountDeactivationRequestAction = createAsyncAction(
  SupplySubAccountActionType.GET_ALL_SUPPLY_SUB_ACCOUNT_DEACTIVATION_REQUEST,
  SupplySubAccountActionType.GET_ALL_SUPPLY_SUB_ACCOUNT_DEACTIVATION_SUCCESS,
  SupplySubAccountActionType.GET_ALL_SUPPLY_SUB_ACCOUNT_DEACTIVATION_FAILURE
)<IsomorphicRequestPayload, SupplySubAccountDeactivation[], Error>();

export const createSupplySubAccountDeactivationsAction = createAsyncAction(
  SupplySubAccountActionType.CREATE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_REQUEST,
  SupplySubAccountActionType.CREATE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_SUCCESS,
  SupplySubAccountActionType.CREATE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_FAILURE
)<{ supplySubAccountIds: number[] }, SupplySubAccountDeactivation[], Error>();

export const deleteSupplySubAccountDeactivationAction = createAsyncAction(
  SupplySubAccountActionType.DELETE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_REQUEST,
  SupplySubAccountActionType.DELETE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_SUCCESS,
  SupplySubAccountActionType.DELETE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_FAILURE
)<{ deactivationIds: number[] }, SupplySubAccountDeactivation[], Error>();

export const initDemandSubAccountsDetailsPage = createAction(
  DemandSubAccountActionType.INIT_DEMAND_SUB_ACCOUNT_DETAILS_PAGE
)<InitDemandSubAccountsDetailPayload>();

export const supplyToggleCreate = createAction(
  SupplySubAccountActionType.TOGGLE_CREATE
)<undefined>();

export const supplyToggleDuplicate = createAction(
  SupplySubAccountActionType.TOGGLE_DUPLICATE
)<string>();

export const supplyToggleDuplicateOpen = createAction(
  SupplySubAccountActionType.TOGGLE_DUPLICATE_OPEN
)<string>();

export const supplyToggleApplicationTokenCreate = () =>
  action(SupplySubAccountActionType.APPLICATION_TOKEN_TOGGLE_CREATE);

export const confirmCreatedApiTokens = createAction(
  SupplySubAccountActionType.APPLICATION_TOKEN_CONFIRM
)<UUIDOnlyPayload>();
