import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Layout } from 'antd';

import { viewContextSelector } from '@portal/store/dist/view-context/selectors';

import AlertBar from '@/components/alert-bar';
import Header from '@/components/header';
import SideNav from '@/components/side-nav';
import Footnote from '@/pages/footnote';
import { getSideNavFullScreen } from '@/store/interface/selectors';

const { Content } = Layout;

const LayoutSFC: React.FC = ({ children }) => {
  const [layoutHeight, setLayoutHeight] = useState('100%');
  const fullscreenMode = useSelector(getSideNavFullScreen);
  const {
    showCrmUserSideNav,
    showDemandUserSideNav,
    showExternalUserSideNav,
    showInternalUserSideNav,
    showSelfServiceUserSideNav,
    showFootnote,
    showBenchmarkReportsMenuItem,
    showMCIReportsMenuItem,
  } = useSelector(viewContextSelector);

  return (
    <>
      <AlertBar onHeightChange={(h) => setLayoutHeight(`calc(100% - ${h}px`)} />
      <Layout
        className="portal-layout ant-layout-has-sider"
        style={{ height: layoutHeight, minHeight: layoutHeight }}
      >
        <SideNav
          showBenchmarkReportsMenuItem={showBenchmarkReportsMenuItem}
          showCrmUserSideNav={showCrmUserSideNav}
          showDemandUserSideNav={showDemandUserSideNav}
          showExternalUserSideNav={showExternalUserSideNav}
          showInternalUserSideNav={showInternalUserSideNav}
          showMCIReportsMenuItem={showMCIReportsMenuItem}
          showSelfServiceUserSideNav={showSelfServiceUserSideNav}
        />
        <div className="portal-content-wrapper">
          <Header />
          <div className="portal-content-wrapper-body">
            <Content className="portal-content">{children}</Content>
            {!fullscreenMode && showFootnote && <Footnote />}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default LayoutSFC;
