import uniq from 'lodash/uniq';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import type { RichAccessToken } from '@evenfinancial/auth-client';

import { client } from '@portal/api-client';
import type { PortalPreSelectRule } from '@portal/api-models';
import { richAccessTokenByUuidRequestAction } from '@portal/store/dist/user';

import * as actions from './actions';
import { PreSelectRuleActionType } from './types';

function* getPreSelectRules() {
  try {
    const PreSelectRules: PortalPreSelectRule[] = yield call(async () => {
      return client
        .get<PortalPreSelectRule[]>('/originator/preselect_rules', {
          params: {
            limit: 1e5,
          },
        })
        .then((res) => res.data);
    });
    const accessTokenUuids = uniq(PreSelectRules.map((c) => c.createdBy));
    const rats = yield call(async () =>
      client
        .post<RichAccessToken>('/auth/rich_access_tokens', {
          accessTokenUuids,
        })
        .then((res) => res.data)
    );

    yield put(richAccessTokenByUuidRequestAction.success(rats));
    yield put(actions.preSelectRuleRequestAction.success(PreSelectRules));
  } catch (err: any) {
    yield put(actions.preSelectRuleRequestAction.failure(err));
  }
}

function* watchGetAllRequest() {
  yield takeEvery(PreSelectRuleActionType.GET_ALL_REQUEST, getPreSelectRules);
}

export function* preSelectRuleSaga() {
  yield all([fork(watchGetAllRequest)]);
}
