import * as React from 'react';
import { useEffect, useState } from 'react';

import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { PortalFormFieldContext } from '@/components/form/portal-form/lib/portal-form-field-context';
import type { IPortalFormFieldContext } from '@/components/form/portal-form/lib/portal-form-field-context';
import type { FieldComponentProps } from '@/components/form/portal-form/types';

export interface RepeatableItemProps<
  ValueType,
  ItemProps = { [x: string]: unknown }
> extends FieldComponentProps<ValueType> {
  ItemComponent: React.ComponentType<FieldComponentProps<ValueType>>;
  index: number;
  itemProps?: ItemProps;
  itemTitle?: ((index: number) => string) | string;
  onChange?: (value: ValueType, index: number) => void;
  onDelete?: (index: number) => void;
  showDelete?: boolean;
}

const RepeatableItem = <
  ValueType extends any,
  ItemProps = { [x: string]: unknown }
>(
  props: RepeatableItemProps<ValueType, ItemProps>
) => {
  const {
    ItemComponent,
    index,
    value,
    onDelete,
    onChange,
    itemTitle,
    showDelete,
    itemProps,
  } = props;

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  const fieldContext: IPortalFormFieldContext = {
    setFieldValue: () => {},
  };

  return (
    <div className={classNames('repeatable-item', { 'is-visible': show })}>
      <div className="repeatable-item-header">
        <h3 className="repeatable-item-title">
          {(typeof itemTitle === 'function' ? itemTitle(index) : itemTitle) ||
            `Item ${index + 1}`}
        </h3>
        {showDelete && (
          <div
            className="repeatable-item-remove"
            onClick={() => onDelete?.(index)}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </div>
        )}
      </div>

      <PortalFormFieldContext.Provider value={fieldContext}>
        <ItemComponent
          value={value}
          onChange={(value) => {
            onChange?.(value, index);
          }}
          {...itemProps}
        />
      </PortalFormFieldContext.Provider>
    </div>
  );
};

export default RepeatableItem;
