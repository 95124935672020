import { createSelector } from 'reselect';

import type { PartnerPageAlooma } from '@portal/api-models';
import { ComplianceStatus, IntegrationStatus } from '@portal/api-models';
import { selectAccounts } from '@portal/store/dist/account/selectors';
import { selectPermissions } from '@portal/store/dist/user/selectors';

import type { PartnerPageCreateForm } from '@/pages/partner-page/create';
import { selectComplianceRows } from '@/resources/compliance/selects';
import { getComplianceInternal } from '@/resources/compliance/utils';
import { selectPartnerPages } from '@/resources/partner-keys/selects';
import { selectSupplySubAccountsKeyedByCompanyUuid } from '@/resources/supply-sub-accounts/shared/selects';
import type { ApplicationState } from '@/store';

export interface PartnerKeyRow extends PartnerPageAlooma {
  accountName?: string;
  accountUuid?: string;
  complianceStatus?: ComplianceStatus;
  integrationStatus?: IntegrationStatus;
  /**
   * TODO: integrationType needs to be better typed/separated out.
   * This is sometimes returning an IntegrationCategory enum and sometimes returning
   * an IntegrationType enum.
   */
  integrationType?: string;
  subAccountName?: string;
  subAccountUuid?: string;
  versionName?: string;
}

export const tableDataSelector = createSelector(
  [
    selectPermissions,
    selectPartnerPages,
    selectSupplySubAccountsKeyedByCompanyUuid,
    selectComplianceRows,
    selectAccounts,
  ],
  (
    { shouldComputeInternalPartnerKeyData },
    partnerKeys,
    supplySubAccountsByCompanyUuid,
    compliances,
    accounts
  ) => {
    return partnerKeys.flatMap((pkr: PartnerKeyRow) => {
      const subAccountRecord =
        supplySubAccountsByCompanyUuid?.[pkr.companyUuid];

      if (!subAccountRecord) {
        return [] as PartnerKeyRow[];
      }

      const { uuid, name, integrationType, accountId } = subAccountRecord;

      pkr.subAccountUuid = uuid;
      pkr.subAccountName = name;
      pkr.integrationType = integrationType;

      if (shouldComputeInternalPartnerKeyData) {
        const account = accounts.find(({ id }) => id === accountId);
        const complianceInternal = getComplianceInternal(
          pkr.companyUuid,
          compliances
        );

        pkr.integrationStatus =
          pkr?.integrationStatus ?? IntegrationStatus.Draft;
        pkr.complianceStatus =
          (complianceInternal?.status as ComplianceStatus) ??
          ComplianceStatus.draft;
        pkr.accountName = account?.name || 'n/a';
        pkr.accountUuid = account?.uuid;
      }

      switch (pkr.version) {
        case '7':
          pkr.versionName = 'Even';
          break;
        case '8':
          pkr.versionName = 'Fiona';
          break;
        case '9':
          pkr.versionName = 'LeapLife';
          break;
        default:
          pkr.versionName = 'Legacy';
      }

      return pkr;
    });
  }
);

export const duplicatePartnerKeyDataSelector = createSelector(
  [
    ({ partnerPages }: ApplicationState) => partnerPages,
    ({ subAccounts }: ApplicationState) => subAccounts?.supply,
    selectPartnerPages,
  ],
  (partnerPages, supply, partnerKeys) => {
    const pkr = partnerKeys.find(
      ({ id }: PartnerKeyRow) => id === partnerPages.duplicateId
    );

    if (!pkr) {
      return;
    }

    const ssa = supply.find(
      ({ companyUuid }) => companyUuid === pkr.companyUuid
    );

    if (!ssa) {
      return;
    }

    if (parseInt(pkr.version, 10) < 7) {
      pkr.version = '';
    }

    return {
      duplicateId: partnerPages.duplicateId as any,
      partner: pkr.partner_key,
      productType: pkr.product,
      supplySubAccountUuid: ssa.uuid,
      version: pkr.version,
    } as PartnerPageCreateForm;
  }
);
