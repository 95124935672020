const { Route, DemandMVPOfferManagementRoute } = require('@portal/common');

/**
 * @readonly
 * @enum {string} Roles
 */
const Roles = {
  DemandUser: 'Demand User',
  ExternalUser: 'External User',
  Growth: 'Growth',
  InternalUser: 'Internal User',
  Scale: 'Scale',
  SelfService: 'Self Service',
  Starter: 'Starter',
  SupplyUser: 'Supply User',
};

/**
 * @readonly
 * @enum {string} RouteGuards
 */
const RouteGuards = {
  MigrationTrial: 'check_user_migration_trial',
  OnboardingFlow: 'check_onboarding_flow',
  PlanTrial: 'check_user_trial',
  RestrictCRM: 'restrict_crm',
};

/**x
 * @typedef {Object} UserMeta
 * @property {Roles[]} roles
 * @property {string[]} scopes
 * @property {string[]} featureFlags
 */

/**
 * @typedef {(meta: UserMeta) => boolean} CanAccess
 */

/**
 * @template T
 * @typedef {Object} GuardObject<T>
 * @property {T[]} [all] - Succeeds if all guards pass.
 * @property {T[]} [any] - Succeeds if any guard passes.
 */

/**
 * @template T
 * @typedef {T[] | GuardObject<T>} GuardAttribute<T>
 */

/**
 * @typedef {Object} AuthRoute
 * @property {string} name - The route name.
 * @property {string} [pattern = name] - The route pattern.
 * @property {string} [page] - The path to the page component.
 * @property {GuardAttribute<Roles>} [roles] - Restrict page access based on user roles.
 * @property {GuardAttribute<string>} [scopes] - Restrict page access based on user scopes.
 * @property {GuardAttribute<string>} [featureFlags] - Restrict page access based on user feature flags.
 * @property {GuardAttribute<RouteGuards>} [guards] - Set of guard rules by which we determine should the user be able to access the page.
 * @property {CanAccess} [canAccess] - Custom restriction callback that is supplied with current user's access rights.
 */

/**
 * @param {string[]} haystack
 * @param {string[]} needles
 * @returns {boolean}
 */
const includesAll = (haystack, needles) =>
  needles.every((value) => haystack.includes(value));

/**
 * @type CanAccess
 */
const canAccessHomepage = ({ roles, featureFlags }) => {
  if (
    roles.includes(Roles.InternalUser) ||
    roles.includes(Roles.SelfService) ||
    roles.includes(Roles.Scale) ||
    includesAll(roles, [Roles.ExternalUser, Roles.SupplyUser])
  ) {
    return true;
  }

  if (includesAll(roles, [Roles.ExternalUser, Roles.DemandUser])) {
    return (
      featureFlags.includes('demand_homepage') ||
      featureFlags.includes('demand_cc_mvp')
    );
  }

  return false;
};

/**
 * @type AuthRoute[]
 */
const routes = [
  {
    name: 'login-error',
  },
  {
    name: 'sign-out',
  },
  {
    canAccess: canAccessHomepage,
    name: 'home',
    pattern: '/home',
  },
  {
    featureFlags: ['onboarding'],
    guards: [RouteGuards.RestrictCRM],
    name: 'welcome',
    page: 'welcome/index',
    pattern: '/welcome',
    roles: [Roles.SelfService],
  },
  {
    featureFlags: ['onboarding'],
    guards: [RouteGuards.RestrictCRM],
    name: 'welcome-step',
    page: 'welcome/index',
    pattern: '/welcome/:step',
    roles: [Roles.SelfService],
  },
  {
    name: 'analytics-self-service',
    page: 'accounts/internal/analytics-self-service',
    pattern: '/analytics/self-service',
    roles: [Roles.InternalUser],
  },
  {
    name: 'accounts',
    page: 'accounts/internal/index',
    pattern: '/accounts',
    roles: [Roles.InternalUser],
  },
  {
    guards: [RouteGuards.MigrationTrial, RouteGuards.RestrictCRM],
    name: 'users',
    page: 'users/index',
    pattern: '/users',
    roles: [
      Roles.InternalUser,
      Roles.SelfService,
      Roles.DemandUser,
      Roles.Scale,
    ],
  },
  {
    guards: [RouteGuards.MigrationTrial, RouteGuards.RestrictCRM],
    name: 'users-details',
    page: 'users/details',
    pattern: '/users/:uuid/:tab?',
    roles: [
      Roles.InternalUser,
      Roles.SelfService,
      Roles.DemandUser,
      Roles.Scale,
    ],
  },
  {
    name: 'account-details',
    page: 'accounts/details',
    pattern: '/accounts/:uuid/:tab?',
  },
  {
    name: 'partner-keys',
    page: 'partner-page/index',
    pattern: '/partner-keys',
    roles: [Roles.InternalUser],
  },
  // NOTICE: This route is not needed for application,
  // but it blocks file-system based routing.
  {
    name: 'partner-page',
    page: 'partner-page/index',
    pattern: '/partner-page',
    roles: [Roles.InternalUser],
  },
  {
    name: 'partner-key-update',
    page: 'partner-page/update',
    pattern: '/partner-keys/:id/:tab?',
  },
  {
    name: 'supply-sub-accounts',
    page: 'supply-sub-accounts/index',
    pattern: '/supply-sub-accounts',
    roles: [Roles.InternalUser],
  },
  {
    name: 'supply-sub-account-details',
    page: 'supply-sub-accounts/details',
    pattern: '/supply-sub-accounts/:uuid/:tab?',
    roles: [Roles.InternalUser, Roles.SupplyUser],
  },
  {
    name: 'demand-sub-accounts',
    page: 'demand-sub-accounts/index',
    pattern: '/demand-sub-accounts',
    roles: [Roles.InternalUser],
  },
  {
    name: 'demand-sub-accounts-details',
    page: 'demand-sub-accounts/details',
    pattern: '/demand-sub-accounts/:uuid/:tab?',
    roles: [Roles.InternalUser, Roles.DemandUser],
  },
  {
    featureFlags: ['demand_cc_mvp'],
    name: 'demand-sub-account-contract-details',
    page: 'contracts/demand/details',
    pattern: '/demand-sub-accounts/:uuid/contracts/:id?',
    roles: [Roles.DemandUser],
  },
  {
    name: 'channels-contracts',
    page: 'channels/contracts/index',
    pattern: '/channels/contracts',
    roles: [Roles.InternalUser],
  },
  {
    name: 'yield-optimizer',
    page: 'channels/yield-optimizer/index',
    pattern: '/channels/yield-optimizer',
    roles: [Roles.InternalUser],
  },
  {
    featureFlags: ['benchmark_reports'],
    name: 'benchmark-reports',
    page: 'benchmark-reports/index',
    pattern: '/benchmark-reports/:report?',
    roles: [Roles.InternalUser, Roles.DemandUser],
  },
  {
    featureFlags: ['mci_reports'],
    name: 'mci-reports',
    page: 'offer-management/personal-loans/mci-reports/offers/index',
    pattern: Route.MciReports,
    roles: [Roles.InternalUser, Roles.DemandUser],
  },
  {
    name: 'feature-flags',
    page: 'feature-flags/index',
    pattern: '/feature-flags',
    roles: [Roles.InternalUser],
  },
  {
    name: 'feature-flag-details',
    page: 'feature-flags/details',
    pattern: '/feature-flags/:uuid',
    roles: [Roles.InternalUser],
  },
  {
    name: 'payables',
    page: 'billing/payables/index',
    pattern: '/billing/payables',
    roles: [Roles.InternalUser],
  },
  {
    name: 'payables-details',
    page: '/billing/payables/details',
    pattern: '/billing/payables/:id',
    roles: [Roles.InternalUser],
  },
  {
    name: 'payable-adjustments',
    page: 'billing/payable-adjustments/index',
    pattern: '/billing/payable-adjustments',
    roles: [Roles.InternalUser],
  },
  {
    name: 'receivables',
    page: 'billing/receivables/index',
    pattern: '/billing/receivables',
    roles: [Roles.InternalUser],
  },
  {
    name: 'receivables-details',
    page: '/billing/receivables/details',
    pattern: '/billing/receivables/:id',
    roles: [Roles.InternalUser],
  },
  {
    name: 'receivables-adjustments',
    page: 'billing/receivables-adjustments/index',
    pattern: '/billing/receivables-adjustments',
    roles: [Roles.InternalUser],
  },
  {
    name: 'data-imports',
    page: 'billing/tools/data-imports/index',
    pattern: '/billing/tools/data-imports',
    roles: [Roles.InternalUser],
  },
  {
    name: 'imported-lead-events',
    page: 'billing/tools/imported-lead-events/index',
    pattern: '/billing/tools/imported-lead-events',
    roles: [Roles.InternalUser],
  },
  {
    name: 'imported-channel-events',
    page: 'billing/tools/imported-channel-events/index',
    pattern: '/billing/tools/imported-channel-events',
    roles: [Roles.InternalUser],
  },
  {
    name: 'leads',
    page: 'leads/index',
    pattern: '/leads',
    roles: [Roles.InternalUser],
  },
  {
    name: 'leads-details',
    page: 'leads/details',
    pattern: '/leads/:uuid/:tab?',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-personal-loans-home',
    page: 'offer-management/personal-loans/dashboard/index',
    pattern: '/offer-management/personal-loans/home',
    roles: [Roles.InternalUser],
  },
  {
    name: 'personal-loans-configurations',
    page: 'offer-management/personal-loans/configurations/index',
    pattern: '/offer-management/personal-loans/configurations',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-personal-loans-compliance',
    page: 'offer-management/personal-loans/compliance/index',
    pattern: '/offer-management/personal-loans/compliance',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-personal-loans-contracts',
    page: 'offer-management/personal-loans/contracts/index',
    pattern: '/offer-management/personal-loans/contracts',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-personal-loans-preview-offers',
    page: 'offer-management/personal-loans/preview-offers/index',
    pattern: '/offer-management/personal-loans/preview-offers',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-personal-loans-lead-filtering-preview-offer-rules',
    page: 'offer-management/personal-loans/lead-filtering/preview-offer-rules/index',
    pattern:
      '/offer-management/personal-loans/lead-filtering/preview-offer-rules',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-personal-loans-lead-filtering-lender-provided-filters',
    page: '/offer-management/personal-loans/lead-filtering/lender-provided-filters/index',
    pattern:
      '/offer-management/personal-loans/lead-filtering/lender-provided-filters',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-personal-loans-pricing-controls',
    page: 'offer-management/personal-loans/pricing-controls/index',
    pattern: '/offer-management/personal-loans/pricing-controls',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-personal-loans-capping',
    page: 'offer-management/personal-loans/capping',
    pattern: '/offer-management/personal-loans/capping',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-personal-loans-benchmark-reports',
    page: 'benchmark-reports/index',
    pattern: '/offer-management/personal-loans/benchmark-reports',
    roles: [Roles.InternalUser, Roles.DemandUser],
  },
  {
    featureFlags: ['mci_reports'],
    name: 'offer-management-personal-loans-mci-reports-offers',
    page: 'offer-management/personal-loans/mci-reports/offers/index',
    pattern: Route.OfferManagement_PersonalLoans_MciOffersReports,
    roles: [Roles.InternalUser, Roles.DemandUser],
  },
  {
    featureFlags: ['mci_reports'],
    name: 'offer-management-personal-loans-mci-reports-rejections',
    page: 'offer-management/personal-loans/mci-reports/rejections/index',
    pattern: Route.OfferManagement_PersonalLoans_MciRejectionsReports,
    roles: [Roles.InternalUser, Roles.DemandUser],
  },
  {
    featureFlags: ['mci_reports'],
    name: 'offer-management-personal-loans-mci-reports-conversions',
    page: 'offer-management/personal-loans/mci-reports/conversions/index',
    pattern: Route.OfferManagement_PersonalLoans_MciConversionsReports,
    roles: [Roles.InternalUser, Roles.DemandUser],
  },
  {
    name: 'life-insurance-configurations',
    page: 'offer-management/life-insurance/configurations/index',
    pattern: '/offer-management/life-insurance/configurations',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-life-insurance-contracts',
    page: '/offer-management/life-insurance/contracts/index',
    pattern: '/offer-management/life-insurance/contracts',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-life-insurance-benchmark-reports',
    page: 'benchmark-reports/index',
    pattern: '/offer-management/life-insurance/benchmark-reports',
    roles: [Roles.InternalUser],
  },

  {
    name: 'credit-cards-configurations',
    page: 'offer-management/credit-cards/configurations/index',
    pattern: '/offer-management/credit-cards/configurations',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-credit-cards-compliance',
    page: 'offer-management/credit-cards/compliance/index',
    pattern: '/offer-management/credit-cards/compliance',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-credit-cards-contracts',
    page: 'offer-management/credit-cards/contracts/index',
    pattern: '/offer-management/credit-cards/contracts',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-credit-cards-product-offers',
    page: '/offer-management/credit-cards/product-offers/index',
    pattern: '/offer-management/credit-cards/product-offers',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-credit-cards-product-offers-details',
    page: '/offer-management/credit-cards/product-offers/details',
    pattern: '/offer-management/credit-cards/product-offers/:uuid',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-credit-cards-lead-filtering-product-offer-rules',
    page: 'offer-management/credit-cards/lead-filtering/product-offer-rules/index',
    pattern:
      '/offer-management/credit-cards/lead-filtering/product-offer-rules',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-credit-cards-lead-filtering-matching-criteria',
    page: 'offer-management/credit-cards/lead-filtering/matching-criteria/index',
    pattern: '/offer-management/credit-cards/lead-filtering/matching-criteria',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-credit-cards-capping',
    page: 'offer-management/credit-cards/capping',
    pattern: '/offer-management/credit-cards/capping',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-credit-cards-benchmark-reports',
    page: 'benchmark-reports/index',
    pattern: '/offer-management/credit-cards/benchmark-reports',
    roles: [Roles.InternalUser],
  },
  {
    name: 'savings-configurations',
    page: 'offer-management/savings/configurations/index',
    pattern: '/offer-management/savings/configurations',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-savings-compliance',
    page: 'offer-management/savings/compliance/index',
    pattern: '/offer-management/savings/compliance',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-savings-contracts',
    page: '/offer-management/savings/contracts/index',
    pattern: '/offer-management/savings/contracts',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-savings-product-offers',
    page: '/offer-management/savings/product-offers/index',
    pattern: '/offer-management/savings/product-offers',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-savings-lead-filtering',
    page: '/offer-management/savings/product-offers-rules/index',
    pattern: '/offer-management/savings/lead-filtering',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-savings-capping',
    page: 'offer-management/savings/capping',
    pattern: '/offer-management/savings/capping',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-savings-benchmark-reports',
    page: 'benchmark-reports/index',
    pattern: '/offer-management/savings/benchmark-reports',
    roles: [Roles.InternalUser],
  },
  {
    name: 'custom-offers-configurations',
    page: 'offer-management/custom-offers/configurations/index',
    pattern: '/offer-management/custom-offers/configurations',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-custom-offers-contracts',
    page: 'offer-management/custom-offers/contracts/index',
    pattern: '/offer-management/custom-offers/contracts',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-custom-offers-offers',
    page: '/offer-management/custom-offers/offers/index',
    pattern: '/offer-management/custom-offers/offers',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-custom-offers-lead-filtering-offer-rules',
    page: 'offer-management/custom-offers/lead-filtering/product-offer-rules/index',
    pattern: '/offer-management/custom-offers/lead-filtering/offer-rules',
    roles: [Roles.InternalUser],
  },
  {
    name: 'offer-management-custom-offers-benchmark-reports',
    page: 'benchmark-reports/index',
    pattern: '/offer-management/custom-offers/benchmark-reports',
    roles: [Roles.InternalUser],
  },
  {
    name: 'channels-performance-summary',
    page: 'channels/performance-summary/index',
    pattern: '/channels/performance-summary',
    roles: [Roles.InternalUser, Roles.SupplyUser],
  },
  {
    name: 'channels-channels',
    page: 'supply-sub-accounts/index',
    pattern: '/channels/channels',
    roles: [Roles.InternalUser],
  },
  {
    name: 'channels-compliance',
    page: 'channels/compliance/index',
    pattern: '/channels/compliance',
    roles: [Roles.InternalUser],
  },
  {
    name: 'channels-template-center',
    page: 'channels/template-center/index',
    pattern: '/channels/template-center',
    roles: [Roles.InternalUser],
  },
  {
    name: 'channels-template-center-create',
    page: 'channels/template-center/create',
    pattern: '/channels/template-center/new',
    roles: [Roles.InternalUser],
  },
  {
    name: 'channels-template-center-edit',
    page: 'channels/template-center/edit',
    pattern: '/channels/template-center/edit/:uuid',
    roles: [Roles.InternalUser],
  },
  {
    name: 'channels-template-center-details',
    page: 'channels/template-center/details',
    pattern: '/channels/template-center/:uuid',
    roles: [Roles.InternalUser],
  },
  {
    name: 'channels-integrations',
    page: 'partner-page/index',
    pattern: '/channels/integrations',
    roles: [Roles.InternalUser],
  },
  {
    guards: [RouteGuards.RestrictCRM],
    name: 'account-self-service-upgrade',
    page: 'self-service/account/upgrade/index',
    pattern: '/account/upgrade',
    roles: [Roles.SelfService],
  },
  {
    guards: [RouteGuards.RestrictCRM],
    name: 'account-self-service-upgrade-scale',
    page: 'self-service/account/upgrade-scale/index',
    pattern: '/account/upgrade-scale',
    roles: [Roles.SelfService],
  },
  {
    guards: [RouteGuards.RestrictCRM],
    name: 'account-self-service',
    page: 'self-service/account/index',
    pattern: '/account/:tab?',
    roles: [Roles.SelfService, Roles.Scale],
  },
  {
    guards: [RouteGuards.PlanTrial, RouteGuards.RestrictCRM],
    name: 'integrations-self-service',
    page: 'self-service/integrations/index',
    pattern: '/integrations',
    roles: [Roles.SelfService, Roles.Scale],
  },
  {
    guards: [RouteGuards.PlanTrial, RouteGuards.RestrictCRM],
    name: 'self-service-partner-page-wizard',
    page: 'self-service/integrations/partner-page-wizard/index',
    pattern: '/integrations/:id/wizard',
    roles: [Roles.SelfService, Roles.Scale],
  },
  {
    guards: [RouteGuards.PlanTrial, RouteGuards.RestrictCRM],
    name: 'integration-self-service-integration-details',
    page: 'self-service/integrations/details',
    pattern: '/integrations/:id/:tab?',
    roles: [Roles.SelfService],
  },
  {
    guards: [RouteGuards.OnboardingFlow, RouteGuards.RestrictCRM],
    name: 'compliance-self-service',
    page: 'self-service/compliance/index',
    pattern: '/compliance',
    roles: [Roles.SelfService, Roles.Scale],
  },
  {
    guards: [RouteGuards.OnboardingFlow],
    name: 'performance-summary-self-service',
    page: 'performance-summary/index',
    pattern: '/performance-summary',
    roles: [Roles.SelfService, Roles.Scale],
  },
  {
    guards: [RouteGuards.OnboardingFlow, RouteGuards.RestrictCRM],
    name: 'self-service-offer-management-custom-offers',
    page: 'self-service/offer-management/custom-offers',
    pattern: '/financial-services/custom-offers',
    roles: [Roles.SelfService, Roles.Scale],
  },
  {
    guards: [RouteGuards.PlanTrial, RouteGuards.RestrictCRM],
    name: 'self-service-offer-management-product-offers-personal-loan',
    page: 'self-service/offer-management/product-offers/personal-loans',
    pattern: '/financial-services/personal-loans',
    roles: [Roles.SelfService, Roles.Scale],
  },
  {
    guards: [RouteGuards.OnboardingFlow, RouteGuards.RestrictCRM],
    name: 'self-service-offer-management-product-offers-life-insurance',
    page: 'self-service/offer-management/product-offers/life-insurance',
    pattern: '/financial-services/life-insurance',
    roles: [Roles.SelfService, Roles.Scale],
  },
  {
    featureFlags: ['demand_cc_mvp'],
    name: 'demand-offer-management-fs-life-insurance',
    page: 'demand/offer-management/financial-services/life-insurance',
    pattern: '/offer-management/financial-services/life-insurance',
    roles: [Roles.DemandUser],
  },
  {
    featureFlags: ['demand_cc_mvp'],
    name: 'demand-offer-management-fs-credit-cards',
    page: 'demand/offer-management/financial-services/credit-cards',
    pattern: '/offer-management/financial-services/credit-cards',
    roles: [Roles.DemandUser],
  },
  {
    featureFlags: ['demand_cc_mvp'],
    name: 'demand-offer-management-fs-savings',
    page: 'demand/offer-management/financial-services/savings',
    pattern: '/offer-management/financial-services/savings',
    roles: [Roles.DemandUser],
  },
  {
    featureFlags: ['demand_cc_mvp'],
    name: 'demand-offer-management-fs-student-loan-refi',
    page: 'demand/offer-management/financial-services/student-loan-refi',
    pattern: '/offer-management/financial-services/student-loan-refi',
    roles: [Roles.DemandUser],
  },
  {
    featureFlags: ['demand_cc_mvp'],
    name: 'demand-offer-management-fs-auto-loan-refi',
    page: 'demand/offer-management/financial-services/auto-loan-refi',
    pattern: '/offer-management/financial-services/auto-loan-refi',
    roles: [Roles.DemandUser],
  },
  {
    featureFlags: ['demand_cc_mvp'],
    name: 'demand-offer-management-custom-offers',
    page: 'demand/offer-management/custom-offers',
    pattern: '/offer-management/custom-offers',
    roles: [Roles.DemandUser],
  },
  {
    featureFlags: ['demand_cc_mvp'],
    name: 'demand-offer-management-personal-loans-benchmark-reports',
    page: 'benchmark-reports/index',
    pattern:
      '/offer-management/financial-services/personal-loans/data-and-analytics/benchmark-reports/:report?',
    roles: [Roles.DemandUser],
  },
  {
    featureFlags: ['demand_cc_mvp'],
    name: 'demand-offer-management-personal-loans-mci-offers-reports',
    page: 'offer-management/personal-loans/mci-reports/offers/index',
    pattern: DemandMVPOfferManagementRoute.PersonalLoansMCIOffersReports,
    roles: [Roles.DemandUser],
  },
  {
    featureFlags: ['demand_cc_mvp'],
    name: 'demand-offer-management-personal-loans-mci-rejections-reports',
    page: 'offer-management/personal-loans/mci-reports/rejections/index',
    pattern: DemandMVPOfferManagementRoute.PersonalLoansMCIRejectionsReports,
    roles: [Roles.DemandUser],
  },
  {
    featureFlags: ['demand_cc_mvp'],
    name: 'demand-offer-management-personal-loans-mci-conversions-reports',
    page: 'offer-management/personal-loans/mci-reports/conversions/index',
    pattern: DemandMVPOfferManagementRoute.PersonalLoansMCIConversionsReports,
    roles: [Roles.DemandUser],
  },
  {
    featureFlags: ['demand_cc_mvp'],
    name: 'demand-offer-management-personal-loans-dashboard',
    page: 'offer-management/personal-loans/dashboard/index',
    pattern: '/offer-management/financial-services/personal-loans/dashboard',
    roles: [Roles.DemandUser],
  },
  {
    guards: [RouteGuards.OnboardingFlow, RouteGuards.RestrictCRM],
    name: 'self-service-offer-management-product-offers-credit-cards',
    page: 'self-service/offer-management/product-offers/credit-cards',
    pattern: '/financial-services/credit-cards',
    roles: [Roles.SelfService, Roles.Scale],
  },
  {
    guards: [RouteGuards.OnboardingFlow, RouteGuards.RestrictCRM],
    name: 'self-service-offer-management-product-offers-savings',
    page: 'self-service/offer-management/product-offers/savings',
    pattern: '/financial-services/savings',
    roles: [Roles.SelfService, Roles.Scale],
  },
  {
    guards: [RouteGuards.OnboardingFlow, RouteGuards.RestrictCRM],
    name: 'self-service-offer-management-product-offers-student-loan-refinance',
    page: 'self-service/offer-management/product-offers/student-loan-ref',
    pattern: '/financial-services/student-loan-refi',
    roles: [Roles.SelfService, Roles.Scale],
  },
  {
    guards: [RouteGuards.OnboardingFlow, RouteGuards.RestrictCRM],
    name: 'self-service-offer-management-product-offers-auto-loan-refinance',
    page: 'self-service/offer-management/product-offers/auto-loan-ref',
    pattern: '/financial-services/auto-loan-refi',
    roles: [Roles.SelfService, Roles.Scale],
  },
  {
    guards: [RouteGuards.OnboardingFlow, RouteGuards.RestrictCRM],
    name: 'invoices-self-service',
    page: 'self-service/invoices/index',
    pattern: '/invoices',
    roles: [Roles.SelfService, Roles.Scale],
  },
  {
    guards: [RouteGuards.RestrictCRM],
    name: 'purchase-plan-self-service',
    page: 'self-service/purchase-plan/index',
    pattern: '/purchase-plan',
    roles: [Roles.SelfService],
  },
  {
    guards: [RouteGuards.PlanTrial, RouteGuards.RestrictCRM],
    name: 'pay-implementation-fee',
    page: 'self-service/payment/integration-fee/index',
    pattern: '/implementation-fee',
    roles: [Roles.SelfService],
  },
  {
    name: 'supply-migration',
    page: 'supply-migration',
    pattern: '/supply-migration',
    roles: [Roles.SupplyUser, Roles.Reporter],
  },
  {
    name: 'whitelist',
    page: 'whitelist/index',
    pattern: '/whitelist',
    roles: [Roles.InternalUser],
  },
  {
    name: 'premium-lenders',
    page: 'premium-lenders/index',
    pattern: '/premium-lenders',
    roles: [Roles.InternalUser],
  },
  {
    featureFlags: [
      'guide_users',
      'guide_integrations',
      'guide_performance_summary',
      'guide_homepage',
    ],
    name: 'self-service-guide',
    page: 'self-service/guide/index',
    pattern: '/guide/:path?',
    roles: [Roles.SelfService],
  },
  {
    featureFlags: ['demand_cc_mvp'],
    name: 'invoices-demand-user',
    page: 'demand/invoices/index',
    pattern: 'demand/invoices',
    roles: [Roles.DemandUser],
  },
];

// eslint-disable-next-line no-undef
module.exports = routes;
