import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { getAccounts } from '@portal/store/dist/account/sagas';
import {
  getDemandHomeAction,
  getSupplyHomeAction,
} from '@portal/store/dist/home/actions';
import { getUsers } from '@portal/store/dist/user/sagas';

import { getPartnerKeys } from '@/store/partner-page/sagas';
import {
  getDemandSubAccounts,
  getSupplySubAccounts,
} from '@/store/subaccount/sagas';
import { getSaasMigrationSettingsByAuthContext } from '@/store/supply-migration/sagas';

export function* getDemandHomeSaga() {
  try {
    yield all([
      yield call(getUsers),
      yield call(getAccounts),
      yield call(getDemandSubAccounts),
    ]);

    yield put(getDemandHomeAction.success());
  } catch {
    yield put(getDemandHomeAction.failure());
  }
}

export function* getSupplyHomeSaga() {
  try {
    yield all([
      yield call(getUsers),
      yield call(getAccounts),
      yield call(getSaasMigrationSettingsByAuthContext),
      yield call(getSupplySubAccounts),
      yield call(getPartnerKeys),
    ]);

    yield put(getSupplyHomeAction.success());
  } catch {
    yield put(getSupplyHomeAction.failure());
  }
}

export function* homeSaga() {
  yield takeLatest(getType(getDemandHomeAction.request), getDemandHomeSaga);
  yield takeLatest(getType(getSupplyHomeAction.request), getSupplyHomeSaga);
}
