import type { FC } from 'react';
import { useQuery } from 'react-query';

import { Select } from 'antd';

import { queries } from '@portal/api-client';

export interface SubAccountOfferCatalogProductTypeDropdownProvidedProps {
  defaultValue?: string | string[];
  disabled?: boolean;
  onChange?: (
    value: string | string[],
    option: React.ReactNode | React.ReactNode[]
  ) => void;
  placeholder?: string;
  value?: string | string[];
}

export const SubAccountOfferCatalogProductTypeDropdown: FC<
  SubAccountOfferCatalogProductTypeDropdownProvidedProps
> = (props) => {
  const { defaultValue, disabled, onChange, placeholder, value } = props;

  const productsQuery = useQuery(queries.ews.offerCatalog.products());
  const offerCatalogProductTypes = productsQuery.data ?? [];

  const selectOptions = offerCatalogProductTypes.map((product) => (
    <Select.Option key={`oc-product-type-${product.type}`} value={product.type}>
      {product.displayName}
    </Select.Option>
  ));

  return (
    <Select
      allowClear
      filterOption
      showSearch
      defaultValue={defaultValue}
      disabled={disabled}
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
      optionFilterProp="children"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    >
      {selectOptions}
    </Select>
  );
};
