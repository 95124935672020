"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseVersion = exports.parseUuid = void 0;
const class_validator_1 = require("class-validator");
const lodash_1 = require("lodash");
const parseUuid = (uuid) => {
    if (!(0, lodash_1.isString)(uuid)) {
        return null;
    }
    if (!(0, class_validator_1.isUUID)(uuid)) {
        return null;
    }
    return uuid;
};
exports.parseUuid = parseUuid;
const parseVersion = (version) => {
    if (!(0, lodash_1.isNumber)(version) && !(0, class_validator_1.isNumberString)(version)) {
        return null;
    }
    return (0, lodash_1.toNumber)(version);
};
exports.parseVersion = parseVersion;
