import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import {
  faCopy,
  faEllipsisVertical,
  faWindowRestore,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, Menu, message } from 'antd';
import type { ColumnProps } from 'antd/lib/table';
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';

import { ProductSubType } from '@evenfinancial/lead-client';

import {
  ComplianceIntegrationType,
  IntegrationStatus,
} from '@portal/api-models';
import { PortalClipboard, PortalClipboardType } from '@portal/ui-lib';

import type { OpenActionModal } from '@/components/self-service/home/integrations-card';
import { StatusDotCompliance } from '@/components/status-dot-compliance';
import {
  createActionsColumn,
  createDropdownColumn,
  createLinkColumn,
  createStatusColumn,
} from '@/components/table/columns';
import { renderTags } from '@/components/table/lib/column-renderers';
import type { IntegrationRow } from '@/resources/integrations/types';
import {
  createButtonColumn,
  createIntegrationTypeColumn,
} from '@/resources/self-service/home/integrations-table-utils';
import { IntegrationCategory } from '@/resources/sub-accounts-shared/utils';
import { Link } from '@/routes';

export const getProductSubTypeText = (
  productSubType: ProductSubType
): string => {
  switch (productSubType) {
    case ProductSubType.StudentLoanRefinance:
      return 'Student Loan Refinancing';
    case ProductSubType.AutomobileRefinance:
      return 'Auto Loan Refinancing';
    default:
      return productSubType.toString();
  }
};

export const tableConfig = (
  integrations: IntegrationRow[],
  planPurchased: boolean,
  openEmbedCodeCopyModal: (id?: string) => void,
  openActionModal: OpenActionModal,
  embedExists: boolean,
  hidePartnerPageStatuses: boolean,
  showIntegrationActions: boolean,
  showIntegrationCopyOverride: boolean
): Array<ColumnProps<IntegrationRow>> => {
  return [
    {
      className: 'integrations-card-icon-column',
      key: 'start_icon',
      render: () => (
        <span>
          <FontAwesomeIcon
            className="integrations-card-icon-column-icon"
            icon={faWindowRestore}
            style={{ height: 16, width: 16 }}
          />
        </span>
      ),
      title: '',
    },
    createLinkColumn<IntegrationRow>({
      baseUrl: 'integrations',
      dataIndex: 'partner_key',
      filterKey: 'partner_key',
      key: 'id',
      sorter: false,
      title: 'Integration Key',
      urlSuffix: '/wizard',
    }),
    createIntegrationTypeColumn({
      embedExists,
      key: 'integrationType',
      rows: integrations,
      sorter: false,
      title: 'Integration Type',
    }),
    {
      key: 'product',
      render: (_field: any, row: IntegrationRow) => {
        const tags = [row.product.toString()];

        if (row.productSubType) {
          tags.push(getProductSubTypeText(row.productSubType));
        }

        return renderTags(tags);
      },
      rows: integrations,
      sorter: false,
      title: 'Product Type',
    },
    {
      dataIndex: 'integrationUrl',
      key: 'integrationUrl',
      render: (
        _field: any,
        {
          id,
          integrationType,
          integrationUrl,
          integrationStatus,
        }: IntegrationRow
      ) => {
        if (
          integrationStatus !== IntegrationStatus.Live &&
          !showIntegrationCopyOverride
        ) {
          return truncate(integrationUrl, { length: 32 });
        }

        return integrationType === IntegrationCategory.Embed ? (
          <a onClick={() => openEmbedCodeCopyModal(id)}>
            Copy Embed Code <FontAwesomeIcon icon={faCopy} />
          </a>
        ) : (
          <PortalClipboard
            maxLength={32}
            text={integrationUrl}
            type={PortalClipboardType.BlueTextIcon}
          />
        );
      },
      title: embedExists ? 'Asset' : 'URL',
    },
    createDropdownColumn({
      hidden: hidePartnerPageStatuses,
      key: 'complianceStatus',
      render: (status) => {
        return <StatusDotCompliance status={status} />;
      },
      rows: integrations,
      sorter: false,
      title: 'Compliance Status',
    }),
    createStatusColumn({
      hidden: hidePartnerPageStatuses,
      key: 'integrationStatus',
      rows: integrations,
      sorter: false,
      title: 'Integration Status',
    }),
    createButtonColumn({
      callback: openActionModal,
      planPurchased,
      showIntegrationActions,
    }),
    createActionsColumn({
      key: 'actions',
      render: (
        _text: any,
        {
          id,
          integrationType,
          integrationUrl,
          integrationStatus,
        }: IntegrationRow
      ) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                {integrationStatus === IntegrationStatus.Live && (
                  <Menu.Item>
                    <a href={integrationUrl} rel="noreferrer" target="_blank">
                      Preview
                    </a>
                  </Menu.Item>
                )}
                {integrationStatus === IntegrationStatus.Live && (
                  <Menu.Item>
                    {integrationType === ComplianceIntegrationType.embed ? (
                      <a onClick={() => openEmbedCodeCopyModal(id)}>
                        Copy Embed Code
                      </a>
                    ) : (
                      <CopyToClipboard
                        text={integrationUrl}
                        onCopy={() =>
                          message.success('Copied to clipboard!', 1)
                        }
                      >
                        <a>Copy Partner page URL</a>
                      </CopyToClipboard>
                    )}
                  </Menu.Item>
                )}
                <Menu.Item>
                  <Link route={`/integrations/${id}/wizard`}>
                    <a>Customize Theme</a>
                  </Link>
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
            trigger={['hover']}
          >
            <Button
              icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
              type="text"
            />
          </Dropdown>
        );
      },
      title: '',
    }),
  ].filter((item) => !isEmpty(item));
};
