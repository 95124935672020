import * as React from 'react';

import { faCircleDollar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import numeral from 'numeral';

import {
  ApplicationsIcon,
  ClicksIcon,
  LeadsIcon,
  PreApprovedIcon,
} from '@portal/ui-lib';

import type { TopTotalsPanelItem } from '@/components/reporting/top-totals-panel';

export const creditCardFunnelPanelConfig: TopTotalsPanelItem[] = [
  {
    icon: <LeadsIcon />,
    key: 'numLeads',
    title: 'Leads',
    tooltip:
      'Count of total number of users per integration that start the application. A user is only counted if they start an application',
  },
  {
    icon: <ApplicationsIcon />,
    key: 'numLeadsSubmittedApp',
    title: 'Submitted Applications',
    tooltip:
      'Count of leads who fill out all fields and submit an application (e.g., user clicks “Get Offers” button)',
  },
  {
    icon: <ClicksIcon />,
    key: 'numLeadsClicked',
    title: 'Unique Offer Clicks',
    tooltip: 'Total count of unique clicks for credit card offers',
  },
  {
    icon: <PreApprovedIcon />,
    key: 'numLeadsApproved',
    title: 'Approvals',
    tooltip: 'Count of total credit card approvals from issuer partners',
  },
  {
    formatter: (value) => numeral(value).format('$0,0.00'),
    icon: <FontAwesomeIcon icon={faCircleDollar} />,
    key: 'totalPayout',
    title: 'Attributed Earnings',
    tooltip:
      'Estimated value of payout based on the leads attributed to the dates in the report. Please note that Attributed Earnings will vary from your Total Earnings value because credit card applications often take several days to process. You can view your actual payout at any time in the Earnings Report under “Total Earnings”',
  },
];
