"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.originatorQueries = void 0;
var utils_1 = require("../utils");
var admin_1 = require("./admin");
var static_offers_1 = require("./static_offers");
__exportStar(require("./types"), exports);
exports.originatorQueries = {
    admin: {
        getStaticOfferWithRules: (0, utils_1.makeQueryWithVars)({
            queryFn: admin_1.getStaticOfferWithRules,
            queryKey: 'originatorStaticOffersWithRules',
        }),
        getStaticOffersWithRules: (0, utils_1.makeQueryWithVars)({
            queryFn: admin_1.getStaticOffersWithRules,
            queryKey: 'originatorStaticOffersWithRulesChunked',
        }),
        offerRules: (0, utils_1.makeQueryWithVars)({
            queryFn: admin_1.getOfferRules,
            queryKey: 'originatorOfferRules',
        }),
        staticOffer: (0, utils_1.makeQueryWithVars)({
            queryFn: admin_1.getStaticOffer,
            queryKey: 'originatorStaticOffers',
        }),
    },
    staticOffer: {
        getStaticOffersByProductType: (0, utils_1.makeQueryWithVars)({
            queryFn: static_offers_1.getStaticOffersByProductType,
            queryKey: 'originatorStaticOffersByProductType',
        }),
    },
};
