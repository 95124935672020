import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import type { ActionType } from 'typesafe-actions';

import type { RichAccessToken } from '@evenfinancial/auth-client';

import { client } from '@portal/api-client';
import { getPortalError } from '@portal/common';
import type { OfferRuleProduct } from '@portal/common/dist/originator/models';
import { richAccessTokenByUuidRequestAction } from '@portal/store/dist/user';

import * as actions from '../actions';

function* getOfferRulesProductByProductType(
  action: ActionType<typeof actions.getOfferRulesProductByProductType.request>
) {
  const { productType } = action.payload;

  try {
    const response: OfferRuleProduct[] = yield call(async () => {
      return client
        .get<OfferRuleProduct[]>('/originator/offer_rules_product', {
          params: { productTypes: [productType] },
        })
        .then((res) => res.data);
    });
    const accessTokenUuids = compact(
      uniq(response.map((c) => c.updatedBy || c.createdByUuid))
    );
    const rats = yield call(async () =>
      client
        .post<RichAccessToken>('/auth/rich_access_tokens', {
          accessTokenUuids,
        })
        .then((res) => res.data)
    );

    yield put(richAccessTokenByUuidRequestAction.success(rats));
    yield put(
      actions.getOfferRulesProductByProductType.success({
        productType,
        response,
      })
    );
  } catch (err: any) {
    const portalError = getPortalError(err);

    yield put(actions.getOfferRulesProductByProductType.failure(portalError));
  }
}

export function* offerRulesSaga() {
  yield takeLatest(
    getType(actions.getOfferRulesProductByProductType.request),
    getOfferRulesProductByProductType
  );
}
