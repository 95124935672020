export enum DemandInclusionActionType {
  APPLY_DEMAND_INCLUSION_TEMPLATE_FAILURE = '@@demandInclusion/APPLY_DEMAND_INCLUSION_TEMPLATE_FAILURE',
  APPLY_DEMAND_INCLUSION_TEMPLATE_REQUEST = '@@demandInclusion/APPLY_DEMAND_INCLUSION_TEMPLATE_REQUEST',
  APPLY_DEMAND_INCLUSION_TEMPLATE_SUCCESS = '@@demandInclusion/APPLY_DEMAND_INCLUSION_TEMPLATE_SUCCESS',

  CREATE_BULK_DEMAND_INCLUSIONS_FAILURE = '@@demandInclusion/CREATE_BULK_DEMAND_INCLUSIONS_FAILURE',
  CREATE_BULK_DEMAND_INCLUSIONS_REQUEST = '@@demandInclusion/CREATE_BULK_DEMAND_INCLUSIONS_REQUEST',
  CREATE_BULK_DEMAND_INCLUSIONS_SUCCESS = '@@demandInclusion/CREATE_BULK_DEMAND_INCLUSIONS_SUCCESS',

  CREATE_DEMAND_INCLUSIONS_FAILURE = '@@demandInclusion/CREATE_DEMAND_INCLUSIONS_FAILURE',
  CREATE_DEMAND_INCLUSIONS_REQUEST = '@@demandInclusion/CREATE_DEMAND_INCLUSIONS_REQUEST',
  CREATE_DEMAND_INCLUSIONS_SUCCESS = '@@demandInclusion/CREATE_DEMAND_INCLUSIONS_SUCCESS',

  DELETE_BULK_DEMAND_INCLUSIONS_FAILURE = '@@demandInclusion/DELETE_BULK_DEMAND_INCLUSIONS_FAILURE',
  DELETE_BULK_DEMAND_INCLUSIONS_REQUEST = '@@demandInclusion/DELETE_BULK_DEMAND_INCLUSIONS_REQUEST',
  DELETE_BULK_DEMAND_INCLUSIONS_SUCCESS = '@@demandInclusion/DELETE_BULK_DEMAND_INCLUSIONS_SUCCESS',

  DELETE_DEMAND_INCLUSIONS_FAILURE = '@@demandInclusion/DELETE_DEMAND_INCLUSIONS_FAILURE',
  DELETE_DEMAND_INCLUSIONS_REQUEST = '@@demandInclusion/DELETE_DEMAND_INCLUSIONS_REQUEST',
  DELETE_DEMAND_INCLUSIONS_SUCCESS = '@@demandInclusion/DELETE_DEMAND_INCLUSIONS_SUCCESS',

  GET_DEMAND_ENABLEMENTS_FAILURE = '@@demandInclusion/GET_DEMAND_ENABLEMENTS_FAILURE',
  GET_DEMAND_ENABLEMENTS_REQUEST = '@@demandInclusion/GET_DEMAND_ENABLEMENTS_REQUEST',
  GET_DEMAND_ENABLEMENTS_SUCCESS = '@@demandInclusion/GET_DEMAND_ENABLEMENTS_SUCCESS',

  GET_DEMAND_INCLUSIONS_FAILURE = '@@demandInclusion/GET_DEMAND_INCLUSIONS_FAILURE',
  GET_DEMAND_INCLUSIONS_REQUEST = '@@demandInclusion/GET_DEMAND_INCLUSIONS_REQUEST',
  GET_DEMAND_INCLUSIONS_SUCCESS = '@@demandInclusion/GET_DEMAND_INCLUSIONS_SUCCESS',

  GET_OFFER_RULES_BY_SUBACCOUNT_ID_FAILURE = '@@demandInclusion/GET_OFFER_RULES_BY_SUBACCOUNT_ID_FAILURE',
  GET_OFFER_RULES_BY_SUBACCOUNT_ID_REQUEST = '@@demandInclusion/GET_OFFER_RULES_BY_SUBACCOUNT_ID_REQUEST',
  GET_OFFER_RULES_BY_SUBACCOUNT_ID_SUCCESS = '@@demandInclusion/GET_OFFER_RULES_BY_SUBACCOUNT_ID_SUCCESS',
}
