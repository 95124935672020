import type { MouseEventHandler } from 'react';
import { createRef, useEffect, useState } from 'react';
import * as React from 'react';

export interface NotificationMessageProps {
  message: React.ReactNode;
}

const NotificationMessage: ({ message }: { message: any }) => JSX.Element = ({
  message,
}) => {
  const descriptionBox = createRef<HTMLDivElement>();

  const [haveMore, setHaveMore] = useState(false);
  const [showMore, setShowMore] = useState(true);

  useEffect(() => {
    if (descriptionBox.current) {
      const newHaveMore = descriptionBox.current.scrollHeight > 70;

      if (newHaveMore) {
        descriptionBox.current.style.height = showMore ? '70px' : 'auto';
      }

      setHaveMore(newHaveMore);
    }
  });

  const toggleShowMore: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e?.stopPropagation();
    const newShowMore = !showMore;

    if (descriptionBox.current) {
      descriptionBox.current.style.height = newShowMore ? '70px' : 'auto';
    }

    setShowMore(newShowMore);
  };

  if (!message) {
    return <></>;
  }

  return (
    <>
      <div ref={descriptionBox} className="ant-notification-notice-description">
        {message}
      </div>
      {haveMore && (
        <a className="notification-show-more" onClick={toggleShowMore}>
          View {showMore ? 'more' : 'less'}
        </a>
      )}
    </>
  );
};

export default NotificationMessage;
