import { createReducer } from 'typesafe-actions';

import { reconcileState } from '@/store/utils';

import { getEnv } from '../../envWrapper';
import { nextHydrate } from '../actions';
import type { HydrateAction } from '../actions';

import { setIsServer } from './actions';
import type { ConfigAction, ConfigState } from './types';

export const initialConfigState: ConfigState = getEnv();

export const configurationReducer = createReducer<
  ConfigState,
  ConfigAction | HydrateAction
>(initialConfigState)
  .handleAction(setIsServer, (state, { payload: IS_SERVER }) => ({
    ...state,
    IS_SERVER,
  }))
  .handleAction(nextHydrate, (state, { payload: { config } }) => ({
    ...state,
    ...reconcileState(config, initialConfigState),
  }));
