import * as React from 'react';
import { useSelector } from 'react-redux';

import isEmpty from 'lodash/isEmpty';

import { isInternalUserType } from '@portal/common/dist/access-control';
import { userSelector } from '@portal/store/dist/user/selectors';
import type { UserWithDeactivationInfo } from '@portal/store/dist/user/selectors';
import { getUserFullName } from '@portal/store/dist/user/util';

import RecordDropdown from '@/components/form/portal-form/fields/record-dropdown';
import type { ComposedRecordDropdownProps } from '@/components/form/portal-form/fields/record-dropdown';

const emptyUserFilter = (user: UserWithDeactivationInfo) =>
  !isEmpty(user.firstName) && !isEmpty(user.lastName);

const internalUserFilter = (user: UserWithDeactivationInfo) =>
  isInternalUserType(user.emailAddress);

export type UserDropdownProvidedProps = {
  accountIds?: number[];
  internalUsersOnly?: boolean;
  showUserID?: boolean;
  userFilter?: (user: UserWithDeactivationInfo) => boolean;
  valueField?: keyof Omit<UserWithDeactivationInfo, 'roles'>;
} & ComposedRecordDropdownProps;

type UserDropdownProps = UserDropdownProvidedProps;

const UserDropdown: React.FC<UserDropdownProps> = ({
  valueField,
  userFilter,
  internalUsersOnly = false,
  showUserID = false,
  ...props
}) => {
  const users = useSelector(userSelector);

  const filters = [emptyUserFilter];

  if (userFilter) {
    filters.push(userFilter);
  }

  if (internalUsersOnly) {
    filters.push(internalUserFilter);
  }

  return (
    <RecordDropdown
      filters={filters}
      id="user"
      optionKey="uuid"
      optionLabel={(user) =>
        showUserID
          ? `${user.id} - ${getUserFullName(user)}`
          : getUserFullName(user)
      }
      optionValue={valueField ?? 'uuid'}
      records={users}
      {...props}
    />
  );
};

export default UserDropdown;
