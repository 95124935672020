"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.messageParser = void 0;
const messageParser = (message) => {
    let output = message;
    if (typeof message === 'object') {
        output = JSON.stringify(message);
    }
    return output;
};
exports.messageParser = messageParser;
