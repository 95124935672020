export enum ReceivableActionType {
  ADD_ADJUSTMENT_FAILURE = '@@receivable/ADD_ADJUSTMENT_FAILURE',
  ADD_ADJUSTMENT_REQUEST = '@@receivable/ADD_ADJUSTMENT_REQUEST',
  ADD_ADJUSTMENT_SUCCESS = '@@receivable/ADD_ADJUSTMENT_SUCCESS',

  DELETE_ADJUSTMENT_FAILURE = '@@receivable/DELETE_ADJUSTMENT_FAILURE',
  DELETE_ADJUSTMENT_REQUEST = '@@receivable/DELETE_ADJUSTMENT_REQUEST',
  DELETE_ADJUSTMENT_SUCCESS = '@@receivable/DELETE_ADJUSTMENT_SUCCESS',

  GET_ALL_ADJUSTMENTS_FAILURE = '@@receivable/GET_ALL_ADJUSTMENTS_FAILURE',
  GET_ALL_ADJUSTMENTS_REQUEST = '@@receivable/GET_ALL_ADJUSTMENTS_REQUEST',
  GET_ALL_ADJUSTMENTS_SUCCESS = '@@receivable/GET_ALL_ADJUSTMENTS_SUCCESS',

  GET_ALL_FAILURE = '@@receivable/GET_ALL_FAILURE',
  GET_ALL_REQUEST = '@@receivable/GET_ALL_REQUEST',
  GET_ALL_SUCCESS = '@@receivable/GET_ALL_SUCCESS',

  GET_BY_ID_FAILURE = '@@receivable/GET_BY_ID_FAILURE',
  GET_BY_ID_REQUEST = '@@receivable/GET_BY_ID_REQUEST',
  GET_BY_ID_SUCCESS = '@@receivable/GET_BY_ID_SUCCESS',

  GET_EVENTS_BY_ID_FAILURE = '@@receivable/GET_EVENTS_BY_ID_FAILURE',
  GET_EVENTS_BY_ID_REQUEST = '@@receivable/GET_EVENTS_BY_ID_REQUEST',
  GET_EVENTS_BY_ID_SUCCESS = '@@receivable/GET_EVENTS_BY_ID_SUCCESS',

  INIT_DETAIL_PAGE = '@@receivable/INIT_DETAIL_PAGE',

  REGENERATE_LEDGER_ITEMS_FAILURE = '@@receivable/REGENERATE_LEDGER_ITEMS_FAILURE',
  REGENERATE_LEDGER_ITEMS_REQUEST = '@@receivable/REGENERATE_LEDGER_ITEMS_REQUEST',
  REGENERATE_LEDGER_ITEMS_SUCCESS = '@@receivable/REGENERATE_LEDGER_ITEMS_SUCCESS',

  TOGGLE_ADD_ADJUSTMENTS_FORM = '@@receivable/TOGGLE_ADD_ADJUSTMENTS_FORM',
  UPDATE_ADD_ADJUSTMENTS_FORM = '@@receivable/UPDATE_ADD_ADJUSTMENTS_FORM',
}
