"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MilitaryStatus = exports.OperationType = exports.PropertyFormatType = exports.PropertyItemType = exports.ProductPropertyType = void 0;
exports.ProductPropertyType = {
    Array: 'array',
    Bool: 'boolean',
    Boolean: 'boolean',
    Integer: 'integer',
    Number: 'number',
    Object: 'object',
    String: 'string',
};
exports.PropertyItemType = {
    Number: 'number',
    String: 'string',
};
exports.PropertyFormatType = {
    DateTime: 'date-time',
    Uri: 'uri',
    Uuid: 'uuid',
};
var OperationType;
(function (OperationType) {
    OperationType["AddOptional"] = "AddOptional";
    OperationType["AddRequired"] = "AddRequired";
    OperationType["ChangeOptionalToRequired"] = "ChangeOptionalToRequired";
    OperationType["ChangeRequiredToOptional"] = "ChangeRequiredToOptional";
    OperationType["DeleteOptional"] = "DeleteOptional";
    OperationType["DeleteRequired"] = "DeleteRequired";
    OperationType["UpdateExisting"] = "UpdateExisting";
})(OperationType = exports.OperationType || (exports.OperationType = {}));
var MilitaryStatus;
(function (MilitaryStatus) {
    MilitaryStatus["Active"] = "active";
    MilitaryStatus["Veteran"] = "veteran";
})(MilitaryStatus = exports.MilitaryStatus || (exports.MilitaryStatus = {}));
