import * as React from 'react';

import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from 'antd/lib/input';
import type { FilterDropdownProps } from 'antd/lib/table/interface';

import useAutoFocus from '@/utils/hooks/use-auto-focus';

const { Search } = Input;

export const onInputChange =
  (setSelectedKeys?: (keys: string[]) => void, confirm?: () => void) =>
  (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!setSelectedKeys) {
      return;
    }

    if (!e.target.value && confirm) {
      setSelectedKeys([]);

      if (e.type !== 'change') {
        confirm();
      }

      return;
    }

    setSelectedKeys(e.target.value ? [e.target.value] : []);
  };

export const filterIcon = (filtered: boolean) => (
  <FontAwesomeIcon
    fixedWidth
    color={filtered ? '#1890ff' : undefined}
    icon={faMagnifyingGlass}
  />
);

/**
 * @deprecated
 * Please use the new textFilterDropdown under /components/table/lib/filter-dropdowns
 * which has more options available.
 *
 * @param {string} [placeholder='']
 * @return {(props: FilterDropdownProps) => React.ReactNode}
 */
export const textFilterDropdown = (placeholder: string = '') => {
  const SearchBarComponent: React.FC<FilterDropdownProps> = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
  }) => {
    const { inputRef, AutoFocus } = useAutoFocus<Input>();

    return (
      <AutoFocus>
        <Search
          ref={inputRef}
          allowClear
          placeholder={placeholder}
          style={{ width: 200 }}
          value={selectedKeys?.[0] ?? ''}
          onChange={onInputChange(setSelectedKeys, confirm)}
          onSearch={confirm}
        />
      </AutoFocus>
    );
  };

  return SearchBarComponent;
};
