"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.randomUsers = void 0;
exports.randomUsers = [
    {
        emailAddress: 'Alayna22@hotmail.com',
        firstName: 'Arnaldo',
        id: 26700,
        lastName: 'Volkman',
        uuid: '9e4b2f50-e04c-45f5-9c24-683a504fa2ae',
    },
    {
        emailAddress: 'Aliya0@gmail.com',
        firstName: 'Rose',
        id: 29350,
        lastName: 'Mertz',
        uuid: '6394b359-5139-4f85-8208-46d0a2b79a69',
    },
    {
        emailAddress: 'Brant.Hills48@yahoo.com',
        firstName: 'Orville',
        id: 26342,
        lastName: 'Hermiston',
        uuid: '584558cb-8869-456a-bd0e-01faf815c1a6',
    },
    {
        emailAddress: 'Americo_Stiedemann39@yahoo.com',
        firstName: 'Darby',
        id: 42110,
        lastName: 'Predovic',
        uuid: '6a69e190-796f-4742-a694-fd73c87fe0ac',
    },
    {
        emailAddress: 'Rollin_Franecki93@yahoo.com',
        firstName: 'Moriah',
        id: 11279,
        lastName: 'Steuber',
        uuid: '58bd33f7-b498-4f28-8f88-f2301516cf94',
    },
    {
        emailAddress: 'Genoveva.Botsford60@yahoo.com',
        firstName: 'Cleta',
        id: 91171,
        lastName: 'Klein',
        uuid: 'eb0c128d-db46-4d05-b97f-7cf4d3470f17',
    },
    {
        emailAddress: 'Aileen57@hotmail.com',
        firstName: 'Ona',
        id: 73257,
        lastName: 'Tremblay',
        uuid: 'a3e6f42e-7f6a-4d77-b9e7-029a5281f376',
    },
    {
        emailAddress: 'Lester.Hilll49@gmail.com',
        firstName: 'Ethyl',
        id: 58531,
        lastName: 'Little',
        uuid: '192e9473-f218-4c49-b83f-096cad3098c5',
    },
    {
        emailAddress: 'Laury_Klocko@gmail.com',
        firstName: 'Eveline',
        id: 3713,
        lastName: 'Kemmer',
        uuid: '146a481f-a03e-42fa-8085-d7e210f11797',
    },
    {
        emailAddress: 'Aron80@hotmail.com',
        firstName: 'Milo',
        id: 56296,
        lastName: 'Veum',
        uuid: 'a5129dfd-1ddc-4a97-91fc-0099f4bb52ea',
    },
];
