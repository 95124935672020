import type { ActionType } from 'typesafe-actions';

import type { ComplianceItem } from '@portal/api-models';

import type * as actions from './actions';

export type ComplianceAction = ActionType<typeof actions>;

export enum PersonalLoansActionType {
  GET_FAILURE = '@@compliance/PERSONAL_LOANS_FAILURE',
  GET_REQUEST = '@@compliance/PERSONAL_LOANS_REQUEST',
  GET_SUCCESS = '@@compliance/PERSONAL_LOANS_SUCCESS',
}

export enum CreditCardsActionType {
  GET_FAILURE = '@@compliance/CREDIT_CARDS_FAILURE',
  GET_REQUEST = '@@compliance/CREDIT_CARDS_REQUEST',
  GET_SUCCESS = '@@compliance/CREDIT_CARDS_SUCCESS',
}

export enum SavingsActionType {
  GET_FAILURE = '@@compliance/SAVINGS_FAILURE',
  GET_REQUEST = '@@compliance/SAVINGS_REQUEST',
  GET_SUCCESS = '@@compliance/SAVINGS_SUCCESS',
}

export enum ChannelsActionType {
  GET_FAILURE = '@@compliance/CHANNELS_FAILURE',
  GET_REQUEST = '@@compliance/CHANNELS_REQUEST',
  GET_SUCCESS = '@@compliance/CHANNELS_SUCCESS',
}

export enum ComplianceActionType {
  COMPLIANCE_ITEMS_MY_ACCOUNT_FAILURE = '@@compliance/COMPLIANCE_ITEMS_MY_ACCOUNT_FAILURE',
  COMPLIANCE_ITEMS_MY_ACCOUNT_REQUEST = '@@compliance/COMPLIANCE_ITEMS_MY_ACCOUNT_REQUEST',
  COMPLIANCE_ITEMS_MY_ACCOUNT_SUCCESS = '@@compliance/COMPLIANCE_ITEMS_MY_ACCOUNT_SUCCESS',

  SUBMIT_FOR_LENDER_REVIEW_FAILURE = '@@compliance/SUBMIT_FOR_LENDER_REVIEW_FAILURE',
  SUBMIT_FOR_LENDER_REVIEW_REQUEST = '@@compliance/SUBMIT_FOR_LENDER_REVIEW_REQUEST',
  SUBMIT_FOR_LENDER_REVIEW_SUCCESS = '@@compliance/SUBMIT_FOR_LENDER_REVIEW_SUCCESS',

  SUBMIT_FOR_REVIEW_FAILURE = '@@compliance/SUBMIT_FOR_REVIEW_FAILURE',

  SUBMIT_FOR_REVIEW_PAUSE_SUCCESS = '@@compliance/SUBMIT_FOR_REVIEW_PAUSE_SUCCESS',
  SUBMIT_FOR_REVIEW_REQUEST = '@@compliance/SUBMIT_FOR_REVIEW_REQUEST',
  SUBMIT_FOR_REVIEW_SUCCESS = '@@compliance/SUBMIT_FOR_REVIEW_SUCCESS',
}

export interface SubmitForReviewRequestPayload {
  approvedChanges?: any;
  doNotRedirect?: boolean;
  existingCompliance?: ComplianceItem;
  integrationId: string;
  subAccountUuid: string;
  updatedChanges?: any;
}

export interface ComplianceState {
  channels: ComplianceItem[];
  creditCards: ComplianceItem[];
  personalLoans: ComplianceItem[];
  rows: ComplianceItem[];
  savings: ComplianceItem[];
}
