"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeadHydrationActionType = void 0;
var LeadHydrationActionType;
(function (LeadHydrationActionType) {
    LeadHydrationActionType["CREATE_CREDIT_CARD_LEAD_HYDRATION_CONFIG_FAILURE"] = "@@lead-hydration/CREATE_CREDIT_CARD_LEAD_HYDRATION_CONFIG_FAILURE";
    LeadHydrationActionType["CREATE_CREDIT_CARD_LEAD_HYDRATION_CONFIG_REQUEST"] = "@@lead-hydration/CREATE_CREDIT_CARD_LEAD_HYDRATION_CONFIG_REQUEST";
    LeadHydrationActionType["CREATE_CREDIT_CARD_LEAD_HYDRATION_CONFIG_SUCCESS"] = "@@lead-hydration/CREATE_CREDIT_CARD_LEAD_HYDRATION_CONFIG_SUCCESS";
    LeadHydrationActionType["CREATE_LEAD_HYDRATION_CONFIG_FAILURE"] = "@@lead-hydration/CREATE_LEAD_HYDRATION_CONFIG_FAILURE";
    LeadHydrationActionType["CREATE_LEAD_HYDRATION_CONFIG_REQUEST"] = "@@lead-hydration/CREATE_LEAD_HYDRATION_CONFIG_REQUEST";
    LeadHydrationActionType["CREATE_LEAD_HYDRATION_CONFIG_SUCCESS"] = "@@lead-hydration/CREATE_LEAD_HYDRATION_CONFIG_SUCCESS";
    LeadHydrationActionType["DELETE_CREDIT_CARD_LEAD_HYDRATION_CONFIG_FAILURE"] = "@@lead-hydration/DELETE_CREDIT_CARD_LEAD_HYDRATION_CONFIG_FAILURE";
    LeadHydrationActionType["DELETE_CREDIT_CARD_LEAD_HYDRATION_CONFIG_REQUEST"] = "@@lead-hydration/DELETE_CREDIT_CARD_LEAD_HYDRATION_CONFIG_REQUEST";
    LeadHydrationActionType["DELETE_CREDIT_CARD_LEAD_HYDRATION_CONFIG_SUCCESS"] = "@@lead-hydration/DELETE_CREDIT_CARD_LEAD_HYDRATION_CONFIG_SUCCESS";
    LeadHydrationActionType["GET_LEAD_HYDRATION_CONFIGS_BY_SUB_ACCOUNT_UUID_FAILURE"] = "@@lead-hydration/GET_LEAD_HYDRATION_CONFIGS_BY_SUB_ACCOUNT_UUID_FAILURE";
    LeadHydrationActionType["GET_LEAD_HYDRATION_CONFIGS_BY_SUB_ACCOUNT_UUID_REQUEST"] = "@@lead-hydration/GET_LEAD_HYDRATION_CONFIGS_BY_SUB_ACCOUNT_UUID_REQUEST";
    LeadHydrationActionType["GET_LEAD_HYDRATION_CONFIGS_BY_SUB_ACCOUNT_UUID_SUCCESS"] = "@@lead-hydration/GET_LEAD_HYDRATION_CONFIGS_BY_SUB_ACCOUNT_UUID_SUCCESS";
})(LeadHydrationActionType = exports.LeadHydrationActionType || (exports.LeadHydrationActionType = {}));
