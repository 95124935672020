"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFlagPermissions = void 0;
const permissions_1 = require("../permissions");
const getFlagPermissions = (flags) => {
    let config = {};
    if (flags.has('benchmark_reports')) {
        config = Object.assign(Object.assign({}, config), permissions_1.benchmarkReportsConfig);
    }
    if (flags.has('compliance_tab')) {
        config = Object.assign(Object.assign({}, config), permissions_1.complianceConfig);
    }
    if (flags.has('demand_mvp')) {
        config = Object.assign(Object.assign({}, config), permissions_1.demandMVPConfig);
    }
    if (flags.has('mci_reports')) {
        config = Object.assign(Object.assign({}, config), permissions_1.mciReportsConfig);
    }
    if (flags.has('demand_homepage')) {
        config = Object.assign(Object.assign({}, config), permissions_1.demandHomepageConfig);
    }
    if (flags.has('onboarding')) {
        config = Object.assign(Object.assign({}, config), permissions_1.onboardingConfig);
    }
    if (flags.has('notifications')) {
        config = Object.assign(Object.assign({}, config), permissions_1.notificationsConfig);
    }
    if (flags.has('demand_cc_mvp')) {
        config = Object.assign(Object.assign({}, config), permissions_1.demandCCMVPConfig);
    }
    if (flags.has('demand_filtering_tab')) {
        config = Object.assign(Object.assign({}, config), permissions_1.demandFilteringTabConfig);
    }
    if (flags.has('demand_partners_tab')) {
        config = Object.assign(Object.assign({}, config), permissions_1.demandPartnersTabConfig);
    }
    if (flags.has('deactivate')) {
        config = Object.assign(Object.assign({}, config), permissions_1.deactivateConfig);
    }
    if (flags.has('guide_performance_summary')) {
        config = Object.assign(Object.assign({}, config), permissions_1.guidePerformanceSummaryConfig);
    }
    if (flags.has('video_tutorials')) {
        config = Object.assign(Object.assign({}, config), permissions_1.videoTutorialsConfig);
    }
    if (flags.has('tutorial_getting_started')) {
        config = Object.assign(Object.assign({}, config), permissions_1.tutorialGettingStartedConfig);
    }
    if (flags.has('onboarding_tracking')) {
        config = Object.assign(Object.assign({}, config), permissions_1.onboardingTrackingConfig);
    }
    if (flags.has('guide_homepage')) {
        config = Object.assign(Object.assign({}, config), permissions_1.guideHomepageConfiguration);
    }
    if (flags.has('guide_users')) {
        config = Object.assign(Object.assign({}, config), permissions_1.guideUsersConfiguration);
    }
    if (flags.has('guide_integrations')) {
        config = Object.assign(Object.assign({}, config), permissions_1.guideIntegrationsConfiguration);
    }
    if (flags.has('product_type_schema')) {
        config = Object.assign(Object.assign({}, config), { hasUpdateProductTypeSchemaFeatureFlag: true });
    }
    if (flags.has('dev_tools')) {
        config = Object.assign(Object.assign({}, config), { hasDevToolsFeatureFlag: true });
    }
    if (flags.has('demand_receivable')) {
        config = Object.assign(Object.assign({}, config), { hasDemandReceivablesFeatureFlag: true });
    }
    return config;
};
exports.getFlagPermissions = getFlagPermissions;
