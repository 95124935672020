import type { SaasMigrationSetting } from '@evenfinancial/finance-client';

import type { PortalStep } from '@/components/steps';
import type { Integration } from '@/resources/integrations/types';
import {
  getAfterPlanPurchasedActiveStep,
  getAfterPlanPurchasedSteps,
} from '@/resources/self-service/getting-started/after-plan-purchased-steps';
import {
  getBeforPlanPurchasedSteps,
  getBeforePlanPurchasedActiveStep,
} from '@/resources/self-service/getting-started/before-plan-purchased-steps';
import type { GettingStartedBoxSteps } from '@/resources/self-service/getting-started/getting-started-box-steps';

export type GettingStartedBoxConfig = {
  currentStep: number;
  steps: PortalStep[];
  subtitle: string;
  title: string;
};

export const generateGettingStartedBoxConfig = (
  integration?: Integration,
  planPurchased?: boolean,
  supplyMigration?: SaasMigrationSetting
): GettingStartedBoxConfig | undefined => {
  let title: string;
  let subtitle: string;
  let activeStep: GettingStartedBoxSteps | undefined;
  let steps: PortalStep[];

  if (planPurchased) {
    if (!integration) {
      return undefined;
    }

    title = 'Thank you for purchasing a plan';
    subtitle = 'Your have a few steps until you are ready to go live!';
    activeStep = getAfterPlanPurchasedActiveStep(integration);
    steps = getAfterPlanPurchasedSteps(integration!, activeStep);
  } else {
    title = 'Getting Started';
    subtitle = 'Follow these steps to get up and running';
    activeStep = getBeforePlanPurchasedActiveStep(integration);
    steps = getBeforPlanPurchasedSteps(integration, supplyMigration);
  }

  const currentStep = steps.findIndex(
    (step: PortalStep) => step.id === activeStep?.toString()
  );

  return { currentStep, steps, subtitle, title };
};
