import * as React from 'react';
import { connect } from 'react-redux';

import isEmpty from 'lodash/isEmpty';

import type { TempAccount } from '@portal/api-models';

import RecordDropdown from '@/components/form/portal-form/fields/record-dropdown';
import type { ComposedRecordDropdownProps } from '@/components/form/portal-form/fields/record-dropdown';
import type { ApplicationState } from '@/store';

const emptyAccountFilter = (account: TempAccount) => !isEmpty(account.name);

export type AccountDropdownProvidedProps = {
  accountFilter?: (account: TempAccount) => boolean;
  isValueId?: boolean;
} & ComposedRecordDropdownProps;

interface AccountDropdownStateProps {
  accounts: TempAccount[];
}

type AccountDropdownProps = AccountDropdownProvidedProps &
  AccountDropdownStateProps;

const AccountDropdown: React.FC<AccountDropdownProps> = ({
  accountFilter,
  isValueId,
  accounts,
  ...props
}) => {
  const filters = accountFilter
    ? [emptyAccountFilter, accountFilter]
    : [emptyAccountFilter];

  return (
    <RecordDropdown
      filters={filters}
      id="account"
      optionKey="uuid"
      optionLabel="name"
      optionValue={isValueId ? 'id' : 'uuid'}
      records={accounts}
      {...props}
    />
  );
};

export default connect<
  AccountDropdownStateProps,
  {},
  AccountDropdownProvidedProps,
  ApplicationState
>(({ accounts }: ApplicationState) => ({
  accounts: accounts.rows,
}))(AccountDropdown);
