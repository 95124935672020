import * as React from 'react';
import { Component } from 'react';

import { Button } from 'antd';
import Alert from 'antd/lib/alert';
import isEqual from 'lodash/isEqual';

import { OnboardingStatus } from '@portal/api-models';

import MessageContainer from '@/components/message-container';
import { IntegrationsCard } from '@/components/self-service/home/integrations-card';
import PortalSteps from '@/components/steps';
import type { ControlCenterHomeProps } from '@/pages/home/self-service';
import LatestInvoicesCard from '@/pages/home/self-service/cards/latest-invoices';
import PerformanceSummaryCard from '@/pages/home/self-service/cards/performance-summary';
import { generateGettingStartedBoxConfig } from '@/resources/self-service/getting-started/getting-started-box';
import type { GettingStartedBoxConfig } from '@/resources/self-service/getting-started/getting-started-box';
import { Router } from '@/routes';
import { removeParamFromUrl } from '@/utils/router';

export enum QueryParams {
  FeePaymentSuccess = 'feePaymentSuccess',
  PublishSuccess = 'publishSuccess',
  UpgradedMigrationSuccess = 'upgradedMigrationSuccess',
}

export interface SelfServiceHomeRegularProvidedProps {
  isPermanent?: boolean;
}

export class SelfServiceHomeRegular extends Component<
  ControlCenterHomeProps & SelfServiceHomeRegularProvidedProps
> {
  private boxConfig?: GettingStartedBoxConfig;

  constructor(props: ControlCenterHomeProps) {
    super(props);

    this.boxConfig = this.initializeBoxConfig(props);
  }

  public componentDidUpdate(prevProps: ControlCenterHomeProps) {
    if (
      Boolean(prevProps.selfServiceData.currentPlan) !==
        Boolean(this.props.selfServiceData.currentPlan) ||
      !isEqual(this.props.integrations[0], prevProps.integrations[0])
    ) {
      this.boxConfig = this.initializeBoxConfig(this.props);
    }
  }

  private initializeBoxConfig(props: ControlCenterHomeProps) {
    const { supplyMigration } = this.props;

    return generateGettingStartedBoxConfig(
      props.integrations[0],
      Boolean(props.selfServiceData.currentPlan),
      supplyMigration
    );
  }

  public render() {
    const {
      selfServiceData,
      integrations,
      subAccounts,
      latestInvoices,
      query,
      supplyMigration,
      account,
      showControlCenterHomeGettingStartedBox,
      showControlCenterHomeSetupBankingInformation,
    } = this.props;

    const publishSuccessMessage = Boolean(query[QueryParams.PublishSuccess]);
    const feePaymentSuccessMessage = Boolean(
      query[QueryParams.FeePaymentSuccess]
    );
    const planPurchased = Boolean(selfServiceData.currentPlan);

    const hideBox =
      planPurchased &&
      Boolean(
        integrations.find(
          (i) =>
            i.onboardingStatus === OnboardingStatus.Ready ||
            i.onboardingStatus === OnboardingStatus.Live
        )
      );

    const boxConfiguration = this.boxConfig
      ? this.boxConfig.currentStep <= 0
      : true;

    const showBankingInformationSetupMessage =
      showControlCenterHomeSetupBankingInformation &&
      boxConfiguration &&
      planPurchased &&
      !supplyMigration &&
      account &&
      !account.w9Url;

    return (
      <>
        {publishSuccessMessage && (
          <Alert
            closable
            showIcon
            message="Success Note - Congrats! Your integration is now live on Even Network"
            type="success"
            onClose={() => removeParamFromUrl(QueryParams.PublishSuccess)}
          />
        )}
        {feePaymentSuccessMessage && (
          <Alert
            closable
            showIcon
            message="Success Note - You have paid the implementation fee"
            type="success"
            onClose={() => removeParamFromUrl(QueryParams.FeePaymentSuccess)}
          />
        )}
        {showBankingInformationSetupMessage && (
          <MessageContainer
            className="banking-information"
            title="Set Up Your Bank Information"
          >
            <div className="banking-information-content">
              Fill out your Bank Information in order to receive payments from
              Even Financial.
            </div>
            <Button
              type="primary"
              onClick={async () => Router.push('/account/payout')}
            >
              Go to Payout Settings
            </Button>
          </MessageContainer>
        )}

        {this.boxConfig &&
        this.boxConfig.currentStep >= 0 &&
        this.boxConfig.steps.length > 0 &&
        !hideBox &&
        !supplyMigration &&
        showControlCenterHomeGettingStartedBox ? (
          <div className="getting-started-box">
            <h2 className="getting-started-box-title">
              {this.boxConfig.title}
            </h2>
            <p className="getting-started-box-tip">{this.boxConfig.subtitle}</p>
            <PortalSteps
              currentStep={this.boxConfig.currentStep}
              steps={this.boxConfig.steps}
            />
          </div>
        ) : (
          <></>
        )}
        <PerformanceSummaryCard
          disabled={selfServiceData.performanceSummaryDisabled}
          integrations={integrations}
          subAccounts={subAccounts}
        />
        <IntegrationsCard
          disabled={selfServiceData.integrationsDisabled}
          integrations={integrations}
          planPurchased={planPurchased}
        />
        <LatestInvoicesCard
          disabled={selfServiceData.invoicesDisabled}
          invoices={latestInvoices}
        />
      </>
    );
  }
}
