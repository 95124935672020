import { createAsyncAction } from 'typesafe-actions';

import type {
  TempAvailableDemandPartner,
  TempFeaturedDemandPartner,
} from '@portal/api-models';
import type { PortalError } from '@portal/common';

import type { IsomorphicRequestPayload } from '@/store/types';

import { TrustedPartnersActionType } from './types';

interface GetTrustedPartnersPayload extends IsomorphicRequestPayload {
  companyUuid: string;
}

interface AvailableTrustedPartnersResponse {
  companyUuid: string;
  response: TempAvailableDemandPartner[];
}
interface FeaturedTrustedPartnersResponse {
  companyUuid: string;
  response: TempFeaturedDemandPartner[];
}

export const getAvailableTrustedPartnersAction = createAsyncAction(
  TrustedPartnersActionType.GET_AVAILABLE_REQUEST,
  TrustedPartnersActionType.GET_AVAILABLE_SUCCESS,
  TrustedPartnersActionType.GET_AVAILABLE_FAILURE
)<GetTrustedPartnersPayload, AvailableTrustedPartnersResponse, PortalError>();

export const getFeaturedTrustedPartnersAction = createAsyncAction(
  TrustedPartnersActionType.GET_FEATURED_REQUEST,
  TrustedPartnersActionType.GET_FEATURED_SUCCESS,
  TrustedPartnersActionType.GET_FEATURED_FAILURE
)<GetTrustedPartnersPayload, FeaturedTrustedPartnersResponse, PortalError>();

interface SetTrustedPartnersPayload extends IsomorphicRequestPayload {
  companyUuid: string;
  demandSubAccountIds: string[];
}

export const setFeaturedTrustedPartnersAction = createAsyncAction(
  TrustedPartnersActionType.SET_FEATURED_REQUEST,
  TrustedPartnersActionType.SET_FEATURED_SUCCESS,
  TrustedPartnersActionType.SET_FEATURED_FAILURE
)<SetTrustedPartnersPayload, FeaturedTrustedPartnersResponse, PortalError>();

interface DeleteTrustedPartnersPayload extends IsomorphicRequestPayload {
  companyUuid: string;
  id: number;
}

interface DeleteTrustedPartnersResponse {
  companyUuid: string;
  id: number;
}
export const deleteFeaturedTrustedPartnersAction = createAsyncAction(
  TrustedPartnersActionType.DELETE_FEATURED_REQUEST,
  TrustedPartnersActionType.DELETE_FEATURED_SUCCESS,
  TrustedPartnersActionType.DELETE_FEATURED_FAILURE
)<DeleteTrustedPartnersPayload, DeleteTrustedPartnersResponse, PortalError>();
