import type { AxiosResponse } from 'axios';
import { call, put, take, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import type { ActionType } from 'typesafe-actions';

import type { SupplyDemandInclusion } from '@evenfinancial/partner-data-client';

import { client } from '@portal/api-client';
import { getSecondLookOfferConfigurationsBySupplySubAccountIdAction } from '@portal/store/dist';
import { Toaster } from '@portal/ui-lib';
import type { DemandEnablement } from '@portal/ui-lib';

import { createSupplyTemplateApplicationAction } from '../actions';

import {
  applyDemandInclusionTemplateAction,
  demandEnablementsRequestAction,
  demandInclusionsCreateBulkAction,
  demandInclusionsDeleteBulkAction,
  demandInclusionsRequestAction,
  getOfferRulesBySubaccountIdAction,
} from './actions';

const supplyDemandEnablementsBaseUrl =
  '/originator/demandEnablements/supplyDemandInclusions';

const demandEnablementOptionsBaseUrl = '/originator/demandEnablements/options';

function* applyDemandInclusionTemplateSaga({
  payload,
}: ActionType<typeof applyDemandInclusionTemplateAction.request>) {
  try {
    const {
      data: { supplySubAccountId },
    } = payload;

    yield put(createSupplyTemplateApplicationAction.request(payload));

    yield take(getType(createSupplyTemplateApplicationAction.success));

    // Must allow supply template application to be executed first to ensure
    // a second look offer configuration will exist.
    yield put(
      getSecondLookOfferConfigurationsBySupplySubAccountIdAction.request({
        supplySubAccountId,
      })
    );

    yield put(applyDemandInclusionTemplateAction.success());
  } catch (err: unknown) {
    yield put(applyDemandInclusionTemplateAction.failure(err));
  }
}

export function* demandInclusionSaga() {
  yield takeLatest(
    getType(applyDemandInclusionTemplateAction.request),
    applyDemandInclusionTemplateSaga
  );
}

function* getDemandEnablements({
  payload,
}: ActionType<typeof demandEnablementsRequestAction.request>) {
  try {
    const queryParams = new URLSearchParams({
      productType: payload.productType,
    }).toString();

    const { data }: AxiosResponse<DemandEnablement> = yield call(
      client.get,
      `${demandEnablementOptionsBaseUrl}?${queryParams}`
    );

    yield put(demandEnablementsRequestAction.success(data));
  } catch (err: any) {
    yield put(demandEnablementsRequestAction.failure(err));
  }
}

export function* demandEnablementsSaga() {
  yield takeLatest(
    getType(demandEnablementsRequestAction.request),
    getDemandEnablements
  );
}

function* getSupplyDemandInclusions({
  payload,
}: ActionType<typeof demandInclusionsRequestAction.request>) {
  try {
    const {
      data: { supplyDemandInclusions },
    }: AxiosResponse<{ supplyDemandInclusions: SupplyDemandInclusion[] }> =
      yield call(
        client.get,
        `${supplyDemandEnablementsBaseUrl}/${payload.supplySubAccountUuid}`
      );

    yield put(demandInclusionsRequestAction.success(supplyDemandInclusions));
  } catch (err: any) {
    yield put(demandInclusionsRequestAction.failure(err));
  }
}

export function* supplyDemandInclusionsSaga() {
  yield takeLatest(
    getType(demandInclusionsRequestAction.request),
    getSupplyDemandInclusions
  );
}

function* createBulkSupplyDemandInclusions({
  payload,
}: ActionType<typeof demandInclusionsCreateBulkAction.request>) {
  const results: SupplyDemandInclusion[] = [];
  const errors: Error[] = [];

  const errorIds: number[] = [];

  for (const demandEnablementUploadPayload of payload) {
    try {
      const response = yield call(
        client.post,
        supplyDemandEnablementsBaseUrl,
        demandEnablementUploadPayload.query
      );
      results.push(response.data);
    } catch (err: unknown) {
      errorIds.push(demandEnablementUploadPayload.query.demandSubAccountId);
      errors.push(err as Error);
    }
  }

  if (results.length > 0) {
    yield put(demandInclusionsCreateBulkAction.success(results));
  }

  if (errors.length > 0) {
    yield call(
      [Toaster, 'error'],
      `Failed to enable the following Demand Sub-Account IDs: ${errorIds.join(
        ', '
      )}`
    );
    yield put(demandInclusionsCreateBulkAction.failure(errors));
  }
}

export function* createBulkSupplyDemandInclusionsSaga() {
  yield takeLatest(
    getType(demandInclusionsCreateBulkAction.request),
    createBulkSupplyDemandInclusions
  );
}

function* deleteBulkSupplyDemandInclusions({
  payload,
}: ActionType<typeof demandInclusionsDeleteBulkAction.request>) {
  const resultsIds: number[] = [];
  const errors: Error[] = [];
  const errorIds: number[] = [];

  for (const { query } of payload) {
    try {
      const response = yield call(
        client.delete,
        `${supplyDemandEnablementsBaseUrl}/${query.id}`,
        {
          data: query,
        }
      );
      if (response.data) {
        resultsIds.push(query.id);
      } else {
        errorIds.push(query.id);
      }
    } catch (err: unknown) {
      errorIds.push(query.id);
      errors.push(err as Error);
    }
  }

  if (resultsIds.length > 0) {
    yield put(demandInclusionsDeleteBulkAction.success(resultsIds));
  }

  if (errors.length > 0) {
    yield call(
      [Toaster, 'error'],
      `Failed to disable the following Demand Sub-Account IDs: ${errorIds.join(
        ', '
      )}`
    );
    yield put(demandInclusionsDeleteBulkAction.failure(errors));
  }
}

export function* deleteBulkSupplyDemandInclusionsSaga() {
  yield takeLatest(
    getType(demandInclusionsDeleteBulkAction.request),
    deleteBulkSupplyDemandInclusions
  );
}

function* getOfferRulesByIdActionSaga(
  action: ActionType<typeof getOfferRulesBySubaccountIdAction.request>
) {
  const { supplySubAccountId } = action.payload;
  try {
    const { data } = yield call(client.get, `/originator/offer_rules_history`, {
      params: {
        excludeDeleted: false,
        isDeactivated: false,
        supplySubAccountId: [supplySubAccountId],
      },
    });

    yield put(getOfferRulesBySubaccountIdAction.success(data));
  } catch (err: any) {
    yield put(getOfferRulesBySubaccountIdAction.failure(err));
  }
}

export function* getOfferRulesByIdSaga() {
  yield takeLatest(
    getType(getOfferRulesBySubaccountIdAction.request),
    getOfferRulesByIdActionSaga
  );
}
