import * as React from 'react';

import { IntegrationStatus, OnboardingStatus } from '@portal/api-models';

import { IntegrationCategory } from '@/resources/sub-accounts-shared/utils';

const formatActionModalTitle = (onboardingStatus?: OnboardingStatus) => {
  switch (onboardingStatus) {
    case OnboardingStatus.Draft:
      return 'Submit for Compliance Review';
    case OnboardingStatus.Ready:
      return 'Ready to go Live?';
    default:
      return '';
  }
};

const formatActionModalBody = (
  isPaid: boolean,
  onboardingStatus?: OnboardingStatus,
  integrationType?: IntegrationCategory
) => {
  switch (onboardingStatus) {
    case OnboardingStatus.Draft:
      if (integrationType === IntegrationCategory.Embed) {
        return (
          <>
            <p>
              Your embed will be saved as a draft and can be reviewed from the
              Integrations module or Homepage.
            </p>
            <p>
              The website where you will share the integration URL needs to be
              reviewed and confirmed that it follows all our partners
              guidelines. This usually takes 3-5 work days. You will receive
              an&nbsp;
              <strong className="text-semibold">email confirmation</strong> once
              your&nbsp; embed is compliance approved.
            </p>
            {!isPaid ? (
              <p>
                Meanwhile, to complete your account setup, please purchase a
                plan to go live on the Even network.
              </p>
            ) : null}
          </>
        );
      }

      return (
        <>
          <p>
            Your partner page will be saved as a draft and can be reviewed from
            the Integrations module or Homepage.
          </p>
          <p>
            The website where you will share the integration URL needs to be
            reviewed and confirmed that it follows all our partners guidelines.
            This usually takes 3-5 work days. You will receive an&nbsp;
            <strong className="text-semibold">email confirmation</strong> once
            your&nbsp; partner page is compliance approved.
          </p>
          {!isPaid ? (
            <p>
              Meanwhile, to complete your account setup, please purchase a plan
              to go live on the Even network.
            </p>
          ) : null}
        </>
      );
    case OnboardingStatus.Ready:
      if (integrationType === IntegrationCategory.Embed) {
        return (
          <p>
            Your Embed will be live on Even network as soon as you click on
            Confirm. Please cancel if you have not implemented the iframe into
            your website html.
          </p>
        );
      }

      return (
        <p>
          Your Partner page will be live on Even network as soon as you click on
          Confirm. Please cancel if you have not implemented the partner page on
          your website
        </p>
      );
    default:
      return '';
  }
};

const formatActionModalButtons = (onboardingStatus?: OnboardingStatus) => {
  const defaultButtons = { cancel: 'Cancel', ok: 'Submit' };

  switch (onboardingStatus) {
    case OnboardingStatus.Draft:
      return {
        ...defaultButtons,
        ok: 'Submit',
      };
    case OnboardingStatus.Ready:
      return {
        ...defaultButtons,
        ok: 'Confirm',
      };
    default:
      return defaultButtons;
  }
};

export const formatActionModalData = (
  isPaid: boolean,
  onboardingStatus?: OnboardingStatus,
  integrationType?: IntegrationCategory
) => {
  return {
    body: formatActionModalBody(isPaid, onboardingStatus, integrationType),
    buttons: formatActionModalButtons(onboardingStatus),
    title: formatActionModalTitle(onboardingStatus),
  };
};

export const formatCallback = (
  submitForReview: (subAccountUuid: string, integrationId: string) => void,
  updateIntegrationStatus: (
    id: string,
    key: string,
    status: IntegrationStatus
  ) => void,
  closeModal: () => void,
  id: string,
  key: string,
  subAccountUuid: string,
  onboardingStatus?: OnboardingStatus
) => {
  switch (onboardingStatus) {
    case OnboardingStatus.Draft:
      return () => {
        submitForReview(subAccountUuid!, id);
        setTimeout(() => closeModal(), 500);
      };
    case OnboardingStatus.Ready:
      return () => {
        updateIntegrationStatus(id, key, IntegrationStatus.Live);
        setTimeout(() => closeModal(), 500);
      };

    default:
      return () => {};
  }
};
