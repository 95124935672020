"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.externalUrlFormatter = exports.moneyFormatter = exports.formatPercentWithPrecision = exports.formatWithPadding = exports.formatPrecision = exports.percentFormatter = void 0;
const percentFormatter = (value) => `${value}%`;
exports.percentFormatter = percentFormatter;
const formatPrecision = (precision, value) => {
    if (value) {
        let retVal = value.toString();
        if (retVal.length <= precision) {
            retVal += '0'.repeat(precision - (retVal.length - 1));
        }
        return retVal;
    }
    return '';
};
exports.formatPrecision = formatPrecision;
const formatWithPadding = (pad, str) => {
    return (pad + String(str)).substring(String(str).length);
};
exports.formatWithPadding = formatWithPadding;
const formatPercentWithPrecision = (value, convert = false) => {
    try {
        const divisor = convert ? 1 : 100;
        return Number(value / divisor).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            style: 'percent',
        });
    }
    catch (e) {
        return (0, exports.percentFormatter)(value);
    }
};
exports.formatPercentWithPrecision = formatPercentWithPrecision;
const CurrencyFormatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
});
const moneyFormatter = (value, exact = false) => {
    if (value !== 0 && !value) {
        return '';
    }
    const parsed = typeof value === 'string'
        ? value.replace(/[^\d.]/g, '')
        : value !== null && value !== void 0 ? value : 0;
    const matches = String(parsed).match(/(^.+)(\.[0-9]{0,2})/);
    const amount = !exact
        ? (matches === null || matches === void 0 ? void 0 : matches[1]) || parsed
        : parsed;
    const precision = !exact ? (0, exports.formatPrecision)(2, matches === null || matches === void 0 ? void 0 : matches[2]) : '';
    const output = CurrencyFormatter.format(amount);
    const formattedOutput = amount < 0 ? `(${output.replace(/-/g, '')})` : output;
    return exact
        ? formattedOutput
        : formattedOutput.replace(/\.00/g, '') + precision;
};
exports.moneyFormatter = moneyFormatter;
const externalUrlFormatter = (url) => /https?:\/\//i.test(url) ? url : `http://${url}`;
exports.externalUrlFormatter = externalUrlFormatter;
