/* eslint-disable max-lines */
import { message } from 'antd';
import type { AxiosResponse } from 'axios';
import {
  all,
  call,
  delay,
  fork,
  put,
  putResolve,
  retry,
  select,
  takeLatest,
} from 'redux-saga/effects';
import type { ActionType } from 'typesafe-actions';

import type {
  AccessToken,
  AccessTokenWithSecret,
  Application,
} from '@evenfinancial/auth-client';
import type {
  AccountOwnersWriteData,
  DemandSubAccountDeactivation,
  SupplySubAccountDeactivation,
} from '@evenfinancial/finance-client';
import { ProductType, SubAccountType } from '@evenfinancial/finance-client';
import { PartnerFeature } from '@evenfinancial/partner-data-client';

import { client, createActivePartnerFeature } from '@portal/api-client';
import type {
  ComplianceItem,
  DemandSupplyPartner,
  PartnerPageAlooma,
  PortalSubAccount,
  PortalSubAccountCreateData,
} from '@portal/api-models';
import { CommunicationChannel, ComplianceStatus } from '@portal/api-models';
import { getOwners } from '@portal/store/dist/account/sagas';
import {
  createCreditCardLeadHydrationConfigAction,
  createLeadHydrationConfigAction,
} from '@portal/store/dist/lead-hydration';
import * as leadHydrationActions from '@portal/store/dist/lead-hydration/actions';
import { richAccessTokenByUuidRequestAction } from '@portal/store/dist/user';
import { selectPermissions } from '@portal/store/dist/user/selectors';
import { Toaster } from '@portal/ui-lib';

import { getSubAccountByUUID } from '@/store/subaccount/selectors';
import { getBase64 } from '@/utils/base64-unmarshal';

import { Router } from '../../routes';
import { createExpectedEarningsShareAction } from '../expected-earnings-share/actions';

import {
  DemandSubAccountActionType,
  SubAccountSharedActionType,
  SupplySubAccountActionType,
} from './actionTypes';
import * as actions from './actions';

/**
 * @deprecated
 * Use useGetSubAccountsQuery from api-client instead.
 */
export function* getSupplySubAccounts(
  action?: ActionType<typeof actions.supplySubAccountRequestAction.request>
) {
  try {
    const { shouldGetSubAccountOwners } = yield select(selectPermissions);

    const { data: subAccounts }: AxiosResponse<PortalSubAccount[]> = yield call(
      [client, 'get'],
      '/finance/subaccounts',
      {
        params: { subAccountTypeKey: SubAccountType.SupplySource },
      }
    );

    if (shouldGetSubAccountOwners) {
      yield* getSubAccountOwners(subAccounts, action?.payload?.cookie);
    }

    yield put(actions.supplySubAccountRequestAction.success(subAccounts));
  } catch (err: any) {
    yield put(actions.supplySubAccountRequestAction.failure(err));
  }
}

/**
 * @deprecated
 * Use useGetSubAccountsQuery from api-client instead.
 */
export function* getDemandSubAccounts(
  action?: ActionType<typeof actions.demandSubAccountRequestAction.request>
) {
  try {
    const { shouldGetSubAccountOwners } = yield select(selectPermissions);

    const { data: subAccounts }: AxiosResponse<PortalSubAccount[]> = yield call(
      [client, 'get'],
      '/finance/subaccounts',
      {
        params: { subAccountTypeKey: SubAccountType.DemandSource },
      }
    );

    if (shouldGetSubAccountOwners) {
      yield* getSubAccountOwners(subAccounts, action?.payload?.cookie);
    }

    yield put(actions.demandSubAccountRequestAction.success(subAccounts));
  } catch (err: any) {
    yield put(actions.demandSubAccountRequestAction.failure(err));
  }
}

export function* getLenderDemandSubAccounts({
  payload: { query },
}: ActionType<typeof actions.lenderDemandSubAccountRequestAction.request>) {
  try {
    const { data }: AxiosResponse<PortalSubAccount[]> = yield call(
      [client, 'get'],
      '/originator/supply_templates/lenders/',
      {
        params: query,
      }
    );

    yield put(actions.lenderDemandSubAccountRequestAction.success(data));
  } catch (err: any) {
    yield put(actions.lenderDemandSubAccountRequestAction.failure(err));
  }
}

export function* getMigratedLenderDemandSubAccounts({
  payload,
}: ActionType<
  typeof actions.migratedLenderDemandSubAccountRequestAction.request
>) {
  try {
    let subAccounts: PortalSubAccount[] = [];

    if (payload.subaccount_uuid) {
      const { data }: AxiosResponse<PortalSubAccount[]> = yield call(
        [client, 'get'],
        `/originator/supply_templates/lenders/migrated/${payload.subaccount_uuid}`
      );

      subAccounts = data;
    }

    yield put(
      actions.migratedLenderDemandSubAccountRequestAction.success(subAccounts)
    );
  } catch (err: any) {
    yield put(actions.migratedLenderDemandSubAccountRequestAction.failure(err));
  }
}

export function* getByUuid({
  payload: { uuid, cookie },
}: ActionType<typeof actions.subAccountByUuidAction.request>) {
  try {
    const {
      shouldGetSubAccountOwners,
      showComplianceTab,
      shouldGetSubAccountCompliance,
    } = yield select(selectPermissions);

    const { data: subAccount }: AxiosResponse<PortalSubAccount> = yield call(
      [client, 'get'],
      `/finance/subaccounts/${uuid}`
    );

    if (shouldGetSubAccountOwners) {
      yield* getSubAccountOwners([subAccount], cookie);
    }

    if (showComplianceTab && shouldGetSubAccountCompliance) {
      yield put(
        actions.subAccountComplianceRequestAction.request({
          uuid: subAccount.uuid,
        })
      );
    }

    yield put(actions.subAccountByUuidAction.success(subAccount));
  } catch (err: any) {
    yield call(Router.pushRoute, '/404');
    yield put(actions.subAccountByUuidAction.failure(err));
  }
}

export function* getDemandSupplyPartnersByUuid({
  payload: { uuid },
}: ActionType<
  typeof actions.demandSubAccountSupplyPartnersByUuidRequestAction.request
>) {
  try {
    const { data: partners }: AxiosResponse<DemandSupplyPartner[]> = yield call(
      [client, 'get'],
      `/originator/supply_partners/${uuid}`
    );

    yield put(
      actions.demandSubAccountSupplyPartnersByUuidRequestAction.success({
        partners,
        uuid,
      })
    );
  } catch (err: any) {
    yield put(
      actions.demandSubAccountSupplyPartnersByUuidRequestAction.failure(err)
    );
  }
}

export function* getComplianceItemsByUuid({
  payload: { uuid },
}: ActionType<typeof actions.subAccountComplianceRequestAction.request>) {
  try {
    const { data: complianceItems }: AxiosResponse<ComplianceItem[]> =
      yield call([client, 'get'], `/compliance/${uuid}`, {
        params: { status: ComplianceStatus.waitingOnSupplyPartner },
      });

    yield put(
      actions.subAccountComplianceRequestAction.success({
        complianceItems,
        uuid,
      })
    );
  } catch (err: any) {
    yield put(actions.subAccountComplianceRequestAction.failure(err));
  }
}

function* getSubAccountOwners(
  subAccounts: PortalSubAccount[],
  cookie: string | undefined
) {
  yield* getOwners<PortalSubAccount>(subAccounts, 'subAccountOwners', cookie);
}

export function* getApplicationsForSupplySubAccount(
  action: ActionType<typeof actions.subAccountApplicationsByUuidAction.request>
) {
  try {
    const subAccountResource: PortalSubAccount = yield select(
      getSubAccountByUUID,
      {
        uuid: action.payload.uuid,
      }
    );

    let companyUuid = subAccountResource?.companyUuid;

    if (!companyUuid) {
      const {
        data: { companyUuid: uuid },
      } = yield retry(
        5,
        5000,
        client.get,
        `/finance/subaccounts/${action.payload.uuid}`
      );

      companyUuid = uuid;
    }

    const { data: partnerKeys }: AxiosResponse<PartnerPageAlooma[]> =
      yield call([client, 'get'], '/partner_page', {
        params: { companyUuid },
      });

    const { data: allAccessTokens }: AxiosResponse<AccessTokenWithSecret[]> =
      yield call(
        [client, 'get'],
        `/finance/subaccounts/${action.payload.uuid}/access-tokens`
      );

    const accessTokens = allAccessTokens.filter((a) => !('deletedAt' in a));

    const { data: rats } = yield call(
      [client, 'post'],
      '/auth/rich_access_tokens',
      {
        accessTokenUuids: accessTokens.slice(0, 100).map((a) => a.uuid),
      }
    );

    yield putResolve(richAccessTokenByUuidRequestAction.success(rats));

    const { data: primaryApplication }: AxiosResponse<Application> = yield call(
      [client, 'get'],
      `/finance/subaccounts/${action.payload.uuid}/application`
    );

    const { data: availableScopes }: AxiosResponse<string[]> = yield call(
      [client, 'get'],
      `/auth/companies/${companyUuid}/scopes`
    );

    yield put(
      actions.subAccountApplicationsByUuidAction.success({
        accessTokens,
        availableScopes,
        partnerKeys,
        primaryApplication,
        uuid: action.payload.uuid,
      })
    );
  } catch (err: any) {
    yield put(actions.subAccountApplicationsByUuidAction.failure(err));
  }
}

function* supplySubAccountTokenCreate({
  payload,
}: ActionType<
  typeof actions.supplySubAccountApplicationTokenCreateRequestAction.request
>) {
  try {
    const { data: tokens }: AxiosResponse<Record<string, AccessToken>> =
      yield call(
        [client, 'post'],
        `/finance/subaccounts/${payload.subAccountUuid}/access-tokens`,
        payload
      );

    const { data: rawAccessTokens }: AxiosResponse<AccessToken[]> = yield call(
      [client, 'get'],
      `/finance/subaccounts/${payload.subAccountUuid}/access-tokens`
    );

    const accessTokens = rawAccessTokens.filter((at) => !('deletedAt' in at));

    const newTokens = Object.keys(tokens).reduce((acc, tokenKey) => {
      const accessToken = accessTokens.find(
        (t) => t.uuid === tokens[tokenKey].uuid
      );

      return {
        ...acc,
        ...{ [tokenKey]: { ...accessToken, ...tokens[tokenKey] } },
      };
    }, {});

    const { data: rats } = yield call(
      [client, 'post'],
      '/auth/rich_access_tokens',
      {
        accessTokenUuids: Object.keys(tokens).map(
          (tokenKey) => tokens[tokenKey].uuid
        ),
      }
    );

    yield putResolve(richAccessTokenByUuidRequestAction.success(rats));

    yield put(
      actions.supplySubAccountApplicationTokenCreateRequestAction.success({
        tokens: newTokens,
        uuid: payload.subAccountUuid!,
      })
    );

    yield put(actions.supplyToggleApplicationTokenCreate());
  } catch (err: any) {
    yield put(
      actions.supplySubAccountApplicationTokenCreateRequestAction.failure(err)
    );
  }
}

export function* toggleDuplicateSupplySubAccount(
  action: ActionType<typeof actions.supplyToggleDuplicate>
) {
  const subAccountUuid = action.payload;

  yield put(
    leadHydrationActions.getLeadHydrationConfigsBySubAccountUuidAction.request({
      subAccountUuid,
    })
  );

  yield put(actions.supplyToggleDuplicateOpen(subAccountUuid));
}

function* createSupplySubAccount(
  action: ActionType<typeof actions.supplySubAccountCreateRequestAction.request>
) {
  try {
    const formParams = action.payload;

    const communicationEligibilities: string[] = Object.keys(
      CommunicationChannel
    ).reduce((accumulator, currentCC) => {
      if (
        currentCC in formParams &&
        formParams[currentCC as keyof typeof CommunicationChannel]
      ) {
        accumulator.push(
          CommunicationChannel[currentCC as keyof typeof CommunicationChannel]
        );
      }

      return accumulator;
    }, [] as string[]);

    const subAccountParams: PortalSubAccountCreateData = {
      accountUuid: formParams.account.uuid,
      coBrand: Boolean(formParams.coBrand),
      communicationEligibilities:
        communicationEligibilities as CommunicationChannel[],
      dataShare: formParams.dataShare,
      description: formParams.description,
      displayName: formParams.displayName,
      integrationType: formParams.integrationType as any,
      name: formParams.name,
      roles: ['leadgen-with-pii', 'leadgen-without-pii'],
      secondLookOfferConfiguration: formParams.secondLookOfferConfiguration,
      subAccountType: SubAccountType.SupplySource,
      // TODO: Current ant design component is temporarily single select that returns a string. Finance API expects string[]
      // Remove this logic of converting prop from string to string[1] when supplyProductTypes multi-select is enabled
      supplyProductTypes: [formParams.supplyProductType],
    };

    if (formParams.leadDeduplicationEnabled) {
      subAccountParams.leadDeduplicationDuration = formParams.leadDeduplication;
    }

    const { data: newSubAccount }: AxiosResponse<PortalSubAccount> = yield call(
      [client, 'post'],
      '/finance/subaccounts',
      subAccountParams
    );

    if (!formParams.lenderRemarketing) {
      yield call(() =>
        createActivePartnerFeature({
          feature: PartnerFeature.NoRemarketing,
          subAccountUuid: newSubAccount.uuid,
        })
      );
    }

    yield put(
      createExpectedEarningsShareAction.request({
        companyUuid: newSubAccount.companyUuid,
        hideUpdateAlert: true,
        share: '0.6',
        subAccountUuid: newSubAccount.uuid,
      })
    );

    if (formParams.leadHydrationEnabled) {
      if (formParams.supplyProductType !== ProductType.CreditCard) {
        yield put(
          createLeadHydrationConfigAction.request({
            enabledCreditBuckets: formParams.leadHydrationCreditBucketsEnabled,
            subAccountUuid: newSubAccount.uuid,
          })
        );
      } else {
        yield put(
          createCreditCardLeadHydrationConfigAction.request({
            subAccountUuid: newSubAccount.uuid,
          })
        );
      }
    }

    yield put(
      actions.supplySubAccountCreateRequestAction.success(newSubAccount)
    );
    Router.push(`/supply-sub-accounts/${newSubAccount.uuid}`);
    yield delay(100);
    yield call(
      message.success,
      'The supply sub account was created successfully'
    );
  } catch (err: any) {
    let message = err.message ? err.message : 'An error has occurred';

    if (err.request?.response) {
      const response = JSON.parse(err.request?.response);

      message = response?.message;
    }

    yield call([Toaster, 'error'], message);

    yield put(
      actions.supplySubAccountCreateRequestAction.failure({
        ...err,
        message,
      })
    );
  }
}

export function* createDemandSubAccount(
  action: ActionType<typeof actions.demandSubAccountCreateRequestAction.request>
) {
  try {
    const {
      accountUuid,
      description,
      disclaimer,
      displayName,
      image,
      name,
      offerCatalogProductType,
      offerFirmness,
      productType,
      shouldDisplayPreSelect,
      subAccountOwners,
      supportsPreSelect,
    } = action.payload;

    const imageAsBase64 = yield call(getBase64, image.file);

    const subAccountParams: PortalSubAccountCreateData = {
      accountUuid,
      description,
      disclaimer,
      displayName,
      imageAsBase64,
      name,
      offerCatalogProductType,
      offerFirmness,
      productType,
      roles: ['leadgen-with-pii', 'leadgen-without-pii'],
      shouldDisplayPreSelect: Boolean(shouldDisplayPreSelect),
      subAccountOwners: subAccountOwners as AccountOwnersWriteData,
      subAccountType: SubAccountType.DemandSource,
      supportsPreSelect: Boolean(supportsPreSelect),
    };

    const { data: newSubAccount }: AxiosResponse<PortalSubAccount> = yield call(
      [client, 'post'],
      '/finance/subaccounts',
      subAccountParams
    );

    yield put(
      actions.demandSubAccountCreateRequestAction.success(newSubAccount)
    );

    const newDemandSubaccountUrl = `/demand-sub-accounts/${newSubAccount.uuid}${
      productType ? `?productType=${encodeURIComponent(productType)}` : ''
    }`;

    yield call([Router, 'push'], newDemandSubaccountUrl);
    yield delay(100);
    yield call(
      message.success,
      'The demand sub account was created successfully'
    );
  } catch (err: any) {
    yield put(actions.demandSubAccountCreateRequestAction.failure(err));
  }
}

export function* updateSupplySubAccount(
  action: ActionType<typeof actions.supplySubAccountUpdateRequestAction.request>
) {
  try {
    const formParams = action.payload;

    formParams.subAccountType = SubAccountType.SupplySource;

    const subAccountOwners: Partial<AccountOwnersWriteData> = {
      supplyManager: formParams.subAccountOwners?.supplyPartnerManager,
      supplyYieldManager: formParams.subAccountOwners?.supplyYieldManager,
    };

    const updatedFormParams = {
      ...formParams,
      subAccountOwners,
      subAccountType: SubAccountType.SupplySource,
      // TODO: Current ant design component is temporarily single select that returns a string. Finance API expects string[]
      // Remove this logic of converting prop from string to string[1] when supplyProductTypes multi-select is enabled
      supplyProductTypes: Array.isArray(formParams.supplyProductTypes)
        ? formParams.supplyProductTypes
        : [formParams.supplyProductTypes],
    };
    const { data: updated }: AxiosResponse<PortalSubAccount> = yield call(
      [client, 'post'],
      `/finance/subaccounts/${updatedFormParams.uuid}`,
      updatedFormParams
    );

    yield put(actions.supplySubAccountUpdateRequestAction.success(updated));
    yield put(actions.subAccountByUuidAction.request({ uuid: updated.uuid }));
    yield delay(100);
    yield call(Toaster.success, 'The account was updated successfully');
  } catch (err: any) {
    yield put(actions.supplySubAccountUpdateRequestAction.failure(err));
  }
}

export function* updateDemandSubAccount(
  action: ActionType<typeof actions.demandSubAccountUpdateRequestAction.request>
) {
  try {
    const formParams = action.payload;

    formParams.subAccountType = SubAccountType.DemandSource;

    const subAccountOwners = {
      demandManager: formParams.subAccountOwners?.demandPartnerManager,
      demandYieldManager: formParams.subAccountOwners?.demandYieldManager,
    };

    if (formParams.image) {
      const imageAsBase64: Promise<string | ArrayBuffer | null> = yield call(
        getBase64,
        formParams.image.file
      );

      formParams.imageAsBase64 = imageAsBase64;
    }

    const updatedFormParams = {
      ...formParams,
      subAccountOwners,
    };

    const { data: updated }: AxiosResponse<PortalSubAccount> = yield call(
      [client, 'post'],
      `/finance/subaccounts/${updatedFormParams.uuid}`,
      updatedFormParams
    );

    yield put(actions.demandSubAccountUpdateRequestAction.success(updated));
    yield put(actions.subAccountByUuidAction.request({ uuid: updated.uuid }));
    yield delay(100);
    yield call(message.success, 'The account was updated successfully');
  } catch (err: any) {
    yield put(actions.demandSubAccountUpdateRequestAction.failure(err));
  }
}

export function* getDemandSubAccountDeactivations() {
  try {
    const {
      data: demandSubAccountDeactivations,
    }: AxiosResponse<DemandSubAccountDeactivation[]> = yield call(
      [client, 'get'],
      '/finance/deactivations/subaccounts/demand'
    );

    yield put(
      actions.demandSubAccountDeactivationRequestAction.success(
        demandSubAccountDeactivations
      )
    );
  } catch (err: any) {
    yield put(actions.demandSubAccountDeactivationRequestAction.failure(err));
  }
}

export function* createDemandSubAccountDeactivations(
  action: ActionType<
    typeof actions.createDemandSubAccountDeactivationsAction.request
  >
) {
  try {
    const { demandSubAccountIds } = action.payload;

    const {
      data: createdDeactivations,
    }: AxiosResponse<DemandSubAccountDeactivation[]> = yield call(
      [client, 'post'],
      '/finance/deactivations/subaccounts/demand',
      { demandSubAccountIds }
    );

    yield put(
      actions.createDemandSubAccountDeactivationsAction.success(
        createdDeactivations
      )
    );
    yield delay(100);
    yield call(
      message.success,
      'The Configuration Deactivations Successfully Updated'
    );
  } catch (err: any) {
    yield put(actions.createDemandSubAccountDeactivationsAction.failure(err));
  }
}

export function* deleteDemandSubAccountDeactivations(
  action: ActionType<
    typeof actions.deleteDemandSubAccountDeactivationAction.request
  >
) {
  try {
    const { deactivationIds } = action.payload;

    const {
      data: deleteDeactivations,
    }: AxiosResponse<DemandSubAccountDeactivation[]> = yield call(
      [client, 'put'],
      '/finance/deactivations/subaccounts/demand',
      { deactivationIds }
    );

    yield put(
      actions.deleteDemandSubAccountDeactivationAction.success(
        deleteDeactivations
      )
    );
    yield delay(100);
    yield call(
      message.success,
      'The Configuration Activations Successfully Updated'
    );
  } catch (err: any) {
    yield put(actions.deleteDemandSubAccountDeactivationAction.failure(err));
  }
}

export function* getSupplySubAccountDeactivations() {
  try {
    const {
      data: supplySubAccountDeactivation,
    }: AxiosResponse<SupplySubAccountDeactivation[]> = yield call(
      [client, 'get'],
      '/finance/deactivations/subaccounts/supply'
    );

    yield put(
      actions.supplySubAccountDeactivationRequestAction.success(
        supplySubAccountDeactivation
      )
    );
  } catch (err: any) {
    yield put(actions.supplySubAccountDeactivationRequestAction.failure(err));
  }
}

export function* createSupplySubAccountDeactivations(
  action: ActionType<
    typeof actions.createSupplySubAccountDeactivationsAction.request
  >
) {
  try {
    const { supplySubAccountIds } = action.payload;

    const {
      data: createdDeactivations,
    }: AxiosResponse<SupplySubAccountDeactivation[]> = yield call(
      [client, 'post'],
      '/finance/deactivations/subaccounts/supply',
      { supplySubAccountIds }
    );

    yield put(
      actions.createSupplySubAccountDeactivationsAction.success(
        createdDeactivations
      )
    );
    yield delay(100);
    yield call(
      message.success,
      'The Channel Deactivations Successfully Updated'
    );
  } catch (err: any) {
    yield put(actions.createSupplySubAccountDeactivationsAction.failure(err));
  }
}

export function* deleteSupplySubAccountDeactivations(
  action: ActionType<
    typeof actions.deleteSupplySubAccountDeactivationAction.request
  >
) {
  try {
    const { deactivationIds } = action.payload;

    const {
      data: deleteDeactivations,
    }: AxiosResponse<SupplySubAccountDeactivation[]> = yield call(
      [client, 'put'],
      '/finance/deactivations/subaccounts/supply',
      { deactivationIds }
    );

    yield put(
      actions.deleteSupplySubAccountDeactivationAction.success(
        deleteDeactivations
      )
    );
    yield delay(100);
    yield call(message.success, 'The Channel Activations Successfully Updated');
  } catch (err: any) {
    yield put(actions.deleteSupplySubAccountDeactivationAction.failure(err));
  }
}

function* watchDemandSubAccountDeactivations() {
  yield takeLatest(
    DemandSubAccountActionType.GET_ALL_DEMAND_SUB_ACCOUNT_DEACTIVATION_REQUEST,
    getDemandSubAccountDeactivations
  );
}

function* watchCreateDemandSubAccountDeactivations() {
  yield takeLatest(
    DemandSubAccountActionType.CREATE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_REQUEST,
    createDemandSubAccountDeactivations
  );
}

function* watchDeleteDemandSubAccountDeactivations() {
  yield takeLatest(
    DemandSubAccountActionType.DELETE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_REQUEST,
    deleteDemandSubAccountDeactivations
  );
}

function* watchSupplySubAccountDeactivations() {
  yield takeLatest(
    SupplySubAccountActionType.GET_ALL_SUPPLY_SUB_ACCOUNT_DEACTIVATION_REQUEST,
    getSupplySubAccountDeactivations
  );
}

function* watchCreateSupplySubAccountDeactivations() {
  yield takeLatest(
    SupplySubAccountActionType.CREATE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_REQUEST,
    createSupplySubAccountDeactivations
  );
}

function* watchDeleteSupplySubAccountDeactivations() {
  yield takeLatest(
    SupplySubAccountActionType.DELETE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_REQUEST,
    deleteSupplySubAccountDeactivations
  );
}

function* watchGetSupply() {
  yield takeLatest(
    SupplySubAccountActionType.GET_ALL_REQUEST,
    getSupplySubAccounts
  );
}

function* watchGetDemand() {
  yield takeLatest(
    DemandSubAccountActionType.GET_ALL_REQUEST,
    getDemandSubAccounts
  );
}

function* watchGetLenderDemand() {
  yield takeLatest(
    DemandSubAccountActionType.GET_ALL_LENDERS_REQUEST,
    getLenderDemandSubAccounts
  );
}

function* watchGetMigratedLenderDemand() {
  yield takeLatest(
    DemandSubAccountActionType.GET_ALL_MIGRATED_LENDERS_REQUEST,
    getMigratedLenderDemandSubAccounts
  );
}

function* watchGetDemandSupplyPartnersByUuid() {
  yield takeLatest(
    DemandSubAccountActionType.SUPPLY_PARTNERS_BY_UUID_REQUEST,
    getDemandSupplyPartnersByUuid
  );
}

function* watchToggleDuplicateSupplySubAccount() {
  yield takeLatest(
    SupplySubAccountActionType.TOGGLE_DUPLICATE,
    toggleDuplicateSupplySubAccount
  );
}

function* watchCreateSupplySubAccount() {
  yield takeLatest(
    SupplySubAccountActionType.CREATE_REQUEST_CLIENT,
    createSupplySubAccount
  );
}

function* watchCreateDemandSubAccount() {
  yield takeLatest(
    DemandSubAccountActionType.CREATE_REQUEST_CLIENT,
    createDemandSubAccount
  );
}

function* watchGetByUuidRequest() {
  yield takeLatest(SubAccountSharedActionType.BY_UUID_REQUEST, getByUuid);
}

function* watchGetComplianceItemsByUuidRequest() {
  yield takeLatest(
    SubAccountSharedActionType.COMPLIANCE_ITEMS_BY_UUID_REQUEST,
    getComplianceItemsByUuid
  );
}

function* watchGetApplicationsForSupplySubAccountRequest() {
  yield takeLatest(
    SupplySubAccountActionType.APPLICATIONS_BY_UUID_REQUEST,
    getApplicationsForSupplySubAccount
  );
}

function* watchSupplySubAccountTokenCreate() {
  yield takeLatest(
    SupplySubAccountActionType.APPLICATION_TOKEN_CREATE_REQUEST_CLIENT,
    supplySubAccountTokenCreate
  );
}

function* watchUpdateSupplySubAccount() {
  yield takeLatest(
    SupplySubAccountActionType.UPDATE_REQUEST,
    updateSupplySubAccount
  );
}

function* watchUpdateDemandSubAccount() {
  yield takeLatest(
    DemandSubAccountActionType.UPDATE_REQUEST,
    updateDemandSubAccount
  );
}

export function* subAccountSaga() {
  yield all([
    fork(watchGetSupply),
    fork(watchGetDemand),
    fork(watchGetLenderDemand),
    fork(watchGetMigratedLenderDemand),
    fork(watchGetDemandSupplyPartnersByUuid),
    fork(watchToggleDuplicateSupplySubAccount),
    fork(watchCreateSupplySubAccount),
    fork(watchCreateDemandSubAccount),
    fork(watchGetByUuidRequest),
    fork(watchGetComplianceItemsByUuidRequest),
    fork(watchGetApplicationsForSupplySubAccountRequest),
    fork(watchSupplySubAccountTokenCreate),
    fork(watchUpdateSupplySubAccount),
    fork(watchUpdateDemandSubAccount),
    fork(watchDemandSubAccountDeactivations),
    fork(watchCreateDemandSubAccountDeactivations),
    fork(watchDeleteDemandSubAccountDeactivations),
    fork(watchSupplySubAccountDeactivations),
    fork(watchCreateSupplySubAccountDeactivations),
    fork(watchDeleteSupplySubAccountDeactivations),
  ]);
}
