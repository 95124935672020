import { produce } from 'immer';
import { createReducer } from 'typesafe-actions';

import {
  receivableAddAdjustmentAction,
  receivableAdjustmentDeleteAction,
  receivableAdjustmentsRequestAction,
  receivableByIdRequestAction,
  receivableEventsByIdRequestAction,
  receivableRequestAction,
  toggleAddAdjustmentsForm,
  updateAddAdjustmentsForm,
} from './actions';
import type { ReceivableAction, ReceivableState } from './types';

export const initialState: ReceivableState = {
  addAdjustmentsDrawerOpen: false,
  addAdjustmentsForm: {},
  adjustments: [],
  byId: {},
  eventsById: {},
  haveAllAdjustmentsBeenRequested: false,
  haveAllReceivablesBeenRequested: false,
  rows: [],
};

export const receivableReducer = createReducer<
  ReceivableState,
  ReceivableAction
>(initialState)
  .handleAction(
    receivableRequestAction.success,
    (state, { payload: receivableSummaryArray }) =>
      produce(state, (draftState) => {
        draftState.haveAllReceivablesBeenRequested = true;
        draftState.rows = receivableSummaryArray;
      })
  )
  .handleAction(
    receivableByIdRequestAction.success,
    (state, { payload: ledgerItem }) =>
      produce(state, (draftState) => {
        draftState.byId[ledgerItem.id] = ledgerItem;
      })
  )
  .handleAction(
    receivableAddAdjustmentAction.success,
    (state, { payload: accountAdjustmentArray }) =>
      produce(state, (draftState) => {
        draftState.haveAllAdjustmentsBeenRequested = false;
        draftState.addAdjustmentsForm = {};
        draftState.addAdjustmentsDrawerOpen = !state.addAdjustmentsDrawerOpen;
        draftState.adjustments.push(...accountAdjustmentArray);
      })
  )
  .handleAction(
    toggleAddAdjustmentsForm,
    (state, { payload: { accountId, ledgerItemId } }) =>
      produce(state, (draftState) => {
        draftState.addAdjustmentsDrawerOpen = !state.addAdjustmentsDrawerOpen;
        draftState.addAdjustmentsForm = { accountId, ledgerItemId };
      })
  )
  .handleAction(
    updateAddAdjustmentsForm,
    (state, { payload: { accountId, ledgerItemId, adjustments } }) =>
      produce(state, (draftState) => {
        draftState.addAdjustmentsForm = {
          accountId,
          adjustments,
          ledgerItemId,
        };
      })
  )
  .handleAction(
    receivableAdjustmentsRequestAction.success,
    (state, { payload: adjustments }) =>
      produce(state, (draftState) => {
        draftState.adjustments = adjustments;
      })
  )
  .handleAction(
    receivableAdjustmentDeleteAction.success,
    (state, { payload: adjustment }) => {
      return produce(state, (draftState) => {
        draftState.adjustments = state.adjustments.filter(
          (adj) => adj.id !== adjustment.id
        );
      });
    }
  )
  .handleAction(
    receivableEventsByIdRequestAction.success,
    (state, { payload: ledgerItems }) =>
      produce(state, (draftState) => {
        const ledgerID = ledgerItems[0].ledgerItemId;

        draftState.eventsById[ledgerID] = ledgerItems;
      })
  );
