import { IntegrationStatus } from '@portal/api-models';

/**
 * Integration status possibly non-existent for pre-self-service partner pages
 * hence why we need to evaluate this.
 */
export const getIntegrationStatus = (
  integrationStatus: IntegrationStatus | undefined,
  isInMigration: boolean
): IntegrationStatus =>
  integrationStatus === IntegrationStatus.Live || isInMigration
    ? IntegrationStatus.Live
    : IntegrationStatus.Draft;
