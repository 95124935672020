import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { createSelector } from 'reselect';

import {
  selectSaasPlans,
  selectSaasSubscription,
  selectSaasSubscriptionPreview,
  selectSaasUserTrial,
} from '@/resources/saas/selects';
import type { PurchasePlanForm } from '@/resources/self-service/payment/purchase-plan/purchase-plan-form';
import {
  findMatchingSaasPlan,
  findMigrationSettingsSaasPlan,
} from '@/resources/self-service/payment/util-methods';
import { selectIsImplementationFeeWaived } from '@/resources/self-service/selects';
import { isPermanentTrial } from '@/resources/supply-migration/helpers';
import { selectSaasMigrationSettings } from '@/resources/supply-migration/selects';
import type { ApplicationState } from '@/store';
import type { SaasData } from '@/store/saas/types';
import { assembleSubscriptionPaymentRequestBody } from '@/store/saas/utils';

export const saasUserTrialSelector = createSelector(
  [selectSaasUserTrial],
  (userTrial) => userTrial
);

export const saasUserPlanSelector = createSelector(
  [(state: ApplicationState) => state.saas],
  (saas) => {
    return saas.plan;
  }
);

export const saasImplementationFeePlanSelector = createSelector(
  [
    saasUserPlanSelector,
    selectSaasMigrationSettings,
    (state: ApplicationState) => state.saas.plans,
  ],
  (currentPlan, migrationSettings, plans) => {
    if (currentPlan) {
      return currentPlan;
    }

    if (migrationSettings && isPermanentTrial(migrationSettings)) {
      return findMigrationSettingsSaasPlan(plans, migrationSettings.tier);
    }
  }
);

export const saasDataSelector = createSelector(
  [selectSaasPlans, saasUserPlanSelector, selectSaasSubscription],
  (plans, currentPlan, subscription) => {
    return {
      currentPlan,
      plans,
      subscription,
    } as SaasData;
  }
);

export const selectFindMigrationPlan = createSelector(
  [selectSaasSubscription, selectSaasMigrationSettings],
  (currentSubscription, supplyMigration) =>
    !currentSubscription &&
    Boolean(supplyMigration) &&
    !isPermanentTrial(supplyMigration)
);

export const subscriptionPreviewDataSelector = createSelector(
  [
    (state: ApplicationState, values: PurchasePlanForm) => {
      const plans = selectSaasPlans(state);
      const waiveImplementationFee = selectIsImplementationFeeWaived(state);
      const findMigrationPlan = selectFindMigrationPlan(state);
      const currentSubscription = selectSaasSubscription(state);
      const lastSubscriptionPreview = selectSaasSubscriptionPreview(state);

      return {
        currentSubscription,
        findMigrationPlan,
        lastSubscriptionPreview,
        plans,
        values,
        waiveImplementationFee,
      };
    },
  ],
  ({
    plans,
    values,
    waiveImplementationFee,
    findMigrationPlan,
    currentSubscription,
    lastSubscriptionPreview,
  }) => {
    if (
      !isEmpty(values.paymentAndBillingInformation) &&
      !isEmpty(values.planSummary)
    ) {
      const plan = findMatchingSaasPlan(
        plans,
        values.paymentAndBillingInformation.tier!,
        values.planSummary.planDuration,
        values.planSummary.billingCycle,
        waiveImplementationFee,
        findMigrationPlan
      );

      if (
        plan &&
        isEqual(
          lastSubscriptionPreview?.request,
          assembleSubscriptionPaymentRequestBody(
            values,
            plan.id,
            currentSubscription?.id
          )
        )
      ) {
        return lastSubscriptionPreview?.response;
      }
    }
  }
);

export const hideTrialExpirationSelector = createSelector(
  [selectSaasSubscription],
  (subscription) => Boolean(subscription)
);
