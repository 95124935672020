export const nonAuthenticatedRoutes = new Set([
  '/deactivated',
  '/health',
  '/login-error',
  '/session-timeout',
  '/sign-in-callback',
  '/sign-in',
  '/sign-out',
  '/unverified',
]);
