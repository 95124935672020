import * as React from 'react';

import { Input } from 'antd';
import debounce from 'lodash/debounce';

import { useControllable } from '@/components/form/portal-form';
import type {
  FieldComponent,
  FieldComponentProps,
} from '@/components/form/portal-form/types';

export type HexColorInputProvidedProps = FieldComponentProps<
  string | undefined
>;

const HexColorInput: FieldComponent<string, HexColorInputProvidedProps> = ({
  onChange,
  defaultValue,
  value,
  ...props
}) => {
  const [hexValue, setHexValue] = useControllable({
    defaultValue,
    onChange,
    value,
  });

  const debounceSetHexColor = debounce(setHexValue, 300);

  return (
    <div className="hex-color-input">
      <input
        className="hex-color-input-preview"
        type="color"
        value={hexValue}
        onChange={(e) => {
          debounceSetHexColor(e.target.value);
        }}
      />
      <div className="hex-color-input-form">
        <Input
          data-test-id="test-hex-input"
          maxLength={7}
          value={value}
          onChange={(e) => {
            setHexValue(e.target.value);
          }}
          {...props}
        />
      </div>
    </div>
  );
};

export default HexColorInput;
