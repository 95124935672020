"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.permissionConfigDefaults = void 0;
exports.permissionConfigDefaults = {
    canApplyDemandInclusionsTemplate: false,
    canCreateAccounts: false,
    canCreateDemandCaps: false,
    canCreateDemandContracts: false,
    canCreateDemandInclusionTemplates: false,
    canCreateFeatureFlags: false,
    canCreateLedgerItems: false,
    canCreatePartnerPages: false,
    canCreateSubAccounts: false,
    canCreateSupplyContracts: false,
    canCreateSupplyDemandCaps: false,
    canCreateUsers: false,
    canCreateWhitelist: false,
    canDeactivateAccounts: false,
    canDeactivateUsers: false,
    canDeleteDemandCaps: false,
    canDeleteSupplyDemandCaps: false,
    canEditDemandInclusionTemplates: false,
    canEditSecondLookOfferConfiguration: false,
    canEditSubAccountDisplayName: false,
    canEditSubAccountProductType: false,
    canEditSupplyTemplates: false,
    canManageAllAccounts: false,
    canManageDemandContracts: false,
    canManageFeatureFlags: false,
    canManageOfferCatalogOffers: false,
    canManageOfferCatalogProductTypes: false,
    canManageUserRoles: false,
    canReadDemandCaps: false,
    canReadDemandContracts: false,
    canReadIntegrations: false,
    canReadOfferCatalogOffers: false,
    canReadOfferCatalogProductTypes: false,
    canReadRuleDemandApiFilters: false,
    canReadStateExclusions: false,
    canReadSupplyDemandCaps: false,
    canReadUsers: false,
    canRequestAccounts: false,
    canRequestPartnerKeys: false,
    canRequestPerformanceSummary: false,
    canRequestSaasSubscription: false,
    canRequestSelfServiceData: false,
    canRequestUserTrial: false,
    canSeeAllAccounts: false,
    canSelectOfferRows: false,
    canSelectSubAccountRows: false,
    canSetupSubAccountFiltering: false,
    canUpdateDemandCaps: false,
    canUpdateSupplyDemandCaps: false,
    canUseWithActivateDeactivate: false,
    hasBenchmarkReportsFeatureFlag: false,
    hasComplianceFeatureFlag: false,
    hasCrmUserRole: false,
    hasDeactivateFeatureFlag: false,
    hasDemandCCMVPFeatureFlag: false,
    hasDemandHomepageFeatureFlag: false,
    hasDemandMVPFeatureFlag: false,
    hasDemandReceivablesFeatureFlag: false,
    hasDemandUserRole: false,
    hasDevToolsFeatureFlag: false,
    hasExternalUserRole: false,
    hasFirstRolloutOnboardingWhitelist: false,
    hasFreeTier: false,
    hasGrowthTier: false,
    hasGuideHomepageFeatureFlag: false,
    hasGuideIntegrationsFeatureFlag: false,
    hasGuidePerformanceSummaryFlag: false,
    hasGuideUsersFeatureFlag: false,
    hasInternalUserRole: false,
    hasMCIReportsFeatureFlag: false,
    hasManagerRole: false,
    hasNotificationsFeatureFlag: false,
    hasOnboardingFeatureFlag: false,
    hasOnboardingTrackingFeatureFlag: false,
    hasScaleTier: false,
    hasScaleUserRole: false,
    hasSelfServiceUserRole: false,
    hasStarterLegacyTier: false,
    hasStarterLegacyUserRole: false,
    hasStarterTier: false,
    hasSupplyUserRole: false,
    hasTutorialGettingStartedFeatureFlag: false,
    hasUpdateProductTypeSchemaFeatureFlag: false,
    hasVideoTutorialsFeatureFlag: false,
    hasViewerRole: false,
    hasWaiveImplementationFeeWhitelist: false,
    hidePartnerPageStatuses: false,
    isTrial: false,
    shouldBeAbleToRedirectToPreselectRules: false,
    shouldComputeInternalPartnerKeyData: false,
    shouldEnablePerformanceSummary: false,
    shouldForceCurrentUserRolesOnUserCreation: false,
    shouldGetAllSaasSettingsOnUserIndexPage: false,
    shouldGetDemandSubAccountsOnUserIndexPage: false,
    shouldGetPartnerKeysOnUserIndexPage: false,
    shouldGetSubAccountCompliance: false,
    shouldGetSubAccountOwners: false,
    shouldGetSupplySubAccountsOnUserDetailsPage: false,
    shouldGetSupplySubAccountsOnUserIndexPage: false,
    shouldGetUserDeactivationsOnUserDetailsPage: false,
    shouldHideTrialExpirationBar: false,
    shouldOverrideNavLocks: false,
    shouldOverrideOnboardingStatusOfMigratedUsers: false,
    shouldRedirectIndexEntryPointToBenchmarkReports: false,
    shouldRedirectIndexEntryPointToHomePage: false,
    shouldRedirectIndexEntryPointToMciReports: false,
    shouldRedirectIndexPageToAccount: false,
    shouldRedirectToAccountDetails: false,
    shouldRedirectToReceivableDetails: false,
    shouldRedirectToSupplyMigration: false,
    shouldRedirectToWelcome: false,
    shouldReplaceRouteParamPartnerPageWizard: false,
    shouldRestrictPersonalLoansDashboardDate: false,
    shouldRestrictUserSettingsEditing: false,
    shouldSetFeaturedTrustedPartnersOnPartnerPageUpdate: false,
    shouldSkipTrialExpiredPage: false,
    shouldSubmitForComplianceReviewOnPartnerPageUpdate: false,
    shouldUseExternalEntrypointOnIndexPage: false,
    shouldUseSelfServiceEntrypointOnIndexPage: false,
    showAccountActivateDeactivateCard: false,
    showAccountManagersForm: false,
    showAccountPayoutSettingsTab: false,
    showAccountPlanHeader: false,
    showAccountSubscriptionTab: false,
    showActivateDeactivateIndicator: false,
    showActivateYourPlanWarning: false,
    showAllDemandContracts: false,
    showBenchmarkReportsCard: false,
    showBenchmarkReportsMenuItemFeatureFlag: false,
    showBenchmarkReportsMenuItemIfFeatureFlag: false,
    showBenchmarkReportsSubMenu: false,
    showBenchmarkReportsSubMenuFeatureFlag: false,
    showBenchmarkReportsSubMenuIfFeatureFlag: false,
    showChangeOfferStatusButton: false,
    showChangeSubAccountStatusButton: false,
    showComplianceNavbarItem: false,
    showComplianceTab: false,
    showContractTabCopy: false,
    showContractsCardFeatureFlag: false,
    showContractsCardIfFeatureFlag: false,
    showControlCenterHome: false,
    showControlCenterHomeGettingStartedBox: false,
    showControlCenterHomeHeaderText: false,
    showControlCenterHomeSetupBankingInformation: false,
    showControlCenterLiteHome: false,
    showControlCenterSidebarTrialBanner: false,
    showCreateFeatureFlagButton: false,
    showCreateNewAccessTokenButton: false,
    showCreateNewAccountButton: false,
    showCreateNewDemandContractButton: false,
    showCreateNewDemandSubAccountButton: false,
    showCreateNewLedgerItemButton: false,
    showCreateNewPartnerKeyButton: false,
    showCreateNewPartnerPageButton: false,
    showCreateNewSubAccountButton: false,
    showCreateNewSupplyContractButton: false,
    showCreateNewSupplySubAccountButton: false,
    showCreateNewUserButton: false,
    showCreateWhitelistButton: false,
    showCreditCardSettingsCard: false,
    showCrmUserSideNav: false,
    showDeleteContractButton: false,
    showDeletePartnerKeyButton: false,
    showDemandFilteringTab: false,
    showDemandHomePage: false,
    showDemandPartnersTab: false,
    showDemandSubAccountsCard: false,
    showDemandUserDetails: false,
    showDemandUserSideNavFeatureFlag: false,
    showDemandUserSideNavIfFeatureFlag: false,
    showDemandUsersPageFeatureFlag: false,
    showDemandUsersPageIfFeatureFlag: false,
    showExportButton: false,
    showExternalAccountDetails: false,
    showExternalDemandAccountDetailsPropertyFields: false,
    showExternalSubAccountDetails: false,
    showExternalSupplyAccountDetailsPropertyFields: false,
    showExternalSupplySubaccountDetails: false,
    showExternalUserPayable: false,
    showExternalUserSideNavFeatureFlag: false,
    showExternalUserSideNavIfFeatureFlag: false,
    showFeedbackHeaderItem: false,
    showFeedbackItemFeatureFlag: false,
    showFeedbackItemIfFeatureFlag: false,
    showFirstIntegrationPage: false,
    showFootnote: false,
    showGettingStartedTutorial: false,
    showHelpCenterHeaderItemFeatureFlag: false,
    showHelpCenterHeaderItemIfFeatureFlag: false,
    showHelpCenterItemFeatureFlag: false,
    showHelpCenterItemIfFeatureFlag: false,
    showHomeMenuItem: false,
    showHomeMenuItemFeatureFlag: false,
    showHomeMenuItemIfFeatureFlag: false,
    showHomepageGuide: false,
    showIntegrationActions: false,
    showIntegrationCopyOverride: false,
    showIntegrationCreateButton: false,
    showIntegrationsGuideLink: false,
    showInternalAccessTokenTable: false,
    showInternalAccountDetails: false,
    showInternalContractHistoryTable: false,
    showInternalSubAccountDetails: false,
    showInternalSupplyPartnersTable: false,
    showInternalSupplySubaccountDetails: false,
    showInternalUserDetails: false,
    showInternalUserFeaturedSideNavIfFeatureFlag: false,
    showInternalUserSideNav: false,
    showInternalUsersPage: false,
    showMCIReportsCard: false,
    showMCIReportsMenuItemFeatureFlag: false,
    showMCIReportsMenuItemIfFeatureFlag: false,
    showMockComplianceRows: false,
    showNotificationsHeaderItemFeatureFlag: false,
    showNotificationsHeaderItemIfFeatureFlag: false,
    showOnboardingTrackingTab: false,
    showPartnerKeyItemDropdownActions: false,
    showPartnerKeyItemIntegrationStatus: false,
    showPartnerKeyItemLinkToPartnerPage: false,
    showPartnerPageAllowCoApplicantsField: false,
    showPartnerPageEmbedFeaturedOfferField: false,
    showPartnerPageFeatureFlagSelectField: false,
    showPartnerPageLoanPurposeSelectField: false,
    showPartnerPageThemeDisplayNameField: false,
    showPartnerPageThemeHeaderCopyField: false,
    showPartnerPageThemeHeaderTextField: false,
    showPartnerPageThemeInPartnershipWithField: false,
    showPartnerPageTrackingTab: false,
    showPayoutForm: false,
    showPerformanceReportCard: false,
    showPerformanceSummaryGuideLink: false,
    showPerformanceSummaryMenuItem: false,
    showPersonalLoanDashboardSubAccountDropdown: false,
    showRebrandGuideAndAssetsLink: false,
    showScaleUsersPage: false,
    showSelfServiceUserDetails: false,
    showSelfServiceUserDetailsActivateDeactivateIndicator: false,
    showSelfServiceUserSideNavFeatureFlag: false,
    showSelfServiceUserSideNavIfFeatureFlag: false,
    showSelfServiceUserSideNavbar: false,
    showSelfServiceUsersPage: false,
    showSidebarResources: false,
    showSubAccountActivateDeactivateCard: false,
    showSubAccountNameColumn: false,
    showSubAccountNameLabel: false,
    showSupplyHomePage: false,
    showSupplySubAccountsCard: false,
    showTrialExpirationBar: false,
    showUserActivateDeactivateCard: false,
    showUserActivateDeactivateFormFields: false,
    showUserDetailsActivateDeactivateIndicator: false,
    showUserSettingsAccessTab: false,
    showUsersGuideLink: false,
    showVideoTutorials: false,
    showWithActivateDeactivateStatusAction: false,
};
