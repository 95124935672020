import type { ColumnProps } from 'antd/lib/table';

import { SummaryGroupByLabelsMap } from '@/components/reporting/summary-group-by';
import {
  createAmountColumn,
  createNumericColumn,
  createStringColumn,
} from '@/components/table/columns';
import { summaryGroupByValueFormatter } from '@/resources/channels/performance-summary/shared/formatters';
import { ContractApplicationType } from '@/store/demand-contracts/types';

const getConditionalTableColumns = (
  contractApplicationType: ContractApplicationType
): Array<ColumnProps<any>> => {
  switch (contractApplicationType) {
    case ContractApplicationType.CompletedApplication:
      return [
        createNumericColumn({
          align: 'right',
          key: 'numLeadsCompletedApp',
          title: 'Completed Applications',
        }),
      ];

    case ContractApplicationType.SubmittedApplication:
      return [
        createNumericColumn({
          align: 'right',
          key: 'numLeadsSubmittedApp',
          title: 'Submitted Applications',
        }),
        createNumericColumn({
          align: 'right',
          key: 'numLeadsPreapproved',
          title: 'Pre-Approved Applications',
        }),
        createNumericColumn({
          align: 'right',
          key: 'numLeadsClicked',
          title: 'Unique Offer Clicks',
        }),
      ];

    default:
      return [];
  }
};

export const loansFunnelTableConfig = (
  contractApplicationType: ContractApplicationType
) => {
  return (groupBy: string): Array<ColumnProps<any>> => [
    createStringColumn({
      key: 'groupKey',
      render: summaryGroupByValueFormatter,
      title: SummaryGroupByLabelsMap[groupBy],
    }),
    createNumericColumn({
      align: 'right',
      key: 'numLeads',
      title: 'Leads',
    }),
    ...getConditionalTableColumns(contractApplicationType),
    createNumericColumn({
      align: 'right',
      key: 'numLeadsFunded',
      title: 'Funded Loans',
    }),
    createAmountColumn({
      align: 'right',
      key: 'totalPayout',
      title: 'Attributed Earnings',
    }),
  ];
};
