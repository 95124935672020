import * as React from 'react';

export interface OverlayProps {
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const Overlay: React.FC<OverlayProps> = ({ onClick, style, className }) => {
  return (
    <div
      className={className ? className : 'portal-overlay'}
      style={style}
      onClick={onClick}
    ></div>
  );
};

export default Overlay;
