"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerticalType = exports.PayoutStructure = exports.ComplianceFieldId = exports.ComplianceStatusId = exports.ComplianceStatus = exports.ComplianceIntegrationType = void 0;
var ComplianceIntegrationType;
(function (ComplianceIntegrationType) {
    ComplianceIntegrationType["api"] = "API";
    ComplianceIntegrationType["declines"] = "Declines";
    ComplianceIntegrationType["embed"] = "Embed";
    ComplianceIntegrationType["partnerPage"] = "Partner Page";
})(ComplianceIntegrationType = exports.ComplianceIntegrationType || (exports.ComplianceIntegrationType = {}));
var ComplianceStatus;
(function (ComplianceStatus) {
    ComplianceStatus["backlog"] = "Backlog";
    ComplianceStatus["closed"] = "Closed";
    ComplianceStatus["demandPartnerReview"] = "Demand Partner Review";
    ComplianceStatus["draft"] = "Draft";
    ComplianceStatus["identifiedViolation"] = "Identified Violation";
    ComplianceStatus["internalReview"] = "Internal Review";
    ComplianceStatus["rejected"] = "Rejected";
    ComplianceStatus["todo"] = "To Do";
    ComplianceStatus["waitingOnDemandPartnerReview"] = "Waiting on Demand Partner Review";
    ComplianceStatus["waitingOnSupplyPartner"] = "Waiting on Supply Partner";
})(ComplianceStatus = exports.ComplianceStatus || (exports.ComplianceStatus = {}));
var ComplianceStatusId;
(function (ComplianceStatusId) {
    ComplianceStatusId["Backlog"] = "10000";
    ComplianceStatusId["Closed"] = "10002";
    ComplianceStatusId["DemandPartnerReview"] = "10126";
    ComplianceStatusId["IdentifiedViolation"] = "10089";
    ComplianceStatusId["InternalReview"] = "10125";
    ComplianceStatusId["Rejected"] = "10040";
    ComplianceStatusId["Todo"] = "10015";
    ComplianceStatusId["WaitingOnDemandPartnerReview"] = "";
    ComplianceStatusId["WaitingOnSupplyPartner"] = "10194";
})(ComplianceStatusId = exports.ComplianceStatusId || (exports.ComplianceStatusId = {}));
var ComplianceFieldId;
(function (ComplianceFieldId) {
    ComplianceFieldId["AccountUuid"] = "customfield_10237";
    ComplianceFieldId["DemandSubAccountCompanyUuid"] = "customfield_10228";
    ComplianceFieldId["SupplySubAccountCompanyUuid"] = "customfield_10061";
})(ComplianceFieldId = exports.ComplianceFieldId || (exports.ComplianceFieldId = {}));
var PayoutStructure;
(function (PayoutStructure) {
    PayoutStructure["fixed"] = "Fixed (CPL, etc)";
    PayoutStructure["revShare"] = "Rev Share";
    PayoutStructure["rtp"] = "RTP";
})(PayoutStructure = exports.PayoutStructure || (exports.PayoutStructure = {}));
var VerticalType;
(function (VerticalType) {
    VerticalType["creditCards"] = "Credit Cards";
    VerticalType["insurance"] = "Insurance";
    VerticalType["lifeInsurance"] = "Life Insurance";
    VerticalType["personalLoans"] = "Personal Loans";
    VerticalType["savings"] = "Savings";
})(VerticalType = exports.VerticalType || (exports.VerticalType = {}));
