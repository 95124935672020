import type { ServerResponse } from 'http';

import { action, createAsyncAction } from 'typesafe-actions';

import type { IntegrationStatus, PartnerPageAlooma } from '@portal/api-models';
import type { PortalError } from '@portal/common';
import type { FirstIntegrationFormState } from '@portal/ui-lib';

import type { IntegrationCreateForm } from '@/resources/self-service/integrations/create-form';

import type { PartnerPageCreateForm } from '../../pages/partner-page/create';
import type { PartnerPageUpdateForm } from '../../pages/partner-page/update';
import type { IsomorphicRequestPayload } from '../types';

import { PartnerPageActionType } from './types';

export const toggleCreate = () => action(PartnerPageActionType.TOGGLE_CREATE);
export const toggleDuplicate = (id: string) =>
  action(PartnerPageActionType.TOGGLE_DUPLICATE, id);

export interface PartnerPageDeleteAction extends IsomorphicRequestPayload {
  id: string;
  supplySubAccountUuid: string;
}

export interface IntegrationDetailsPayload extends IsomorphicRequestPayload {
  defaultTab: string;
  partnerPageId: string;
}

export const partnerPageCreateRequestAction = createAsyncAction(
  PartnerPageActionType.CREATE_REQUEST_CLIENT,
  PartnerPageActionType.CREATE_SUCCESS_CLIENT,
  PartnerPageActionType.CREATE_FAILURE_CLIENT
)<PartnerPageCreateForm, any, PortalError>();

export const partnerPageCreateSelfServiceRequestAction = createAsyncAction(
  PartnerPageActionType.CREATE_PARTNER_PAGE_REQUEST,
  PartnerPageActionType.CREATE_PARTNER_PAGE_SUCCESS,
  PartnerPageActionType.CREATE_PARTNER_PAGE_FAILURE
)<FirstIntegrationFormState, any, PortalError>();

export const partnerPageUpdateRequestAction = createAsyncAction(
  PartnerPageActionType.UPDATE_REQUEST_CLIENT,
  PartnerPageActionType.UPDATE_SUCCESS_CLIENT,
  PartnerPageActionType.UPDATE_FAILURE_CLIENT
)<PartnerPageUpdateForm, any, Error>();

export const partnerPageDeleteRequestAction = createAsyncAction(
  PartnerPageActionType.DELETE_REQUEST_CLIENT,
  PartnerPageActionType.DELETE_SUCCESS_CLIENT,
  PartnerPageActionType.DELETE_FAILURE_CLIENT
)<PartnerPageDeleteAction, any, Error>();

export interface PartnerPageByKeyRequestPayload
  extends IsomorphicRequestPayload {
  id: string;
  reloadPartnerPage?: boolean;
  res?: ServerResponse;
}

export const partnerPageByKeyAction = createAsyncAction(
  PartnerPageActionType.BY_KEY_REQUEST,
  PartnerPageActionType.BY_KEY_SUCCESS,
  PartnerPageActionType.BY_KEY_FAILURE
)<PartnerPageByKeyRequestPayload, PartnerPageAlooma, Error>();

export interface getAllRequestPayload extends IsomorphicRequestPayload {
  getAllByKey?: boolean;
}

export const partnerKeyRequestAction = createAsyncAction(
  PartnerPageActionType.GET_ALL_REQUEST,
  PartnerPageActionType.GET_ALL_SUCCESS,
  PartnerPageActionType.GET_ALL_FAILURE
)<getAllRequestPayload, PartnerPageAlooma[], Error>();

export const getAllWizardData = createAsyncAction(
  PartnerPageActionType.GET_ALL_WIZARD_DATA_REQUEST,
  PartnerPageActionType.GET_ALL_WIZARD_DATA_SUCCESS,
  PartnerPageActionType.GET_ALL_WIZARD_DATA_FAILURE
)<PartnerPageByKeyRequestPayload, void, Error>();

export const getAllDetailsData = createAsyncAction(
  PartnerPageActionType.GET_ALL_DETAILS_DATA_REQUEST,
  PartnerPageActionType.GET_ALL_DETAILS_DATA_SUCCESS,
  PartnerPageActionType.GET_ALL_DETAILS_DATA_FAILURE
)<IntegrationDetailsPayload, void, Error>();

export interface getByIdRequestPayload extends IsomorphicRequestPayload {
  id: number;
}

export const partnerKeyByIdRequestAction = createAsyncAction(
  PartnerPageActionType.GET_BY_ID_REQUEST,
  PartnerPageActionType.GET_BY_ID_SUCCESS,
  PartnerPageActionType.GET_BY_ID_FAILURE
)<getByIdRequestPayload, PartnerPageAlooma[], Error>();

export interface ReplaceImageRequestPayload extends IsomorphicRequestPayload {
  file: string;
  id: string;
  key: string;
}

export const partnerKeyReplaceImageAction = createAsyncAction(
  PartnerPageActionType.REPLACE_IMAGE_REQUEST_CLIENT,
  PartnerPageActionType.REPLACE_IMAGE_SUCCESS_CLIENT,
  PartnerPageActionType.REPLACE_IMAGE_FAILURE_CLIENT
)<ReplaceImageRequestPayload, null, Error>();

export const partnerKeyUpdateIntegrationStatusAction = createAsyncAction(
  PartnerPageActionType.UPDATE_INTEGRATION_STATUS_REQUEST_CLIENT,
  PartnerPageActionType.UPDATE_INTEGRATION_STATUS_SUCCESS_CLIENT,
  PartnerPageActionType.UPDATE_INTEGRATION_STATUS_FAILURE_CLIENT
)<
  {
    displaySuccess?: boolean;
    id: string;
    key: string;
    redirectPath?: string;
    status: IntegrationStatus;
  },
  { resp: any; updatePartnerKeyId: string },
  Error
>();

export const partnerPageCreateIntegrationRequestAction = createAsyncAction(
  PartnerPageActionType.CREATE_INTEGRATION_REQUEST,
  PartnerPageActionType.CREATE_INTEGRATION_SUCCESS,
  PartnerPageActionType.CREATE_INTEGRATION_FAILURE
)<IntegrationCreateForm, any, PortalError>();

interface PPKeyValidationData extends IsomorphicRequestPayload {
  partnerPageKey: string;
  throwError?: boolean;
}

export const partnerPageKeyValidationRequestAction = createAsyncAction(
  PartnerPageActionType.PARTNER_PAGE_KEY_VALIDATION_REQUEST,
  PartnerPageActionType.PARTNER_PAGE_KEY_VALIDATION_SUCCESS,
  PartnerPageActionType.PARTNER_PAGE_KEY_VALIDATION_FAILURE
)<PPKeyValidationData, boolean | undefined, PortalError>();

export const initSelfServiceIntegrationsPageAction = () =>
  action(PartnerPageActionType.INIT_SELF_SERVICE_INTEGRATIONS_PAGE);

export const toggleIntegrationCreate = () =>
  action(PartnerPageActionType.TOGGLE_INTEGRATION_CREATE);

export const openUpgradeToGrowthModal = () =>
  action(PartnerPageActionType.OPEN_UPGRADE_TO_GROWTH_MODAL);

export const closeUpgradeToGrowthModal = () =>
  action(PartnerPageActionType.CLOSE_UPGRADE_TO_GROWTH_MODAL);
