import * as React from 'react';

import Select from 'antd/lib/select';

import { UsStatesEnum } from '@portal/api-models';

import type { ComposedRecordDropdownProps } from '@/components/form/portal-form/fields/record-dropdown';

type UsStateDropdownProps = ComposedRecordDropdownProps;
export type UsStateDropdownProvidedProps = UsStateDropdownProps;

const UsStateDropdown: React.FC<UsStateDropdownProps> = (props) => {
  const { ...selectProps } = props;

  return (
    <Select
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
      mode={'multiple'}
      {...selectProps}
    >
      {Object.keys(UsStatesEnum).map((stateCode) => (
        <Select.Option key={stateCode} value={stateCode}>
          {stateCode}
        </Select.Option>
      ))}
    </Select>
  );
};

export default UsStateDropdown;
