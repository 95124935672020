import type { ActionType } from 'typesafe-actions';

import type { PortalPreSelectRule } from '@portal/api-models';

import type * as actions from './actions';

export type PreSelectRuleAction = ActionType<typeof actions>;
export enum PreSelectRuleActionType {
  GET_ALL_FAILURE = '@@preselect-rule/GET_ALL_FAILURE',
  GET_ALL_REQUEST = '@@preselect-rule/GET_ALL_REQUEST',
  GET_ALL_SUCCESS = '@@preselect-rule/GET_ALL_SUCCESS',
}

export interface PreSelectRuleState {
  readonly haveAllPreSelectRulesBeenRequested: boolean;
  rows: PortalPreSelectRule[];
}
