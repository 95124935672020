"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TutorialVideoSeriesType = exports.TutorialVideoType = void 0;
var TutorialVideoType;
(function (TutorialVideoType) {
    TutorialVideoType["GETTING_STARTED_VIDEO"] = "getting-started-video";
})(TutorialVideoType = exports.TutorialVideoType || (exports.TutorialVideoType = {}));
var TutorialVideoSeriesType;
(function (TutorialVideoSeriesType) {
    TutorialVideoSeriesType["EMBED_VIDEOS"] = "tutorial-videos-embed";
    TutorialVideoSeriesType["PARTNER_PAGE_VIDEOS"] = "tutorial-videos-pp";
})(TutorialVideoSeriesType = exports.TutorialVideoSeriesType || (exports.TutorialVideoSeriesType = {}));
