"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMemoizedSerialize = void 0;
const serialize_1 = require("./serialize");
const createMemoizedSerialize = () => {
    let cachedValue = '';
    let cachedSerialValue = '';
    return function (value) {
        if (value !== cachedValue) {
            cachedValue = value;
            cachedSerialValue = (0, serialize_1.serialize)(value);
        }
        return cachedSerialValue;
    };
};
exports.createMemoizedSerialize = createMemoizedSerialize;
