"use strict";
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.originatorExternalSaga = exports.originatorSaga = exports.createSecondLookOfferConfigurationSaga = exports.getSecondLookOfferConfigurationsBySupplySubAccountIdSaga = exports.demandApiSaga = void 0;
var effects_1 = require("redux-saga/effects");
var typesafe_actions_1 = require("typesafe-actions");
var api_client_1 = require("@portal/api-client");
var common_1 = require("@portal/common");
var actions_1 = require("./actions");
function getDemandApisSaga(_a) {
    var demandApis, err_1;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4, (0, effects_1.put)(actions_1.getDemandApisAction.success([]))];
            case 1:
                _b.sent();
                return [4, (0, effects_1.call)([api_client_1.client, 'get'], '/originator/demandApis', { params: payload })];
            case 2:
                demandApis = (_b.sent()).data;
                return [4, (0, effects_1.put)(actions_1.getDemandApisAction.success(demandApis))];
            case 3:
                _b.sent();
                return [3, 6];
            case 4:
                err_1 = _b.sent();
                return [4, (0, effects_1.put)(actions_1.getDemandApisAction.failure(err_1))];
            case 5:
                _b.sent();
                return [3, 6];
            case 6: return [2];
        }
    });
}
function getDemandApisBySubAccountUuidSaga(_a) {
    var demandApis, err_2;
    var uuid = _a.payload.uuid;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 5]);
                return [4, (0, effects_1.call)([api_client_1.client, 'get'], '/originator/demandApis', {
                        params: { subAccountUuid: uuid },
                    })];
            case 1:
                demandApis = (_b.sent()).data;
                return [4, (0, effects_1.put)(actions_1.getDemandApisBySubAccountUuidAction.success({
                        demandApis: demandApis,
                        uuid: uuid,
                    }))];
            case 2:
                _b.sent();
                return [3, 5];
            case 3:
                err_2 = _b.sent();
                return [4, (0, effects_1.put)(actions_1.getDemandApisBySubAccountUuidAction.failure(err_2))];
            case 4:
                _b.sent();
                return [3, 5];
            case 5: return [2];
        }
    });
}
function demandApiSaga() { return __generator(this, function (_a) {
    return [2];
}); }
exports.demandApiSaga = demandApiSaga;
function getSecondLookOfferConfigurationsBySupplySubAccountIdSaga(action) {
    var supplySubAccountId, secondLookOfferConfigurations, err_3, portalError;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                supplySubAccountId = action.payload.supplySubAccountId;
                return [4, (0, effects_1.call)([api_client_1.client, 'get'], '/originator/second-look-offer-configurations', { params: { supplySubAccountId: supplySubAccountId } })];
            case 1:
                secondLookOfferConfigurations = (_a.sent()).data;
                return [4, (0, effects_1.put)(actions_1.getSecondLookOfferConfigurationsBySupplySubAccountIdAction.success({
                        secondLookOfferConfigurations: secondLookOfferConfigurations,
                        supplySubAccountId: supplySubAccountId,
                    }))];
            case 2:
                _a.sent();
                return [3, 5];
            case 3:
                err_3 = _a.sent();
                portalError = (0, common_1.getPortalError)(err_3);
                return [4, (0, effects_1.put)(actions_1.getSecondLookOfferConfigurationsBySupplySubAccountIdAction.failure(portalError))];
            case 4:
                _a.sent();
                return [3, 5];
            case 5: return [2];
        }
    });
}
exports.getSecondLookOfferConfigurationsBySupplySubAccountIdSaga = getSecondLookOfferConfigurationsBySupplySubAccountIdSaga;
function createSecondLookOfferConfigurationSaga(action) {
    var err_4, portalError;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, (0, effects_1.call)([api_client_1.client, 'post'], '/originator/second-look-offer-configurations', action.payload)];
            case 1:
                _a.sent();
                return [4, (0, effects_1.put)(actions_1.createSecondLookOfferConfigurationAction.success())];
            case 2:
                _a.sent();
                return [3, 5];
            case 3:
                err_4 = _a.sent();
                portalError = (0, common_1.getPortalError)(err_4);
                return [4, (0, effects_1.put)(actions_1.createSecondLookOfferConfigurationAction.failure(portalError))];
            case 4:
                _a.sent();
                return [3, 5];
            case 5: return [2];
        }
    });
}
exports.createSecondLookOfferConfigurationSaga = createSecondLookOfferConfigurationSaga;
function originatorSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, (0, effects_1.takeLatest)((0, typesafe_actions_1.getType)(actions_1.getDemandApisAction.request), getDemandApisSaga)];
            case 1:
                _a.sent();
                return [4, (0, effects_1.takeLatest)((0, typesafe_actions_1.getType)(actions_1.getDemandApisBySubAccountUuidAction.request), getDemandApisBySubAccountUuidSaga)];
            case 2:
                _a.sent();
                return [4, (0, effects_1.takeLatest)((0, typesafe_actions_1.getType)(actions_1.getSecondLookOfferConfigurationsBySupplySubAccountIdAction.request), getSecondLookOfferConfigurationsBySupplySubAccountIdSaga)];
            case 3:
                _a.sent();
                return [4, (0, effects_1.takeLatest)((0, typesafe_actions_1.getType)(actions_1.createSecondLookOfferConfigurationAction.request), createSecondLookOfferConfigurationSaga)];
            case 4:
                _a.sent();
                return [2];
        }
    });
}
exports.originatorSaga = originatorSaga;
function originatorExternalSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, (0, effects_1.all)([(0, effects_1.fork)(originatorSaga)])];
            case 1:
                _a.sent();
                return [2];
        }
    });
}
exports.originatorExternalSaga = originatorExternalSaga;
