import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';

import type {
  CustomValidator,
  PortalFormField,
  RequiredValidator,
  Validator,
} from '@/components/form/portal-form/types';

type ValidatorWithDefaults<T extends object> = Validator<T> & {
  defaultMessage: string;
};

export type ValidationMap<T extends object> = Record<
  string,
  ValidatorWithDefaults<T>[]
>;

const createDefaultMessage = <T extends object>(
  field: PortalFormField<T>,
  validation: Validator<T>
): string => {
  const label = field.label ?? upperFirst(String(field.name));

  if ((validation as RequiredValidator)?.required) {
    return `"${label}" is a required field`;
  }

  return `"${label}" is invalid`;
};

/**
 * Given an array of fields, extract validations and key by the "name"
 * property string.
 */
export const createValidationMap = <T extends object>(
  fields: PortalFormField<T>[]
): ValidationMap<T> => {
  return fields.reduce((map: ValidationMap<T>, field) => {
    const { validation, name } = field;

    if (name && validation) {
      map[name] = validation.map((validator) => ({
        ...validator,
        defaultMessage: createDefaultMessage(field, validator),
      }));
    }

    return map;
  }, {});
};

/**
 * Validate a single field given a key, value and validationMap.
 *
 * @template T
 *
 * @param {string} path
 * @param {ValidatorWithDefaults<T>[]} validations
 * @param {T} formValues
 *
 * @returns {(null | string)}
 * Returns null if validation passes. If not, it returns an error message.
 */
export const validateOne = async <T extends object>(
  path: string,
  validations: ValidatorWithDefaults<T>[],
  formValues: T
): Promise<null | string> => {
  const value = get(formValues, path);

  let error: null | string = null;

  for (const validation of validations) {
    const message = validation?.message ?? validation.defaultMessage;
    const required = (validation as RequiredValidator)?.required;
    const customValidator = (validation as CustomValidator<T>)?.validator;

    if (required && (typeof value === 'object' ? isEmpty(value) : !value)) {
      error = message;
      break;
    }

    let customValidatorResponse: boolean | Promise<boolean>;

    if (customValidator) {
      const customValidatorInstance = customValidator(value, formValues);

      customValidatorResponse =
        customValidatorInstance instanceof Promise
          ? // eslint-disable-next-line no-await-in-loop
            await customValidatorInstance
          : customValidatorInstance;

      if (!customValidatorResponse) {
        error = message;
        break;
      }
    }
  }

  return error;
};

/**
 * Validate all fields at once.
 *
 * @template T
 *
 * @param {ValidationMap<T>} validationMap
 * @param {formValues} T
 *
 * @returns Promise<{Record<string, null | string>}>
 * Returns a map of errors keyed by the "name" property string.
 */
export const validateAll = async <T extends object>(
  validationMap: ValidationMap<T>,
  formValues: T
): Promise<Record<string, null | string>> => {
  const errors: any = {};
  const paths = Object.keys(validationMap);

  const errorPromises = paths.map(async (path) => {
    const validateOneInstance = validateOne(
      path,
      validationMap[path],
      formValues
    );

    const error =
      validateOneInstance instanceof Promise
        ? await validateOneInstance
        : validateOneInstance;

    if (error) {
      errors[path] = error;
    }
  });

  await Promise.all(errorPromises);

  return errors;
};
