import { createAsyncAction } from 'typesafe-actions';

import type { DemandDataImportSummary } from '@evenfinancial/finance-client';

import type { PortalError } from '@portal/common';

import type { IsomorphicRequestPayload } from '@/store/types';

import { DemandDataImportActionType } from './types';

export const getDemandDataImportSummariesAll = createAsyncAction(
  DemandDataImportActionType.GET_ALL_DEMAND_DATA_IMPORT_SUMMARIES_REQUEST,
  DemandDataImportActionType.GET_ALL_DEMAND_DATA_IMPORT_SUMMARIES_SUCCESS,
  DemandDataImportActionType.GET_ALL_DEMAND_DATA_IMPORT_SUMMARIES_FAILURE
)<IsomorphicRequestPayload, DemandDataImportSummary[], PortalError>();
