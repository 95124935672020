"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSerializedItemPredicate = void 0;
const create_memoized_serialize_1 = require("./create-memoized-serialize");
const serialize_1 = require("./serialize");
const createSerializedItemPredicate = (getItemValue) => {
    const memoizedSerialize = (0, create_memoized_serialize_1.createMemoizedSerialize)();
    return (query, item) => (0, serialize_1.serialize)(getItemValue(item)).includes(memoizedSerialize(query));
};
exports.createSerializedItemPredicate = createSerializedItemPredicate;
