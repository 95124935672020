import { keyBy } from 'lodash';
import { createSelector } from 'reselect';

import type { PartnerPageAlooma } from '@portal/api-models';

import { getSelectSupplySubAccountByCompanyUuid } from '@/resources/supply-sub-accounts/shared/selects';
import type { SelectSubAccount } from '@/resources/supply-sub-accounts/shared/selects';

import type { ApplicationState } from '../../store';

type SelectPartnerPage = (
  state: ApplicationState
) => PartnerPageAlooma | undefined;

/**
 * @param {ApplicationState} state
 * @return PartnerPageAlooma[]
 */
export const selectPartnerPages = (
  state: ApplicationState
): PartnerPageAlooma[] => state.partnerPages.rows;

export const selectPartnerPagesByCompanyUuid = createSelector(
  [selectPartnerPages],
  (partnerPages) => keyBy(partnerPages, 'companyUuid')
);

/**
 * @param {boolean} [invert=false]
 * @returns {(state: ApplicationState) => boolean}
 */
export const getSelectHasPartnerPages =
  (invert: boolean = false) =>
  (state: ApplicationState): boolean => {
    const hasPartnerPages = selectPartnerPages(state).length > 0;

    return !invert ? hasPartnerPages : !hasPartnerPages;
  };

export const selectHasPartnerPages = getSelectHasPartnerPages(false);
export const selectNotHasPartnerPages = getSelectHasPartnerPages(true);

export const selectHasPartnerPagesLoaded = (
  state: ApplicationState
): boolean => {
  return (
    state.partnerPages.haveAllBeenRequested || selectHasPartnerPages(state)
  );
};

/**
 * @param {string} id
 * @return {SelectPartnerPage}
 */
export const getSelectPartnerPageById =
  (id: string): SelectPartnerPage =>
  (state) =>
    selectPartnerPages(state).find((partnerPage) => partnerPage.id === id);

/**
 * @param {string} partnerPageId
 * @return {SelectSubAccount}
 */
export const getSelectSupplySubAccountByPartnerPageId =
  (partnerPageId: string): SelectSubAccount =>
  (state) => {
    const partnerPage = getSelectPartnerPageById(partnerPageId)(state);

    if (partnerPage) {
      return getSelectSupplySubAccountByCompanyUuid(partnerPage.companyUuid)(
        state
      );
    }
  };
