import type { ActionType } from 'typesafe-actions';

import type { PortalSubAccount } from '@portal/api-models';

import type * as actions from './actions';

export type DemandConfigurationsAction = ActionType<typeof actions>;

export enum DemandConfigurationsActionType {
  GET_BY_PRODUCT_TYPE_FAILURE = '@@demand-configurations/GET_BY_PRODUCT_TYPE_FAILURE',
  GET_BY_PRODUCT_TYPE_REQUEST = '@@demand-configurations/GET_BY_PRODUCT_TYPE_REQUEST',
  GET_BY_PRODUCT_TYPE_SUCCESS = '@@demand-configurations/GET_BY_PRODUCT_TYPE_SUCCESS',
}

export interface DemandConfigurationsState {
  [key: string]: PortalSubAccount[];
}
