import { createAction } from 'typesafe-actions';

import { InterfaceActionType } from './actionTypes';

export const toggleSidenav = createAction(
  InterfaceActionType.TOGGLE_SIDENAV
)<undefined>();

export const enterFullscreenMode = createAction(
  InterfaceActionType.ENTER_FULLSCREEN_MODE
)<undefined>();

export const exitFullscreenMode = createAction(
  InterfaceActionType.EXIT_FULLSCREEN_MODE
)<undefined>();
