import { createAsyncAction } from 'typesafe-actions';

import type { ExternalDemandEvent } from '@evenfinancial/finance-client';

import type { PortalError } from '@portal/common';

import type { IsomorphicRequestPayload } from '@/store/types';

import { ImportedLeadEventsActionType } from './types';

interface GetAllResponsePayload {
  response: ExternalDemandEvent[];
}

export const getImportedLeadEventsAll = createAsyncAction(
  ImportedLeadEventsActionType.GET_ALL_IMPORTED_LEAD_EVENTS_REQUEST,
  ImportedLeadEventsActionType.GET_ALL_IMPORTED_LEAD_EVENTS_SUCCESS,
  ImportedLeadEventsActionType.GET_ALL_IMPORTED_LEAD_EVENTS_FAILURE
)<IsomorphicRequestPayload, GetAllResponsePayload, PortalError>();
