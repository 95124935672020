import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import isNumber from 'lodash/isNumber';
import mapValues from 'lodash/mapValues';

import type { ProductType } from '@evenfinancial/finance-client';
import type { LoanPayoutSummary } from '@evenfinancial/supply-reporting-client';

import type { PayoutSummary } from '@portal/api-models';

import { productTypeKeyMap } from '@/resources/channels/performance-summary/product-type-mapper';

export interface AccountEarningsDatum extends LoanPayoutSummary {
  creditCardPayout?: number;
  lifeInsurancePayout?: number;
  loanPayout?: number;
  otherPayout?: number;
  savingsPayout?: number;
}

export interface ProductTypeAndEarnings {
  earnings: PayoutSummary[];
  productType: ProductType;
}

export function getCalculatedAccountEarningsSummaries(
  productTypeAndEarningsBySubAccount: ProductTypeAndEarnings[]
) {
  const prepared: AccountEarningsDatum[][] =
    productTypeAndEarningsBySubAccount.map(({ productType, earnings }) => {
      return earnings.map((earning) => ({
        ...earning,
        [productTypeKeyMap[productType]]: earning.totalPayout,
      }));
    });

  const merged = flatten(prepared);

  const grouped = groupBy(merged, 'groupKey');

  const summaries: AccountEarningsDatum[] = Object.values(grouped).map(
    (groupedEarnings) => {
      return groupedEarnings.reduce((accu, curr: any) => {
        return {
          ...accu,
          ...mapValues(curr, (value, key) => {
            return isNumber(value)
              ? ((accu[key as keyof AccountEarningsDatum] || 0) as number) +
                  value
              : value;
          }),
        };
      }, {} as AccountEarningsDatum);
    }
  );

  return summaries;
}
