"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreditBucket = void 0;
var CreditBucket;
(function (CreditBucket) {
    CreditBucket["Excellent"] = "Excellent";
    CreditBucket["Fair"] = "Fair";
    CreditBucket["Good"] = "Good";
    CreditBucket["Limited"] = "Limited";
    CreditBucket["Poor"] = "Poor";
    CreditBucket["Unknown"] = "Unknown";
})(CreditBucket = exports.CreditBucket || (exports.CreditBucket = {}));
