import { HYDRATE } from 'next-redux-wrapper';
import type { Reducer } from 'redux';

import type { HydrateAction } from '@/store/actions';
import { reconcileState } from '@/store/utils';

import { ExpectedEarningsShareActionType } from './types';
import type {
  ExpectedEarningsShareAction,
  ExpectedEarningsShareState,
} from './types';

export const initialState: ExpectedEarningsShareState = {
  bySubAccountUuid: {},
  rows: [],
};

export const reducer: Reducer<
  ExpectedEarningsShareState,
  ExpectedEarningsShareAction | HydrateAction
> = (
  state: ExpectedEarningsShareState = initialState,
  action: ExpectedEarningsShareAction | HydrateAction
) => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...reconcileState(action.payload.expectedEarningsShare, initialState),
      };
    }

    case ExpectedEarningsShareActionType.GET_EXPECTED_EARNINGS_SHARES_BY_SUB_ACCOUNT_ID_SUCCESS: {
      return {
        ...state,
        bySubAccountUuid: {
          [action.payload.uuid]: action.payload.response,
        },
      };
    }

    case ExpectedEarningsShareActionType.GET_EXPECTED_EARNINGS_SHARES_SUCCESS: {
      return {
        ...state,
        rows: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
