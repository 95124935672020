import * as React from 'react';

import Tag from 'antd/lib/tag';
import Tooltip from 'antd/lib/tooltip';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';
import Moment from 'moment';

import { PortalColumnRenderType } from '@/components/table/interface';
import type { PortalColumnProps } from '@/components/table/interface';
import { Link } from '@/routes';

import type {
  RenderBooleanOptions,
  RenderDateOptions,
  RenderLinkOptions,
} from './interface';

const NA_TEXT = '--';

const render = <ValueType,>(
  value: ValueType,
  format?: () => React.ReactNode
) => {
  if (value !== undefined && value !== null) {
    return format ? format() : value;
  }

  return NA_TEXT;
};

export const renderCurrency = (value: number) =>
  render(value, () =>
    new Intl.NumberFormat('en-US', {
      currency: 'USD',
      style: 'currency',
    }).format(value)
  );

export const renderPercent = (value: string) =>
  render(value, () => `${value}%`);

export const renderNumericRange = ([min, max]: (number | undefined)[]) =>
  render([min, max], () => {
    if (min && max) {
      return `${min} - ${max}`;
    }

    if (min && !max) {
      return `> ${min}`;
    }

    if (!min && max) {
      return `< ${max}`;
    }

    return NA_TEXT;
  });

export const renderLink =
  ({
    key,
    urlKey,
    urlSuffix,
    baseUrl,
    isExternalUrl,
    tooltip,
  }: RenderLinkOptions) =>
  (value: string, record: any) => {
    const text = value || record[key];
    const linkText = tooltip ? (
      <Tooltip {...tooltip}>
        <span>{text}</span>
      </Tooltip>
    ) : (
      text
    );

    if (!Object.prototype.hasOwnProperty.call(record, urlKey ?? key)) {
      return record[key] || NA_TEXT;
    }

    if (isExternalUrl) {
      return (
        <a
          href={`${baseUrl}/${record[urlKey ?? key]}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {linkText}
        </a>
      );
    }

    return (
      <Link
        route={`/${baseUrl}/${record[urlKey ?? key]}${
          urlSuffix ? urlSuffix : ''
        }`}
      >
        <a>{linkText}</a>
      </Link>
    );
  };

export const renderDate =
  ({ format }: RenderDateOptions) =>
  (date: string | Date | Date[]) =>
    render(date, () => {
      if (Array.isArray(date)) {
        const sDate = date?.[0];
        const eDate = date?.[1];

        const startDate = Moment.parseZone(
          new Date(sDate).toISOString()
        ).format(format);
        const endDate = Moment.parseZone(new Date(eDate).toISOString()).format(
          format
        );

        return `${startDate} - ${endDate}`;
      }

      // Splitting "." here as the Leads API returns a date with an added
      // decimal at the end. This results in Moment registering the date
      // as invalid.
      const parsed = typeof date === 'string' ? date.split('.')[0] : date;

      return Moment.parseZone(new Date(parsed).toISOString()).format(format);
    });

export const renderBoolean =
  ({ text }: RenderBooleanOptions) =>
  (truthy: boolean) =>
    render(truthy, () => {
      if (truthy === true) {
        return text?.true ?? 'True';
      }

      return text?.false ?? 'False';
    });

export const renderTags = (tags: string | string[]) =>
  render(tags, () => {
    const renderTag = (tag: string) => (
      <Tag key={tag}>{startCase(camelCase(tag))}</Tag>
    );

    if (Array.isArray(tags)) {
      return tags.map((tag) => renderTag(tag));
    }

    return renderTag(tags);
  });

/**
 * Return renderer based on PortalColumnRenderType.
 *
 * @template T
 *
 * @param {PortalColumnProps<T>} props
 * @return {((value: any, record: T, index: number) => React.ReactNode)}
 */
export const getRenderer = <T extends {}>(
  props: PortalColumnProps<T>
): ((value: any, record: T, index: number) => React.ReactNode) => {
  const { key, renderType, renderOptions, render } = props;

  if (render) {
    return render;
  }

  switch (renderType) {
    case PortalColumnRenderType.Date:
      return renderDate(renderOptions as RenderDateOptions);

    case PortalColumnRenderType.Link:
      return renderLink({ key: key as string, ...renderOptions });

    case PortalColumnRenderType.Tag:
      return renderTags;

    default:
      return (value) => value;
  }
};
