import type { ReactElement } from 'react';
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';

import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'antd/lib/button';
import classNames from 'classnames';
import clone from 'lodash/clone';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

import PortalFormError from '@/components/form/portal-form/components/form-error';
import { useForm } from '@/components/form/portal-form/lib/portal-form-context';
import type { FieldComponentProps } from '@/components/form/portal-form/types';
import usePrevious from '@/utils/hooks/use-previous';

import {
  getItemsFromValues,
  getValuesFromItems,
  repeatableErrorCounter,
  repeatableValidator,
  replaceItemValueByIndex,
} from './helpers';
import type { RepeatableItemData } from './interface';
import RepeatableItem from './repeatable-item';

export interface RepeatableProps<ItemType, ItemProps = { [x: string]: unknown }>
  extends FieldComponentProps<ItemType[]> {
  ItemComponent: React.ComponentType<FieldComponentProps<ItemType>>;
  addItemText?: string;
  defaultItemValue: ItemType;
  /* Additional props to be passed down to all item components. */
  itemProps?: ItemProps;
  itemTitle?: ((index: number) => string) | string;
  /** Maximum number of items allowed. */
  max?: number;
  /** Decide what the 'Add Item' button should do once the max is reached. */
  maxAction?: 'hide' | 'disable';
  /** Minimum number of items allowed. */
  min?: number;
}

const Repeatable = <
  ValueType extends any,
  ItemProps = { [x: string]: unknown }
>({
  ItemComponent,
  value,
  defaultValue,
  defaultItemValue,
  itemProps,
  onChange,
  addItemText = 'Add Item',
  itemTitle,
  min = 0,
  max,
  maxAction = 'disable',
}: RepeatableProps<ValueType, ItemProps>): ReactElement => {
  const defaultItems: RepeatableItemData<ValueType>[] =
    getItemsFromValues(defaultValue);

  const [items, setItems] = useState(defaultItems);
  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { onValidate, onErrorCount, removeOnValidate, removeOnErrorCount } =
    useForm();

  const getValidationMessage = () => repeatableValidator({ items, max, min });
  const getErrorCount = () => repeatableErrorCounter({ items, max, min });

  const validate = () => setError(getValidationMessage());

  const prevItems = usePrevious(items.length);

  useEffect(() => {
    onValidate(validate);
    onErrorCount(getErrorCount);

    return function () {
      removeOnValidate(validate);
      removeOnErrorCount(getErrorCount);
    };
  });

  useEffect(() => {
    if (prevItems !== undefined) {
      setError(getValidationMessage());
    }
  }, [items.length]);

  useEffect(() => {
    const prevValue = getValuesFromItems(items);

    if (Array.isArray(value) && !isEqual(value, prevValue)) {
      setItems(getItemsFromValues(value));
    }
  }, [value]);

  const updateItems = useCallback(
    (nextItems: RepeatableItemData<ValueType>[]) => {
      setItems(nextItems);
      onChange?.(nextItems.map((item) => item.value));
    },
    []
  );

  const updateItem = useCallback(
    debounce((nextValue: ValueType, index: number) => {
      setWaiting(false);
      updateItems(replaceItemValueByIndex(items, nextValue, index));
    }, 750),
    [items]
  );

  const handleChange = useCallback(
    (nextValue: ValueType, index: number) => {
      setWaiting(true);
      updateItem(nextValue, index);
    },
    [items]
  );

  const handleDelete = useCallback(
    (index: number) => {
      if (!waiting) {
        updateItems([...items.slice(0, index), ...items.slice(index + 1)]);
      }
    },
    [waiting, items]
  );

  const handleAddOne = useCallback(() => {
    if (!waiting) {
      const newItemValue =
        typeof defaultItemValue === 'object'
          ? clone(defaultItemValue)
          : defaultItemValue;

      updateItems(
        items.concat({
          id: String(items.length),
          value: newItemValue,
        })
      );
    }
  }, [waiting, items]);

  const isMaxReached = items.length === max;
  const isButtonHidden = maxAction === 'hide' && isMaxReached;
  const isButtonDisabled = maxAction === 'disable' && isMaxReached;

  return (
    <div className="repeatable">
      <PortalFormError className="repeatable-error" error={error} />
      {items.map((item, index) => (
        <RepeatableItem
          key={item.id}
          ItemComponent={ItemComponent}
          index={index}
          itemProps={itemProps}
          itemTitle={itemTitle}
          showDelete={items.length > min}
          value={item.value}
          onChange={handleChange}
          onDelete={handleDelete}
        />
      ))}
      <Button
        className={classNames('repeatable-add-one', {
          'is-disabled': isButtonDisabled,
          'is-hidden': isButtonHidden,
        })}
        type="dashed"
        onClick={handleAddOne}
      >
        <FontAwesomeIcon icon={faPlus} /> {addItemText}
      </Button>
    </div>
  );
};

export default Repeatable;
