"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalRoutes = exports.DemandMVPOfferManagementRoute = exports.Route = void 0;
var Route;
(function (Route) {
    Route["MciReports"] = "/mci-reports";
    Route["OfferManagement_PersonalLoans_MciConversionsReports"] = "/offer-management/personal-loans/mci-reports/conversions";
    Route["OfferManagement_PersonalLoans_MciOffersReports"] = "/offer-management/personal-loans/mci-reports/offers";
    Route["OfferManagement_PersonalLoans_MciRejectionsReports"] = "/offer-management/personal-loans/mci-reports/rejections";
})(Route = exports.Route || (exports.Route = {}));
var DemandMVPOfferManagementRoute;
(function (DemandMVPOfferManagementRoute) {
    DemandMVPOfferManagementRoute["AutoLoanRefi"] = "/offer-management/financial-services/auto-loan-refi";
    DemandMVPOfferManagementRoute["CreditCards"] = "/offer-management/financial-services/credit-cards";
    DemandMVPOfferManagementRoute["CustomOffers"] = "/offer-management/custom-offers";
    DemandMVPOfferManagementRoute["LifeInsurance"] = "/offer-management/financial-services/life-insurance";
    DemandMVPOfferManagementRoute["PersonalLoansBenchmarkReports"] = "/offer-management/financial-services/personal-loans/data-and-analytics/benchmark-reports";
    DemandMVPOfferManagementRoute["PersonalLoansCompliance"] = "/offer-management/financial-services/personal-loans/compliance";
    DemandMVPOfferManagementRoute["PersonalLoansDashboard"] = "/offer-management/financial-services/personal-loans/dashboard";
    DemandMVPOfferManagementRoute["PersonalLoansMCIConversionsReports"] = "/offer-management/financial-services/personal-loans/data-and-analytics/mci-reports/conversions";
    DemandMVPOfferManagementRoute["PersonalLoansMCIOffersReports"] = "/offer-management/financial-services/personal-loans/data-and-analytics/mci-reports/offers";
    DemandMVPOfferManagementRoute["PersonalLoansMCIRejectionsReports"] = "/offer-management/financial-services/personal-loans/data-and-analytics/mci-reports/rejections";
    DemandMVPOfferManagementRoute["Savings"] = "/offer-management/financial-services/savings";
    DemandMVPOfferManagementRoute["StudentLoanRefi"] = "/offer-management/financial-services/student-loan-refi";
})(DemandMVPOfferManagementRoute = exports.DemandMVPOfferManagementRoute || (exports.DemandMVPOfferManagementRoute = {}));
const RootRoutes = {
    DemandSubAccountDetail: '/demand-sub-accounts/[uuid]',
    DemandSubAccountDetailContractTerms: '/demand-sub-accounts/[uuid]/contract-terms',
    DemandSubAccountIndex: '/demand-sub-accounts',
    DemandSubAccounts: '/demand-sub-accounts',
    FeatureFlags: '/feature-flags',
};
const OfferCatalogRoutes = {
    Offers: '/offer-catalog/offers',
    OffersDetail: '/offers/[uuid]/[version]',
    ProductTypes: '/offer-catalog/product-types',
    ProductTypes_Details: '/offer-catalog/product-types/details',
};
exports.InternalRoutes = Object.assign(Object.assign({}, RootRoutes), { OfferCatalog: OfferCatalogRoutes });
