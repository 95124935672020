import { differenceWith } from 'lodash';
import { HYDRATE } from 'next-redux-wrapper';
import type { Reducer } from 'redux';

import { toPortionOf100 } from '@portal/common';

import type { HydrateAction } from '@/store/actions';
import { reconcileState } from '@/store/utils';

import { SupplyContractsActionType } from './types';
import type { SupplyContractsAction, SupplyContractsState } from './types';

export const initialState: SupplyContractsState = {
  bySubAccountUuid: {},
  rows: [],
  supplyContractCreateVisible: false,
};

export const reducer: Reducer<
  SupplyContractsState,
  SupplyContractsAction | HydrateAction
> = (
  state: SupplyContractsState = initialState,
  action: SupplyContractsAction | HydrateAction
) => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...reconcileState(action.payload.supplyContracts, initialState),
      };
    }

    case SupplyContractsActionType.GET_ALL_SUCCESS: {
      return {
        ...state,
        rows: action.payload.response,
      };
    }

    case SupplyContractsActionType.GET_SUPPLY_CONTRACTS_BY_SUBACCOUNT_UUID_SUCCESS: {
      return {
        ...state,
        bySubAccountUuid: {
          [action.payload.uuid]: action.payload.response,
        },
        rows: [
          ...differenceWith(
            state.rows,
            action.payload.response,
            (row, payloadRow) => row.id === payloadRow.id
          ),
          ...action.payload.response,
        ],
      };
    }

    case SupplyContractsActionType.SUPPLY_CONTRACT_TOGGLE_CREATE: {
      const newState = {
        ...state,
        supplyContractCreateVisible: !state.supplyContractCreateVisible,
      };

      if (state.supplyContractCreateVisible) {
        newState.duplicateSupplyContract = undefined;
      }

      return newState;
    }

    case SupplyContractsActionType.SUPPLY_CONTRACT_DUPLICATE: {
      const supplyProcessingFeeConfig = toPortionOf100(
        action.payload.supplyProcessingFeeConfig
      );

      return {
        ...state,
        duplicateSupplyContract: {
          ...action.payload,
          supplyProcessingFeeConfig,
        },
        supplyContractCreateVisible: true,
      };
    }

    default: {
      return state;
    }
  }
};
