import type { ReactNode } from 'react';
import * as React from 'react';

import type { ValueFormat } from '@nivo/core';
import { ResponsiveLine } from '@nivo/line';
import type { DatumValue, LineSvgProps, SliceTooltipProps } from '@nivo/line';
import merge from 'lodash/merge';

import ChartTooltip from '@/components/reporting/chart/tooltip';
import { colors, theme } from '@/resources/performance-summary/nivo-theme';

const lineTooltipFormat = (
  value: DatumValue,
  formatter: string | ValueFormat<DatumValue> | undefined
): DatumValue | ReactNode => {
  if (formatter && typeof formatter === 'string') {
    return formatter;
  }

  if (formatter && typeof formatter === 'function') {
    return formatter(value);
  }

  return value;
};

interface LineChartProps {
  lineProps: LineSvgProps;
}

const LineChart: React.FC<LineChartProps> = ({ lineProps }) => {
  const { axisLeft, tooltipFormat } = lineProps;

  const defaults = {
    animate: false,
    axisBottom: {
      tickPadding: 25,
      tickRotation: 0,
      tickSize: 10,
    },
    axisLeft: {
      legendOffset: -100,
      legendPosition: 'middle',
      tickSize: 10,
    },
    colors,
    crosshairType: 'x',
    enableGridX: false,
    enableSlices: 'x',
    margin: {
      bottom: 70,
      left: 120,
      right: 50,
      top: 10,
    },
    pointBorderColor: { from: 'serieColor' },
    pointBorderWidth: 4,
    pointColor: { theme: 'background' },
    pointLabel: 'y',
    pointLabelYOffset: -15,
    pointSize: 4,
    sliceTooltip: ({ slice }: SliceTooltipProps) => (
      <ChartTooltip
        label={slice.points[0].data.x}
        legend={axisLeft?.legend || ''}
        value={lineTooltipFormat(slice.points[0].data.y, tooltipFormat)}
      />
    ),
    theme,
    useMesh: true,
  };

  return (
    <div className="chart line-chart">
      <div className="chart-inner line-chart-inner">
        <ResponsiveLine {...merge(defaults, lineProps)} />
      </div>
    </div>
  );
};

export default LineChart;
