import { HYDRATE } from 'next-redux-wrapper';
import type { Reducer } from 'redux';

import type { HydrateAction } from '@/store/actions';
import { reconcileState } from '@/store/utils';

import { DemandDataImportActionType } from './demand/types';
import type { DemandDataImportAction } from './demand/types';
import type { DataImportsState } from './types';

export const initialState: DataImportsState = {
  demand: {
    summaries: [],
  },
};

export const reducer: Reducer<
  DataImportsState,
  DemandDataImportAction | HydrateAction
> = (
  state: DataImportsState = initialState,
  action: DemandDataImportAction | HydrateAction
) => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...reconcileState(action.payload.dataImports, initialState),
      };
    }

    case DemandDataImportActionType.GET_ALL_DEMAND_DATA_IMPORT_SUMMARIES_SUCCESS:
      return {
        ...state,
        demand: {
          ...state.demand,
          summaries: action.payload,
        },
      };

    default: {
      return state;
    }
  }
};
