import moment from 'moment';

export const DateFormat = 'MM/DD/YYYY';
export const DateLongFormat = 'MMM DD, YYYY';
export const DateTimeLongFormat = 'MMM DD, YYYY HH:ss A';
export const TimeFormat = 'HH:mm:ss';
export const EvenDateFormat = 'YYYY-MM-DD';
export const DateTimeFormat = `${DateFormat} ${TimeFormat}`;
export const EvenDateTimeFormat = `${EvenDateFormat} ${TimeFormat}`;

export function formatDate(value: number | string | Date, format?: string) {
  return moment(value).format(format || 'MM/DD/YYYY');
}

export function formatTime(value: number | string | Date) {
  return moment(value).format('HH:mm:ss');
}

export function formatDateAndTime(value: number | string | Date) {
  return `${formatDate(value)} ${formatTime(value)}`;
}

export function addMonth(
  value: number | string | Date,
  month: number | string
) {
  return moment(value).add(month, 'M').format('MM/DD/YYYY');
}

export const toDate = (date: number | string | Date): Date => {
  if (typeof date === 'string') {
    // Replacing dashes with slashes as dashes can cause Chrome to show date
    // as a day behind and Safari to show as "Invalid Date".
    return new Date(date.replace(/-/g, '/'));
  }

  if (typeof date === 'number') {
    return new Date(date);
  }

  return date;
};

export function dateRange(
  startDate: number | string | Date,
  endDate: number | string | Date
) {
  const sDate: Date = toDate(startDate);
  const eDate: Date = toDate(endDate);

  const isSameMonth = sDate.getMonth() === eDate.getMonth();
  const isSameYear = sDate.getFullYear() === eDate.getFullYear();

  const startDateFormat = `MMM DD${!isSameYear ? ', YYYY ' : ''}`;
  const endDateFormat = `${!isSameMonth || !isSameYear ? 'MMM ' : ''}DD, YYYY`;

  return `${moment(sDate).format(startDateFormat)} - ${moment(eDate).format(
    endDateFormat
  )}`;
}
