"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteActivePartnerFeatureMutation = exports.useCreateActivePartnerFeatureMutation = exports.useGetActivePartnerFeaturesForSubAccountQuery = exports.deleteActivePartnerFeature = exports.createActivePartnerFeature = exports.getActivePartnerFeaturesForSubAccount = void 0;
var react_query_1 = require("react-query");
var client_1 = require("../client");
var types_1 = require("../types");
var getActivePartnerFeaturesForSubAccount = function (subAccountUuid) {
    return client_1.client
        .get("/partner-data/active-partner-features/".concat(subAccountUuid))
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.getActivePartnerFeaturesForSubAccount = getActivePartnerFeaturesForSubAccount;
var createActivePartnerFeature = function (_a) {
    var feature = _a.feature, subAccountUuid = _a.subAccountUuid;
    return client_1.client.post("/partner-data/active-partner-features/".concat(subAccountUuid), {
        feature: feature,
    });
};
exports.createActivePartnerFeature = createActivePartnerFeature;
var deleteActivePartnerFeature = function (featureId) {
    return client_1.client.delete("/partner-data/active-partner-features/".concat(featureId));
};
exports.deleteActivePartnerFeature = deleteActivePartnerFeature;
var useGetActivePartnerFeaturesForSubAccountQuery = function (subAccountUuid) {
    return (0, react_query_1.useQuery)([types_1.QueryKey.ActivePartnerFeatures, subAccountUuid], function () {
        return (0, exports.getActivePartnerFeaturesForSubAccount)(subAccountUuid);
    });
};
exports.useGetActivePartnerFeaturesForSubAccountQuery = useGetActivePartnerFeaturesForSubAccountQuery;
var useCreateActivePartnerFeatureMutation = function () {
    return (0, react_query_1.useMutation)(exports.createActivePartnerFeature);
};
exports.useCreateActivePartnerFeatureMutation = useCreateActivePartnerFeatureMutation;
var useDeleteActivePartnerFeatureMutation = function () {
    return (0, react_query_1.useMutation)(exports.deleteActivePartnerFeature);
};
exports.useDeleteActivePartnerFeatureMutation = useDeleteActivePartnerFeatureMutation;
