import { HYDRATE } from 'next-redux-wrapper';
import { deprecated } from 'typesafe-actions';
import type { ActionType } from 'typesafe-actions';

import type { ApplicationState } from '@/store';

export const RESET_STORE = 'RESET_STORE';

const { createStandardAction } = deprecated;

export const resetStore = createStandardAction(RESET_STORE)<ApplicationState>();
export const nextHydrate = createStandardAction(HYDRATE)<ApplicationState>();

export type HydrateAction = ActionType<typeof nextHydrate>;

export interface ResetStoreAction {
  type: 'RESET_STORE';
}

export type GlobalAction = HydrateAction | ResetStoreAction;
