import { createSelector } from 'reselect';

import type { SupplyTemplateProductType } from '@evenfinancial/originator-client';

import { getSupplyTemplateProductTypeFromPartnerPage } from '@portal/common';

import type { ApplicationState } from '@/store';
import { selectSupplyTemplates } from '@/store/originator/selectors';

const selectProductType = (
  _: ApplicationState,
  productType: SupplyTemplateProductType
) => productType;

/**
 * Returns the supply templates based on the passed product type and checks if the product type is personalLoan
 * @param {ApplicationState} state
 * @param {SupplyTemplateProductType[]} productType
 * @return SupplyTemplate[]
 */
export const selectTemplatesDetails = createSelector(
  [selectProductType, selectSupplyTemplates],
  (productType, supplyTemplates) => {
    return supplyTemplates.filter((template) => {
      return productType === template.productType;
    });
  }
);

/**
 * Returns the supply template product type based on the passed id
 * @param {ApplicationState} state
 * @param {string | undefined} id
 * @return SupplyTemplateProductType | undefined
 */
export const selectSupplyTemplateProductType = (
  { partnerPages }: ApplicationState,
  id: string = ''
): SupplyTemplateProductType | undefined => {
  const partnerPageFull = partnerPages.byPartnerKeyId?.[id];

  return partnerPageFull
    ? getSupplyTemplateProductTypeFromPartnerPage(partnerPageFull)
    : undefined;
};

export const isInMigration = (
  { partnerPages, supplyMigration }: ApplicationState,
  id?: string
) => {
  const partnerPage = partnerPages.rows.find(
    (partnerPage) => partnerPage.id === id
  );

  return (
    supplyMigration?.settings?.integrationKeysToMigrate?.includes(
      partnerPage?.partner_key || ''
    ) || false
  );
};

export const selectSupplySubAccountUuid = (
  { partnerPages, subAccounts }: ApplicationState,
  id?: string
) => {
  const partnerPage = partnerPages.rows.find(
    (partnerPage) => partnerPage.id === id
  );

  const supplySubAccount = subAccounts.supply.find(
    (supplySubAccount) =>
      supplySubAccount.companyUuid === partnerPage?.companyUuid
  );

  return { subaccount_uuid: supplySubAccount?.uuid };
};
