"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getScopePermissions = void 0;
const api_models_1 = require("@portal/api-models");
const accounts_create_1 = require("../permissions/scope-config/accounts-create");
const demand_contracts_create_1 = require("../permissions/scope-config/demand-contracts-create");
const feature_flags_create_1 = require("../permissions/scope-config/feature-flags-create");
const ledger_items_create_1 = require("../permissions/scope-config/ledger-items-create");
const partner_pages_create_1 = require("../permissions/scope-config/partner-pages-create");
const sub_accounts_create_1 = require("../permissions/scope-config/sub-accounts-create");
const supply_contracts_create_1 = require("../permissions/scope-config/supply-contracts-create");
const users_create_1 = require("../permissions/scope-config/users-create");
const users_read_1 = require("../permissions/scope-config/users-read");
const whitelist_create_1 = require("../permissions/scope-config/whitelist-create");
const getScopePermissions = (scopes) => {
    let config = {};
    if (scopes.has(api_models_1.Scopes.DemandContractsCreate)) {
        config = Object.assign(Object.assign({}, config), demand_contracts_create_1.demandContractsCreateConfig);
    }
    if (scopes.has(api_models_1.Scopes.FeatureFlagsCreate)) {
        config = Object.assign(Object.assign({}, config), feature_flags_create_1.featureFlagsCreateConfig);
    }
    if (scopes.has(api_models_1.Scopes.LedgerItemsCreate)) {
        config = Object.assign(Object.assign({}, config), ledger_items_create_1.ledgerItemsCreateConfig);
    }
    if (scopes.has(api_models_1.Scopes.SubAccountsCreate)) {
        config = Object.assign(Object.assign({}, config), sub_accounts_create_1.subAccountsCreateConfig);
    }
    if (scopes.has(api_models_1.Scopes.SupplyContractsCreate)) {
        config = Object.assign(Object.assign({}, config), supply_contracts_create_1.supplyContractsCreateConfig);
    }
    if (scopes.has(api_models_1.Scopes.UsersCreate)) {
        config = Object.assign(Object.assign({}, config), users_create_1.usersCreateConfig);
    }
    if (scopes.has(api_models_1.Scopes.WhitelistCreate)) {
        config = Object.assign(Object.assign({}, config), whitelist_create_1.whitelistCreateConfig);
    }
    if (scopes.has(api_models_1.Scopes.UsersRead)) {
        config = Object.assign(Object.assign({}, config), users_read_1.usersReadConfig);
    }
    if (scopes.has(api_models_1.Scopes.PartnerPagesCreate)) {
        config = Object.assign(Object.assign({}, config), partner_pages_create_1.partnerPagesCreateConfig);
    }
    if (scopes.has(api_models_1.Scopes.AccountsCreate)) {
        config = Object.assign(Object.assign({}, config), accounts_create_1.accountsCreateConfig);
    }
    return config;
};
exports.getScopePermissions = getScopePermissions;
