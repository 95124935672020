"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayableActionType = void 0;
var PayableActionType;
(function (PayableActionType) {
    PayableActionType["ADD_ADJUSTMENT_FAILURE"] = "@@payable/ADD_ADJUSTMENT_FAILURE";
    PayableActionType["ADD_ADJUSTMENT_REQUEST"] = "@@payable/ADD_ADJUSTMENT_REQUEST";
    PayableActionType["ADD_ADJUSTMENT_SUCCESS"] = "@@payable/ADD_ADJUSTMENT_SUCCESS";
    PayableActionType["CREATE_ONE_OFF_LEDGER_ITEM_FAILURE"] = "@@payable/CREATE_ONE_OFF_LEDGER_ITEM_FAILURE";
    PayableActionType["CREATE_ONE_OFF_LEDGER_ITEM_REQUEST"] = "@@payable/CREATE_ONE_OFF_LEDGER_ITEM_REQUEST";
    PayableActionType["CREATE_ONE_OFF_LEDGER_ITEM_SUCCESS"] = "@@payable/CREATE_ONE_OFF_LEDGER_ITEM_SUCCESS";
    PayableActionType["DELETE_ADJUSTMENT_FAILURE"] = "@@payable/DELETE_ADJUSTMENT_FAILURE";
    PayableActionType["DELETE_ADJUSTMENT_REQUEST"] = "@@payable/DELETE_ADJUSTMENT_REQUEST";
    PayableActionType["DELETE_ADJUSTMENT_SUCCESS"] = "@@payable/DELETE_ADJUSTMENT_SUCCESS";
    PayableActionType["DELETE_PAYABLE_FAILURE"] = "@@payable/DELETE_PAYABLE_FAILURE";
    PayableActionType["DELETE_PAYABLE_REQUEST"] = "@@payable/DELETE_PAYABLE_REQUEST";
    PayableActionType["DELETE_PAYABLE_SUCCESS"] = "@@payable/DELETE_PAYABLE_SUCCESS";
    PayableActionType["GET_ADJUSTMENTS_BY_LEDGER_ITEM_ID_FAILURE"] = "@@payable/GET_ADJUSTMENTS_BY_LEDGER_ITEM_ID_FAILURE";
    PayableActionType["GET_ADJUSTMENTS_BY_LEDGER_ITEM_ID_REQUEST"] = "@@payable/GET_ADJUSTMENTS_BY_LEDGER_ITEM_ID_REQUEST";
    PayableActionType["GET_ADJUSTMENTS_BY_LEDGER_ITEM_ID_SUCCESS"] = "@@payable/GET_ADJUSTMENTS_BY_LEDGER_ITEM_ID_SUCCESS";
    PayableActionType["GET_ALL_ADJUSTMENTS_FAILURE"] = "@@payable/GET_ALL_ADJUSTMENTS_FAILURE";
    PayableActionType["GET_ALL_ADJUSTMENTS_REQUEST"] = "@@payable/GET_ALL_ADJUSTMENTS_REQUEST";
    PayableActionType["GET_ALL_ADJUSTMENTS_SUCCESS"] = "@@payable/GET_ALL_ADJUSTMENTS_SUCCESS";
    PayableActionType["GET_ALL_FAILURE"] = "@@payable/GET_ALL_FAILURE";
    PayableActionType["GET_ALL_REQUEST"] = "@@payable/GET_ALL_REQUEST";
    PayableActionType["GET_ALL_SUCCESS"] = "@@payable/GET_ALL_SUCCESS";
    PayableActionType["GET_BY_ID_FAILURE"] = "@@payable/GET_BY_ID_FAILURE";
    PayableActionType["GET_BY_ID_REQUEST"] = "@@payable/GET_BY_ID_REQUEST";
    PayableActionType["GET_BY_ID_SUCCESS"] = "@@payable/GET_BY_ID_SUCCESS";
    PayableActionType["GET_DETAIL_PAGE"] = "@@payable/GET_DETAIL_PAGE";
    PayableActionType["GET_EVENTS_BY_ID_FAILURE"] = "@@payable/GET_EVENTS_BY_ID_FAILURE";
    PayableActionType["GET_EVENTS_BY_ID_REQUEST"] = "@@payable/GET_EVENTS_BY_ID_REQUEST";
    PayableActionType["GET_EVENTS_BY_ID_SUCCESS"] = "@@payable/GET_EVENTS_BY_ID_SUCCESS";
    PayableActionType["IGNORE_PAYABLE_FAILURE"] = "@@payable/IGNORE_PAYABLE_FAILURE";
    PayableActionType["IGNORE_PAYABLE_REQUEST"] = "@@payable/IGNORE_PAYABLE_REQUEST";
    PayableActionType["IGNORE_PAYABLE_SUCCESS"] = "@@payable/IGNORE_PAYABLE_SUCCESS";
    PayableActionType["REGENERATE_LEDGER_ITEMS_FAILURE"] = "@@payable/REGENERATE_LEDGER_ITEMS_FAILURE";
    PayableActionType["REGENERATE_LEDGER_ITEMS_REQUEST"] = "@@payable/REGENERATE_LEDGER_ITEMS_REQUEST";
    PayableActionType["REGENERATE_LEDGER_ITEMS_SUCCESS"] = "@@payable/REGENERATE_LEDGER_ITEMS_SUCCESS";
    PayableActionType["SETTLE_LEDGER_ITEMS_FAILURE"] = "@@payable/SETTLE_LEDGER_ITEMS_FAILURE";
    PayableActionType["SETTLE_LEDGER_ITEMS_REQUEST"] = "@@payable/SETTLE_LEDGER_ITEMS_REQUEST";
    PayableActionType["SETTLE_LEDGER_ITEMS_SUCCESS"] = "@@payable/SETTLE_LEDGER_ITEMS_SUCCESS";
    PayableActionType["TOGGLE_ADD_ADJUSTMENTS_FORM"] = "@@payable/TOGGLE_ADD_ADJUSTMENTS_FORM";
    PayableActionType["TOGGLE_CREATE_ONE_OFF_LEDGER_ITEM"] = "@@payable/TOGGLE_CREATE_ONE_OFF_LEDGER_ITEM";
    PayableActionType["UPDATE_ADD_ADJUSTMENTS_FORM"] = "@@payable/UPDATE_ADD_ADJUSTMENTS_FORM";
})(PayableActionType = exports.PayableActionType || (exports.PayableActionType = {}));
