import type { ActionType } from 'typesafe-actions';

import type { SubAccountExternalDemandEvent } from '@evenfinancial/finance-client';

import type * as actions from './actions';

export type ImportedChannelEventsAction = ActionType<typeof actions>;
export enum ImportedChannelEventsActionType {
  GET_ALL_IMPORTED_CHANNEL_EVENTS_FAILURE = '@@account/GET_ALL_IMPORTED_CHANNEL_EVENTS_FAILURE',
  GET_ALL_IMPORTED_CHANNEL_EVENTS_REQUEST = '@@account/GET_ALL_IMPORTED_CHANNEL_EVENTS_REQUEST',
  GET_ALL_IMPORTED_CHANNEL_EVENTS_SUCCESS = '@@account/GET_ALL_IMPORTED_CHANNEL_EVENTS_SUCCESS',
}

export interface ImportedChannelEventsState {
  readonly haveAllChannelEventsBeenRequested: boolean;
  rows: SubAccountExternalDemandEvent[];
}
