import type { PortalSaasMigrationSetting } from '@portal/api-models';

import type { ApplicationState } from '@/store';

export const selectSaasMigrationSettingsByFinanceAccountId = (
  state: ApplicationState,
  financeAccountId: number
): PortalSaasMigrationSetting | undefined =>
  state.supplyMigration.byFinanceAccountId?.[financeAccountId];

export const selectSaasMigrationSettings = (state: ApplicationState) =>
  state.supplyMigration.settings;
