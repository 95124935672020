import React from 'react';

import { Divider, Select } from 'antd';

import {
  ConfigProductType,
  CreditCardPurposesOptions,
} from '@evenfinancial/even-ts-static';

import { EnumDropdown } from '@/components/form/option-dropdown';

import { CreditCardFlows } from './constants';

import { productIsTypePredicate } from '.';

const { Option } = Select;

export const creditCardFields = [
  {
    fieldDecoratorProps: {
      initialValue: CreditCardFlows.StepByStepPreselect,
    },
    fieldElement: (
      <Select>
        <Option value={CreditCardFlows.Redirect}>Redirect</Option>
        <Option value={CreditCardFlows.Remarketing}>Remarketing</Option>
        <Option value={CreditCardFlows.SinglePagePreSelect}>
          Single Page Pre-Select
        </Option>
        <Option value={CreditCardFlows.StepByStepPreselect}>
          Step-By-Step Pre-Select
        </Option>
      </Select>
    ),
    itemProps: {
      label: 'Credit Card Flow',
    },
    name: 'credit_card_flow',
    predicate: productIsTypePredicate(ConfigProductType.CreditCard),
  },
  {
    before: () => (
      <Divider className="legacy-portal-form-item-horizontal-divider" />
    ),
    fieldDecoratorProps: {
      rules: [],
    },
    fieldElement: (
      <Select
        getPopupContainer={(triggerNode) =>
          triggerNode.parentNode as HTMLElement
        }
      >
        {EnumDropdown(CreditCardPurposesOptions)}
      </Select>
    ),
    itemProps: {
      label: 'Default Card Purpose',
    },
    name: 'app.cardPurposes',
    order: 99,
    predicate: productIsTypePredicate(ConfigProductType.CreditCard),
  },
];

export const mapCreditCardFlowFromForm = (config: any) => {
  const mappedConfig = { ...config };

  if (mappedConfig.credit_card_application_type) {
    delete mappedConfig.credit_card_application_type;
  }

  if (config.credit_card_flow === CreditCardFlows.SinglePagePreSelect) {
    mappedConfig.credit_card_flow = 'pre-select';
    mappedConfig.credit_card_application_type = 'single_step';
  } else if (config.credit_card_flow === CreditCardFlows.StepByStepPreselect) {
    mappedConfig.credit_card_flow = 'pre-select';
    mappedConfig.credit_card_application_type = 'step_by_step';
  }

  return mappedConfig;
};

export const mapCreditCardFlowToForm = (config: any) => {
  const mappedConfig = { ...config };

  if (config.credit_card_flow === 'pre-select') {
    mappedConfig.credit_card_flow =
      mappedConfig.credit_card_application_type === 'step_by_step'
        ? CreditCardFlows.StepByStepPreselect
        : CreditCardFlows.SinglePagePreSelect;
  }

  if (mappedConfig.credit_card_application_type) {
    delete mappedConfig.credit_card_application_type;
  }

  return mappedConfig;
};
