import { toNumber } from 'lodash';
import router from 'next/router';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import type { ActionType } from 'typesafe-actions';

import { getDemandContracts } from '@portal/api-client';
import type { PortalDemandContract } from '@portal/api-models';
import type { PortalError } from '@portal/common';
import { getPortalError } from '@portal/common';
import { accountRequestAction } from '@portal/store/dist/account/actions';
import { userRequestAction } from '@portal/store/dist/user';

import type { ApplicationState } from '@/store';
import { selectDemandContractById } from '@/store/demand-contracts/selectors';
import {
  demandSubAccountRequestAction,
  supplySubAccountRequestAction,
} from '@/store/subaccount/actions';

import * as actions from './actions';

export function* getAllDemandContracts() {
  try {
    const demandContracts: PortalDemandContract[] = yield call(
      getDemandContracts
    );

    yield put(actions.getAllDemandContracts.success(demandContracts));
  } catch (err: any) {
    const portalError: PortalError = yield call(getPortalError, err);

    yield put(actions.getAllDemandContracts.failure(portalError));
  }
}

export function* getDemandContractById(
  action: ActionType<typeof actions.getDemandContractById.request>
) {
  const { id } = action.payload;

  try {
    // set loader to TRUE
    yield put(actions.demandContractLoadingByIdAction(true));

    const demandContracts: PortalDemandContract[] = yield call(
      getDemandContracts,
      { id: toNumber(id) }
    );

    if (demandContracts.length) {
      yield put(actions.getDemandContractById.success(demandContracts));
    } else {
      yield put(
        actions.getDemandContractById.failure({
          customError: 'Failed to find such contract!',
          message: 'Failed to find such contract',
          name: 'Not Found',
        })
      );
      yield call([router, 'replace'], '/404');
    }
  } catch (err: any) {
    const portalError: PortalError = yield call(getPortalError, err);

    yield put(actions.getDemandContractById.failure(portalError));
  } finally {
    // set loader to FALSE
    yield put(actions.demandContractLoadingByIdAction(false));
  }
}

export function* initDemandContractDetailsPage(
  action: ActionType<typeof actions.initDemandContractDetailsPage>
) {
  const { id } = action.payload;

  const contract: PortalDemandContract | null = yield select(
    (state: ApplicationState) => selectDemandContractById(state, id)
  );

  if (!contract && typeof id === 'string') {
    yield put(actions.getDemandContractById.request({ id }));
  }

  yield all([
    put(supplySubAccountRequestAction.request({})),
    put(demandSubAccountRequestAction.request({})),
    put(accountRequestAction.request({})),
    put(userRequestAction.request({})),
  ]);
}

function* watchGetAllDemandContracts() {
  yield takeLatest(
    actions.getAllDemandContracts.request,
    getAllDemandContracts
  );
}

function* watchGetDemandContractById() {
  yield takeLatest(
    actions.getDemandContractById.request,
    getDemandContractById
  );
}

export function* demandContractsSaga() {
  yield takeLatest(
    actions.initDemandContractDetailsPage,
    initDemandContractDetailsPage
  );

  yield all([
    fork(watchGetAllDemandContracts),
    fork(watchGetDemandContractById),
  ]);
}
