import type { ColumnProps } from 'antd/lib/table';

import type { OpenActionModal } from '@/components/self-service/home/integrations-card';
import { formatButton } from '@/components/self-service/home/integrations-table-action-button';
import { createTagColumn } from '@/components/table/columns';
import type {
  ColumnCreatorProps,
  ColumnType,
} from '@/components/table/columns';
import type { IntegrationRow } from '@/resources/integrations/types';
import type { IntegrationCategory } from '@/resources/sub-accounts-shared/utils';

type ButtonColumnProp = {
  callback: OpenActionModal;
  planPurchased: boolean;
  showIntegrationActions: boolean;
} & ColumnProps<IntegrationRow>;

export const createButtonColumn = ({
  planPurchased,
  callback,
  title = '',
  showIntegrationActions,
}: ButtonColumnProp) => {
  if (!showIntegrationActions) {
    return {};
  }

  return planPurchased
    ? {
        render: (
          _: any,
          {
            id,
            partner_key: key,
            integrationType,
            implementationFeePaymentStatus,
            onboardingStatus,
            subAccountUuid,
          }: IntegrationRow
        ) => {
          return (
            onboardingStatus &&
            formatButton(
              onboardingStatus,
              integrationType as IntegrationCategory,
              implementationFeePaymentStatus,
              id,
              key,
              subAccountUuid!,
              callback
            )
          );
        },
        title,
      }
    : {};
};

interface CreateIntegrationTypeColumnProps
  extends ColumnCreatorProps<IntegrationRow, ColumnType.Dropdown> {
  embedExists: boolean;
}

export const createIntegrationTypeColumn = ({
  embedExists,
  ...props
}: CreateIntegrationTypeColumnProps) => {
  return embedExists
    ? createTagColumn({
        ...props,
      })
    : {};
};
