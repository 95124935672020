"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNullDateTuple = void 0;
const isNullDateTuple = (date) => {
    if ((date === null || date === void 0 ? void 0 : date.length) === 2 && date[0] === null && date[1] === null) {
        return true;
    }
    return false;
};
exports.isNullDateTuple = isNullDateTuple;
