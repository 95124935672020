"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.accountReducer = exports.initialAccountState = void 0;
var immer_1 = require("immer");
var typesafe_actions_1 = require("typesafe-actions");
var actions_1 = require("../actions");
var hydrate_1 = require("../hydrate");
var utils_1 = require("../utils");
var actions_2 = require("./actions");
exports.initialAccountState = {
    byUuid: {},
    createDrawerVisible: false,
    deactivationRows: [],
    duplicateUuid: '',
    haveAllAccountsBeenRequested: false,
    haveAllAccountsDeactivationBeenRequested: false,
    rows: [],
    rowsSelfService: [],
};
exports.accountReducer = (0, typesafe_actions_1.createReducer)(exports.initialAccountState)
    .handleAction(actions_1.resetStore, function (state) {
    return (0, immer_1.produce)(state, function (draftState) {
        draftState = exports.initialAccountState;
    });
})
    .handleAction(hydrate_1.nextHydrate, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), (0, utils_1.reconcileState)(payload.accounts, exports.initialAccountState)));
})
    .handleAction(actions_2.accountRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.haveAllAccountsBeenRequested = true;
        draftState.rows = payload;
    });
})
    .handleAction(actions_2.accountByUuidAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.byUuid[payload.uuid] = payload;
        draftState.rows = (0, utils_1.replaceById)(state.rows, payload);
    });
})
    .handleAction(actions_2.accountCreateRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.byUuid[payload.uuid] = payload;
        draftState.rows = (0, utils_1.replaceById)(state.rows, payload);
    });
})
    .handleAction(actions_2.toggleDuplicateAccount, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.createDrawerVisible = !state.createDrawerVisible;
        draftState.duplicateUuid = payload;
    });
})
    .handleAction(actions_2.toggleCreateAccount, function (state) {
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.createDrawerVisible = !state.createDrawerVisible;
        draftState.duplicateUuid = '';
    });
})
    .handleAction(actions_2.accountUpdateRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.rows = (0, utils_1.replaceById)(state.rows, payload);
    });
})
    .handleAction(actions_2.createAccountDeactivationsAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.deactivationRows = state.deactivationRows.concat(payload);
    });
})
    .handleAction(actions_2.accountDeactivationRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.haveAllAccountsDeactivationBeenRequested = true;
        draftState.deactivationRows = payload;
    });
})
    .handleAction(actions_2.deleteAccountDeactivationAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.deactivationRows = (0, utils_1.replaceByIdFromArray)(state.deactivationRows, payload);
    });
})
    .handleAction(actions_2.accountsSelfServiceRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.rowsSelfService = payload;
    });
})
    .handleAction(actions_2.accountUpdatePayoutSettingsRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.byUuid[payload.uuid] = payload;
        draftState.rows = (0, utils_1.replaceById)(state.rows, payload);
    });
});
