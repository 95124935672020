"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOtherSupplyContracts = exports.getActiveSupplyContract = void 0;
const luxon_1 = require("luxon");
const utils_1 = require("../../utils");
const filterInvalid = ({ effectiveAt, deletedAt }) => {
    if (!effectiveAt || deletedAt) {
        return false;
    }
    return true;
};
const sortByEffectiveAt = (a, b) => (0, utils_1.sortISODate)(a.effectiveAt, b.effectiveAt);
const getActiveSupplyContract = (supplyContracts) => {
    return supplyContracts
        .filter(filterInvalid)
        .filter(({ effectiveAt }) => luxon_1.DateTime.fromISO(effectiveAt) <= luxon_1.DateTime.now())
        .sort(sortByEffectiveAt)
        .shift();
};
exports.getActiveSupplyContract = getActiveSupplyContract;
const getOtherSupplyContracts = (supplyContracts) => {
    const activeContract = (0, exports.getActiveSupplyContract)(supplyContracts);
    return supplyContracts
        .filter(filterInvalid)
        .filter(({ id }) => id !== (activeContract === null || activeContract === void 0 ? void 0 : activeContract.id))
        .sort(sortByEffectiveAt);
};
exports.getOtherSupplyContracts = getOtherSupplyContracts;
