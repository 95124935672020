"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormatPortalLog = void 0;
const message_parser_1 = require("./message-parser");
const types_1 = require("./types");
class FormatPortalLog {
    constructor(options) {
        this.transform = (info) => {
            const { context, level, message, error } = info;
            const { format = types_1.FormatType.String, ddEnv, ddService, ddTraceEnabled = false, ddVersion, } = this.options;
            info.timestamp = new Date().getTime();
            const parsed = (0, message_parser_1.messageParser)(message);
            if (ddTraceEnabled) {
                info.env = ddEnv;
                info.service = ddService;
                info.version = ddVersion;
                info.message = `[${context}][${level}] ${parsed}`;
                info.error = error;
                info.stack = error === null || error === void 0 ? void 0 : error.stack;
            }
            if (format === types_1.FormatType.JSON || ddTraceEnabled) {
                return JSON.stringify(info);
            }
            let formatted = `[Time: ${info.timestamp}]`;
            if (context) {
                formatted += `[${context}]`;
            }
            if (error) {
                return `${formatted}[${level}] : ${parsed} ${JSON.stringify(error, undefined, 2)}`;
            }
            return `${formatted}[${level}] : ${parsed}`;
        };
        this.options = options;
    }
}
exports.FormatPortalLog = FormatPortalLog;
