import type { AxiosResponse } from 'axios';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import {
  all,
  call,
  delay,
  put,
  putResolve,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import type { ActionType } from 'typesafe-actions';

import type {
  RecurlyToken,
  SaasFee,
  SaasTrial,
} from '@evenfinancial/finance-client';

import { client } from '@portal/api-client';
import type { SaasSubscriptionFull } from '@portal/api-models';
import { ErrorType } from '@portal/api-models';
import { getPortalError } from '@portal/common';
import { Toaster } from '@portal/ui-lib';

import { NewFeatureStatic } from '@/components/new-feature/helpers';
import { IntegrationDataSelector } from '@/resources/integrations/selectors';
import { saasDataSelector } from '@/resources/saas/selectors';
import { selectSaasSubscriptionPreview } from '@/resources/saas/selects';
import { findMatchingSaasPlan } from '@/resources/self-service/payment/util-methods';
import { NewMigrationFeature } from '@/resources/supply-migration/helpers';
import { myAccountComplianceRequestAction } from '@/store/compliance/actions';
import { submitForReviewInternal } from '@/store/compliance/sagas';
import { partnerKeyRequestAction } from '@/store/partner-page/actions';
import type { SaasData, SaasState } from '@/store/saas/types';
import { assembleSubscriptionPaymentRequestBody } from '@/store/saas/utils';
import { supplySubAccountRequestAction } from '@/store/subaccount/actions';
import { getSettingsByAuthContextRequestAction } from '@/store/supply-migration/actions';

import type { ApplicationState } from '..';
import { Router } from '../../routes';

import * as actions from './actions';
import type {
  CreateRecurlyTokenRequestPayload,
  CreateSaasSubscriptionRequestPayload,
} from './actions';

export function* initPurchasePlanPage() {
  try {
    const haveAllSupplySubAccountsBeenRequested: boolean = yield select(
      (state: ApplicationState) =>
        state.subAccounts.haveAllSupplySubAccountsBeenRequested
    );

    if (!haveAllSupplySubAccountsBeenRequested) {
      yield put(supplySubAccountRequestAction.request({}));
    }

    yield all([
      put(partnerKeyRequestAction.request({ getAllByKey: true })),
      put(myAccountComplianceRequestAction.request({})),
      put(actions.saasAllRequestAction.request({ skipTrial: true })),
    ]);
    yield put(actions.initPurchasePlanPage.success());
  } catch (err: any) {
    yield put(actions.initPurchasePlanPage.failure(err));
  }
}

export function* initIntegrationFeePage() {
  try {
    const haveAllSupplySubAccountsBeenRequested: boolean = yield select(
      (state: ApplicationState) =>
        state.subAccounts.haveAllSupplySubAccountsBeenRequested
    );

    if (!haveAllSupplySubAccountsBeenRequested) {
      yield put(supplySubAccountRequestAction.request({}));
    }

    yield all([
      put(partnerKeyRequestAction.request({ getAllByKey: true })),
      put(myAccountComplianceRequestAction.request({})),
      put(actions.saasAllRequestAction.request({ skipTrial: true })),
      put(getSettingsByAuthContextRequestAction.request({})),
    ]);
    yield put(actions.initIntegrationFeePage.success());
  } catch (err: any) {
    yield put(actions.initIntegrationFeePage.failure(err));
  }
}

export function* getUserTrial() {
  try {
    const { data: userTrial } = yield call(
      client.get,
      '/finance/saas/userTrial'
    );

    yield put(actions.saasUserTrialRequestAction.success(userTrial));
  } catch (err: any) {
    yield put(actions.saasUserTrialRequestAction.failure(err));
  }
}

export function* checkAndCreateTrial() {
  NewFeatureStatic.add(
    [
      NewMigrationFeature.HomepageTutorial,
      NewMigrationFeature.UsersTutorial,
      NewMigrationFeature.IntegrationsTutorial,
      NewMigrationFeature.PerformanceSummaryTutorial,
    ],
    {
      viewsUntilExpires: 1,
    }
  );

  try {
    const { data: newTrial }: AxiosResponse<SaasTrial> = yield call(
      [client, 'post'],
      '/finance/saas/start_trial'
    );

    yield put(actions.createSaasTrialRequestAction.success(newTrial));

    // Shallow set to true to prevent page reloading and resetting showGettingStartedModal prop
    // in reducer for this action
    Router.pushRoute('/home', undefined, { shallow: true });
  } catch (err: any) {
    yield put(actions.createSaasTrialRequestAction.failure(err));
  }
}

export function* customOffersContactUs(
  action: ActionType<
    typeof actions.saasCustomOfferContactUsRequestAction.request
  >
) {
  try {
    yield call(
      client.post,
      '/finance/saas/custom-offer',
      omit(action.payload, 'cookie')
    );

    yield put(actions.saasCustomOfferContactUsRequestAction.success());
    yield delay(100);
    yield call([Toaster, 'success'], 'Email sent successfully');
  } catch (err: any) {
    yield put(actions.saasCustomOfferContactUsRequestAction.failure(err));
    yield delay(100);
    yield call([Toaster, 'error'], ErrorType.default);
  }
}

export function* scaleUpgradeContactUs(
  action: ActionType<
    typeof actions.saasScaleUpgradeContactUsRequestAction.request
  >
) {
  const { succeededCallback, failureCallback, ...payload } = action.payload;

  try {
    yield call(client.post, '/finance/saas/scale-upgrade', {
      ...payload,
      creditSpectrum: payload.creditSpectrum[0],
      marketingChannels: payload.marketingChannels?.[0],
      sectorType: payload.sectorType[0],
    });

    yield put(actions.saasScaleUpgradeContactUsRequestAction.success());
    yield delay(50);
    yield call(
      Toaster.success,
      'Thank you for submitting your request. Someone from sales team will reach out to you shortly.'
    );
    succeededCallback?.();
  } catch (err: any) {
    yield put(actions.saasScaleUpgradeContactUsRequestAction.failure(err));
    yield call([Toaster, 'error'], ErrorType.default);
    failureCallback?.();
  }
}

export function* customLeadAttributesContactUs(
  action: ActionType<
    typeof actions.saasCustomLeadAttributesContactUsRequestAction.request
  >
) {
  try {
    yield call(
      client.post,
      `/finance/saas/custom-lead-attributes/${action.payload.subAccountUuid}`
    );

    yield call(
      Toaster.success,
      'A customer service representative will reach out to set up your custom lead attributes'
    );

    yield put(actions.saasCustomLeadAttributesContactUsRequestAction.success());
  } catch (err: any) {
    yield put(
      actions.saasCustomLeadAttributesContactUsRequestAction.failure(err)
    );
  }
}

export function* getFees() {
  try {
    const { data: fees }: AxiosResponse<SaasFee[]> = yield call(
      client.get,
      '/finance/saas/fees'
    );

    yield put(actions.saasFeesRequestAction.success(fees));
  } catch (err: any) {
    yield put(actions.saasFeesRequestAction.failure(err));
  }
}

export function* createFee(
  action: ActionType<typeof actions.createSaasFeesRequestAction.request>
) {
  try {
    const fee: SaasFee = yield call(createFeeRequest, action.payload);

    yield putResolve(actions.createSaasFeesRequestAction.success(fee));
  } catch (err: any) {
    yield putResolve(actions.createSaasFeesRequestAction.failure(err));
  }
}

export function* createFeeRequest(
  payload: actions.CreateSaasFeeRequestPayload
) {
  const { data: response }: AxiosResponse<SaasFee> = yield call(
    client.post,
    '/finance/saas/fees',
    payload
  );

  return response;
}

export function* getPlans() {
  try {
    const { data: plans } = yield call([client, 'get'], '/finance/saas/plans');

    yield put(actions.saasPlansRequestAction.success(plans));
  } catch (err: any) {
    yield put(actions.saasPlansRequestAction.failure(err));
  }
}

export function* getSubscription() {
  try {
    const { data: subscription } = yield call(
      client.get,
      '/finance/saas/subscription-full'
    );

    yield put(actions.saasSubscriptionRequestAction.success(subscription));
  } catch (err: any) {
    yield put(actions.saasSubscriptionRequestAction.failure(err));
  }
}

export function* createSubscription(
  action: ActionType<typeof actions.createSaasSubscriptionRequestAction.request>
) {
  try {
    const subscription: SaasSubscriptionFull = yield* createSubscriptionRequest(
      action.payload
    );

    yield put(
      actions.createSaasSubscriptionRequestAction.success(subscription)
    );
  } catch (err: any) {
    const portalError = getPortalError(err);

    yield put(actions.createSaasSubscriptionRequestAction.failure(portalError));
  }
}

export function* previewCreateSubscription(
  action: ActionType<
    typeof actions.previewCreateSaasSubscriptionRequestAction.request
  >
) {
  try {
    const { data: preview } = yield call(
      client.post,
      '/finance/saas/subscription/preview',
      action.payload
    );

    yield put(
      actions.previewCreateSaasSubscriptionRequestAction.success({
        request: action.payload,
        response: preview,
      })
    );
  } catch (err: any) {
    const portalError = getPortalError(err);

    yield put(
      actions.previewCreateSaasSubscriptionRequestAction.failure(portalError)
    );
  }
}

export function* deleteSubscription(
  action: ActionType<typeof actions.deleteSaasSubscriptionRequestAction.request>
) {
  try {
    const { data: subscription } = yield call(
      client.delete,
      `/finance/saas/subscription/${action.payload.id}?reason=${action.payload.reason}`
    );

    yield put(
      actions.deleteSaasSubscriptionRequestAction.success(subscription)
    );

    yield delay(50);
    yield call([Toaster, 'success'], 'The subscription was canceled');
  } catch (err: any) {
    yield put(actions.deleteSaasSubscriptionRequestAction.failure(err));
  }
}

export function* createSubscriptionRequest(
  payload: CreateSaasSubscriptionRequestPayload
) {
  const { data: response } = yield call(
    client.post,
    '/finance/saas/subscription',
    payload
  );

  return response;
}

export function* getSaasAll(
  action: ActionType<typeof actions.saasAllRequestAction.request>
) {
  const saas: SaasState = yield select((state: ApplicationState) => state.saas);
  const cookiePayload = { cookie: action.payload.cookie };

  if (!action.payload.skipTrial && !saas.hasTrialBeenRequested) {
    yield put(actions.saasUserTrialRequestAction.request(cookiePayload));
  }

  if (!action.payload.skipFees && !saas.hasFeesBeenRequested) {
    yield put(actions.saasFeesRequestAction.request(cookiePayload));
  }

  if (!saas.hasPlansBeenRequested) {
    yield put(actions.saasPlansRequestAction.request(cookiePayload));
  }

  if (!saas.hasSubscriptionBeenRequested) {
    yield put(actions.saasSubscriptionRequestAction.request(cookiePayload));
  }

  yield put(actions.saasAllRequestAction.success());
}

export function* purchasePlan({
  payload: {
    paymentEmail,
    tokenId,
    subscriptionPayment,
    implementationFeePayment,
    isSubmitFlow,
    integrationId,
  },
}: ActionType<typeof actions.purchasePlanRequestAction.request>) {
  try {
    if (tokenId && paymentEmail) {
      yield call(createRecurlyTokenRequest, {
        paymentEmail,
        tokenId,
      });
    }

    const subscriptionData: SaasSubscriptionFull = yield call(
      createSubscriptionRequest,
      subscriptionPayment
    );

    yield put(
      actions.createSaasSubscriptionRequestAction.success(subscriptionData)
    );

    if (implementationFeePayment) {
      yield put(
        actions.createSaasFeesRequestAction.request(implementationFeePayment)
      );
    }

    if (isSubmitFlow && integrationId) {
      yield put(actions.executeSubmitFlowAction.request({ integrationId }));
    } else if (subscriptionData) {
      yield delay(50);
      yield call([Toaster, 'success'], 'Purchased a plan successfully.');
      yield call([Router, 'push'], '/home');
    }
  } catch (err: any) {
    const portalError = getPortalError(err);

    yield call(
      [Toaster, 'error'],
      portalError.customError ?? ErrorType.default
    );
    yield put(actions.purchasePlanRequestAction.failure(getPortalError(err)));
  }
}

export function* purchaseIntegrationFee({
  payload: {
    paymentEmail,
    tokenId,
    cookie,
    saasPlanId,
    planSummary: { saasFeeReason, accessTokenUuid, integrationId },
    isSubmitFlow,
  },
}: ActionType<typeof actions.purchaseIntegrationFeeRequestAction.request>) {
  try {
    if (!saasFeeReason || !accessTokenUuid || !integrationId) {
      throw new Error('Something went wrong');
    }

    if (tokenId && paymentEmail) {
      yield call(createRecurlyTokenRequest, {
        cookie,
        paymentEmail,
        tokenId,
      });
    }

    const fee: SaasFee = yield call(createFeeRequest, {
      accessTokenUuid,
      cookie,
      reason: saasFeeReason,
      saasPlanId,
    });

    yield put(actions.createSaasFeesRequestAction.success(fee));

    yield delay(50);
    yield call(
      Toaster.success,
      'Success Note - You have paid the implementation fee'
    );

    if (isSubmitFlow) {
      yield put(actions.executeSubmitFlowAction.request({ integrationId }));
    } else {
      yield put(actions.purchaseIntegrationFeeRequestAction.success());
      yield call([Router, 'push'], '/home?feePaymentSuccess=true');
    }
  } catch (err: any) {
    const portalError = getPortalError(err);

    yield call(
      [Toaster, 'error'],
      portalError.customError ?? ErrorType.default
    );
    yield put(actions.purchaseIntegrationFeeRequestAction.failure(portalError));
  }
}

export function* executeSubmitFlow(
  action: ActionType<typeof actions.executeSubmitFlowAction.request>
) {
  const { integrationId } = action.payload;
  const {
    integration: { subAccountUuid },
  } = yield select((state: ApplicationState) =>
    IntegrationDataSelector(state, integrationId)
  );

  yield call(submitForReviewInternal, {
    integrationId,
    subAccountUuid,
  });
  yield put(actions.executeSubmitFlowAction.success());
}

export function* createRecurlyToken(
  action: ActionType<typeof actions.createRecurlyTokenRequestAction.request>
) {
  try {
    const token: RecurlyToken = yield* createRecurlyTokenRequest(
      action.payload
    );

    yield put(actions.createRecurlyTokenRequestAction.success(token));
  } catch (err: any) {
    yield put(
      actions.createRecurlyTokenRequestAction.failure(getPortalError(err))
    );
  }
}

export function* createRecurlyTokenRequest(
  payload: CreateRecurlyTokenRequestPayload
) {
  const { data } = yield call(
    client.post,
    '/finance/saas/recurly-token',
    payload
  );

  return data;
}

export function* onPurchaseFormChanged({
  payload: { data, waiveImplementationFee, findMigrationPlan },
}: ActionType<typeof actions.onPurchaseFormChangedAction.request>) {
  try {
    const saasData: SaasData = yield select(saasDataSelector);

    const plan = yield call(
      findMatchingSaasPlan,
      saasData.plans,
      data.paymentAndBillingInformation.tier!,
      data.planSummary.planDuration,
      data.planSummary.billingCycle,
      waiveImplementationFee,
      findMigrationPlan
    );

    yield call(() => {
      if (!plan) {
        throw new Error(ErrorType.saasPlanNotFound);
      }
    });

    const request = yield call(
      assembleSubscriptionPaymentRequestBody,
      data,
      plan.id,
      saasData.subscription?.id
    );

    const subscriptionPreviewData = yield select(selectSaasSubscriptionPreview);

    if (!isEqual(request, subscriptionPreviewData?.request)) {
      yield put(
        actions.previewCreateSaasSubscriptionRequestAction.request(request)
      );
    }

    yield put(actions.onPurchaseFormChangedAction.success());
  } catch (err) {
    yield actions.onPurchaseFormChangedAction.failure(getPortalError(err));
  }
}

export function* saasSaga() {
  yield takeLatest(
    getType(actions.saasUserTrialRequestAction.request),
    getUserTrial
  );
  yield takeLatest(
    getType(actions.createSaasTrialRequestAction.request),
    checkAndCreateTrial
  );
  yield takeLatest(getType(actions.saasFeesRequestAction.request), getFees);
  yield takeLatest(
    getType(actions.createSaasFeesRequestAction.request),
    createFee
  );
  yield takeLatest(getType(actions.saasPlansRequestAction.request), getPlans);
  yield takeLatest(
    getType(actions.saasSubscriptionRequestAction.request),
    getSubscription
  );
  yield takeLatest(
    getType(actions.createSaasSubscriptionRequestAction.request),
    createSubscription
  );
  yield takeLatest(
    getType(actions.previewCreateSaasSubscriptionRequestAction.request),
    previewCreateSubscription
  );
  yield takeLatest(
    getType(actions.deleteSaasSubscriptionRequestAction.request),
    deleteSubscription
  );
  yield takeLatest(getType(actions.saasAllRequestAction.request), getSaasAll);
  yield takeLatest(
    getType(actions.createRecurlyTokenRequestAction.request),
    createRecurlyToken
  );
  yield takeLatest(
    getType(actions.purchasePlanRequestAction.request),
    purchasePlan
  );
  yield takeLatest(
    getType(actions.purchaseIntegrationFeeRequestAction.request),
    purchaseIntegrationFee
  );
  yield takeLatest(
    getType(actions.saasCustomOfferContactUsRequestAction.request),
    customOffersContactUs
  );
  yield takeLatest(
    getType(actions.saasScaleUpgradeContactUsRequestAction.request),
    scaleUpgradeContactUs
  );
  yield takeLatest(
    getType(actions.saasCustomLeadAttributesContactUsRequestAction.request),
    customLeadAttributesContactUs
  );
  yield takeLatest(
    getType(actions.onPurchaseFormChangedAction.request),
    onPurchaseFormChanged
  );
  yield takeLatest(
    getType(actions.executeSubmitFlowAction.request),
    executeSubmitFlow
  );
  yield takeLatest(
    getType(actions.initPurchasePlanPage.request),
    initPurchasePlanPage
  );
  yield takeLatest(
    getType(actions.initIntegrationFeePage.request),
    initIntegrationFeePage
  );
}
