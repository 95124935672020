import * as React from 'react';

import classNames from 'classnames';
import kebabCase from 'lodash/kebabCase';

import {
  PortalFormFieldContext,
  importMap,
  useForm,
  validateOne,
} from '@/components/form/portal-form';
import { PortalFormFieldType } from '@/components/form/portal-form/types';
import type { PortalFormField } from '@/components/form/portal-form/types';

import {
  getDefaultValue as getDefaultValueProp,
  getValueFromOnChangeEvent,
  getValue as getValueProp,
} from './helpers';
import type { SafeFieldProps } from './helpers';

interface PortalFormFieldElementProps<FormType extends object> {
  field: PortalFormField<FormType>;
}

const PortalFormFieldElement = <FormType extends {}>(
  props: PortalFormFieldElementProps<FormType>
) => {
  const { field } = props;

  const {
    values,
    validationMap,
    setValue,
    setError,
    getValue,
    getDefaultValue,
  } = useForm();

  if (field.type === PortalFormFieldType.Element) {
    return null;
  }

  const fieldProps = field.fieldProps as SafeFieldProps;

  const handleOnChange = async (...payload: unknown[]) => {
    const value = getValueFromOnChangeEvent(field.type, payload);

    setError(field.name!, null);
    setValue(field.name!, value);

    if (field.validation) {
      const error = await validateOne(
        field.name!,
        validationMap[field.name],
        values
      );

      setError(field.name!, error);
    }

    fieldProps?.onChange?.(...payload);
  };

  const handleSetFieldValue = (value: any, validate: boolean) => {
    setValue(field.name!, value, validate);
  };

  const Component = importMap[field.type];

  if (Component) {
    return (
      <PortalFormFieldContext.Provider
        value={{
          setFieldValue: handleSetFieldValue,
        }}
      >
        <Component
          {...fieldProps}
          {...getValueProp(field, getValue(field.name!))}
          {...getDefaultValueProp(field, getDefaultValue(field.name!))}
          className={classNames(fieldProps?.className, {
            'date-picker': field.type === PortalFormFieldType.DatePicker,
          })}
          data-qa={kebabCase(field.name)}
          onChange={handleOnChange}
        />
      </PortalFormFieldContext.Provider>
    );
  }

  return null;
};

export default PortalFormFieldElement;
