import { createSelector } from 'reselect';

import type { ApplicationState } from '@/store';

export const getApplicationConfiguration = (state: ApplicationState) =>
  state.config;

export const getPartnerPageBaseUrls = createSelector(
  [getApplicationConfiguration],
  (config) => config.PARTNER_PAGE_BASE_URLS
);
