import * as React from 'react';
import { useMemo } from 'react';

import { isEqual } from 'lodash';
import moment from 'moment';

import { ProductType } from '@evenfinancial/finance-client';
import { SummaryGroupBy } from '@evenfinancial/supply-reporting-client';

import {
  useGetFunnelSummaryQuery,
  useGetSupplyContractsQuery,
} from '@portal/api-client';
import type { PortalSubAccount } from '@portal/api-models';

import StatBar from '@/components/reporting/stat-bar';
import type { Integration } from '@/resources/integrations/types';
import { EvenDateFormat } from '@/utils/date-time-formatters';

import { generateStatBarConfig, getProductTypeBadges } from './utils';

export interface PerformanceSummaryStatBarReportProps {
  integrations: Integration[];
  subAccount: PortalSubAccount;
}

const PerformanceSummaryStatBarReport: React.FC<
  PerformanceSummaryStatBarReportProps
> = ({ subAccount, integrations }) => {
  const maxDate = moment().subtract(1, 'd').format(EvenDateFormat);
  const minDate = moment(maxDate).subtract(30, 'd').format(EvenDateFormat);

  const productType = subAccount.supplyProductTypes?.[0] ?? ProductType.Other;

  const params = {
    groupBy: SummaryGroupBy.Day,
    maxDate,
    minDate,
    productType,
    subAccountUuid: subAccount.uuid,
  };

  const funnelSummaryQuery = useGetFunnelSummaryQuery(params, {
    // Retry attempts temporary until supply reporting service becomes
    // more reliable.
    retry: 3,
  });

  const supplyContractsQuery = useGetSupplyContractsQuery({
    subAccountUuid: subAccount.uuid,
  });

  const summaryStatConfig = useMemo(() => {
    return generateStatBarConfig({
      funnelSummary: funnelSummaryQuery.data,
      productType: productType as unknown as ProductType,
      supplyContracts: supplyContractsQuery.data,
    });
  }, [funnelSummaryQuery.data, supplyContractsQuery.data]);

  const badges = useMemo(() => getProductTypeBadges(integrations), []);

  return (
    <div className="performance-summary-stat-bar">
      <h4 className="channel-name">
        {badges}
        {subAccount.name}
      </h4>
      <StatBar showTooltipsOnLabels stats={summaryStatConfig} />
    </div>
  );
};

export default React.memo(
  PerformanceSummaryStatBarReport,

  (prevProps, nextProps) => {
    return (
      isEqual(prevProps.subAccount, nextProps.subAccount) &&
      isEqual(prevProps.integrations, nextProps.integrations)
    );
  }
);
