import { datadogRum } from '@datadog/browser-rum-slim';

import type { User } from '@evenfinancial/auth-client';

import { getDDBaseConfig } from '@/services/datadog';
import type { ApplicationState } from '@/store';

export function init(config: ApplicationState['config']) {
  datadogRum.init({
    ...getDDBaseConfig(config),
    applicationId: 'd81e9e1c-fffb-4f4f-b965-ac2f9ce5b679',
    replaySampleRate: 0,
    trackInteractions: true,
  });
}

export function setUser(engineUser: User) {
  const {
    accountId,
    accountUuid,
    companyUuid,
    createdAt,
    createdById,
    emailAddress: email,
    updatedById,
    uuid,
  } = engineUser;

  const id = String(engineUser.id);

  datadogRum.setUser({
    accountId,
    accountUuid,
    companyUuid,
    createdAt,
    createdById,
    email,
    id,
    updatedById,
    uuid,
  });
}
