import * as React from 'react';

import isEmpty from 'lodash/isEmpty';

import type { PortalSubAccount } from '@portal/api-models';

import RecordDropdown from '@/components/form/portal-form/fields/record-dropdown';
import type { ComposedRecordDropdownProps } from '@/components/form/portal-form/fields/record-dropdown';

import { useSubAccounts } from './helpers';

const emptySubAccountFilter = (subAccount: PortalSubAccount) =>
  !isEmpty(subAccount.name);

export type SubAccountDropdownProvidedProps = {
  accountIds?: number[];
  accountUuids?: string[];
  /** @deprecated Use accountUuids instead */
  accounts?: string[];
  dataIndex?: keyof PortalSubAccount;
  demandOnly?: true;
  isValueId?: true;
  labelDisplayId?: boolean;
  subAccountFilter?: (subAccount: PortalSubAccount) => boolean;
  supplyOnly?: true;
} & ComposedRecordDropdownProps;

type SubAccountDropdownProps = SubAccountDropdownProvidedProps;

const SubAccountDropdown: React.FC<SubAccountDropdownProps> = (props) => {
  const {
    subAccountFilter,
    supplyOnly,
    demandOnly,
    accounts,
    accountUuids = accounts,
    accountIds,
    dataIndex,
    isValueId,
    labelDisplayId = false,
    ...recordDropdownProps
  } = props;

  const subAccounts = useSubAccounts({
    accountIds,
    accountUuids,
    demandOnly,
    supplyOnly,
  });

  const filters = subAccountFilter
    ? [emptySubAccountFilter, subAccountFilter]
    : [emptySubAccountFilter];

  return (
    <RecordDropdown
      filters={filters}
      id="subaccount"
      optionKey="uuid"
      optionLabel={(subAccount) =>
        (labelDisplayId
          ? `${subAccount.id} - ${subAccount.name}`
          : subAccount.name) || ''
      }
      optionValue={dataIndex ?? (isValueId ? 'id' : 'uuid')}
      records={subAccounts}
      {...recordDropdownProps}
    />
  );
};

export default SubAccountDropdown;
