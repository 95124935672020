import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import omit from 'lodash/omit';
import { useRouter } from 'next/router';

import { OnboardingStatus } from '@portal/api-models';
import { viewContextSelector } from '@portal/store/dist/view-context/selectors';
import { EvenButton, ViewMoreButton } from '@portal/ui-lib';

import { CardTable } from '@/components/card-table';
import PanelCard from '@/components/panel-card/panel-card';
import ActionModal from '@/components/self-service/home/action-modal';
import type { ActionModalProps } from '@/components/self-service/home/action-modal';
import ExpandedRowDetails from '@/components/self-service/integrations/expanded-row-details';
import EmptyPlaceholder from '@/components/table/empty-placeholder';
import CopyEmbedCodeModal from '@/pages/self-service/compliance/copy-embed-code-modal';
import { ImplementationFeePaymentStatus } from '@/resources/integrations/types';
import type { IntegrationRow } from '@/resources/integrations/types';
import { tableConfig } from '@/resources/self-service/home/integrations-table-config';
import { getIntegrationRowRenderProps } from '@/resources/self-service/integrations/get-expanded-row-instructions';
import { IntegrationCategory } from '@/resources/sub-accounts-shared/utils';
import * as complianceActions from '@/store/compliance/actions';
import * as partnerPageActions from '@/store/partner-page/actions';

export type OpenActionModal = (
  type: OnboardingStatus,
  integrationType: IntegrationCategory,
  implementationFeePaymentStatus: ImplementationFeePaymentStatus,
  id: string,
  partnerKey: string,
  subAccountUuid: string
) => void;

export interface IntegrationsCardProps {
  disabled: boolean;
  integrations: IntegrationRow[];
  planPurchased: boolean;
}

interface EmbedCodeState {
  copyEmbedCodeVisible: boolean;
  integrationId?: string;
}

export const IntegrationsCard: React.FC<IntegrationsCardProps> = ({
  disabled,
  integrations,
  planPurchased,
}) => {
  const dispatch = useDispatch();
  const { push } = useRouter();
  const {
    hidePartnerPageStatuses,
    showIntegrationCreateButton,
    showIntegrationActions,
    showIntegrationCopyOverride,
  } = useSelector(viewContextSelector);

  const embedExists = integrations.some(
    (integration) => integration.integrationType === IntegrationCategory.Embed
  );

  const [{ copyEmbedCodeVisible, integrationId }, setEmbedCodeState] =
    useState<EmbedCodeState>(() => ({
      copyEmbedCodeVisible: false,
      integrationId: undefined,
    }));

  const [actionModalState, setActionModalState] = useState<
    Omit<ActionModalProps, 'closeCallback'>
  >(() => ({
    id: undefined,
    implementationFeePaymentStatus: undefined,
    integrationType: undefined,
    opened: false,
    partnerKey: undefined,
    subAccountUuid: undefined,
    type: undefined,
  }));

  const toggleCreate = () =>
    dispatch(partnerPageActions.toggleIntegrationCreate());

  const submitForReview = (integrationId: string, subAccountUuid: string) =>
    dispatch(
      complianceActions.submitForReviewAction.request({
        integrationId,
        subAccountUuid,
      })
    );

  const openCopyEmbedCodeModal = (id?: string) => {
    setEmbedCodeState({ copyEmbedCodeVisible: true, integrationId: id });
  };

  const openActionModalState = (
    onboardingStatus: OnboardingStatus,
    integrationType: IntegrationCategory,
    implementationFeePaymentStatus: ImplementationFeePaymentStatus,
    id: string,
    partnerKey: string,
    subAccountUuid: string
  ) => {
    if (
      implementationFeePaymentStatus === ImplementationFeePaymentStatus.Unpaid
    ) {
      submitForReview(id, subAccountUuid);
    } else {
      setActionModalState({
        id,
        integrationType,
        onboardingStatus,
        opened: true,
        partnerKey,
        subAccountUuid,
      });
    }
  };

  return (
    <PanelCard
      className="integrations-card"
      data-testid="integrations-card"
      disabled={disabled}
      extra={
        <>
          {showIntegrationCreateButton && (
            <EvenButton
              tertiary
              className={classNames(
                'theme-green',
                'integrations-card-create-integration',
                {
                  'integrations-card-disabled': disabled,
                }
              )}
              onClick={toggleCreate}
            >
              Create Integration
            </EvenButton>
          )}
          <ViewMoreButton onClick={async () => push('/integrations')} />
        </>
      }
      title="Integrations"
    >
      <CardTable
        className="integrations-card-table"
        columns={tableConfig(
          integrations,
          planPurchased,
          openCopyEmbedCodeModal,
          openActionModalState,
          embedExists,
          hidePartnerPageStatuses,
          showIntegrationActions,
          showIntegrationCopyOverride
        )}
        dataSource={integrations}
        expandable={{
          defaultExpandAllRows: true,
          expandIcon: () => null,
          expandedRowRender: (record) => {
            const props =
              record.onboardingStatus === OnboardingStatus.Ready &&
              planPurchased
                ? getIntegrationRowRenderProps(record, planPurchased)
                : undefined;

            if (props && showIntegrationActions) {
              return <ExpandedRowDetails {...omit(props, ['action'])} />;
            }

            return <></>;
          },
          rowExpandable: () => true,
        }}
        locale={{
          emptyText: (
            <EmptyPlaceholder description="Create your first integration" />
          ),
        }}
        pagination={false}
        rowKey="id"
      />
      {embedExists && (
        <CopyEmbedCodeModal
          dismissModal={() =>
            setEmbedCodeState({
              copyEmbedCodeVisible: false,
              integrationId: undefined,
            })
          }
          integrationId={integrationId}
          visible={copyEmbedCodeVisible}
        />
      )}
      {planPurchased && (
        <ActionModal
          closeCallback={() => {
            setActionModalState({
              id: undefined,
              integrationType: undefined,
              onboardingStatus: undefined,
              opened: false,
              partnerKey: undefined,
            });
          }}
          {...actionModalState}
        />
      )}
    </PanelCard>
  );
};
