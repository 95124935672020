import { createSelector } from 'reselect';

import {
  selectNotifications,
  selectNotificationsDrawerVisible,
  selectNotificationsFilter,
  selectUnreadNotificationsCount,
} from '@/resources/notifications/selects';

export const notificationsSelector = createSelector(
  [
    selectNotifications,
    selectNotificationsFilter,
    selectNotificationsDrawerVisible,
    selectUnreadNotificationsCount,
  ],
  (notifications, filter, drawerVisible, unreadNotificationsCount) => ({
    drawerVisible,
    filter,
    notifications,
    unreadNotificationsCount,
  })
);
