"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeActionType = void 0;
var HomeActionType;
(function (HomeActionType) {
    HomeActionType["GET_DEMAND_HOME_FAILURE"] = "@@home/GET_DEMAND_HOME_FAILURE";
    HomeActionType["GET_DEMAND_HOME_REQUEST"] = "@@home/GET_DEMAND_HOME_REQUEST";
    HomeActionType["GET_DEMAND_HOME_SUCCESS"] = "@@home/GET_DEMAND_HOME_SUCCESS";
    HomeActionType["GET_SUPPLY_HOME_FAILURE"] = "@@home/GET_SUPPLY_HOME_FAILURE";
    HomeActionType["GET_SUPPLY_HOME_REQUEST"] = "@@home/GET_SUPPLY_HOME_REQUEST";
    HomeActionType["GET_SUPPLY_HOME_SUCCESS"] = "@@home/GET_SUPPLY_HOME_SUCCESS";
})(HomeActionType = exports.HomeActionType || (exports.HomeActionType = {}));
