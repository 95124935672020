import { camelCase, groupBy, orderBy, sortBy, startCase } from 'lodash';
import { createSelector } from 'reselect';

import type {
  ExpectedEarningsShare,
  SupplySubAccountDeactivation,
} from '@evenfinancial/finance-client';
import type { DemandApi } from '@evenfinancial/originator-client';

import type {
  DataShareType,
  PortalSubAccount,
  TempAccount,
  UserWithRoles,
} from '@portal/api-models';
import { serialize } from '@portal/common/dist/utils';
import { selectDemandApiRows } from '@portal/store/dist';
import {
  selectAccounts,
  selectAccountsKeyedById,
} from '@portal/store/dist/account/selectors';
import { selectSupplySubAccountToDuplicate } from '@portal/store/dist/subaccount/selectors';
import {
  getAccountOwnerUuid,
  mapUuidToName,
  selectUsers,
} from '@portal/store/dist/user/selectors';
import type { SupplySubAccountCreateFormData } from '@portal/ui-lib';

import type { ExpectedEarningsShareProvidedProps } from '@/pages/supply-sub-accounts/internal/cards/expected-earnings-share-card';
import type { LeadDeduplicationCardFormProvidedProps } from '@/pages/supply-sub-accounts/internal/cards/lead-deduplication-card';
import type { LeadHydrationProps } from '@/pages/supply-sub-accounts/internal/cards/lead-hydration-card';
import type { SupplySubAccountDetailsProvidedProps } from '@/pages/supply-sub-accounts/internal/details';
import type { InheritedSubAccount } from '@/resources/demand-sub-accounts/selectors/details-data';
import type { ApplicationState } from '@/store';

import type { SupplyDemandSARow, SupplySubAccountRow } from './types';

export const selectSupplySubAccounts = (state: ApplicationState) =>
  state.subAccounts.supply;

export const selectDeactivateSupplySubAccounts = (state: ApplicationState) =>
  state.subAccounts.supplyDeactivationRows;

export const selectSupplySubAccountsSortedByName = (state: ApplicationState) =>
  sortBy(selectSupplySubAccounts(state), ({ name = '' }) => serialize(name));

export const selectSupplySubAccountsByUuid =
  (uuid: string) => (state: ApplicationState) =>
    selectSupplySubAccounts(state).find((ssa) => ssa.uuid === uuid);

export const subAccountOwnersSelector = createSelector(
  [
    (
      { accounts, subAccounts }: ApplicationState,
      props: SupplySubAccountDetailsProvidedProps
    ) => {
      const subAccount = subAccounts.byUuid[props.uuid];

      if (!subAccount) {
        return;
      }

      return {
        ...subAccount,
        partnerManager: getAccountOwnerUuid(
          accounts.rows,
          subAccount,
          'supplyPartnerManager'
        ),
        yieldManager: getAccountOwnerUuid(
          accounts.rows,
          subAccount,
          'supplyYieldManager'
        ),
      };
    },
  ],
  (subAccount?: InheritedSubAccount) => {
    if (subAccount) {
      return { subAccount };
    }
  }
);

export const tableDataSelector = createSelector(
  [
    selectAccounts,
    selectSupplySubAccounts,
    selectUsers,
    selectDeactivateSupplySubAccounts,
  ],
  (
    accounts: TempAccount[],
    supply: PortalSubAccount[],
    users: UserWithRoles[],
    subAccountDeactivations: SupplySubAccountDeactivation[]
  ): SupplySubAccountRow[] => {
    const deactivationIdsBySubAccountId = groupBy(
      subAccountDeactivations.filter((record) => !record.deletedAt),
      'supplySubAccountId'
    );

    return supply.map((subAccount) => {
      const user = users.find(
        (user) => subAccount.createdBy === user.accountUuid
      );

      const account = accounts.find(
        (account) => subAccount.accountUuid === account.uuid
      );

      const integrationType = startCase(camelCase(subAccount.integrationType));

      const dataShare = startCase(
        camelCase(subAccount.dataShare)
      ) as DataShareType;

      const createdBy = user ? `${user.firstName} ${user.lastName}` : '';

      const deactivation = deactivationIdsBySubAccountId[subAccount.id]?.[0];

      return {
        ...subAccount,
        accountName: account?.name || '',
        createdBy,
        dataShare,
        deactivationId: deactivation?.id,
        integrationType: integrationType as any,
        isActive: !deactivation,
        isActiveDisplay: deactivation ? 'Inactive' : 'Active',
        supplyPartnerManager: mapUuidToName(
          getAccountOwnerUuid(accounts, subAccount, 'supplyYieldManager'),
          users
        ),
        supplyYieldManager: mapUuidToName(
          getAccountOwnerUuid(accounts, subAccount, 'supplyYieldManager'),
          users
        ),
      };
    });
  }
);

export const applicationsTabDataSelector = createSelector(
  [
    (
      { subAccounts }: ApplicationState,
      props: SupplySubAccountDetailsProvidedProps
    ) => subAccounts.byUuid[props.uuid],
    (
      { subAccounts }: ApplicationState,
      props: SupplySubAccountDetailsProvidedProps
    ) => subAccounts.viewData[props.uuid],
    selectAccounts,
    selectUsers,
    ({ config }: ApplicationState) => config.APP_URL,
    selectDeactivateSupplySubAccounts,
  ],
  (
    subAccount,
    subAccountViewData,
    accounts,
    user,
    appUrl,
    deactivateSupplySubAccounts
  ) => {
    if (!subAccount) {
      return {};
    }

    const deactivateSuppySubAccount = deactivateSupplySubAccounts.find(
      (item) =>
        item.supplySubAccountId === subAccount.id && !('deletedBy' in item)
    );
    const isActive = !deactivateSuppySubAccount;

    const savd = subAccountViewData || { accessTokens: [], partnerKeys: [] };

    return {
      accessTokens: savd.accessTokens.map((token) => {
        const found = user.find(
          (u) => u.accountUuid === token.resourceOwnerUuid
        );

        const mapped = {
          ...token,
          createdBy: found ? `${found.firstName} ${found.lastName}` : 'n/a',
        };

        return mapped;
      }),
      account:
        subAccount && accounts.find((a) => a.uuid === subAccount.accountUuid),
      appUrl,
      deactivationIds: deactivateSuppySubAccount?.id,
      isActive: isActive || false,
      partnerKeys: savd.partnerKeys,
      subAccount,
    };
  }
);

export const partnerTabDataSelector = createSelector(
  [
    (
      { subAccounts }: ApplicationState,
      props: SupplySubAccountDetailsProvidedProps
    ) => subAccounts.byUuid[props.uuid],
    selectDemandApiRows,
    (
      { originator }: ApplicationState,
      props: SupplySubAccountDetailsProvidedProps
    ) => originator.partnerMappingsBySubAccountUuid[props.uuid],
    ({ originator }: ApplicationState) => originator.demandOfferCounts,
    ({ subAccounts }: ApplicationState) => subAccounts.demand,
    selectAccounts,
    ({ config }: ApplicationState) => config.APP_URL,
  ],
  (
    subAccount,
    demandApi,
    partnerMappings,
    demandOfferCounts,
    demandSubAccounts,
    accounts,
    appUrl
  ) => {
    const demandApiMap = demandApi.reduce<Record<string, DemandApi>>(
      (acc, api) => {
        const { subAccountUuid } = api;

        if (subAccountUuid) {
          return { ...acc, [subAccountUuid]: api };
        }

        return acc;
      },
      {}
    );

    return {
      account:
        subAccount && accounts.find((a) => a.uuid === subAccount.accountUuid),
      appUrl,
      demandApi,
      demandSubAccounts: demandSubAccounts.map<SupplyDemandSARow>(
        (demandSubAccount) => {
          const row: SupplyDemandSARow = {
            ...demandSubAccount,
            demandApiPresent: Boolean(demandApiMap[demandSubAccount.uuid]),
            dynamic: 'Disabled',
            dynamicOffersEnabled: false,
            isSecondLook: demandApiMap[demandSubAccount.uuid]
              ? Boolean(demandApiMap[demandSubAccount.uuid].isSecondLook)
              : false,
            static: 0,
            totalStaticForDemand: demandOfferCounts[demandSubAccount.uuid] || 0,
          };

          if (!partnerMappings || !partnerMappings[demandSubAccount.uuid]) {
            return row;
          }

          const mapping = partnerMappings[demandSubAccount.uuid];

          row.static = mapping.offerRuleIds ? mapping.offerRuleIds.length : 0;

          const predicate = Boolean(
            mapping.demandInclusionUuids &&
              mapping.demandInclusionUuids.length &&
              row.demandApiPresent
          );

          row.dynamic = predicate
            ? 'Enabled'
            : row.demandApiPresent
            ? 'Disabled'
            : 'No API';
          row.dynamicOffersEnabled = predicate;

          return row;
        }
      ),
      subAccount,
    };
  }
);

export const leadDeduplicationCardSelector = createSelector(
  [
    (
      { originator }: ApplicationState,
      { supplySubAccountUuid }: LeadDeduplicationCardFormProvidedProps
    ) =>
      originator.deduplicationConfigurations.filter(
        (dc) => dc.supplySubAccountUuid === supplySubAccountUuid
      ),
    ({ analytics }: ApplicationState) => analytics.duplicateSummaryRowsByDDCId,
    ({ message }) => message.loading > 0,
  ],
  (deduplicationConfigurations, duplicationSummaries, isLoading) => {
    return {
      deduplicationConfigurations,
      duplicationSummaries,
      isLoading,
    };
  }
);

export const leadHydrationDataSelector = createSelector(
  [
    ({ leadHydration }: ApplicationState, { subAccount }: LeadHydrationProps) =>
      leadHydration.bySubAccountUuid[subAccount.uuid],
    ({ message }) => message.loading > 0,
  ],
  (leadHydrationConfigs, isLoading) => {
    return {
      isLoading,
      leadHydrationConfigs,
    };
  }
);

export const expectedEarningsShareDataSelector = createSelector(
  [
    (
      { expectedEarningsShare }: ApplicationState,
      props: ExpectedEarningsShareProvidedProps
    ) => {
      const expectedEarningsShares =
        expectedEarningsShare.bySubAccountUuid[props.subAccount.uuid];

      if (expectedEarningsShares) {
        return orderBy(expectedEarningsShares, 'createdAt', ['desc']);
      }

      return [] as ExpectedEarningsShare[];
    },
  ],
  (expectedEarningsShares) => expectedEarningsShares
);

export const currentExpectedEarningShareSelector = createSelector(
  [expectedEarningsShareDataSelector],
  (expectedEarningsShares) => expectedEarningsShares.shift()
);

export const historyExpectedEarningShareSelector = createSelector(
  [expectedEarningsShareDataSelector],
  (expectedEarningsShares) => {
    expectedEarningsShares.shift();

    return expectedEarningsShares;
  }
);

export const duplicateSubAccountDataSelector = createSelector(
  [
    selectSupplySubAccountToDuplicate,
    selectAccountsKeyedById,
    ({ originator }: ApplicationState) => originator,
    ({ leadHydration }: ApplicationState) => leadHydration,
  ],
  (subAccount, accountsKeyedById, originator, leadHydration) => {
    if (!subAccount) {
      return;
    }

    const {
      accountId,
      name,
      displayName,
      integrationType,
      dataShare,
      coBrand,
      description,
    } = subAccount;

    const account = accountsKeyedById[accountId];

    if (!account) {
      return;
    }

    const leadHydrationConfigs =
      leadHydration.bySubAccountUuid[subAccount.uuid];

    const deduplicationConfigurations =
      originator.deduplicationConfigurations.filter(
        (dc) => dc.supplySubAccountUuid === subAccount.uuid
      );
    const leadDeduplicationEnabled = deduplicationConfigurations.length > 0;
    const leadDeduplication = leadDeduplicationEnabled
      ? deduplicationConfigurations[0].durationMinutes
      : undefined;

    const leadHydrationEnabled =
      leadHydrationConfigs?.length > 0 &&
      leadHydrationConfigs[0].enabledCreditBuckets?.length > 0;
    const leadHydrationCreditBucketsEnabled = leadHydrationConfigs?.length
      ? leadHydrationConfigs[0].enabledCreditBuckets
      : [];

    const secondLookOfferConfiguration = {} as any;

    const formData: SupplySubAccountCreateFormData = {
      account: account as any,
      coBrand,
      dataShare: dataShare as DataShareType,
      description,
      displayName,
      integrationType: integrationType!,
      leadDeduplication,
      leadDeduplicationEnabled,
      leadHydrationCreditBucketsEnabled,
      leadHydrationEnabled,
      lenderRemarketing: false,
      name,
      remarketingEmail: false,
      secondLookOfferConfiguration,
      supplyProductType: subAccount.supplyProductTypes as any,
      transactionalEmail: false,
    };

    return formData;
  }
);
