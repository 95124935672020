import { ProductType } from '@evenfinancial/finance-client';

import type { TopTotalsPanelItem } from '@/components/reporting/top-totals-panel';
import { creditCardFunnelPanelConfig } from '@/resources/channels/performance-summary/panel-configs/credit-card/funnel';
import { lifeInsuranceFunnelPanelConfig } from '@/resources/channels/performance-summary/panel-configs/life-insurance/funnel';
import { loansFunnelPanelConfig } from '@/resources/channels/performance-summary/panel-configs/loans/funnel';
import { savingsFunnelPanelConfig } from '@/resources/channels/performance-summary/panel-configs/savings/funnel';
import { commonEarningsPanelConfig } from '@/resources/channels/performance-summary/panel-configs/shared/earnings';
import type { ContractApplicationType } from '@/store/demand-contracts/types';

export const earningsPanelConfig = (
  productType: ProductType
): TopTotalsPanelItem[] => {
  switch (productType) {
    case ProductType.Loan:
    case ProductType.Savings:
    case ProductType.CreditCard:
    case ProductType.LifeInsurance:
      return commonEarningsPanelConfig;

    default:
      return [];
  }
};

export const funnelPanelConfig = (
  productType: ProductType,
  contractApplicationType: ContractApplicationType
): TopTotalsPanelItem[] => {
  switch (productType) {
    case ProductType.Loan:
      return loansFunnelPanelConfig(contractApplicationType);

    case ProductType.Savings:
      return savingsFunnelPanelConfig;

    case ProductType.CreditCard:
      return creditCardFunnelPanelConfig;

    case ProductType.LifeInsurance:
      return lifeInsuranceFunnelPanelConfig;

    default:
      return [];
  }
};
