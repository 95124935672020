import * as React from 'react';

import classNames from 'classnames';

interface PortalFormErrorProps {
  className?: string;
  error: string | null;
}

const PortalFormError: React.FC<PortalFormErrorProps> = ({
  error,
  className,
}) => {
  return (
    <>
      {error && (
        <p
          className={classNames('portal-form-field-error', className, {
            'is-visible': error,
          })}
        >
          {error}
        </p>
      )}
    </>
  );
};

export default PortalFormError;
