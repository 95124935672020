"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWhitelistConfig = void 0;
const permissions_1 = require("../permissions");
const getWhitelistConfig = (whitelist) => {
    let config = {};
    if (whitelist.has('waiveImplementationFee')) {
        config = Object.assign(Object.assign({}, config), permissions_1.waiveImplementationFeeConfig);
    }
    if (whitelist.has('firstRolloutOnboarding')) {
        config = Object.assign(Object.assign({}, config), permissions_1.firstRolloutOnboardingConfig);
    }
    return config;
};
exports.getWhitelistConfig = getWhitelistConfig;
