import axios from 'axios';
import flow from 'lodash/flow';

import { ErrorType } from '@portal/api-models';
import { getPortalError } from '@portal/common';
import { redact } from '@portal/common/dist/logging/utils';

import { LoggerFactory } from '@/utils/logger';
import type { Logger } from '@/utils/logger/logger';

import { getEnv } from '../envWrapper';

const redirectDeactivatedUserInterceptor = async (err: unknown) => {
  const { customError } = getPortalError(err);

  if (
    typeof window !== 'undefined' &&
    customError === ErrorType.deactivatedUser
  ) {
    window.location.href = '/deactivated';
  }

  return Promise.reject(err);
};

const logErrorInterceptor = (err: unknown, logger: Logger) => {
  const redactedError = redact(err);

  logger.error(redactedError);

  return redactedError;
};

export const createErrorInterceptor =
  (logger: Logger) => async (err: unknown) =>
    flow(logErrorInterceptor, redirectDeactivatedUserInterceptor)(err, logger);

export const cmsPublicRestClientFactoryFn = () => {
  const config = getEnv();

  const client = axios.create({
    baseURL: config.EVEN_CMS_URL,
  });

  client.interceptors.response.use(
    undefined,
    createErrorInterceptor(LoggerFactory('CMSPublicRestClient', config))
  );

  return client;
};
