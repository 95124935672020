"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTierPermissions = void 0;
const api_models_1 = require("@portal/api-models");
const permissions_1 = require("../permissions");
const getTierPermissions = (roles) => {
    if (roles.has(api_models_1.Roles.Starter)) {
        return permissions_1.starterTierConfig;
    }
    if (roles.has(api_models_1.Roles.StarterLegacy)) {
        return permissions_1.starterLegacyTierConfig;
    }
    if (roles.has(api_models_1.Roles.Growth)) {
        return permissions_1.growthTierConfig;
    }
    if (roles.has(api_models_1.Roles.Scale)) {
        return permissions_1.scaleTierConfig;
    }
    return {};
};
exports.getTierPermissions = getTierPermissions;
