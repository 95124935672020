"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.userReducer = exports.initialUserState = void 0;
var immer_1 = require("immer");
var typesafe_actions_1 = require("typesafe-actions");
var access_control_1 = require("@portal/common/dist/access-control");
var store_utils_1 = require("@portal/common/dist/utils/store-utils");
var actions_1 = require("../actions");
var hydrate_1 = require("../hydrate");
var utils_1 = require("../utils");
var actions_2 = require("./actions");
exports.initialUserState = {
    accessByUuid: {},
    accessTokenById: {},
    accessTokenByUuid: {},
    byUuid: {},
    contactUsRequest: [],
    createDrawerVisible: false,
    deactivationRows: [],
    duplicateUuid: '',
    featureFlags: [],
    hasMultipleUserExperiences: false,
    haveAllUsersAccessBeenRequested: false,
    haveAllUsersBeenRequested: false,
    haveAllUsersDeactivationBeenRequested: false,
    permissions: access_control_1.permissionConfigDefaults,
    rows: [],
    rowsByUuid: {},
    whitelist: [],
};
exports.userReducer = (0, typesafe_actions_1.createReducer)(exports.initialUserState)
    .handleAction(actions_1.resetStore, function (state) {
    return (0, immer_1.produce)(state, function (draftState) {
        draftState = exports.initialUserState;
    });
})
    .handleAction(actions_2.setClaims, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.claims = payload;
    });
})
    .handleAction(actions_2.setProfileMeta, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.engineUser = payload.engineUser;
        draftState.featureFlags = payload.featureFlags;
        draftState.requestedTier = payload.requestedTier;
        draftState.roles = payload.roles;
        draftState.scopes = payload.scopes;
        draftState.whitelist = payload.whitelist;
        draftState.permissions = (0, access_control_1.getPermissions)(payload);
        draftState.profileMeta = payload;
        draftState.hasMultipleUserExperiences =
            payload.hasMultipleUserExperiences;
    });
})
    .handleAction(actions_2.userRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.haveAllUsersBeenRequested = true;
        draftState.rows = payload;
    });
})
    .handleAction(actions_2.userAccessRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.haveAllUsersAccessBeenRequested = true;
        payload.forEach(function (access) {
            draftState.accessByUuid[access.userUuid] = access;
        });
    });
})
    .handleAction(actions_2.userByUuidRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        var _a = (0, store_utils_1.replaceByUuidWithIndexes)(state.rows, [payload]), indexes = _a.indexes, rows = _a.rows;
        draftState.byUuid[payload.uuid] = payload;
        draftState.rows = rows;
        draftState.rowsByUuid[payload.uuid] = indexes[0];
    });
})
    .handleAction(actions_2.userAccessByUuidRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.accessByUuid[payload.userUuid] = payload;
    });
})
    .handleAction(actions_2.toggleCreateUser, function (state) {
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.duplicateUuid = '';
        draftState.createDrawerVisible = !state.createDrawerVisible;
    });
})
    .handleAction(actions_2.toggleDuplicateOpen, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.duplicateUuid = payload;
        draftState.createDrawerVisible = !state.createDrawerVisible;
    });
})
    .handleAction(actions_2.richAccessTokenByUuidRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.accessTokenByUuid = __assign(__assign({}, draftState.accessTokenByUuid), payload);
    });
})
    .handleAction(actions_2.richAccessTokenByIdRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.accessTokenById[payload.id] = payload.richAccessToken;
    });
})
    .handleAction(actions_2.userDeactivationRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.deactivationRows = payload;
        draftState.haveAllUsersDeactivationBeenRequested = true;
    });
})
    .handleAction(actions_2.createUserDeactivationsAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.deactivationRows = __spreadArray(__spreadArray([], state.deactivationRows, true), payload, true);
    });
})
    .handleAction(actions_2.deleteUserDeactivationAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.deactivationRows = (0, store_utils_1.replaceByIdFromArray)(state.deactivationRows, payload);
    });
})
    .handleAction(actions_2.sendUserDemandContactUsRequestEmailAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        if (state === null || state === void 0 ? void 0 : state.contactUsRequest) {
            draftState.contactUsRequest = __spreadArray(__spreadArray([], state.contactUsRequest, true), [payload], false);
        }
        else {
            draftState.contactUsRequest = [payload];
        }
    });
})
    .handleAction(hydrate_1.nextHydrate, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), (0, utils_1.reconcileState)(payload.user, exports.initialUserState)));
});
