import { HYDRATE } from 'next-redux-wrapper';
import type { Reducer } from 'redux';
import type { ActionType } from 'typesafe-actions';

import type { deleteAccessTokenByUuid } from '@portal/store/dist/user';
import { UserActionType } from '@portal/store/dist/user/actionTypes';

import { RESET_STORE } from '@/store/actions';
import type { GlobalAction } from '@/store/actions';
import {
  reconcileState,
  replaceById,
  replaceByIdFromArray,
} from '@/store/utils';

import {
  DemandSubAccountActionType,
  SubAccountSharedActionType,
  SupplySubAccountActionType,
} from './actionTypes';
import type { SubAccountAction, SubAccountState } from './types';

export const initialState: SubAccountState = {
  applicationTokenCreateVisible: false,
  byUuid: {},
  demand: [],
  demandCreateVisible: false,
  demandDeactivationRows: [],
  demandSupplyPartnersByUuid: {},
  duplicateUuid: '',
  haveAllDeactivationDemandRowsBeenRequested: false,
  haveAllDeactivationSupplyRowsBeenRequested: false,
  haveAllDemandSubAccountsBeenRequested: false,
  haveAllSupplySubAccountsBeenRequested: false,
  loadingByUuid: false,
  supply: [],
  supplyCreateVisible: false,
  supplyDeactivationRows: [],
  viewData: {},
};

export const reducer: Reducer<
  SubAccountState,
  SubAccountAction | GlobalAction
> = (
  state: SubAccountState = initialState,
  action:
    | SubAccountAction
    | GlobalAction
    | ActionType<typeof deleteAccessTokenByUuid>
) => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...reconcileState(action.payload.subAccounts, initialState),
      };
    }

    case RESET_STORE: {
      return {
        ...initialState,
      };
    }

    case SupplySubAccountActionType.GET_ALL_SUCCESS: {
      return {
        ...state,
        haveAllSupplySubAccountsBeenRequested: true,
        supply: action.payload,
      };
    }
    case SupplySubAccountActionType.TOGGLE_CREATE: {
      return {
        ...state,
        duplicateUuid: '',
        supplyCreateVisible: !state.supplyCreateVisible,
      };
    }
    case SupplySubAccountActionType.TOGGLE_DUPLICATE_OPEN: {
      return {
        ...state,
        duplicateUuid: action.payload,
        supplyCreateVisible: !state.supplyCreateVisible,
      };
    }
    case SupplySubAccountActionType.APPLICATION_TOKEN_TOGGLE_CREATE: {
      return {
        ...state,
        applicationTokenCreateVisible: !state.applicationTokenCreateVisible,
      };
    }
    case SupplySubAccountActionType.APPLICATIONS_BY_UUID_SUCCESS: {
      return {
        ...state,
        viewData: {
          ...state.viewData,
          [action.payload.uuid]: {
            accessTokens: action.payload.accessTokens,
            availableScopes: action.payload.availableScopes,
            partnerKeys: action.payload.partnerKeys,
            primaryApplication: action.payload.primaryApplication,
          },
        },
      };
    }
    case UserActionType.DELETE_TOKEN_BY_UUID_SUCCESS: {
      return {
        ...state,
        viewData: {
          ...state.viewData,
          [action.payload.subAccountUuid]: {
            ...state.viewData[action.payload.subAccountUuid],
            accessTokens: [
              ...state.viewData[
                action.payload.subAccountUuid
              ].accessTokens.filter(
                (token) => token.uuid !== action.payload.uuid
              ),
            ],
          },
        },
      };
    }
    case SupplySubAccountActionType.APPLICATION_TOKEN_CREATE_SUCCESS_CLIENT: {
      return {
        ...state,
        viewData: {
          ...state.viewData,
          [action.payload.uuid]: {
            ...state.viewData[action.payload.uuid],
            accessTokens: [
              ...state.viewData[action.payload.uuid].accessTokens,
              ...Object.values(action.payload.tokens),
            ],
            createdApiTokens: action.payload.tokens,
          },
        },
      };
    }
    case SupplySubAccountActionType.APPLICATION_TOKEN_CONFIRM: {
      return {
        ...state,
        viewData: {
          ...state.viewData,
          [action.payload.uuid]: {
            ...state.viewData[action.payload.uuid],
            createdApiTokens: undefined,
          },
        },
      };
    }
    case DemandSubAccountActionType.TOGGLE_CREATE: {
      return {
        ...state,
        demandCreateVisible: !state.demandCreateVisible,
        duplicateUuid: '',
      };
    }
    case DemandSubAccountActionType.TOGGLE_DUPLICATE: {
      return {
        ...state,
        demandCreateVisible: !state.demandCreateVisible,
        duplicateUuid: action.payload,
      };
    }
    case DemandSubAccountActionType.GET_ALL_SUCCESS: {
      return {
        ...state,
        demand: action.payload,
        haveAllDemandSubAccountsBeenRequested: true,
      };
    }
    case DemandSubAccountActionType.GET_ALL_LENDERS_SUCCESS: {
      return {
        ...state,
        demand: action.payload,
        haveAllDemandSubAccountsBeenRequested: false,
      };
    }
    case DemandSubAccountActionType.GET_ALL_MIGRATED_LENDERS_SUCCESS: {
      return {
        ...state,
        demand: action.payload,
        haveAllDemandSubAccountsBeenRequested: false,
      };
    }
    case DemandSubAccountActionType.UPDATE_SUCCESS: {
      return {
        ...state,
        demand: replaceById(state.demand, action.payload),
      };
    }
    case DemandSubAccountActionType.SUPPLY_PARTNERS_BY_UUID_SUCCESS: {
      return {
        ...state,
        demandSupplyPartnersByUuid: {
          ...state.demandSupplyPartnersByUuid,
          [action.payload.uuid]: action.payload.partners,
        },
      };
    }
    case SubAccountSharedActionType.BY_UUID_REQUEST: {
      return {
        ...state,
        loadingByUuid: true,
      };
    }
    case SubAccountSharedActionType.BY_UUID_SUCCESS: {
      return {
        ...state,
        byUuid: {
          ...state.byUuid,
          [action.payload.uuid]: action.payload,
        },
        loadingByUuid: false,
      };
    }
    case SubAccountSharedActionType.COMPLIANCE_ITEMS_BY_UUID_SUCCESS: {
      return {
        ...state,
        byUuid: {
          ...state.byUuid,
          [action.payload.uuid]: {
            ...state.byUuid[action.payload.uuid],
            complianceItems: action.payload.complianceItems,
          },
        },
      };
    }

    case DemandSubAccountActionType.CREATE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_SUCCESS: {
      return {
        ...state,
        demandDeactivationRows: [
          ...state.demandDeactivationRows,
          ...action.payload,
        ],
      };
    }
    case DemandSubAccountActionType.GET_ALL_DEMAND_SUB_ACCOUNT_DEACTIVATION_SUCCESS: {
      return {
        ...state,
        demandDeactivationRows: action.payload,
        haveAllDeactivationDemandRowsBeenRequested: true,
      };
    }
    case DemandSubAccountActionType.DELETE_DEMAND_SUB_ACCOUNT_DEACTIVATIONS_SUCCESS: {
      return {
        ...state,
        demandDeactivationRows: replaceByIdFromArray(
          state.demandDeactivationRows,
          action.payload
        ),
      };
    }
    case SupplySubAccountActionType.CREATE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_SUCCESS: {
      return {
        ...state,
        supplyDeactivationRows: [
          ...state.supplyDeactivationRows,
          ...action.payload,
        ],
      };
    }
    case SupplySubAccountActionType.GET_ALL_SUPPLY_SUB_ACCOUNT_DEACTIVATION_SUCCESS: {
      return {
        ...state,
        haveAllDeactivationSupplyRowsBeenRequested: true,
        supplyDeactivationRows: action.payload,
      };
    }
    case SupplySubAccountActionType.DELETE_SUPPLY_SUB_ACCOUNT_DEACTIVATIONS_SUCCESS: {
      return {
        ...state,
        supplyDeactivationRows: replaceByIdFromArray(
          state.supplyDeactivationRows,
          action.payload
        ),
      };
    }
    default: {
      return state;
    }
  }
};
