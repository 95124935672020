import type { InitConfiguration } from '@datadog/browser-core';

import { FormatPortalLog } from '@portal/common/dist/logging/format-portal-log';
import { FormatType } from '@portal/common/dist/logging/types';
import type { ConfigState } from '@portal/store/dist/configuration/types';

import { getDDBaseConfig } from '@/services/datadog';
import { Logger } from '@/utils/logger/logger';
import type { Transport } from '@/utils/logger/transport';
import { ConsoleTransport } from '@/utils/logger/transport.console';
import { DataDogTransport } from '@/utils/logger/transport.datadog';

export const getTransports = (
  name: string,
  datadogConfig: InitConfiguration,
  isServer: boolean
): Transport[] => {
  const { clientToken, env, service, version } = datadogConfig;

  const transports: Transport[] = [];

  const formatterOptions = {
    ddEnv: env,
    ddService: service,
    ddVersion: version,
  };

  if (isServer || env !== 'prod') {
    const format = new FormatPortalLog({
      ...formatterOptions,
      format: FormatType.String,
    });

    transports.push(new ConsoleTransport({ format, name }));
  }

  if (clientToken && env && !isServer) {
    const format = new FormatPortalLog({
      ...formatterOptions,
      format: FormatType.JSON,
    });

    transports.push(new DataDogTransport({ format, name }));
  }

  return transports;
};

export const LoggerFactory = (name: string, config: ConfigState) => {
  const baseDDConfig = getDDBaseConfig(config);

  const transports = getTransports(
    name,
    baseDDConfig,
    typeof window === 'undefined'
  );

  return new Logger({ name, transports });
};
