import { message } from 'antd';
import type { AxiosResponse } from 'axios';
import {
  all,
  call,
  fork,
  put,
  putResolve,
  select,
  takeEvery,
} from 'redux-saga/effects';
import type { ActionType } from 'typesafe-actions';

import { client } from '@portal/api-client';
import type { PortalBillingCycle } from '@portal/api-models';

import type { ApplicationState } from '..';

import * as actions from './actions';
import { BillingCycleActionType } from './types';

function* getBillingCycle() {
  try {
    const { data }: AxiosResponse<PortalBillingCycle[]> = yield call(
      client.get,
      '/finance/billing-cycles',
      {
        params: { limit: 1e5 },
      }
    );

    yield put(actions.billingCycleRequestAction.success(data));
  } catch (err: any) {
    yield put(actions.billingCycleRequestAction.failure(err));
  }
}

function* createBillingCycle(
  action: ActionType<typeof actions.billingCycleCreateRequestAction.request>
) {
  const formParams = action.payload;

  const oldActive = yield select(({ billingCycles }: ApplicationState) =>
    billingCycles.rows.filter(
      (bc) =>
        bc.accountUuid === formParams.accountUuid &&
        !bc.endDate &&
        bc.ledgerItemType === formParams.ledgerItemType
    )
  );

  try {
    for (const oldBc of oldActive) {
      yield putResolve(
        actions.billingCycleEndRequestAction.request({ id: oldBc.id })
      );
    }

    const { data: newBillingCycle }: AxiosResponse<PortalBillingCycle> =
      yield call(
        client.post,
        `/finance/billing-cycles/accounts/${formParams.accountUuid}/`,
        {
          ...formParams,
          effectiveAt: formParams?.effectiveAt?.toISOString().split('T')[0],
          ledgerItemType: formParams.ledgerItemType,
          netD: parseInt(formParams?.netD ?? '', 10),
        }
      );

    yield put(actions.billingCycleCreateRequestAction.success(newBillingCycle));
    yield put(actions.toggleCreate());
    setTimeout(
      () => message.success('The billing cycle was created successfully'),
      100
    );
  } catch (err: any) {
    yield put(actions.billingCycleCreateRequestAction.failure(err));
  }
}

function* endBillingCycle(
  action: ActionType<typeof actions.billingCycleEndRequestAction.request>
) {
  try {
    const { data: updated }: AxiosResponse<PortalBillingCycle> = yield call(
      client.post,
      `/finance/billing-cycles/${action.payload.id}/end`,
      null
    );

    yield put(actions.billingCycleEndRequestAction.success(updated));
    setTimeout(
      () => message.success('The billing cycle was deactivated successfully'),
      100
    );
  } catch (err: any) {
    yield put(actions.billingCycleEndRequestAction.failure(err));
  }
}

function* deleteBillingCycle(
  action: ActionType<typeof actions.billingCycleDeleteRequestAction.request>
) {
  try {
    yield call(client.delete, `/finance/billing-cycles/${action.payload.id}`);

    yield put(
      actions.billingCycleDeleteRequestAction.success({ id: action.payload.id })
    );
    setTimeout(
      () => message.success('The billing cycle was deleted successfully'),
      100
    );
  } catch (err: any) {
    yield put(actions.billingCycleDeleteRequestAction.failure(err));
  }
}

function* watchGetAllRequest() {
  yield takeEvery(BillingCycleActionType.GET_ALL_REQUEST, getBillingCycle);
}

function* watchCreateRequest() {
  yield takeEvery(
    BillingCycleActionType.CREATE_REQUEST_CLIENT,
    createBillingCycle
  );
}

function* watchEndRequest() {
  yield takeEvery(BillingCycleActionType.END_REQUEST_CLIENT, endBillingCycle);
}

function* watchDeleteRequest() {
  yield takeEvery(
    BillingCycleActionType.DELETE_REQUEST_CLIENT,
    deleteBillingCycle
  );
}

export function* billingCycleSaga() {
  yield all([
    fork(watchGetAllRequest),
    fork(watchCreateRequest),
    fork(watchEndRequest),
    fork(watchDeleteRequest),
  ]);
}
