"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryKey = void 0;
var QueryKey;
(function (QueryKey) {
    QueryKey["AccountMetadata"] = "accountMetadata";
    QueryKey["AccountOwners"] = "accountOwners";
    QueryKey["Accounts"] = "accounts";
    QueryKey["ActivePartnerFeatures"] = "activePartnerFeatures";
    QueryKey["CommunicationEligibilities"] = "communicationEligibilities";
    QueryKey["CurrentUserAccount"] = "currentUserAccount";
    QueryKey["DemandContracts"] = "demandContracts";
    QueryKey["MciConversionsReports"] = "mciConversionsReports";
    QueryKey["MciConversionsReportsMostRecent"] = "mciConversionsReportsMostRecent";
    QueryKey["MciOffersReports"] = "mciOffersReports";
    QueryKey["MciOffersReportsMostRecent"] = "mciOffersReportsMostRecent";
    QueryKey["MciRejectionsReports"] = "mciRejectionsReports";
    QueryKey["MciRejectionsReportsMostRecent"] = "mciRejectionsReportsMostRecent";
    QueryKey["PerformanceSummaryFunnel"] = "performanceSummaryFunnel";
    QueryKey["PerformanceSummaryPayout"] = "performanceSummaryPayout";
    QueryKey["SampleDemandContractFinancials"] = "sampleDemandContractFinancials";
    QueryKey["SubAccounts"] = "subAccounts";
    QueryKey["SupplyContracts"] = "supplyContracts";
})(QueryKey = exports.QueryKey || (exports.QueryKey = {}));
