import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import type { Action } from 'typesafe-actions';

import { ErrorType } from '@portal/api-models';
import { Toaster } from '@portal/ui-lib';

import { SupplyMigrationActionType } from '@/store/supply-migration/types';

import type { ApplicationState } from '..';
import type { ConfigState } from '../config';

import * as actions from './actions';
import type { OverlayLoaderType } from './types';

function* setLoadingStart() {
  const config: ConfigState = yield select(
    (state: ApplicationState) => state.config
  );

  if (config.IS_SERVER) {
    return;
  }

  yield put(actions.loadingStart());
}

function* setLoadingEnd() {
  yield put(actions.loadingEnd());
}

function* setOverlayLoading(overlay: OverlayLoaderType) {
  yield put(actions.setOverlayLoading(overlay));
}

function* unsetOverlayLoading() {
  yield put(actions.setOverlayLoading());
}

function* notifyFailure(action: ReturnType<typeof actions.error>) {
  const { customError = ErrorType.default } = action.payload;

  yield call([Toaster, 'error'], customError);
}

function* notifySuccess(action: ReturnType<typeof actions.success>) {
  yield call([Toaster, 'success'], action.payload);
}

function* setOverlayLoadingPartnerPage() {
  yield* setOverlayLoading({
    content: 'Please wait, creating an integration may take up to 15 seconds',
  });
}

function* watchCreateIntegrationStart() {
  yield takeEvery(
    (action: Action) => /CREATE_INTEGRATION_REQUEST$/m.test(action.type),
    setOverlayLoadingPartnerPage
  );
}

function* watchCreateIntegrationEnd() {
  yield takeEvery(
    (action: Action) =>
      /CREATE_INTEGRATION_(?:SUCCESS|FAILURE)$/m.test(action.type),
    unsetOverlayLoading
  );
}

function* watchMigrationFlow() {
  while (true) {
    yield take(SupplyMigrationActionType.START_REQUEST);
    yield put(
      actions.setOverlayLoading({
        content: 'Upgrade in progress, this may take up to 30 seconds',
      })
    );
    yield take([
      SupplyMigrationActionType.START_SUCCESS,
      SupplyMigrationActionType.START_FAILURE,
    ]);
    yield put(actions.setOverlayLoading());
  }
}

function* watchLoading() {
  yield takeEvery(
    (action: Action) => /_REQUEST$|_REQUEST_CLIENT/m.test(action.type),
    setLoadingStart
  );
}

function* watchLoadingEnd() {
  yield takeEvery(
    (action: Action) => /_(?:SUCCESS|FAILURE)$|_CLIENT/m.test(action.type),
    setLoadingEnd
  );
}

function* watchClientFailure() {
  yield takeEvery(
    (action: Action) => /_FAILURE_CLIENT/.test(action.type),
    notifyFailure
  );
}

function* watchClientError() {
  yield takeEvery(getType(actions.error), notifyFailure);
}

function* watchSuccess() {
  yield takeEvery(getType(actions.success), notifySuccess);
}

export function* messageSaga() {
  yield all([
    fork(watchCreateIntegrationStart),
    fork(watchCreateIntegrationEnd),
    fork(watchMigrationFlow),
    fork(watchLoading),
    fork(watchLoadingEnd),
    fork(watchClientFailure),
    fork(watchClientError),
    fork(watchSuccess),
  ]);
}
