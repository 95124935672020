import * as React from 'react';

import { Table } from 'antd';
import type { TableProps } from 'antd/lib/table/Table';
import classnames from 'classnames';

export interface CardTableProps extends TableProps<any> {}

export const CardTable: React.FC<CardTableProps> = ({
  className,
  ...props
}) => {
  return (
    <Table
      bordered={false}
      className={classnames('card-table', className)}
      {...props}
    />
  );
};
