import * as React from 'react';

import { faCircleDollar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import numeral from 'numeral';

import {
  ApplicationsIcon,
  ClicksIcon,
  FundedIcon,
  LeadsIcon,
  PreApprovedIcon,
} from '@portal/ui-lib';

import type { TopTotalsPanelItem } from '@/components/reporting/top-totals-panel';
import { ContractApplicationType } from '@/store/demand-contracts/types';

const loansFunnelField: Record<string, TopTotalsPanelItem> = {
  attributedEarnings: {
    formatter: (value) => numeral(value).format('$0,0.00'),
    icon: <FontAwesomeIcon icon={faCircleDollar} />,
    key: 'totalPayout',
    title: 'Attributed Earnings',
    tooltip:
      'Estimated value of payout based on the leads attributed to the dates in the report. Please note that Attributed Earnings will vary from your Total Earnings value as loan applications often take several days for a consumer to fund. You can view your actual payout at any time in the Earnings report under “Total Earnings.”',
  },
  completedApplications: {
    icon: <ApplicationsIcon />,
    key: 'numLeadsCompletedApp',
    title: 'Completed Applications',
    tooltip:
      'Count of unique leads directed to a landing page either via preview or application step. As you view lead metrics, please note that if the same lead comes in through desktop and mobile, it will be counted as two leads',
  },
  fundedLoans: {
    icon: <FundedIcon />,
    key: 'numLeadsFunded',
    title: 'Funded Loans',
    tooltip: 'Total count of funded loans from all lending partners',
  },
  leads: {
    icon: <LeadsIcon />,
    key: 'numLeads',
    title: 'Leads',
    tooltip:
      'Count of unique leads directed to a landing page either via preview or application step. As you view lead metrics, please note that if the same lead comes in through desktop and mobile, it will be counted as two leads',
  },
  preApprovedApplications: {
    icon: <PreApprovedIcon />,
    key: 'numLeadsPreapproved',
    title: 'Pre-Approved Applications',
    tooltip:
      'Count of leads who receive one or multiple pre-approved and/or pre-qualified loan offers from lending partners',
  },
  submittedApplications: {
    icon: <ApplicationsIcon />,
    key: 'numLeadsSubmittedApp',
    title: 'Submitted Applications',
    tooltip:
      'Count of leads who fill out all fields and submit an application (e.g., user clicks “Get Offers” button)',
  },
  uniqueOfferClicks: {
    icon: <ClicksIcon />,
    key: 'numLeadsClicked',
    title: 'Unique Offer Clicks',
    tooltip:
      'Total count of unique clicks across loan offers and secondary offers',
  },
};

export const loansFunnelPanelConfig = (
  contractApplicationType: ContractApplicationType
): TopTotalsPanelItem[] => {
  const isCompletedApplication =
    contractApplicationType === ContractApplicationType.CompletedApplication;

  const isSubmittedApplication =
    contractApplicationType === ContractApplicationType.SubmittedApplication;

  const config = [loansFunnelField.leads];

  if (isCompletedApplication) {
    config.push(loansFunnelField.completedApplications);
  }

  if (isSubmittedApplication) {
    config.push(
      loansFunnelField.submittedApplications,
      loansFunnelField.preApprovedApplications,
      loansFunnelField.uniqueOfferClicks
    );
  }

  config.push(
    loansFunnelField.fundedLoans,
    loansFunnelField.attributedEarnings
  );

  return config;
};
