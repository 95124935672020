import * as React from 'react';

import { faChartLine, faCube } from '@fortawesome/pro-solid-svg-icons';

import { SidebarIcon } from '@portal/ui-lib';

import type { MenuItemProps } from '@/components/side-nav/menu-items/self-service';
import { RouterAwareMenuItem } from '@/components/side-nav/router-aware-menu-item';

export const IntegrationsMenuItem = ({ disabled, ...props }: MenuItemProps) => (
  <RouterAwareMenuItem
    key="menu-item-channels-integrations"
    disabled={disabled}
    href="/integrations"
    icon={<SidebarIcon icon={faCube} />}
    showDisabledIcon={disabled}
    text="Integrations"
    {...props}
  />
);

export const PerformanceSummaryMenuItem = ({
  disabled,
  ...props
}: MenuItemProps) => (
  <RouterAwareMenuItem
    key="menu-item-performance-summary"
    disabled={disabled}
    href="/performance-summary"
    icon={<SidebarIcon icon={faChartLine} />}
    showDisabledIcon={disabled}
    text="Performance Summary"
    {...props}
  />
);
