"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.creditRatingOrder = exports.GroupByType = void 0;
const originator_client_1 = require("@evenfinancial/originator-client");
var GroupByType;
(function (GroupByType) {
    GroupByType["clientTag"] = "clientTag";
    GroupByType["creditRating"] = "creditRating";
    GroupByType["day"] = "day";
    GroupByType["loanPurpose"] = "loanPurpose";
    GroupByType["month"] = "month";
    GroupByType["referralUrl"] = "referralUrl";
    GroupByType["week"] = "week";
})(GroupByType = exports.GroupByType || (exports.GroupByType = {}));
exports.creditRatingOrder = {
    [originator_client_1.ProvidedCreditRating.Excellent]: 2,
    [originator_client_1.ProvidedCreditRating.Fair]: 4,
    [originator_client_1.ProvidedCreditRating.Good]: 3,
    [originator_client_1.ProvidedCreditRating.Limited]: 6,
    [originator_client_1.ProvidedCreditRating.Poor]: 5,
    [originator_client_1.ProvidedCreditRating.Unknown]: 1,
};
