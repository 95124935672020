import * as React from 'react';
import { Component } from 'react';

import { Form } from '@ant-design/compatible';
import type {
  FormProps,
  GetFieldDecoratorOptions,
  WrappedFormUtils,
} from '@ant-design/compatible/lib/form/Form';
import type { FormItemProps } from '@ant-design/compatible/lib/form/FormItem';
import { faCircleQuestion } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tabs, Tooltip } from 'antd';
import classNames from 'classnames';
import { isEmpty, startCase } from 'lodash';

import ConfirmableAction from '@/components/confirmable-action';
import type { ConfirmableActionProps } from '@/components/confirmable-action';

import LegacyPortalFormElement from './components/portal-form-element';

import '@ant-design/compatible/assets/index.css';

/**
 * @deprecated
 */
export interface LegacyPortalFormValidationErrorMessage {
  field: string;
  message: string;
}

/**
 * @deprecated
 */
export interface LegacyPortalFormValidationError {
  errors: LegacyPortalFormValidationErrorMessage[];
}

/**
 * @deprecated
 */
export interface LegacyPortalFormValidationErrors {
  [key: string]:
    | LegacyPortalFormValidationError
    | LegacyPortalFormValidationErrors;
}

/**
 * @deprecated
 */
export interface LegacyPortalFormItem<T = any> {
  after?: ((formState: T) => React.ReactFragment) | React.ReactFragment;
  before?: ((formState: T) => React.ReactFragment) | React.ReactFragment;
  fieldDecoratorProps?: GetFieldDecoratorOptions;
  fieldElement?: React.ReactFragment;
  itemProps?: FormItemProps;
  name: string;
  // In what order should we show the field?
  order?: number;

  // Should we show this field based on the current form state?
  predicate?: (formState: T) => boolean;

  tooltip?: string;
}

type FormEffect<T> = (form: T) => void;

export interface TabOptions {
  initialTab?: string;
  onTabChange?: (selectedKey: string) => void;
  tabBadgeByKey?: { [key: string]: React.ReactElement | React.ReactFragment };
}

/**
 * @deprecated
 */
export interface LegacyPortalFormProps<TFormType> {
  additionalActions?: React.ReactFragment;

  className?: string;
  confirmationProps?: Omit<Partial<ConfirmableActionProps<{}>>, 'action'>;

  // Effects
  createRequest?: FormEffect<TFormType>;

  disableSubmitButton?: boolean;
  enableConfirmation?: boolean;

  fields?: LegacyPortalFormItem[];

  form: WrappedFormUtils;
  formProps?: FormProps;

  hideCancel?: boolean;
  // Actions
  onCancel?: () => void;
  onValidateFail?: (
    errors: LegacyPortalFormValidationErrors,
    values: TFormType
  ) => void;
  submitButtonTitle?: string;

  tabOptions?: TabOptions;
  tabs?: { [key: string]: Array<LegacyPortalFormItem<TFormType>> };
  // replaces the need for setting timeouts in componentDidMount to forceUpdate and re-fill form as can be unreliable. Here until can investigate faster rendering of portal-form.
  temp_onDidMount?: () => void;

  updateRequest?: FormEffect<TFormType>;
  validator?: (values: TFormType) => boolean;
}

/**
 * @deprecated
 */
interface LegacyPortalFormState {
  allowAllFields: boolean;
  timeoutID: number | undefined;
}

/**
 * A wrapper around the antd form element, with common styles.
 *
 * @export
 * @class LegacyPortalForm
 * @extends {Component<LegacyPortalFormProps<TFormType>>}
 * @template TFormType
 * @deprecated
 */
export default class LegacyPortalForm<TFormType> extends Component<
  LegacyPortalFormProps<TFormType>,
  LegacyPortalFormState
> {
  public static defaultProps = {
    confirmationProps: {},
  };

  constructor(props: LegacyPortalFormProps<TFormType>) {
    super(props);

    this.state = {
      allowAllFields: typeof props.temp_onDidMount === 'function', // added b/c complex predicates fail on first render w/ empty intitial state b/c ant forms is lame
      timeoutID: undefined,
    };
  }

  private handleLoadingState = () => {
    const timeoutID = window.setTimeout(
      () => this.setState({ timeoutID: undefined }),
      1000
    );

    this.setState({ timeoutID });
  };

  componentWillUnmount() {
    clearTimeout(this.state.timeoutID);
  }

  public componentDidMount() {
    if (this.props.temp_onDidMount) {
      this.setState({ allowAllFields: false });
      this.props.temp_onDidMount();
    }
  }

  public render() {
    const defaultSubmitButtonTitle = this.props.createRequest
      ? 'Create'
      : 'Update';

    const submitButtonTitle =
      this.props.submitButtonTitle || defaultSubmitButtonTitle;

    const formProps = {
      ...this.props.formProps,
      hideRequiredMark: true,
    };

    return (
      <div className={classNames('legacy-portal-form', this.props.className)}>
        <Form {...formProps}>
          {this.mappedFields}
          <div className="legacy-portal-form-actions">
            {!this.props.hideCancel && (
              <Button key="pf-action-cancel" onClick={this.props.onCancel}>
                Cancel
              </Button>
            )}

            {this.props.enableConfirmation ? (
              <ConfirmableAction
                action={this.onSubmit(this.props.form)}
                okText={submitButtonTitle}
                {...this.props.confirmationProps}
              >
                <Button key="pf-action-primary" type="primary">
                  {submitButtonTitle}
                </Button>
              </ConfirmableAction>
            ) : (
              <Button
                key="pf-action-primary"
                disabled={this.props.disableSubmitButton}
                loading={Boolean(this.state.timeoutID)}
                type="primary"
                onClick={() => {
                  this.onSubmit(this.props.form)();
                  this.handleLoadingState();
                }}
              >
                {submitButtonTitle}
              </Button>
            )}

            {this.props.additionalActions}
          </div>
        </Form>
      </div>
    );
  }

  /**
   * Maps field configs into elements, using `<Input />` as default
   * if there is no `fieldElement` provided.
   *
   * @readonly
   * @private
   * @memberof LegacyPortalForm
   */
  private get mappedFields() {
    // const { fields, tabs, initialTab, onTabChange } = this.props;
    const { fields, tabs, tabOptions } = this.props;

    const currentFieldsValue = this.props.form.getFieldsValue() as TFormType;

    if (fields) {
      return this.filterAndSortFields(currentFieldsValue, fields).map(
        this.mapField(currentFieldsValue)
      );
    }

    if (tabs) {
      const mapped = Object.entries(tabs).map(
        this.mapTabPane(currentFieldsValue)
      );

      return (
        <Tabs
          className="legacy-portal-form-tabs"
          defaultActiveKey={tabOptions && tabOptions.initialTab}
          onChange={tabOptions && tabOptions.onTabChange}
        >
          {mapped}
        </Tabs>
      );
    }

    return null;
  }

  private mapTabPane =
    (currentState: TFormType) =>
    ([name, fields]: [string, Array<LegacyPortalFormItem<TFormType>>]) => {
      const filtered = this.filterAndSortFields(currentState, fields);
      const { tabOptions } = this.props;
      const { tabBadgeByKey } = tabOptions ? tabOptions : { tabBadgeByKey: {} };

      return (
        <Tabs.TabPane
          key={name.toLocaleLowerCase()}
          forceRender
          className="legacy-portal-form-tab-content"
          tab={
            <>
              {tabBadgeByKey && tabBadgeByKey[name]}
              {name}
            </>
          }
        >
          {filtered.map(this.mapField(currentState))}
        </Tabs.TabPane>
      );
    };

  private filterAndSortFields = (
    currentState: TFormType,
    fields: Array<LegacyPortalFormItem<TFormType>>
  ) =>
    fields
      .reduce((acc, item) => {
        if (
          this.state.allowAllFields ||
          !item.predicate ||
          item.predicate(currentState)
        ) {
          acc.push({
            ...item,
            order: typeof item.order === 'undefined' ? acc.length : item.order,
          });
        }

        return acc;
      }, [] as LegacyPortalFormItem[])
      .sort((a, b) => (a.order || 0) - (b.order || 0));

  private mapField =
    (formData: TFormType) => (field: LegacyPortalFormItem, index: number) => {
      const providedLabel = field?.itemProps?.label
        ? field.itemProps.label
        : startCase(field.name);

      const label = (
        <>
          <span className="legacy-portal-form-item-label">{providedLabel}</span>
          {field.tooltip && (
            <Tooltip title={field.tooltip}>
              <FontAwesomeIcon
                className="legacy-portal-form-item-tooltip"
                icon={faCircleQuestion}
              />
            </Tooltip>
          )}
        </>
      );

      const classNames = ['legacy-portal-form-item'];

      if (field.itemProps && field.itemProps.className) {
        classNames.push(field.itemProps.className);
      }

      field.before =
        field.before instanceof Function
          ? field.before(formData)
          : field.before;
      field.after =
        field.after instanceof Function ? field.after(formData) : field.after;

      if (field.fieldDecoratorProps?.rules?.[0]?.required) {
        field.fieldDecoratorProps.rules[0].message =
          field.fieldDecoratorProps.rules[0].message ||
          `${providedLabel} is required`;
      }

      const key = `${field.name}-${index}`;

      return (
        <LegacyPortalFormElement
          key={key}
          className={classNames.join(' ')}
          field={field}
          getFieldDecorator={this.props.form.getFieldDecorator}
          itemKey={key}
          label={label}
        />
      );
    };

  /**
   * Validate & submit the form.
   *
   * @private
   * @param {WrappedFormUtils} form
   * @returns
   * @memberof LegacyPortalForm
   */
  private onSubmit(form: WrappedFormUtils) {
    return () => {
      form.validateFields(
        (errors: LegacyPortalFormValidationErrors, values: TFormType) => {
          if (!isEmpty(errors)) {
            if (this.props.onValidateFail) {
              this.props.onValidateFail(errors, values);
            }

            return;
          }

          if (this.props.validator && !this.props.validator(values)) {
            return;
          }

          if (this.props.createRequest) {
            this.props.createRequest(values);
          } else if (this.props.updateRequest) {
            this.props.updateRequest(values);
          }
        }
      );
      this.setState({ timeoutID: undefined });
    };
  }
}
