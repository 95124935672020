import { produce } from 'immer';
import { createReducer } from 'typesafe-actions';

import type {
  ProductType,
  StaticOffer,
} from '@evenfinancial/originator-client';

import { nextHydrate } from '../actions';
import type { HydrateAction } from '../actions';
import { reconcileState, replaceByIdFromArray } from '../utils';

import * as offerActions from './actions';
import type { OfferAction, OfferState } from './types';

export const initialState: OfferState = {
  deactivationRows: [],
  enabledStaticOffers: [],
  haveAllOffersDeactivationBeenRequested: false,
  productOffersByProductType: {},
  staticOffersByProductType: {} as Record<ProductType, StaticOffer[]>,
};

export const reducer = createReducer<OfferState, OfferAction | HydrateAction>(
  initialState
)
  .handleAction(nextHydrate, (state, { payload }) => ({
    ...state,
    ...reconcileState(payload.originator, initialState),
  }))
  .handleAction(
    offerActions.productOfferRequestAction.success,
    (state, { payload: { productType, productOffers } }) =>
      produce(state, (draftState) => {
        draftState.productOffersByProductType = {
          ...state.productOffersByProductType,
          [productType]: {
            haveAllRowsBeenRequested: true,
            rows: productOffers,
          },
        };
      })
  )
  .handleAction(
    offerActions.staticOfferRequestAction.success,
    (state, { payload: { productType, staticOffers } }) =>
      produce(state, (draftState) => {
        draftState.staticOffersByProductType[productType] = staticOffers;
      })
  )
  .handleAction(
    offerActions.enabledStaticOffersRequestAction.success,
    (state, { payload }) =>
      produce(state, (draftState) => {
        draftState.enabledStaticOffers = payload;
      })
  )
  .handleAction(
    offerActions.offerDeactivationRequestAction.success,
    (state, { payload }) =>
      produce(state, (draftState) => {
        draftState.deactivationRows = payload;
        draftState.haveAllOffersDeactivationBeenRequested = true;
      })
  )
  .handleAction(
    offerActions.createOfferDeactivationsAction.success,
    (state, { payload }) =>
      produce(state, (draftState) => {
        draftState.deactivationRows = [...state.deactivationRows, ...payload];
      })
  )
  .handleAction(
    offerActions.deleteOfferDeactivationAction.success,
    (state, { payload }) =>
      produce(state, (draftState) => {
        draftState.deactivationRows = replaceByIdFromArray(
          state.deactivationRows,
          payload
        );
      })
  );
