"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.demandConfig = void 0;
exports.demandConfig = {
    canRequestAccounts: true,
    hasDemandUserRole: true,
    shouldRestrictPersonalLoansDashboardDate: true,
    shouldRestrictUserSettingsEditing: true,
    showAllDemandContracts: true,
    showBenchmarkReportsMenuItemIfFeatureFlag: true,
    showContractsCardIfFeatureFlag: true,
    showDemandHomePage: true,
    showDemandSubAccountsCard: true,
    showDemandUserDetails: true,
    showDemandUserSideNavIfFeatureFlag: true,
    showDemandUsersPageIfFeatureFlag: true,
    showExportButton: true,
    showExternalDemandAccountDetailsPropertyFields: true,
    showHomeMenuItemIfFeatureFlag: true,
    showMCIReportsMenuItemIfFeatureFlag: true,
    showRebrandGuideAndAssetsLink: true,
    showSubAccountNameColumn: true,
};
