import type * as React from 'react';

import type { ColumnProps } from 'antd/lib/table';
import type {
  ColumnFilterItem,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';

import type { SubAccountDropdownProvidedProps } from '@/components/form/sub-account-dropdown';
import type {
  RenderDateOptions,
  RenderLinkOptions,
} from '@/components/table/lib/column-renderers/interface';
import type { TextFilterDropdownOptions } from '@/components/table/lib/filter-dropdowns/text-filter-dropdown';
import type { UuidFilterDropdownOptions } from '@/components/table/lib/filter-dropdowns/uuid-filter-dropdown';

export type OnTableChange<RowType extends {}> = (
  pagination: TablePaginationConfig,
  filters: Record<string, React.ReactText[] | null>,
  sorter: SorterResult<RowType> | SorterResult<RowType>[],
  extra: TableCurrentDataSource<RowType>
) => void;

export enum PortalColumnRenderType {
  Date,
  Link,
  Tag,
  Text,
}

export enum PortalColumnFilterType {
  Dropdown,
  SubAccount,
  Text,
  UUID,
}

type BaseProps<RowType extends {}> = {
  dataIndex?: keyof RowType;
  filterKey?: string;
  filterOptions?: {};
  key: keyof RowType;
  renderOptions?: {};
  title?: string;
};

type RenderProps =
  | {
      renderOptions?: RenderDateOptions;
      renderType?: PortalColumnRenderType.Date;
    }
  | {
      renderOptions?: Omit<RenderLinkOptions, 'key'>;
      renderType?: PortalColumnRenderType.Link;
    }
  | { renderType?: PortalColumnRenderType.Tag }
  | { renderType?: PortalColumnRenderType.Text };

export interface FilterDropdownProps {
  filterOptions?: {
    filters: ColumnFilterItem[];
    multiple?: boolean;
  };
  filterType?: PortalColumnFilterType.Dropdown;
}

export interface FilterSubAccountProps {
  filterOptions?: SubAccountDropdownProvidedProps;
  filterType?: PortalColumnFilterType.SubAccount;
}

export interface FilterTextProps {
  filterOptions?: TextFilterDropdownOptions;
  filterType?: PortalColumnFilterType.Text;
}

export interface FilterUUIDProps {
  filterOptions?: UuidFilterDropdownOptions;
  filterType?: PortalColumnFilterType.UUID;
}

type FilterProps =
  | FilterDropdownProps
  | FilterSubAccountProps
  | FilterTextProps
  | FilterUUIDProps;

export type PortalColumnProps<RowType extends {}> = BaseProps<RowType> &
  RenderProps &
  FilterProps &
  Pick<ColumnProps<RowType>, 'render'>;
