"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.externalConfig = void 0;
exports.externalConfig = {
    hasExternalUserRole: true,
    shouldBeAbleToRedirectToPreselectRules: true,
    shouldGetSubAccountOwners: true,
    shouldRedirectToAccountDetails: true,
    shouldRedirectToReceivableDetails: true,
    shouldUseExternalEntrypointOnIndexPage: true,
    showControlCenterLiteHome: true,
    showExternalAccountDetails: true,
    showExternalSubAccountDetails: true,
    showExternalSupplySubaccountDetails: true,
    showExternalUserPayable: true,
    showExternalUserSideNavIfFeatureFlag: true,
    showFeedbackHeaderItem: true,
};
