import * as React from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

import Alert from 'antd/lib/alert';
import type { AlertProps } from 'antd/lib/alert';
import pick from 'lodash/pick';
import { useRouter } from 'next/router';

import { selectPermissions } from '@portal/store/dist/user/selectors';

import { QueryParams } from '@/components/self-service/home/index-regular';
import { selectIsPaid } from '@/resources/self-service/selects';
import { isPermanentTrial } from '@/resources/supply-migration/helpers';
import { selectSaasMigrationSettings } from '@/resources/supply-migration/selects';
import useOnResize from '@/utils/hooks/use-on-resize';
import { removeParamFromUrl } from '@/utils/router';

interface AlertBarProps {
  onHeightChange(height: number): void;
}

const AlertBar: React.FC<AlertBarProps> = ({ onHeightChange }) => {
  const { query: queryUnpicked } = useRouter();

  const isPaid = useSelector(selectIsPaid);
  const supplyMigration = useSelector(selectSaasMigrationSettings);
  const { showActivateYourPlanWarning, hasCrmUserRole } =
    useSelector(selectPermissions);

  const ref = useRef<HTMLDivElement>(null);

  const query = pick(queryUnpicked, ['upgradedMigrationSuccess']);
  const upgradedMigrationSuccess = Boolean(
    query[QueryParams.UpgradedMigrationSuccess]
  );

  let alert: AlertProps | undefined;
  const isPermanentMigration =
    supplyMigration && isPermanentTrial(supplyMigration);

  if (
    showActivateYourPlanWarning &&
    !hasCrmUserRole &&
    !isPaid &&
    !supplyMigration
  ) {
    alert = {
      message:
        'Activate your plan to unlock the full set of features and go live with your integration',
      type: 'warning',
    };
  }

  if (upgradedMigrationSuccess) {
    alert = {
      closable: true,
      message: isPermanentMigration
        ? 'Permanent Trial - You have Unlimited Permanent Trial'
        : 'You have successfully upgraded to the new version of Control Center',
      onClose: () => {
        removeParamFromUrl(QueryParams.UpgradedMigrationSuccess);
        onHeightChange(0);
      },
      type: 'success',
    };
  }

  useOnResize(() => alert && onHeightChange(ref?.current?.offsetHeight ?? 0));

  if (!alert) {
    return null;
  }

  return (
    <div ref={ref}>
      <Alert {...alert} />
    </div>
  );
};

export default AlertBar;
