import * as React from 'react';
import { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';

import { Skeleton } from 'antd';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import type { LegalAgreementType } from '@portal/common/dist/legal-agreements';
import { cmsLegalAgreementRequestAction } from '@portal/store/dist/cms';
import { selectLegalAgreementByType } from '@portal/store/dist/cms/selectors';

import type { ApplicationState } from '@/store';

interface LegalAgreementProps {
  legalAgreementType: LegalAgreementType;
}

const LegalAgreement: React.FC<LegalAgreementProps> = ({
  legalAgreementType,
}) => {
  const { loading, agreement } = useSelector((state: ApplicationState) =>
    selectLegalAgreementByType(state, legalAgreementType)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      cmsLegalAgreementRequestAction.request({
        agreementType: legalAgreementType,
      })
    );
  }, [dispatch]);

  if (!agreement && !loading) {
    return <div>Something went wrong!</div>;
  }

  return (
    <div className="legal-agreement">
      {!loading && agreement?.content ? (
        <ReactMarkdown
          className="legal-agreement-format"
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkGfm]}
        >
          {agreement.content}
        </ReactMarkdown>
      ) : (
        <>
          <Skeleton active paragraph />
          <Skeleton active paragraph />
          <Skeleton active paragraph />
          <Skeleton active paragraph />
          <Skeleton active paragraph />
        </>
      )}
    </div>
  );
};

export { LegalAgreement };
