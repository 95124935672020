import type { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import type { ActionType } from 'typesafe-actions';

import { client } from '@portal/api-client';
import type {
  TempAvailableDemandPartner,
  TempFeaturedDemandPartner,
} from '@portal/api-models';

import * as actions from './actions';
import { TrustedPartnersActionType } from './types';

export function* getAvailableTrustedPartnersByCompanyUuid(
  action: ActionType<typeof actions.getAvailableTrustedPartnersAction.request>
) {
  const { companyUuid } = action.payload;
  try {
    const { data: response }: AxiosResponse<TempAvailableDemandPartner[]> =
      yield call(
        client.get,
        `/partner-data/available-demand-partners/${companyUuid}`
      );

    yield put(
      actions.getAvailableTrustedPartnersAction.success({
        companyUuid,
        response,
      })
    );
  } catch (err: any) {
    yield put(actions.getAvailableTrustedPartnersAction.failure(err));
  }
}

export function* getFeaturedTrustedPartnersByCompanyUuid(
  action: ActionType<typeof actions.getFeaturedTrustedPartnersAction.request>
) {
  const { companyUuid } = action.payload;
  try {
    const { data: response }: AxiosResponse<TempFeaturedDemandPartner[]> =
      yield call(
        client.get,
        `/partner-data/featured-demand-partners/${companyUuid}`
      );

    yield put(
      actions.getFeaturedTrustedPartnersAction.success({
        companyUuid,
        response,
      })
    );
  } catch (err: any) {
    yield put(actions.getFeaturedTrustedPartnersAction.failure(err));
  }
}

export function* setFeaturedTrustedPartnersForCompanyUuid(
  action: ActionType<typeof actions.setFeaturedTrustedPartnersAction.request>
) {
  const { companyUuid, demandSubAccountIds } = action.payload;
  try {
    const { data: response }: AxiosResponse<TempFeaturedDemandPartner[]> =
      yield call(
        [client, 'post'],
        `/partner-data/featured-demand-partners/${companyUuid}`,
        { demandSubAccountIds }
      );

    yield put(
      actions.setFeaturedTrustedPartnersAction.success({
        companyUuid,
        response,
      })
    );
  } catch (err: any) {
    yield put(actions.setFeaturedTrustedPartnersAction.failure(err));
  }
}

function* deleteFeaturedTrustedPartner(
  action: ActionType<typeof actions.deleteFeaturedTrustedPartnersAction.request>
) {
  const { id, companyUuid } = action.payload;

  try {
    yield call(
      [client, 'delete'],
      `/partner-data/featured-demand-partners/${id}`
    );

    yield put(
      actions.deleteFeaturedTrustedPartnersAction.success({
        companyUuid,
        id,
      })
    );
  } catch (err: any) {
    yield put(actions.deleteFeaturedTrustedPartnersAction.failure(err));
  }
}

function* watchGetAvailableByCompanyUuidRequest() {
  yield takeEvery(
    TrustedPartnersActionType.GET_AVAILABLE_REQUEST,
    getAvailableTrustedPartnersByCompanyUuid
  );
}

function* watchGetFeaturedBySubAccountUuidRequest() {
  yield takeEvery(
    TrustedPartnersActionType.GET_FEATURED_REQUEST,
    getFeaturedTrustedPartnersByCompanyUuid
  );
}

function* watchSetFeaturedForSubAccountUuidRequest() {
  yield takeEvery(
    TrustedPartnersActionType.GET_FEATURED_REQUEST,
    setFeaturedTrustedPartnersForCompanyUuid
  );
}

function* watchDeleteFeaturedRequest() {
  yield takeEvery(
    TrustedPartnersActionType.GET_FEATURED_REQUEST,
    deleteFeaturedTrustedPartner
  );
}

export function* trustedPartnersSaga() {
  yield all([
    fork(watchGetAvailableByCompanyUuidRequest),
    fork(watchGetFeaturedBySubAccountUuidRequest),
    fork(watchSetFeaturedForSubAccountUuidRequest),
    fork(watchDeleteFeaturedRequest),
  ]);
}
