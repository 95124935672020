import {
  ConfigProductType,
  LoanPurposeOptions,
} from '@evenfinancial/even-ts-static';
import type {
  SaasMigrationSetting,
  SaasPlan,
} from '@evenfinancial/finance-client';

import {
  ComplianceStatus,
  IntegrationStatus,
  OnboardingStatus,
} from '@portal/api-models';

import { ImplementationFeePaymentStatus } from '@/resources/integrations/types';
import { isFeeWaivedForIntegrationCategory } from '@/resources/self-service/helpers';
import { getSaasFeeData } from '@/resources/self-service/payment/util-methods';
import type { IntegrationCategory } from '@/resources/sub-accounts-shared/utils';
import type { SaasState } from '@/store/saas/types';

export const getOnboardingStatus = (
  implementationFeePaymentStatus: ImplementationFeePaymentStatus,
  complianceStatus?: ComplianceStatus,
  integrationStatus?: IntegrationStatus,
  isPermanentTrial?: boolean
): OnboardingStatus => {
  if (integrationStatus === IntegrationStatus.Live) {
    return OnboardingStatus.Live;
  }

  const feePaid =
    implementationFeePaymentStatus === ImplementationFeePaymentStatus.Paid;
  const feeWaived =
    implementationFeePaymentStatus === ImplementationFeePaymentStatus.Waived;

  if (complianceStatus) {
    switch (complianceStatus) {
      case ComplianceStatus.draft:
        return OnboardingStatus.Draft;

      case ComplianceStatus.closed:
        return feePaid || feeWaived || isPermanentTrial
          ? OnboardingStatus.Ready
          : OnboardingStatus.IntegrationFeeUnpaid;

      default:
        return feePaid || feeWaived || isPermanentTrial
          ? OnboardingStatus.SubmittedForReviewPaid
          : OnboardingStatus.SubmittedForReview;
    }
  }

  return OnboardingStatus.Draft;
};

export const getImplementationFeeStatus = (
  saas: SaasState,
  accessTokenUuid: string,
  isImplementationFeeWaived?: boolean
): ImplementationFeePaymentStatus => {
  if (isImplementationFeeWaived) {
    return ImplementationFeePaymentStatus.Waived;
  }

  return saas.hasFeesBeenRequested
    ? getSaasFeeData(accessTokenUuid, saas.fees)
    : ImplementationFeePaymentStatus.Unknown;
};

export const isFeeWaivedForIntegration = (
  isFeeWaivedByWhitelist: boolean,
  integrationCategory: IntegrationCategory,
  saasPlan?: SaasPlan,
  supplyMigration?: SaasMigrationSetting
) =>
  isFeeWaivedByWhitelist ||
  isFeeWaivedForIntegrationCategory(
    integrationCategory,
    saasPlan,
    supplyMigration
  );

export const getProductByLoanPurpose = (
  loanPurposeOptions?: LoanPurposeOptions[]
) => {
  const loanPurpose = loanPurposeOptions?.filter(
    (item: LoanPurposeOptions) =>
      item === LoanPurposeOptions.STUDENT_REFINANCE ||
      item === LoanPurposeOptions.AUTO_REFINANCE
  );

  if (loanPurpose?.includes(LoanPurposeOptions.AUTO_REFINANCE)) {
    return ConfigProductType.AutomobileRefinance;
  }

  if (loanPurpose?.includes(LoanPurposeOptions.STUDENT_REFINANCE)) {
    return ConfigProductType.StudentLoanRefinance;
  }

  return undefined;
};
