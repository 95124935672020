import type { ActionType } from 'typesafe-actions';

import type {
  SaasFee,
  SaasPlan,
  SaasSubscription,
  SaasTrial,
} from '@evenfinancial/finance-client';

import type * as actions from './actions';

export type SaasAction = ActionType<typeof actions>;
export enum SaasActionType {
  CLOSE_GETTING_STARTED_MODAL = '@@saas/CLOSE_GETTING_STARTED_MODAL',
  CREATE_RECURLY_TOKEN_FAILURE = '@@saas/CREATE_RECURLY_TOKEN_FAILURE',

  CREATE_RECURLY_TOKEN_REQUEST = '@@saas/CREATE_RECURLY_TOKEN_REQUEST',
  CREATE_RECURLY_TOKEN_SUCCESS = '@@saas/CREATE_RECURLY_TOKEN_SUCCESS',
  CREATE_SAAS_FEE_FAILURE = '@@saas/CREATE_SAAS_FEE_FAILURE',

  CREATE_SAAS_FEE_REQUEST = '@@saas/CREATE_SAAS_FEE_REQUEST',
  CREATE_SAAS_FEE_SUCCESS = '@@saas/CREATE_SAAS_FEE_SUCCESS',
  CREATE_SAAS_SUBSCRIPTION_FAILURE = '@@saas/CREATE_SAAS_SUBSCRIPTION_FAILURE',

  CREATE_SAAS_SUBSCRIPTION_REQUEST = '@@saas/CREATE_SAAS_SUBSCRIPTION_REQUEST',
  CREATE_SAAS_SUBSCRIPTION_SUCCESS = '@@saas/CREATE_SAAS_SUBSCRIPTION_SUCCESS',
  CREATE_SAAS_TRIAL_FAILURE = '@@saas/CREATE_SAAS_TRIAL_FAILURE',

  CREATE_SAAS_TRIAL_REQUEST = '@@saas/CREATE_SAAS_TRIAL_REQUEST',
  CREATE_SAAS_TRIAL_SUCCESS = '@@saas/CREATE_SAAS_TRIAL_SUCCESS',
  CUSTOM_LEAD_ATTRIBUTES_CONTACT_US_FAILURE = '@@saas/CUSTOM_LEAD_ATTRIBUTES_CONTACT_US_FAILURE',

  CUSTOM_LEAD_ATTRIBUTES_CONTACT_US_REQUEST = '@@saas/CUSTOM_LEAD_ATTRIBUTES_CONTACT_US_REQUEST',
  CUSTOM_LEAD_ATTRIBUTES_CONTACT_US_SUCCESS = '@@saas/CUSTOM_LEAD_ATTRIBUTES_CONTACT_US_SUCCESS',
  CUSTOM_OFFER_CONTACT_US_FAILURE = '@@saas/CUSTOM_OFFER_CONTACT_US_FAILURE',

  CUSTOM_OFFER_CONTACT_US_REQUEST = '@@saas/CUSTOM_OFFER_CONTACT_US_REQUEST',
  CUSTOM_OFFER_CONTACT_US_SUCCESS = '@@saas/CUSTOM_OFFER_CONTACT_US_SUCCESS',
  DELETE_SAAS_SUBSCRIPTION_FAILURE = '@@saas/DELETE_SAAS_SUBSCRIPTION_FAILURE',

  DELETE_SAAS_SUBSCRIPTION_REQUEST = '@@saas/DELETE_SAAS_SUBSCRIPTION_REQUEST',
  DELETE_SAAS_SUBSCRIPTION_SUCCESS = '@@saas/DELETE_SAAS_SUBSCRIPTION_SUCCESS',
  EXECUTE_SUBMIT_FLOW_FAILURE = '@@saas/EXECUTE_SUBMIT_FLOW_FAILURE',

  EXECUTE_SUBMIT_FLOW_REQUEST = '@@saas/EXECUTE_SUBMIT_FLOW_REQUEST',
  EXECUTE_SUBMIT_FLOW_SUCCESS = '@@saas/EXECUTE_SUBMIT_FLOW_SUCCESS',
  GET_SAAS_ALL_FAILURE = '@@saas/GET_SAAS_ALL_FAILURE',

  GET_SAAS_ALL_REQUEST = '@@saas/GET_SAAS_ALL_REQUEST',
  GET_SAAS_ALL_SUCCESS = '@@saas/GET_SAAS_ALL_SUCCESS',
  GET_SAAS_FEES_FAILURE = '@@saas/GET_SAAS_FEES_FAILURE',

  GET_SAAS_FEES_REQUEST = '@@saas/GET_SAAS_FEES_REQUEST',
  GET_SAAS_FEES_SUCCESS = '@@saas/GET_SAAS_FEES_SUCCESS',
  GET_SAAS_PLANS_FAILURE = '@@saas/GET_SAAS_PLANS_FAILURE',

  GET_SAAS_PLANS_REQUEST = '@@saas/GET_SAAS_PLANS_REQUEST',
  GET_SAAS_PLANS_SUCCESS = '@@saas/GET_SAAS_PLANS_SUCCESS',
  GET_SAAS_SUBSCRIPTION_FAILURE = '@@saas/GET_SAAS_SUBSCRIPTION_FAILURE',

  GET_SAAS_SUBSCRIPTION_REQUEST = '@@saas/GET_SAAS_SUBSCRIPTION_REQUEST',
  GET_SAAS_SUBSCRIPTION_SUCCESS = '@@saas/GET_SAAS_SUBSCRIPTION_SUCCESS',
  GET_USER_TRIAL_FAILURE = '@@saas/GET_USER_TRIAL_FAILURE',

  GET_USER_TRIAL_REQUEST = '@@saas/GET_USER_TRIAL_REQUEST',
  GET_USER_TRIAL_SUCCESS = '@@saas/GET_USER_TRIAL_SUCCESS',

  INIT_INTEGRATION_FEE_PAGE_FAILURE = '@@saas/INIT_INTEGRATION_FEE_PAGE_FAILURE',
  INIT_INTEGRATION_FEE_PAGE_REQUEST = '@@saas/INIT_INTEGRATION_FEE_PAGE_REQUEST',
  INIT_INTEGRATION_FEE_PAGE_SUCCESS = '@@saas/INIT_INTEGRATION_FEE_PAGE_SUCCESS',

  INIT_PURCHASE_PLAN_PAGE_FAILURE = '@@saas/INIT_PURCHASE_PLAN_PAGE_FAILURE',
  INIT_PURCHASE_PLAN_PAGE_REQUEST = '@@saas/INIT_PURCHASE_PLAN_PAGE_REQUEST',
  INIT_PURCHASE_PLAN_PAGE_SUCCESS = '@@saas/INIT_PURCHASE_PLAN_PAGE_SUCCESS',

  ON_PURCHASE_PLAN_FORM_CHANGED_FAILURE = '@@saas/ON_PURCHASE_PLAN_FORM_CHANGED_FAILURE',
  ON_PURCHASE_PLAN_FORM_CHANGED_REQUEST = '@@saas/ON_PURCHASE_PLAN_FORM_CHANGED_REQUEST',
  ON_PURCHASE_PLAN_FORM_CHANGED_SUCCESS = '@@saas/ON_PURCHASE_PLAN_FORM_CHANGED_SUCCESS',

  PREVIEW_CREATE_SAAS_SUBSCRIPTION_FAILURE = '@@saas/PREVIEW_CREATE_SAAS_SUBSCRIPTION_FAILURE',
  PREVIEW_CREATE_SAAS_SUBSCRIPTION_REQUEST = '@@saas/PREVIEW_CREATE_SAAS_SUBSCRIPTION_REQUEST',
  PREVIEW_CREATE_SAAS_SUBSCRIPTION_SUCCESS = '@@saas/PREVIEW_CREATE_SAAS_SUBSCRIPTION_SUCCESS',

  PURCHASE_INTEGRATION_FEE_FAILURE = '@@saas/PURCHASE_INTEGRATION_FEE_FAILURE',
  PURCHASE_INTEGRATION_FEE_REQUEST = '@@saas/PURCHASE_INTEGRATION_FEE_REQUEST',
  PURCHASE_INTEGRATION_FEE_SUCCESS = '@@saas/PURCHASE_INTEGRATION_FEE_SUCCESS',

  PURCHASE_PLAN_FAILURE = '@@saas/PURCHASE_PLAN_FAILURE',
  PURCHASE_PLAN_REQUEST = '@@saas/PURCHASE_PLAN_REQUEST',
  PURCHASE_PLAN_SUCCESS = '@@saas/PURCHASE_PLAN_SUCCESS',

  SCALE_UPGRADE_CONTACT_US_FAILURE = '@@saas/SCALE_UPGRADE_CONTACT_US_FAILURE',
  SCALE_UPGRADE_CONTACT_US_REQUEST = '@@saas/SCALE_UPGRADE_CONTACT_US_REQUEST',
  SCALE_UPGRADE_CONTACT_US_SUCCESS = '@@saas/SCALE_UPGRADE_CONTACT_US_SUCCESS',
}

export type SaasData = {
  currentPlan: SaasPlan;
  plans: SaasPlan[];
  subscription: SaasSubscription;
};
export interface SaasState {
  fees: SaasFee[];
  hasFeesBeenRequested: boolean;
  hasPlanBeenPurchased: boolean;
  hasPlansBeenRequested: boolean;
  hasSubscriptionBeenRequested: boolean;
  hasTrialBeenRequested: boolean;
  lastPurchasedPlan?: SaasPlan;
  lastPurchasedSubscription?: SaasSubscription;
  loading: boolean;
  plan?: SaasPlan;
  plans: SaasPlan[];
  showGettingStartedModal?: boolean;
  subscription?: Partial<SaasSubscription>;
  subscriptionCancelError?: boolean;
  subscriptionPreview?: actions.SaasSubscriptionPreviewData;
  userTrial?: SaasTrial;
}
