"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPermissionsFromRoles = void 0;
var lodash_1 = require("lodash");
var roles_1 = require("../roles");
var compliance_jira_webhook_permissions_1 = require("./configs/compliance-jira-webhook-permissions");
var crm_permissions_1 = require("./configs/crm-permissions");
var evencom_api_permissions_1 = require("./configs/evencom-api-permissions");
var internal_manager_permissions_1 = require("./configs/internal-manager-permissions");
var internal_viewer_permissions_1 = require("./configs/internal-viewer-permissions");
var scale_permissions_1 = require("./configs/scale-permissions");
var self_service_permissions_1 = require("./configs/self-service-permissions");
var super_administrator_permissions_1 = require("./configs/super-administrator-permissions");
var roleToPermissions = new Map([
    [roles_1.Roles.Manager, internal_manager_permissions_1.internalManagerPermissions],
    [roles_1.Roles.InternalViewer, internal_viewer_permissions_1.internalViewerPermissions],
    [roles_1.Roles.SelfService, self_service_permissions_1.selfServicePermissions],
    [roles_1.Roles.CRM, crm_permissions_1.crmPermissions],
    [roles_1.Roles.Scale, scale_permissions_1.scalePermissions],
    [roles_1.Roles.ComplianceJiraWebhook, compliance_jira_webhook_permissions_1.complianceJiraWebhookPermissions],
    [roles_1.Roles.EvencomApi, evencom_api_permissions_1.evencomApiPermissions],
    [roles_1.Roles.SuperAdministrator, super_administrator_permissions_1.superAdministratorPermissions],
]);
var getPermissionsFromRoles = function (roles) {
    var allPermissions = roles
        .flatMap(function (role) { var _a; return (_a = roleToPermissions.get(role)) !== null && _a !== void 0 ? _a : []; })
        .sort();
    return (0, lodash_1.uniq)(allPermissions);
};
exports.getPermissionsFromRoles = getPermissionsFromRoles;
