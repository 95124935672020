import { HYDRATE } from 'next-redux-wrapper';
import type { Reducer } from 'redux';

import { mapCreditCardFlowToForm } from '@/resources/partner-page/fields/credit-cards';
import { RESET_STORE } from '@/store/actions';
import type { GlobalAction } from '@/store/actions';
import { reconcileState } from '@/store/utils';

import { PartnerPageActionType } from './types';
import type { PartnerPageAction, PartnerPageState } from './types';

export const initialState: PartnerPageState = {
  byPartnerKeyId: {},
  createDrawerVisible: false,
  duplicateId: '',
  haveAllBeenRequested: false,
  integrationCreateVisible: false,
  loadingWizardData: false,
  rows: [],
  showUpgradeToGrowthModal: false,
  uploadingPartnerKeyImage: false,
};

export const reducer: Reducer<
  PartnerPageState,
  PartnerPageAction | GlobalAction
> = (
  state: PartnerPageState = initialState,
  action: PartnerPageAction | GlobalAction
) => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...reconcileState(action.payload.partnerPages, initialState),
      };
    }

    case RESET_STORE: {
      return {
        ...initialState,
      };
    }

    case PartnerPageActionType.TOGGLE_CREATE: {
      return {
        ...state,
        createDrawerVisible: !state.createDrawerVisible,
        duplicateId: '',
      };
    }
    case PartnerPageActionType.TOGGLE_DUPLICATE: {
      return {
        ...state,
        createDrawerVisible: !state.createDrawerVisible,
        duplicateId: action.payload,
      };
    }
    case PartnerPageActionType.BY_KEY_SUCCESS: {
      if (!action.payload || !action.payload.id) {
        return state;
      }

      return {
        ...state,
        byPartnerKeyId: {
          ...state.byPartnerKeyId,
          [action.payload.id]: mapCreditCardFlowToForm(action.payload),
        },
      };
    }
    case PartnerPageActionType.GET_BY_ID_SUCCESS:
    case PartnerPageActionType.GET_ALL_SUCCESS: {
      return {
        ...state,
        haveAllBeenRequested: true,
        rows: action.payload.reverse(), // returned with latest created/edited last so reversing
      };
    }
    case PartnerPageActionType.UPDATE_INTEGRATION_STATUS_SUCCESS_CLIENT: {
      const replaceId = action.payload.updatePartnerKeyId;
      const newId = action.payload.resp.id;

      const rows = [...state.rows];
      const byPartnerKeyId = { ...state.byPartnerKeyId };

      const rowIndex = rows.findIndex((pp) => pp.id === replaceId);

      if (rowIndex !== -1) {
        rows[rowIndex].id = newId;
        const newRow = {
          ...rows[rowIndex],
          id: newId,
        };

        rows.splice(rowIndex, 1);
        rows.push(newRow);
      }

      if (byPartnerKeyId[replaceId]) {
        delete byPartnerKeyId[replaceId];
      }

      byPartnerKeyId[newId] = action.payload.resp;

      return {
        ...state,
        byPartnerKeyId,
        rows,
      };
    }
    case PartnerPageActionType.TOGGLE_INTEGRATION_CREATE: {
      return {
        ...state,
        integrationCreateVisible: !state.integrationCreateVisible,
      };
    }

    case PartnerPageActionType.REPLACE_IMAGE_REQUEST_CLIENT: {
      return {
        ...state,
        uploadingPartnerKeyImage: true,
      };
    }

    case PartnerPageActionType.PARTNER_PAGE_KEY_VALIDATION_SUCCESS: {
      return {
        ...state,
        partnerPageKeyValid: action.payload,
      };
    }
    case PartnerPageActionType.GET_ALL_WIZARD_DATA_FAILURE:
    case PartnerPageActionType.GET_ALL_WIZARD_DATA_SUCCESS:
      return {
        ...state,
        loadingWizardData: false,
      };
    case PartnerPageActionType.GET_ALL_WIZARD_DATA_REQUEST:
      return {
        ...state,
        loadingWizardData: true,
      };

    case PartnerPageActionType.OPEN_UPGRADE_TO_GROWTH_MODAL:
      return {
        ...state,
        showUpgradeToGrowthModal: true,
      };
    case PartnerPageActionType.CLOSE_UPGRADE_TO_GROWTH_MODAL:
      return {
        ...state,
        showUpgradeToGrowthModal: false,
      };

    default: {
      return state;
    }
  }
};
