"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.serialize = void 0;
const lodash_1 = require("lodash");
const serialize = (value) => {
    if ((0, lodash_1.isString)(value)) {
        return (0, lodash_1.replace)((0, lodash_1.lowerCase)(value), /\s+/g, '');
    }
    return '';
};
exports.serialize = serialize;
