import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Avatar } from 'antd';
import Tooltip from 'antd/lib/tooltip';
import classNames from 'classnames';

import type { UserAvatarProps } from '@portal/store/dist/user/selectors';
import { userAvatarSelector } from '@portal/store/dist/user/selectors';

import type { ApplicationState } from '@/store';

export const generateColor = (str: string) => {
  const strArray = str.split('');

  const colorHash = strArray?.reduce(
    (acc, currChar) => currChar.charCodeAt(0) + ((acc << 5) - acc),
    0
  );

  return `hsl(${colorHash % 360}, 50%, 60%)`;
};

export const getInitials = (name: string) => {
  const formattedNameValue = name.replace(/\s+/g, ' ').trim();
  const splitName = formattedNameValue.toUpperCase().split(' ');

  if (splitName.length <= 1) {
    return splitName[0][0] || '';
  }

  return `${splitName[0][0]}${splitName[splitName.length - 1][0]}`;
};

/**
 * @deprecated Please use <UserAvatar /> component from @portal/ui-lib
 */
export const UserAvatar = (props: UserAvatarProps) => {
  const selected = useSelector((state: ApplicationState) =>
    userAvatarSelector(state, props)
  );

  const { avatarUrl, className, size, name, withTooltip } = {
    ...props,
    ...selected,
  };

  const MemoizedUserAvatar = useMemo(
    () => (
      <div className={classNames('user-avatar', className)}>
        <Tooltip placement="right" title={withTooltip ? name : undefined}>
          <Avatar
            className="user-avatar-component"
            size={size}
            src={avatarUrl}
            style={{ backgroundColor: generateColor(name || '') }}
          >
            {getInitials(name || '')}
          </Avatar>
        </Tooltip>
        {!withTooltip && <div className="user-avatar-name">{name}</div>}
      </div>
    ),
    [name, withTooltip]
  );

  return name ? MemoizedUserAvatar : null;
};

export default UserAvatar;
