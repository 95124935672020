import capitalize from 'lodash/capitalize';
import moment from 'moment';

import { DateFormat } from '@/utils/date-time-formatters';

export const summaryGroupByValueFormatter = (text?: string) => {
  if (text === null) {
    return 'Unknown';
  }

  if (moment(text, 'YYYY-MM-DD', true).isValid()) {
    return moment(text).format(DateFormat);
  }

  return text
    ?.split('_')
    .map((string) => capitalize(string))
    .join(' ');
};
