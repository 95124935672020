import type { ActionType } from 'typesafe-actions';

import type { ProductType } from '@evenfinancial/finance-client';
import type { SummaryGroupBy } from '@evenfinancial/supply-reporting-client';

import type { PayoutSummary, SummaryData } from '@portal/api-models';

import type { ChartType } from '@/components/reporting/chart';
import type { PerformanceSummariesTabsEnum } from '@/resources/channels/performance-summary/page-config-sub-account';

import type * as actions from './actions';

export type PerformanceSummaryAction = ActionType<typeof actions>;

export enum PerformanceSummaryActionType {
  CLEAR_SUMMARIES_FOR_ACCOUNT_UUID = '@@performance_summary/CLEAR_SUMMARIES_FOR_ACCOUNT_UUID',
  CLEAR_SUMMARIES_FOR_SUBACCOUNT_UUID = '@@performance_summary/CLEAR_SUMMARIES_FOR_SUBACCOUNT_UUID',
  GET_ALL_BY_ACCOUNT_UUID_FAILURE = '@@performance_summary/GET_ALL_BY_ACCOUNT_ID_FAILURE',
  GET_ALL_BY_ACCOUNT_UUID_REQUEST = '@@performance_summary/GET_ALL_BY_ACCOUNT_ID_REQUEST',
  GET_ALL_BY_ACCOUNT_UUID_SUCCESS = '@@performance_summary/GET_ALL_BY_ACCOUNT_ID_SUCCESS',
  GET_ALL_BY_SUBACCOUNT_UUID_FAILURE = '@@performance_summary/GET_ALL_BY_SUBACCOUNT_ID_FAILURE',
  GET_ALL_BY_SUBACCOUNT_UUID_REQUEST = '@@performance_summary/GET_ALL_BY_SUBACCOUNT_ID_REQUEST',
  GET_ALL_BY_SUBACCOUNT_UUID_SUCCESS = '@@performance_summary/GET_ALL_BY_SUBACCOUNT_ID_SUCCESS',
  SET_FILTERS = '@@performance_summary/SET_FILTERS',
  SET_LOADING = '@@performance_summary/SET_LOADING',
  SET_SUMMARIES_FOR_SUBACCOUNT_ID = '@@performance_summary/SET_SUMMARIES_FOR_SUBACCOUNT_UUID',
}

export interface PerformanceReportsFilterState {
  accountUuid?: string;
  companyUuid?: string;
  earningsChartType: ChartType;
  earningsClientTag?: string;
  earningsGroupBy: SummaryGroupBy;
  funnelChartType: ChartType;
  funnelClientTag?: string;
  funnelGroupBy: SummaryGroupBy;
  maxDate: string;
  minDate: string;
  subAccountUuid?: string;
  tab: PerformanceSummariesTabsEnum;
}

export interface PerformanceSummariesState {
  filters: PerformanceReportsFilterState;
  loading: boolean;
  summaries: {
    byAccountUuid: Record<
      string,
      {
        data?: {
          earnings: PayoutSummary[];
        };
        productTypes?: ProductType[];
      }
    >;
    bySubAccountUuid: Record<
      string,
      {
        data?: SummaryData;
        productType?: ProductType;
      }
    >;
  };
}
