import { createAction, createAsyncAction } from 'typesafe-actions';

import type { PremiumLender } from '@portal/api-models';

import { PremiumLendersActionType } from './actionTypes';
import type {
  CreatePremiumLenderPayload,
  DeletePremiumLenderPayload,
  FindPremiumLendersPayload,
  GetPremiumLenderByIdPayload,
  UpdatePremiumLenderPayload,
} from './types';

export const createPremiumLenderAction = createAsyncAction(
  PremiumLendersActionType.CREATE_PREMIUM_LENDER_REQUEST,
  PremiumLendersActionType.CREATE_PREMIUM_LENDER_SUCCESS,
  PremiumLendersActionType.CREATE_PREMIUM_LENDER_FAILURE
)<CreatePremiumLenderPayload, PremiumLender, Error>();

export const updatePremiumLenderAction = createAsyncAction(
  PremiumLendersActionType.UPDATE_PREMIUM_LENDER_REQUEST,
  PremiumLendersActionType.UPDATE_PREMIUM_LENDER_SUCCESS,
  PremiumLendersActionType.UPDATE_PREMIUM_LENDER_FAILURE
)<UpdatePremiumLenderPayload, PremiumLender, Error>();

export const deletePremiumLenderAction = createAsyncAction(
  PremiumLendersActionType.DELETE_PREMIUM_LENDER_REQUEST,
  PremiumLendersActionType.DELETE_PREMIUM_LENDER_SUCCESS,
  PremiumLendersActionType.DELETE_PREMIUM_LENDER_FAILURE
)<DeletePremiumLenderPayload, { id: string }, Error>();

export const findPremiumLendersAction = createAsyncAction(
  PremiumLendersActionType.FIND_PREMIUM_LENDERS_REQUEST,
  PremiumLendersActionType.FIND_PREMIUM_LENDERS_SUCCESS,
  PremiumLendersActionType.FIND_PREMIUM_LENDERS_FAILURE
)<FindPremiumLendersPayload, PremiumLender[], Error>();

export const getPremiumLenderByIdAction = createAsyncAction(
  PremiumLendersActionType.GET_PREMIUM_LENDER_BY_ID_REQUEST,
  PremiumLendersActionType.GET_PREMIUM_LENDER_BY_ID_SUCCESS,
  PremiumLendersActionType.GET_PREMIUM_LENDER_BY_ID_FAILURE
)<GetPremiumLenderByIdPayload, PremiumLender, Error>();

export const initPremiumLendersInternalPageAction = createAction(
  PremiumLendersActionType.INIT_PREMIUM_LENDERS_INTERNAL_PAGE
)();

export const togglePremiumLenderCreateAction = createAction(
  PremiumLendersActionType.TOGGLE_PREMIUM_LENDER_CREATE
)();

export const togglePremiumLenderUpdateAction = createAction(
  PremiumLendersActionType.TOGGLE_PREMIUM_LENDER_UPDATE
)<string | undefined | null>();
