import { createAsyncAction } from 'typesafe-actions';

import type {
  ProductType,
  StaticOffer,
} from '@evenfinancial/originator-client';

import type { OfferDeactivation, ProductOffer } from '@portal/common';

import type { IsomorphicRequestPayload } from '../types';

import { OfferActionType } from './types';

export interface OfferRequestPayload extends IsomorphicRequestPayload {
  productType: ProductType;
}

export interface ProductOfferSuccessPayload {
  productOffers: ProductOffer[];
  productType: ProductType;
}

export interface StaticOfferSuccessPayload {
  productType: ProductType;
  staticOffers: StaticOffer[];
}

export interface EnabledStaticOfferRequestPayload {
  productType: ProductType;
  supplySubAccountUuid: string;
}

export interface PatchEnabledStaticOffersRequestPayload {
  staticOfferId: number;
  supplySubAccountIds: number[];
}

export const staticOfferRequestAction = createAsyncAction(
  OfferActionType.GET_ALL_STATIC_OFFERS_REQUEST,
  OfferActionType.GET_ALL_STATIC_OFFERS_SUCCESS,
  OfferActionType.GET_ALL_STATIC_OFFERS_FAILURE
)<OfferRequestPayload, StaticOfferSuccessPayload, Error>();

export const enabledStaticOffersRequestAction = createAsyncAction(
  OfferActionType.GET_STATIC_OFFERS_REQUEST,
  OfferActionType.GET_STATIC_OFFERS_SUCCESS,
  OfferActionType.GET_STATIC_OFFERS_FAILURE
)<EnabledStaticOfferRequestPayload, StaticOffer[], Error>();

export const patchBulkStaticOffersRequestAction = createAsyncAction(
  OfferActionType.PATCH_STATIC_OFFERS_REQUEST,
  OfferActionType.PATCH_STATIC_OFFERS_SUCCESS,
  OfferActionType.PATCH_STATIC_OFFERS_FAILURE
)<PatchEnabledStaticOffersRequestPayload[], StaticOffer[], Error>();

export const productOfferRequestAction = createAsyncAction(
  OfferActionType.GET_ALL_PRODUCT_OFFERS_REQUEST,
  OfferActionType.GET_ALL_PRODUCT_OFFERS_SUCCESS,
  OfferActionType.GET_ALL_PRODUCT_OFFERS_FAILURE
)<OfferRequestPayload, ProductOfferSuccessPayload, Error>();

export const offerDeactivationRequestAction = createAsyncAction(
  OfferActionType.GET_ALL_OFFER_DEACTIVATION_REQUEST,
  OfferActionType.GET_ALL_OFFER_DEACTIVATION_SUCCESS,
  OfferActionType.GET_ALL_OFFER_DEACTIVATION_FAILURE
)<IsomorphicRequestPayload, OfferDeactivation[], Error>();

export const createOfferDeactivationsAction = createAsyncAction(
  OfferActionType.CREATE_OFFER_DEACTIVATIONS_REQUEST,
  OfferActionType.CREATE_OFFER_DEACTIVATIONS_SUCCESS,
  OfferActionType.CREATE_OFFER_DEACTIVATIONS_FAILURE
)<{ offerIds: number[] }, OfferDeactivation[], Error>();

export const deleteOfferDeactivationAction = createAsyncAction(
  OfferActionType.DELETE_OFFER_DEACTIVATIONS_REQUEST,
  OfferActionType.DELETE_OFFER_DEACTIVATIONS_SUCCESS,
  OfferActionType.DELETE_OFFER_DEACTIVATIONS_FAILURE
)<{ deactivationIds: number[] }, OfferDeactivation[], Error>();
