/* eslint-disable no-console */
import { LogLevel } from '@/utils/logger/logger';
import type { ParsedLog } from '@/utils/logger/parser';
import { Transport } from '@/utils/logger/transport';

export class ConsoleTransport extends Transport {
  private getOutput(level: LogLevel, parsed: ParsedLog) {
    const { format } = this.options;
    const { message, error, context, stack } = parsed;

    let output = [parsed.message, parsed.context, parsed.error, parsed.stack];

    if (format) {
      output = [
        format.transform({
          context,
          error,
          'error.stack': stack,
          level,
          message,
        }),
      ];
    }

    return output;
  }

  public info(parsed: ParsedLog) {
    console.log(...this.getOutput(LogLevel.Info, parsed));
  }

  public debug(parsed: ParsedLog) {
    console.debug(...this.getOutput(LogLevel.Debug, parsed));
  }

  public warn(parsed: ParsedLog) {
    console.warn(...this.getOutput(LogLevel.Warn, parsed));
  }

  public error(parsed: ParsedLog) {
    console.error(...this.getOutput(LogLevel.Error, parsed));
  }
}
