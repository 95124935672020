"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubAccountPartial = void 0;
const getSubAccountPartial = (subAccount) => {
    if (subAccount) {
        return {
            companyUuid: subAccount.companyUuid,
            displayName: subAccount.displayName,
            id: subAccount.id,
            imageUrl: subAccount.imageUrl,
            name: subAccount.name,
            uuid: subAccount.uuid,
        };
    }
    return null;
};
exports.getSubAccountPartial = getSubAccountPartial;
