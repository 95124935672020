"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapEvenRolesToRoles = exports.matchToRoleType = exports.mapRolesToEvenRoles = void 0;
const lodash_1 = require("lodash");
const api_models_1 = require("@portal/api-models");
const mapRolesToEvenRoles = (roles = []) => roles.map((role) => `portal_${(0, lodash_1.snakeCase)(role)}`);
exports.mapRolesToEvenRoles = mapRolesToEvenRoles;
const matchToRoleType = (role) => {
    const roleKeys = Object.keys(api_models_1.Roles);
    const key = roleKeys.find((k) => {
        const val = api_models_1.Roles[k].toLowerCase();
        if (role.toLowerCase() === val) {
            return true;
        }
        return false;
    });
    const found = api_models_1.Roles[key];
    return found || role.split(' ').map(lodash_1.capitalize).join(' ');
};
exports.matchToRoleType = matchToRoleType;
const mapEvenRolesToRoles = (roles = []) => {
    return roles
        .filter((r) => r.includes('portal_'))
        .map((role) => (0, lodash_1.words)(role.replace('portal_', '')).join(' '))
        .map(exports.matchToRoleType)
        .sort();
};
exports.mapEvenRolesToRoles = mapEvenRolesToRoles;
