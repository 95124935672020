import * as React from 'react';

import type { CheckboxOptionType } from 'antd/lib/checkbox';
import type { CheckboxValueType } from 'antd/lib/checkbox/Group';
import Radio from 'antd/lib/radio';

import { useControllable } from '@/components/form/portal-form';
import type {
  FieldComponent,
  FieldComponentProps,
} from '@/components/form/portal-form/types';

export type RadioGroupProvidedProps = FieldComponentProps<CheckboxValueType> & {
  isVertical?: boolean;
  options: Array<CheckboxOptionType>;
};

const RadioGroup: FieldComponent<
  CheckboxValueType,
  RadioGroupProvidedProps
> = ({ onChange, value, defaultValue = '', isVertical, ...props }) => {
  const [selectedRadioOption, setSelectedRadioOption] = useControllable({
    defaultValue,
    onChange,
    value,
  });

  return (
    <Radio.Group
      {...props}
      className={`portal-form-radio-group ${isVertical ? 'vertical' : ''}`}
      options={props.options}
      value={selectedRadioOption}
      onChange={(e) => setSelectedRadioOption(e.target.value)}
    />
  );
};

export default RadioGroup;
