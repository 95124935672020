import inspectlet, { install } from 'inspectlet-es';

import { useTracker } from './use-tracker';

export const useInspectlet = () => {
  useTracker(({ engineUser }) => {
    install(847700307);
    inspectlet(['identify', engineUser.emailAddress]);
  });
};
