"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadUrl = exports.replaceByUuidWithIndexes = exports.replaceByUuid = exports.replaceByIdFromArray = exports.replaceById = void 0;
const replaceById = (rows, ...newElements) => {
    const newRows = [...rows];
    for (const element of newElements) {
        const existing = newRows.findIndex((n) => n.id === element.id);
        if (existing === -1) {
            continue;
        }
        newRows[existing] = element;
    }
    return newRows;
};
exports.replaceById = replaceById;
const replaceByIdFromArray = (rows, newElements) => {
    const newRows = [...rows];
    for (const element of newElements) {
        const existing = newRows.findIndex((n) => n.id === element.id);
        if (existing === -1) {
            continue;
        }
        newRows[existing] = element;
    }
    return newRows;
};
exports.replaceByIdFromArray = replaceByIdFromArray;
const replaceByUuidFull = (rows, newElements) => {
    const newRows = [...rows];
    const updatedIndexes = [];
    for (const element of newElements) {
        const existing = newRows.findIndex((n) => n.uuid === element.uuid);
        if (existing > -1) {
            updatedIndexes.push(existing);
            newRows[existing] = element;
        }
        else {
            updatedIndexes.push(newRows.length);
            newRows.push(element);
        }
    }
    return { indexes: updatedIndexes, rows: newRows };
};
const replaceByUuid = (rows, newElements) => {
    return replaceByUuidFull(rows, newElements).rows;
};
exports.replaceByUuid = replaceByUuid;
const replaceByUuidWithIndexes = (rows, newElements) => {
    return replaceByUuidFull(rows, newElements);
};
exports.replaceByUuidWithIndexes = replaceByUuidWithIndexes;
const downloadUrl = (url) => {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', 'true');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};
exports.downloadUrl = downloadUrl;
