import type { AxiosInstance } from 'axios';

import type {
  SupplyTemplate,
  SupplyTemplateApplication,
  SupplyTemplateApplicationCreateData,
} from '@evenfinancial/originator-client';

import type { SupplyTemplateQuery } from '@portal/api-models';
/**
 * @param {AxiosInstance} client
 * @param {*} params
 * @return Promise<SupplyTemplate[]>
 */
export const getSupplyTemplates = async (
  client: AxiosInstance,
  params: SupplyTemplateQuery
): Promise<SupplyTemplate[]> => {
  return client
    .get<SupplyTemplate[]>('/originator/supply_templates', { params })
    .then((res) => res.data);
};

/**
 * @param {AxiosInstance} client
 * @param {SupplyTemplateApplicationCreateData} createData
 * @return {Promise<SupplyTemplateApplication>}
 */
export const createSupplyTemplateApplication = async (
  client: AxiosInstance,
  createData: SupplyTemplateApplicationCreateData
): Promise<SupplyTemplateApplication> =>
  client
    .post('/originator/supply_template_applications', createData)
    .then((res) => res.data);
