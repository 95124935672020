import { createSelector } from 'reselect';

import type { ApplicationState } from '@/store';

export const getSlice = (state: ApplicationState) => state.demandContracts;

export const getLoadingContractById = createSelector(
  [getSlice],
  (contracts) => contracts?.loadingContractById
);

export const getDemandContractRows = createSelector(
  [getSlice],
  (contracts) => contracts?.rows
);

export const selectDemandContractById = createSelector(
  [
    (_state: ApplicationState, id: string | string[]) => id,
    getDemandContractRows,
  ],
  (id, demandContracts) => {
    if (id && typeof id === 'string') {
      const contract = demandContracts.find(
        (item) => item.id === parseInt(id, 10)
      );

      return contract;
    }
  }
);
