import * as React from 'react';
import { useEffect } from 'react';

import { useForm } from '@/components/form/portal-form';

/**
 * @return {React.RefObject<PortalFormFieldList<T>>}
 */
export const useFieldList = <T extends {}>(): React.RefObject<any> => {
  // Temporary any until can devote more time looking into type issue.
  const ref: any = React.createRef<any>();

  // Also temporary solution to satisfy T type.
  const temp: T = {} as any;

  temp;

  const { onValidate, onErrorCount, removeOnValidate, removeOnErrorCount } =
    useForm();

  useEffect(() => {
    const validate = () => ref.current?.validate?.();
    const getErrorCount = () => ref.current?.getErrorCount?.();

    onValidate(validate);
    onErrorCount(getErrorCount);

    return function () {
      removeOnValidate(validate);
      removeOnErrorCount(getErrorCount);
    };
  });

  return ref;
};
