import groupBy from 'lodash/groupBy';
import { createSelector } from 'reselect';

import type { UserDeactivation } from '@evenfinancial/auth-client';

import type {
  TempAccount,
  UserGrantsData,
  UserWithRoles,
} from '@portal/api-models';
import { Roles } from '@portal/api-models';
import {
  getUserAccessRoles,
  getUserPartnerRoles,
  getUserTypeRole,
} from '@portal/common/dist/access-control';
import { selectAccounts } from '@portal/store/dist/account/selectors';
import {
  UserAccountsStatus,
  selectDeactivateUsers,
  selectUserState,
  selectUsers,
  selectUsersAccess,
} from '@portal/store/dist/user/selectors';
import type {
  UserAccountLink,
  UserRow,
} from '@portal/store/dist/user/selectors';
import { getUserFullName } from '@portal/store/dist/user/util';

import { getFreeTrialDaysLeft } from '@/resources/saas/helpers';
import { selectSaasUserTrial } from '@/resources/saas/selects';

//TODO:move the rest of the selectors to package/store when the account store is migrated

const mapUserToUserRow = (
  user: UserWithRoles,
  canSeeAllAccounts?: boolean,
  accounts?: TempAccount[],
  accessByUuid?: { [uuid: string]: UserGrantsData },
  userDeactivation?: UserDeactivation
): UserRow => {
  const accountsUuids: string[] =
    (accessByUuid?.[user.uuid]?.grants?.accounts as string[]) || [];

  const userType = user?.roles ? getUserTypeRole(user.roles as Roles[]) : '';
  const canProvidedUserSeeAllAccounts =
    userType === Roles.InternalUser || canSeeAllAccounts;

  let userAccounts: UserAccountLink[];
  let accountsStatus: UserAccountsStatus;

  if (canProvidedUserSeeAllAccounts && accountsUuids.length === 0) {
    userAccounts = [{ name: 'All', uuid: '' }];
    accountsStatus = UserAccountsStatus.All;
  } else if (accountsUuids.length === 0) {
    userAccounts = [{ name: 'n/a', uuid: '' }];
    accountsStatus = UserAccountsStatus.Empty;
  } else {
    userAccounts = accountsUuids.map((uuid: string) => {
      const userAccount = accounts?.find((account) => account.uuid === uuid);

      return { name: userAccount?.name, uuid };
    });

    accountsStatus =
      (userAccounts[0].name as UserAccountsStatus) || UserAccountsStatus.Empty;
  }

  return {
    ...user,
    accessRoles: user?.roles ? getUserAccessRoles(user.roles as Roles[]) : [],
    accounts: userAccounts,
    accountsStatus,
    completeName: getUserFullName(user),
    deactivationId: userDeactivation?.id,
    isActive: !user?.deactivatedAt,
    isActiveDisplay: !user?.deactivatedAt ? 'Active' : 'Inactive',
    partnerRoles: user?.roles ? getUserPartnerRoles(user.roles as Roles[]) : [],
    userType,
  };
};

export const tableDataSelector = createSelector(
  [selectUsers, selectAccounts, selectUsersAccess, selectDeactivateUsers],
  (
    users: UserWithRoles[],
    accounts: TempAccount[],
    accessByUuid: any,
    userDeactivations: UserDeactivation[]
  ): UserRow[] => {
    const deactivationIdsByUserId = groupBy(
      userDeactivations?.filter((record) => !record.deletedAt),
      'userId'
    );

    return users.map((user: UserWithRoles) =>
      mapUserToUserRow(
        user,
        undefined,
        accounts,
        accessByUuid,
        deactivationIdsByUserId[user.id]?.[0]
      )
    );
  }
);

export const nameSelector = createSelector(
  [selectUserState],
  (user) => user.claims?.name ?? ''
);

export const trialExpirationDaysSelector = createSelector(
  [selectSaasUserTrial],
  (userTrial) => getFreeTrialDaysLeft(userTrial?.endDate)
);
