import type { Dispatch } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Button, Intent } from '@blueprintjs/core';
import { Steps } from 'antd';
import classNames from 'classnames';
import type { Action } from 'typesafe-actions';

import ConfirmableAction from '@/components/confirmable-action';

const { Step } = Steps;

export type PortalStepAction = {
  confirmable?: {
    cancelText?: string;
    className?: string;
    message?: string | React.ReactElement<any> | React.ReactFragment;
    okText?: string;
    title?: string;
  };
  onClick: (dispatch: Dispatch<Action>) => void;
  title: string;
};

export type PortalStep = {
  action?: PortalStepAction;
  alwaysActive?: boolean;
  alwaysShowAction?: boolean;
  customActionContent?: JSX.Element | JSX.Element[];
  description: string | JSX.Element | JSX.Element[];
  hideTail?: boolean;
  icon?: JSX.Element;
  id: string;
  title: string;
  titleCompleted?: string;
};

export interface PortalStepsProps {
  currentStep: number;
  steps: PortalStep[];
}

const PortalSteps: React.FC<PortalStepsProps> = ({ currentStep, steps }) => {
  const dispatch = useDispatch();

  const stepsToRender = steps.map((step: PortalStep, index: number) => {
    const isCompleted = index < currentStep;
    const isCurrent = index === currentStep;
    const isGrayButton = step.alwaysShowAction && !isCurrent;
    const isDisabled =
      (!step.alwaysActive && index > currentStep) || isGrayButton;

    const showButton =
      (step.alwaysShowAction && !step.customActionContent) || !isCompleted;
    const title = (isCompleted && step.titleCompleted) || step.title;

    const button = step.action ? (
      <Button
        className={classNames('portal-step-button', {
          'portal-step-button-gray': isGrayButton,
        })}
        disabled={isDisabled}
        intent={Intent.PRIMARY}
        onClick={
          step.action.confirmable
            ? () => {}
            : () => step.action?.onClick(dispatch)
        }
      >
        {step.alwaysShowAction && isCompleted ? 'Done' : step.action.title}
      </Button>
    ) : (
      <></>
    );

    return (
      <Step
        key={index}
        className={classNames('portal-step', {
          'ant-steps-item-active': step.alwaysActive,
          'ant-steps-item-hide-tail': step.hideTail,
          'ant-steps-item-process': step.alwaysActive,
        })}
        description={step.description}
        icon={step.icon}
        title={
          <>
            <span
              className={classNames('portal-step-title', {
                'portal-step-title-active': isCurrent || step.alwaysActive,
              })}
            >
              {title}
            </span>
            {showButton &&
              step.action &&
              (step.action.confirmable ? (
                <ConfirmableAction
                  {...step.action.confirmable}
                  action={() => step.action?.onClick(dispatch)}
                >
                  {button}
                </ConfirmableAction>
              ) : (
                button
              ))}
            {step.customActionContent && (!isCurrent || !step.action) && (
              <div className="portal-step-custom-action-content">
                {step.customActionContent}
              </div>
            )}
          </>
        }
      />
    );
  });

  return (
    <Steps current={currentStep} direction="vertical">
      {stepsToRender}
    </Steps>
  );
};

export default PortalSteps;
