"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selfServiceConfig = void 0;
exports.selfServiceConfig = {
    canReadIntegrations: true,
    canRequestPartnerKeys: true,
    canRequestSaasSubscription: true,
    canRequestSelfServiceData: true,
    canRequestUserTrial: true,
    canSetupSubAccountFiltering: true,
    hasSelfServiceUserRole: true,
    shouldForceCurrentUserRolesOnUserCreation: true,
    shouldGetAllSaasSettingsOnUserIndexPage: true,
    shouldGetPartnerKeysOnUserIndexPage: true,
    shouldGetSubAccountOwners: true,
    shouldGetSupplySubAccountsOnUserDetailsPage: true,
    shouldGetUserDeactivationsOnUserDetailsPage: true,
    shouldOverrideOnboardingStatusOfMigratedUsers: true,
    shouldRedirectToWelcome: true,
    shouldReplaceRouteParamPartnerPageWizard: true,
    shouldSubmitForComplianceReviewOnPartnerPageUpdate: true,
    shouldUseSelfServiceEntrypointOnIndexPage: true,
    showAccountPayoutSettingsTab: true,
    showAccountPlanHeader: true,
    showAccountSubscriptionTab: true,
    showActivateYourPlanWarning: true,
    showComplianceNavbarItem: true,
    showContractTabCopy: true,
    showControlCenterHome: true,
    showControlCenterHomeGettingStartedBox: true,
    showControlCenterHomeHeaderText: true,
    showControlCenterHomeSetupBankingInformation: true,
    showControlCenterSidebarTrialBanner: true,
    showExportButton: true,
    showFirstIntegrationPage: true,
    showFootnote: true,
    showHelpCenterHeaderItemIfFeatureFlag: true,
    showIntegrationActions: true,
    showIntegrationCreateButton: true,
    showNotificationsHeaderItemIfFeatureFlag: true,
    showPartnerKeyItemDropdownActions: true,
    showPartnerKeyItemLinkToPartnerPage: true,
    showSelfServiceUserDetails: true,
    showSelfServiceUserSideNavIfFeatureFlag: true,
    showSelfServiceUsersPage: true,
    showSidebarResources: true,
    showTrialExpirationBar: true,
};
