import * as React from 'react';
import { useEffect } from 'react';

import Input from 'antd/lib/input';
import Select from 'antd/lib/select';
import debounce from 'lodash/debounce';

import { SummaryGroupBy } from '@evenfinancial/supply-reporting-client';

const { Option } = Select;
const { Search } = Input;

export const SummaryGroupByLabelsMap: Record<string, string> = {
  credit_rating: 'Credit Bucket',
  day: 'Day',
  lead_client_tag: 'Client Tag',
  loan_purpose: 'Loan Purpose',
  month: 'Month',
  policy_type: 'Policy Type',
  referrer: 'Referrer URL',
  week: 'Week',
};

export interface PerformanceSummaryGroupByFilterProps {
  clientTag?: string;
  disabled: boolean;
  groupBy?: SummaryGroupBy;
  groupByOptions: SummaryGroupBy[];
  onChange: (value: SummaryGroupBy) => void;
  onClientTagChange: (value: string) => void;
}

const GroupByFilter: React.FC<PerformanceSummaryGroupByFilterProps> = ({
  groupBy,
  groupByOptions,
  clientTag,
  disabled,
  onChange,
  onClientTagChange,
}) => {
  const onClientTagChangeDebounced = debounce((value: string) => {
    onClientTagChange(value as string);
  }, 1000);

  useEffect(() => {
    !groupByOptions.includes(groupBy!) && onChange(groupByOptions[0]);
  }, [groupByOptions]);

  return groupByOptions.length > 0 ? (
    <div className="performance-summary-groupby">
      <span className="performance-summary-groupby-label">Grouped By</span>

      <Select
        dropdownMatchSelectWidth
        filterOption
        className="revenue-reports-groupby-dropdown"
        disabled={disabled}
        optionFilterProp="children"
        value={groupBy}
        onChange={(value: SummaryGroupBy) => onChange(value)}
      >
        {groupByOptions.map((groupByOption) => (
          <Option key={groupByOption} value={groupByOption}>
            {SummaryGroupByLabelsMap[groupByOption]}
          </Option>
        ))}
      </Select>

      {groupBy === SummaryGroupBy.LeadClientTag && (
        <Search
          className="performance-summary-groupby-input"
          defaultValue={clientTag}
          placeholder="Enter Key"
          onSearch={onClientTagChangeDebounced}
        />
      )}
    </div>
  ) : (
    <></>
  );
};

export default GroupByFilter;
