import { useEffect, useRef } from 'react';

/**
 * Maintain previous value of a given variable.
 *
 * @template T
 * @param {T} value
 * @returns {T}
 */
const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default usePrevious;
