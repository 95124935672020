import { createAction, createAsyncAction } from 'typesafe-actions';

import type { ComplianceItem } from '@portal/api-models';

import type { IsomorphicRequestPayload } from '../types';

import {
  ChannelsActionType,
  ComplianceActionType,
  CreditCardsActionType,
  PersonalLoansActionType,
  SavingsActionType,
} from './types';
import type { SubmitForReviewRequestPayload } from './types';

/**
 * Shared Actions
 */

export const getPersonalLoansAction = createAsyncAction(
  PersonalLoansActionType.GET_REQUEST,
  PersonalLoansActionType.GET_SUCCESS,
  PersonalLoansActionType.GET_FAILURE
)<IsomorphicRequestPayload, ComplianceItem[], Error>();

export const getCreditCardsAction = createAsyncAction(
  CreditCardsActionType.GET_REQUEST,
  CreditCardsActionType.GET_SUCCESS,
  CreditCardsActionType.GET_FAILURE
)<IsomorphicRequestPayload, ComplianceItem[], Error>();

export const getSavingsAction = createAsyncAction(
  SavingsActionType.GET_REQUEST,
  SavingsActionType.GET_SUCCESS,
  SavingsActionType.GET_FAILURE
)<IsomorphicRequestPayload, ComplianceItem[], Error>();

export const getChannelsComplianceAction = createAsyncAction(
  ChannelsActionType.GET_REQUEST,
  ChannelsActionType.GET_SUCCESS,
  ChannelsActionType.GET_FAILURE
)<IsomorphicRequestPayload, ComplianceItem[], Error>();

export const myAccountComplianceRequestAction = createAsyncAction(
  ComplianceActionType.COMPLIANCE_ITEMS_MY_ACCOUNT_REQUEST,
  ComplianceActionType.COMPLIANCE_ITEMS_MY_ACCOUNT_SUCCESS,
  ComplianceActionType.COMPLIANCE_ITEMS_MY_ACCOUNT_FAILURE
)<IsomorphicRequestPayload, ComplianceItem[], Error>();

export const submitForReviewRequestPause = createAction(
  ComplianceActionType.SUBMIT_FOR_REVIEW_PAUSE_SUCCESS
)<undefined>();

export const submitForReviewAction = createAsyncAction(
  ComplianceActionType.SUBMIT_FOR_REVIEW_REQUEST,
  ComplianceActionType.SUBMIT_FOR_REVIEW_SUCCESS,
  ComplianceActionType.SUBMIT_FOR_REVIEW_FAILURE
)<SubmitForReviewRequestPayload, ComplianceItem[], Error>();

export const submitForLenderReviewAction = createAsyncAction(
  ComplianceActionType.SUBMIT_FOR_LENDER_REVIEW_REQUEST,
  ComplianceActionType.SUBMIT_FOR_LENDER_REVIEW_SUCCESS,
  ComplianceActionType.SUBMIT_FOR_LENDER_REVIEW_FAILURE
)<
  {
    demandSubAccountUuid: string;
    epicKey: string;
    integrationId: string;
    subAccountUuid: string;
  },
  ComplianceItem[],
  Error
>();
