"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationActionType = void 0;
var NotificationActionType;
(function (NotificationActionType) {
    NotificationActionType["GET_ALL_FAILURE"] = "@@notification/GET_ALL_FAILURE";
    NotificationActionType["GET_ALL_REQUEST"] = "@@notification/GET_ALL_REQUEST";
    NotificationActionType["GET_ALL_SUCCESS"] = "@@notification/GET_ALL_SUCCESS";
    NotificationActionType["SET_FILTER"] = "@@notification/SET_FILTER";
    NotificationActionType["TOGGLE_DRAWER"] = "@@notification/TOGGLE_DRAWER";
    NotificationActionType["UPDATE_READ_FAILURE"] = "@@notification/UPDATE_READ_FAILURE";
    NotificationActionType["UPDATE_READ_REQUEST"] = "@@notification/UPDATE_READ_REQUEST";
    NotificationActionType["UPDATE_READ_SUCCESS"] = "@@notification/UPDATE_READ_SUCCESS";
    NotificationActionType["WATCH_START"] = "@@notification/WATCH_START";
    NotificationActionType["WATCH_STOP"] = "@@notification/WATCH_STOP";
})(NotificationActionType = exports.NotificationActionType || (exports.NotificationActionType = {}));
