import { message } from 'antd';
import type { AxiosResponse } from 'axios';
import compact from 'lodash/compact';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import uniq from 'lodash/uniq';
import {
  all,
  call,
  delay,
  fork,
  put,
  putResolve,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import type { ActionType } from 'typesafe-actions';

import type { DeduplicationConfiguration } from '@evenfinancial/lead-client';

import { client } from '@portal/api-client';
import type {
  PartnerMapping,
  PortalRuleDemandApiFilter,
  StateExclusion,
} from '@portal/api-models';
import { richAccessTokenByUuidRequestAction } from '@portal/store/dist/user';

import type { ApplicationState } from '..';

import * as actions from './actions';
import {
  createBulkSupplyDemandInclusionsSaga,
  deleteBulkSupplyDemandInclusionsSaga,
  demandEnablementsSaga,
  demandInclusionSaga,
  getOfferRulesByIdSaga,
  supplyDemandInclusionsSaga,
} from './demand-inclusion';
import {
  watchCreateRuleDemandAPIFilter,
  watchDeleteRuleDemandAPIFilter,
  watchRuleDemandAPIFiltersBySubAccountUuid,
} from './sagas/rule-demand-api-filters';

export function* getAllDemandRuleApiAction() {
  try {
    const { data: response }: AxiosResponse<PortalRuleDemandApiFilter[]> =
      yield call([client, 'get'], '/originator/ruleDemandApiFilters');

    // todo: move to helper or selector (PUT reducer action)
    const accessTokenUuids: string[] = yield call(
      compact,
      uniq(response.map((c) => c.createdBy))
    );

    const { data: rats } = yield call(client.post, '/auth/rich_access_tokens', {
      accessTokenUuids,
    });

    yield put(richAccessTokenByUuidRequestAction.success(rats));
    yield put(actions.demandRuleApiGetAllAction.success(response));
  } catch (err: any) {
    yield put(actions.demandRuleApiGetAllAction.failure(err));
  }
}

function* getPartnerMappingsForSubAccount({
  payload: { uuid },
}: ActionType<
  typeof actions.partnerMappingBySubAccountUuidRequestAction.request
>) {
  try {
    const { data: response }: AxiosResponse<PartnerMapping> = yield call(
      client.get,
      `/originator/partner_mappings/${uuid}`
    );

    yield put(
      actions.partnerMappingBySubAccountUuidRequestAction.success({
        response,
        uuid,
      })
    );
  } catch (err: any) {
    yield put(actions.partnerMappingBySubAccountUuidRequestAction.failure(err));
  }
}

function* getDemandOfferCounts() {
  try {
    const { data: response }: AxiosResponse<Record<string, number>> =
      yield call(client.get, '/originator/offer_rules/count');

    yield put(actions.demandOfferCountsRequestAction.success(response));
  } catch (err: any) {
    yield put(actions.demandOfferCountsRequestAction.failure(err));
  }
}

function* getSupplyDemandInclusionCounts() {
  try {
    const { data: response }: AxiosResponse<Record<string, number>> =
      yield call(
        [client, 'get'],
        '/partner-data/supply_demand_inclusions/count'
      );

    yield put(
      actions.supplyDemandInclusionCountsRequestAction.success(response)
    );
  } catch (err: any) {
    yield put(actions.supplyDemandInclusionCountsRequestAction.failure(err));
  }
}

function* getStateExclusions({
  payload: { uuid },
}: ActionType<
  typeof actions.stateExclusionsBySubAccountUuidRequestAction.request
>) {
  try {
    const { data: response }: AxiosResponse<StateExclusion[]> = yield call(
      [client, 'get'],
      `/partner-data/state_exclusions/${uuid}`
    );

    yield put(
      actions.stateExclusionsBySubAccountUuidRequestAction.success({
        response,
        uuid,
      })
    );
  } catch (err: any) {
    yield put(
      actions.stateExclusionsBySubAccountUuidRequestAction.failure(err)
    );
  }
}

export function* updateStateExclusions({
  payload: { uuid, stateCodes },
}: ActionType<
  typeof actions.updateStateExclusionsBySubAccountUuidRequestAction.request
>) {
  try {
    const { data: response }: AxiosResponse<StateExclusion[]> = yield call(
      [client, 'put'],
      `/partner-data/state_exclusions/${uuid}`,
      stateCodes
    );

    yield put(
      actions.updateStateExclusionsBySubAccountUuidRequestAction.success({
        response,
        uuid,
      })
    );
    yield delay(500);
    yield call([message, 'success'], 'State exclusions updated successfully');

    yield putResolve(
      actions.stateExclusionsBySubAccountUuidRequestAction.request({
        uuid,
      })
    );
  } catch (err: any) {
    yield put(
      actions.updateStateExclusionsBySubAccountUuidRequestAction.failure(err)
    );
    yield delay(500);
    yield call([message, 'error'], 'State exclusions update failed');
  }
}

function* getDeduplicationConfigurations(
  action: ActionType<
    typeof actions.deduplicationConfigurationsRequestAction.request
  >
) {
  try {
    const { payload } = action;

    const { data: response }: AxiosResponse<DeduplicationConfiguration[]> =
      yield call([client, 'get'], '/originator/deduplication_configurations', {
        params: pickBy(payload, identity),
      });

    yield put(
      actions.deduplicationConfigurationsRequestAction.success(response)
    );
  } catch (err: any) {
    yield put(actions.deduplicationConfigurationsRequestAction.failure(err));
  }
}

function* deleteDeduplicationConfiguration({
  payload: { supplySubAccountUuid, id = Infinity },
}: ActionType<typeof actions.deduplicationConfigurationsDeleteAction.request>) {
  try {
    yield putResolve(
      actions.deduplicationConfigurationsRequestAction.request({
        supplySubAccountUuid,
      })
    );

    const allIds: number[] = yield select((state: ApplicationState) =>
      state.originator.deduplicationConfigurations.map(({ id }) => id)
    );

    if (allIds.includes(id ?? Infinity)) {
      yield call(
        client.delete,
        `/originator/deduplication_configurations/${id}`
      );
    }

    yield put(
      actions.deduplicationConfigurationsDeleteAction.success({
        id,
      })
    );

    yield putResolve(
      actions.deduplicationConfigurationsRequestAction.request({
        supplySubAccountUuid,
      })
    );
    yield delay(100);
    yield call([message, 'success'], 'Lead deduplication deleted successfully');
  } catch (err: any) {
    yield put(actions.deduplicationConfigurationsDeleteAction.failure(err));
  }
}

function* createDeduplicationConfiguration(
  action: ActionType<
    typeof actions.deduplicationConfigurationsCreateAction.request
  >
) {
  try {
    const { payload } = action;

    const { data: ddc }: AxiosResponse<DeduplicationConfiguration> = yield call(
      client.post,
      '/originator/deduplication_configurations',
      payload
    );

    yield put(actions.deduplicationConfigurationsCreateAction.success(ddc));
    yield delay(100);
    yield call([message, 'success'], 'Lead deduplication updated successfully');

    yield put(
      actions.deduplicationConfigurationsRequestAction.request({
        supplySubAccountUuid: payload.supplySubAccountUuid,
      })
    );
  } catch (err: any) {
    yield put(actions.deduplicationConfigurationsDeleteAction.failure(err));
  }
}

function* watchDuplicateAction({
  payload: { demandSubAccountUuid, id },
}: ActionType<typeof actions.beginDuplicateRule>) {
  yield put(
    actions.duplicateRuleDemandAPIFilterRow({
      demandSubAccountUuid,
      id,
    })
  );
  yield put(actions.toggleCreateRuleDemandAPIFilterForm(true));
}

export function* originatorSaga() {
  yield takeLatest(getType(actions.beginDuplicateRule), watchDuplicateAction);

  yield takeLatest(
    getType(actions.demandRuleApiGetAllAction.request),
    getAllDemandRuleApiAction
  );

  yield takeLatest(
    getType(actions.partnerMappingBySubAccountUuidRequestAction.request),
    getPartnerMappingsForSubAccount
  );

  yield takeLatest(
    getType(actions.demandOfferCountsRequestAction.request),
    getDemandOfferCounts
  );

  yield takeLatest(
    getType(actions.supplyDemandInclusionCountsRequestAction.request),
    getSupplyDemandInclusionCounts
  );

  yield takeLatest(
    getType(actions.deduplicationConfigurationsRequestAction.request),
    getDeduplicationConfigurations
  );

  yield takeLatest(
    getType(actions.deduplicationConfigurationsDeleteAction.request),
    deleteDeduplicationConfiguration
  );

  yield takeLatest(
    getType(actions.deduplicationConfigurationsCreateAction.request),
    createDeduplicationConfiguration
  );

  yield takeLatest(
    getType(actions.stateExclusionsBySubAccountUuidRequestAction.request),
    getStateExclusions
  );

  yield takeLatest(
    getType(actions.updateStateExclusionsBySubAccountUuidRequestAction.request),
    updateStateExclusions
  );

  yield all([
    fork(demandInclusionSaga),
    fork(demandEnablementsSaga),
    fork(supplyDemandInclusionsSaga),
    fork(createBulkSupplyDemandInclusionsSaga),
    fork(deleteBulkSupplyDemandInclusionsSaga),
    fork(getOfferRulesByIdSaga),
    fork(watchRuleDemandAPIFiltersBySubAccountUuid),
    fork(watchCreateRuleDemandAPIFilter),
    fork(watchDeleteRuleDemandAPIFilter),
  ]);
}
