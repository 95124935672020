"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.internalManagerConfig = void 0;
exports.internalManagerConfig = {
    canApplyDemandInclusionsTemplate: true,
    canCreateDemandCaps: true,
    canCreateDemandInclusionTemplates: true,
    canCreateSupplyDemandCaps: true,
    canDeactivateAccounts: true,
    canDeactivateUsers: true,
    canDeleteDemandCaps: true,
    canDeleteSupplyDemandCaps: true,
    canEditDemandInclusionTemplates: true,
    canEditSecondLookOfferConfiguration: true,
    canEditSubAccountDisplayName: true,
    canEditSubAccountProductType: true,
    canEditSupplyTemplates: true,
    canManageAllAccounts: true,
    canManageDemandContracts: true,
    canManageFeatureFlags: true,
    canManageOfferCatalogOffers: true,
    canManageOfferCatalogProductTypes: true,
    canManageUserRoles: true,
    canReadDemandCaps: true,
    canReadDemandContracts: true,
    canReadOfferCatalogOffers: true,
    canReadOfferCatalogProductTypes: true,
    canReadRuleDemandApiFilters: true,
    canReadStateExclusions: true,
    canReadSupplyDemandCaps: true,
    canSeeAllAccounts: true,
    canUpdateDemandCaps: true,
    canUpdateSupplyDemandCaps: true,
    hasInternalUserRole: true,
    hasManagerRole: true,
    shouldComputeInternalPartnerKeyData: true,
    shouldGetDemandSubAccountsOnUserIndexPage: true,
    shouldGetSubAccountCompliance: true,
    shouldGetSupplySubAccountsOnUserDetailsPage: true,
    shouldGetSupplySubAccountsOnUserIndexPage: true,
    shouldGetUserDeactivationsOnUserDetailsPage: true,
    shouldRedirectIndexPageToAccount: true,
    shouldSetFeaturedTrustedPartnersOnPartnerPageUpdate: true,
    showAccountManagersForm: true,
    showCreateNewAccessTokenButton: true,
    showCreateNewDemandSubAccountButton: true,
    showCreateNewPartnerKeyButton: true,
    showCreateNewSupplySubAccountButton: true,
    showCreditCardSettingsCard: true,
    showDeleteContractButton: true,
    showDeletePartnerKeyButton: true,
    showExportButton: true,
    showInternalAccessTokenTable: true,
    showInternalAccountDetails: true,
    showInternalContractHistoryTable: true,
    showInternalSubAccountDetails: true,
    showInternalSupplyPartnersTable: true,
    showInternalSupplySubaccountDetails: true,
    showInternalUserDetails: true,
    showInternalUserFeaturedSideNavIfFeatureFlag: true,
    showInternalUserSideNav: true,
    showInternalUsersPage: true,
    showPartnerKeyItemDropdownActions: true,
    showPartnerKeyItemIntegrationStatus: true,
    showPartnerKeyItemLinkToPartnerPage: true,
    showPartnerPageAllowCoApplicantsField: true,
    showPartnerPageEmbedFeaturedOfferField: true,
    showPartnerPageFeatureFlagSelectField: true,
    showPartnerPageLoanPurposeSelectField: true,
    showPartnerPageThemeDisplayNameField: true,
    showPartnerPageThemeHeaderCopyField: true,
    showPartnerPageThemeHeaderTextField: true,
    showPartnerPageThemeInPartnershipWithField: true,
    showPartnerPageTrackingTab: true,
    showPersonalLoanDashboardSubAccountDropdown: true,
    showSubAccountNameColumn: true,
    showSubAccountNameLabel: true,
    showUserActivateDeactivateFormFields: true,
    showUserSettingsAccessTab: true,
};
