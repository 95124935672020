"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.onboardingContractSaga = exports.signOnboardingContracts = exports.getSignedOnboardingContracts = exports.getAllOnboardingContracts = void 0;
var effects_1 = require("redux-saga/effects");
var typesafe_actions_1 = require("typesafe-actions");
var api_client_1 = require("@portal/api-client");
var common_1 = require("@portal/common");
var selectors_1 = require("../user/selectors");
var actions = __importStar(require("./actions"));
function getAllOnboardingContracts() {
    var response, err_1, portalError;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, (0, effects_1.call)([api_client_1.client, 'get'], '/onboarding_contract')];
            case 1:
                response = (_a.sent()).data;
                return [4, (0, effects_1.put)(actions.getAllOnboardingContractsAction.success(response))];
            case 2:
                _a.sent();
                return [3, 5];
            case 3:
                err_1 = _a.sent();
                portalError = (0, common_1.getPortalError)(err_1);
                return [4, (0, effects_1.put)(actions.getAllOnboardingContractsAction.failure(portalError))];
            case 4:
                _a.sent();
                return [3, 5];
            case 5: return [2];
        }
    });
}
exports.getAllOnboardingContracts = getAllOnboardingContracts;
function getSignedOnboardingContracts() {
    var response, err_2, portalError;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, (0, effects_1.call)([api_client_1.client, 'get'], '/onboarding_contract/signed')];
            case 1:
                response = (_a.sent()).data;
                return [4, (0, effects_1.put)(actions.getSignedOnboardingContractsAction.success(response))];
            case 2:
                _a.sent();
                return [3, 5];
            case 3:
                err_2 = _a.sent();
                portalError = (0, common_1.getPortalError)(err_2);
                return [4, (0, effects_1.put)(actions.getSignedOnboardingContractsAction.failure(portalError))];
            case 4:
                _a.sent();
                return [3, 5];
            case 5: return [2];
        }
    });
}
exports.getSignedOnboardingContracts = getSignedOnboardingContracts;
function signOnboardingContracts(action) {
    var userId, onboardingContractTypes, _i, onboardingContractTypes_1, type, signature, err_3, portalError;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 9, , 11]);
                return [4, (0, effects_1.select)(selectors_1.selectEngineUser)];
            case 1:
                userId = (_a.sent()).id;
                onboardingContractTypes = action.payload.types;
                _i = 0, onboardingContractTypes_1 = onboardingContractTypes;
                _a.label = 2;
            case 2:
                if (!(_i < onboardingContractTypes_1.length)) return [3, 8];
                type = onboardingContractTypes_1[_i];
                return [4, (0, effects_1.call)([api_client_1.client, 'post'], '/onboarding_contract/sign', {
                        type: type,
                        userId: userId,
                    })];
            case 3:
                signature = (_a.sent()).data;
                return [4, (0, effects_1.put)(actions.signOnboardingContractsAction.success(signature))];
            case 4:
                _a.sent();
                return [4, (0, effects_1.put)(actions.getAllOnboardingContractsAction.request({}))];
            case 5:
                _a.sent();
                return [4, (0, effects_1.put)(actions.getSignedOnboardingContractsAction.request({}))];
            case 6:
                _a.sent();
                _a.label = 7;
            case 7:
                _i++;
                return [3, 2];
            case 8: return [3, 11];
            case 9:
                err_3 = _a.sent();
                portalError = (0, common_1.getPortalError)(err_3);
                return [4, (0, effects_1.put)(actions.signOnboardingContractsAction.failure(portalError))];
            case 10:
                _a.sent();
                return [3, 11];
            case 11: return [2];
        }
    });
}
exports.signOnboardingContracts = signOnboardingContracts;
function onboardingContractSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, (0, effects_1.takeLatest)((0, typesafe_actions_1.getType)(actions.getAllOnboardingContractsAction.request), getAllOnboardingContracts)];
            case 1:
                _a.sent();
                return [4, (0, effects_1.takeLatest)((0, typesafe_actions_1.getType)(actions.getSignedOnboardingContractsAction.request), getSignedOnboardingContracts)];
            case 2:
                _a.sent();
                return [4, (0, effects_1.takeEvery)((0, typesafe_actions_1.getType)(actions.signOnboardingContractsAction.request), signOnboardingContracts)];
            case 3:
                _a.sent();
                return [2];
        }
    });
}
exports.onboardingContractSaga = onboardingContractSaga;
