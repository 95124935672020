"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCommunicationEligibilityStatus = void 0;
const api_models_1 = require("@portal/api-models");
const getCommunicationEligibilityStatus = (communicationEligibilities) => {
    let transactionalEmail = false;
    let remarketingEmail = false;
    communicationEligibilities.forEach(({ channel, deletedAt }) => {
        if (channel === api_models_1.CommunicationChannel.transactionalEmail && !deletedAt) {
            transactionalEmail = true;
        }
        if (channel === api_models_1.CommunicationChannel.remarketingEmail && !deletedAt) {
            remarketingEmail = true;
        }
    });
    return { remarketingEmail, transactionalEmail };
};
exports.getCommunicationEligibilityStatus = getCommunicationEligibilityStatus;
