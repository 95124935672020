"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationType = exports.NotificationTopic = exports.NotificationService = void 0;
var NotificationService;
(function (NotificationService) {
    NotificationService["compliance"] = "compliance";
    NotificationService["portalApi"] = "portalApi";
})(NotificationService = exports.NotificationService || (exports.NotificationService = {}));
var NotificationTopic;
(function (NotificationTopic) {
    NotificationTopic["awaitingBankingInformation"] = "awaiting_banking_information";
    NotificationTopic["default"] = "default";
    NotificationTopic["migrationUpgrade"] = "migration_upgrade_success";
    NotificationTopic["planActivationReminder"] = "plan_activation_reminder";
    NotificationTopic["planPurchase"] = "plan_purchased";
    NotificationTopic["submitForInternalReview"] = "submit_for_internal_review";
    NotificationTopic["submittedForInternalReview"] = "submitted_for_internal_review";
    NotificationTopic["trialExpireReminder"] = "trial_expire_reminder";
})(NotificationTopic = exports.NotificationTopic || (exports.NotificationTopic = {}));
var NotificationType;
(function (NotificationType) {
    NotificationType["Error"] = "error";
    NotificationType["Info"] = "info";
    NotificationType["Success"] = "success";
    NotificationType["Warning"] = "warning";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));
