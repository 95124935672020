import { HYDRATE } from 'next-redux-wrapper';
import type { Reducer } from 'redux';

import type { HydrateAction } from '@/store/actions';
import { reconcileState } from '@/store/utils';

import { MessageActionType } from './types';
import type { MessageAction, MessageState } from './types';

export const initialState: MessageState = {
  loading: 0,
  text: 'Please wait',
};

export const reducer: Reducer<MessageState, MessageAction | HydrateAction> = (
  state: MessageState = initialState,
  action: MessageAction | HydrateAction
) => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...reconcileState(action.payload.message, initialState),
      };
    }

    case MessageActionType.UPDATE_TEXT: {
      return {
        ...state,
        text: action.payload.text,
      };
    }

    case MessageActionType.OVERLAY_LOADER: {
      return {
        ...state,
        overlay: action.payload.overlay,
      };
    }

    case MessageActionType.LOADING_START: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case MessageActionType.LOADING_END: {
      return {
        ...state,
        loading: Math.max(state.loading - 1, 0),
      };
    }

    default: {
      return state;
    }
  }
};
