export enum OriginatorActionType {
  BEGIN_DUPLICATE_RULE = '@@originator/BEGIN_DUPLICATE_RULE',

  CREATE_RULE_DEMAND_API_FILTER_FAILURE_CLIENT = '@@originator/CREATE_RULE_DEMAND_API_FILTER_FAILURE_CLIENT',
  CREATE_RULE_DEMAND_API_FILTER_REQUEST_CLIENT = '@@originator/CREATE_RULE_DEMAND_API_FILTER_REQUEST_CLIENT',
  CREATE_RULE_DEMAND_API_FILTER_SUCCESS_CLIENT = '@@originator/CREATE_RULE_DEMAND_API_FILTER_SUCCESS_CLIENT',

  CREATE_SUPPLY_TEMPLATE_APPLICATION_FAILURE = '@@originator/CREATE_SUPPLY_TEMPLATE_APPLICATION_FAILURE',
  CREATE_SUPPLY_TEMPLATE_APPLICATION_REQUEST = '@@originator/CREATE_SUPPLY_TEMPLATE_APPLICATION_REQUEST',
  CREATE_SUPPLY_TEMPLATE_APPLICATION_SUCCESS = '@@originator/CREATE_SUPPLY_TEMPLATE_APPLICATION_SUCCESS',

  CREATE_SUPPLY_TEMPLATE_FAILURE = '@@originator/CREATE_SUPPLY_TEMPLATE_FAILURE',
  CREATE_SUPPLY_TEMPLATE_REQUEST = '@@originator/CREATE_SUPPLY_TEMPLATE_REQUEST',
  CREATE_SUPPLY_TEMPLATE_SUCCESS = '@@originator/CREATE_SUPPLY_TEMPLATE_SUCCESS',

  DEACTIVATE_SUPPLY_TEMPLATE_FAILURE = '@@originator/DEACTIVATE_SUPPLY_TEMPLATE_FAILURE',
  DEACTIVATE_SUPPLY_TEMPLATE_REQUEST = '@@originator/DEACTIVATE_SUPPLY_TEMPLATE_REQUEST',
  DEACTIVATE_SUPPLY_TEMPLATE_SUCCESS = '@@originator/DEACTIVATE_SUPPLY_TEMPLATE_SUCCESS',

  DEDUPLICATION_CONFIGURATIONS_FAILURE = '@@originator/DEDUPLICATION_CONFIGURATIONS_FAILURE',
  DEDUPLICATION_CONFIGURATIONS_REQUEST = '@@originator/DEDUPLICATION_CONFIGURATIONS_REQUEST',
  DEDUPLICATION_CONFIGURATIONS_SUCCESS = '@@originator/DEDUPLICATION_CONFIGURATIONS_SUCCESS',

  DEDUPLICATION_CONFIGURATION_CREATE_FAILURE_CLIENT = '@@originator/DEDUPLICATION_CONFIGURATION_CREATE_FAILURE_CLIENT',
  DEDUPLICATION_CONFIGURATION_CREATE_REQUEST_CLIENT = '@@originator/DEDUPLICATION_CONFIGURATION_CREATE_REQUEST_CLIENT',
  DEDUPLICATION_CONFIGURATION_CREATE_SUCCESS_CLIENT = '@@originator/DEDUPLICATION_CONFIGURATION_CREATE_SUCCESS_CLIENT',

  DEDUPLICATION_CONFIGURATION_DELETE_FAILURE_CLIENT = '@@originator/DEDUPLICATION_CONFIGURATION_DELETE_FAILURE_CLIENT',
  DEDUPLICATION_CONFIGURATION_DELETE_REQUEST_CLIENT = '@@originator/DEDUPLICATION_CONFIGURATION_DELETE_REQUEST_CLIENT',
  DEDUPLICATION_CONFIGURATION_DELETE_SUCCESS_CLIENT = '@@originator/DEDUPLICATION_CONFIGURATION_DELETE_SUCCESS_CLIENT',

  DELETE_RULE_DEMAND_API_FILTERS_FAILURE = '@@originator/DELETE_RULE_DEMAND_API_FILTERS_FAILURE',
  DELETE_RULE_DEMAND_API_FILTERS_REQUEST = '@@originator/DELETE_RULE_DEMAND_API_FILTERS_REQUEST',
  DELETE_RULE_DEMAND_API_FILTER_SUCCESS = '@@originator/DELETE_RULE_DEMAND_API_FILTER_SUCCESS',

  DEMAND_OFFER_COUNTS_FAILURE = '@@originator/DEMAND_OFFER_COUNTS_FAILURE',
  DEMAND_OFFER_COUNTS_REQUEST = '@@originator/DEMAND_OFFER_COUNTS_REQUEST',
  DEMAND_OFFER_COUNTS_SUCCESS = '@@originator/DEMAND_OFFER_COUNTS_SUCCESS',

  DEMAND_RULE_API_GET_ALL_FAILURE = '@@originator/DEMAND_RULE_API_GET_ALL_FAILURE',
  DEMAND_RULE_API_GET_ALL_REQUEST = '@@originator/DEMAND_RULE_API_GET_ALL_REQUEST',
  DEMAND_RULE_API_GET_ALL_SUCCESS = '@@originator/DEMAND_RULE_API_GET_ALL_SUCCESS',

  DUPLICATE_RULE_DEMAND_API_FILTER_ROW = '@@originator/DUPLICATE_RULE_DEMAND_API_FILTER_ROW',

  GET_OFFER_RULES_PRODUCT_BY_PRODUCT_TYPE_FAILURE = '@@originator/GET_OFFER_RULES_PRODUCT_BY_PRODUCT_TYPE_FAILURE',
  GET_OFFER_RULES_PRODUCT_BY_PRODUCT_TYPE_REQUEST = '@@originator/GET_OFFER_RULES_PRODUCT_BY_PRODUCT_TYPE_REQUEST',
  GET_OFFER_RULES_PRODUCT_BY_PRODUCT_TYPE_SUCCESS = '@@originator/GET_OFFER_RULES_PRODUCT_BY_PRODUCT_TYPE_SUCCESS',

  GET_RATE_TABLES_BY_UUID_FAILURE = '@@originator/GET_RATE_TABLES_BY_UUID_FAILURE',
  GET_RATE_TABLES_BY_UUID_REQUEST = '@@originator/GET_RATE_TABLES_BY_UUID_REQUEST',
  GET_RATE_TABLES_BY_UUID_SUCCESS = '@@originator/GET_RATE_TABLES_BY_UUID_SUCCESS',

  GET_SUPPLY_TEMPLATES_ALL_FAILURE = '@@originator/GET_SUPPLY_TEMPLATES_ALL_FAILURE',
  GET_SUPPLY_TEMPLATES_ALL_REQUEST = '@@originator/GET_SUPPLY_TEMPLATES_ALL_REQUEST',
  GET_SUPPLY_TEMPLATES_ALL_SUCCESS = '@@originator/GET_SUPPLY_TEMPLATES_ALL_SUCCESS',

  GET_SUPPLY_TEMPLATE_APPLICATIONS_FAILURE = '@@originator/GET_SUPPLY_TEMPLATE_APPLICATIONS_FAILURE',
  GET_SUPPLY_TEMPLATE_APPLICATIONS_REQUEST = '@@originator/GET_SUPPLY_TEMPLATE_APPLICATIONS_REQUEST',
  GET_SUPPLY_TEMPLATE_APPLICATIONS_SUCCESS = '@@originator/GET_SUPPLY_TEMPLATE_APPLICATIONS_SUCCESS',

  INCLUSIONS_TEMPLATES_GET_ALL_FAILURE = '@@originator/inclusions_templates/GET_ALL_FAILURE',
  INCLUSIONS_TEMPLATES_GET_ALL_REQUEST = '@@originator/inclusions_templates/GET_ALL_REQUEST',
  INCLUSIONS_TEMPLATES_GET_ALL_SUCCESS = '@@originator/inclusions_templates/GET_ALL_SUCCESS',

  INITIALIZE_WORKING_RULE_FILTER_ROW = '@@originator/INITIALIZE_WORKING_RULE_FILTER_ROW',

  PARTNER_MAPPINGS_BY_SUBACCOUNT_UUID_FAILURE = '@@originator/PARTNER_MAPPINGS_BY_SUBACCOUNT_UUID_FAILURE',
  PARTNER_MAPPINGS_BY_SUBACCOUNT_UUID_REQUEST = '@@originator/PARTNER_MAPPINGS_BY_SUBACCOUNT_UUID_REQUEST',
  PARTNER_MAPPINGS_BY_SUBACCOUNT_UUID_SUCCESS = '@@originator/PARTNER_MAPPINGS_BY_SUBACCOUNT_UUID_SUCCESS',

  RULE_DEMAND_API_FILTERS_BY_SUB_ACCOUNT_UUID_FAILURE = '@@originator/RULE_DEMAND_API_FILTERS_BY_SUB_ACCOUNT_UUID_FAILURE',
  RULE_DEMAND_API_FILTERS_BY_SUB_ACCOUNT_UUID_REQUEST = '@@originator/RULE_DEMAND_API_FILTERS_BY_SUB_ACCOUNT_UUID_REQUEST',
  RULE_DEMAND_API_FILTERS_BY_SUB_ACCOUNT_UUID_SUCCESS = '@@originator/RULE_DEMAND_API_FILTERS_BY_SUB_ACCOUNT_UUID_SUCCESS',

  STATE_EXCLUSIONS_BY_SUBACCOUNT_UUID_FAILURE = '@@originator/STATE_EXCLUSIONS_BY_SUBACCOUNT_UUID_FAILURE',
  STATE_EXCLUSIONS_BY_SUBACCOUNT_UUID_REQUEST = '@@originator/STATE_EXCLUSIONS_BY_SUBACCOUNT_UUID_REQUEST',
  STATE_EXCLUSIONS_BY_SUBACCOUNT_UUID_SUCCESS = '@@originator/STATE_EXCLUSIONS_BY_SUBACCOUNT_UUID_SUCCESS',

  SUPPLY_DEMAND_INCLUSION_COUNTS_FAILURE = '@@originator/SUPPLY_DEMAND_INCLUSION_COUNTS_FAILURE',
  SUPPLY_DEMAND_INCLUSION_COUNTS_REQUEST = '@@originator/SUPPLY_DEMAND_INCLUSION_COUNTS_REQUEST',
  SUPPLY_DEMAND_INCLUSION_COUNTS_SUCCESS = '@@originator/SUPPLY_DEMAND_INCLUSION_COUNTS_SUCCESS',

  TOGGLE_CREATE_RULE_FORM = '@@originator/TOGGLE_CREATE_RULE_FORM',

  UPDATE_STATE_EXCLUSIONS_BY_SUBACCOUNT_UUID_FAILURE = '@@originator/UPDATE_STATE_EXCLUSIONS_BY_SUBACCOUNT_UUID_FAILURE',
  UPDATE_STATE_EXCLUSIONS_BY_SUBACCOUNT_UUID_REQUEST = '@@originator/UPDATE_STATE_EXCLUSIONS_BY_SUBACCOUNT_UUID_REQUEST',
  UPDATE_STATE_EXCLUSIONS_BY_SUBACCOUNT_UUID_SUCCESS = '@@originator/UPDATE_STATE_EXCLUSIONS_BY_SUBACCOUNT_UUID_SUCCESS',

  UPDATE_SUPPLY_TEMPLATE_REQUEST = '@@originator/UPDATE_SUPPLY_TEMPLATE_REQUEST',
}
