import * as React from 'react';
import { useMemo } from 'react';

import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Tooltip } from 'antd';
import type { MenuItemProps } from 'antd/lib/menu/MenuItem';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { useRouter } from 'next/router';

import { Link } from '@/routes';

export interface RouterAwareMenuItemProps
  extends React.PropsWithChildren<MenuItemProps> {
  /** Additional paths to highlight menu item */
  additionalPaths?: string[];
  className?: string;
  disabled?: boolean;
  eventKey?: string;
  href: string;
  icon?: React.ReactElement;
  key?: string;
  persistLockedStyle?: boolean;
  prefetch?: boolean;
  showDisabledIcon?: boolean;
  showText?: boolean;
  text: string;
  tooltipText?: string;
}

const MaybeTooltip: React.FC<React.PropsWithChildren<any>> = ({
  children,
  title,
  ...props
}) =>
  title ? (
    <Tooltip {...props} title={title}>
      {children}
    </Tooltip>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );

export const RouterAwareMenuItem: React.FC<RouterAwareMenuItemProps> = (
  props
) => {
  const {
    additionalPaths = [],
    className,
    disabled,
    href,
    persistLockedStyle,
    showDisabledIcon,
    text,
    tooltipText,
  } = props;

  const { asPath: currentPath } = useRouter();
  const { Item } = Menu;

  const isSelected = useMemo(() => {
    return [href, ...additionalPaths].some((targetPath) => {
      const isHome = currentPath === '/home' && targetPath === '/home';

      return (
        isHome || (targetPath !== '/home' && currentPath.includes(targetPath))
      );
    });
  }, [href, additionalPaths, currentPath]);

  return (
    <MaybeTooltip title={tooltipText}>
      <Item
        {...omit(props, [
          'additionalPaths',
          'showDisabledIcon',
          'disabled',
          'persistLockedStyle',
          'tooltipText',
        ])}
        className={classNames('ant-menu-item nav-menu-item', className, {
          'ant-menu-item-locked': persistLockedStyle && !isSelected,
          'ant-menu-item-selected': isSelected,
        })}
        data-qa={`menu-item-${href.slice(1).replace(/\//g, '-')}`}
        disabled={!isSelected && disabled && !persistLockedStyle}
      >
        <Link route={href}>
          <a>
            <span className="nav-menu-item-text">{text}</span>
            {(disabled || showDisabledIcon) &&
              (!isSelected || persistLockedStyle) && (
                <FontAwesomeIcon
                  className="nav-menu-item-locked-icon"
                  icon={faLock}
                />
              )}
          </a>
        </Link>
      </Item>
    </MaybeTooltip>
  );
};
