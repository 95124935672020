"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnboardingContractActionType = void 0;
var OnboardingContractActionType;
(function (OnboardingContractActionType) {
    OnboardingContractActionType["GET_ALL_ONBOARDING_CONTRACTS_FAILURE"] = "@@onboarding-contract/GET_ALL_ONBOARDING_CONTRACTS_FAILURE";
    OnboardingContractActionType["GET_ALL_ONBOARDING_CONTRACTS_REQUEST"] = "@@onboarding-contract/GET_ALL_ONBOARDING_CONTRACTS_REQUEST";
    OnboardingContractActionType["GET_ALL_ONBOARDING_CONTRACTS_SUCCESS"] = "@@onboarding-contract/GET_ALL_ONBOARDING_CONTRACTS_SUCCESS";
    OnboardingContractActionType["GET_SIGNED_ONBOARDING_CONTRACTS_FAILURE"] = "@@onboarding-contract/GET_SIGNED_ONBOARDING_CONTRACTS_FAILURE";
    OnboardingContractActionType["GET_SIGNED_ONBOARDING_CONTRACTS_REQUEST"] = "@@onboarding-contract/GET_SIGNED_ONBOARDING_CONTRACTS_REQUEST";
    OnboardingContractActionType["GET_SIGNED_ONBOARDING_CONTRACTS_SUCCESS"] = "@@onboarding-contract/GET_SIGNED_ONBOARDING_CONTRACTS_SUCCESS";
    OnboardingContractActionType["SIGN_ONBOARDING_CONTRACT_FAILURE_CLIENT"] = "@@onboarding-contract/SIGN_ONBOARDING_CONTRACT_FAILURE_CLIENT";
    OnboardingContractActionType["SIGN_ONBOARDING_CONTRACT_REQUEST_CLIENT"] = "@@onboarding-contract/SIGN_ONBOARDING_CONTRACT_REQUEST_CLIENT";
    OnboardingContractActionType["SIGN_ONBOARDING_CONTRACT_SUCCESS_CLIENT"] = "@@onboarding-contract/SIGN_ONBOARDING_CONTRACT_SUCCESS_CLIENT";
})(OnboardingContractActionType = exports.OnboardingContractActionType || (exports.OnboardingContractActionType = {}));
