import * as React from 'react';

import { Select } from 'antd';

import {
  ConfigProductType,
  CreditScoreOptions,
  TrustedPartnersSectionLocation,
} from '@evenfinancial/even-ts-static';
import type { ProductType } from '@evenfinancial/finance-client';

import type {
  TempAvailableDemandPartner,
  TempFeaturedDemandPartner,
} from '@portal/api-models';

import InputFeatureFlagSelect from '@/components/form/feature-flag-select/index';
import type { LegacyPortalFormItem } from '@/components/form/legacy-portal-form';
import { EnumDropdown } from '@/components/form/option-dropdown';

import { PKDSTooltips } from './tooltips';

const { Option } = Select;

export const decorateInitialValues = (
  values: Record<string, any>,
  fields: LegacyPortalFormItem[]
) =>
  fields.map((formItem) => {
    const tooltip = PKDSTooltips[formItem.name];

    return {
      ...formItem,
      fieldDecoratorProps: {
        ...formItem.fieldDecoratorProps,
        initialValue:
          values[formItem.name] ?? formItem.fieldDecoratorProps?.initialValue,
      },
      tooltip,
    };
  });

export const productIsTypePredicate =
  (...types: string[]) =>
  (form: any) =>
    types.includes(form.productType);

export const productIsNotTypePredicate =
  (...types: string[]) =>
  (form: any) =>
    !types.includes(form.productType);

export const predicatesAnd =
  (...predicates: Array<(v: any) => boolean>) =>
  (value: any) =>
    predicates.map((p) => p(value)).every((p) => p);

export const predicatesOr =
  (...predicates: Array<(v: any) => boolean>) =>
  (value: any) =>
    predicates.map((p) => p(value)).some((p) => p);

const trustedPartnersLocationOptions = (productType: string) => {
  if (productType === ConfigProductType.CreditCard) {
    return TrustedPartnersSectionLocation;
  }

  return {
    None: TrustedPartnersSectionLocation.None,
    PartnerPage: TrustedPartnersSectionLocation.PartnerPage,
  };
};

export const sharedFields = (
  productType: ProductType,
  partnerPageConfig: any,
  availableTrustedPartners: TempAvailableDemandPartner[],
  featuredTrustedPartners: TempFeaturedDemandPartner[],
  showPartnerPageFeatureFlagSelectField: boolean
): LegacyPortalFormItem[] => [
  {
    fieldDecoratorProps: {
      rules: [],
    },
    fieldElement: (
      <Select
        getPopupContainer={(triggerNode) =>
          triggerNode.parentNode as HTMLElement
        }
      >
        <Option value={CreditScoreOptions.EXCELLENT}>
          Excellent (&gt; 719)
        </Option>
        <Option value={CreditScoreOptions.GOOD}>Good (660 - 719)</Option>
        <Option value={CreditScoreOptions.FAIR}>Fair (620 - 659)</Option>
        <Option value={CreditScoreOptions.POOR}>Poor (&lt; 620)</Option>
        <Option value={''}>None</Option>
      </Select>
    ),
    itemProps: {
      label: 'Default Credit Rating',
    },
    name: 'app.providedCreditRating',
    order: 99,
    predicate: productIsTypePredicate(
      ConfigProductType.Loan,
      ConfigProductType.CreditCard
    ),
  },
  {
    fieldDecoratorProps: {
      rules: [
        {
          message: 'Must be a valid zip code',
          pattern: /^(\d{5}(?:-\d{4})?)$/,
          required: false,
        },
      ],
    },
    itemProps: {
      label: 'Fallback ZIP Code',
    },
    name: 'app.zipcode',
    order: 99,
    predicate: productIsTypePredicate(
      ConfigProductType.Loan,
      ConfigProductType.Deposits
    ),
  },
  {
    fieldDecoratorProps: {
      initialValue:
        partnerPageConfig.show_trusted_partners ||
        TrustedPartnersSectionLocation.None,
      rules: [],
    },
    fieldElement: (
      <Select
        getPopupContainer={(triggerNode) =>
          triggerNode.parentNode as HTMLElement
        }
      >
        {EnumDropdown(trustedPartnersLocationOptions(productType))}
      </Select>
    ),
    itemProps: {
      label: 'Show Trusted Partners?',
    },
    name: 'show_trusted_partners',
    predicate: productIsNotTypePredicate(ConfigProductType.LifeInsurance),
  },
  {
    fieldDecoratorProps: {
      initialValue: featuredTrustedPartners.map(
        (featuredTrustedPartner) => featuredTrustedPartner.demandSubAccountId
      ),
      rules: [],
    },
    fieldElement: (
      <Select
        allowClear
        getPopupContainer={(triggerNode) =>
          triggerNode.parentNode as HTMLElement
        }
        mode="multiple"
      >
        {availableTrustedPartners &&
          availableTrustedPartners.map(
            (availableTrustedPartner: TempAvailableDemandPartner) => (
              <Option
                key={`trusted-parter-${availableTrustedPartner.demandSubAccountId}`}
                value={availableTrustedPartner.demandSubAccountId}
              >
                {availableTrustedPartner.demandSubAccountName}
              </Option>
            )
          )}
      </Select>
    ),
    itemProps: {
      label: 'Featured Trusted Partners Logos',
    },
    name: 'featured_trusted_partners',
    predicate: predicatesAnd(
      productIsNotTypePredicate(ConfigProductType.LifeInsurance),
      (form) =>
        form.show_trusted_partners !== TrustedPartnersSectionLocation.None
    ),
  },
  {
    fieldDecoratorProps: {
      rules: [],
    },
    fieldElement: <InputFeatureFlagSelect />,
    itemProps: {
      label: 'Feature Flags',
    },
    name: 'featureFlags',
    order: 99,
    predicate: () => showPartnerPageFeatureFlagSelectField,
  },
];
