"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleCreate = exports.deleteFeatureFlagAction = exports.disableFeatureFlagAction = exports.enableFeatureFlagAction = exports.updateFeatureFlagAction = exports.createFeatureFlagAction = exports.getFeatureFlagByUuidAction = exports.getAllFeatureFlagsAction = void 0;
var typesafe_actions_1 = require("typesafe-actions");
var actionTypes_1 = require("./actionTypes");
exports.getAllFeatureFlagsAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.FeatureFlagActionType.GET_ALL_FEATURE_FLAGS_REQUEST, actionTypes_1.FeatureFlagActionType.GET_ALL_FEATURE_FLAGS_SUCCESS, actionTypes_1.FeatureFlagActionType.GET_ALL_FEATURE_FLAGS_FAILURE)();
exports.getFeatureFlagByUuidAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.FeatureFlagActionType.GET_FEATURE_FLAG_BY_UUID_REQUEST, actionTypes_1.FeatureFlagActionType.GET_FEATURE_FLAG_BY_UUID_SUCCESS, actionTypes_1.FeatureFlagActionType.GET_FEATURE_FLAG_BY_UUID_FAILURE)();
exports.createFeatureFlagAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.FeatureFlagActionType.CREATE_FEATURE_FLAG_REQUEST, actionTypes_1.FeatureFlagActionType.CREATE_FEATURE_FLAG_SUCCESS, actionTypes_1.FeatureFlagActionType.CREATE_FEATURE_FLAG_FAILURE)();
exports.updateFeatureFlagAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.FeatureFlagActionType.UPDATE_FEATURE_FLAG_REQUEST, actionTypes_1.FeatureFlagActionType.UPDATE_FEATURE_FLAG_SUCCESS, actionTypes_1.FeatureFlagActionType.UPDATE_FEATURE_FLAG_FAILURE)();
exports.enableFeatureFlagAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.FeatureFlagActionType.ENABLE_FEATURE_FLAG_REQUEST, actionTypes_1.FeatureFlagActionType.ENABLE_FEATURE_FLAG_SUCCESS, actionTypes_1.FeatureFlagActionType.ENABLE_FEATURE_FLAG_FAILURE)();
exports.disableFeatureFlagAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.FeatureFlagActionType.DISABLE_FEATURE_FLAG_REQUEST, actionTypes_1.FeatureFlagActionType.DISABLE_FEATURE_FLAG_SUCCESS, actionTypes_1.FeatureFlagActionType.DISABLE_FEATURE_FLAG_FAILURE)();
exports.deleteFeatureFlagAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.FeatureFlagActionType.DELETE_FEATURE_FLAG_REQUEST, actionTypes_1.FeatureFlagActionType.DELETE_FEATURE_FLAG_SUCCESS, actionTypes_1.FeatureFlagActionType.DELETE_FEATURE_FLAG_FAILURE)();
exports.toggleCreate = (0, typesafe_actions_1.createAction)(actionTypes_1.FeatureFlagActionType.TOGGLE_CREATE)();
