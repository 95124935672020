import { createAsyncAction } from 'typesafe-actions';

import type { OfferRule, ProductType } from '@evenfinancial/originator-client';
import type { SupplyDemandInclusion } from '@evenfinancial/partner-data-client';

import type { DemandEnablement } from '@portal/ui-lib';

import type {
  CreateSupplyTemplateApplicationPayload,
  DemandEnablementDeletePayload,
  DemandEnablementRequestPayload,
  DemandEnablementUpdatePayload,
} from '../types';

import { DemandInclusionActionType } from './actionTypes';

export const applyDemandInclusionTemplateAction = createAsyncAction(
  DemandInclusionActionType.APPLY_DEMAND_INCLUSION_TEMPLATE_REQUEST,
  DemandInclusionActionType.APPLY_DEMAND_INCLUSION_TEMPLATE_SUCCESS,
  DemandInclusionActionType.APPLY_DEMAND_INCLUSION_TEMPLATE_FAILURE
)<CreateSupplyTemplateApplicationPayload, undefined, unknown>();

export const demandInclusionsRequestAction = createAsyncAction(
  DemandInclusionActionType.GET_DEMAND_INCLUSIONS_REQUEST,
  DemandInclusionActionType.GET_DEMAND_INCLUSIONS_SUCCESS,
  DemandInclusionActionType.GET_DEMAND_INCLUSIONS_FAILURE
)<DemandEnablementRequestPayload, SupplyDemandInclusion[], Error>();

export const demandInclusionsCreateBulkAction = createAsyncAction(
  DemandInclusionActionType.CREATE_BULK_DEMAND_INCLUSIONS_REQUEST,
  DemandInclusionActionType.CREATE_BULK_DEMAND_INCLUSIONS_SUCCESS,
  DemandInclusionActionType.CREATE_BULK_DEMAND_INCLUSIONS_FAILURE
)<DemandEnablementUpdatePayload[], SupplyDemandInclusion[], Error[]>();

export const demandInclusionsDeleteBulkAction = createAsyncAction(
  DemandInclusionActionType.DELETE_BULK_DEMAND_INCLUSIONS_REQUEST,
  DemandInclusionActionType.DELETE_BULK_DEMAND_INCLUSIONS_SUCCESS,
  DemandInclusionActionType.DELETE_BULK_DEMAND_INCLUSIONS_FAILURE
)<DemandEnablementDeletePayload[], number[], Error[]>();

export const demandEnablementsRequestAction = createAsyncAction(
  DemandInclusionActionType.GET_DEMAND_ENABLEMENTS_REQUEST,
  DemandInclusionActionType.GET_DEMAND_ENABLEMENTS_SUCCESS,
  DemandInclusionActionType.GET_DEMAND_ENABLEMENTS_FAILURE
)<{ productType: ProductType }, DemandEnablement, Error>();

export const getOfferRulesBySubaccountIdAction = createAsyncAction(
  DemandInclusionActionType.GET_OFFER_RULES_BY_SUBACCOUNT_ID_REQUEST,
  DemandInclusionActionType.GET_OFFER_RULES_BY_SUBACCOUNT_ID_SUCCESS,
  DemandInclusionActionType.GET_OFFER_RULES_BY_SUBACCOUNT_ID_FAILURE
)<{ supplySubAccountId: number }, OfferRule[], Error>();
