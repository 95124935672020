import { createSelector } from 'reselect';

import type { ApplicationState } from '@/store';

export const selectLayout = (state: ApplicationState) => state?.interface;

export const getSideNavCollapsed = createSelector(
  [selectLayout],
  (ui) => ui?.sidenavCollapsed
);

export const getSideNavFullScreen = createSelector(
  [selectLayout],
  (ui) => ui?.fullscreenMode
);
