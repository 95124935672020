import {
  AccountTierType,
  SaasFeeReason,
  SaasPlanPaymentCadence,
} from '@evenfinancial/finance-client';
import type { SaasFee, SaasPlan } from '@evenfinancial/finance-client';

import { ImplementationFeePaymentStatus } from '@/resources/integrations/types';
import type { IntegrationRow } from '@/resources/integrations/types';
import { IntegrationCategory } from '@/resources/sub-accounts-shared/utils';

export const isFeePaymentPreApprovalRequired = () => false;

enum DefaultSaasReasonPrice {
  PartnerPage = 2499,
  Embed = 4999,
  Native = 1250,
}

interface CurrentPlanSubscriptionData {
  embedImplementationFee?: number;
  monthlyFeeGrowth?: number;
  monthlyFeeStarter?: number;
  nativeImplementationFee?: number;
  partnerPageImplementationFee?: number;
}

/**
 * TODO: Only accept IntegrationCategory here.
 * Will have to audit usage of this function in order to do so.
 */
export const getIntegrationImplementationSaasFeeReason = (
  integrationCategory: IntegrationCategory
): SaasFeeReason | undefined => {
  switch (integrationCategory) {
    case IntegrationCategory.Embed:
      return SaasFeeReason.EmbedImplementation;

    case IntegrationCategory.API:
      return SaasFeeReason.NativeImplementation;

    case IntegrationCategory.PartnerPage:
      return SaasFeeReason.PartnerPageImplementation;

    default:
  }
};

export const getSaasFeeReasonPrice = (
  reason?: SaasFeeReason,
  plan?: SaasPlan
): number => {
  switch (reason) {
    case SaasFeeReason.EmbedImplementation:
      return plan?.embedImplementationFee ?? DefaultSaasReasonPrice.Embed;

    case SaasFeeReason.NativeImplementation:
      return plan?.nativeImplementationFee ?? DefaultSaasReasonPrice.Native;

    case SaasFeeReason.PartnerPageImplementation:
      return (
        plan?.partnerPageImplementationFee ?? DefaultSaasReasonPrice.PartnerPage
      );

    default:
      return 0;
  }
};

export const getIntegrationData = (
  integrations: IntegrationRow[],
  saasPlan?: SaasPlan,
  integrationId?: string
) => {
  const integration = integrations.find((i) => i.id === integrationId);
  const integrationType = integration?.integrationCategory;

  const saasFeeReason = integrationType
    ? getIntegrationImplementationSaasFeeReason(integrationType)
    : undefined;

  const integrationFeePrice = getSaasFeeReasonPrice(saasFeeReason, saasPlan);

  const accessTokenUuid = integration?.accessTokenUuid;

  return {
    accessTokenUuid,
    companyUuid: integration?.companyUuid,
    integrationFee: integrationFeePrice,
    integrationId,
    integrationType,
    saasFeeReason: integrationId ? saasFeeReason : undefined,
  };
};

export const getSaasFeeData = (accessTokenUuid: string, fees: SaasFee[]) => {
  const fee = fees.find(
    (saasFee) =>
      saasFee.accessTokenUuid && accessTokenUuid === saasFee.accessTokenUuid
  );

  return fee
    ? ImplementationFeePaymentStatus.Paid
    : ImplementationFeePaymentStatus.Unpaid;
};

export const saasPlanHasFees = (plan: SaasPlan) =>
  plan.partnerPageImplementationFee ||
  plan.embedImplementationFee ||
  plan.nativeImplementationFee;

export const matchPlanByWaiveImplementationFee = (
  plan: SaasPlan,
  waiveImplementationFee: boolean
) => {
  const hasFees = saasPlanHasFees(plan);

  return waiveImplementationFee
    ? !hasFees && !plan.defaultPlan
    : hasFees && plan.defaultPlan;
};

export const matchPlanByUserWhitelist = (
  plan: SaasPlan,
  waiveImplementationFee: boolean
): boolean => {
  if (waiveImplementationFee && !plan.defaultPlan) {
    return true;
  }

  return false;
};

export const matchPlanFilter = (
  plan: SaasPlan,
  waiveImplementationFee: boolean
) =>
  !plan.deletedAt &&
  (plan.defaultPlan || matchPlanByUserWhitelist(plan, waiveImplementationFee));

export const findMatchingSaasPlan = (
  plans: SaasPlan[],
  tier: AccountTierType,
  duration: number,
  paymentCadence: SaasPlanPaymentCadence,
  waiveImplementationFee: boolean,
  findMigrationPlan: boolean
): SaasPlan | undefined => {
  return plans.find(
    (plan) =>
      !plan.deletedAt &&
      plan.tier === tier &&
      plan.months === duration &&
      plan.paymentCadence === paymentCadence &&
      (findMigrationPlan ||
        matchPlanByWaiveImplementationFee(plan, waiveImplementationFee))
  );
};

export const findMigrationSettingsSaasPlan = (
  plans: SaasPlan[],
  tier: AccountTierType
): SaasPlan | undefined => {
  return findMatchingSaasPlan(
    plans,
    tier,
    3,
    SaasPlanPaymentCadence.Monthly,
    false,
    true
  );
};

export const findSaasPlan = (plans: SaasPlan[], tier: AccountTierType) => {
  return plans.find((plan) => !plan.deletedAt && plan.tier === tier);
};

export const findDefaultSaasPlan = (
  plans: SaasPlan[],
  tier: AccountTierType
) => {
  return plans.find(
    (plan) => !plan.deletedAt && plan.defaultPlan && plan.tier === tier
  );
};

export const findDefaultSaasPlanByWhitelist = (
  plans: SaasPlan[],
  tier: AccountTierType,
  waiveImplementationFee: boolean,
  firstRolloutOnboarding: boolean,
  findMigrationPlan: boolean
) =>
  (waiveImplementationFee && firstRolloutOnboarding) || findMigrationPlan
    ? findMatchingSaasPlan(
        plans,
        tier,
        3,
        SaasPlanPaymentCadence.Monthly,
        waiveImplementationFee,
        findMigrationPlan
      )
    : findDefaultSaasPlan(plans, tier);

type UpgradableAccountTierType = Pick<
  typeof AccountTierType,
  'Starter' | 'Growth' | 'Scale'
>;

type UpgradableAccountTierTypeValues =
  UpgradableAccountTierType[keyof UpgradableAccountTierType];

const tiers: UpgradableAccountTierTypeValues[] = [
  AccountTierType.Starter,
  AccountTierType.Growth,
  AccountTierType.Scale,
];

export const getNextUpgradeTier = (tier: AccountTierType) => {
  const index = tiers.indexOf(tier as UpgradableAccountTierTypeValues);
  const nextIndex = index + 1;

  return index !== -1 && nextIndex < tier.length ? tiers[nextIndex] : undefined;
};

export const getPreviousTiers = (tier: AccountTierType): AccountTierType[] => {
  const index = tiers.indexOf(tier as UpgradableAccountTierTypeValues);

  return tiers.slice(0, index);
};

export const currentPlanSubscriptionData = (
  currentPlan: SaasPlan
): CurrentPlanSubscriptionData => {
  return {
    embedImplementationFee: currentPlan.embedImplementationFee,
    monthlyFeeGrowth:
      currentPlan.tier === AccountTierType.Growth
        ? currentPlan.monthlyFee
        : null,
    monthlyFeeStarter:
      currentPlan.tier === AccountTierType.Starter
        ? currentPlan.monthlyFee
        : null,
    nativeImplementationFee: currentPlan.nativeImplementationFee,
    partnerPageImplementationFee: currentPlan.partnerPageImplementationFee,
  } as CurrentPlanSubscriptionData;
};
