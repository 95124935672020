import toNumber from 'lodash/toNumber';
import moment from 'moment';

import type {
  AccountTierType,
  SaasMigrationSettingCreateData,
} from '@evenfinancial/finance-client';

import { FeeType, TrialDuration } from '@portal/api-models';
import type {
  FeeData,
  PortalSaasMigrationSetting,
  PricingTier,
} from '@portal/api-models';

import type { MigrationSettingsForm } from '@/components/accounts/migration-settings/form';

export enum NewMigrationFeature {
  HomepageTutorial = 'migration_homepage_tutorial',
  IntegrationsTutorial = 'migration_integrations_tutorial',
  PerformanceSummaryTutorial = 'migration_performance_summary_tutorial',
  UsersTutorial = 'migration_users_tutorial',
}

const toPricingTier = (settings: PortalSaasMigrationSetting): PricingTier => {
  let type: string = 'standard';

  if (
    settings.monthlyFee !== undefined ||
    settings.nativeImplementationFee !== undefined ||
    settings.partnerPageImplementationFee !== undefined ||
    settings.embedImplementationFee !== undefined
  ) {
    type = 'custom';
  }

  return `${settings.tier}_${type}` as PricingTier;
};

export const isCustomTier = (pricingTier: PricingTier): boolean =>
  pricingTier?.includes('custom');

export const isCustomTierSettings = (
  settings: PortalSaasMigrationSetting
): boolean => isCustomTier(toPricingTier(settings));

const toFeeData = (pricing: number | undefined): FeeData => ({
  pricing,
  type:
    pricing === undefined || pricing === 0
      ? FeeType.Waived
      : FeeType.CustomPricing,
});

const toFeeNumber = (feeData: FeeData | undefined) => {
  if (feeData?.type === FeeType.Waived) {
    return 0;
  }

  if (feeData?.type === FeeType.CustomPricing) {
    return feeData?.pricing || 0;
  }

  return undefined;
};

/**
 * Convert MigrationSettingsForm to SupplyMigrationWriteData.
 *
 * @param {number} financeAccountId
 * @param {MigrationSettingsForm} form
 * @return {*} {SaasMigrationSettingCreateData}
 */
export const toSaasMigrationCreateData = (
  financeAccountId: number,
  form: MigrationSettingsForm
): SaasMigrationSettingCreateData => ({
  accountId: financeAccountId,
  embedImplementationFee:
    isCustomTier(form.pricingTier) && !form.pricingTier.includes('starter')
      ? toFeeNumber(form.embedImplementationFeeData)
      : undefined,
  hardMigrationDate: form.trialHardMigrationDate.toISOString(),
  integrationKeysToMigrate: form.integrations,
  legacyAccount: form.legacyAccount,
  monthlyFee: isCustomTier(form.pricingTier)
    ? toFeeNumber(form.platformFeeData)
    : undefined,
  partnerPageImplementationFee: isCustomTier(form.pricingTier)
    ? toFeeNumber(form.partnerPageImplementationFeeData)
    : undefined,
  softMigrationDate: form.trialStartDate.toISOString(),

  subAccountIdsToMigrate: form.subAccounts,

  tier: form.pricingTier.split('_')[0] as AccountTierType,

  trialPeriodDurationDays: toNumber(form.trialDuration),
  // Uncomment when native implementation fee is added to form.
  // nativeImplementationFee: toFeeNumber(form.nativeImplementationFeeData),
  userIdsToMigrate: form.users || [],
});

/**
 * Convert SaasMigration to MigrationSettingsForm.
 *
 * @param {SaasMigrationSetting} settings
 * @return {*}  {MigrationSettingsForm}
 */
export const toMigrationSettingsForm = (
  settings: PortalSaasMigrationSetting
): MigrationSettingsForm => ({
  embedImplementationFeeData: toFeeData(settings.embedImplementationFee),
  integrations: settings.integrationKeysToMigrate ?? [],
  legacyAccount: Boolean(settings.legacyAccount),
  migratedAt: settings.migratedAt ? moment(settings.migratedAt) : undefined,
  partnerPageImplementationFeeData: toFeeData(
    settings.partnerPageImplementationFee
  ),
  platformFeeData: toFeeData(settings.monthlyFee),
  pricingTier: toPricingTier(settings),
  subAccounts: (settings.subAccountIdsToMigrate ?? []) as any,
  trialDuration: settings.trialPeriodDurationDays,
  trialHardMigrationDate: moment(settings.hardMigrationDate),
  trialStartDate: moment(settings.softMigrationDate),
  users: settings.userIdsToMigrate ?? [],
});

export const isPermanentTrial = (
  settings?: Partial<PortalSaasMigrationSetting>
): boolean =>
  settings?.trialPeriodDurationDays ===
  toNumber(TrialDuration.ThreeThousandSixHundredFifty);
