import type { AxiosResponse } from 'axios';
import {
  all,
  call,
  fork,
  join,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import type { ActionType } from 'typesafe-actions';

import { SupplyTemplateApplicationStrategy } from '@evenfinancial/originator-client';
import type {
  SupplyTemplate,
  SupplyTemplateApplication,
} from '@evenfinancial/originator-client';

import { client } from '@portal/api-client';
import { selectUsers } from '@portal/store/dist/user/selectors';
import { getUserFullName } from '@portal/store/dist/user/util';
import { createCrudToaster } from '@portal/ui-lib';

import { error } from '../../message/actions';
import * as actions from '../actions';
import { selectSupplyTemplatesKeyedByUuid } from '../selectors';

import { getSupplyTemplates } from './supply-template';

const supplyTemplateApplicationToaster = createCrudToaster(
  'Supply Template Application'
);

export function* createSupplyTemplateApplication(
  action: ActionType<
    typeof actions.createSupplyTemplateApplicationAction.request
  >
) {
  const { payload } = action;

  try {
    yield call(supplyTemplateApplicationToaster.createLoading);

    const { data: response }: AxiosResponse<SupplyTemplateApplication> =
      yield call(
        [client, 'post'],
        '/originator/supply_template_applications',
        payload.data
      );

    yield put(actions.createSupplyTemplateApplicationAction.success(response));
    yield call(supplyTemplateApplicationToaster.createSuccess);

    if (payload.subAccountUuid) {
      yield put(
        actions.getSupplyTemplateApplicationsAction.request({
          uuid: payload.subAccountUuid,
        })
      );
    }
  } catch (err: any) {
    yield put(actions.createSupplyTemplateApplicationAction.failure(err));
    yield call(supplyTemplateApplicationToaster.createFailure);
  }
}

export function sortByUpdatedAt(apps: SupplyTemplateApplication[]) {
  return apps.sort((a, b) => {
    if (a.updatedAt > b.updatedAt) {
      return -1;
    }

    if (a.updatedAt < b.updatedAt) {
      return 1;
    }

    return 0;
  });
}

export function sortAndFilterApplications(apps: SupplyTemplateApplication[]) {
  // filter out only the latest template applications before an addAndRemove strategy
  // ensure reponse is ordered by updatedAt with latest date first.
  const sortedApps = sortByUpdatedAt(apps);
  const strategyIndex = sortedApps.findIndex(
    (app) => app.strategy === SupplyTemplateApplicationStrategy.AddAndRemove
  );

  return strategyIndex > -1
    ? sortedApps.slice(0, strategyIndex + 1)
    : sortedApps;
}

export function* getSupplyTemplateApplications(
  action: ReturnType<typeof actions.getSupplyTemplateApplicationsAction.request>
) {
  const { payload } = action;

  try {
    // select users from external store
    const users = yield select(selectUsers);

    // non-blocking
    const request = actions.supplyTemplatesRequestAction.request({
      query: {},
    });
    const supplyTemplatesRequestTask = yield fork(getSupplyTemplates, request);
    // get supplyTemplateApplications
    const {
      data: applicationsResponse,
    }: AxiosResponse<SupplyTemplateApplication[]> = yield call(
      [client, 'get'],
      '/originator/supply_template_applications',
      { params: { supplySubAccountUuid: payload.uuid } }
    );
    const relevantApplications =
      sortAndFilterApplications(applicationsResponse);

    // wait until supplyTemplatesRequestTask is done and then select the Templates
    if (supplyTemplatesRequestTask) {
      yield join(supplyTemplatesRequestTask);
    }

    const templates: Record<string, SupplyTemplate> = yield select(
      selectSupplyTemplatesKeyedByUuid
    );
    // use user and template date to fill in the blanks for the remaining
    const hydratedApplications = relevantApplications.map((app) => {
      const templateInfo: SupplyTemplate | undefined = Object.values(
        templates
      ).find((template) => template.id === app.supplyTemplateId);

      // only doing this because the api doesn't return username
      const user = users.find((u) => u.accountUuid === app.createdBy);

      return {
        ...app,
        createdBy: getUserFullName(user),
        templateDetails: app.note,
        templateLastUpdated: templateInfo?.updatedAt,
        templateName: templateInfo?.name,
        templateUuid: templateInfo?.uuid,
      };
    });

    yield put(
      actions.getSupplyTemplateApplicationsAction.success({
        response: hydratedApplications,
        uuid: payload.uuid,
      })
    );
  } catch (err: any) {
    yield put(error({ customError: err }));
    yield put(actions.getSupplyTemplateApplicationsAction.failure(err));
  }
}

export function* supplyTemplateApplicationsSaga() {
  yield all([
    takeLatest(
      getType(actions.createSupplyTemplateApplicationAction.request),
      createSupplyTemplateApplication
    ),
    takeLatest(
      getType(actions.getSupplyTemplateApplicationsAction.request),
      getSupplyTemplateApplications
    ),
  ]);
}
