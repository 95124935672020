"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTableauReportsUrl = exports.getIsProductionEnv = exports.getPartnerPageBaseUrls = exports.getApplicationConfiguration = void 0;
var getApplicationConfiguration = function (state) {
    return state.config;
};
exports.getApplicationConfiguration = getApplicationConfiguration;
var getPartnerPageBaseUrls = function (state) {
    return state.config.PARTNER_PAGE_BASE_URLS;
};
exports.getPartnerPageBaseUrls = getPartnerPageBaseUrls;
var getIsProductionEnv = function (state) {
    return state.config.IS_PRODUCTION_ENV;
};
exports.getIsProductionEnv = getIsProductionEnv;
var getTableauReportsUrl = function (state) {
    return state.config.NEXT_PUBLIC_TABLEAU_REPORTS_URL;
};
exports.getTableauReportsUrl = getTableauReportsUrl;
