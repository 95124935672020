import type { BarDatum } from '@nivo/bar';
import merge from 'lodash/merge';

import { GroupByType } from '@portal/common/dist/analytics';

import getStringArrayStats from '@/utils/string-array-stats';
import type { StringArrayStats } from '@/utils/string-array-stats';

export interface ChartDatum extends BarDatum {
  x: string;
  y: string | number;
}

export type assembleChartDataProps<T extends Object = any> = {
  dataSource: T[];
  xAxisKey: keyof T;
  xAxisValueFormatter?: (value: string) => string;
  yAxisKey: keyof T;
  yAxisValueFormatter?: (value: string) => string;
};

/**
 * Only include every nth tick value in visible data - returns visible data as
 * well as stats relating to the visible and excluded data.
 */
const getExcludedTicksData = (data: ChartDatum[], nth: number) => {
  const excludedMap: any = {};
  const visibleData: ChartDatum[] = [];

  let excludedCount: number = 0;
  let visibleCount: number = 0;
  let counter = nth;

  for (let i = 0; i < data.length; i++) {
    const mapKey = data[i].x;

    if (counter === nth) {
      visibleData.push(data[i]);
      visibleCount++;
      counter = 1;
    } else {
      excludedMap[mapKey] = '';
      excludedCount++;
      counter++;
    }
  }

  return { excludedCount, excludedMap, visibleCount, visibleData };
};

export const assembleChartData = (
  props: assembleChartDataProps
): ChartDatum[] =>
  props.dataSource.map((item) => {
    const x = item[props.xAxisKey] || 'Unknown';
    const y = item[props.yAxisKey] || 0;

    return {
      x: props.xAxisValueFormatter ? props.xAxisValueFormatter(x) : x,
      y: props.yAxisValueFormatter ? props.yAxisValueFormatter(y) : y,
    };
  });

/**
 * Determine if chart should have a certain amount of margin, rotation, etc.
 * depending on the various factors relating to the supplied data and groupBy.
 *
 * @param {ChartDatum[]} data
 * @param {GroupByType} [groupBy]
 *
 * @returns {object} An object of calculated props.
 */
export function getCalculatedChartProps(
  data: ChartDatum[],
  groupBy?: GroupByType,
  initialProps?: {}
) {
  const props: any = merge(
    {
      axisBottom: {},
      margin: { bottom: 60 },
    },
    initialProps
  );

  let nthTicks: number = 1;
  let stringArrStats: StringArrayStats;

  if (groupBy === GroupByType.day) {
    nthTicks = 7;
  }

  if (nthTicks > 1) {
    const { excludedMap, visibleData } = getExcludedTicksData(data, nthTicks);

    props.axisBottom.tickValues = data.map(({ x }) => x);

    props.axisBottom.format = (value: any) => {
      if (!Object.prototype.hasOwnProperty.call(excludedMap, value)) {
        return value;
      }
    };

    stringArrStats = getStringArrayStats(visibleData.map(({ x }: any) => x));
  } else {
    stringArrStats = getStringArrayStats(data.map(({ x }: any) => x));
  }

  if (stringArrStats.count > 100) {
    props.margin.bottom = Math.min(stringArrStats.longest, 30) * 10 + 20;
    props.axisBottom.tickRotation = 55;
  }

  return props;
}
