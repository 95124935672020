"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortByName = exports.sortISODate = exports.sorter = void 0;
const lodash_1 = require("lodash");
const luxon_1 = require("luxon");
const sorter = (a, b) => {
    return a > b ? 1 : a < b ? -1 : 0;
};
exports.sorter = sorter;
const sortISODate = (a = '', b = '') => {
    const aMillis = luxon_1.DateTime.fromISO(a).toMillis();
    const bMillis = luxon_1.DateTime.fromISO(b).toMillis();
    return bMillis - aMillis;
};
exports.sortISODate = sortISODate;
const sortByName = (items) => items.sort((itemA, itemB) => (0, exports.sorter)((0, lodash_1.lowerCase)(itemA.name), (0, lodash_1.lowerCase)(itemB.name)));
exports.sortByName = sortByName;
