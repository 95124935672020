import type { ColumnProps } from 'antd/lib/table';

import {
  createAmountColumn,
  createDateColumn,
  createDateRangeColumn,
  // createStatusColumn,
  createStringColumn,
} from '@/components/table/columns';
import type { InvoiceRow } from '@/resources/latest-invoices/selectors';
import { DateFormat } from '@/utils/date-time-formatters';

export const tableConfig = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _latestInvoices: InvoiceRow[]
): Array<ColumnProps<InvoiceRow>> => {
  return [
    createDateColumn<InvoiceRow>({
      format: DateFormat,
      key: 'createdAt',
      sorter: false,
      title: 'Issued on',
    }),
    createStringColumn({
      dataIndex: 'id',
      key: 'id',
      sorter: false,
      title: '#',
    }),
    createDateRangeColumn({
      filters: [],
      format: DateFormat,
      key: 'activityPeriod',
      sorter: false,
      title: 'Activity Period',
    }),
    createAmountColumn({
      key: 'calculatedPayout',
      sorter: false,
      title: 'Total Due',
    }),
    // createStatusColumn({
    //   key: 'reconciliationType',
    //   rows: latestInvoices,
    //   sorter: false,
    //   title: 'Status',
    // }),
  ];
};
