import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import type { UserWithRoles } from '@portal/api-models';
import type { ConfigState } from '@portal/store';

import { selectConfig } from '../../resources/config/selects';
import type { ApplicationState } from '../../store';

export const useTracker = (
  callback: (payload: {
    config: ConfigState;
    engineUser: UserWithRoles;
    roles: string[];
  }) => void
) => {
  const [hasCallbackBeenExecuted, setHasCallbackBeenExecuted] = useState(false);

  const { engineUser, roles } = useSelector(
    (state: ApplicationState) => state.user
  );

  const config = useSelector(selectConfig);

  useEffect(() => {
    if (
      config.NODE_ENV !== 'production' ||
      hasCallbackBeenExecuted ||
      !engineUser ||
      !roles
    ) {
      return;
    }

    callback({ config, engineUser, roles });
    setHasCallbackBeenExecuted(true);
  }, [config, engineUser, roles]);
};
