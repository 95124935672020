import moment from 'moment';
import { HYDRATE } from 'next-redux-wrapper';
import type { Reducer } from 'redux';

import { SummaryGroupBy } from '@evenfinancial/supply-reporting-client';

import { PerformanceSummariesTabsEnum } from '@/resources/channels/performance-summary/page-config-sub-account';
import type { HydrateAction } from '@/store/actions';
import { reconcileState } from '@/store/utils';

import { ChartType } from '../../components/reporting/chart';
import { EvenDateFormat } from '../../utils/date-time-formatters';

import { PerformanceSummaryActionType } from './types';
import type {
  PerformanceSummariesState,
  PerformanceSummaryAction,
} from './types';

export const initialState: PerformanceSummariesState = {
  filters: {
    earningsChartType: ChartType.LINE,
    earningsGroupBy: SummaryGroupBy.Day,
    funnelChartType: ChartType.LINE,
    funnelGroupBy: SummaryGroupBy.Day,
    maxDate: moment().subtract(1, 'd').format(EvenDateFormat),
    minDate: moment().subtract(30, 'd').format(EvenDateFormat),
    tab: PerformanceSummariesTabsEnum.Earnings,
  },
  loading: false,
  summaries: {
    byAccountUuid: {},
    bySubAccountUuid: {},
  },
};

export const reducer: Reducer<
  PerformanceSummariesState,
  PerformanceSummaryAction | HydrateAction
> = (
  state: PerformanceSummariesState = initialState,
  action: PerformanceSummaryAction | HydrateAction
) => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...reconcileState(action.payload.performanceSummaries, initialState),
      };
    }

    case PerformanceSummaryActionType.SET_FILTERS: {
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    }

    case PerformanceSummaryActionType.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case PerformanceSummaryActionType.CLEAR_SUMMARIES_FOR_ACCOUNT_UUID: {
      const byAccountUuid = state.summaries.byAccountUuid;

      if (byAccountUuid[action.payload.accountUuid]) {
        delete byAccountUuid[action.payload.accountUuid].data;
      }

      return {
        ...state,
        summaries: {
          ...state.summaries,
          byAccountUuid: {
            ...byAccountUuid,
          },
        },
      };
    }

    case PerformanceSummaryActionType.CLEAR_SUMMARIES_FOR_SUBACCOUNT_UUID: {
      const bySubAccountUuid = state.summaries.bySubAccountUuid;

      if (bySubAccountUuid[action.payload.subAccountUuid]) {
        delete bySubAccountUuid[action.payload.subAccountUuid].data;
      }

      return {
        ...state,
        summaries: {
          ...state.summaries,
          bySubAccountUuid: {
            ...bySubAccountUuid,
          },
        },
      };
    }

    case PerformanceSummaryActionType.GET_ALL_BY_ACCOUNT_UUID_SUCCESS: {
      return {
        ...state,
        summaries: {
          ...state.summaries,
          byAccountUuid: {
            ...state.summaries.byAccountUuid,
            [action.payload.accountUuid]: {
              data: action.payload.data,
              productTypes: action.payload.productTypes,
            },
          },
        },
      };
    }

    case PerformanceSummaryActionType.GET_ALL_BY_SUBACCOUNT_UUID_SUCCESS: {
      return {
        ...state,
        summaries: {
          ...state.summaries,
          bySubAccountUuid: {
            ...state.summaries.bySubAccountUuid,
            [action.payload.subAccountUuid]: {
              data: action.payload.data,
              productType: action.payload.productType,
            },
          },
        },
      };
    }

    default: {
      return state;
    }
  }
};
