import type { ActionType } from 'typesafe-actions';

import type { ExternalDemandEvent } from '@evenfinancial/finance-client';

import type * as actions from './actions';

export type ImportedLeadEventsAction = ActionType<typeof actions>;
export enum ImportedLeadEventsActionType {
  GET_ALL_IMPORTED_LEAD_EVENTS_FAILURE = '@@account/GET_ALL_IMPORTED_LEAD_EVENTS_FAILURE',
  GET_ALL_IMPORTED_LEAD_EVENTS_REQUEST = '@@account/GET_ALL_IMPORTED_LEAD_EVENTS_REQUEST',
  GET_ALL_IMPORTED_LEAD_EVENTS_SUCCESS = '@@account/GET_ALL_IMPORTED_LEAD_EVENTS_SUCCESS',
}

export interface ImportedLeadEventsState {
  readonly haveAllImportedLeadEventsBeenRequested: boolean;
  rows: ExternalDemandEvent[];
}
