"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TutorialsActionType = void 0;
var TutorialsActionType;
(function (TutorialsActionType) {
    TutorialsActionType["GET_TUTORIAL_VIDEOS_FAILURE"] = "@@tutorials/GET_TUTORIAL_VIDEOS_FAILURE";
    TutorialsActionType["GET_TUTORIAL_VIDEOS_REQUEST"] = "@@tutorials/GET_TUTORIAL_VIDEOS_REQUEST";
    TutorialsActionType["GET_TUTORIAL_VIDEOS_SUCCESS"] = "@@tutorials/GET_TUTORIAL_VIDEOS_SUCCESS";
    TutorialsActionType["GET_TUTORIAL_VIDEO_DETAILS_FAILURE"] = "@@tutorials/GET_TUTORIAL_VIDEO_DETAILS_FAILURE";
    TutorialsActionType["GET_TUTORIAL_VIDEO_DETAILS_REQUEST"] = "@@tutorials/GET_TUTORIAL_VIDEO_DETAILS_REQUEST";
    TutorialsActionType["GET_TUTORIAL_VIDEO_DETAILS_SUCCESS"] = "@@tutorials/GET_TUTORIAL_VIDEO_DETAILS_SUCCESS";
})(TutorialsActionType = exports.TutorialsActionType || (exports.TutorialsActionType = {}));
