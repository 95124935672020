import * as React from 'react';
import { Component } from 'react';

import { Menu } from 'antd';

export interface RouterActionMenuItemProps {
  eventKey?: string;
  icon?: React.ReactElement;
  key?: string;
  onClick?(): void;
  text: string;
}

export class RouterActionMenuItem extends Component<RouterActionMenuItemProps> {
  constructor(props: RouterActionMenuItemProps) {
    super(props);
  }

  public render() {
    return (
      <Menu.Item {...this.props} className="ant-menu-item nav-menu-item">
        <a>
          <span className="nav-menu-item-text">{this.props.text}</span>
        </a>
      </Menu.Item>
    );
  }
}

export default RouterActionMenuItem;
