"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.queries = void 0;
var ews_1 = require("./ews");
var finance_1 = require("./finance");
var inventory_1 = require("./inventory");
var originator_1 = require("./originator");
var partner_data_1 = require("./partner-data");
__exportStar(require("./client"), exports);
__exportStar(require("./types"), exports);
__exportStar(require("./analytics/mci-reports"), exports);
__exportStar(require("./analytics/performance-summary"), exports);
__exportStar(require("./auth"), exports);
__exportStar(require("./campaign"), exports);
__exportStar(require("./control-center"), exports);
__exportStar(require("./ews/offer-catalog"), exports);
__exportStar(require("./ews/types"), exports);
__exportStar(require("./finance/account-owners"), exports);
__exportStar(require("./finance/accounts"), exports);
__exportStar(require("./finance/demand-contracts"), exports);
__exportStar(require("./finance/saas"), exports);
__exportStar(require("./finance/sub-accounts"), exports);
__exportStar(require("./finance/supply-contracts"), exports);
__exportStar(require("./inventory/demand-sub-account-inventory-caps"), exports);
__exportStar(require("./inventory/static-offer-inventory-caps"), exports);
__exportStar(require("./inventory/supply-demand-sub-account-inventory-caps"), exports);
__exportStar(require("./onboarding-contract"), exports);
__exportStar(require("./originator"), exports);
__exportStar(require("./originator/admin"), exports);
__exportStar(require("./partner-data"), exports);
__exportStar(require("./user"), exports);
exports.queries = {
    ews: ews_1.ewsQueries,
    finance: finance_1.financeQueries,
    inventory: inventory_1.inventoryQueries,
    originator: originator_1.originatorQueries,
    partnerData: partner_data_1.partnerDataQueries,
};
