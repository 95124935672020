"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.whitelistSaga = exports.deleteWhitelist = exports.createMultipleWhitelist = exports.createWhitelist = exports.getAllWhitelist = void 0;
var antd_1 = require("antd");
var effects_1 = require("redux-saga/effects");
var typesafe_actions_1 = require("typesafe-actions");
var api_client_1 = require("@portal/api-client");
var common_1 = require("@portal/common");
var actions = __importStar(require("./actions"));
function getAllWhitelist() {
    var response, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, (0, effects_1.call)([api_client_1.client, 'get'], '/whitelist/', {
                        params: { all: true, limit: 1000 },
                    })];
            case 1:
                response = (_a.sent()).data;
                return [4, (0, effects_1.put)(actions.getAllWhitelistAction.success(response))];
            case 2:
                _a.sent();
                return [3, 5];
            case 3:
                err_1 = _a.sent();
                return [4, (0, effects_1.put)(actions.getAllWhitelistAction.failure(err_1))];
            case 4:
                _a.sent();
                return [3, 5];
            case 5: return [2];
        }
    });
}
exports.getAllWhitelist = getAllWhitelist;
function createWhitelist(action) {
    var response, err_2, portalError;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                return [4, (0, effects_1.call)([api_client_1.client, 'post'], '/whitelist/', action.payload.row)];
            case 1:
                response = (_a.sent()).data;
                return [4, (0, effects_1.call)([antd_1.message, 'success'], 'Whitelist created successfully')];
            case 2:
                _a.sent();
                return [4, (0, effects_1.put)(actions.createWhitelistAction.success(response))];
            case 3:
                _a.sent();
                return [4, (0, effects_1.put)(actions.toggleCreateWhitelist())];
            case 4:
                _a.sent();
                return [3, 7];
            case 5:
                err_2 = _a.sent();
                portalError = (0, common_1.getPortalError)(err_2);
                return [4, (0, effects_1.put)(actions.createWhitelistAction.failure(portalError))];
            case 6:
                _a.sent();
                return [3, 7];
            case 7: return [2];
        }
    });
}
exports.createWhitelist = createWhitelist;
function createMultipleWhitelist(action) {
    var response, err_3, portalError;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                return [4, (0, effects_1.all)(action.payload.rows.map(function (payload) {
                        var data;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4, (0, effects_1.call)([api_client_1.client, 'post'], '/whitelist/', payload)];
                                case 1:
                                    data = (_a.sent()).data;
                                    return [2, data];
                            }
                        });
                    }))];
            case 1:
                response = _a.sent();
                return [4, (0, effects_1.call)([antd_1.message, 'success'], 'Whitelist created successfully')];
            case 2:
                _a.sent();
                return [4, (0, effects_1.putResolve)(actions.createMultipleWhitelistAction.success(response))];
            case 3:
                _a.sent();
                return [4, (0, effects_1.putResolve)(actions.toggleCreateWhitelist())];
            case 4:
                _a.sent();
                return [3, 7];
            case 5:
                err_3 = _a.sent();
                portalError = (0, common_1.getPortalError)(err_3);
                return [4, (0, effects_1.put)(actions.createMultipleWhitelistAction.failure(portalError))];
            case 6:
                _a.sent();
                return [3, 7];
            case 7: return [2];
        }
    });
}
exports.createMultipleWhitelist = createMultipleWhitelist;
function deleteWhitelist(action) {
    var err_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 6]);
                return [4, (0, effects_1.call)([api_client_1.client, 'delete'], "/whitelist/".concat(action.payload.uuid))];
            case 1:
                _a.sent();
                return [4, (0, effects_1.call)([antd_1.message, 'success'], 'Whitelist deleted successfully')];
            case 2:
                _a.sent();
                return [4, (0, effects_1.put)(actions.deleteWhitelistAction.success({ uuid: action.payload.uuid }))];
            case 3:
                _a.sent();
                return [3, 6];
            case 4:
                err_4 = _a.sent();
                return [4, (0, effects_1.put)(actions.deleteWhitelistAction.failure(err_4))];
            case 5:
                _a.sent();
                return [3, 6];
            case 6: return [2];
        }
    });
}
exports.deleteWhitelist = deleteWhitelist;
function whitelistSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, (0, effects_1.takeLatest)((0, typesafe_actions_1.getType)(actions.getAllWhitelistAction.request), getAllWhitelist)];
            case 1:
                _a.sent();
                return [4, (0, effects_1.takeLatest)((0, typesafe_actions_1.getType)(actions.createWhitelistAction.request), createWhitelist)];
            case 2:
                _a.sent();
                return [4, (0, effects_1.takeLatest)((0, typesafe_actions_1.getType)(actions.createMultipleWhitelistAction.request), createMultipleWhitelist)];
            case 3:
                _a.sent();
                return [4, (0, effects_1.takeLatest)((0, typesafe_actions_1.getType)(actions.deleteWhitelistAction.request), deleteWhitelist)];
            case 4:
                _a.sent();
                return [2];
        }
    });
}
exports.whitelistSaga = whitelistSaga;
