import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  faBook,
  faChartColumn,
  faChartLine,
  faHome,
  faList,
} from '@fortawesome/pro-solid-svg-icons';
import { Menu } from 'antd';
import classNames from 'classnames';

import { Route } from '@portal/common';
import { viewContextSelector } from '@portal/store/dist/view-context/selectors';
import { EvenLogo, SidebarIcon } from '@portal/ui-lib';

import { RouterAwareMenuItem } from '@/components/side-nav/router-aware-menu-item';

interface ExternalUserSideNavProps {
  accountUuid: string;
  sidenavCollapsed: boolean;
}

export const ExternalUserSideNav: React.FC<ExternalUserSideNavProps> = ({
  accountUuid,
  sidenavCollapsed,
}) => {
  const {
    showHomeMenuItem,
    showPerformanceSummaryMenuItem,
    showBenchmarkReportsMenuItem,
    showMCIReportsMenuItem,
  } = useSelector(viewContextSelector);

  return (
    <Menu
      className={classNames('portal-sider-menu', {
        'portal-sider-menu-collapsed': sidenavCollapsed,
      })}
      defaultOpenKeys={[]}
      mode="inline"
      selectedKeys={[]}
      theme="dark"
    >
      <Menu.Item
        className={sidenavCollapsed ? 'portal-logo-collapsed' : 'portal-logo'}
      >
        <EvenLogo showLargeLogo={!sidenavCollapsed} />
      </Menu.Item>

      {showHomeMenuItem && (
        <RouterAwareMenuItem
          key="menu-item-home"
          href="/home"
          icon={<SidebarIcon icon={faHome} />}
          text="Home"
        />
      )}

      {showPerformanceSummaryMenuItem && (
        <RouterAwareMenuItem
          key="menu-item-revenue-reporting"
          additionalPaths={[
            '/channels/revenue-reporting',
            '/channels/performance-summary',
          ]}
          eventKey="performance-summary"
          href="/channels/performance-summary"
          icon={<SidebarIcon fixedWidth icon={faChartColumn} />}
          text="Performance Summary"
        />
      )}

      {showMCIReportsMenuItem && (
        <RouterAwareMenuItem
          key="menu-item-mci-reports"
          eventKey="mci-reports"
          href={Route.MciReports}
          icon={<SidebarIcon icon={faList} />}
          text="MCI Reports"
        />
      )}

      {showBenchmarkReportsMenuItem && (
        <RouterAwareMenuItem
          key={`menu-item-benchmark`}
          eventKey={`benchmark-reports`}
          href={`/benchmark-reports`}
          icon={<SidebarIcon icon={faChartLine} />}
          text={`Benchmark Reports`}
        />
      )}

      <RouterAwareMenuItem
        key="menu-item-account"
        additionalPaths={['/supply-sub-accounts', '/demand-sub-accounts']}
        eventKey="account"
        href={`/accounts/${accountUuid}`}
        icon={<SidebarIcon icon={faBook} />}
        text="Account"
      />
    </Menu>
  );
};
