"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterSubAccountsByType = void 0;
const finance_client_1 = require("@evenfinancial/finance-client");
const filterSubAccountsByType = (subAccounts) => {
    const supplySubAccounts = subAccounts.filter(({ subAccountTypeKey }) => subAccountTypeKey === finance_client_1.SubAccountType.SupplySource);
    const demandSubAccounts = subAccounts.filter(({ subAccountTypeKey }) => subAccountTypeKey === finance_client_1.SubAccountType.DemandSource);
    return { demandSubAccounts, supplySubAccounts };
};
exports.filterSubAccountsByType = filterSubAccountsByType;
