"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetRichAccessTokenQuery = exports.Experience = exports.PartnerType = exports.FlatRole = void 0;
__exportStar(require("./constants"), exports);
__exportStar(require("./permissions"), exports);
__exportStar(require("./roles"), exports);
__exportStar(require("./scopes"), exports);
var FlatRole;
(function (FlatRole) {
    FlatRole["CRM"] = "CRM";
    FlatRole["Demand"] = "Demand";
    FlatRole["Growth"] = "Growth";
    FlatRole["InternalManager"] = "Internal Manager";
    FlatRole["InternalViewer"] = "Internal Viewer";
    FlatRole["Scale"] = "Scale";
    FlatRole["ScaleAPI"] = "Scale API";
    FlatRole["StarterFree"] = "Starter Free";
    FlatRole["StarterLegacy"] = "Starter Legacy";
    FlatRole["SupplyLite"] = "Supply Lite";
})(FlatRole = exports.FlatRole || (exports.FlatRole = {}));
var PartnerType;
(function (PartnerType) {
    PartnerType["Demand"] = "Demand";
    PartnerType["Multi"] = "Multi";
    PartnerType["Supply"] = "Supply";
})(PartnerType = exports.PartnerType || (exports.PartnerType = {}));
var Experience;
(function (Experience) {
    Experience["Demand"] = "Demand";
    Experience["Supply"] = "Supply";
})(Experience = exports.Experience || (exports.Experience = {}));
var GetRichAccessTokenQuery = (function () {
    function GetRichAccessTokenQuery() {
    }
    return GetRichAccessTokenQuery;
}());
exports.GetRichAccessTokenQuery = GetRichAccessTokenQuery;
