"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inventoryQueries = void 0;
var demand_sub_account_inventory_caps_1 = require("./demand-sub-account-inventory-caps");
var static_offer_inventory_caps_1 = require("./static-offer-inventory-caps");
var supply_demand_sub_account_inventory_caps_1 = require("./supply-demand-sub-account-inventory-caps");
exports.inventoryQueries = {
    demandSubAccountInventoryCaps: function (params) { return ({
        queryFn: function () { return (0, demand_sub_account_inventory_caps_1.getDemandSubAccountInventoryCaps)(params); },
        queryKey: ['demandSubAccountInventoryCaps', params],
    }); },
    staticOfferInventoryCaps: function (params) { return ({
        queryFn: function () { return (0, static_offer_inventory_caps_1.getStaticOfferInventoryCaps)(params); },
        queryKey: ['staticOfferInventoryCaps', params],
    }); },
    supplyDemandSubAccountInventoryCaps: function (params) { return ({
        queryFn: function () { return (0, supply_demand_sub_account_inventory_caps_1.getSupplyDemandSubAccountInventoryCaps)(params); },
        queryKey: ['supplySubAccountInventoryCaps', params],
    }); },
};
