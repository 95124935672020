import type { SaasPlan, SaasTrial } from '@evenfinancial/finance-client';

import type { IntegrationRow } from '@/resources/integrations/types';
import { getFreeTrialDaysLeft } from '@/resources/saas/helpers';

export const integrationProductReachedLimit = (
  integrations: IntegrationRow[],
  integrationType?: string,
  saasUserPlan?: SaasPlan,
  userTrial?: SaasTrial
): boolean => {
  // currently if user has plan we have no limitations
  if (saasUserPlan?.tier) {
    return false;
  }

  // if the user is migrated, we have no limit
  const freeTrialDaysLeft = getFreeTrialDaysLeft(userTrial?.endDate);
  const freeTrialExpired = freeTrialDaysLeft < 0;

  if (
    userTrial?.expectedTier &&
    userTrial?.fromMigration &&
    !freeTrialExpired
  ) {
    return false;
  }

  // if there is limit for specific plan set limit value
  const limit = 1;

  if (!integrationType) {
    return true;
  }

  return integrations.length >= limit;
};
