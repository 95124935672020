import * as React from 'react';
import { useEffect } from 'react';

import InputNumber from 'antd/lib/input-number';
import type { InputNumberProps } from 'antd/lib/input-number';

import { useControllable, useForm } from '@/components/form/portal-form';
import type { FieldComponent } from '@/components/form/portal-form/types';

type InputRangeValue = [
  string | number | null | undefined,
  string | number | null | undefined
];

export type InputRangeProvidedProps = {
  max?: number;
  min?: number;
};

export type InputRangeProps = InputRangeProvidedProps &
  Omit<
    InputNumberProps,
    'type' | 'value' | 'defaultValue' | 'onChange' | 'onError' | 'form'
  >;

const isWithinRange = (range: InputRangeValue, min?: number, max?: number) => {
  const isMinOutOfBound = Boolean(
    min && range[0] && (range[0] < min || (max && range[0] > max))
  );
  const isMaxOutOfBound = Boolean(
    max && range[1] && (range[1] > max || (min && range[1] < min))
  );

  const isRangeOutOfBound = Boolean(
    min &&
      range[0] &&
      max &&
      range[1] &&
      (range[0] > range[1] || isMinOutOfBound || isMaxOutOfBound)
  );

  if (isRangeOutOfBound) {
    return `Range values must be in between ${min} and ${max}`;
  }

  if (isMinOutOfBound) {
    return `Minimum value must be greater than ${min}`;
  }

  if (isMaxOutOfBound) {
    return `Maximum value must be less than ${max}`;
  }

  return null;
};

const InputRange: FieldComponent<InputRangeValue, InputRangeProps> = ({
  onChange,
  value,
  defaultValue = [0, 0],
  min,
  max,
  ...props
}) => {
  const validate = (range: InputRangeValue) => isWithinRange(range, min, max);

  const { addErrorCounter } = useForm();

  const [range, setRange] = useControllable({
    defaultValue,
    onChange,
    validate,
    value,
  });

  const [minValue, maxValue] = range;

  useEffect(() => {
    addErrorCounter(() => {
      if (validate(range)) {
        return 1;
      }

      return 0;
    });
  });

  if (
    (value !== undefined && !Array.isArray(value)) ||
    (defaultValue !== undefined && !Array.isArray(defaultValue))
  ) {
    return null;
  }

  return (
    <div className="input-range">
      <InputNumber
        {...props}
        value={minValue as number | undefined}
        onChange={(value) => setRange([value, maxValue])}
      />
      <span className="input-range-sep">to</span>
      <InputNumber
        {...props}
        value={maxValue as number | undefined}
        onChange={(value) => setRange([minValue, value])}
      />
    </div>
  );
};

export default InputRange;
