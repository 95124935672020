"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pricingControlsReducer = exports.initialPricingControlsState = void 0;
var immer_1 = require("immer");
var typesafe_actions_1 = require("typesafe-actions");
var actions_1 = require("./actions");
exports.initialPricingControlsState = {
    rows: [],
};
exports.pricingControlsReducer = (0, typesafe_actions_1.createReducer)(exports.initialPricingControlsState).handleAction(actions_1.getPricingControlsAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.rows = payload;
    });
});
