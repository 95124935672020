import type { ColumnProps } from 'antd/lib/table';

import { SummaryGroupByLabelsMap } from '@/components/reporting/summary-group-by';
import {
  createAmountColumn,
  createNumericColumn,
  createStringColumn,
} from '@/components/table/columns';
import { summaryGroupByValueFormatter } from '@/resources/channels/performance-summary/shared/formatters';

export const savingsFunnelTableConfig = (
  groupBy: string
): Array<ColumnProps<any>> => [
  createStringColumn({
    key: 'groupKey',
    render: summaryGroupByValueFormatter,
    title: SummaryGroupByLabelsMap[groupBy],
  }),
  createNumericColumn({
    align: 'right',
    key: 'numLeads',
    title: 'Leads',
  }),
  createNumericColumn({
    align: 'right',
    key: 'numClicks',
    title: 'Clicked Offers',
  }),
  createAmountColumn({
    align: 'right',
    key: 'totalPayout',
    title: 'Attributed Earnings',
  }),
];
