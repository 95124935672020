import * as React from 'react';
import { useEffect, useState } from 'react';

import { faUpload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'antd/lib/button';
import Upload from 'antd/lib/upload';
import type { UploadChangeParam } from 'antd/lib/upload';
import type { UploadFile } from 'antd/lib/upload/interface';

export interface UploadFormFieldProvidedProps {
  accept?: string;
  allowedFilesNumber?: number;
  beforeUpload?: () => boolean;
  defaultValue?: { file: UploadFile; fileList: UploadFile[] };
  disabled?: boolean;
  listType?: 'picture' | 'text' | 'picture-card';
  multiple?: boolean;
  onChange?: (info: UploadChangeParam<UploadFile<any>>) => void;
  value?: { file: File; fileList: File[] };
}

const UploadFormField: React.FC<UploadFormFieldProvidedProps> = ({
  accept,
  disabled,
  multiple,
  listType,
  onChange,
  defaultValue,
  allowedFilesNumber,
  beforeUpload = () => false,
}) => {
  const [disableButton, setDisableButton] = useState(false);

  const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    setDisableButton(allowedFilesNumber === info.fileList.length);

    return onChange && onChange(info);
  };

  useEffect(() => {
    setDisableButton(allowedFilesNumber === defaultValue?.fileList.length);
  }, [defaultValue]);

  return (
    <Upload
      accept={accept}
      beforeUpload={beforeUpload}
      defaultFileList={defaultValue?.fileList}
      disabled={disabled}
      listType={listType || 'picture'}
      multiple={multiple}
      onChange={handleChange}
    >
      <Button data-qa="upload-btn" disabled={disabled || disableButton}>
        <FontAwesomeIcon icon={faUpload} /> Upload
      </Button>
    </Upload>
  );
};

export default UploadFormField;
