import * as React from 'react';

import Form from '@ant-design/compatible/lib/form/Form';
import type { GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form';
import Input from 'antd/lib/input';
import kebabCase from 'lodash/kebabCase';

import type { LegacyPortalFormItem } from '@/components/form/legacy-portal-form';

const { Item } = Form;

/**
 * @deprecated
 */
export interface LegacyPortalFormElementProps {
  className: string;
  field: LegacyPortalFormItem;
  getFieldDecorator(
    id: any,
    options?: GetFieldDecoratorOptions
  ): (node: React.ReactNode) => React.ReactNode;
  itemKey: string;
  label?: React.ReactFragment | null;
}

/**
 * @deprecated
 */
const LegacyPortalFormElement: React.FC<LegacyPortalFormElementProps> = ({
  className,
  field,
  getFieldDecorator,
  itemKey,
  label,
}) => (
  <>
    {field.before}
    <Item
      {...field.itemProps}
      key={itemKey}
      className={className}
      colon={false}
      label={label}
    >
      {getFieldDecorator(
        field.name,
        field.fieldDecoratorProps
      )(field.fieldElement || <Input data-qa={kebabCase(field.name)} />)}
    </Item>
    {field.after}
  </>
);

export default LegacyPortalFormElement;
