"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.feedbackReducer = exports.initialFeedbackState = void 0;
var typesafe_actions_1 = require("typesafe-actions");
var actions_1 = require("./actions");
exports.initialFeedbackState = {
    feedbackDrawerVisible: false,
};
exports.feedbackReducer = (0, typesafe_actions_1.createReducer)(exports.initialFeedbackState).handleAction(actions_1.toggleFeedbackDrawer, function (state) {
    return __assign(__assign({}, state), { feedbackDrawerVisible: !state.feedbackDrawerVisible });
});
