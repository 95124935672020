import * as React from 'react';

import { Select } from 'antd';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';

import type * as EvenStatic from '@evenfinancial/even-ts-static'; /* eslint-disable-line import/namespace */

const { Option } = Select;

export const EnumDropdown = (
  staticEnum: Partial<typeof EvenStatic> & { [key: string]: string | number }
) =>
  Object.keys(staticEnum).map((currentKey) => (
    <Option
      key={`enumDropdown${currentKey}`}
      value={staticEnum[currentKey as keyof typeof staticEnum]}
    >
      {startCase(camelCase(currentKey))}
    </Option>
  ));
