"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toUserUpdateData = exports.toUserAccessData = void 0;
const api_models_1 = require("@portal/api-models");
const access_control_1 = require("../access-control");
const finance_1 = require("../finance");
const getPartnerType = (subRoleObjectMatches) => {
    const { demandRoleObject, supplyRoleObject } = subRoleObjectMatches;
    if (demandRoleObject && supplyRoleObject) {
        return api_models_1.PartnerType.Multi;
    }
    else if (demandRoleObject) {
        return api_models_1.PartnerType.Demand;
    }
    else if (supplyRoleObject) {
        return api_models_1.PartnerType.Supply;
    }
    return undefined;
};
const toUserAccessData = (apiData) => {
    var _a;
    const { account, roles } = apiData;
    const flatRole = (0, access_control_1.getFlatRoleFromRoles)(roles);
    const subRoleObjectMatches = (0, access_control_1.getSubRoleObjects)(roles);
    const partnerType = subRoleObjectMatches
        ? getPartnerType(subRoleObjectMatches)
        : undefined;
    const subAccountsByType = (0, finance_1.filterSubAccountsByType)((_a = apiData.subAccounts) !== null && _a !== void 0 ? _a : []);
    let subAccounts;
    let demandSubAccounts;
    let supplySubAccounts;
    let roleObject;
    if (!partnerType) {
        roleObject = flatRole && { flatRole, roles };
        subAccounts = apiData.subAccounts;
    }
    if (partnerType === api_models_1.PartnerType.Demand || partnerType === api_models_1.PartnerType.Multi) {
        demandSubAccounts = subAccountsByType.demandSubAccounts;
    }
    if (partnerType === api_models_1.PartnerType.Supply || partnerType === api_models_1.PartnerType.Multi) {
        supplySubAccounts = subAccountsByType.supplySubAccounts;
    }
    return {
        account,
        allAccounts: !account,
        demandRole: subRoleObjectMatches.demandRoleObject,
        demandSubAccounts,
        partnerType,
        roleObject,
        subAccounts,
        supplyRole: subRoleObjectMatches.supplyRoleObject,
        supplySubAccounts,
    };
};
exports.toUserAccessData = toUserAccessData;
const toUserUpdateData = (form) => {
    const { account, allAccounts, demandRole = { roles: [] }, demandSubAccounts = [], emailAddress, firstName, lastName, partnerType, roleObject, subAccounts: internalSubAccounts = [], supplyRole = { roles: [] }, supplySubAccounts = [], } = form;
    const roles = [];
    const subAccounts = [];
    const isInternal = (roleObject === null || roleObject === void 0 ? void 0 : roleObject.flatRole) === api_models_1.FlatRole.InternalManager ||
        (roleObject === null || roleObject === void 0 ? void 0 : roleObject.flatRole) === api_models_1.FlatRole.InternalViewer;
    const isDemand = partnerType === api_models_1.PartnerType.Demand || partnerType === api_models_1.PartnerType.Multi;
    const isSupply = partnerType === api_models_1.PartnerType.Supply || partnerType === api_models_1.PartnerType.Multi;
    if (isInternal) {
        roles.push(...roleObject.roles);
        subAccounts.push(...internalSubAccounts);
    }
    if (!isInternal && isDemand) {
        roles.push(...demandRole.roles);
        subAccounts.push(...demandSubAccounts);
    }
    if (!isInternal && isSupply) {
        roles.push(...supplyRole.roles);
        subAccounts.push(...supplySubAccounts);
    }
    let accountUuids;
    let subAccountUuids;
    if (allAccounts) {
        accountUuids = [];
        subAccountUuids = [];
    }
    else {
        accountUuids = account ? [account.uuid] : undefined;
        subAccountUuids = subAccounts.length
            ? subAccounts.map(({ uuid }) => uuid)
            : undefined;
    }
    return {
        accountUuids,
        emailAddress,
        firstName,
        lastName,
        roles: roles.length ? roles : undefined,
        subAccountUuids,
    };
};
exports.toUserUpdateData = toUserUpdateData;
