import * as React from 'react';
import { useRef } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

/**
 * Auto-focus input when visible.
 *
 * @template T
 *
 * @return {
 *   inputRef: React.RefObject<T | undefined>;
 *   AutoFocus: React.FC
 * }
 */
const useAutoFocus = <T extends { focus: () => void }>() => {
  const inputRef = useRef() as React.RefObject<T>;

  const onVisibilityChange = (isVisible: boolean) =>
    isVisible && inputRef.current && inputRef.current.focus();

  const AutoFocus: React.FC<React.PropsWithChildren<unknown>> = ({
    children,
  }) => (
    <VisibilitySensor onChange={onVisibilityChange}>
      {children}
    </VisibilitySensor>
  );

  return { AutoFocus, inputRef };
};

export default useAutoFocus;
