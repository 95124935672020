"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.whitelistReducer = exports.initialWhitelistState = void 0;
var immer_1 = require("immer");
var typesafe_actions_1 = require("typesafe-actions");
var actions_1 = require("../actions");
var actions_2 = require("./actions");
exports.initialWhitelistState = {
    createDrawerVisible: false,
    rows: [],
};
exports.whitelistReducer = (0, typesafe_actions_1.createReducer)(exports.initialWhitelistState)
    .handleAction(actions_2.getAllWhitelistAction.success, function (state, _a) {
    var rows = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.rows = rows;
    });
})
    .handleAction(actions_2.createWhitelistAction.success, function (state, _a) {
    var row = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        var _a;
        (_a = draftState.rows) === null || _a === void 0 ? void 0 : _a.push(row);
    });
})
    .handleAction(actions_2.createMultipleWhitelistAction.success, function (state, _a) {
    var rows = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        var _a;
        draftState.rows = (_a = draftState.rows) === null || _a === void 0 ? void 0 : _a.concat(rows);
    });
})
    .handleAction(actions_2.deleteWhitelistAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.rows = draftState.rows.filter(function (row) { return row.uuid !== payload.uuid; });
    });
})
    .handleAction(actions_2.toggleCreateWhitelist, function (state) {
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.createDrawerVisible = !state.createDrawerVisible;
    });
})
    .handleAction(actions_1.nextHydrate, function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), payload.whitelist));
});
