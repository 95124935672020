"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeadActionType = void 0;
var LeadActionType;
(function (LeadActionType) {
    LeadActionType["GET_LAED_SUMMARY_BY_UUID_FAILURE"] = "@@lead/GET_LEAD_SUMMARY_BY_UUID_FAILURE";
    LeadActionType["GET_LAED_SUMMARY_BY_UUID_REQUEST"] = "@@lead/GET_LEAD_SUMMARY_BY_UUID_REQUEST";
    LeadActionType["GET_LAED_SUMMARY_BY_UUID_SUCCESS"] = "@@lead/GET_LEAD_SUMMARY_BY_UUID_SUCCESS";
    LeadActionType["GET_LEADS_FAILURE"] = "@@lead/GET_LEADS_FAILURE";
    LeadActionType["GET_LEADS_REQUEST"] = "@@lead/GET_LEADS_REQUEST";
    LeadActionType["GET_LEADS_SUCCESS"] = "@@lead/GET_LEADS_SUCCESS";
    LeadActionType["GET_LEAD_BY_UUID_FAILURE"] = "@@lead/GET_LEAD_BY_UUID_FAILURE";
    LeadActionType["GET_LEAD_BY_UUID_REQUEST"] = "@@lead/GET_LEAD_BY_UUID_REQUEST";
    LeadActionType["GET_LEAD_BY_UUID_SUCCESS"] = "@@lead/GET_LEAD_BY_UUID_SUCCESS";
    LeadActionType["GET_LEAD_SUMMARIES_FAILURE"] = "@@lead/GET_LEAD_SUMMARIES_FAILURE";
    LeadActionType["GET_LEAD_SUMMARIES_REQUEST"] = "@@lead/GET_LEAD_SUMMARIES_REQUEST";
    LeadActionType["GET_LEAD_SUMMARIES_SUCCESS"] = "@@lead/GET_LEAD_SUMMARIES_SUCCESS";
    LeadActionType["LEADS_UTIL_STATE_CHANGE"] = "@@lead/LEADS_UTIL_STATE_CHANGE";
    LeadActionType["LEAD_SUMMARIES_UTIL_STATE_CHANGE"] = "@@lead/LEAD_SUMMARIES_UTIL_STATE_CHANGE";
})(LeadActionType = exports.LeadActionType || (exports.LeadActionType = {}));
