import moment from 'moment';
import {
  all,
  call,
  fork,
  put,
  putResolve,
  select,
  takeEvery,
} from 'redux-saga/effects';
import type { ActionType } from 'typesafe-actions';

import { ProductType } from '@evenfinancial/finance-client';

import { client, getFunnelSummary, getPayoutSummary } from '@portal/api-client';
import type {
  PayoutSummary,
  PerformanceSummaryQuery,
  PerformanceSummaryQueryWithProductType,
  PortalSubAccount,
} from '@portal/api-models';
import { getPortalError } from '@portal/common';

import groupSubAccountsByAccountUuid from '@/resources/accounts/group-sub-accounts-by-account-uuid';
import { getCalculatedAccountEarningsSummaries } from '@/services/performance-summary/calculated-earnings';
import type { ProductTypeAndEarnings } from '@/services/performance-summary/calculated-earnings';
import type { ApplicationState } from '@/store';
import { PerformanceSummaryActionType } from '@/store/performance-summary/types';
import type { PerformanceReportsFilterState } from '@/store/performance-summary/types';
import { getSupplyContractsBySubaccountUuidAction } from '@/store/supply-contracts/actions';

import { queryClient } from '../../config/query-client';
import {
  selectPerformanceReportingEarningsParams,
  selectPerformanceReportingFilters,
  selectPerformanceReportingFunnelParams,
} from '../../resources/channels/performance-summary/selects';
import { getSupplySubAccounts } from '../subaccount/sagas';

import * as actions from './actions';

const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

const formatRequestParams = (params: PerformanceSummaryQuery) => ({
  ...params,
  maxDate: moment(params.maxDate).endOf('day').format(DATE_TIME_FORMAT),
  minDate: moment(params.minDate).startOf('day').format(DATE_TIME_FORMAT),
});

function* getSubAccountProductType(subAccountUuid: string) {
  let productType = yield select(
    (state: ApplicationState) =>
      state.performanceSummaries.summaries.bySubAccountUuid[subAccountUuid]
        ?.productType
  );

  if (!productType) {
    const subAccount: PortalSubAccount = yield call(async () => {
      return client
        .get<PortalSubAccount>(`/finance/subaccounts/${subAccountUuid}`)
        .then((res) => res.data);
    });

    productType = subAccount.supplyProductTypes?.length
      ? (subAccount.supplyProductTypes[0] as unknown as ProductType)
      : ProductType.Other;
  }

  return productType;
}

function* getPerformanceSummariesForAccount() {
  try {
    yield put(actions.setLoading(true));

    const { accountUuid }: PerformanceReportsFilterState = yield select(
      selectPerformanceReportingFilters
    );

    const earningsParams: Omit<
      PerformanceSummaryQueryWithProductType,
      'productType' | 'subAccountUuid'
    > = yield select(selectPerformanceReportingEarningsParams);

    const {
      subAccounts: { haveAllSupplySubAccountsBeenRequested },
    }: ApplicationState = yield select((state: ApplicationState) => state);

    if (!haveAllSupplySubAccountsBeenRequested) {
      yield* getSupplySubAccounts();
    }

    const { subAccounts }: ApplicationState = yield select(
      (state: ApplicationState) => state
    );

    const subAccountsByAccountUuid = groupSubAccountsByAccountUuid(
      subAccounts.supply
    );

    const filteredSubAccounts = accountUuid
      ? subAccountsByAccountUuid[accountUuid]
      : [];

    const subAccountUuids = (filteredSubAccounts || []).map(
      (subAccount) => subAccount.uuid
    );
    const productTypes: any = {};
    const productTypeAndEarningsBySubAccount: ProductTypeAndEarnings[] = [];

    yield all(
      subAccountUuids.map(function* (subAccountUuid) {
        const productType = yield* getSubAccountProductType(subAccountUuid);

        // Product type "other" not supported so skip.
        if (productType === ProductType.Other) {
          return;
        }

        const params = {
          ...earningsParams,
          productType,
          subAccountUuid,
        };

        // Wrapping with query client for retry
        // This can be simplified when supply reporting api is more stable.
        const getPayoutSummaryQuery = () =>
          queryClient.fetchQuery({
            queryFn: () => getPayoutSummary(params),
            queryKey: ['payoutSummaries', params],
            retry: 3,
          });

        const payoutSummary: PayoutSummary[] = yield call(
          getPayoutSummaryQuery
        );

        productTypeAndEarningsBySubAccount.push({
          earnings: payoutSummary,
          productType,
        });

        productTypes[productType] = productType;
      })
    );

    const earnings = getCalculatedAccountEarningsSummaries(
      productTypeAndEarningsBySubAccount
    );

    yield put(
      actions.getByAccountId.success({
        accountUuid: accountUuid || '',
        data: {
          earnings,
        },
        productTypes: Object.values(productTypes),
      })
    );
  } catch (err: any) {
    const portalError = getPortalError(err);

    yield put(actions.getByAccountId.failure(portalError));
  } finally {
    yield put(actions.setLoading(false));
  }
}

function* getPerformanceSummariesByProductType({
  payload,
}: ActionType<typeof actions.getBySubAccountId.request>) {
  try {
    yield put(actions.setLoading(true));

    let funnelParams: PerformanceSummaryQuery;
    let earningsParams: PerformanceSummaryQuery;
    let subAccountUuid;

    if (payload?.earningsParams && payload?.funnelParams) {
      funnelParams = formatRequestParams(payload.funnelParams);
      earningsParams = formatRequestParams(payload.earningsParams);
      subAccountUuid = earningsParams.subAccountUuid;
    } else {
      const { subAccountUuid: saUuid }: PerformanceReportsFilterState =
        yield select(selectPerformanceReportingFilters);

      subAccountUuid = saUuid;
      funnelParams = yield select(selectPerformanceReportingFunnelParams);
      earningsParams = yield select(selectPerformanceReportingEarningsParams);
    }

    if (subAccountUuid) {
      const productType = yield* getSubAccountProductType(subAccountUuid);

      yield putResolve(
        getSupplyContractsBySubaccountUuidAction.request({
          uuid: subAccountUuid,
        })
      );

      // Wrapping with query client for retry
      // This can be simplified when supply reporting api is more stable.

      const getPayoutSummaryQuery = () =>
        queryClient.fetchQuery({
          queryFn: () => getPayoutSummary({ ...earningsParams, productType }),
          retry: 3,
        });

      const gerFunnelSummaryQuery = () =>
        queryClient.fetchQuery({
          queryFn: () => getFunnelSummary({ ...funnelParams, productType }),
          retry: 3,
        });

      const payoutSummary = yield call(getPayoutSummaryQuery);
      const funnelSummary = yield call(gerFunnelSummaryQuery);

      yield put(
        actions.getBySubAccountId.success({
          data: {
            earnings: payoutSummary,
            funnel: funnelSummary,
          },
          productType,
          subAccountUuid,
        })
      );
    }
  } catch (err: any) {
    const portalError = getPortalError(err);

    yield put(actions.getBySubAccountId.failure(portalError));
  } finally {
    yield put(actions.setLoading(false));
  }
}

function* watchGetPerformanceSummariesForAccount() {
  yield takeEvery(
    PerformanceSummaryActionType.GET_ALL_BY_ACCOUNT_UUID_REQUEST,
    getPerformanceSummariesForAccount
  );
}

function* watchGetPerformanceSummariesByProductType() {
  yield takeEvery(
    PerformanceSummaryActionType.GET_ALL_BY_SUBACCOUNT_UUID_REQUEST,
    getPerformanceSummariesByProductType
  );
}

export function* performanceSummariesSaga() {
  yield all([
    fork(watchGetPerformanceSummariesForAccount),
    fork(watchGetPerformanceSummariesByProductType),
  ]);
}
