import * as React from 'react';

import get from 'lodash/get';
import set from 'lodash/set';

import {
  PortalFormContext,
  createValidationMap,
  getPopulatedFormValues,
  validateAll,
} from '@/components/form/portal-form';
import type { IPortalFormContext } from '@/components/form/portal-form';
import type { ValidationMap } from '@/components/form/portal-form/lib/validate';
import { CallbackType } from '@/components/form/portal-form/types';
import type {
  CallbacksMap,
  ErrorMap,
  OnChange,
  OnError,
  OnErrorCount,
  OnValidate,
  PortalFormField,
  ValidationResult,
} from '@/components/form/portal-form/types';

type OnSubmissionSuccess<T extends object> = (
  populatedValues: T,
  rawValues: T
) => void;

type OnSubmissionFailure = (errorCount: number) => void;

export type SubmitCallback<T extends object> = (
  onSubmit?: OnSubmissionSuccess<T>,
  onFailure?: OnSubmissionFailure
) => Promise<void>;

export type WithPortalFormProps<T extends object> = {
  form: IPortalFormContext<T>;
};

type ReturnedComponentProps<T extends object, P extends object> = Partial<
  WithPortalFormProps<T>
> &
  Omit<P, 'form'>;

export type WrappedComponentProps<
  T extends object,
  P extends object
> = WithPortalFormProps<T> & P;

export interface WithPortalFormStateProps<T extends object> {
  defaultValues: T;
  errorCount: number;
  /** Stores errors keyed by "name" property string. */
  errorMap: Record<string, string | null>;
  fields: PortalFormField<T>[];
  /**
   * Stores form values in a potentially nested key-value object.
   * Nesting is based on the object paths defined within the "name"
   * property string.
   */
  values: T;
}

export function withPortalForm<
  T extends object,
  P extends WithPortalFormProps<T>
>(
  WrappedComponent: (
    | React.ComponentClass<WrappedComponentProps<T, P>>
    | React.FC<React.PropsWithChildren<WrappedComponentProps<T, P>>>
  ) & {
    getInitialProps?: (context: object) => object;
  }
): React.ComponentClass<ReturnedComponentProps<T, P>> {
  class WithPortalForm extends React.Component<
    ReturnedComponentProps<T, P>,
    WithPortalFormStateProps<T>
  > {
    private callbacks: CallbacksMap<T> = {
      [CallbackType.OnChange]: [],
      [CallbackType.OnValidate]: [],
      [CallbackType.OnError]: [],
      [CallbackType.OnErrorCount]: [],
    };

    public static getInitialProps?: (context: object) => object;

    public displayName = `WithPortalForm(${
      WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`;

    constructor(props: ReturnedComponentProps<T, P>) {
      super(props);

      this.state = {
        defaultValues: {} as any,
        errorCount: 0,
        errorMap: {},
        fields: [],
        values: {} as any,
      };
    }

    /**
     * @memberof WithPortalForm
     */
    public validate = async (): Promise<ValidationResult<T>> => {
      const { values } = this.state;

      const errorMap = await this.getErrorMap();
      const errorCount = await this.getErrorCount();

      await Promise.all(
        this.callbacks[CallbackType.OnValidate].map(async (callback) =>
          callback()
        )
      );

      this.setState({ errorCount, errorMap });

      return { errorCount, errorMap, values };
    };

    /**
     * @param {*} onSuccess
     * @param {*} onFailure
     * @type {SubmitCallback<T>}
     * @memberof WithPortalForm
     */
    public submit: SubmitCallback<T> = async (onSuccess, onFailure) => {
      const nextState = await this.validate();

      const { fields } = this.state;
      const { values, errorCount } = nextState;

      this.setState(nextState);

      if (errorCount > 0 && onFailure) {
        onFailure(errorCount);
      } else if (errorCount === 0 && onSuccess) {
        const populatedValues = getPopulatedFormValues(values, fields);

        onSuccess(populatedValues, values);
      }
    };

    public addOnChange = (onChange: OnChange<T>) =>
      this.addCallback(CallbackType.OnChange, onChange);

    public addOnValidate = (onValidate: OnValidate) =>
      this.addCallback(CallbackType.OnValidate, onValidate);

    public addOnError = (onError: OnError) =>
      this.addCallback(CallbackType.OnError, onError);

    public addOnErrorCount = (onErrorCount: OnErrorCount) =>
      this.addCallback(CallbackType.OnErrorCount, onErrorCount);

    public removeOnChange = (onChange: OnChange<T>) =>
      this.removeCallback(CallbackType.OnChange, onChange);

    public removeOnValidate = (onValidate: OnValidate) =>
      this.removeCallback(CallbackType.OnValidate, onValidate);

    public removeOnError = (onError: OnError) =>
      this.removeCallback(CallbackType.OnError, onError);

    public removeOnErrorCount = (onErrorCount: OnErrorCount) =>
      this.removeCallback(CallbackType.OnErrorCount, onErrorCount);

    /**
     * @param {PortalFormField<T>[]} fields
     * @memberof WithPortalForm
     */
    public setFields = (fields: PortalFormField<T>[]) => {
      this.setState({ fields });
    };

    /**
     * @param {T} defaultValues
     * @memberof WithPortalForm
     */
    public setDefaultValues = (defaultValues: T) => {
      this.setState({ defaultValues });
    };

    /**
     * @public
     * @param {T} values
     * @param {boolean} [validate=false]
     * @memberof WithPortalForm
     */
    public setValues = (values: T, validate: boolean = false) => {
      this.setState({ values }, () => {
        if (validate) {
          this.validate().then(({ errorCount, errorMap }) => {
            if (errorCount > 0) {
              this.callbacks[CallbackType.OnError].forEach((onError) =>
                onError({ errorCount, errorMap })
              );
            }
          });
        }
      });
    };

    /**
     * @public
     * @param {string} changedPath
     * @param {unknown} changedValue
     * @param {boolean} [validate=true]
     * @memberof WithPortalForm
     */
    public setValue = (
      changedPath: string,
      changedValue: unknown,
      validate = true
    ) => {
      const values = set(this.state.values ?? {}, changedPath, changedValue);

      const payload = {
        changedPath,
        changedValue,
        validate,
        values,
      };

      if (validate) {
        this.callbacks[CallbackType.OnChange].forEach((onChange) =>
          onChange(payload)
        );
      }

      this.setValues(values);
    };

    /**
     * @param {string} path
     * @param {(string | null)} value
     * @memberof WithPortalForm
     */
    public setError = (path: string, value: string | null) => {
      this.setState({ errorMap: { ...this.state.errorMap, [path]: value } });
    };

    /**
     * @param {string} path
     * @memberof WithPortalForm
     */
    public getValue = (path: string) => {
      return get(this.state.values, path);
    };

    /**
     * @param {string} path
     * @memberof WithPortalForm
     */
    public getDefaultValue = (path: string) =>
      get(this.state.defaultValues, path);

    /**
     * @param {string} path
     * @memberof WithPortalForm
     */
    public getError = (path: string) => {
      return this.state.errorMap[path] ?? null;
    };

    /**
     * @memberof WithPortalForm
     */
    public getErrorMap = async (): Promise<ErrorMap> => {
      return validateAll(this.validationMap, this.state.values ?? {});
    };

    /**
     * @memberof WithPortalForm
     */
    public getErrorCount = async (): Promise<number> => {
      const onErrorCountCallbacks = this.callbacks[CallbackType.OnErrorCount];

      const errorCountsFromCallbacks = await Promise.all(
        onErrorCountCallbacks.map(async (cb) => {
          const count = await Promise.resolve().then(cb);

          return count ?? 0;
        })
      );

      const reducedErrorCountsFromCallbacks = errorCountsFromCallbacks.reduce(
        (accum: number, count) => accum + count,
        0
      );

      const totalErrors =
        Object.values(await this.getErrorMap()).length +
        reducedErrorCountsFromCallbacks;

      return totalErrors;
    };

    public reset = (): void => {
      this.setState({
        errorCount: 0,
        errorMap: {},
        values: { ...this.state.defaultValues },
      });
    };

    private get validationMap(): ValidationMap<T> {
      const { fields } = this.state;

      return createValidationMap(fields);
    }

    /**
     * @private
     * @param {CallbackType} type
     * @param {(...args: any[]) => any} callback
     * @memberof WithPortalForm
     */
    private addCallback(type: CallbackType, callback: (...args: any[]) => any) {
      if (!this.callbacks[type].includes(callback)) {
        this.callbacks[type].push(callback);
      }
    }

    /**
     * @private
     * @param {CallbackType} type
     * @param {(...args: any[]) => any} removed
     * @memberof WithPortalForm
     */
    private removeCallback(
      type: CallbackType,
      removed: (...args: any[]) => any
    ) {
      this.callbacks[type] = (this.callbacks[type] as any[]).filter(
        (callback: any) => removed !== callback
      );
    }

    /**
     * @private
     * @type {React.FC}
     * @memberof WithPortalForm
     */
    private ContextWrapper: React.FC<React.PropsWithChildren<unknown>> = ({
      children,
    }) => {
      const { form } = this.props;

      // If a form prop is passed down, we want to use the context of the parent
      // form instead of creating a new context.
      if (form) {
        return <>{children}</>;
      }

      return (
        <PortalFormContext.Provider value={this.formContext}>
          {children}
        </PortalFormContext.Provider>
      );
    };

    /**
     * Form prop to be passed down to wrapped component.
     *
     * @readonly
     * @private
     * @memberof WithPortalForm
     */
    private get formContext(): IPortalFormContext<T> {
      const { form } = this.props;
      const { values, fields } = this.state;

      if (form) {
        return form;
      }

      return {
        addErrorCounter: this.addOnErrorCount,
        addValidator: this.addOnValidate,
        fields,
        getDefaultValue: this.getDefaultValue,
        getError: this.getError,
        getErrorCount: this.getErrorCount,

        getValue: this.getValue,
        onChange: this.addOnChange,
        onError: this.addOnError,
        onErrorCount: this.addOnErrorCount,

        onValidate: this.addOnValidate,
        removeErrorCounter: this.removeOnErrorCount,
        removeOnChange: this.removeOnChange,
        removeOnError: this.removeOnError,
        removeOnErrorCount: this.removeOnErrorCount,
        removeOnValidate: this.removeOnValidate,
        removeValidator: this.removeOnValidate,
        reset: this.reset,

        setDefaultValues: this.setDefaultValues,
        setError: this.setError,
        setFields: this.setFields,
        setValue: this.setValue,
        setValues: this.setValues,
        submit: this.submit,
        validate: this.validate,
        validationMap: this.validationMap,
        values,
      };
    }

    public render() {
      const ContextWrapper = this.ContextWrapper;

      return (
        <ContextWrapper>
          <WrappedComponent
            {...(this.props as WrappedComponentProps<T, P>)}
            form={this.formContext}
          />
        </ContextWrapper>
      );
    }
  }

  if (WrappedComponent?.getInitialProps) {
    WithPortalForm.getInitialProps = WrappedComponent.getInitialProps;
  }

  return WithPortalForm;
}
