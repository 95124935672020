import * as React from 'react';

import type { BarDatum, BarSvgProps } from '@nivo/bar';
import type { LineSvgProps } from '@nivo/line';

import BarChart from '@/components/reporting/chart/bar';
import LineChart from '@/components/reporting/chart/line';
import LineAndBarChart from '@/components/reporting/chart/line-and-bar';
import {
  assembleChartData,
  getCalculatedChartProps,
} from '@/resources/performance-summary/chart-helpers';

export enum ChartType {
  BAR = 'Bar',
  COMBINED = 'Combined',
  LINE = 'Line',
}

export interface ChartProps<T extends BarDatum> {
  barProps?: Partial<BarSvgProps<T>>;
  dataSource: T[];
  defaultChartType?: ChartType;
  lineProps?: Partial<LineSvgProps>;
  onChartTypeChange?: (type: ChartType) => void;
  type: ChartType;
  xAxisKey: keyof T;
  xAxisValueFormatter?: (value: string) => string;
  yAxisKey: keyof T;
  yAxisValueFormatter?: (value: string) => string;
}

export type ChartTypeI<T extends BarDatum = any> = React.FC<ChartProps<T>>;

const Chart: ChartTypeI = ({
  type,
  dataSource,
  xAxisKey,
  yAxisKey,
  barProps,
  lineProps,
  xAxisValueFormatter,
  yAxisValueFormatter,
  defaultChartType,
  onChartTypeChange,
}) => {
  const data = assembleChartData({
    dataSource,
    xAxisKey,
    xAxisValueFormatter,
    yAxisKey,
    yAxisValueFormatter,
  });
  const calculatePropsBar = getCalculatedChartProps(data, undefined, barProps);
  const calculatePropsLine = getCalculatedChartProps(
    data,
    undefined,
    lineProps
  );

  switch (type) {
    case ChartType.BAR:
      return (
        <BarChart
          barProps={{
            ...calculatePropsBar,
            data,
          }}
        />
      );

    case ChartType.LINE:
      return (
        <LineChart
          lineProps={{
            ...calculatePropsLine,
            data,
          }}
        />
      );

    case ChartType.COMBINED:
    default:
      return (
        <LineAndBarChart
          barProps={{
            ...calculatePropsBar,
            data,
          }}
          defaultChartType={defaultChartType}
          lineProps={{
            ...calculatePropsLine,
            data: [{ data, id: `data-by-${yAxisKey?.toString()}` }],
          }}
          onChartTypeChange={onChartTypeChange}
        />
      );
  }
};

export default Chart;
