import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import type { ActionType } from 'typesafe-actions';

import { client } from '@portal/api-client';
import type { PortalRateTable } from '@portal/api-models';

import { OriginatorActionType } from '../actionTypes';
import * as actions from '../actions';

function* getRateTablesByUuid(
  action: ActionType<typeof actions.getRateTablesByUuid.request>
) {
  try {
    const uuid = action.payload.uuid;
    const rateTables = yield call(async () => {
      return client
        .get<PortalRateTable>(`/originator/rate_tables/${uuid}`)
        .then((res) => {
          return res.data;
        });
    });

    yield put(
      actions.getRateTablesByUuid.success({ response: { rateTables }, uuid })
    );
  } catch (err: any) {
    yield put(actions.getRateTablesByUuid.failure(err));
  }
}

function* watchGetRateTablesByUuid() {
  yield takeEvery(
    OriginatorActionType.GET_RATE_TABLES_BY_UUID_REQUEST,
    getRateTablesByUuid
  );
}

export function* rateTablesSaga() {
  yield all([fork(watchGetRateTablesByUuid)]);
}
