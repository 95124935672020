"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.internalViewerConfig = void 0;
exports.internalViewerConfig = {
    canManageAllAccounts: true,
    canReadDemandCaps: true,
    canReadDemandContracts: true,
    canReadOfferCatalogOffers: true,
    canReadOfferCatalogProductTypes: true,
    canReadRuleDemandApiFilters: true,
    canReadStateExclusions: true,
    canReadSupplyDemandCaps: true,
    canSeeAllAccounts: true,
    hasInternalUserRole: true,
    hasViewerRole: true,
    shouldComputeInternalPartnerKeyData: true,
    shouldGetDemandSubAccountsOnUserIndexPage: true,
    shouldGetSubAccountCompliance: true,
    shouldGetSupplySubAccountsOnUserDetailsPage: true,
    shouldGetSupplySubAccountsOnUserIndexPage: true,
    shouldGetUserDeactivationsOnUserDetailsPage: true,
    shouldRedirectIndexPageToAccount: true,
    showExportButton: true,
    showInternalAccessTokenTable: true,
    showInternalAccountDetails: true,
    showInternalContractHistoryTable: true,
    showInternalSubAccountDetails: true,
    showInternalSupplyPartnersTable: true,
    showInternalSupplySubaccountDetails: true,
    showInternalUserDetails: true,
    showInternalUserFeaturedSideNavIfFeatureFlag: true,
    showInternalUserSideNav: true,
    showInternalUsersPage: true,
    showPartnerKeyItemDropdownActions: true,
    showPartnerKeyItemIntegrationStatus: true,
    showPartnerKeyItemLinkToPartnerPage: true,
    showPartnerPageAllowCoApplicantsField: true,
    showPartnerPageEmbedFeaturedOfferField: true,
    showPartnerPageFeatureFlagSelectField: true,
    showPartnerPageLoanPurposeSelectField: true,
    showPartnerPageThemeDisplayNameField: true,
    showPartnerPageThemeHeaderCopyField: true,
    showPartnerPageThemeHeaderTextField: true,
    showPartnerPageThemeInPartnershipWithField: true,
    showPartnerPageTrackingTab: true,
    showPersonalLoanDashboardSubAccountDropdown: true,
    showSubAccountNameColumn: true,
    showUserActivateDeactivateFormFields: true,
    showUserSettingsAccessTab: true,
};
