import has from 'lodash/has';

import type {
  Predicate,
  PredicateAndClause,
  PredicateOrClause,
  PredicateResolver,
} from '@/components/form/portal-form/types';

/**
 * Handle internal predicate logic to determine whether or not a field is displayed.
 *
 * @template T
 *
 * @param {Predicate} predicate
 * @param {T} formValues
 *
 * @returns {boolean}
 */
export const resolvePredicate = <T extends object>(
  predicate: Predicate<T>,
  formValues: T
): boolean => {
  let passes = true;

  const resolveAnd = () =>
    (predicate as PredicateAndClause<T>).and.reduce(
      (passesLocal: boolean, predicateLocal) => {
        return resolvePredicate(predicateLocal, formValues) && passesLocal;
      },
      true
    );

  const resolveOr = () =>
    (predicate as PredicateOrClause<T>).or.reduce(
      (passesLocal: boolean, predicateLocal) => {
        return resolvePredicate(predicateLocal, formValues) || passesLocal;
      },
      false
    );

  if (typeof predicate === 'boolean') {
    passes = predicate;
  } else if (has(predicate, 'and')) {
    passes = resolveAnd();
  } else if (has(predicate, 'or')) {
    passes = resolveOr();
  } else {
    passes = (predicate as PredicateResolver<T>)(formValues);
  }

  return passes;
};

export const resolveValidation = () => {};
