"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.featureFlagReducer = exports.featureFlagInitialState = void 0;
var immer_1 = require("immer");
var typesafe_actions_1 = require("typesafe-actions");
var store_utils_1 = require("@portal/common/dist/utils/store-utils");
var actions_1 = require("./actions");
exports.featureFlagInitialState = {
    createDrawerVisible: false,
    rows: [],
};
exports.featureFlagReducer = (0, typesafe_actions_1.createReducer)(exports.featureFlagInitialState)
    .handleAction(actions_1.getAllFeatureFlagsAction.success, function (state, _a) {
    var featureFlags = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.rows = featureFlags;
    });
})
    .handleAction(actions_1.createFeatureFlagAction.success, function (state, _a) {
    var featureFlag = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.rows = __spreadArray(__spreadArray([], draftState.rows, true), [featureFlag], false);
    });
})
    .handleAction([
    actions_1.enableFeatureFlagAction.success,
    actions_1.disableFeatureFlagAction.success,
    actions_1.updateFeatureFlagAction.success,
], function (state, _a) {
    var featureFlag = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.rows = (0, store_utils_1.replaceByUuid)(draftState.rows, [featureFlag]);
    });
})
    .handleAction(actions_1.deleteFeatureFlagAction.success, function (state, _a) {
    var resp = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.rows = draftState.rows.filter(function (row) { return row.uuid !== resp.uuid; });
    });
})
    .handleAction(actions_1.toggleCreate, function (state) {
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.createDrawerVisible = !draftState.createDrawerVisible;
    });
});
