import * as React from 'react';

import { Switch } from 'antd';

import { FeatureFlag } from '@evenfinancial/even-ts-static';

const InputFeatureFlagSelect = (props: any) => {
  const values: Record<string, boolean> = {};

  props.value?.split(',').forEach((featureFlag: string) => {
    values[featureFlag] = true;
  });

  const onSave = (flagName: string, value: boolean) => {
    values[flagName] = value;
    const newValue = Object.keys(values)
      .filter((value) => values[value] && value)
      .join(',');

    props.onChange(newValue);
  };

  return (
    <div className="partner-page-pixels">
      {Object.values(FeatureFlag).map((flag) => (
        <React.Fragment key={`flag_${flag}`}>
          <div className="partner-page-pixels-row">
            <div className="partner-page-pixels-row-header">
              {flag}
              <Switch
                checkedChildren="on"
                defaultChecked={Boolean(values[flag])}
                unCheckedChildren="off"
                onChange={(visible) => {
                  onSave(flag, visible);
                }}
              />
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default InputFeatureFlagSelect;
