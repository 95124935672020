import moment from 'moment';
import { createSelector } from 'reselect';

import type { PerformanceSummaryQuery } from '@portal/api-models';

import type { ApplicationState } from '@/store';

const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

const formatParams = (params: PerformanceSummaryQuery) => ({
  ...params,
  maxDate: moment(params.maxDate).endOf('day').format(DATE_TIME_FORMAT),
  minDate: moment(params.minDate).startOf('day').format(DATE_TIME_FORMAT),
});

export const selectPerformanceReportingFilters = (state: ApplicationState) =>
  state.performanceSummaries.filters;

export const selectPerformanceReportingEarningsParams = createSelector(
  [selectPerformanceReportingFilters],
  (filters) =>
    formatParams({
      clientTagKey: filters.earningsClientTag,
      groupBy: filters.earningsGroupBy,
      maxDate: filters.maxDate,
      minDate: filters.minDate,
      subAccountUuid: filters.subAccountUuid || '',
    })
);

export const selectPerformanceReportingFunnelParams = createSelector(
  [selectPerformanceReportingFilters],
  (filters) =>
    formatParams({
      clientTagKey: filters.funnelClientTag,
      groupBy: filters.funnelGroupBy,
      maxDate: filters.maxDate,
      minDate: filters.minDate,
      subAccountUuid: filters.subAccountUuid || '',
    })
);

export const selectLoading = (state: ApplicationState) =>
  state.performanceSummaries.loading;

export const selectSummariesByAccountUuid = (state: ApplicationState) =>
  state.performanceSummaries.summaries.byAccountUuid;

export const selectSummariesBySubAccountUuid = (state: ApplicationState) =>
  state.performanceSummaries.summaries.bySubAccountUuid;
