import { Router } from '@/routes';

export const removeParamFromUrl = (key: string) => {
  const query = Router.query;

  if (query?.[key]) {
    delete query[key];

    const pathName = Router.pathname;

    Router.replace(pathName, { pathname: pathName, query }, { shallow: true });
  }
};

export const addParamToQuery = (
  query: string,
  key: string,
  value: string | number | boolean
) => {
  const concatenator: string = query ? '&' : '?';

  return `${query}${concatenator}${key}=${value}`;
};
