"use strict";
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.featureFlagSaga = exports.deleteFeatureFlag = exports.disableFeatureFlag = exports.enableFeatureFlag = exports.createFeatureFlag = exports.getAllFeatureFlags = void 0;
var antd_1 = require("antd");
var effects_1 = require("redux-saga/effects");
var typesafe_actions_1 = require("typesafe-actions");
var api_client_1 = require("@portal/api-client");
var common_1 = require("@portal/common");
var actions_1 = require("./actions");
function getAllFeatureFlags() {
    var response, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4, (0, effects_1.call)([api_client_1.client, 'get'], '/feature-flags/', {
                        params: { all: true, limit: 1000 },
                    })];
            case 1:
                response = (_a.sent()).data;
                return [4, (0, effects_1.put)(actions_1.getAllFeatureFlagsAction.success(response))];
            case 2:
                _a.sent();
                return [3, 5];
            case 3:
                err_1 = _a.sent();
                return [4, (0, effects_1.put)(actions_1.getAllFeatureFlagsAction.failure(err_1))];
            case 4:
                _a.sent();
                return [3, 5];
            case 5: return [2];
        }
    });
}
exports.getAllFeatureFlags = getAllFeatureFlags;
function createFeatureFlag(_a) {
    var response, err_2, portalError;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 7]);
                return [4, (0, effects_1.call)([api_client_1.client, 'post'], '/feature-flags/', payload)];
            case 1:
                response = (_b.sent()).data;
                return [4, (0, effects_1.put)(actions_1.createFeatureFlagAction.success(response))];
            case 2:
                _b.sent();
                return [4, (0, effects_1.call)([antd_1.message, 'success'], 'Feature flag created successfully')];
            case 3:
                _b.sent();
                return [4, (0, effects_1.put)((0, actions_1.toggleCreate)())];
            case 4:
                _b.sent();
                return [3, 7];
            case 5:
                err_2 = _b.sent();
                portalError = (0, common_1.getPortalError)(err_2);
                return [4, (0, effects_1.put)(actions_1.createFeatureFlagAction.failure(portalError))];
            case 6:
                _b.sent();
                return [3, 7];
            case 7: return [2];
        }
    });
}
exports.createFeatureFlag = createFeatureFlag;
function updateFeatureFlag(_a) {
    var response, err_3;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4, (0, effects_1.call)([api_client_1.client, 'put'], "/feature-flags/".concat(payload.uuid), payload.updateData)];
            case 1:
                response = (_b.sent()).data;
                return [4, (0, effects_1.put)(actions_1.updateFeatureFlagAction.success(response))];
            case 2:
                _b.sent();
                return [4, (0, effects_1.call)([antd_1.message, 'success'], 'Feature flag updated successfully')];
            case 3:
                _b.sent();
                return [3, 6];
            case 4:
                err_3 = _b.sent();
                return [4, (0, effects_1.put)(actions_1.updateFeatureFlagAction.failure(err_3))];
            case 5:
                _b.sent();
                return [3, 6];
            case 6: return [2];
        }
    });
}
function enableFeatureFlag(_a) {
    var url, body, response, err_4;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                url = "/feature-flags/".concat(payload.uuid);
                body = void 0;
                if (payload.isStaging) {
                    body = {
                        enabledStaging: true,
                    };
                }
                else {
                    url += '/enable';
                }
                return [4, (0, effects_1.call)([api_client_1.client, 'put'], url, body)];
            case 1:
                response = (_b.sent()).data;
                return [4, (0, effects_1.put)(actions_1.enableFeatureFlagAction.success(response))];
            case 2:
                _b.sent();
                return [4, (0, effects_1.call)([antd_1.message, 'success'], 'Feature flag updated successfully')];
            case 3:
                _b.sent();
                return [3, 6];
            case 4:
                err_4 = _b.sent();
                return [4, (0, effects_1.put)(actions_1.enableFeatureFlagAction.failure(err_4))];
            case 5:
                _b.sent();
                return [3, 6];
            case 6: return [2];
        }
    });
}
exports.enableFeatureFlag = enableFeatureFlag;
function disableFeatureFlag(_a) {
    var url, body, response, err_5;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                url = "/feature-flags/".concat(payload.uuid);
                body = void 0;
                if (payload.isStaging) {
                    body = {
                        enabledStaging: false,
                    };
                }
                else {
                    url += '/disable';
                }
                return [4, (0, effects_1.call)([api_client_1.client, 'put'], url, body)];
            case 1:
                response = (_b.sent()).data;
                return [4, (0, effects_1.put)(actions_1.disableFeatureFlagAction.success(response))];
            case 2:
                _b.sent();
                return [4, (0, effects_1.call)([antd_1.message, 'success'], 'Feature flag disabled successfully')];
            case 3:
                _b.sent();
                return [3, 6];
            case 4:
                err_5 = _b.sent();
                return [4, (0, effects_1.put)(actions_1.disableFeatureFlagAction.failure(err_5))];
            case 5:
                _b.sent();
                return [3, 6];
            case 6: return [2];
        }
    });
}
exports.disableFeatureFlag = disableFeatureFlag;
function deleteFeatureFlag(_a) {
    var err_6;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4, (0, effects_1.call)([api_client_1.client, 'delete'], "/feature-flags/".concat(payload.uuid))];
            case 1:
                _b.sent();
                return [4, (0, effects_1.put)(actions_1.deleteFeatureFlagAction.success({ uuid: payload.uuid }))];
            case 2:
                _b.sent();
                return [4, (0, effects_1.call)([antd_1.message, 'success'], 'Feature flag deleted successfully')];
            case 3:
                _b.sent();
                return [3, 6];
            case 4:
                err_6 = _b.sent();
                return [4, (0, effects_1.put)(actions_1.deleteFeatureFlagAction.failure(err_6))];
            case 5:
                _b.sent();
                return [3, 6];
            case 6: return [2];
        }
    });
}
exports.deleteFeatureFlag = deleteFeatureFlag;
function featureFlagSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, (0, effects_1.takeLatest)((0, typesafe_actions_1.getType)(actions_1.getAllFeatureFlagsAction.request), getAllFeatureFlags)];
            case 1:
                _a.sent();
                return [4, (0, effects_1.takeLatest)((0, typesafe_actions_1.getType)(actions_1.createFeatureFlagAction.request), createFeatureFlag)];
            case 2:
                _a.sent();
                return [4, (0, effects_1.takeLatest)((0, typesafe_actions_1.getType)(actions_1.updateFeatureFlagAction.request), updateFeatureFlag)];
            case 3:
                _a.sent();
                return [4, (0, effects_1.takeLatest)((0, typesafe_actions_1.getType)(actions_1.enableFeatureFlagAction.request), enableFeatureFlag)];
            case 4:
                _a.sent();
                return [4, (0, effects_1.takeLatest)((0, typesafe_actions_1.getType)(actions_1.disableFeatureFlagAction.request), disableFeatureFlag)];
            case 5:
                _a.sent();
                return [4, (0, effects_1.takeLatest)((0, typesafe_actions_1.getType)(actions_1.deleteFeatureFlagAction.request), deleteFeatureFlag)];
            case 6:
                _a.sent();
                return [2];
        }
    });
}
exports.featureFlagSaga = featureFlagSaga;
