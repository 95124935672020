"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetFunnelSummaryQuery = exports.useGetPayoutSummaryQuery = exports.getFunnelSummary = exports.getPayoutSummary = void 0;
var react_query_1 = require("react-query");
var client_1 = require("../client");
var types_1 = require("../types");
var getPayoutSummary = function (params) {
    return client_1.client
        .get("/analytics/performanceSummaries/".concat(params.productType, "/payout"), { params: params })
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.getPayoutSummary = getPayoutSummary;
var getFunnelSummary = function (params) {
    return client_1.client
        .get("/analytics/performanceSummaries/".concat(params.productType, "/funnel"), { params: params })
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.getFunnelSummary = getFunnelSummary;
var useGetPayoutSummaryQuery = function (params, options) {
    return (0, react_query_1.useQuery)([types_1.QueryKey.PerformanceSummaryPayout, params], function () { return (0, exports.getPayoutSummary)(params); }, options);
};
exports.useGetPayoutSummaryQuery = useGetPayoutSummaryQuery;
var useGetFunnelSummaryQuery = function (params, options) {
    return (0, react_query_1.useQuery)([types_1.QueryKey.PerformanceSummaryFunnel, params], function () { return (0, exports.getFunnelSummary)(params); }, options);
};
exports.useGetFunnelSummaryQuery = useGetFunnelSummaryQuery;
