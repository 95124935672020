import type { ProfileMeta } from '@portal/api-models';
import {
  hasFeatures,
  hasRoles,
  hasScopes,
} from '@portal/common/dist/access-control';

import { passesRouteGuards } from '@/guards/route-guards';
import type { RouteGuards } from '@/guards/route-guards';
import type { GuardAttribute, GuardObject } from '@/routes-with-auth';
import type { ApplicationState } from '@/store';

export enum DefaultBehavior {
  Any,
  All,
}

/**
 * Returns true if guards pass evaluation.
 */
export const resolveGuardAttribute = <T>(
  attribute: GuardAttribute<T> | undefined,
  evaluateAny: (items: T[]) => boolean,
  evaluateAll: (items: T[]) => boolean,
  defaultBehavior: DefaultBehavior
): boolean => {
  if (attribute && Array.isArray(attribute)) {
    if (defaultBehavior === DefaultBehavior.Any) {
      return evaluateAny(attribute);
    }

    return evaluateAll(attribute);
  }

  if (attribute && typeof attribute === 'object') {
    const all = (attribute as GuardObject<T>)?.all;
    const any = (attribute as GuardObject<T>)?.any;

    if (any) {
      return evaluateAny(any);
    }

    if (all) {
      return evaluateAll(all);
    }
  }

  return true;
};

export const resolveRoles = (
  userRoles: string[],
  rolesAttribute?: GuardAttribute<string>
) =>
  resolveGuardAttribute(
    rolesAttribute,
    (roles) => hasRoles(userRoles, roles, false),
    (roles) => hasRoles(userRoles, roles, true),
    DefaultBehavior.Any
  );

export const resolveScopes = (
  userScopes: string[],
  scopesAttribute?: GuardAttribute<string>
) =>
  resolveGuardAttribute(
    scopesAttribute,
    (scopes) => hasScopes(userScopes, scopes, false),
    (scopes) => hasScopes(userScopes, scopes, true),
    DefaultBehavior.Any
  );

export const resolveFeatureFlags = (
  userFeatureFlags: string[],
  scopesAttribute?: GuardAttribute<string>
) =>
  resolveGuardAttribute(
    scopesAttribute,
    (featureFlags) => hasFeatures(userFeatureFlags, featureFlags, false),
    (featureFlags) => hasFeatures(userFeatureFlags, featureFlags, true),
    DefaultBehavior.Any
  );

export const resolveGuards = (
  state: ApplicationState,
  profileMeta: ProfileMeta,
  guardsAttribute: GuardAttribute<RouteGuards>
) =>
  resolveGuardAttribute(
    guardsAttribute,
    (guards) => passesRouteGuards(state, profileMeta, guards, false),
    (guards) => passesRouteGuards(state, profileMeta, guards, true),
    DefaultBehavior.All
  );
