"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DemandPlatformFeeConfigPercentOf = exports.DemandPlatformFeeConfigType = exports.SupplyMigrationSettings = exports.FeeType = exports.PricingTier = exports.TrialDuration = void 0;
var TrialDuration;
(function (TrialDuration) {
    TrialDuration[TrialDuration["ThreeThousandSixHundredFifty"] = 3650] = "ThreeThousandSixHundredFifty";
    TrialDuration[TrialDuration["ThreeHundredSixty"] = 360] = "ThreeHundredSixty";
    TrialDuration[TrialDuration["OneHundredEighty"] = 180] = "OneHundredEighty";
    TrialDuration[TrialDuration["Ninety"] = 90] = "Ninety";
    TrialDuration[TrialDuration["Sixty"] = 60] = "Sixty";
    TrialDuration[TrialDuration["Thirty"] = 30] = "Thirty";
})(TrialDuration = exports.TrialDuration || (exports.TrialDuration = {}));
var PricingTier;
(function (PricingTier) {
    PricingTier["GrowthCustom"] = "growth_custom";
    PricingTier["GrowthStandard"] = "growth_standard";
    PricingTier["ScaleCustom"] = "scale_custom";
    PricingTier["StarterCustom"] = "starter_custom";
    PricingTier["StarterStandard"] = "starter_standard";
})(PricingTier = exports.PricingTier || (exports.PricingTier = {}));
var FeeType;
(function (FeeType) {
    FeeType["CustomPricing"] = "custom_pricing";
    FeeType["Waived"] = "waived";
})(FeeType = exports.FeeType || (exports.FeeType = {}));
var SupplyMigrationSettings = (function () {
    function SupplyMigrationSettings() {
    }
    return SupplyMigrationSettings;
}());
exports.SupplyMigrationSettings = SupplyMigrationSettings;
var DemandPlatformFeeConfigType;
(function (DemandPlatformFeeConfigType) {
    DemandPlatformFeeConfigType["PercentageOf"] = "percentageOf";
    DemandPlatformFeeConfigType["SupplySubAccount"] = "supplySubAccount";
    DemandPlatformFeeConfigType["Tiered"] = "tiered";
})(DemandPlatformFeeConfigType = exports.DemandPlatformFeeConfigType || (exports.DemandPlatformFeeConfigType = {}));
var DemandPlatformFeeConfigPercentOf;
(function (DemandPlatformFeeConfigPercentOf) {
    DemandPlatformFeeConfigPercentOf["DemandReceivable"] = "demandReceivable";
    DemandPlatformFeeConfigPercentOf["FinancialProductMonetaryValue"] = "financialProductMonetaryValue";
    DemandPlatformFeeConfigPercentOf["NetworkPayout"] = "networkPayout";
})(DemandPlatformFeeConfigPercentOf = exports.DemandPlatformFeeConfigPercentOf || (exports.DemandPlatformFeeConfigPercentOf = {}));
