"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.internalViewerScopes = void 0;
var enum_1 = require("../enum");
exports.internalViewerScopes = [
    enum_1.Scopes.AccessTokensRead,
    enum_1.Scopes.AccountAdjustmentsRead,
    enum_1.Scopes.AccountDeactivationsRead,
    enum_1.Scopes.AccountMetadataRead,
    enum_1.Scopes.AccountsRead,
    enum_1.Scopes.ApplicationsRead,
    enum_1.Scopes.BenchmarkReportsRead,
    enum_1.Scopes.BillingCyclesRead,
    enum_1.Scopes.CommunicationEligibilitiesRead,
    enum_1.Scopes.CompaniesRead,
    enum_1.Scopes.ComplianceRead,
    enum_1.Scopes.DeactivationsRead,
    enum_1.Scopes.DeduplicationConfigurationsRead,
    enum_1.Scopes.DemandApisRead,
    enum_1.Scopes.DemandContractsRead,
    enum_1.Scopes.DemandDataImportsRead,
    enum_1.Scopes.DemandSubAccountInventoryCapsRead,
    enum_1.Scopes.DuplicateSummariesRead,
    enum_1.Scopes.FeatureFlagsRead,
    enum_1.Scopes.FeedbacksCreate,
    enum_1.Scopes.ImportedLeadEventsRead,
    enum_1.Scopes.LeadsRead,
    enum_1.Scopes.LedgerItemsRead,
    enum_1.Scopes.MciReportsRead,
    enum_1.Scopes.OfferCatalogOffersRead,
    enum_1.Scopes.OfferCatalogProductsRead,
    enum_1.Scopes.OfferCatalogProductVersionsRead,
    enum_1.Scopes.OfferRulesRead,
    enum_1.Scopes.OffersRead,
    enum_1.Scopes.PartnerPagesRead,
    enum_1.Scopes.RateTablesRead,
    enum_1.Scopes.RevenueReportsRead,
    enum_1.Scopes.RichAccessTokensRead,
    enum_1.Scopes.RuleDemandApiFilterRead,
    enum_1.Scopes.StateExclusionsRead,
    enum_1.Scopes.SubAccountsRead,
    enum_1.Scopes.SupplyContractsRead,
    enum_1.Scopes.SupplyDemandInclusionsRead,
    enum_1.Scopes.SupplyDemandSubAccountInventoryCapsRead,
    enum_1.Scopes.SupplyMigrationRead,
    enum_1.Scopes.SupplySummariesRead,
    enum_1.Scopes.SupplyTemplateApplicationRead,
    enum_1.Scopes.SupplyTemplatesLendersRead,
    enum_1.Scopes.SupplyTemplatesRead,
    enum_1.Scopes.UserDeactivationsRead,
    enum_1.Scopes.UsersRead,
];
