"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.payoutClauseToProductTypeMap = exports.payoutClauseProductTypeSorter = void 0;
const payout_clause_type_1 = require("./payout-clause-type");
const CREDIT_CARD = 'Credit Card';
const GLOBAL = 'Global';
const INSURANCE = 'Insurance';
const LEGACY = 'Legacy';
const LOAN = 'Loan';
const MORTGAGE = 'Mortgage';
const OTHER = 'Other';
const SAVINGS = 'Savings';
const GENERIC = 'Generic';
const payoutClauseProductTypeOrder = [
    GLOBAL,
    CREDIT_CARD,
    INSURANCE,
    LOAN,
    MORTGAGE,
    SAVINGS,
    OTHER,
    LEGACY,
];
const payoutClauseProductTypeSorter = (a, b) => payoutClauseProductTypeOrder.indexOf(a) -
    payoutClauseProductTypeOrder.indexOf(b);
exports.payoutClauseProductTypeSorter = payoutClauseProductTypeSorter;
exports.payoutClauseToProductTypeMap = {
    [payout_clause_type_1.PayoutClauseType.Bidder]: GLOBAL,
    [payout_clause_type_1.PayoutClauseType.BidderDemandExclusions]: GLOBAL,
    [payout_clause_type_1.PayoutClauseType.CostPerCompletedApplication]: GLOBAL,
    [payout_clause_type_1.PayoutClauseType.CostPerCompletedApplication60ByCreditRating]: GLOBAL,
    [payout_clause_type_1.PayoutClauseType.CostPerCompletedApplicationByCreditRating]: GLOBAL,
    [payout_clause_type_1.PayoutClauseType.CostPerCompletedApplicationByCreditRatingAndDeviceType]: GLOBAL,
    [payout_clause_type_1.PayoutClauseType.CostPerCompletedApplicationBySubId]: GLOBAL,
    [payout_clause_type_1.PayoutClauseType.CostPerLead]: GLOBAL,
    [payout_clause_type_1.PayoutClauseType.CostPerLeadByCreditRating]: GLOBAL,
    [payout_clause_type_1.PayoutClauseType.CostPerLeadByCreditRatingAndDeviceType]: GLOBAL,
    [payout_clause_type_1.PayoutClauseType.CostPerLeadByCreditRatingBySubId]: GLOBAL,
    [payout_clause_type_1.PayoutClauseType.CostPerLeadByDeviceType]: GLOBAL,
    [payout_clause_type_1.PayoutClauseType.CostPerLeadByLifeInsuranceApprovalProbabilityScore]: LEGACY,
    [payout_clause_type_1.PayoutClauseType.CostPerLeadBySubId]: GLOBAL,
    [payout_clause_type_1.PayoutClauseType.CostPerLeadByVantageScore]: GLOBAL,
    [payout_clause_type_1.PayoutClauseType.CostPerLifeInsuranceApplied]: LEGACY,
    [payout_clause_type_1.PayoutClauseType.CostPerPreSelectedCreditCardOfferByDemand]: LEGACY,
    [payout_clause_type_1.PayoutClauseType.CostPerUniqueFundedLoan]: LOAN,
    [payout_clause_type_1.PayoutClauseType.CostPerUniqueFundedLoanBySubId]: LEGACY,
    [payout_clause_type_1.PayoutClauseType.CostPerUniqueLoanClick]: LOAN,
    [payout_clause_type_1.PayoutClauseType.CostPerUniqueLoanClickByDemand]: LEGACY,
    [payout_clause_type_1.PayoutClauseType.CostPerUniqueLoanClickBySubId]: LEGACY,
    [payout_clause_type_1.PayoutClauseType.CostPerUniqueOtherClick]: OTHER,
    [payout_clause_type_1.PayoutClauseType.CostPerUniqueOtherClickByOfferId]: LEGACY,
    [payout_clause_type_1.PayoutClauseType.CostPerUniqueSavingsClick]: SAVINGS,
    [payout_clause_type_1.PayoutClauseType.EmailCampaign]: LEGACY,
    [payout_clause_type_1.PayoutClauseType.EvenOwned]: LEGACY,
    [payout_clause_type_1.PayoutClauseType.LifeInsurancePayout]: LEGACY,
    [payout_clause_type_1.PayoutClauseType.MediaBuy]: LEGACY,
    [payout_clause_type_1.PayoutClauseType.PercentOfFundedLoanAmount]: LEGACY,
    [payout_clause_type_1.PayoutClauseType.RevenueShareCreditCard]: CREDIT_CARD,
    [payout_clause_type_1.PayoutClauseType.RevenueShareInsurance]: INSURANCE,
    [payout_clause_type_1.PayoutClauseType.RevenueShareLoan]: LOAN,
    [payout_clause_type_1.PayoutClauseType.RevenueShareLoanByDemand]: LOAN,
    [payout_clause_type_1.PayoutClauseType.RevenueShareMortgage]: MORTGAGE,
    [payout_clause_type_1.PayoutClauseType.RevenueShareOther]: OTHER,
    [payout_clause_type_1.PayoutClauseType.RevenueShareSavings]: SAVINGS,
    [payout_clause_type_1.PayoutClauseType.GenericRevenueShare]: GENERIC,
};
