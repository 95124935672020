import Checkbox from 'antd/lib/checkbox';
import DatePicker from 'antd/lib/date-picker';
import Input from 'antd/lib/input';
import InputPassword from 'antd/lib/input/Password';
import InputNumber from 'antd/lib/input-number';
import Switch from 'antd/lib/switch';
import { get, set, unset } from 'lodash';

import AccountDropdown from '@/components/form/account-dropdown';
import Custom from '@/components/form/portal-form/fields/custom';
import HexColorInput from '@/components/form/portal-form/fields/hex-color-input';
import InputRange from '@/components/form/portal-form/fields/input-range';
import RadioGroup from '@/components/form/portal-form/fields/radio-group';
import Repeatable from '@/components/form/portal-form/fields/repeatable';
import Select from '@/components/form/portal-form/fields/select';
import Upload from '@/components/form/portal-form/fields/upload';
import SubAccountDropdown from '@/components/form/sub-account-dropdown';
import SubAccountProductTypeDropdown from '@/components/form/sub-account-product-type-dropdown';
import { SubAccountOfferCatalogProductTypeDropdown } from '@/components/form/sub-account-product-type-dropdown/offer-catalog';
import UsStatesDropdown from '@/components/form/us-state-dropdown';
import UserDropdown from '@/components/form/user-dropdown';

import { PortalFormFieldType } from './types';
import type { PortalFormField, PortalFormFieldImportMap } from './types';

export const getDefaultValueFromFieldProps = <T extends object>(
  field: PortalFormField<T>
) => {
  switch (field.type) {
    case PortalFormFieldType.Checkbox:
    case PortalFormFieldType.Switch:
      return field?.fieldProps?.defaultChecked;

    default:
      return (field?.fieldProps as { defaultValue: unknown })?.defaultValue;
  }
};

/**
 * Handle dynamic imports for each field type.
 */
export const importMap: PortalFormFieldImportMap = {
  [PortalFormFieldType.Checkbox]: Checkbox,
  [PortalFormFieldType.Input]: Input,
  [PortalFormFieldType.InputNumber]: InputNumber,
  [PortalFormFieldType.InputPassword]: InputPassword,
  [PortalFormFieldType.Switch]: Switch,
  [PortalFormFieldType.DatePicker]: DatePicker,
  [PortalFormFieldType.DateRangePicker]: DatePicker.RangePicker,
  [PortalFormFieldType.TextArea]: Input.TextArea,
  [PortalFormFieldType.Custom]: Custom,
  [PortalFormFieldType.InputRange]: InputRange,
  [PortalFormFieldType.Repeatable]: Repeatable,
  [PortalFormFieldType.Select]: Select,
  [PortalFormFieldType.RadioGroup]: RadioGroup,
  [PortalFormFieldType.UserDropdown]: UserDropdown,
  [PortalFormFieldType.SubAccountDropdown]: SubAccountDropdown,
  [PortalFormFieldType.AccountDropdown]: AccountDropdown,
  [PortalFormFieldType.UsStateDropdown]: UsStatesDropdown,
  [PortalFormFieldType.SubAccountOfferCatalogProductTypeDropdown]:
    SubAccountOfferCatalogProductTypeDropdown,
  [PortalFormFieldType.SubAccountProductTypeDropdown]:
    SubAccountProductTypeDropdown,
  [PortalFormFieldType.Upload]: Upload,
  [PortalFormFieldType.HexColorInput]: HexColorInput,
};

export const getFormValues = <T extends object>(
  defaultValues: T | {} = {},
  fields: PortalFormField<T>[]
): T => {
  const values: Partial<T> = {};

  fields.forEach((field) => {
    const { name } = field;

    if (name) {
      const initialValue =
        get(defaultValues, name) ?? getDefaultValueFromFieldProps(field);

      set(values, name, initialValue);
    }
  });

  return values as T;
};

export const getPopulatedFormValues = <T extends object>(
  values: T,
  fields: PortalFormField<T>[]
): T => {
  const clonedValues = { ...values };

  fields.forEach(({ name }) => {
    if (name) {
      if (get(clonedValues, name) === undefined) {
        unset(clonedValues, name);
      }
    }
  });

  return clonedValues;
};
