import type { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import type { ActionType } from 'typesafe-actions';

import type { SupplyTemplate } from '@evenfinancial/originator-client';

import { client } from '@portal/api-client';

import {
  error,
  loadingEnd,
  loadingStart,
  success,
} from '../../message/actions';
import { OriginatorActionType } from '../actionTypes';
import {
  deactivateSupplyTemplateAction,
  supplyTemplateCreateAction,
  supplyTemplateUpdateAction,
  supplyTemplatesRequestAction,
} from '../actions';

export function* getSupplyTemplates({
  payload,
}: ActionType<typeof supplyTemplatesRequestAction.request>) {
  try {
    const { query } = payload;

    const { data }: AxiosResponse<SupplyTemplate[]> = yield call(
      [client, 'get'],
      '/originator/supply_templates',
      {
        params: query,
      }
    );

    yield put(supplyTemplatesRequestAction.success(data));

    // return so that putResolve can finish the saga
    return data;
  } catch (err: unknown) {
    yield put(supplyTemplatesRequestAction.failure(err));
  }
}

export function* deactivateSupplyTemplate({
  payload,
}: ActionType<typeof deactivateSupplyTemplateAction.request>) {
  try {
    const { supplyTemplateId } = payload;

    yield call(
      [client, 'put'],
      `/originator/supply_templates/deactivate/${supplyTemplateId}`
    );

    yield put(supplyTemplatesRequestAction.request({ query: {} }));
    yield put(deactivateSupplyTemplateAction.success());
  } catch (err: unknown) {
    yield put(deactivateSupplyTemplateAction.failure(err));
  }
}
export function* createSupplyTemplate({
  payload: { query, router },
}: ActionType<typeof supplyTemplateCreateAction.request>) {
  try {
    yield put(loadingStart());

    const { data }: AxiosResponse<SupplyTemplate> = yield call(
      [client, 'post'],
      '/originator/supply_templates',
      query
    );

    yield put(supplyTemplatesRequestAction.success([data]));

    yield call([router, 'pushRoute'], `/channels/template-center/${data.uuid}`);
    yield put(success('Successfully created template!'));
  } catch {
    yield put(error({ customError: 'Unable to create template' }));
  } finally {
    yield put(loadingEnd());
  }
}

export function* updateSupplyTemplate({
  payload: { query, router },
}: ActionType<typeof supplyTemplateUpdateAction>) {
  try {
    yield put(loadingStart());

    const { data }: AxiosResponse<SupplyTemplate> = yield call(
      [client, 'put'],
      '/originator/supply_templates',
      query
    );

    yield put(supplyTemplatesRequestAction.success([data]));

    yield call([router, 'pushRoute'], `/channels/template-center/${data.uuid}`);

    yield put(success('Successfully updated template!'));
  } catch (err) {
    yield put(error({ customError: 'Unable to update template' }));
  } finally {
    yield put(loadingEnd());
  }
}

export function* supplyTemplateSaga() {
  yield takeLatest(
    getType(supplyTemplatesRequestAction.request),
    getSupplyTemplates
  );

  yield takeLatest(
    OriginatorActionType.DEACTIVATE_SUPPLY_TEMPLATE_REQUEST,
    deactivateSupplyTemplate
  );
  yield takeLatest(
    getType(supplyTemplateCreateAction.request),
    createSupplyTemplate
  );
  yield takeLatest(getType(supplyTemplateUpdateAction), updateSupplyTemplate);
}
