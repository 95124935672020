import type { ActionType } from 'typesafe-actions';

import type { LedgerItemType } from '@evenfinancial/finance-client';

import type { PortalBillingCycle } from '@portal/api-models';

import type * as actions from './actions';

export type BillingCycleAction = ActionType<typeof actions>;

export enum BillingCycleActionType {
  CREATE_FAILURE_CLIENT = '@@billing-cycle/CREATE_FAILURE_CLIENT',
  CREATE_REQUEST_CLIENT = '@@billing-cycle/CREATE_REQUEST_CLIENT',
  CREATE_SUCCESS_CLIENT = '@@billing-cycle/CREATE_SUCCESS_CLIENT',

  DELETE_FAILURE_CLIENT = '@@billing-cycle/DELETE_FAILURE_CLIENT',
  DELETE_REQUEST_CLIENT = '@@billing-cycle/DELETE_REQUEST_CLIENT',
  DELETE_SUCCESS_CLIENT = '@@billing-cycle/DELETE_SUCCESS_CLIENT',

  END_FAILURE_CLIENT = '@@billing-cycle/END_FAILURE_CLIENT',
  END_REQUEST_CLIENT = '@@billing-cycle/END_REQUEST_CLIENT',
  END_SUCCESS_CLIENT = '@@billing-cycle/END_SUCCESS_CLIENT',

  GET_ALL_FAILURE = '@@billing-cycle/GET_ALL_FAILURE',
  GET_ALL_REQUEST = '@@billing-cycle/GET_ALL_REQUEST',
  GET_ALL_SUCCESS = '@@billing-cycle/GET_ALL_SUCCESS',

  TOGGLE_CREATE = '@@billing-cycle/TOGGLE_CREATE',
}

export interface BillingCycleState {
  createDrawerVisible: boolean;
  ledgerItemType?: LedgerItemType;
  rows: PortalBillingCycle[];
}
