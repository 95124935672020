import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useOktaAuth } from '@okta/okta-react';

import { OnboardingContractType } from '@evenfinancial/finance-client';

import { LegalAgreementType } from '@portal/common/dist/legal-agreements';
import {
  getAllOnboardingContractsAction,
  getSignedOnboardingContractsAction,
  selectOnboardingContractState,
  signOnboardingContractsAction,
} from '@portal/store/dist';
import { selectIsOnboardingContractSigned } from '@portal/store/dist/user/selectors';

import AgreementModal from '@/components/agreement-modal';
import { LegalAgreement } from '@/components/legal-agreement';

/**
 * Legal agreement modal that all portal users must agree to before
 * they can access the site.
 */
export const LegalAgreementModal = () => {
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();

  const {
    haveOnboardingContractsBeenRequested,
    haveSignedOnboardingContractsBeenRequested,
  } = useSelector(selectOnboardingContractState);

  const isOnboardingContractSigned = useSelector(
    selectIsOnboardingContractSigned
  );

  const handleAgree = () =>
    dispatch(
      signOnboardingContractsAction.request({
        types: [
          OnboardingContractType.TermsOfService,
          OnboardingContractType.PrivacyPolicy,
        ],
      })
    );

  const handleCancel = async () => oktaAuth.signOut();

  useEffect(() => {
    dispatch(getAllOnboardingContractsAction.request({}));
    dispatch(getSignedOnboardingContractsAction.request({}));
  }, []);

  if (
    !isOnboardingContractSigned &&
    haveOnboardingContractsBeenRequested &&
    haveSignedOnboardingContractsBeenRequested
  ) {
    return (
      <AgreementModal
        title="Terms and Services"
        visible={!isOnboardingContractSigned}
        onAgree={handleAgree}
        onCancel={handleCancel}
      >
        <LegalAgreement
          legalAgreementType={LegalAgreementType.ControlCenterTermsOfUse}
        />
        <LegalAgreement legalAgreementType={LegalAgreementType.PrivacyPolicy} />
      </AgreementModal>
    );
  }

  return null;
};
