"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTransUnionLeadAttribute = exports.isSelfReportedLeadAttribute = exports.isSetLeadAttribute = exports.isBooleanLeadAttribute = exports.isNumericLeadAttribute = exports.isHighOrderRule = exports.isLeadAttributeRule = exports.isSetRule = exports.isComparisonValueRule = exports.transUnionLeadAttributes = exports.selfReportedLeadAttributes = exports.numericLeadAttributes = exports.setLeadAttributes = exports.booleanLeadAttributes = void 0;
const originator_client_1 = require("@evenfinancial/originator-client");
const api_models_1 = require("@portal/api-models");
exports.booleanLeadAttributes = Object.values(originator_client_1.LeadAttributeBoolean);
exports.setLeadAttributes = Object.values(originator_client_1.LeadAttributeString);
exports.numericLeadAttributes = Object.values(originator_client_1.LeadAttributeNumeric);
exports.selfReportedLeadAttributes = [
    originator_client_1.LeadAttributeBoolean.HasDirectDeposit,
    originator_client_1.LeadAttributeString.BankRoutingNumber,
    originator_client_1.LeadAttributeString.CitizenshipStatus,
    originator_client_1.LeadAttributeString.EducationLevel,
    originator_client_1.LeadAttributeString.EmploymentPayFrequency,
    originator_client_1.LeadAttributeString.EmploymentStatus,
    originator_client_1.LeadAttributeString.LoanPurpose,
    originator_client_1.LeadAttributeString.PropertyStatus,
    originator_client_1.LeadAttributeString.SelfReportedCreditRating,
    originator_client_1.LeadAttributeString.State,
    originator_client_1.LeadAttributeString.Zipcode,
    originator_client_1.LeadAttributeNumeric.Age,
    originator_client_1.LeadAttributeNumeric.AnnualIncome,
    originator_client_1.LeadAttributeNumeric.MonthlyGrossIncome,
    originator_client_1.LeadAttributeNumeric.MonthlyNetIncome,
    originator_client_1.LeadAttributeNumeric.RequestToIncomeRatio,
    originator_client_1.LeadAttributeNumeric.RequestedLoanAmount,
    originator_client_1.LeadAttributeNumeric.SelfReportedCreditScore,
];
exports.transUnionLeadAttributes = [
    originator_client_1.LeadAttributeBoolean.IsFrozen,
    originator_client_1.LeadAttributeString.PublicRecordCode,
    originator_client_1.LeadAttributeNumeric.ClosedWithBalanceBalance,
    originator_client_1.LeadAttributeNumeric.ClosedWithBalancePastDue,
    originator_client_1.LeadAttributeNumeric.CollectionCount,
    originator_client_1.LeadAttributeNumeric.DaysOnFile,
    originator_client_1.LeadAttributeNumeric.DebtToIncomeRatio,
    originator_client_1.LeadAttributeNumeric.FicoScore8,
    originator_client_1.LeadAttributeNumeric.HistoricalNegativeOccurrencesCount,
    originator_client_1.LeadAttributeNumeric.InquiryCount,
    originator_client_1.LeadAttributeNumeric.InstallmentBalance,
    originator_client_1.LeadAttributeNumeric.InstallmentPastDue,
    originator_client_1.LeadAttributeNumeric.MortgageBalance,
    originator_client_1.LeadAttributeNumeric.MortgagePastDue,
    originator_client_1.LeadAttributeNumeric.NegativeTradeCount,
    originator_client_1.LeadAttributeNumeric.NonMortgageDebtToIncomeRatio,
    originator_client_1.LeadAttributeNumeric.OpenBalance,
    originator_client_1.LeadAttributeNumeric.OpenPastDue,
    originator_client_1.LeadAttributeNumeric.RevolvingBalance,
    originator_client_1.LeadAttributeNumeric.RevolvingPastDue,
    originator_client_1.LeadAttributeNumeric.TotalBalance,
    originator_client_1.LeadAttributeNumeric.TotalBalanceMinusMortgage,
    originator_client_1.LeadAttributeNumeric.TotalNonPastDueBalancesForCreditCards,
    originator_client_1.LeadAttributeNumeric.TotalNonPastDueBalancesForPremiumCreditCards,
    originator_client_1.LeadAttributeNumeric.TotalNonPastDueBalanceForRevolvingTrades,
    originator_client_1.LeadAttributeNumeric.TotalPastDue,
    originator_client_1.LeadAttributeNumeric.TradeCount,
    originator_client_1.LeadAttributeNumeric.VantageScore3,
    originator_client_1.LeadAttributeNumeric.At01S,
    originator_client_1.LeadAttributeNumeric.At09S,
    originator_client_1.LeadAttributeNumeric.At21S,
    originator_client_1.LeadAttributeNumeric.At24S,
    originator_client_1.LeadAttributeNumeric.At34B,
    originator_client_1.LeadAttributeNumeric.At36S,
    originator_client_1.LeadAttributeNumeric.Au01S,
    originator_client_1.LeadAttributeNumeric.Au02S,
    originator_client_1.LeadAttributeNumeric.Au03S,
    originator_client_1.LeadAttributeNumeric.Au12S,
    originator_client_1.LeadAttributeNumeric.Au20S,
    originator_client_1.LeadAttributeNumeric.Au51A,
    originator_client_1.LeadAttributeNumeric.Aut106,
    originator_client_1.LeadAttributeNumeric.Aut107,
    originator_client_1.LeadAttributeNumeric.Aut206,
    originator_client_1.LeadAttributeNumeric.Aut207,
    originator_client_1.LeadAttributeNumeric.Aut306,
    originator_client_1.LeadAttributeNumeric.Aut307,
    originator_client_1.LeadAttributeNumeric.Aut406,
    originator_client_1.LeadAttributeNumeric.Aut407,
    originator_client_1.LeadAttributeNumeric.Aut506,
    originator_client_1.LeadAttributeNumeric.Aut507,
    originator_client_1.LeadAttributeNumeric.Bc101S,
    originator_client_1.LeadAttributeNumeric.Bc110S,
    originator_client_1.LeadAttributeNumeric.Bc28S,
    originator_client_1.LeadAttributeNumeric.Bc33S,
    originator_client_1.LeadAttributeNumeric.Bc34S,
    originator_client_1.LeadAttributeNumeric.Bi33S,
    originator_client_1.LeadAttributeNumeric.Bi34S,
    originator_client_1.LeadAttributeNumeric.Bkc54,
    originator_client_1.LeadAttributeNumeric.Br34S,
    originator_client_1.LeadAttributeNumeric.Br57S,
    originator_client_1.LeadAttributeNumeric.Co01S,
    originator_client_1.LeadAttributeNumeric.Co07S,
    originator_client_1.LeadAttributeNumeric.G043S,
    originator_client_1.LeadAttributeNumeric.G093S,
    originator_client_1.LeadAttributeNumeric.G106S,
    originator_client_1.LeadAttributeNumeric.G237S,
    originator_client_1.LeadAttributeNumeric.G243B,
    originator_client_1.LeadAttributeNumeric.G980S,
    originator_client_1.LeadAttributeNumeric.In06S,
    originator_client_1.LeadAttributeNumeric.Pb101S,
    originator_client_1.LeadAttributeNumeric.Pb57S,
    originator_client_1.LeadAttributeNumeric.Re01S,
    originator_client_1.LeadAttributeNumeric.Re101S,
    originator_client_1.LeadAttributeNumeric.Re31S,
    originator_client_1.LeadAttributeNumeric.Re57S,
    originator_client_1.LeadAttributeNumeric.S061S,
    originator_client_1.LeadAttributeNumeric.S064A,
    originator_client_1.LeadAttributeNumeric.S064B,
    originator_client_1.LeadAttributeNumeric.S204A,
    originator_client_1.LeadAttributeNumeric.S207A,
    originator_client_1.LeadAttributeNumeric.S208S,
    originator_client_1.LeadAttributeNumeric.S209A,
    originator_client_1.LeadAttributeNumeric.S209S,
];
const isComparisonValueRule = ({ key }) => key === api_models_1.LeadFilterRuleType.RejectIfGreaterThan ||
    key === api_models_1.LeadFilterRuleType.RejectIfLessThan ||
    key === api_models_1.LeadFilterRuleType.RejectIfBooleanMatches;
exports.isComparisonValueRule = isComparisonValueRule;
const isSetRule = ({ key }) => key === api_models_1.LeadFilterRuleType.RejectIfInSet ||
    key === api_models_1.LeadFilterRuleType.RejectIfNotInSet;
exports.isSetRule = isSetRule;
const isLeadAttributeRule = (form) => (0, exports.isComparisonValueRule)(form) || (0, exports.isSetRule)(form);
exports.isLeadAttributeRule = isLeadAttributeRule;
const isHighOrderRule = ({ key }) => key === api_models_1.LeadFilterRuleType.ApprovalBiasedRuleCombinator ||
    key === api_models_1.LeadFilterRuleType.RejectionBiasedRuleCombinator;
exports.isHighOrderRule = isHighOrderRule;
const isNumericLeadAttribute = (leadAttribute) => exports.numericLeadAttributes.includes(leadAttribute);
exports.isNumericLeadAttribute = isNumericLeadAttribute;
const isBooleanLeadAttribute = (leadAttribute) => exports.booleanLeadAttributes.includes(leadAttribute);
exports.isBooleanLeadAttribute = isBooleanLeadAttribute;
const isSetLeadAttribute = (leadAttribute) => exports.setLeadAttributes.includes(leadAttribute);
exports.isSetLeadAttribute = isSetLeadAttribute;
const isSelfReportedLeadAttribute = (leadAttribute) => exports.selfReportedLeadAttributes.includes(leadAttribute);
exports.isSelfReportedLeadAttribute = isSelfReportedLeadAttribute;
const isTransUnionLeadAttribute = (leadAttribute) => exports.transUnionLeadAttributes.includes(leadAttribute);
exports.isTransUnionLeadAttribute = isTransUnionLeadAttribute;
