import type { ActionType } from 'typesafe-actions';

import type {
  TempAvailableDemandPartner,
  TempFeaturedDemandPartner,
} from '@portal/api-models';

import type * as actions from './actions';

export type TrustedPartnersAction = ActionType<typeof actions>;
export enum TrustedPartnersActionType {
  DELETE_FEATURED_FAILURE = '@@trusted-partners/DELETE_FEATURED_FAILURE',
  DELETE_FEATURED_REQUEST = '@@trusted-partners/DELETE_FEATURED_REQUEST',
  DELETE_FEATURED_SUCCESS = '@@trusted-partners/DELETE_FEATURED_SUCCESS',

  GET_AVAILABLE_FAILURE = '@@trusted-partners/GET_AVAILABLE_FAILURE',
  GET_AVAILABLE_REQUEST = '@@trusted-partners/GET_AVAILABLE_REQUEST',
  GET_AVAILABLE_SUCCESS = '@@trusted-partners/GET_AVAILABLE_SUCCESS',

  GET_FEATURED_FAILURE = '@@trusted-partners/GET_FEATURED_FAILURE',
  GET_FEATURED_REQUEST = '@@trusted-partners/GET_FEATURED_REQUEST',
  GET_FEATURED_SUCCESS = '@@trusted-partners/GET_FEATURED_SUCCESS',

  SET_FEATURED_FAILURE = '@@trusted-partners/SET_FEATURED_FAILURE',
  SET_FEATURED_REQUEST = '@@trusted-partners/SET_FEATURED_REQUEST',
  SET_FEATURED_SUCCESS = '@@trusted-partners/SET_FEATURED_SUCCESS',
}

export interface TrustedPartnersState {
  availableByCompanyUuid: { [key: string]: TempAvailableDemandPartner[] };
  featuredByCompanyUuid: { [key: string]: TempFeaturedDemandPartner[] };
}
