import { HYDRATE } from 'next-redux-wrapper';
import type { Reducer } from 'redux';

import { isActiveOrNotExpiredSubscription } from '@/resources/saas/helpers';
import { SaasActionType } from '@/store/saas/types';
import type { SaasAction, SaasState } from '@/store/saas/types';

import type { HydrateAction } from '../actions';
import { reconcileState } from '../utils';

export const initialState: SaasState = {
  fees: [],
  hasFeesBeenRequested: false,
  hasPlanBeenPurchased: false,
  hasPlansBeenRequested: false,
  hasSubscriptionBeenRequested: false,
  hasTrialBeenRequested: false,
  loading: false,
  plans: [],
  showGettingStartedModal: false,
  userTrial: undefined,
};

export const reducer: Reducer<SaasState, SaasAction | HydrateAction> = (
  state: SaasState = initialState,
  action: SaasAction | HydrateAction
) => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...reconcileState(action.payload.saas, initialState),
      };
    }

    case SaasActionType.GET_USER_TRIAL_SUCCESS: {
      return {
        ...state,
        hasTrialBeenRequested: true,
        userTrial: action.payload,
      };
    }
    case SaasActionType.GET_SAAS_PLANS_SUCCESS: {
      return {
        ...state,
        hasPlansBeenRequested: true,
        plans: action.payload,
      };
    }
    case SaasActionType.GET_SAAS_FEES_SUCCESS: {
      return {
        ...state,
        fees: action.payload,
        hasFeesBeenRequested: true,
      };
    }
    case SaasActionType.CREATE_SAAS_TRIAL_SUCCESS: {
      return {
        ...state,
        showGettingStartedModal: true,
        userTrial: action.payload,
      };
    }
    case SaasActionType.CREATE_SAAS_FEE_SUCCESS: {
      return {
        ...state,
        fees: [...state.fees, action.payload],
      };
    }
    case SaasActionType.GET_SAAS_SUBSCRIPTION_SUCCESS: {
      const lastPurchasedSubscriptionData = action.payload;
      const isActiveOrNotExpired = isActiveOrNotExpiredSubscription(
        lastPurchasedSubscriptionData?.subscription
      );

      return {
        ...state,
        hasSubscriptionBeenRequested: true,
        lastPurchasedPlan: lastPurchasedSubscriptionData?.plan,
        lastPurchasedSubscription: lastPurchasedSubscriptionData?.subscription,
        plan: isActiveOrNotExpired
          ? lastPurchasedSubscriptionData?.plan
          : undefined,
        subscription: isActiveOrNotExpired
          ? lastPurchasedSubscriptionData?.subscription
          : undefined,
      };
    }
    case SaasActionType.PREVIEW_CREATE_SAAS_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        subscriptionPreview: {
          ...action.payload,
        },
      };
    }
    case SaasActionType.CREATE_SAAS_SUBSCRIPTION_SUCCESS: {
      const subscription = action.payload.subscription;
      const plan = action.payload.plan;

      return {
        ...state,
        hasPlanBeenPurchased: true,
        lastPurchasedPlan: plan,
        lastPurchasedSubscription: subscription,
        plan,
        subscription,
      };
    }
    case SaasActionType.DELETE_SAAS_SUBSCRIPTION_REQUEST: {
      return {
        ...state,
        subscriptionCancelError: undefined,
      };
    }
    case SaasActionType.DELETE_SAAS_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        subscription: action.payload,
        subscriptionCancelError: undefined,
      };
    }
    case SaasActionType.DELETE_SAAS_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        subscriptionCancelError: true,
      };
    }
    case SaasActionType.PURCHASE_PLAN_FAILURE:
    case SaasActionType.PURCHASE_PLAN_SUCCESS:
    case SaasActionType.PURCHASE_INTEGRATION_FEE_FAILURE:
    case SaasActionType.PURCHASE_INTEGRATION_FEE_SUCCESS:
    case SaasActionType.EXECUTE_SUBMIT_FLOW_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SaasActionType.PURCHASE_PLAN_REQUEST:
    case SaasActionType.PURCHASE_INTEGRATION_FEE_REQUEST:
    case SaasActionType.EXECUTE_SUBMIT_FLOW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SaasActionType.CLOSE_GETTING_STARTED_MODAL:
      return {
        ...state,
        showGettingStartedModal: false,
      };
    default: {
      return state;
    }
  }
};
