import { message } from 'antd';
import type { AxiosResponse } from 'axios';
import uniq from 'lodash/uniq';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import type { ActionType } from 'typesafe-actions';

import { LedgerItemType } from '@evenfinancial/finance-client';
import type {
  AccountAdjustment,
  LedgerItemEvent,
  LedgerItemRecreationBatch,
} from '@evenfinancial/finance-client';

import { client } from '@portal/api-client';
import type {
  PortalLedgerItem,
  TempReceivableSummary,
} from '@portal/api-models';
import { getPortalError } from '@portal/common';
import { accountRequestAction } from '@portal/store/dist/account/actions';
import { getHaveAccountsBeenLoaded } from '@portal/store/dist/account/selectors';
import { richAccessTokenByUuidRequestAction } from '@portal/store/dist/user';

import { demandSubAccountRequestAction } from '../subaccount/actions';
import { getHaveAllDemandSubAccountsBeenRequested } from '../subaccount/selectors';

import * as actions from './actions';
import { getReceivableAdjustments, getReceivablesById } from './selectors';

function* initDetailPage(
  action: ActionType<typeof actions.initReceivableDetailPage>
) {
  try {
    const haveAllAccountsBeenRequested = yield select(
      getHaveAccountsBeenLoaded
    );

    const haveAllDemandSubAccountsBeenRequested = yield select(
      getHaveAllDemandSubAccountsBeenRequested
    );

    const receivablesByID = yield select(getReceivablesById);
    const receivableEventsById = yield select(getReceivableEventsById);
    const receivableAdjustments = yield select(getReceivableAdjustments);

    if (!haveAllAccountsBeenRequested) {
      yield put(accountRequestAction.request({}));
    }

    if (!haveAllDemandSubAccountsBeenRequested) {
      yield put(demandSubAccountRequestAction.request({}));
    }

    if (!receivablesByID[action.payload.id]) {
      yield put(
        actions.receivableByIdRequestAction.request({ id: action.payload.id })
      );
    }

    if (!receivableAdjustments.length) {
      yield put(actions.receivableAdjustmentsRequestAction.request({}));
    }

    if (!receivableEventsById[action.payload.id]) {
      yield put(
        actions.receivableEventsByIdRequestAction.request({
          id: action.payload.id,
        })
      );
    }
  } catch (err: any) {
    yield put(actions.receivableRequestAction.failure(err));
  }
}

function* getReceivables() {
  try {
    const { data: receivables }: AxiosResponse<TempReceivableSummary[]> =
      yield call([client, 'get'], '/finance/ledgerItems/receivableSummaries', {
        params: {
          limit: 8000,
        },
      });

    yield put(actions.receivableRequestAction.success(receivables));
  } catch (err: any) {
    yield put(actions.receivableRequestAction.failure(err));
  }
}

function* getReceivableById(
  action: ActionType<typeof actions.receivableByIdRequestAction.request>
) {
  try {
    const { data: receivable }: AxiosResponse<PortalLedgerItem> = yield call(
      [client, 'get'],
      `/finance/ledgerItems/${action.payload.id}`
    );

    yield put(actions.receivableByIdRequestAction.success(receivable));
  } catch (err: any) {
    yield put(actions.receivableByIdRequestAction.failure(err));
  }
}

function* getAdjustments() {
  try {
    const { data: adjustments }: AxiosResponse<AccountAdjustment[]> =
      yield call([client, 'get'], '/finance/accountAdjustments', {
        params: { ledgerItemType: LedgerItemType.Receivable },
      });

    yield put(actions.receivableAdjustmentsRequestAction.success(adjustments));

    // TODO: move this to a selector
    const accessTokenUuids = uniq(adjustments.map((a) => a.createdBy));
    const { data: tokens } = yield call(
      [client, 'post'],
      '/auth/rich_access_tokens',
      {
        accessTokenUuids,
      }
    );

    yield put(richAccessTokenByUuidRequestAction.success(tokens));
  } catch (err: any) {
    yield put(actions.receivableAdjustmentsRequestAction.failure(err));
  }
}

function* deleteAdjustment(
  action: ActionType<typeof actions.receivableAdjustmentDeleteAction.request>
) {
  try {
    yield call(
      [client, 'delete'],
      `/finance/accountAdjustments/${action.payload.id}`
    );

    yield put(actions.receivableAdjustmentDeleteAction.success(action.payload));
    yield delay(100);
    yield call(
      [message, 'success'],
      'Receivable adjustment successfully cancelled.'
    );
  } catch (err: any) {
    const portalError = getPortalError(err);

    yield put(actions.receivableAdjustmentDeleteAction.failure(portalError));
  }
}

function* regenerateLedgerItems({
  payload: { ledgerItemIds, copyAdjustments },
}: ActionType<typeof actions.regenerateLedgerItemsRequestAction.request>) {
  try {
    const {
      data: ledgerItemsRegenerateData,
    }: AxiosResponse<LedgerItemRecreationBatch> = yield call(
      [client, 'post'],
      '/finance/ledgerItems/regenerate',
      {
        copyAdjustments,
        ledgerItemIds,
      }
    );

    yield put(
      actions.regenerateLedgerItemsRequestAction.success(
        ledgerItemsRegenerateData
      )
    );
    yield delay(100);
    yield call([message, 'success'], 'Receivables regenerated successfully');
  } catch (e: any) {
    yield put(actions.regenerateLedgerItemsRequestAction.failure(e));
  }
}

function* addAdjustments({
  payload: { accountId, ledgerItemId, adjustments },
}: ActionType<typeof actions.receivableAddAdjustmentAction.request>) {
  try {
    const enrichedAdustments = adjustments?.map((adjustment) => ({
      ...adjustment,
      accountId,
      ledgerItemId,
    }));

    const { data: accountAdjustmentsData }: AxiosResponse<AccountAdjustment[]> =
      yield call(
        [client, 'post'],
        '/finance/accountAdjustments',
        enrichedAdustments
      );

    yield put(
      actions.receivableAddAdjustmentAction.success(accountAdjustmentsData)
    );
    yield delay(100);
    yield call([message, 'success'], 'Successfully added account adjustments.');
  } catch (e: any) {
    yield put(actions.receivableAddAdjustmentAction.failure(e));
  }
}

function* getReceivableEventsById(
  action: ActionType<typeof actions.receivableEventsByIdRequestAction.request>
) {
  try {
    const { data: events }: AxiosResponse<LedgerItemEvent[]> = yield call(
      [client, 'get'],
      `/finance/ledgerItems/${action.payload.id}/events`
    );

    yield put(actions.receivableEventsByIdRequestAction.success(events));
  } catch (err: any) {
    yield put(actions.receivableEventsByIdRequestAction.failure(err));
  }
}

export function* receivableSaga() {
  yield takeLatest(
    getType(actions.receivableEventsByIdRequestAction.request),
    getReceivableEventsById
  );

  yield takeLatest(
    getType(actions.receivableRequestAction.request),
    getReceivables
  );

  yield takeLatest(
    getType(actions.receivableByIdRequestAction.request),
    getReceivableById
  );

  yield takeLatest(
    getType(actions.receivableAdjustmentsRequestAction.request),
    getAdjustments
  );

  yield takeLatest(
    getType(actions.receivableAddAdjustmentAction.request),
    addAdjustments
  );

  yield takeLatest(
    getType(actions.receivableAdjustmentDeleteAction.request),
    deleteAdjustment
  );
  yield takeLatest(
    getType(actions.regenerateLedgerItemsRequestAction.request),
    regenerateLedgerItems
  );
  yield takeLatest(getType(actions.initReceivableDetailPage), initDetailPage);
}
