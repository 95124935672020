import { action, createAction, createAsyncAction } from 'typesafe-actions';

import type {
  RecurlyToken,
  SaasFee,
  SaasFeeCreateData,
  SaasPlan,
  SaasSubscriptionCreateData,
  SaasSubscriptionPreview,
  SaasTrial,
} from '@evenfinancial/finance-client';

import type { SaasSubscriptionFull } from '@portal/api-models';
import type { PortalError } from '@portal/common';
import type { CustomOfferContactUsFormState } from '@portal/ui-lib';

import type { UpgradeScaleForm } from '@/resources/self-service/account/upgrade-scale-form-fields';
import type { PlanSummaryForm } from '@/resources/self-service/payment/integration-fee/plan-summary';
import type { PurchasePlanForm } from '@/resources/self-service/payment/purchase-plan/purchase-plan-form';
import { SaasActionType } from '@/store/saas/types';

import type { Callbacks, IsomorphicRequestPayload } from '../types';

export const saasUserTrialSave = (saasTrial: SaasTrial) =>
  action(SaasActionType.GET_USER_TRIAL_SUCCESS, saasTrial);

export const initPurchasePlanPage = createAsyncAction(
  SaasActionType.INIT_PURCHASE_PLAN_PAGE_REQUEST,
  SaasActionType.INIT_PURCHASE_PLAN_PAGE_FAILURE,
  SaasActionType.INIT_PURCHASE_PLAN_PAGE_SUCCESS
)<IsomorphicRequestPayload, undefined, Error>();

export const initIntegrationFeePage = createAsyncAction(
  SaasActionType.INIT_INTEGRATION_FEE_PAGE_REQUEST,
  SaasActionType.INIT_INTEGRATION_FEE_PAGE_FAILURE,
  SaasActionType.INIT_INTEGRATION_FEE_PAGE_SUCCESS
)<IsomorphicRequestPayload, undefined, Error>();

export const saasSubscriptionSave = createAction(
  SaasActionType.GET_SAAS_SUBSCRIPTION_SUCCESS
)<SaasSubscriptionFull>();

export const closeGettingStartedModal = () =>
  action(SaasActionType.CLOSE_GETTING_STARTED_MODAL);

export const saasUserTrialRequestAction = createAsyncAction(
  SaasActionType.GET_USER_TRIAL_REQUEST,
  SaasActionType.GET_USER_TRIAL_SUCCESS,
  SaasActionType.GET_USER_TRIAL_FAILURE
)<IsomorphicRequestPayload, SaasTrial, Error>();

export const saasCustomOfferContactUsRequestAction = createAsyncAction(
  SaasActionType.CUSTOM_OFFER_CONTACT_US_REQUEST,
  SaasActionType.CUSTOM_OFFER_CONTACT_US_SUCCESS,
  SaasActionType.CUSTOM_OFFER_CONTACT_US_FAILURE
)<IsomorphicRequestPayload & CustomOfferContactUsFormState, undefined, Error>();

export const saasCustomLeadAttributesContactUsRequestAction = createAsyncAction(
  SaasActionType.CUSTOM_LEAD_ATTRIBUTES_CONTACT_US_REQUEST,
  SaasActionType.CUSTOM_LEAD_ATTRIBUTES_CONTACT_US_SUCCESS,
  SaasActionType.CUSTOM_LEAD_ATTRIBUTES_CONTACT_US_FAILURE
)<IsomorphicRequestPayload & { subAccountUuid: string }, undefined, Error>();

export const saasScaleUpgradeContactUsRequestAction = createAsyncAction(
  SaasActionType.SCALE_UPGRADE_CONTACT_US_REQUEST,
  SaasActionType.SCALE_UPGRADE_CONTACT_US_SUCCESS,
  SaasActionType.SCALE_UPGRADE_CONTACT_US_FAILURE
)<IsomorphicRequestPayload & UpgradeScaleForm & Callbacks, undefined, Error>();

export const createSaasTrialRequestAction = createAsyncAction(
  SaasActionType.CREATE_SAAS_TRIAL_REQUEST,
  SaasActionType.CREATE_SAAS_TRIAL_SUCCESS,
  SaasActionType.CREATE_SAAS_TRIAL_FAILURE
)<IsomorphicRequestPayload, SaasTrial, Error>();

export const saasPlansRequestAction = createAsyncAction(
  SaasActionType.GET_SAAS_PLANS_REQUEST,
  SaasActionType.GET_SAAS_PLANS_SUCCESS,
  SaasActionType.GET_SAAS_PLANS_FAILURE
)<IsomorphicRequestPayload, SaasPlan[], Error>();

export const saasFeesRequestAction = createAsyncAction(
  SaasActionType.GET_SAAS_FEES_REQUEST,
  SaasActionType.GET_SAAS_FEES_SUCCESS,
  SaasActionType.GET_SAAS_FEES_FAILURE
)<IsomorphicRequestPayload, SaasFee[], Error>();

export interface CreateSaasFeeRequestPayload
  extends Omit<SaasFeeCreateData, 'accountId'>,
    IsomorphicRequestPayload {}

export const createSaasFeesRequestAction = createAsyncAction(
  SaasActionType.CREATE_SAAS_FEE_REQUEST,
  SaasActionType.CREATE_SAAS_FEE_SUCCESS,
  SaasActionType.CREATE_SAAS_FEE_FAILURE
)<CreateSaasFeeRequestPayload, SaasFee, Error>();

export const saasSubscriptionRequestAction = createAsyncAction(
  SaasActionType.GET_SAAS_SUBSCRIPTION_REQUEST,
  SaasActionType.GET_SAAS_SUBSCRIPTION_SUCCESS,
  SaasActionType.GET_SAAS_SUBSCRIPTION_FAILURE
)<IsomorphicRequestPayload, SaasSubscriptionFull, Error>();

export const executeSubmitFlowAction = createAsyncAction(
  SaasActionType.EXECUTE_SUBMIT_FLOW_REQUEST,
  SaasActionType.EXECUTE_SUBMIT_FLOW_SUCCESS,
  SaasActionType.EXECUTE_SUBMIT_FLOW_FAILURE
)<{ integrationId: string } & IsomorphicRequestPayload, void, Error>();

export interface CreateSaasSubscriptionRequestPayload
  extends Omit<SaasSubscriptionCreateData, 'accountId'>,
    IsomorphicRequestPayload {}

export const createSaasSubscriptionRequestAction = createAsyncAction(
  SaasActionType.CREATE_SAAS_SUBSCRIPTION_REQUEST,
  SaasActionType.CREATE_SAAS_SUBSCRIPTION_SUCCESS,
  SaasActionType.CREATE_SAAS_SUBSCRIPTION_FAILURE
)<CreateSaasSubscriptionRequestPayload, SaasSubscriptionFull, PortalError>();

export interface PurchaseFormChangedPayload extends IsomorphicRequestPayload {
  data: PurchasePlanForm;
  findMigrationPlan: boolean;
  waiveImplementationFee: boolean;
}

export const onPurchaseFormChangedAction = createAsyncAction(
  SaasActionType.ON_PURCHASE_PLAN_FORM_CHANGED_REQUEST,
  SaasActionType.ON_PURCHASE_PLAN_FORM_CHANGED_SUCCESS,
  SaasActionType.ON_PURCHASE_PLAN_FORM_CHANGED_FAILURE
)<PurchaseFormChangedPayload, void, PortalError>();

export interface SaasSubscriptionPreviewData {
  request: CreateSaasSubscriptionRequestPayload;
  response: SaasSubscriptionPreview;
}

export const previewCreateSaasSubscriptionRequestAction = createAsyncAction(
  SaasActionType.PREVIEW_CREATE_SAAS_SUBSCRIPTION_REQUEST,
  SaasActionType.PREVIEW_CREATE_SAAS_SUBSCRIPTION_SUCCESS,
  SaasActionType.PREVIEW_CREATE_SAAS_SUBSCRIPTION_FAILURE
)<
  CreateSaasSubscriptionRequestPayload,
  SaasSubscriptionPreviewData,
  PortalError
>();

export const deleteSaasSubscriptionRequestAction = createAsyncAction(
  SaasActionType.DELETE_SAAS_SUBSCRIPTION_REQUEST,
  SaasActionType.DELETE_SAAS_SUBSCRIPTION_SUCCESS,
  SaasActionType.DELETE_SAAS_SUBSCRIPTION_FAILURE
)<{ id: number; reason: string } & IsomorphicRequestPayload, any, Error>();

export interface SaasAllRequestPayload extends IsomorphicRequestPayload {
  skipFees?: boolean;
  skipTrial?: boolean;
}

export const saasAllRequestAction = createAsyncAction(
  SaasActionType.GET_SAAS_ALL_REQUEST,
  SaasActionType.GET_SAAS_ALL_SUCCESS,
  SaasActionType.GET_SAAS_ALL_FAILURE
)<SaasAllRequestPayload, void, Error>();

export interface CreateRecurlyToken {
  paymentEmail: string;
  tokenId: string;
}

export interface PurchasePlanRequestPayload
  extends Partial<CreateRecurlyToken>,
    IsomorphicRequestPayload {
  implementationFeePayment?: CreateSaasFeeRequestPayload;
  integrationId?: string;
  isSubmitFlow?: boolean;
  subscriptionPayment: CreateSaasSubscriptionRequestPayload;
}

export const purchasePlanRequestAction = createAsyncAction(
  SaasActionType.PURCHASE_PLAN_REQUEST,
  SaasActionType.PURCHASE_PLAN_SUCCESS,
  SaasActionType.PURCHASE_PLAN_FAILURE
)<PurchasePlanRequestPayload, void, Error>();

export interface PurchaseIntegrationFeePayload
  extends Partial<CreateRecurlyToken>,
    IsomorphicRequestPayload {
  isSubmitFlow?: boolean;
  planSummary: PlanSummaryForm;
  saasPlanId?: number;
}

export const purchaseIntegrationFeeRequestAction = createAsyncAction(
  SaasActionType.PURCHASE_INTEGRATION_FEE_REQUEST,
  SaasActionType.PURCHASE_INTEGRATION_FEE_SUCCESS,
  SaasActionType.PURCHASE_INTEGRATION_FEE_FAILURE
)<PurchaseIntegrationFeePayload, void, Error>();

export interface CreateRecurlyTokenRequestPayload
  extends CreateRecurlyToken,
    IsomorphicRequestPayload {}

export const createRecurlyTokenRequestAction = createAsyncAction(
  SaasActionType.CREATE_RECURLY_TOKEN_REQUEST,
  SaasActionType.CREATE_RECURLY_TOKEN_SUCCESS,
  SaasActionType.CREATE_RECURLY_TOKEN_FAILURE
)<CreateRecurlyTokenRequestPayload, RecurlyToken, Error>();
