import { createAsyncAction } from 'typesafe-actions';

import type { PortalPreSelectRule } from '@portal/api-models';

import type { IsomorphicRequestPayload } from '../types';

import { PreSelectRuleActionType } from './types';

export const preSelectRuleRequestAction = createAsyncAction(
  PreSelectRuleActionType.GET_ALL_REQUEST,
  PreSelectRuleActionType.GET_ALL_SUCCESS,
  PreSelectRuleActionType.GET_ALL_FAILURE
)<IsomorphicRequestPayload, PortalPreSelectRule[], Error>();
