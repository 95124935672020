import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import type { ActionType } from 'typesafe-actions';

import { SubAccountType } from '@evenfinancial/finance-client';

import { client } from '@portal/api-client';
import type { PortalSubAccount } from '@portal/api-models';
import { getOwners } from '@portal/store/dist/account/sagas';
import { selectPermissions } from '@portal/store/dist/user/selectors';

import * as actions from './actions';
import { DemandConfigurationsActionType } from './types';

function* getDemandConfigurationsForProductType(
  action: ActionType<
    typeof actions.getDemandConfigurationsByProductType.request
  >
) {
  const { shouldGetSubAccountOwners } = yield select(selectPermissions);
  const { productType } = action.payload;

  try {
    const response: PortalSubAccount[] = yield call(async () => {
      return client
        .get<PortalSubAccount[]>('/finance/subaccounts', {
          params: {
            productType,
            subAccountTypeKey: SubAccountType.DemandSource,
          },
        })
        .then((res) => res.data);
    });

    if (shouldGetSubAccountOwners) {
      yield* getOwners<PortalSubAccount>(
        response,
        'subAccountOwners',
        action.payload.cookie
      );
    }

    yield put(
      actions.getDemandConfigurationsByProductType.success({
        productType,
        response,
      })
    );
  } catch (err: any) {
    yield put(actions.getDemandConfigurationsByProductType.failure(err));
  }
}

function* watchGetDemandConfigurationsByProductType() {
  yield takeLatest(
    DemandConfigurationsActionType.GET_BY_PRODUCT_TYPE_REQUEST,
    getDemandConfigurationsForProductType
  );
}

export function* demandConfigurationsSaga() {
  yield all([fork(watchGetDemandConfigurationsByProductType)]);
}
