import type { InitConfiguration } from '@datadog/browser-core';

import type { ConfigState } from '@portal/store/dist/configuration/types';

export enum DDEnv {
  Dev = 'dev',
  Prod = 'prod',
  Stage = 'stage',
}

export const getDDEnv = (config: ConfigState): string | undefined => {
  if (config.IS_PRODUCTION_ENV) {
    return DDEnv.Prod;
  }

  if (config.PORTAL_API_URL?.includes('dev-api.evenfinancial')) {
    return DDEnv.Dev;
  }

  if (config.PORTAL_API_URL?.includes('api.evenfinancial')) {
    return DDEnv.Stage;
  }
};

export const getDDBaseConfig = (
  config: ConfigState
): Omit<InitConfiguration, 'beforeSend'> => {
  const env = getDDEnv(config);
  const clientToken = config.DATADOG_CLIENT_TOKEN;

  const allowedTracingOrigins = [
    'api',
    'dev-api',
    'stage-api',
    'controls',
    'controls-dev',
    'controls-stage',
  ].map((subdomain) => `https://${subdomain}.evenfinancial.com`);

  return {
    allowedTracingOrigins,
    clientToken,
    env,
    sampleRate: 100,
    service: 'portal-app',
    site: 'datadoghq.com',
    version: config.DD_VERSION,
  };
};
