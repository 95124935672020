"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.payableReducer = exports.initialPayableState = void 0;
var immer_1 = require("immer");
var typesafe_actions_1 = require("typesafe-actions");
var actions_1 = require("./actions");
exports.initialPayableState = {
    addAdjustmentsDrawerOpen: false,
    addAdjustmentsForm: {},
    adjustments: [],
    adjustmentsByLedgerItemId: {},
    byId: {},
    createOneOffDrawerOpen: false,
    eventsById: {},
    haveAllAdjustmentsBeenRequested: false,
    haveAllPayablesBeenRequested: false,
    rows: [],
};
exports.payableReducer = (0, typesafe_actions_1.createReducer)(exports.initialPayableState)
    .handleAction(actions_1.payableRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.haveAllPayablesBeenRequested = true;
        draftState.rows = payload;
    });
})
    .handleAction(actions_1.payableByIdRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.byId[payload.id] = payload;
    });
})
    .handleAction(actions_1.payableIgnoreAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        var updatedIndex = draftState.rows.findIndex(function (p) { return p.id === payload[0].id; });
        var updatedPayable = __assign(__assign({}, draftState.rows[updatedIndex]), { status: payload[0].status });
        var updatedPayables = __spreadArray([], draftState.rows, true);
        updatedPayables[updatedIndex] = updatedPayable;
        draftState.rows = updatedPayables;
    });
})
    .handleAction(actions_1.payableAdjustmentsRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.haveAllAdjustmentsBeenRequested = true;
        draftState.adjustments = payload;
    });
})
    .handleAction(actions_1.payableAdjustmentsByLedgerItemIdRequestAction.success, function (state, _a) {
    var _b = _a.payload, ledgerItemId = _b.ledgerItemId, adjustments = _b.adjustments;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.adjustmentsByLedgerItemId[ledgerItemId] = adjustments;
    });
})
    .handleAction(actions_1.payableAddAdjustmentAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.addAdjustmentsDrawerOpen =
            !draftState.addAdjustmentsDrawerOpen;
        draftState.adjustments = __spreadArray(__spreadArray([], draftState.adjustments, true), payload, true);
        draftState.haveAllAdjustmentsBeenRequested = false;
        draftState.addAdjustmentsForm = {};
    });
})
    .handleAction(actions_1.toggleCreateOneOffLedgerItem, function (state) {
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.createOneOffDrawerOpen = !draftState.createOneOffDrawerOpen;
    });
})
    .handleAction(actions_1.toggleAddAdjustmentsForm, function (state, _a) {
    var _b = _a.payload, ledgerItemId = _b.ledgerItemId, accountId = _b.accountId;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.addAdjustmentsDrawerOpen =
            !draftState.addAdjustmentsDrawerOpen;
        draftState.addAdjustmentsForm = {
            accountId: accountId,
            ledgerItemId: ledgerItemId,
        };
    });
})
    .handleAction(actions_1.updateAddAdjustmentsForm, function (state, _a) {
    var _b = _a.payload, ledgerItemId = _b.ledgerItemId, accountId = _b.accountId, adjustments = _b.adjustments;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.addAdjustmentsForm = {
            accountId: accountId,
            adjustments: adjustments,
            ledgerItemId: ledgerItemId,
        };
    });
})
    .handleAction(actions_1.payableAdjustmentDeleteAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        var updatedAdjustments = __spreadArray([], draftState.adjustments, true);
        var updatedPayables = __spreadArray([], draftState.rows, true);
        var found = updatedAdjustments.findIndex(function (bc) { return bc.id === payload.id; });
        if (found < 0) {
            return draftState;
        }
        var payablesIndex = updatedPayables.findIndex(function (bc) { return bc.id === updatedAdjustments[found].ledgerItemId; });
        updatedPayables[payablesIndex] = __assign(__assign({}, draftState.rows[payablesIndex]), { adjustment: Number(updatedPayables === null || updatedPayables === void 0 ? void 0 : updatedPayables[payablesIndex].adjustment) -
                updatedAdjustments[found].amount, totalPayout: Number(updatedPayables === null || updatedPayables === void 0 ? void 0 : updatedPayables[payablesIndex].totalPayout) -
                updatedAdjustments[found].amount });
        updatedAdjustments.splice(found, 1);
        draftState.adjustments = updatedAdjustments;
        draftState.rows = updatedPayables;
    });
})
    .handleAction(actions_1.deletePayableAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.rows = draftState.rows.filter(function (row) { return row.id !== payload.id; });
    });
})
    .handleAction(actions_1.createOneOffLedgerItemRequestAction.success, function (state) {
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.createOneOffDrawerOpen = !draftState.createOneOffDrawerOpen;
        draftState.haveAllPayablesBeenRequested = false;
    });
})
    .handleAction(actions_1.payableEventsByIdRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.eventsById[payload[0].ledgerItemId] = payload;
    });
});
