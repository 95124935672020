import omit from 'lodash/omit';
import { createReducer } from 'typesafe-actions';
import type { ActionType } from 'typesafe-actions';

import { nextHydrate } from '@/store/actions';
import type { HydrateAction } from '@/store/actions';
import { reconcileState } from '@/store/utils';

import * as Actions from './actions';

type InterfaceAction = ActionType<typeof Actions>;

export interface InterfaceState {
  fullscreenMode: boolean;
  sidenavCollapsed: boolean;
}

export const initialState: InterfaceState = {
  fullscreenMode: false,
  sidenavCollapsed: false,
};

export const interfaceReducer = createReducer<
  InterfaceState,
  InterfaceAction | HydrateAction
>(initialState)
  .handleAction(Actions.toggleSidenav, (state) => {
    return { ...state, sidenavCollapsed: !state.sidenavCollapsed };
  })
  .handleAction(Actions.enterFullscreenMode, (state) => {
    return { ...state, fullscreenMode: true };
  })
  .handleAction(Actions.exitFullscreenMode, (state) => {
    return { ...state, fullscreenMode: false };
  })
  .handleAction(nextHydrate, (state, action) => {
    const payload = omit(action.payload.interface, ['fullscreenMode']);

    return {
      ...state,
      ...reconcileState(payload, initialState),
    };
  });
