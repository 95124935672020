import { produce } from 'immer';
import { createReducer } from 'typesafe-actions';
import type { ActionType } from 'typesafe-actions';

import { authenticationCallbackDoneAction } from './actions';
import type * as authenticationActions from './actions';
import type { AuthenticationState } from './types';

export const initialState: AuthenticationState = {
  isAuthenticationCallbackDone: false,
};

export const authenticationReducer = createReducer<
  AuthenticationState,
  ActionType<typeof authenticationActions>
>(initialState).handleAction(authenticationCallbackDoneAction, (state) =>
  produce(state, (draftState) => {
    draftState.isAuthenticationCallbackDone = true;
  })
);
