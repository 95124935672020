import type { PartnerPageAlooma, PartnerPageFull } from '@portal/api-models';

import { getIntegrationStatus } from '@/resources/integrations/helpers/get-integration-status';
import type { Integration } from '@/resources/integrations/types';

interface Options {
  fullPartnerPage: PartnerPageFull;
  migratedIntegrationKeys?: string[];
  partnerPage: PartnerPageAlooma;
}

/**
 * Convert a PartnerPageAlooma to an Integration
 *
 * TODO: Right now, this only returning a partial.
 * Eventually, should move all integration logic here to create a single point in
 * which integration data is processed.
 */
export const partnerPageToIntegration = (
  options: Options
): Pick<Integration, 'companyUuid' | 'integrationStatus' | 'isInMigration'> => {
  const {
    partnerPage: { companyUuid, partner_key: partnerKey },
    fullPartnerPage,
    migratedIntegrationKeys,
  } = options;

  const isInMigration = Boolean(migratedIntegrationKeys?.includes(partnerKey));

  const integrationStatus = getIntegrationStatus(
    fullPartnerPage.integrationStatus,
    isInMigration
  );

  return { companyUuid, integrationStatus, isInMigration };
};
