import { AccountTierType } from '@evenfinancial/finance-client';
import type {
  SaasMigrationSetting,
  SaasPlan,
} from '@evenfinancial/finance-client';

import { IntegrationCategory } from '@/resources/sub-accounts-shared/utils';

/**
 * Compares an IntegrationCategory to a SaasPlan to assess if the
 * IntegrationCategory is waived by the SaasPlan.
 */
export const isFeeWaivedForIntegrationCategory = (
  integrationCategory: IntegrationCategory,
  saasPlan?: SaasPlan,
  supplyMigration?: SaasMigrationSetting
) => {
  if (!saasPlan && !supplyMigration) {
    return false;
  }

  const isPartnerPageFeeWaived =
    integrationCategory === IntegrationCategory.PartnerPage &&
    (saasPlan?.partnerPageImplementationFee === 0 ||
      (!saasPlan && supplyMigration?.partnerPageImplementationFee === 0));

  const isEmbedFeeWaived =
    integrationCategory === IntegrationCategory.Embed &&
    (saasPlan?.embedImplementationFee === 0 ||
      (!saasPlan && supplyMigration?.embedImplementationFee === 0));

  const isNativeFeeWaived =
    integrationCategory === IntegrationCategory.API &&
    (saasPlan?.nativeImplementationFee === 0 ||
      (!saasPlan && supplyMigration?.nativeImplementationFee === 0));

  if (
    saasPlan?.tier === AccountTierType.Starter ||
    supplyMigration?.tier === AccountTierType.Starter
  ) {
    return isPartnerPageFeeWaived;
  }

  return isPartnerPageFeeWaived || isEmbedFeeWaived || isNativeFeeWaived;
};
