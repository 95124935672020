"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationStatus = exports.OnboardingStatus = exports.PartnerPageDefinitionFieldDataType = void 0;
var PartnerPageDefinitionFieldDataType;
(function (PartnerPageDefinitionFieldDataType) {
    PartnerPageDefinitionFieldDataType["boolean"] = "boolean";
    PartnerPageDefinitionFieldDataType["color"] = "color";
    PartnerPageDefinitionFieldDataType["number"] = "number";
    PartnerPageDefinitionFieldDataType["string"] = "string";
})(PartnerPageDefinitionFieldDataType = exports.PartnerPageDefinitionFieldDataType || (exports.PartnerPageDefinitionFieldDataType = {}));
var OnboardingStatus;
(function (OnboardingStatus) {
    OnboardingStatus["Draft"] = "draft";
    OnboardingStatus["IntegrationFeeUnpaid"] = "integration_fee_unpaid";
    OnboardingStatus["Live"] = "live";
    OnboardingStatus["Ready"] = "ready";
    OnboardingStatus["SubmittedForReview"] = "submitted_for_review";
    OnboardingStatus["SubmittedForReviewPaid"] = "submitted_for_review_paid";
})(OnboardingStatus = exports.OnboardingStatus || (exports.OnboardingStatus = {}));
var IntegrationStatus;
(function (IntegrationStatus) {
    IntegrationStatus["Draft"] = "draft";
    IntegrationStatus["Live"] = "live";
})(IntegrationStatus = exports.IntegrationStatus || (exports.IntegrationStatus = {}));
