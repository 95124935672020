import * as React from 'react';

import { faCircleDollar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import numeral from 'numeral';

import { ApplicationsIcon, LeadsIcon } from '@portal/ui-lib';

import type { TopTotalsPanelItem } from '@/components/reporting/top-totals-panel';

export const lifeInsuranceFunnelPanelConfig: TopTotalsPanelItem[] = [
  {
    icon: <LeadsIcon />,
    key: 'numLeads',
    title: 'Leads',
    tooltip: 'Count of leads once a consumer begins interacting with the form',
  },
  {
    icon: <ApplicationsIcon />,
    key: 'numLeadsSubmittedQuoteApp',
    title: 'Submitted Quote Application',
    tooltip: 'Count of leads who completed basic health questions',
  },
  {
    icon: <ApplicationsIcon />,
    key: 'numLeadsSubmittedLeadApp',
    title: 'Submitted Lead Application',
    tooltip:
      'Count of leads who selected a specific quote and consented to submitting an application',
  },
  {
    icon: <ApplicationsIcon />,
    key: 'numLeadsSubmittedAppToCarrier',
    title: 'Submitted Application to Carrier',
    tooltip: 'Count of leads who submitted an application to a carrier',
  },
  {
    icon: <ApplicationsIcon />,
    key: 'numLeadsWithPolicy',
    title: 'Policy In force',
    tooltip: 'Count of leads who have acquired a life insurance policy',
  },
  {
    formatter: (value) => numeral(value).format('$0,0.00'),
    icon: <FontAwesomeIcon icon={faCircleDollar} />,
    key: 'totalPayout',
    title: 'Attributed Earnings',
    tooltip:
      'Estimated value of payout based on the leads attributed to the dates in the report. Please note that Attributed Earnings will vary from your Total Earnings value because life insurance applications often take several days to finalize. You can view your actual payout at any time in the Earnings Report under “Total Earnings”',
  },
];
