"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.redact = void 0;
const redactedStr = '[REDACTED]';
const redactKeys = ['password', 'secret'];
function redact(data) {
    if (!data) {
        return data;
    }
    const isObject = typeof data === 'object';
    const redactedDataStr = redactKeys.reduce((accum, key) => {
        const matcher = new RegExp(`(?<key>"[^"]{0,}(${key}|${key}\\\\)")(:|:\\\\)(?<value>"[^"]+")`, 'gim');
        const matches = accum.match(matcher);
        return ((matches === null || matches === void 0 ? void 0 : matches.reduce((_accum, match) => {
            if (match[match.length - 2] === '\\') {
                return _accum.replace(match, `${match.split(':')[0]}:\\"${redactedStr}\\"`);
            }
            return _accum.replace(match, `${match.split(':')[0]}:"${redactedStr}"`);
        }, accum)) || accum);
    }, isObject ? JSON.stringify(data) : data);
    return isObject ? JSON.parse(redactedDataStr) : redactedDataStr;
}
exports.redact = redact;
