import { message } from 'antd';
import type { AxiosResponse } from 'axios';
import Router from 'next/router';
import { call, delay, put, putResolve, takeLatest } from 'redux-saga/effects';
import { action } from 'typesafe-actions';
import type { ActionType } from 'typesafe-actions';

import { client, getProfileMeta } from '@portal/api-client';
import type {
  PortalSaasMigrationSetting,
  ProfileMeta,
} from '@portal/api-models';
import * as userActions from '@portal/store/dist/user';

import { NewFeatureStatic } from '@/components/new-feature/helpers';
import { QueryParams } from '@/components/self-service/home/index-regular';
import {
  NewMigrationFeature,
  toSaasMigrationCreateData,
} from '@/resources/supply-migration/helpers';
import { RESET_STORE } from '@/store/actions';
import * as saasActions from '@/store/saas/actions';
import { SupplyMigrationActionType } from '@/store/supply-migration/types';

import * as actions from './actions';

function* getSaasMigrationSettingsByFinanceAccountId({
  payload,
}: ActionType<
  typeof actions.getSettingsByFinanceAccountIdRequestAction.request
>) {
  try {
    const { data: settings }: AxiosResponse<PortalSaasMigrationSetting> =
      yield call(client.get, `/supply_migration/${payload.financeAccountId}`);

    yield put(
      actions.getSettingsByFinanceAccountIdRequestAction.success({
        financeAccountId: payload.financeAccountId,
        settings,
      })
    );
  } catch (err: any) {
    yield put(actions.getSettingsByFinanceAccountIdRequestAction.failure(err));
  }
}

export function* getSaasMigrationSettingsByAuthContext() {
  try {
    const { data: settings }: AxiosResponse<PortalSaasMigrationSetting> =
      yield call(client.get, '/supply_migration/');

    yield put(actions.getSettingsByAuthContextRequestAction.success(settings));
  } catch (err: any) {
    yield put(actions.getSettingsByAuthContextRequestAction.failure(err));
  }
}

function* updateSaasMigrationSettings({
  payload,
}: ActionType<typeof actions.updateMigrationRequestAction.request>) {
  try {
    const { data: settings }: AxiosResponse<PortalSaasMigrationSetting> =
      yield call(
        client.post,
        '/supply_migration/',
        toSaasMigrationCreateData(payload.financeAccountId, payload.form)
      );

    yield putResolve(
      actions.updateMigrationRequestAction.success({
        financeAccountId: payload.financeAccountId,
        settings,
      })
    );
    yield delay(50);
    yield call([message, 'success'], 'Successfully saved migration settings!');
  } catch (err: any) {
    yield put(actions.updateMigrationRequestAction.failure(err));
  }
}

function* migrate({
  payload,
}: ActionType<typeof actions.startMigration.request>) {
  try {
    const { data: migration } = yield call(
      client.post,
      `/supply_migration/${payload.financeAccountId}/start`
    );

    yield call(
      NewFeatureStatic.add,
      [
        NewMigrationFeature.HomepageTutorial,
        NewMigrationFeature.UsersTutorial,
        NewMigrationFeature.IntegrationsTutorial,
        NewMigrationFeature.PerformanceSummaryTutorial,
      ],
      {
        viewsUntilExpires: 1,
      }
    );

    yield call([client, 'get'], '/auth/refresh');

    const profileMeta: ProfileMeta = yield call(getProfileMeta);

    yield put(action(RESET_STORE));
    yield put(saasActions.saasUserTrialRequestAction.success(migration));
    yield put(userActions.setProfileMeta(profileMeta));

    yield call(
      Router.replace,
      `/home?${QueryParams.UpgradedMigrationSuccess}=true`
    );

    yield put(actions.startMigration.success(null));
  } catch (err: any) {
    yield put(actions.startMigration.failure(err));
  }
}

export function* supplyMigrationSaga() {
  try {
    yield takeLatest(
      SupplyMigrationActionType.GET_BY_FINANCE_ACCOUNT_ID_REQUEST,
      getSaasMigrationSettingsByFinanceAccountId
    );
    yield takeLatest(
      SupplyMigrationActionType.GET_BY_AUTH_CONTEXT_REQUEST,
      getSaasMigrationSettingsByAuthContext
    );
    yield takeLatest(
      SupplyMigrationActionType.UPDATE_REQUEST,
      updateSaasMigrationSettings
    );
    yield takeLatest(SupplyMigrationActionType.START_REQUEST, migrate);
  } catch (err: any) {
    throw new Error('Unable to yield all supply migration sagas');
  }
}
