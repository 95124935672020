"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.crmConfig = void 0;
exports.crmConfig = {
    canRequestPerformanceSummary: true,
    hasCrmUserRole: true,
    shouldEnablePerformanceSummary: true,
    shouldHideTrialExpirationBar: true,
    showCrmUserSideNav: true,
};
