import { HYDRATE } from 'next-redux-wrapper';
import type { Reducer } from 'redux';

import type { HydrateAction } from '@/store/actions';
import { reconcileState } from '@/store/utils';

import {
  ChannelsActionType,
  ComplianceActionType,
  CreditCardsActionType,
  PersonalLoansActionType,
  SavingsActionType,
} from './types';
import type { ComplianceAction, ComplianceState } from './types';

export const initialState: ComplianceState = {
  channels: [],
  creditCards: [],
  personalLoans: [],
  rows: [],
  savings: [],
};

export const reducer: Reducer<
  ComplianceState,
  ComplianceAction | HydrateAction
> = (
  state: ComplianceState = initialState,
  action: ComplianceAction | HydrateAction
) => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...reconcileState(action.payload.compliance, initialState),
      };
    }
    case PersonalLoansActionType.GET_SUCCESS: {
      return {
        ...state,
        personalLoans: action.payload,
      };
    }
    case CreditCardsActionType.GET_SUCCESS: {
      return {
        ...state,
        creditCards: action.payload,
      };
    }
    case SavingsActionType.GET_SUCCESS: {
      return {
        ...state,
        savings: action.payload,
      };
    }
    case ChannelsActionType.GET_SUCCESS: {
      return {
        ...state,
        channels: action.payload,
      };
    }
    case ComplianceActionType.COMPLIANCE_ITEMS_MY_ACCOUNT_SUCCESS: {
      return {
        ...state,
        rows: action.payload,
      };
    }
    case ComplianceActionType.SUBMIT_FOR_REVIEW_SUCCESS:
    case ComplianceActionType.SUBMIT_FOR_LENDER_REVIEW_SUCCESS: {
      return {
        ...state,
        rows: [...state.rows, ...action.payload],
      };
    }
    default: {
      return state;
    }
  }
};
