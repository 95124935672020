"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.configurationReducer = exports.initialConfigState = void 0;
var typesafe_actions_1 = require("typesafe-actions");
var runtime_config_1 = require("@portal/common/dist/runtime-config");
var hydrate_1 = require("../hydrate");
var actions_1 = require("./actions");
exports.initialConfigState = (0, runtime_config_1.getRuntimeConfig)();
exports.configurationReducer = (0, typesafe_actions_1.createReducer)(exports.initialConfigState)
    .handleAction(hydrate_1.nextHydrate, function (state, _a) {
    var config = _a.payload.config;
    return (__assign(__assign({}, state), (0, hydrate_1.reconcileState)(config, exports.initialConfigState)));
})
    .handleAction(actions_1.setIsServer, function (state, _a) {
    var IS_SERVER = _a.payload;
    return (__assign(__assign({}, state), { IS_SERVER: IS_SERVER }));
});
