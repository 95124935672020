"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RepositoryKey = void 0;
var RepositoryKey;
(function (RepositoryKey) {
    RepositoryKey["AccessTokens"] = "access_tokens";
    RepositoryKey["Accounts"] = "accounts";
    RepositoryKey["Campaigns"] = "campaigns";
    RepositoryKey["ComplianceItems"] = "compliance_items";
    RepositoryKey["ComplianceWebhookItems"] = "compliance_webhook_items";
    RepositoryKey["DeduplicationConfigurations"] = "deduplication_configurations";
    RepositoryKey["DemandContracts"] = "demand_contracts";
    RepositoryKey["DemandDataImports"] = "demand_data_imports";
    RepositoryKey["DuplicateRejectionSummaries"] = "duplicate_rejection_summaries";
    RepositoryKey["ExpectedEarningsShares"] = "expected_earnings_share";
    RepositoryKey["LedgerItems"] = "ledger_items";
    RepositoryKey["Offers"] = "offers";
    RepositoryKey["PartnerPages"] = "partner_pages";
    RepositoryKey["SubAccounts"] = "subaccounts";
    RepositoryKey["SupplyContracts"] = "supply_contracts";
    RepositoryKey["SupplyDemandInclusions"] = "supply_demand_inclusions";
    RepositoryKey["Test"] = "test";
    RepositoryKey["Users"] = "users";
})(RepositoryKey = exports.RepositoryKey || (exports.RepositoryKey = {}));
