import type { MigrationSettingsForm } from '@/components/accounts/migration-settings/form';
import type { IsomorphicRequestPayload } from '@/store/types';

export enum SupplyMigrationActionType {
  GET_BY_AUTH_CONTEXT_FAILURE = '@@supply_migration/GET_BY_AUTH_CONTEXT_FAILURE',
  GET_BY_AUTH_CONTEXT_REQUEST = '@@supply_migration/GET_BY_AUTH_CONTEXT_REQUEST',
  GET_BY_AUTH_CONTEXT_SUCCESS = '@@supply_migration/GET_BY_AUTH_CONTEXT_SUCCESS',

  GET_BY_FINANCE_ACCOUNT_ID_FAILURE = '@@supply_migration/GET_BY_FINANCE_ACCOUNT_ID_FAILURE',
  GET_BY_FINANCE_ACCOUNT_ID_REQUEST = '@@supply_migration/GET_BY_FINANCE_ACCOUNT_ID_REQUEST',
  GET_BY_FINANCE_ACCOUNT_ID_SUCCESS = '@@supply_migration/GET_BY_FINANCE_ACCOUNT_ID_SUCCESS',

  START_FAILURE = '@@supply_migration/START_FAILURE',
  START_REQUEST = '@@supply_migration/START_REQUEST',
  START_SUCCESS = '@@supply_migration/START_SUCCESS',

  UPDATE_FAILURE = '@@supply_migration/UPDATE_FAILURE',
  UPDATE_REQUEST = '@@supply_migration/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@@supply_migration/UPDATE_SUCCESS',
}

export interface GetByFinanceAccountIdRequestPayload
  extends IsomorphicRequestPayload {
  financeAccountId: number;
}
export type StartMigrationRequestPayload = GetByFinanceAccountIdRequestPayload;
export interface UpdateSupplyMigrationRequestPayload
  extends GetByFinanceAccountIdRequestPayload {
  form: MigrationSettingsForm;
}
