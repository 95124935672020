import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@ant-design/compatible';
import type { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import type { FeedbackForm } from '@portal/store';
import {
  feedbackCreateRequestAction,
  getFeedbackDrawerVisible,
  toggleFeedbackDrawer,
} from '@portal/store/dist/feedback';

import { DrawerForm } from '@/components/form/drawer-form';

import '@ant-design/compatible/assets/index.css';

const { Option } = Select;
interface FeedbackProps {
  form: WrappedFormUtils;
}

const FeedbackCreate: React.FC<FeedbackProps> = ({ form }) => {
  const dispatch = useDispatch();
  const request = (form: FeedbackForm) =>
    dispatch(feedbackCreateRequestAction.request(form));
  const toggle = () => dispatch(toggleFeedbackDrawer());

  const visible = useSelector(getFeedbackDrawerVisible);

  return (
    <DrawerForm
      isLegacyPortalForm
      className="feedback-drawer"
      createRequest={request}
      fields={[
        {
          fieldDecoratorProps: {
            rules: [{ required: true }],
          },
          fieldElement: (
            <Select
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode as HTMLElement
              }
            >
              <Option value="idea">Idea</Option>
              <Option value="problem">Problem</Option>
              <Option value="question">Question</Option>
              <Option value="praise">Praise</Option>
            </Select>
          ),
          itemProps: { label: 'Feedback Type' },
          name: 'feedbackType',
        },
        {
          fieldDecoratorProps: {
            rules: [{ required: true }],
          },
          fieldElement: <TextArea autoSize={{ maxRows: 30, minRows: 15 }} />,
          name: 'comment',
        },
      ]}
      form={form}
      submitButtonTitle={'Submit'}
      title="Send us your feedback!"
      toggleDrawer={toggle}
      visible={visible}
    />
  );
};

const WrappedFeedbackCreate = Form.create({
  name: 'feedback_create',
})(FeedbackCreate);

export default WrappedFeedbackCreate;
