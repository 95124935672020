import kebabCase from 'lodash/kebabCase';
import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

import { ConfigProductType } from '@evenfinancial/even-ts-static';
import type { ProductSubType } from '@evenfinancial/lead-client';

import type { PartnerPageAlooma } from '@portal/api-models';
import { ComplianceStatus, OnboardingStatus } from '@portal/api-models';
import { selectPermissions } from '@portal/store/dist/user/selectors';

import { getComplianceStatusLabel } from '@/components/table/compliance-columns';
import { selectComplianceRows } from '@/resources/compliance/selects';
import { getComplianceInternal } from '@/resources/compliance/utils';
import { partnerPageToIntegration } from '@/resources/integrations/helpers/partner-page-to-integration';
import { selectMigratedIntegrationKeys } from '@/resources/integrations/selects';
import { ImplementationFeePaymentStatus } from '@/resources/integrations/types';
import type {
  Integration,
  IntegrationRow,
} from '@/resources/integrations/types';
import {
  getImplementationFeeStatus,
  getOnboardingStatus,
  getProductByLoanPurpose,
  isFeeWaivedForIntegration,
} from '@/resources/integrations/utils';
import { tableDataSelector as partnerKeyRowsSelector } from '@/resources/partner-keys/selectors';
import { selectPartnerPages } from '@/resources/partner-keys/selects';
import { genConsumerUrl } from '@/resources/partner-page/query-string-mapper';
import { getPartnerPagesByPartnerKey } from '@/resources/partner-page/selectors';
import { selectCurrentSaasPlan } from '@/resources/saas/selects';
import { isFeeWaivedForIntegrationCategory } from '@/resources/self-service/helpers';
import { selectIsImplementationFeeWaived } from '@/resources/self-service/selects';
import { getIntegrationCategory } from '@/resources/sub-accounts-shared/utils';
import { isPermanentTrial } from '@/resources/supply-migration/helpers';
import { selectSupplySubAccountsKeyedByCompanyUuid } from '@/resources/supply-sub-accounts/shared/selects';
import type { ApplicationState } from '@/store';
import { getPartnerPageBaseUrls } from '@/store/configuration/selectors';

import { selectSaasMigrationSettings } from '../supply-migration/selects';

export const getIntegrationSubProductType = (
  alooma: PartnerPageAlooma,
  fullConfigById: any
): ConfigProductType | undefined => {
  let productSubType;

  if (alooma.product === ConfigProductType.Loan) {
    productSubType = getProductByLoanPurpose(
      fullConfigById[alooma.id]?.loanPurposeOptions
    );
  }

  return productSubType;
};

export const integrationsSelector = createSelector(
  [
    selectPermissions,
    selectPartnerPages,
    getPartnerPagesByPartnerKey,
    getPartnerPageBaseUrls,
    ({ saas }: ApplicationState) => saas,
    selectComplianceRows,
    selectIsImplementationFeeWaived,
    selectSaasMigrationSettings,
    selectCurrentSaasPlan,
    selectSupplySubAccountsKeyedByCompanyUuid,
    selectMigratedIntegrationKeys,
  ],
  (
    { canReadIntegrations, shouldOverrideOnboardingStatusOfMigratedUsers },
    partnerPages,
    partnerPagesConfigs,
    baseUrls,
    saas,
    compliances,
    isImplementationFeeWaivedByWhitelist,
    supplyMigration,
    currentSaasPlan,
    supplySubAccountsByCompanyUuid,
    migratedIntegrationKeys
  ) => {
    if (!canReadIntegrations) {
      return [];
    }

    return partnerPages.reduce<Integration[]>((acc, partnerPage) => {
      const fullPartnerPage = partnerPagesConfigs?.[partnerPage.id];

      if (!fullPartnerPage) {
        return acc;
      }

      const subAccount =
        supplySubAccountsByCompanyUuid[partnerPage.companyUuid];

      const { isInMigration, integrationStatus } = partnerPageToIntegration({
        fullPartnerPage,
        migratedIntegrationKeys,
        partnerPage,
      });

      /**
       * TODO: This value is returned as ConfigProductType, but everywhere it is used,
       * it is typed as ProductSubType from the leads API.
       */
      const productSubType = getIntegrationSubProductType(
        partnerPage,
        partnerPagesConfigs
      );

      const formattedPartnerKey = kebabCase(partnerPage.partner_key);

      const [ppUrl] = genConsumerUrl(
        baseUrls,
        partnerPage.version,
        '',
        formattedPartnerKey,
        partnerPage.product
      ).split('?');

      const accessTokenUuid =
        partnerPagesConfigs[partnerPage.id]?.[
          'credentials.access_token'
        ]?.split('_')[0] || '';

      const integrationCategory = getIntegrationCategory(
        subAccount?.integrationType
      );

      const isImplementationFeeWaived = isFeeWaivedForIntegration(
        isImplementationFeeWaivedByWhitelist,
        integrationCategory,
        currentSaasPlan,
        supplyMigration
      );

      const implementationFeePaymentStatus =
        !saas.subscription &&
        supplyMigration?.integrationKeysToMigrate?.includes(
          partnerPage.partner_key
        )
          ? ImplementationFeePaymentStatus.Paid
          : getImplementationFeeStatus(
              saas,
              accessTokenUuid,
              isImplementationFeeWaived
            );

      const complianceInternal = getComplianceInternal(
        partnerPage.companyUuid,
        compliances
      );

      const complianceStatus = (complianceInternal?.status ??
        ComplianceStatus.draft) as ComplianceStatus;
      const complianceStatusUser = getComplianceStatusLabel(complianceStatus);

      const isPermanent = isPermanentTrial(supplyMigration!);

      const onboardingStatus = getOnboardingStatus(
        implementationFeePaymentStatus,
        complianceStatus,
        partnerPagesConfigs[partnerPage.id]?.integrationStatus,
        isPermanent &&
          isFeeWaivedForIntegrationCategory(
            integrationCategory,
            currentSaasPlan,
            supplyMigration
          )
      );

      const data: Integration = {
        ...partnerPage,
        accessTokenUuid,
        complianceStatus,
        complianceStatusUser,
        implementationFeePaymentStatus,
        integrationCategory,
        integrationProduct: productSubType ?? partnerPage.product,
        integrationStatus,
        integrationUrl: ppUrl,
        isImplementationFeeWaived,
        isInMigration,
        onboardingStatus,
        productSubType: productSubType as unknown as ProductSubType,
      };

      if (!isInMigration) {
        acc.push(data);
      }

      if (isInMigration) {
        const complianceOverride = !complianceInternal
          ? {
              complianceStatus: ComplianceStatus.closed,
              complianceStatusUser: getComplianceStatusLabel(
                ComplianceStatus.closed
              ),
            }
          : undefined;

        if (shouldOverrideOnboardingStatusOfMigratedUsers) {
          data.onboardingStatus = OnboardingStatus.Live;
        }

        acc.push({
          ...data,
          ...complianceOverride,
        });
      }

      return acc;
    }, [] as Integration[]);
  }
);

export const IntegrationsTableDataSelector = createSelector(
  [integrationsSelector, partnerKeyRowsSelector],
  (integrations, partnerKeyRows) => {
    return orderBy(
      integrations.map((integration) => {
        const partnerKeyRow = partnerKeyRows.find(
          (partnerKeyRow) => partnerKeyRow.id === integration.id
        );

        return {
          ...partnerKeyRow,
          ...integration,
          integrationType: getIntegrationCategory(
            partnerKeyRow?.integrationType
          ),
        };
      }) as IntegrationRow[],
      ['partner_key'],
      'asc'
    );
  }
);

export const IntegrationDataSelector = createSelector(
  [
    (state: ApplicationState, integrationId: string) => {
      const integration = IntegrationsTableDataSelector(state).find(
        (integration) => integration.id === integrationId
      );

      const partnerPageConfigStore =
        state.partnerPages.byPartnerKeyId[integrationId];

      const partnerPageConfig = partnerPageConfigStore
        ? Object.keys(partnerPageConfigStore).reduce(
            (accum: any, key) => {
              if (key.includes('clientTags.')) {
                accum.clientTags[key.split('.')[1]] =
                  partnerPageConfigStore[key];
              } else {
                accum[key] = partnerPageConfigStore[key];
              }

              return accum;
            },
            { clientTags: {} }
          )
        : {};

      return {
        baseUrls: state.config.PARTNER_PAGE_BASE_URLS,
        integration,
        partnerPageConfig,
      };
    },
  ],
  ({ integration, baseUrls, partnerPageConfig }) => ({
    baseUrls,
    integration,
    partnerPageConfig,
  })
);
