import { init, setUser } from '../../services/datadog/datadog-rum';
import { initializeDataDog } from '../logger/transport.datadog';

import { useTracker } from './use-tracker';

export const useDataDog = () => {
  useTracker(({ engineUser, roles, config }) => {
    init(config);
    setUser(engineUser);

    initializeDataDog({
      config,
      context: { roles, user: engineUser },
    });
  });
};
