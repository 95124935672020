"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnalyticsActionType = void 0;
var AnalyticsActionType;
(function (AnalyticsActionType) {
    AnalyticsActionType["GET_ALL_DUPLICATE_SUMMARIES_FAILURE"] = "@@analytics/GET_ALL_DUPLICATE_SUMMARIES_FAILURE";
    AnalyticsActionType["GET_ALL_DUPLICATE_SUMMARIES_REQUEST"] = "@@analytics/GET_ALL_DUPLICATE_SUMMARIES_REQUEST";
    AnalyticsActionType["GET_ALL_DUPLICATE_SUMMARIES_SUCCESS"] = "@@analytics/GET_ALL_DUPLICATE_SUMMARIES_SUCCESS";
    AnalyticsActionType["GET_MCI_REPORT_URL_FAILURE"] = "@@analytics/GET_MCI_REPORT_URL_FAILURE";
    AnalyticsActionType["GET_MCI_REPORT_URL_REQUEST"] = "@@analytics/GET_MCI_REPORT_URL_REQUEST";
    AnalyticsActionType["GET_MCI_REPORT_URL_SUCCESS"] = "@@analytics/GET_MCI_REPORT_URL_SUCCESS";
    AnalyticsActionType["GET_SUPPLY_SUMMARIES_FAILURE"] = "@@analytics/GET_SUPPLY_SUMMARIES_FAILURE";
    AnalyticsActionType["GET_SUPPLY_SUMMARIES_REQUEST"] = "@@analytics/GET_SUPPLY_SUMMARIES_REQUEST";
    AnalyticsActionType["GET_SUPPLY_SUMMARIES_SUCCESS"] = "@@analytics/GET_SUPPLY_SUMMARIES_SUCCESS";
    AnalyticsActionType["IS_LOADING_MCI_REPORTS"] = "@@analytics/IS_LOADING_MCI_REPORTS";
    AnalyticsActionType["SET_SUPPLY_SUMMARY_DATE_RANGE_FILTER"] = "@@analytics/SET_SUPPLY_SUMMARY_DATE_RANGE_FILTER";
})(AnalyticsActionType = exports.AnalyticsActionType || (exports.AnalyticsActionType = {}));
