"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserActionType = void 0;
var UserActionType;
(function (UserActionType) {
    UserActionType["CREATE_FAILURE_CLIENT"] = "@@user/CREATE_FAILURE_CLIENT";
    UserActionType["CREATE_REQUEST_CLIENT"] = "@@user/CREATE_REQUEST_CLIENT";
    UserActionType["CREATE_SUCCESS_CLIENT"] = "@@user/CREATE_SUCCESS_CLIENT";
    UserActionType["CREATE_USER_DEACTIVATIONS_FAILURE"] = "@@user/CREATE_USER_DEACTIVATIONS_FAILURE";
    UserActionType["CREATE_USER_DEACTIVATIONS_REQUEST"] = "@@user/CREATE_USER_DEACTIVATIONS_REQUEST";
    UserActionType["CREATE_USER_DEACTIVATIONS_SUCCESS"] = "@@user/CREATE_USER_DEACTIVATIONS_SUCCESS";
    UserActionType["DELETE_TOKEN_BY_UUID_FAILURE"] = "@@user/DELETE_TOKEN_BY_UUID_FAILURE";
    UserActionType["DELETE_TOKEN_BY_UUID_REQUEST"] = "@@user/DELETE_TOKEN_BY_UUID_REQUEST";
    UserActionType["DELETE_TOKEN_BY_UUID_SUCCESS"] = "@@user/DELETE_TOKEN_BY_UUID_SUCCESS";
    UserActionType["DELETE_USER_DEACTIVATIONS_FAILURE"] = "@@user/DELETE_USER_DEACTIVATIONS_FAILURE";
    UserActionType["DELETE_USER_DEACTIVATIONS_REQUEST"] = "@@user/DELETE_USER_DEACTIVATIONS_REQUEST";
    UserActionType["DELETE_USER_DEACTIVATIONS_SUCCESS"] = "@@user/DELETE_USER_DEACTIVATIONS_SUCCESS";
    UserActionType["GET_ACCESS_ALL_FAILURE"] = "@@user/GET_ACCESS_ALL_FAILURE";
    UserActionType["GET_ACCESS_ALL_REQUEST"] = "@@user/GET_ACCESS_ALL_REQUEST";
    UserActionType["GET_ACCESS_ALL_SUCCESS"] = "@@user/GET_ACCESS_ALL_SUCCESS";
    UserActionType["GET_ACCESS_BY_UUID_FAILURE"] = "@@user/GET_ACCESS_BY_UUID_FAILURE";
    UserActionType["GET_ACCESS_BY_UUID_REQUEST"] = "@@user/GET_ACCESS_BY_UUID_REQUEST";
    UserActionType["GET_ACCESS_BY_UUID_SUCCESS"] = "@@user/GET_ACCESS_BY_UUID_SUCCESS";
    UserActionType["GET_ALL_FAILURE"] = "@@user/GET_ALL_FAILURE";
    UserActionType["GET_ALL_REQUEST"] = "@@user/GET_ALL_REQUEST";
    UserActionType["GET_ALL_SUCCESS"] = "@@user/GET_ALL_SUCCESS";
    UserActionType["GET_ALL_USER_DEACTIVATION_FAILURE"] = "@@user/GET_ALL_USER_DEACTIVATION_FAILURE";
    UserActionType["GET_ALL_USER_DEACTIVATION_REQUEST"] = "@@user/GET_ALL_USER_DEACTIVATION_REQUEST";
    UserActionType["GET_ALL_USER_DEACTIVATION_SUCCESS"] = "@@user/GET_ALL_USER_DEACTIVATION_SUCCESS";
    UserActionType["GET_BILLING_INFO_FAILURE"] = "@@user/GET_BILLING_INFO_FAILURE";
    UserActionType["GET_BILLING_INFO_REQUEST"] = "@@user/GET_BILLING_INFO_REQUEST";
    UserActionType["GET_BILLING_INFO_SUCCESS"] = "@@user/GET_BILLING_INFO_SUCCESS";
    UserActionType["GET_BY_UUID_FAILURE"] = "@@user/GET_BY_UUID_FAILURE";
    UserActionType["GET_BY_UUID_REQUEST"] = "@@user/GET_BY_UUID_REQUEST";
    UserActionType["GET_BY_UUID_SUCCESS"] = "@@user/GET_BY_UUID_SUCCESS";
    UserActionType["GET_RICH_TOKEN_BY_ID_FAILURE"] = "@@user/GET_RICH_TOKEN_BY_ID_FAILURE";
    UserActionType["GET_RICH_TOKEN_BY_ID_REQUEST"] = "@@user/GET_RICH_TOKEN_BY_ID_REQUEST";
    UserActionType["GET_RICH_TOKEN_BY_ID_SUCCESS"] = "@@user/GET_RICH_TOKEN_BY_ID_SUCCESS";
    UserActionType["GET_RICH_TOKEN_BY_UUID_FAILURE"] = "@@user/GET_RICH_TOKEN_BY_UUID_FAILURE";
    UserActionType["GET_RICH_TOKEN_BY_UUID_REQUEST"] = "@@user/GET_RICH_TOKEN_BY_UUID_REQUEST";
    UserActionType["GET_RICH_TOKEN_BY_UUID_SUCCESS"] = "@@user/GET_RICH_TOKEN_BY_UUID_SUCCESS";
    UserActionType["GET_VERIFICATION_FAILURE"] = "@@user/VERIFICATION_FAILURE";
    UserActionType["GET_VERIFICATION_REQUEST"] = "@@user/VERIFICATION_REQUEST";
    UserActionType["GET_VERIFICATION_SUCCESS"] = "@@user/VERIFICATION_SUCCESS";
    UserActionType["PW_RESET_FAILURE"] = "@@user/PW_RESET_FAILURE";
    UserActionType["PW_RESET_REQUEST"] = "@@user/PW_RESET_REQUEST";
    UserActionType["PW_RESET_SUCCESS"] = "@@user/PW_RESET_SUCCESS";
    UserActionType["SEND_DEMAND_USER_CONTACT_US_REQUEST_EMAIL_FAILURE"] = "@@user/SEND_DEMAND_USER_CONTAC_US_REQUEST_EMAIL_FAILURE";
    UserActionType["SEND_DEMAND_USER_CONTACT_US_REQUEST_EMAIL_REQUEST"] = "@@user/SEND_DEMAND_USER_CONTAC_US_REQUEST_EMAIL_REQUEST";
    UserActionType["SEND_DEMAND_USER_CONTACT_US_REQUEST_EMAIL_SUCCESS"] = "@@user/SEND_DEMAND_USER_CONTAC_US_REQUEST_EMAIL_SUCCESS";
    UserActionType["SEND_USER_CREATION_LIMIT_CHANGE_REQUEST_EMAIL_FAILURE"] = "@@user/SEND_USER_CREATION_LIMIT_CHANGE_REQUEST_EMAIL_FAILURE";
    UserActionType["SEND_USER_CREATION_LIMIT_CHANGE_REQUEST_EMAIL_REQUEST"] = "@@user/SEND_USER_CREATION_LIMIT_CHANGE_REQUEST_EMAIL_REQUEST";
    UserActionType["SEND_USER_CREATION_LIMIT_CHANGE_REQUEST_EMAIL_SUCCESS"] = "@@user/SEND_USER_CREATION_LIMIT_CHANGE_REQUEST_EMAIL_SUCCESS";
    UserActionType["SET_CLAIMS"] = "@@user/SET_CLAIMS";
    UserActionType["SET_PROFILE_META"] = "@@user/SET_PROFILE_META";
    UserActionType["TOGGLE_CREATE"] = "@@user/TOGGLE_CREATE";
    UserActionType["TOGGLE_DUPLICATE"] = "@@user/TOGGLE_DUPLICATE";
    UserActionType["TOGGLE_DUPLICATE_OPEN"] = "@@user/TOGGLE_DUPLICATE_OPEN";
    UserActionType["UPDATE_FAILURE"] = "@@user/UPDATE_FAILURE";
    UserActionType["UPDATE_REQUEST"] = "@@user/UPDATE_REQUEST";
    UserActionType["UPDATE_SUCCESS"] = "@@user/UPDATE_SUCCESS";
})(UserActionType = exports.UserActionType || (exports.UserActionType = {}));
