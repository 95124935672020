import * as React from 'react';

import { ComplianceIntegrationType } from '@portal/api-models';

export interface PublishIntegrationInstructionsProps {
  integrationType?: ComplianceIntegrationType;
  integrationUrl?: string;
}

const PublishIntegrationInstructions: React.FC<
  PublishIntegrationInstructionsProps
> = ({ integrationType, integrationUrl }) =>
  integrationType === ComplianceIntegrationType.embed ? (
    <div className="publish-integration-description">
      Add the provided iframe code into your website html to host the search,
      comparison and recommendation flow within your experience.
      <ul>
        <li>Copy the provided iframe code</li>
        <li>Paste into your website html</li>
        <li>Only place one iframe per page</li>
      </ul>
      <span>
        Please confirm by clicking “Publish” once you have implemented your
        embed.
      </span>
    </div>
  ) : (
    <div className="publish-integration-description">
      Send users to your Partner page (
      <a href={integrationUrl} rel="noreferrer" target="_blank">
        {integrationUrl}
      </a>
      ) by following these simple steps mentioned below:
      <ul>
        <li>
          Add a hyperlink on your website that links out to the Partner page URL
        </li>
        <li>
          Add a CTA button on your website that links out to the Partner page
          URL
        </li>
      </ul>
      <span>
        Please confirm by clicking “Publish” once you have implemented your
        partner page.
      </span>
    </div>
  );

export default PublishIntegrationInstructions;
