"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorType = void 0;
var ErrorType;
(function (ErrorType) {
    ErrorType["accountIdInvalid"] = "Account ID not found";
    ErrorType["accountNameExists"] = "Active account name already exists";
    ErrorType["accountUuidInvalid"] = "Account UUID not found";
    ErrorType["awsFileUrlIssue"] = "Could not fetch file";
    ErrorType["billingCycleUuidInvalid"] = "Billing Cycle UUID not found";
    ErrorType["deactivatedUser"] = "Deactivated user";
    ErrorType["default"] = "We were unable to complete a request. We're aware of the issue and are working to fix it.";
    ErrorType["demandContractIdInvalid"] = "Demand Contract ID not found.";
    ErrorType["externalAccountSelection"] = "External users must have access to at least one account.";
    ErrorType["failedSubscriptionCreation"] = "We haven\u2019t processed your subscription payment. If you think it should have been already processed, please contact support.";
    ErrorType["featureFlagNameExists"] = "Feature flag name already exists.";
    ErrorType["financeAccountIdInvalid"] = "Account ID not found";
    ErrorType["forbiddenUserCreation"] = "You don't have permission to create this type of user";
    ErrorType["inclusionsTemplateInvalid"] = "InclusionsTemplateEntity invalid.";
    ErrorType["invalidCredentials"] = "Invalid credentials";
    ErrorType["inventoryNotLoaded"] = "Inventory not found.";
    ErrorType["ledgerItemExistsWithSameEndDate"] = "Account cannot have ledger items of the same type with the same end date";
    ErrorType["partnerPageExists"] = "The partner page key, version, and product type combination already exists.";
    ErrorType["partnerPageNameExist"] = "Partner page name already exists for this product type";
    ErrorType["payoutClauseInvalid"] = "All Payout Clause Pricing Models must have a valid value";
    ErrorType["revenueClauseInvalid"] = "All Revenue Clause Pricing Models must have a valid value";
    ErrorType["ruleDemandApiFilterCannotUsePreviousSupplySource"] = "One or more supply sources are currently in use. Delete the existing lender provided knockout criteria containing your desired supply source and try again.";
    ErrorType["saasPlanNotFound"] = "No saas plan found";
    ErrorType["saasSubscriptionNotFound"] = "Subscription not found";
    ErrorType["subAccountAlreadyHasContractWithSameEffectiveAtDate"] = "Sub Account already has contract with same effectiveAt date";
    ErrorType["subAccountNameExists"] = "Active SubAccount name already exists for Account";
    ErrorType["subAccountUuidInvalid"] = "SubAccount UUID not found.";
    ErrorType["supplyContractIdInvalid"] = "Supply Contract ID not found.";
    ErrorType["unauthorizedAccess"] = "Unauthorized Access";
    ErrorType["userExists"] = "The email address is already in use.";
    ErrorType["userIdInvalid"] = "User ID not found";
    ErrorType["userNotFound"] = "User not found";
    ErrorType["userUnregistered"] = "Engine user not registered";
    ErrorType["userUuidInvalid"] = "User UUID not found.";
    ErrorType["userVerified"] = "The email address is already verified.";
    ErrorType["whitelistEmailExists"] = "Whitelist e-mail with type already exists.";
})(ErrorType = exports.ErrorType || (exports.ErrorType = {}));
