import * as React from 'react';

import { Router } from '../routes';
import { nonAuthenticatedRoutes } from '../utils/non-authenticated-routes';

/**
 * Show children only if the current route requires authentication.
 */
export const AuthenticatedRouteGuard: React.FC = ({ children }) => {
  const isNonAuthenticatedRoute = nonAuthenticatedRoutes.has(Router.pathname);

  if (isNonAuthenticatedRoute) {
    return null;
  }

  return <>{children}</>;
};
