"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCmsClient = void 0;
function getCmsClient(c, config, configuration = {}) {
    const client = new c(Object.assign(Object.assign({}, configuration), { baseOptions: Object.assign(Object.assign({}, configuration.baseOptions), { headers: {
                Authorization: `Bearer ${config.NEXT_PUBLIC_CMS_TOKEN}`,
            } }), isJsonMime: () => false }));
    return client;
}
exports.getCmsClient = getCmsClient;
exports.default = getCmsClient;
