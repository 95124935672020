export enum PremiumLendersActionType {
  CREATE_PREMIUM_LENDER_FAILURE = '@@premium-lenders/CREATE_PREMIUM_LENDER_FAILURE',
  CREATE_PREMIUM_LENDER_REQUEST = '@@premium-lenders/CREATE_PREMIUM_LENDER_REQUEST',
  CREATE_PREMIUM_LENDER_SUCCESS = '@@premium-lenders/CREATE_PREMIUM_LENDER_SUCCESS',

  DELETE_PREMIUM_LENDER_FAILURE = '@@premium-lenders/DELETE_PREMIUM_LENDER_FAILURE',
  DELETE_PREMIUM_LENDER_REQUEST = '@@premium-lenders/DELETE_PREMIUM_LENDER_REQUEST',
  DELETE_PREMIUM_LENDER_SUCCESS = '@@premium-lenders/DELETE_PREMIUM_LENDER_SUCCESS',

  FIND_PREMIUM_LENDERS_FAILURE = '@@premium-lenders/FIND_PREMIUM_LENDERS_FAILURE',
  FIND_PREMIUM_LENDERS_REQUEST = '@@premium-lenders/FIND_PREMIUM_LENDERS_REQUEST',
  FIND_PREMIUM_LENDERS_SUCCESS = '@@premium-lenders/FIND_PREMIUM_LENDERS_SUCCESS',

  GET_PREMIUM_LENDER_BY_ID_FAILURE = '@@premium-lenders/GET_PREMIUM_LENDER_BY_ID_FAILURE',
  GET_PREMIUM_LENDER_BY_ID_REQUEST = '@@premium-lenders/GET_PREMIUM_LENDER_BY_ID_REQUEST',
  GET_PREMIUM_LENDER_BY_ID_SUCCESS = '@@premium-lenders/GET_PREMIUM_LENDER_BY_ID_SUCCESS',

  INIT_PREMIUM_LENDERS_INTERNAL_PAGE = '@@premium-lenders/INIT_PREMIUM_LENDERS_INTERNAL_PAGE',
  TOGGLE_PREMIUM_LENDER_CREATE = '@@premium-lenders/TOGGLE_PREMIUM_LENDER_CREATE',
  TOGGLE_PREMIUM_LENDER_UPDATE = '@@premium-lenders/TOGGLE_PREMIUM_LENDER_UPDATE',

  UPDATE_PREMIUM_LENDER_FAILURE = '@@premium-lenders/UPDATE_PREMIUM_LENDER_FAILURE',

  UPDATE_PREMIUM_LENDER_REQUEST = '@@premium-lenders/UPDATE_PREMIUM_LENDER_REQUEST',
  UPDATE_PREMIUM_LENDER_SUCCESS = '@@premium-lenders/UPDATE_PREMIUM_LENDER_SUCCESS',
}
