import type { AxiosResponse } from 'axios';
import {
  all,
  call,
  put,
  putResolve,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import type { ActionType } from 'typesafe-actions';

import { client } from '@portal/api-client';
import type { PremiumLender } from '@portal/api-models';
import { userRequestAction } from '@portal/store/dist/user';

import { demandSubAccountRequestAction } from '@/store/subaccount/actions';

import {
  createPremiumLenderAction,
  deletePremiumLenderAction,
  findPremiumLendersAction,
  getPremiumLenderByIdAction,
  initPremiumLendersInternalPageAction,
  updatePremiumLenderAction,
} from './actions';

export function* createPremiumLender({
  payload: { data },
}: ActionType<typeof createPremiumLenderAction.request>) {
  try {
    const { data: response }: AxiosResponse<PremiumLender> = yield call(
      client.post,
      '/premium_lenders',
      data
    );

    yield putResolve(createPremiumLenderAction.success(response));
  } catch (err: any) {
    yield put(createPremiumLenderAction.failure(err));
  }
}

export function* updatePremiumLender({
  payload: { id, data },
}: ActionType<typeof updatePremiumLenderAction.request>) {
  try {
    const { data: response }: AxiosResponse<PremiumLender> = yield call(
      client.patch,
      `/premium_lenders/${id}`,
      data
    );

    yield putResolve(updatePremiumLenderAction.success(response));
  } catch (err: any) {
    yield put(updatePremiumLenderAction.failure(err));
  }
}

export function* deletePremiumLender({
  payload: { id },
}: ActionType<typeof deletePremiumLenderAction.request>) {
  try {
    yield call([client, 'delete'], `/premium_lenders/${id}`);

    yield putResolve(deletePremiumLenderAction.success({ id }));
  } catch (err: any) {
    yield put(deletePremiumLenderAction.failure(err));
  }
}

export function* findPremiumLenders({
  payload: { data },
}: ActionType<typeof findPremiumLendersAction.request>) {
  try {
    const { data: response }: AxiosResponse<PremiumLender[]> = yield call(
      client.get,
      '/premium_lenders',
      { params: data }
    );

    yield putResolve(findPremiumLendersAction.success(response));
  } catch (err: any) {
    yield put(findPremiumLendersAction.failure(err));
  }
}

export function* getPremiumLenderById({
  payload: { id },
}: ActionType<typeof getPremiumLenderByIdAction.request>) {
  try {
    const { data: response }: AxiosResponse<PremiumLender> = yield call(
      client.get,
      `/premium_lenders/${id}`
    );

    yield putResolve(getPremiumLenderByIdAction.success(response));
  } catch (err: any) {
    yield put(getPremiumLenderByIdAction.failure(err));
  }
}

export function* initPremiumLendersInternalPage() {
  yield all([
    put(findPremiumLendersAction.request({ data: {} })),
    put(userRequestAction.request({})),
    put(demandSubAccountRequestAction.request({})),
  ]);
}

export function* premiumLendersSaga() {
  yield takeLeading(
    getType(createPremiumLenderAction.request),
    createPremiumLender
  );
  yield takeLeading(
    getType(updatePremiumLenderAction.request),
    updatePremiumLender
  );
  yield takeLeading(
    getType(deletePremiumLenderAction.request),
    deletePremiumLender
  );
  yield takeLatest(
    getType(findPremiumLendersAction.request),
    findPremiumLenders
  );
  yield takeLatest(
    getType(getPremiumLenderByIdAction.request),
    getPremiumLenderById
  );
  yield takeLatest(
    initPremiumLendersInternalPageAction,
    initPremiumLendersInternalPage
  );
}
