import * as React from 'react';

import {
  faClipboardCheck,
  faCube,
  faEnvelope,
  faFileInvoice,
  faFileSignature,
  faStore,
} from '@fortawesome/pro-solid-svg-icons';
import { Menu } from 'antd';

import { KitIconNames, SidebarIcon } from '@portal/ui-lib';

import { RouterAwareMenuItem } from '@/components/side-nav/router-aware-menu-item';

const { ItemGroup } = Menu;

export interface ChannelsSectionProps {
  showTemplateCenterMenuItem?: boolean;
  sidenavCollapsed?: boolean;
}

export const ChannelsSection: React.FC<ChannelsSectionProps> = ({
  showTemplateCenterMenuItem,
  sidenavCollapsed,
  ...otherProps
}) => (
  <ItemGroup
    className="nav-menu-item-group"
    title={sidenavCollapsed ? '' : 'CHANNELS'}
    {...otherProps}
  >
    <RouterAwareMenuItem
      key="menu-item-revenue-reporting"
      additionalPaths={[
        '/channels/revenue-reporting',
        '/channels/performance-summary',
      ]}
      eventKey="channels-revenue-reporting"
      href="/channels/performance-summary"
      icon={<SidebarIcon icon={KitIconNames.RevenueReport} />}
      text="Performance Summary"
    />
    <RouterAwareMenuItem
      key="menu-item-channels-channels"
      eventKey="channels-channels"
      href="/channels/channels"
      icon={<SidebarIcon icon={faStore} />}
      text="Channels"
    />
    <RouterAwareMenuItem
      key="channels-compliance"
      eventKey="channels-compliance"
      href="/channels/compliance"
      icon={<SidebarIcon icon={faClipboardCheck} />}
      text="Compliance"
    />
    {showTemplateCenterMenuItem && (
      <RouterAwareMenuItem
        key="channels-template-center"
        eventKey="channels-template-center"
        href="/channels/template-center"
        icon={<SidebarIcon icon={faFileInvoice} />}
        text="Template Center"
      />
    )}
    <RouterAwareMenuItem
      key="menu-item-channels-integrations"
      eventKey="channels-integrations"
      href="/channels/integrations"
      icon={<SidebarIcon icon={faCube} />}
      text="Integrations"
    />
    <RouterAwareMenuItem
      key="menu-item-channels-contracts"
      eventKey="channels-contracts"
      href="/channels/contracts"
      icon={<SidebarIcon icon={faFileSignature} />}
      text="Contracts"
    />
    <RouterAwareMenuItem
      key="communication-eligibilities"
      eventKey="communication-eligibilities"
      href="/channels/communication-eligibilities"
      icon={<SidebarIcon icon={faEnvelope} />}
      text="Communication Eligibilities"
    />
    <RouterAwareMenuItem
      key="menu-item-yield-optimizer"
      eventKey="yield-optimizer"
      href="/channels/yield-optimizer"
      icon={<SidebarIcon icon={KitIconNames.FileInvoiceDollar} />}
      text="Yield Optimizer"
    />
  </ItemGroup>
);
