import * as React from 'react';

import { faBell } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer, Tabs } from 'antd';

import { NotificationFilter } from '@portal/store/dist/notifications/types';

const { TabPane } = Tabs;

interface NotificationsDrawerProps extends React.PropsWithChildren<{}> {
  drawerVisible: boolean;
  filter: NotificationFilter;
  setFilter: (filter: NotificationFilter) => void;
  toggleDrawer: (visible: boolean, filter?: NotificationFilter) => void;
}

export const NotificationsDrawer: React.FC<NotificationsDrawerProps> = ({
  children,
  drawerVisible,
  filter,
  setFilter,
  toggleDrawer,
}) => {
  return (
    <Drawer
      closable
      className="notifications-drawer"
      placement="right"
      title={
        <>
          <FontAwesomeIcon className="icon" icon={faBell} />
          <span className="label">Notifications</span>
        </>
      }
      visible={drawerVisible}
      width={458}
      onClose={() => toggleDrawer(false)}
    >
      <Tabs
        activeKey={filter}
        onChange={(activeKey) => setFilter(activeKey as NotificationFilter)}
      >
        <TabPane key={NotificationFilter.UNREAD} tab="Unread" />
        <TabPane key={NotificationFilter.ALL} tab="See all" />
      </Tabs>
      {children}
    </Drawer>
  );
};
