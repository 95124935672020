export const PKDSTooltips = {
  'app.loanAmount':
    "Overrides the default Loan Amount in Preview Step filter and Application form. Default value is '$5,000'",
  'app.providedCreditRating':
    'Overrides for default "Credit Rating" in preview step filter and application form. Selecting a default may reduce data quality and approval rate. Do not select a default credit rating unless you have an explicit reason to do so.',
  'app.purpose':
    "Overrides the default Loan Purpose in Preview Step filter and Application form. Default value is 'Debt Consolidation'",
  'app.zipcode':
    'Determines a fallback ZIP code for the Preview Step filter and Application form if the users IP location can not be detected. ZIP will always be populated with users IP when possible.',
  credit_card_flow:
    'Redirect flow sends leads directly to issuer website from the preview offer call to action. \n\nRemarketing flow sends leads through a simple form capturing contact information before sending to issuer website so we may send remarketing emails to them.',
  embedScripts:
    'The JavaScript code that you enter here will be included on the page (this does not fire on any event)',
  filter_style:
    'Alternate styles for the filter options above preview static offers.',
  headScripts: 'Script URL to include in <head> tag',
  headerCopy:
    'Overrides default copy template. Default value is blank entry or standard.',
  headerLayout:
    "Whitelabled setup includes only the Primary Brand logo on partner page headers. Co-branded setup includes the Primary Brand logo on the top left and Secondary 'in partnership with' brand on the right. Does not affect solo embed assets.",
  headerText: 'Overrides the default Partner Page header text.',
  'height, width*':
    'Supported sizes for EAUS embeds. If a new size is needed, please request via ',
  loanPurposeOptions:
    'Determines which options will appear as filters for preview offers on Step 1 of the Embed or Partner Page. Filter will be hidden if only one Loan Purpose is selected.',
  loan_flow:
    'Alternate user flows for the application form that splits fields onto varying number of steps.',
  offer:
    'Adds a static offer that appears above the form on Application Step. Must be a static offer UUID.',
  offer_overflow:
    'Determines how to handle preview offers when there are more that can show based on Max Total Results. Leaving this option blank provides no way to view overflow offers. "Paginate" segments the offers into separate pages and adds pagination to the bottom of each page. "Partner Link" adds a \'See more offers\' call to action below the offers to view additional on an external partner page. Must have a "Page Size" set to work',
  offer_style: 'Alternate styles for the offers.',
  page_size:
    'Determines the number of offers to show per page when Offer Overflow is set. Offer Overflow will not work without this configuration.',
  partner:
    "A unique ID that stores all settings, tracking configurations and theme configurations set here. These configurations affect both partner page and embed (unless overridden by query parameters). The ID must be unique and should not include spaces or special charactes other than '-' and '_'. \n\nOnly include words relating to the name of your business. Do not include words like 'cash', 'instant', 'guaranteed', 'fast' etc.",
  partnerBrandDominant:
    "Determines which logo will appear in the top left dominant position on partner pages. If 'Brand Setup' is set to Whitelabeled, this will be the only logo present in the partner page header. This does not affect solo embed assets outside of default theme configs.",
  partnerDisclaimer:
    'Adds text under embed offer wall and above footer. Can be plain text or encoded HTML.',
  partnerDisplayName: 'Sets the alt text for partner logo.',
  partnerLogoBase64Image:
    'Partner logo image that appears on the partner page header. Should be cropped with no internal padding and in jpeg, png or gif format. Image should be 90px high. Width is flexible.',
  productType:
    'Determines which product type is available in the partner page and embed for this partner key.',
  shouldAllowFionaAccount:
    'Adds a form for creating an account. It appears at the bottom of results step and as a last step in step by step application.',
  shouldAllowLoanCoApplicant:
    'Determines if the application includes the option to add a co-applicant.',
  shouldAutoRedirect:
    'Determines if the user will be automatically redirected on results step to the top ranked pre-approved or pre-qualified offer after 10 seconds. Enabling this will increase conversion payout but may negatively impact user experience.',
  shouldShowPartnershipHeading:
    'Determines if the Even or Fiona branding is visible on the top header, adjacent to the partner logo.',
  step: 'Determines the initial step to show preview offers or application form if applicable.',
  'theme.borderRadiusLarge':
    "Controls the border radius for containers. Must include suffix 'px' (ie '4px').",
  'theme.borderRadiusSmall':
    "Controls the border radius for buttons and inputs. Must include suffix 'px' (ie '4px').",
  'theme.colorBackgroundPrimary': 'Controls background color of Embed',
  'theme.colorBackgroundSecondary':
    'Controls backgrond colors for filter container, application form container, results heading container, and partner page background.',
  'theme.colorBorder': 'Controls border color. Must be hex value.',
  'theme.colorCta':
    'Controls all call to action button background colors and loader color',
  'theme.colorError': 'Controls error messages on inputs',
  'theme.colorPrimary':
    'Controls partner page header background, partner page sidebar icons, text link color, and countdown color',
  'theme.colorSecondary':
    "Controls filter/sorting highlight and 'Pre-Approved' banner color",
  'theme.colorTextPrimary':
    'Text Color for offer stats, button text, form section headings, pre-approved banner text',
  'theme.colorTextSecondary':
    'Text Color for offer stat labels, input text, filter text, disclaimer text, footer text',
  'theme.fontFamilyPrimary':
    'Font Family for offer stats, button text, form section headings, pre-approved banner text',
  'theme.fontFamilySecondary':
    'Font Family for offer stat labels, input text, filter text, disclaimer text, footer text',
  'theme.fontSizeBase':
    "Base font size for all text. Must include prefix 'px' (ie '14px'). Recommended is 14px.",
  'theme.partnerLogo':
    'URL of a hosted logo image. To upload a new image, use the Logo Upload.',
  'theme.partnerLogoLink':
    'URL for the logo image to link to in a new tab. Recommended to use your website homepage URL.',
  'theme.partnerLogoWidth':
    "Controls the width for the logo. Must include suffix 'px' (ie '120px').",
  'ui.generic_offer_index':
    'Determines the placement of the generic call to action on the Preview Offer wall\n\n-1 hides the generic cta\n0 top of list\n1 second in list\n2 third in list\netc\n\nuse 100 or any large number to force offer to the bottom of the list',
  'ui.maxResults':
    'Overrides the total maximum number of preview offers to show on an preview step. If empty, defaults are as follows:\nPersonal Loans - 5\nSavings - 3\nCredit Cards - 10\n\nDoes not include Generic CTAs',
  version:
    'Internal consumer brand that affects the internal partnership logo image, default theme colors and fonts, etc for co-branded assets. Also controls the branding for privacy / terms page links in asset footers and non-company UUID tracking.',
};
