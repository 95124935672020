import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import kebabCase from 'lodash/kebabCase';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import startsWith from 'lodash/startsWith';

import { ConfigProductType } from '@evenfinancial/even-ts-static';

import type { PartnerPageBaseUrls } from '@/store/config';

import { mapCreditCardFlowFromForm } from './fields/credit-cards';

const excludeKeys = [
  'eventHandlers',
  'featured_trusted_partners',
  // Theme exclusions start
  'partnerLogoBase64Image',
  'partnerLogoBase64Image.file.lastModified',
  'partnerLogoBase64Image.file.name',
  'partnerLogoBase64Image.file.origin.FileObj.uid',
  'partnerLogoBase64Image.file.percent',
  'partnerLogoBase64Image.file.size',
  'partnerLogoBase64Image.file.xhr',
  'partnerLogoBase64Image.file.status',
  'partnerLogoBase64Image.file.thumbUrl',
  'partnerLogoBase64Image.file.type',
  'partnerLogoBase64Image.file.uid',
  'partnerLogoBase64Image.fileList',
  'partnerLogoBase64Image.file.response',
  'partnerLogoBase64Image.file.originFileObj.uid',
  'partnerLogoBase64Image.event.isTrusted',
  'partnerDisplayName',
  // Theme exclusions end
  // Tracking exclusions begin
  'embedScripts',
  'headScripts',
  // Tracking exclusions end
  'id',
  'version',
];

const encodeBool = (v: boolean) => {
  return v ? 'true' : 'false';
};

const encodeKV = (k: string, v: string | boolean) => {
  const encoded =
    typeof v !== 'boolean' ? encodeURIComponent(v) : encodeBool(v);

  return isEmpty(encoded) ? '' : `${encodeURIComponent(k)}=${encoded}`;
};

const sanitizeObject = (query: object) => {
  const omitted = omit(query, excludeKeys);
  const picked = omitBy(omitted, isNil);

  return picked;
};

export const flatten = (object: object, prefix = ''): any =>
  object &&
  Object.entries(object).reduce((obj, [k, v]) => {
    const key = `${prefix}${k}`;

    if (!Array.isArray(v) && typeof v === 'object') {
      return { ...obj, ...flatten(v, `${key}.`) };
    }

    return { ...obj, [key]: v };
  }, {});

const reduceToQueryLoop = (queryObject: object, prefix?: string): string => {
  const query = Object.entries(sanitizeObject(queryObject)).reduce(
    (qs, [k, v]) => {
      if (Array.isArray(v)) {
        if (!v.length) {
          return qs;
        }

        const reduced = encodeKV(prefix ? `${prefix}.${k}` : k, v.join(','));

        return `${qs}&${reduced}`;
      }

      if (typeof v === 'object') {
        if (isEmpty(v)) {
          return qs;
        }

        const reduced = reduceToQueryLoop(v!, k);

        return `${qs}${reduced}`;
      }

      if (typeof v === 'boolean' || typeof v === 'string') {
        const key = prefix ? `${prefix}.${k}` : k;
        const encoded = encodeKV(key, v);

        return encoded.length ? `${qs}&${encoded}` : qs;
      }

      return qs;
    },
    ''
  );

  return query;
};

const trimmedQueryString = (queryString: string) =>
  startsWith(queryString, '&') ? queryString.slice(1) : queryString;

export const reduceToQuery = (queryObject: object, prefix?: string) =>
  trimmedQueryString(
    reduceToQueryLoop(flatten(sanitizeObject(queryObject)), prefix)
  );

export const getConfigData = (integrationData: any) =>
  omitBy(
    integrationData,
    (value, key) =>
      key.includes('theme.') ||
      key.includes('credentials.') ||
      ['headerText', 'shouldShowPartnershipHeading', 'id'].includes(key) ||
      !value
  );

export const isConfigChanged = (oldPP: any, newPP: any) => {
  return !isEqual(getConfigData({ ...oldPP, ...newPP }), getConfigData(oldPP));
};

/**
 * Generates the url for an embed based on the environment and config version.
 *
 * @param isProductionEnv If not production environment, "dev-" is appended to the
 * beginning of the generated url
 * @param version The version determines the host of the url: currently a choice
 * between evenfinancial, hifiona, or leaplife
 * @returns The base url of the embed
 */
export const genEmbedBaseUrl = (isProductionEnv: boolean, version: string) => {
  let host;

  switch (version) {
    case '7':
    default:
      host = 'evenfinancial';
      break;
    case '8':
      host = 'hifiona';
      break;
    case '9':
      host = 'leaplife';
      break;
  }

  return `https://${isProductionEnv ? '' : 'dev-'}embed.${host}.com`;
};

/**
 * Generates a complete url for an embed that includes parameters like
 * the partner, access token, product type, etc.
 *
 * @param baseUrl The base url of the embed
 * @param config The form data of the partner key design page
 * @returns The complete url for the embed
 */
export const formToEmbedUrl = (baseUrl: string, config: any): string => {
  const flattened = flatten(config);

  if (flattened.productType) {
    flattened.available_products =
      flattened.productType === ConfigProductType.Deposits
        ? 'deposits'
        : flattened.productType;
  }

  const queryString = reduceToQuery(flattened);

  return `${baseUrl}/ui/multi-product/index.html?${queryString}`;
};

export const genEmbedSnippet = (query: string, baseUrl: string) => {
  const scriptUrl = `${baseUrl}/script/helper/bundle.js`;

  return `<div>
            <script type="text/javascript" src="${scriptUrl}"></script>
            <iframe class="even-iframe" id="iFrameResizer0" scrolling="no" src="${query}" style="width: 100%; border: none;"></iframe>
          </div>`;
};

/**
 * Generates a url for a partner page
 *
 * @param baseUrls An enum containing the different options for the host of the
 * partner page url
 * @param version The version of the partner page, which determines which host
 * is used for the url
 * @param queryString A query that is added to the url that contains various
 * parameters such as product type and theme
 * @param partner The partner that owns the product page
 * @param type The product type being offered on the page
 * @returns The complete partner page url
 */
export const genConsumerUrl = (
  baseUrls: PartnerPageBaseUrls,
  version: string,
  queryString: string,
  partner: string,
  type: string
): string => {
  let host;

  switch (version) {
    case '7':
    default:
      host = baseUrls.evenBaseUrl;
      break;
    case '8':
      host = baseUrls.fionaBaseUrl;
      break;
    case '9':
      host = baseUrls.leaplifeBaseUrl;
      break;
  }

  const base = [
    host,
    partner,
    type.substr(-1) === 's' || type === ConfigProductType.LifeInsurance
      ? kebabCase(type)
      : kebabCase(`${type}s`),
  ].join('/');

  return `${base}?${queryString}`;
};

/**
 * Wrapper function around genConsumerUrl that returns a partner page url
 *
 * @param baseUrls An enum containing the different options for the host of the
 * partner page url
 * @param query The form data of the partner key design page
 * @returns A partner page url
 */
export const formToPartnerPageUrl = (
  baseUrls: PartnerPageBaseUrls,
  query: any
): string => {
  if (!query.partner || !query.productType) {
    return '';
  }

  const type = query.productType;
  const queryString = reduceToQuery(mapCreditCardFlowFromForm(query));

  return genConsumerUrl(
    baseUrls,
    query.version,
    queryString,
    query.partner,
    type
  );
};
