import type { AxiosResponse } from 'axios';
import { uniq } from 'lodash';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import type { ExternalDemandEvent } from '@evenfinancial/finance-client';

import { client } from '@portal/api-client';
import { getPortalError } from '@portal/common';
import { richAccessTokenByUuidRequestAction } from '@portal/store/dist/user';

import { ImportedLeadEventsActionType } from '@/store/imported-lead-events/types';

import * as actions from './actions';

function* getImportedLeadEventsAll() {
  try {
    const { data: response }: AxiosResponse<ExternalDemandEvent[]> = yield call(
      [client, 'get'],
      '/finance/imported-events/lead'
    );

    const accessTokenUuids = uniq(response.map((c) => c.createdByUuid));
    const { data: rats } = yield call(
      [client, 'post'],
      '/auth/rich_access_tokens',
      {
        accessTokenUuids,
      }
    );

    yield put(richAccessTokenByUuidRequestAction.success(rats));

    yield put(
      actions.getImportedLeadEventsAll.success({
        response,
      })
    );
  } catch (err: any) {
    const portalError = getPortalError(err);

    yield put(actions.getImportedLeadEventsAll.failure(portalError));
  }
}

function* watchGetAllRequest() {
  yield takeLatest(
    ImportedLeadEventsActionType.GET_ALL_IMPORTED_LEAD_EVENTS_REQUEST,
    getImportedLeadEventsAll
  );
}

export function* importedLeadEventsSaga() {
  yield all([fork(watchGetAllRequest)]);
}
