import { createSelector } from 'reselect';

import type { PortalSubAccount } from '@portal/api-models';
import { selectAccounts } from '@portal/store/dist/account/selectors';
import { selectDemandSubAccounts } from '@portal/store/dist/subaccount/selectors';
import { getUUIDfromProps } from '@portal/store/dist/utils';

import type { ApplicationState } from '@/store';

export const getSlice = (state: ApplicationState) => state.subAccounts;

export const getApplicationTokenCreateVisible = (state: ApplicationState) =>
  state.subAccounts.applicationTokenCreateVisible;

export const getAvailableScopes = createSelector(
  [getUUIDfromProps, getSlice],
  (subAccountUUID, subs) => subs.viewData[subAccountUUID]?.availableScopes
);

export const getPrimaryApplication = createSelector(
  [getUUIDfromProps, getSlice],
  (subAccountUUID, subs) => subs.viewData[subAccountUUID]?.primaryApplication
);

export const getSupplySubAccounts = createSelector(
  [getSlice],
  (subs) => subs?.supply
);

export const getSubAccountByUUID = createSelector(
  [getUUIDfromProps, getSlice],
  (uuid, subs) => subs?.byUuid?.[uuid]
);

export const getSubAccountCreatedApiTokens = createSelector(
  [getUUIDfromProps, getSlice],
  (uuid, subs) => subs?.viewData?.[uuid]?.createdApiTokens
);

export const getSelectSubAccountParentAccount = createSelector(
  [getSubAccountByUUID, selectAccounts],
  (subAccount, accounts) => {
    if (subAccount) {
      const { accountUuid } = subAccount;

      return accounts.find((account) => account.uuid === accountUuid);
    }
  }
);

export const getHaveAllDemandSubAccountsBeenRequested = createSelector(
  [getSlice],
  (accts) => accts.haveAllDemandSubAccountsBeenRequested
);

export const getDemandCreateVisible = (state: ApplicationState) =>
  state.subAccounts.demandCreateVisible;

/**
 * @deprecated
 * Deprecated in favor of api-client useGetSubAccountsByIdQuery.
 */
export const getDemandById = createSelector(
  selectDemandSubAccounts,
  (demandSlice): Record<number, PortalSubAccount> => {
    return demandSlice.reduce((acc, demand) => {
      acc[demand.id] = demand;
      return acc;
    }, {} as Record<number, PortalSubAccount>);
  }
);
