import { WhitelistType } from '@portal/api-models';

import type { ApplicationState } from '@/store';

export const selectWhitelistType = (
  state: ApplicationState,
  type: WhitelistType
) => state.user.whitelist?.includes(type) || false;

export const selectIsImplementationFeeWaived = (state: ApplicationState) =>
  selectWhitelistType(state, WhitelistType.WaiveImplementationFee);

export const selectIsFirstRolloutOnboarding = (state: ApplicationState) =>
  selectWhitelistType(state, WhitelistType.FirstRolloutOnboarding);

export const selectIsPaid = (state: ApplicationState) =>
  Boolean(state.saas.subscription);
