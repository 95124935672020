import { HYDRATE } from 'next-redux-wrapper';
import type { Reducer } from 'redux';

import type { HydrateAction } from '../actions';
import { reconcileState } from '../utils';

import { TrustedPartnersActionType } from './types';
import type { TrustedPartnersAction, TrustedPartnersState } from './types';

export const initialState: TrustedPartnersState = {
  availableByCompanyUuid: {},
  featuredByCompanyUuid: {},
};

export const reducer: Reducer<
  TrustedPartnersState,
  TrustedPartnersAction | HydrateAction
> = (
  state: TrustedPartnersState = initialState,
  action: TrustedPartnersAction | HydrateAction
) => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...reconcileState(action.payload.trustedPartners, initialState),
      };
    }
    case TrustedPartnersActionType.GET_AVAILABLE_SUCCESS: {
      return {
        ...state,
        availableByCompanyUuid: {
          ...state.availableByCompanyUuid,
          [action.payload.companyUuid]: action.payload.response,
        },
      };
    }
    case TrustedPartnersActionType.GET_FEATURED_SUCCESS:
    case TrustedPartnersActionType.SET_FEATURED_SUCCESS: {
      return {
        ...state,
        featuredByCompanyUuid: {
          ...state.featuredByCompanyUuid,
          [action.payload.companyUuid]: action.payload.response,
        },
      };
    }
    case TrustedPartnersActionType.DELETE_FEATURED_SUCCESS: {
      const rows = state.featuredByCompanyUuid[action.payload.companyUuid];
      const idx = rows.findIndex((x) => x.id === action.payload.id);

      if (idx < 0) {
        return state;
      }

      const updatedRows = [...rows.slice(0, idx), ...rows.slice(idx + 1)];

      return {
        ...state,
        featuredByCompanyUuid: {
          ...state.featuredByCompanyUuid,
          [action.payload.companyUuid]: updatedRows,
        },
      };
    }

    default: {
      return state;
    }
  }
};
