import { ErrorType } from '@portal/api-models';

import type { PurchasePlanForm } from '@/resources/self-service/payment/purchase-plan/purchase-plan-form';
import { findMatchingSaasPlan } from '@/resources/self-service/payment/util-methods';
import type { SaasData } from '@/store/saas/types';
import { EvenDateFormat, formatDate } from '@/utils/date-time-formatters';

export const assembleSubscriptionPaymentRequestBody = (
  data: PurchasePlanForm,
  saasPlanId: number,
  upgradesSaasSubscriptionId?: number
) => {
  return {
    months: data.planSummary.planDuration.valueOf(),
    paymentCadence: data.planSummary.billingCycle,
    saasPlanId,
    startDate: formatDate(new Date(), EvenDateFormat),
    upgradesSaasSubscriptionId,
  };
};

export const assemblePurchasePlanPayload = (
  data: PurchasePlanForm,
  saasData: SaasData,
  waiveImplementationFee: boolean,
  findMigrationPlan: boolean,
  subscriptionId?: number
) => {
  const plan = findMatchingSaasPlan(
    saasData.plans,
    data.paymentAndBillingInformation.tier!,
    data.planSummary.planDuration,
    data.planSummary.billingCycle,
    waiveImplementationFee,
    findMigrationPlan
  );

  if (!plan) {
    throw new Error(ErrorType.saasPlanNotFound);
  }

  return {
    implementationFeePayment:
      !waiveImplementationFee && data.planSummary.saasFeeReason
        ? {
            accessTokenUuid: data.planSummary.accessTokenUuid,
            reason: data.planSummary.saasFeeReason,
          }
        : undefined,
    paymentEmail: data.paymentAndBillingInformation.paymentInformation?.email,
    subscriptionPayment: assembleSubscriptionPaymentRequestBody(
      data,
      plan.id,
      subscriptionId
    ),
  };
};
