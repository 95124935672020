import * as React from 'react';
import { Component, cloneElement } from 'react';

import Modal from 'antd/lib/modal';
import classNames from 'classnames';

export interface ConfirmableActionProps<ChildComponentProps> {
  action: () => void;
  cancelText?: string;
  childOnClick?: keyof ChildComponentProps | 'onClick';
  children: React.ReactElement<ChildComponentProps>;
  className?: string;
  message?: string | React.ReactElement<any> | React.ReactFragment;
  okText?: string;
  title?: string;
}

interface ConfirmableActionState {
  modalVisible: boolean;
}

/**
 * @deprecated Please use <ConfirmableAction /> component from @portal/ui-lib
 */
export default class ConfirmableAction<T> extends Component<
  ConfirmableActionProps<T>,
  ConfirmableActionState
> {
  public static defaultProps = {
    cancelText: 'No',
    okText: 'Yes',
    title: 'Are you sure?',
  };

  constructor(props: ConfirmableActionProps<T>) {
    super(props);
    this.state = { modalVisible: false };
  }

  public render() {
    const {
      message,
      title,
      children,
      childOnClick,
      className,
      okText,
      cancelText,
    } = this.props;

    return (
      <div className={classNames('confirmable-action', className)}>
        {cloneElement(children, {
          [childOnClick || 'onClick']: () =>
            this.setState({ modalVisible: true }),
        })}
        <Modal
          centered
          cancelText={cancelText}
          okText={okText}
          title={<span className="confirmable-action-title">{title}</span>}
          visible={this.state.modalVisible}
          onCancel={() => this.setState({ modalVisible: false })}
          onOk={this.onOk}
        >
          {typeof message === 'string' ? <p>{message}</p> : message}
        </Modal>
      </div>
    );
  }

  private onOk = () => {
    this.props.action();
    this.setState({ modalVisible: false });
  };
}
