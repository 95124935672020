import { put, take, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import type { ActionType } from 'typesafe-actions';

import { ProductType } from '@evenfinancial/finance-client';

import { mapSupplyTemplateProductTypesToProductTypes } from '@portal/common';
import { getDemandApisAction } from '@portal/store/dist/originator/actions';

import { error, loadingEnd, loadingStart } from '../../message/actions';
import * as offerActions from '../../offers/actions';
import { supplyTemplatesRequestAction } from '../../originator/actions';

import * as actions from './actions';

export function* initializeTemplateCenter({
  payload,
}: ActionType<typeof actions.initializeTemplateCenterAction>) {
  const { uuid } = payload;

  yield put(loadingStart());

  try {
    // request supplyTemplate
    yield put(
      supplyTemplatesRequestAction.request({
        query: {},
      })
    );
    const response = yield take(getType(supplyTemplatesRequestAction.success));

    // should have returned just one value
    const template = response.payload.find(
      (template) => template.uuid === uuid
    );

    if (!template) {
      throw new Error(`template not found with uuid: ${uuid}`);
    }

    const [productType] = mapSupplyTemplateProductTypesToProductTypes([
      template.productType,
    ]);

    // put request for offers and demand apis with found productType
    yield put(
      offerActions.staticOfferRequestAction.request({
        productType,
      })
    );

    yield put(
      offerActions.staticOfferRequestAction.request({
        productType: ProductType.Other,
      })
    );

    yield put(getDemandApisAction.request({ productType }));
  } catch (e) {
    yield put(error({}));
  } finally {
    yield put(loadingEnd());
  }
}

export function* initializeTemplateCenterSaga() {
  yield takeLatest(
    getType(actions.initializeTemplateCenterAction),
    initializeTemplateCenter
  );
}
