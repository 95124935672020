import type { PartnerPageAlooma, PartnerPageFull } from '@portal/api-models';

import type { ApplicationState } from '@/store';

export const selectPartnerPageAloomaById = (
  { partnerPages }: ApplicationState,
  id: string
): PartnerPageAlooma | undefined =>
  partnerPages.rows.find((partnerPage) => partnerPage.id === id);

export const selectPartnerPageFullById = (
  { partnerPages }: ApplicationState,
  id: string
): PartnerPageFull | undefined => partnerPages.byPartnerKeyId?.[id];

export const selectMigratedIntegrationKeys = ({
  supplyMigration,
}: ApplicationState): string[] | undefined =>
  supplyMigration?.settings?.integrationKeysToMigrate;

export const selectSupplyMigrationSettings = ({
  supplyMigration,
}: ApplicationState) => supplyMigration?.settings;
