"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountActionType = void 0;
var AccountActionType;
(function (AccountActionType) {
    AccountActionType["BY_UUID_FAILURE"] = "@@account/BY_UUID_FAILURE";
    AccountActionType["BY_UUID_REQUEST"] = "@@account/BY_UUID_REQUEST";
    AccountActionType["BY_UUID_SUCCESS"] = "@@account/BY_UUID_SUCCESS";
    AccountActionType["CREATE_ACCOUNT_DEACTIVATIONS_FAILURE"] = "@@account/CREATE_ACCOUNT_DEACTIVATIONS_FAILURE";
    AccountActionType["CREATE_ACCOUNT_DEACTIVATIONS_REQUEST"] = "@@account/CREATE_ACCOUNT_DEACTIVATIONS_REQUEST";
    AccountActionType["CREATE_ACCOUNT_DEACTIVATIONS_SUCCESS"] = "@@account/CREATE_ACCOUNT_DEACTIVATIONS_SUCCESS";
    AccountActionType["CREATE_FAILURE_CLIENT"] = "@@account/CREATE_FAILURE_CLIENT";
    AccountActionType["CREATE_REQUEST_CLIENT"] = "@@account/CREATE_REQUEST_CLIENT";
    AccountActionType["CREATE_SUCCESS_CLIENT"] = "@@account/CREATE_SUCCESS_CLIENT";
    AccountActionType["DELETE_ACCOUNT_DEACTIVATIONS_FAILURE"] = "@@account/DELETE_ACCOUNT_DEACTIVATIONS_FAILURE";
    AccountActionType["DELETE_ACCOUNT_DEACTIVATIONS_REQUEST"] = "@@account/DELETE_ACCOUNT_DEACTIVATIONS_REQUEST";
    AccountActionType["DELETE_ACCOUNT_DEACTIVATIONS_SUCCESS"] = "@@account/DELETE_ACCOUNT_DEACTIVATIONS_SUCCESS";
    AccountActionType["GET_ALL_DEACTIVATION_FAILURE"] = "@@account/GET_ALL_DEACTIVATION_FAILURE";
    AccountActionType["GET_ALL_DEACTIVATION_REQUEST"] = "@@account/GET_ALL_DEACTIVATION_REQUEST";
    AccountActionType["GET_ALL_DEACTIVATION_SUCCESS"] = "@@account/GET_ALL_DEACTIVATION_SUCCESS";
    AccountActionType["GET_ALL_FAILURE"] = "@@account/GET_ALL_FAILURE";
    AccountActionType["GET_ALL_REQUEST"] = "@@account/GET_ALL_REQUEST";
    AccountActionType["GET_ALL_SELF_SERVICE_FAILURE"] = "@@account/GET_ALL_SELF_SERVICE_FAILURE";
    AccountActionType["GET_ALL_SELF_SERVICE_REQUEST"] = "@@account/GET_ALL_SELF_SERVICE_REQUEST";
    AccountActionType["GET_ALL_SELF_SERVICE_SUCCESS"] = "@@account/GET_ALL_SELF_SERVICE_SUCCESS";
    AccountActionType["GET_ALL_SUCCESS"] = "@@account/GET_ALL_SUCCESS";
    AccountActionType["TOGGLE_CREATE"] = "@@account/TOGGLE_CREATE";
    AccountActionType["TOGGLE_DUPLICATE"] = "@@account/TOGGLE_DUPLICATE";
    AccountActionType["UPDATE_FAILURE_CLIENT"] = "@@account/UPDATE_FAILURE_CLIENT";
    AccountActionType["UPDATE_PAYOUT_SETTINGS_FAILURE_CLIENT"] = "@@account/UPDATE_PAYOUT_SETTINGS_FAILURE_CLIENT";
    AccountActionType["UPDATE_PAYOUT_SETTINGS_REQUEST_CLIENT"] = "@@account/UPDATE_PAYOUT_SETTINGS_REQUEST_CLIENT";
    AccountActionType["UPDATE_PAYOUT_SETTINGS_SUCCESS_CLIENT"] = "@@account/UPDATE_PAYOUT_SETTINGS_SUCCESS_CLIENT";
    AccountActionType["UPDATE_REQUEST_CLIENT"] = "@@account/UPDATE_REQUEST_CLIENT";
    AccountActionType["UPDATE_SUCCESS_CLIENT"] = "@@account/UPDATE_SUCCESS_CLIENT";
})(AccountActionType = exports.AccountActionType || (exports.AccountActionType = {}));
