export interface StringArrayStats {
  count: number;
  longest: number;
}

/**
 * Given an array of strings, return stats such as longest string, total
 * string count, etc.
 *
 * @param {string[]} strings
 * @returns {StringStats}
 */
export default function stringArrayStats(strings: string[]): StringArrayStats {
  const defaultStats = {
    count: 0,
    longest: 0,
  };

  return strings.reduce(
    (stats, string) => ({
      count: stats.count + string.length,
      longest: Math.max(stats.longest, string.length),
    }),
    defaultStats
  );
}
