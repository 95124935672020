import type { ReactElement } from 'react';
import * as React from 'react';

import { Col, Row } from 'antd';
import type { ColProps } from 'antd/lib/grid';
import classNames from 'classnames';
import startCase from 'lodash/startCase';

import { useForm } from '@/components/form/portal-form';
import PortalFormError from '@/components/form/portal-form/components/form-error';
import PortalFormFieldElement from '@/components/form/portal-form/components/form-field-element';
import FieldTooltip from '@/components/form/portal-form/components/tooltip';
import {
  LabelPosition,
  PortalFormFieldType,
  TooltipPosition,
} from '@/components/form/portal-form/types';
import type { PortalFormField } from '@/components/form/portal-form/types';
import Overlay from '@/components/overlay';
import type { OverlayProps } from '@/components/overlay';

export enum Layout {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

interface PortalFormFieldItemProps<T extends object> {
  className?: string;
  field: PortalFormField<T>;
  fieldCol?: ColProps;
  labelCol?: ColProps;
  layout?: Layout;
}

export const fieldLabel = (fieldName: string | JSX.Element) => (
  <div className="field-label-wrapper">
    <span className="field-label">{fieldName}</span>
  </div>
);

const TooltipTop: React.FC<
  React.PropsWithChildren<PortalFormFieldItemProps<any>>
> = ({ field }) => (
  <FieldTooltip
    field={field}
    position={TooltipPosition.Top}
    showSmallTooltipIcon={field?.showSmallTooltipIcon ?? false}
  />
);

const TooltipRight: React.FC<
  React.PropsWithChildren<PortalFormFieldItemProps<any>>
> = ({ field }) => (
  <FieldTooltip
    field={field}
    position={TooltipPosition.Right}
    showSmallTooltipIcon={field?.showSmallTooltipIcon ?? false}
  />
);

const MaybeOverlay: React.FC<
  React.PropsWithChildren<{ props?: OverlayProps }>
> = ({ props: fieldOverlay }) => {
  return fieldOverlay ? (
    <Overlay
      className={fieldOverlay.className}
      style={fieldOverlay.style}
      onClick={fieldOverlay.onClick}
    />
  ) : null;
};

export interface LabelElementProps {
  field: PortalFormField<any>;
  label: JSX.Element | string;
  labelPosition: LabelPosition;
  showLabel: boolean;
}

export interface FieldElementProps extends LabelElementProps {
  error: string | null;
  extra?: string | JSX.Element;
  fieldOverlay?: OverlayProps;
}

const LabelElement: React.FC<React.PropsWithChildren<LabelElementProps>> = ({
  showLabel,
  labelPosition,
  field,
  label,
}) => (
  <>
    {showLabel && labelPosition === LabelPosition.Top && (
      <div
        className={classNames('portal-form-field-label', {
          'portal-form-field-label-inline': field.inlineLabel,
        })}
      >
        <label>{label}</label>
        <TooltipTop field={field} />
      </div>
    )}
  </>
);

const FieldElement: React.FC<React.PropsWithChildren<FieldElementProps>> = ({
  showLabel,
  labelPosition,
  field,
  label,
  fieldOverlay,
  error,
  extra,
}) => (
  <>
    <div className="portal-form-field-element">
      {showLabel && labelPosition === LabelPosition.Left && fieldLabel(label)}
      <PortalFormFieldElement field={field} />
      <MaybeOverlay props={fieldOverlay} />
      <TooltipRight field={field} />
    </div>
    {extra && <p className="portal-form-field-extra">{extra}</p>}
    <PortalFormError error={error} />
  </>
);

export interface MaybeColumnsProps extends React.PropsWithChildren<{}> {
  fieldCol?: ColProps;
  labelCol?: ColProps;
  layout: Layout;
}

const MaybeColumns: React.FC<MaybeColumnsProps> = ({
  layout,
  children,
  labelCol,
  fieldCol,
}) => {
  const defaultLabelCol = {
    sm: { span: 24 },
    xl: { span: 7 },
  };

  const defaultFieldCol = {
    sm: { span: 24 },
    xl: { span: 15 },
  };

  const [label, field] = React.Children.toArray(children);

  return layout === Layout.VERTICAL ? (
    <>
      {label}
      {field}
    </>
  ) : (
    <Row justify="space-between">
      <Col {...{ ...defaultLabelCol, ...labelCol }}>{label}</Col>
      <Col {...{ ...defaultFieldCol, ...fieldCol }}>{field}</Col>
    </Row>
  );
};

const PortalFormFieldItem = <T extends {}>(
  props: PortalFormFieldItemProps<T>
): ReactElement => {
  const {
    className,
    field,
    layout = Layout.VERTICAL,
    labelCol,
    fieldCol,
  } = props;
  const { getError } = useForm();

  if (field.type === PortalFormFieldType.Element) {
    return (
      <div
        className={classNames(
          'portal-form-field',
          field.fieldClassName,
          field.size && `column-${field.size}`
        )}
      >
        {field.element}
      </div>
    );
  }

  const {
    inlineLabel = false,
    showLabel = true,
    labelPosition = LabelPosition.Top,
    size = 12,
    fieldClassName,
    extra,
    fieldOverlay,
  } = field;

  const error = getError(field.name);
  const label = field.label ?? startCase(field.name ?? '');

  return (
    <div
      className={classNames(
        `portal-form-field column-${size}`,
        className,
        fieldClassName,
        { 'portal-form-field-inline': inlineLabel }
      )}
    >
      <MaybeColumns {...{ fieldCol, labelCol, layout }}>
        <LabelElement {...{ field, label, labelPosition, showLabel }} />
        <FieldElement
          {...{
            error,
            extra,
            field,
            fieldOverlay,
            label,
            labelPosition,
            showLabel,
          }}
        />
      </MaybeColumns>
    </div>
  );
};

export default PortalFormFieldItem;
