"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.demandCCMVPConfig = void 0;
exports.demandCCMVPConfig = {
    hasDemandCCMVPFeatureFlag: true,
    shouldRedirectIndexEntryPointToHomePage: true,
    showBenchmarkReportsCard: true,
    showContractsCardFeatureFlag: true,
    showDemandUserSideNavFeatureFlag: true,
    showDemandUsersPageFeatureFlag: true,
    showExternalUserSideNavFeatureFlag: true,
    showMCIReportsCard: true,
    showPerformanceReportCard: true,
};
