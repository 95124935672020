"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplyFlatRolesForMulti = exports.DemandFlatRolesForMulti = void 0;
var DemandFlatRolesForMulti;
(function (DemandFlatRolesForMulti) {
    DemandFlatRolesForMulti["Demand"] = "Demand";
})(DemandFlatRolesForMulti = exports.DemandFlatRolesForMulti || (exports.DemandFlatRolesForMulti = {}));
var SupplyFlatRolesForMulti;
(function (SupplyFlatRolesForMulti) {
    SupplyFlatRolesForMulti["Scale"] = "Scale";
})(SupplyFlatRolesForMulti = exports.SupplyFlatRolesForMulti || (exports.SupplyFlatRolesForMulti = {}));
