import { parseArgs } from '@/utils/logger/parser';
import type { Transport } from '@/utils/logger/transport';

interface LoggerOptions {
  name: string;
  transports: Transport[];
}

export enum LogLevel {
  Debug = 'debug',
  Error = 'error',
  Info = 'info',
  Warn = 'warn',
}

export class Logger {
  private options: LoggerOptions;

  constructor(options: LoggerOptions) {
    this.options = {
      name: options?.name ?? '',
      transports: options?.transports ?? [],
    };
  }

  public info(...args: unknown[]) {
    const { name, transports } = this.options;

    transports.forEach((transport) =>
      transport.info(parseArgs(name, LogLevel.Info, args))
    );
  }

  public debug(...args: unknown[]) {
    const { name, transports } = this.options;

    transports.forEach((transport) =>
      transport.debug(parseArgs(name, LogLevel.Debug, args))
    );
  }

  public warn(...args: unknown[]) {
    const { name, transports } = this.options;

    transports.forEach((transport) =>
      transport.warn(parseArgs(name, LogLevel.Warn, args))
    );
  }

  public error(...args: unknown[]) {
    const { name, transports } = this.options;

    transports.forEach((transport) =>
      transport.error(parseArgs(name, LogLevel.Error, args))
    );
  }
}
