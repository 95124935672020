/* eslint-disable no-use-before-define */
import type * as React from 'react';

import type { CheckboxProps } from 'antd/lib/checkbox';
import type { DatePickerProps, RangePickerProps } from 'antd/lib/date-picker';
import type { InputProps, PasswordProps, TextAreaProps } from 'antd/lib/input';
import type { InputNumberProps } from 'antd/lib/input-number';
import type { SelectProps, SelectValue } from 'antd/lib/select';
import type { SwitchProps } from 'antd/lib/switch';

import type { AccountDropdownProvidedProps } from '@/components/form/account-dropdown';
import type { CustomProps } from '@/components/form/portal-form/fields/custom';
import type { HexColorInputProvidedProps } from '@/components/form/portal-form/fields/hex-color-input';
import type { InputRangeProps } from '@/components/form/portal-form/fields/input-range';
import type { RadioGroupProvidedProps } from '@/components/form/portal-form/fields/radio-group';
import type { RepeatableProps } from '@/components/form/portal-form/fields/repeatable';
import type { UploadFormFieldProvidedProps } from '@/components/form/portal-form/fields/upload';
import type { SubAccountDropdownProvidedProps } from '@/components/form/sub-account-dropdown';
import type { SubAccountProductTypeDropdownProvidedProps } from '@/components/form/sub-account-product-type-dropdown';
import type {
  SubAccountOfferCatalogProductTypeDropdown,
  SubAccountOfferCatalogProductTypeDropdownProvidedProps,
} from '@/components/form/sub-account-product-type-dropdown/offer-catalog';
import type { UsStateDropdownProvidedProps } from '@/components/form/us-state-dropdown';
import type { UserDropdownProvidedProps } from '@/components/form/user-dropdown';
import type { OverlayProps } from '@/components/overlay';

export type PredicateOrClause<FormType extends object> = {
  and?: undefined;
  or: Array<
    | PredicateOrClause<FormType>
    | PredicateAndClause<FormType>
    | PredicateResolver<FormType>
    | boolean
  >;
};

export type PredicateAndClause<FormType extends object> = {
  and: Array<
    | PredicateOrClause<FormType>
    | PredicateAndClause<FormType>
    | PredicateResolver<FormType>
    | boolean
  >;
  or?: undefined;
};

export type PredicateResolver<FormType extends object> = (
  form: FormType
) => boolean;

export type Predicate<FormType extends object> =
  | PredicateOrClause<FormType>
  | PredicateAndClause<FormType>
  | PredicateResolver<FormType>
  | boolean;

export type CustomValidator<FormType extends object> = {
  message?: string;
  validator:
    | ((value: any, formValues: FormType) => boolean)
    | ((value: any, formValues: FormType) => Promise<boolean>);
};

export type RequiredValidator = {
  message?: string;
  required: boolean;
};

export type Validator<FormType extends object> =
  | RequiredValidator
  | CustomValidator<FormType>;

export type Option<OptionType = any, FormType extends object = {}> = {
  disabled?: boolean;
  label: React.ReactFragment;
  predicate?: Predicate<FormType>;
  value: OptionType;
};

export type Options<OptionType = any, FormType extends object = {}> = Array<
  Option<OptionType, FormType>
>;

export type OptionsGroup<OptionType = any, FormType extends object = {}> = {
  key: string;
  label: string;
  options: Options<OptionType, FormType>;
  predicate?: Predicate<FormType>;
};

export type OptionOrGroup<FormType extends {}, OptionType = any> =
  | Option<OptionType, FormType>
  | OptionsGroup<OptionType, FormType>;

/**
 * See README.md file (link below) for detailed steps on how to go about
 * registering a new field.
 *
 * Also, be sure to document your new type in the README.md file as well.
 *
 * @link https://github.com/EVENFinancial/portal/tree/master/portal-app/components/form/portal-form
 */
export enum PortalFormFieldType {
  Element,
  Custom,
  Input,
  InputNumber,
  InputRange,
  InputPassword,
  Checkbox,
  RadioGroup,
  Select,
  TextArea,
  DatePicker,
  DateRangePicker,
  Switch,
  Repeatable,
  UserDropdown,
  UsStateDropdown,
  SubAccountDropdown,
  AccountDropdown,
  SubAccountOfferCatalogProductTypeDropdown,
  SubAccountProductTypeDropdown,
  Upload,
  HexColorInput,
}

export enum LabelPosition {
  Left,
  Top,
}

export enum TooltipPosition {
  Right = 'right',
  Top = 'top',
}

/**
 * See README.md file (link below) for more information on each property.
 *
 * @link https://github.com/EVENFinancial/portal/tree/master/portal-app/components/form/portal-form
 */
type Field<
  FormType extends object,
  Type extends PortalFormFieldType,
  ExtendedFieldProps = {}
> = {
  extra?: string | JSX.Element;
  fieldClassName?: string;
  fieldKey?: string;
  fieldOverlay?: OverlayProps;
  fieldProps?: ExtendedFieldProps & {
    'data-recurly'?: string;
    'data-testid'?: string;
    disabled?: boolean;
  };
  inlineLabel?: boolean;
  label?: string | JSX.Element;
  labelPosition?: LabelPosition;
  name: string;
  predicate?: Predicate<FormType>;
  showLabel?: boolean;
  showSmallTooltipIcon?: boolean;
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  tooltip?: string;
  tooltipPosition?: TooltipPosition;
  type: Type;
  validation?: Array<Validator<FormType>>;
};

type NonInputField<
  FormType extends object = {},
  Type extends PortalFormFieldType = PortalFormFieldType,
  Props extends object = {}
> = {
  [P in keyof Omit<
    Field<FormType, Type, any>,
    'type' | 'predicate' | 'size' | 'fieldClassName'
  >]?: null;
} & Pick<
  Field<FormType, Type, any>,
  'type' | 'predicate' | 'size' | 'fieldClassName'
> & {
    key: string;
  } & Props;

export type ElementField<FormType extends object> = NonInputField<
  FormType,
  PortalFormFieldType.Element,
  {
    element: React.ReactElement;
  }
>;

export type CustomField<
  FormType extends object,
  ValueType,
  ComponentProps = {}
> = Field<
  FormType,
  PortalFormFieldType.Custom,
  CustomProps<ValueType, ComponentProps>
>;

export type InputField<FormType extends object = any> = Field<
  FormType,
  PortalFormFieldType.Input,
  InputProps & { 'data-qa'?: string }
>;

export type InputNumberField<FormType extends object = any> = Field<
  FormType,
  PortalFormFieldType.InputNumber,
  InputNumberProps
>;

export type InputRangeField<FormType extends object = any> = Field<
  FormType,
  PortalFormFieldType.InputRange,
  InputRangeProps
>;

export type InputPasswordField<FormType extends object = any> = Field<
  FormType,
  PortalFormFieldType.InputPassword,
  PasswordProps
>;

export type CheckboxField<FormType extends object = any> = Omit<
  Field<FormType, PortalFormFieldType.Checkbox, CheckboxProps>,
  'tooltipPosition'
>;

export type SelectFieldProps<
  FormType extends object = any,
  OptionType = any
> = Omit<SelectProps<SelectValue>, 'onChange' | 'options' | 'form'> & {
  form?: {
    values: FormType;
  };
  /** A list of form keys that when changed, will trigger Select to update. */
  formDeps?: (keyof FormType)[];
  onChange?: (value: SelectValue) => void;
  options?: Array<
    Option<OptionType, FormType> | OptionsGroup<OptionType, FormType>
  >;
};

export type SelectField<
  FormType extends object = any,
  OptionType = any
> = Field<
  FormType,
  PortalFormFieldType.Select,
  SelectFieldProps<FormType, OptionType>
>;

export type RadioGroup<FormType extends object = any> = Field<
  FormType,
  PortalFormFieldType.RadioGroup,
  RadioGroupProvidedProps
>;

export type TextAreaField<FormType extends object = any> = Field<
  FormType,
  PortalFormFieldType.TextArea,
  TextAreaProps
>;

export type DatePickerField<FormType extends object> = Field<
  FormType,
  PortalFormFieldType.DatePicker,
  Partial<DatePickerProps>
>;

export type DateRangePickerField<FormType extends object> = Field<
  FormType,
  PortalFormFieldType.DateRangePicker,
  Partial<RangePickerProps>
>;

export type SwitchField<FormType extends object> = Field<
  FormType,
  PortalFormFieldType.Switch,
  SwitchProps & React.RefAttributes<HTMLElement>
>;

export type RepeatableField<
  FormType extends object,
  ItemType,
  ItemProps
> = Field<
  FormType,
  PortalFormFieldType.Repeatable,
  RepeatableProps<ItemType, ItemProps>
>;

export type UserDropdownField<FormType extends object> = Field<
  FormType,
  PortalFormFieldType.UserDropdown,
  UserDropdownProvidedProps
>;

export type UsStateDropdownField<FormType extends object> = Field<
  FormType,
  PortalFormFieldType.UsStateDropdown,
  UsStateDropdownProvidedProps
>;

export type SubAccountDropdownField<FormType extends object> = Field<
  FormType,
  PortalFormFieldType.SubAccountDropdown,
  SubAccountDropdownProvidedProps
>;

export type AccountDropdownField<FormType extends object> = Field<
  FormType,
  PortalFormFieldType.AccountDropdown,
  AccountDropdownProvidedProps
>;

export type SubAccountOfferCatalogProductTypeDropdown<FormType extends object> =
  Field<
    FormType,
    PortalFormFieldType.SubAccountOfferCatalogProductTypeDropdown,
    SubAccountOfferCatalogProductTypeDropdownProvidedProps
  >;

export type SubAccountProductTypeDropdownField<FormType extends object> = Field<
  FormType,
  PortalFormFieldType.SubAccountProductTypeDropdown,
  SubAccountProductTypeDropdownProvidedProps
>;

export type UploadFormField<FormType extends object> = Field<
  FormType,
  PortalFormFieldType.Upload,
  UploadFormFieldProvidedProps
>;

export type HexColorInputField<FormType extends object> = Field<
  FormType,
  PortalFormFieldType.HexColorInput,
  HexColorInputProvidedProps
>;

/**
 * Add your field type union here.
 */
export type PortalFormField<FormType extends object, Arg1 = any, Arg2 = any> =
  | ElementField<FormType>
  | CustomField<FormType, Arg1, Arg2>
  | InputField<FormType>
  | InputNumberField<FormType>
  | InputRangeField<FormType>
  | InputPasswordField<FormType>
  | CheckboxField<FormType>
  | TextAreaField<FormType>
  | SelectField<FormType>
  | RadioGroup<FormType>
  | DatePickerField<FormType>
  | DateRangePickerField<FormType>
  | SwitchField<FormType>
  | RepeatableField<FormType, Arg1, Arg2>
  | UserDropdownField<FormType>
  | UsStateDropdownField<FormType>
  | SubAccountDropdownField<FormType>
  | AccountDropdownField<FormType>
  | SubAccountOfferCatalogProductTypeDropdown<FormType>
  | SubAccountProductTypeDropdownField<FormType>
  | UploadFormField<FormType>
  | HexColorInputField<FormType>;

export type ErrorMap = Record<string, string | null>;

export type ValidationResult<FormType extends object> = {
  errorCount: number;
  errorMap: ErrorMap;
  values: FormType;
};

export type PortalFormFieldImportMap<ComponentType = any> = {
  [K in Exclude<
    PortalFormFieldType,
    PortalFormFieldType.Element
  >]: React.ComponentType<ComponentType>;
};

export type FieldListErrorCallback = (payload: {
  errorCount: number;
  errorMap: ErrorMap;
}) => void;

export type FieldComponentErrorCallback = (message: string) => void;

export type FieldComponentProps<ValueType> = {
  /**
   * Allows a field component to receive a default value.
   * It is up to the field component to handle this value.
   */
  defaultValue?: ValueType;
  /**
   * Allows <PortalFormFieldList /> to be made aware of input changes within a field component.
   * It is up to the field component to handle this value.
   */
  onChange?: (value: ValueType, ...args: any[]) => void;
  /**
   * Allows a field to become controllable.
   */
  value?: ValueType;
};

/**
 * @deprecated Please use the FieldComponentProps directly
 * @example
 * interface MyProps extends FieldComponentProps<AccountTierType> {...myprops}
 * const MyComponent: React.FC<MyProps> = ({}) => {}
 */
export type FieldComponent<ValueType, Props = {}> = React.FC<
  FieldComponentProps<ValueType> & Props
>;

export enum CallbackType {
  OnChange = 'onChange',
  OnError = 'onError',
  OnErrorCount = 'onErrorCount',
  OnValidate = 'onValidate',
}

export type OnChange<T extends {}> = (payload: {
  changedPath: string;
  changedValue: any;
  validate: boolean;
  values: T;
}) => void;

export type OnValidate = () => Promise<void> | void;

export type OnError = (payload: {
  errorCount: number;
  errorMap: ErrorMap;
}) => void;

export type OnErrorCount = () => number | undefined;

export type CallbacksMap<T extends {}> = {
  [CallbackType.OnChange]: OnChange<T>[];
  [CallbackType.OnValidate]: OnValidate[];
  [CallbackType.OnError]: OnError[];
  [CallbackType.OnErrorCount]: OnErrorCount[];
};
