"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.signedOnboardingContractSave = exports.onboardingContractsSave = exports.signOnboardingContractsAction = exports.getSignedOnboardingContractsAction = exports.getAllOnboardingContractsAction = void 0;
var typesafe_actions_1 = require("typesafe-actions");
var actionTypes_1 = require("./actionTypes");
exports.getAllOnboardingContractsAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.OnboardingContractActionType.GET_ALL_ONBOARDING_CONTRACTS_REQUEST, actionTypes_1.OnboardingContractActionType.GET_ALL_ONBOARDING_CONTRACTS_SUCCESS, actionTypes_1.OnboardingContractActionType.GET_ALL_ONBOARDING_CONTRACTS_FAILURE)();
exports.getSignedOnboardingContractsAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.OnboardingContractActionType.GET_SIGNED_ONBOARDING_CONTRACTS_REQUEST, actionTypes_1.OnboardingContractActionType.GET_SIGNED_ONBOARDING_CONTRACTS_SUCCESS, actionTypes_1.OnboardingContractActionType.GET_SIGNED_ONBOARDING_CONTRACTS_FAILURE)();
exports.signOnboardingContractsAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.OnboardingContractActionType.SIGN_ONBOARDING_CONTRACT_REQUEST_CLIENT, actionTypes_1.OnboardingContractActionType.SIGN_ONBOARDING_CONTRACT_SUCCESS_CLIENT, actionTypes_1.OnboardingContractActionType.SIGN_ONBOARDING_CONTRACT_FAILURE_CLIENT)();
var onboardingContractsSave = function (onboardingContracts) {
    return (0, typesafe_actions_1.action)((0, typesafe_actions_1.getType)(exports.getAllOnboardingContractsAction.success), onboardingContracts);
};
exports.onboardingContractsSave = onboardingContractsSave;
var signedOnboardingContractSave = function (onboardingContractsSignature) {
    return (0, typesafe_actions_1.action)((0, typesafe_actions_1.getType)(exports.getSignedOnboardingContractsAction.success), onboardingContractsSignature);
};
exports.signedOnboardingContractSave = signedOnboardingContractSave;
