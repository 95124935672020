import type { UserClaims } from '@okta/okta-auth-js';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import type {
  OnboardingContract,
  OnboardingContractSignature,
  SaasTrial,
} from '@evenfinancial/finance-client';

import {
  client,
  getFinanceAccounts,
  getOnboardingContractSignatures,
  getOnboardingContracts,
  getProfileMeta,
  getSaasMigrationSettings,
  getSaasSubscriptionFull,
  getSaasUserTrial,
} from '@portal/api-client';
import type {
  Experience,
  PortalSaasMigrationSetting,
  ProfileMeta,
  SaasSubscriptionFull,
  TempAccount,
} from '@portal/api-models';
import type { EngineUserClaims } from '@portal/common';
import type { PermissionConfig } from '@portal/common/dist/access-control';
import { setClaims, setProfileMeta } from '@portal/store/dist';
import { accountsSave } from '@portal/store/dist/account/actions';
import {
  onboardingContractsSave,
  signedOnboardingContractSave,
} from '@portal/store/dist/onboarding-contract/actions';
import { selectPermissions } from '@portal/store/dist/user/selectors';

import type { ApplicationState } from '..';
import { Router } from '../../routes';
import { saasSubscriptionSave, saasUserTrialSave } from '../saas/actions';
import type { SaasState } from '../saas/types';
import { saasMigrationSettingsByAuthContextSave } from '../supply-migration/actions';

import { AuthenticationActionType } from './actionTypes';
import { authenticationCallbackDoneAction } from './actions';
import type { authenticationCallbackAction, setExperience } from './actions';
import {
  getPreferredExperience,
  isMultiFromAccountRoles,
  setPreferredExperience,
} from './helpers';

const setBearerTokenHeader = (token: string) => {
  client.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const setExperienceHeader = (experience: Experience) => {
  client.defaults.headers.common.experience = experience;
};

/**
 * Saga to be executed when a Supply+Demand User changes their experience.
 */
export function* setExperienceSaga({
  payload: selectedExperience,
}: ReturnType<typeof setExperience>) {
  try {
    const { accountUuid }: UserClaims<EngineUserClaims> = yield select(
      (state) => state.user.claims
    );

    const localStorageValue: Experience | null = yield call(
      getPreferredExperience,
      accountUuid
    );

    if (localStorageValue === selectedExperience) {
      return;
    }

    yield call(setPreferredExperience, accountUuid, selectedExperience);
    yield call(setExperienceHeader, selectedExperience);

    const profileMeta: ProfileMeta = yield call(getProfileMeta);

    yield put(setProfileMeta(profileMeta));

    return Router.pushRoute('/');
  } catch {
    return Router.pushRoute('/login-error');
  }
}

/**
 * Saga to be executed after authentication on each page load.
 */
export function* authenticationCallbackSaga({
  payload: { claims, accessToken },
}: ReturnType<typeof authenticationCallbackAction>) {
  try {
    const { accountUuid, accountRoles } = claims;

    const isMulti: boolean = yield call(isMultiFromAccountRoles, accountRoles);

    const preferredExperience: Experience = yield call(
      getPreferredExperience,
      accountUuid
    );

    if (isMulti) {
      yield call(setExperienceHeader, preferredExperience);
    }

    yield call(setBearerTokenHeader, accessToken);

    const profileMeta: ProfileMeta = yield call(getProfileMeta);

    const {
      supplyMigration: { isForcedToUpgrade },
      engineUser,
    } = profileMeta;

    if (engineUser.deactivatedAt) {
      return Router.pushRoute('/deactivated');
    }

    yield put(setClaims(claims));
    yield put(setProfileMeta(profileMeta));

    const { hasTrialBeenRequested, hasSubscriptionBeenRequested }: SaasState =
      yield select((state: ApplicationState) => state.saas);

    const {
      canRequestAccounts,
      canRequestPerformanceSummary,
      canRequestSaasSubscription,
      canRequestSelfServiceData,
      canRequestUserTrial,
      shouldRedirectToSupplyMigration,
    }: PermissionConfig = yield select(selectPermissions);

    if (!hasTrialBeenRequested && canRequestUserTrial) {
      const saasTrial: SaasTrial = yield call(getSaasUserTrial);

      yield put(saasUserTrialSave(saasTrial));
    }

    if (!hasSubscriptionBeenRequested && canRequestSaasSubscription) {
      const saasSubscriptionData: SaasSubscriptionFull = yield call(
        getSaasSubscriptionFull
      );

      yield put(saasSubscriptionSave(saasSubscriptionData));
    }

    if (canRequestSelfServiceData) {
      const [settings, onboardingContracts, onboardingContractsSignatures]: [
        PortalSaasMigrationSetting,
        OnboardingContract[],
        OnboardingContractSignature[]
      ] = yield all([
        call(getSaasMigrationSettings),
        call(getOnboardingContracts),
        call(getOnboardingContractSignatures),
      ]);

      yield put(saasMigrationSettingsByAuthContextSave(settings));
      yield put(onboardingContractsSave(onboardingContracts));
      yield put(signedOnboardingContractSave(onboardingContractsSignatures));
    }

    if (canRequestAccounts) {
      const accounts: TempAccount[] = yield call(getFinanceAccounts);

      yield put(accountsSave(accounts));
    }

    if (
      !Router.pathname.includes('/supply-migration') &&
      shouldRedirectToSupplyMigration &&
      isForcedToUpgrade
    ) {
      return Router.pushRoute('/supply-migration');
    }

    if (
      !Router.pathname.includes('/performance-summary') &&
      canRequestPerformanceSummary
    ) {
      return Router.pushRoute('/performance-summary');
    }

    yield put(authenticationCallbackDoneAction());
  } catch {
    return Router.pushRoute('/login-error');
  }
}

export function* authenticationSaga() {
  yield takeEvery(
    AuthenticationActionType.AUTHENTICATION_CALLBACK,
    authenticationCallbackSaga
  );

  yield takeEvery(AuthenticationActionType.SET_EXPERIENCE, setExperienceSaga);
}
