import { Experience } from '@portal/api-models';
import type { Roles } from '@portal/api-models';
import {
  getSubRoleObjects,
  mapEvenRolesToRoles,
} from '@portal/common/dist/access-control';

const PREFERRED_EXPERIENCE_KEY = 'cc-preferred-experience';

export const getLocalStorageMap = <T1, T2>(key: string) => {
  try {
    const mapJson = localStorage.getItem(key);
    const iterable = mapJson ? JSON.parse(mapJson) : [];

    return new Map<T1, T2>(iterable);
  } catch {
    // Return new map if there is trouble parsing existing local storage value
    return new Map<T1, T2>();
  }
};

export const setLocalStorageMap = <T1, T2>(key: string, map: Map<T1, T2>) => {
  localStorage.setItem(key, JSON.stringify(Array.from(map.entries())));
};

export const getPreferredExperience = (accountUuid: string) => {
  const preferredExperienceMap = getLocalStorageMap<string, Experience>(
    PREFERRED_EXPERIENCE_KEY
  );

  const preferredExperience = preferredExperienceMap.get(accountUuid);

  if (preferredExperience) {
    return preferredExperience;
  }

  return Experience.Supply;
};

export const setPreferredExperience = (
  accountUuid: string,
  experience: Experience
) => {
  const preferredExperienceMap = getLocalStorageMap<string, Experience>(
    PREFERRED_EXPERIENCE_KEY
  );

  preferredExperienceMap.set(accountUuid, experience);
  setLocalStorageMap(PREFERRED_EXPERIENCE_KEY, preferredExperienceMap);
};

export const isMultiFromAccountRoles = (accountRoles: string[]) => {
  const { demandRoleObject, supplyRoleObject } = getSubRoleObjects(
    mapEvenRolesToRoles(accountRoles) as Roles[]
  );

  return Boolean(demandRoleObject) && Boolean(supplyRoleObject);
};
