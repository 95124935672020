import type { ColumnFilterItem } from 'antd/lib/table/interface';
import { chain, get } from 'lodash';
import type { PropertyPath } from 'lodash';
import flattenDeep from 'lodash/flattenDeep';
import includes from 'lodash/includes';
import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import toString from 'lodash/toString';
import union from 'lodash/union';
import moment from 'moment';

import type {
  PayoutClause,
  RevenueClause,
} from '@evenfinancial/finance-client';
import type { ProvidedCreditRating } from '@evenfinancial/lead-client';

import type {
  PortalDemandContract,
  PortalSupplyContract,
} from '@portal/api-models';
import {
  getRevenueClauseTypeDisplayText,
  moneyFormatter,
  payoutClauseToProductTypeMap,
} from '@portal/common';
import { createMemoizedSerialize } from '@portal/common/dist/utils';

import type { OfferRuleRow } from '@/resources/offer-management/offer-rules/selectors';

/**
 * @deprecated Please user sorter() from @portal/common/dist/utils/sorting
 */
export const sorter = (a: any, b: any) => {
  return a > b ? 1 : a < b ? -1 : 0;
};

export const sorterString = (a: any, b: any) => sorter(toLower(a), toLower(b));

export const sorterNumeric = (a: any, b: any) =>
  sorter(a ? Number(a) : 0, b ? Number(b) : 0);

export const find = (
  haystack: string,
  needle: string,
  exact: boolean = false
) => {
  const haystackLower = toLower(haystack);
  const needleLower = toLower(needle);

  return exact
    ? haystackLower === needleLower
    : haystackLower.includes(needleLower);
};

export const sorterForKey =
  <T>(path: PropertyPath) =>
  (recordA: T, recordB: T) => {
    const recordAValue = get(recordA, path);
    const recordBValue = get(recordB, path);

    if (typeof recordAValue === 'string') {
      return sorterString(recordAValue, recordBValue);
    }

    if (Array.isArray(recordAValue)) {
      const result = sorter(recordAValue[0] || '', recordBValue[0] || '');

      return result === 0 ? sorter(recordAValue[1], recordBValue[1]) : result;
    }

    return sorter(recordAValue || '', recordBValue || '');
  };

export const sorterForArrayKey =
  <T>(path: PropertyPath, fallbackPath?: PropertyPath) =>
  (recordA: T, recordB: T) => {
    // "@" symbol ensures that missing data is grouped together as opposed to
    // being mixed with repeating array items.
    const itemA = (get(recordA, path) as any)?.sort()?.[0] ?? '@';
    const itemB = (get(recordB, path) as any)?.sort()?.[0] ?? '@';

    let a: string;
    let b: string;

    if (fallbackPath) {
      a = `${itemA} ${get(recordA, fallbackPath)}`;
      b = `${itemB} ${get(recordB, fallbackPath)}`;
    } else {
      a = itemA;
      b = itemB;
    }

    return a > b ? 1 : -1;
  };

export const sorterForNumericKey =
  <T>(path: PropertyPath) =>
  (recordA: T, recordB: T) =>
    sorterNumeric(get(recordA, path), get(recordB, path));

// both parameters must be same type of data (i.e., end date and start date or first name and last name)
export const sorterByTwoParametersKey =
  <T>(primarySortPath: PropertyPath, additionalSortPath: PropertyPath) =>
  (recordA: T, recordB: T) => {
    const result = sorter(
      get(recordA, primarySortPath) || '',
      get(recordB, primarySortPath) || ''
    );

    return result === 0
      ? sorter(
          get(recordA, additionalSortPath) || '',
          get(recordB, additionalSortPath) || ''
        )
      : result;
  };

export const mapUniqueFilters = <T>(
  records: T[],
  path: PropertyPath
): ColumnFilterItem[] => {
  return chain(records)
    .flatMap((record: T) => {
      const resolved = get(record, path);

      if (resolved instanceof Array) {
        return resolved.map(toString);
      }

      if (typeof resolved === 'string') {
        const integerWithDecimalComma =
          /^(\$)?(?=.)(\d{1,3}(,\d{3})*)?(\.\d+)?$/;

        if (resolved.match(integerWithDecimalComma)) {
          return resolved.trim();
        }

        return resolved.split(',').map((s: string) => s.trim());
      }

      return toString(resolved);
    })
    .compact()
    .uniq()
    .sort()
    .map((s: string) => ({ text: s, value: s }))
    .value();
};

export const mapFilters = <T>(
  records: T[],
  path: PropertyPath
): ColumnFilterItem[] => {
  return chain(records)
    .map((record: T) => toString(get(record, path)))
    .compact()
    .uniq()
    .sort()
    .map((asString: string): ColumnFilterItem => {
      return { text: startCase(asString), value: asString };
    })
    .value();
};

export const mapNumberFilters = <T>(
  records: T[],
  path: PropertyPath,
  prefix: string = ''
): ColumnFilterItem[] => {
  return chain(records)
    .map((record: T) => Number.parseInt(toString(get(record, path)), 10))
    .uniq()
    .sort((a, b) => a - b)
    .map((asString: number): ColumnFilterItem => {
      return { text: prefix + asString, value: toString(asString) };
    })
    .value();
};

export const mapPayoutClauseFilters = (
  records: PortalSupplyContract[]
): ColumnFilterItem[] => {
  return chain(records)
    .map((record: PortalSupplyContract) => record.payoutClauses)
    .flatMap((clauses: PayoutClause[]) => clauses)
    .compact()
    .map(({ key }: PayoutClause) => ({
      text: payoutClauseToProductTypeMap[key],
      value: payoutClauseToProductTypeMap[key],
    }))
    .uniqBy('value')
    .sortBy('value')
    .value();
};

export const mapRevenueClauseFilters = (
  records: PortalDemandContract[]
): ColumnFilterItem[] => {
  return chain(records)
    .map((record: PortalDemandContract) => record.revenueClauses)
    .flatMap((clauses: RevenueClause[]) => clauses)
    .compact()
    .map(({ key }: RevenueClause) => ({
      text: getRevenueClauseTypeDisplayText(key),
      value: getRevenueClauseTypeDisplayText(key),
    }))
    .uniqBy('value')
    .sortBy('value')
    .value();
};

export const mapCreditScoreFilters = (
  records: OfferRuleRow[]
): ColumnFilterItem[] =>
  chain(records)
    .map((record: OfferRuleRow) => record.selfAssessedCreditScoreKeys!)
    .flatMap((s: ProvidedCreditRating[]) => {
      return s.map((s: ProvidedCreditRating) => s.toString().trim());
    })
    .compact()
    .map((asString: string): ColumnFilterItem => {
      return { text: startCase(asString), value: toString(asString) };
    })
    .uniqBy('value')
    .sortBy('value')
    .value();

export const mapEnumFilters = (enumeration: object): ColumnFilterItem[] => {
  return chain(Object.values(enumeration) as string[])
    .map((value: string) => ({ text: value, value }))
    .compact()
    .sort()
    .value();
};

export const onFilterForKey =
  <T>(path: PropertyPath, exact: boolean = true) =>
  (value: unknown, record: T): boolean =>
    find(toString(get(record, path)), toString(value), exact);

export const onFilterForSerialKey = <T>(path: PropertyPath) => {
  const memoSerialize = createMemoizedSerialize();

  return (value: string | number | boolean, record: T): boolean =>
    includes(toString(get(record, path)), memoSerialize(toString(value)));
};

export const onFilterForTimestampRange =
  <T>(path: PropertyPath) =>
  (value: string | number | boolean, record: T): boolean => {
    const inputValue = get(record, path);

    if (!inputValue) {
      return false;
    }

    const dateRange: Date[] = Array.isArray(inputValue)
      ? inputValue
      : [inputValue];

    const [from, to] = toString(value)
      .split(',')
      .map((v: string) => moment(v));

    if (!from?.isValid() && !to?.isValid()) {
      return true;
    }

    const matches = dateRange.map((date: Date) =>
      moment
        .parseZone(new Date(String(date)).toISOString())
        .isBetween(from?.startOf('day'), to?.endOf('day'), 'ms', '[]')
    );

    return matches.includes(true);
  };

export const onFilterForPayoutClause =
  () =>
  (value: unknown, record: PortalSupplyContract): boolean => {
    const { payoutClauses } = record;

    for (const { key } of payoutClauses) {
      if (value === payoutClauseToProductTypeMap[key]) {
        return true;
      }
    }

    return false;
  };

export const onFilterForArrayByKey =
  <T1, T2 extends { [K in keyof T1]: string[] }>(key: keyof T1) =>
  (value: string | number | boolean, record: T2): boolean => {
    return record[key]
      ? Boolean(
          Object.values(record[key]).find((x) => {
            return String(x).toLowerCase() === String(value).toLowerCase();
          })
        )
      : false;
  };

export const onFilterForRevenueClause =
  () =>
  (value: unknown, record: PortalDemandContract): boolean => {
    const { revenueClauses } = record;

    for (const { key } of revenueClauses) {
      if (value === getRevenueClauseTypeDisplayText(key)) {
        return true;
      }
    }

    return false;
  };

export const onFilterForCreditScore =
  () =>
  (value: string | number | boolean, record: OfferRuleRow): boolean => {
    const { selfAssessedCreditScoreKeys } = record;

    return selfAssessedCreditScoreKeys?.includes(value as ProvidedCreditRating);
  };

export const downloadUrl = (url: string): void => {
  const element = document.createElement('a');

  element.setAttribute('href', url);
  element.setAttribute('download', 'true');
  element.style.display = 'none';

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const mapCreditScore = <T>(
  records: T[],
  path: PropertyPath
): ColumnFilterItem[] => {
  const data = union(
    flattenDeep(records.map((record: T) => get(record, path)))
  ) as ProvidedCreditRating[];
  const filter = data.map((asString: string): ColumnFilterItem => {
    return { text: startCase(asString), value: toString(asString) };
  });

  return filter ? orderBy(filter, ['value'], ['asc']) : [];
};

export const mapRangeValues = <T>(
  records: T[],
  path: PropertyPath
): ColumnFilterItem[] => {
  const data = union(
    flattenDeep(records.map((record: T) => get(record, path)))
  ) as string[];
  const filter = data.map((asString: string): ColumnFilterItem => {
    return { text: asString, value: asString };
  });

  return filter ? orderBy(filter, ['value'], ['desc']) : [];
};

export const onFilterForAnnualIncomeRange =
  () =>
  (value: string | number | boolean, record: OfferRuleRow): boolean => {
    const { annualIncomeRange } = record;
    const formattedStringData = annualIncomeRange
      ? `${moneyFormatter(annualIncomeRange.start)} - ${moneyFormatter(
          annualIncomeRange.end
        )}`
      : 'n/a';

    return formattedStringData === value.toString();
  };

export const onFilterForLoanAmountRange =
  () =>
  (value: string | number | boolean, record: OfferRuleRow): boolean => {
    const { loanAmountRange } = record;
    const formattedStringData = loanAmountRange
      ? `${moneyFormatter(loanAmountRange.start)} - ${moneyFormatter(
          loanAmountRange.end
        )}`
      : 'n/a';

    return formattedStringData === value.toString();
  };

export const enumToFilters = <
  EnumType extends Record<keyof EnumType, string>,
  K extends keyof EnumType
>(
  Enum: EnumType
): ColumnFilterItem[] => {
  return Object.keys(Enum)
    .sort()
    .map((key) => ({
      text: startCase(key),
      value: Enum[key as K],
    }));
};
