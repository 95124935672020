import type { UserClaims } from '@okta/okta-auth-js';
import { createAction } from 'typesafe-actions';

import type { Experience } from '@portal/api-models';
import type { EngineUserClaims } from '@portal/common';

import { AuthenticationActionType } from './actionTypes';

interface AuthenticationCallbackPayload {
  accessToken: string;
  claims: UserClaims<EngineUserClaims>;
}

export const authenticationCallbackAction = createAction(
  AuthenticationActionType.AUTHENTICATION_CALLBACK
)<AuthenticationCallbackPayload>();

export const authenticationCallbackDoneAction = createAction(
  AuthenticationActionType.AUTHENTICATION_CALLBACK_DONE
)<void>();

export const setExperience = createAction(
  AuthenticationActionType.SET_EXPERIENCE
)<Experience>();
