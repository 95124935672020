"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.originatorExternalReducer = exports.originatorReducer = exports.initialOriginatorState = void 0;
var immer_1 = require("immer");
var redux_1 = require("redux");
var typesafe_actions_1 = require("typesafe-actions");
var actions_1 = require("./actions");
exports.initialOriginatorState = {
    demandApi: {
        byDemandSubAccountUuid: {},
        rows: [],
    },
    secondLookOfferConfiguration: {
        bySupplySubAccountId: {},
    },
};
exports.originatorReducer = (0, typesafe_actions_1.createReducer)(exports.initialOriginatorState)
    .handleAction(actions_1.getDemandApisAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.demandApi.rows = payload;
    });
})
    .handleAction(actions_1.getDemandApisBySubAccountUuidAction.success, function (state, _a) {
    var _b = _a.payload, demandApis = _b.demandApis, uuid = _b.uuid;
    return (0, immer_1.produce)(state, function (draft) {
        draft.demandApi.byDemandSubAccountUuid[uuid] = demandApis;
    });
})
    .handleAction(actions_1.getSecondLookOfferConfigurationsBySupplySubAccountIdAction.success, function (state, _a) {
    var _b = _a.payload, supplySubAccountId = _b.supplySubAccountId, secondLookOfferConfigurations = _b.secondLookOfferConfigurations;
    return (0, immer_1.produce)(state, function (draft) {
        draft.secondLookOfferConfiguration.bySupplySubAccountId[supplySubAccountId] = secondLookOfferConfigurations;
    });
});
exports.originatorExternalReducer = (0, redux_1.combineReducers)({
    originator: exports.originatorReducer,
});
