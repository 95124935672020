"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.supplyLiteScopes = void 0;
var enum_1 = require("../enum");
exports.supplyLiteScopes = [
    enum_1.Scopes.AccessTokensRead,
    enum_1.Scopes.AccountsRead,
    enum_1.Scopes.ApplicationsRead,
    enum_1.Scopes.CompaniesRead,
    enum_1.Scopes.FeedbacksCreate,
    enum_1.Scopes.PartnerPagesRead,
    enum_1.Scopes.PartnerPagesUpdate,
    enum_1.Scopes.RevenueReportsRead,
    enum_1.Scopes.RichAccessTokensRead,
    enum_1.Scopes.SubAccountsRead,
    enum_1.Scopes.SupplyContractsRead,
    enum_1.Scopes.SupplyInclusionsTemplatesRead,
    enum_1.Scopes.SupplyMigrationRead,
    enum_1.Scopes.SupplySummariesRead,
    enum_1.Scopes.SupplyTemplateApplicationCreate,
    enum_1.Scopes.SupplyTemplateApplicationRead,
    enum_1.Scopes.SupplyTemplatesRead,
    enum_1.Scopes.UsersRead,
];
