"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getScopesFromRoles = void 0;
var lodash_1 = require("lodash");
var roles_1 = require("../roles");
var crm_scopes_1 = require("./configs/crm-scopes");
var demand_scopes_1 = require("./configs/demand-scopes");
var evencom_api_scopes_1 = require("./configs/evencom-api-scopes");
var growth_scopes_1 = require("./configs/growth-scopes");
var internal_manager_scopes_1 = require("./configs/internal-manager-scopes");
var internal_viewer_scopes_1 = require("./configs/internal-viewer-scopes");
var scale_scopes_1 = require("./configs/scale-scopes");
var starter_free_scopes_1 = require("./configs/starter-free-scopes");
var starter_legacy_scopes_1 = require("./configs/starter-legacy-scopes");
var supply_lite_scopes_1 = require("./configs/supply-lite-scopes");
var roleToScopes = new Map([
    [roles_1.Roles.Manager, internal_manager_scopes_1.internalManagerScopes],
    [roles_1.Roles.InternalViewer, internal_viewer_scopes_1.internalViewerScopes],
    [roles_1.Roles.SupplyUser, supply_lite_scopes_1.supplyLiteScopes],
    [roles_1.Roles.Starter, starter_free_scopes_1.starterFreeScopes],
    [roles_1.Roles.StarterLegacy, starter_legacy_scopes_1.starterLegacyScopes],
    [roles_1.Roles.CRM, crm_scopes_1.crmScopes],
    [roles_1.Roles.Growth, growth_scopes_1.growthScopes],
    [roles_1.Roles.Scale, scale_scopes_1.scaleScopes],
    [roles_1.Roles.DemandUser, demand_scopes_1.demandScopes],
    [roles_1.Roles.EvencomApi, evencom_api_scopes_1.evencomApiScopes],
]);
var getScopesFromRoles = function (roles) {
    var allPermissions = roles
        .flatMap(function (role) { var _a; return (_a = roleToScopes.get(role)) !== null && _a !== void 0 ? _a : []; })
        .sort();
    return (0, lodash_1.uniq)(allPermissions);
};
exports.getScopesFromRoles = getScopesFromRoles;
