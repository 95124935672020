import type { AxiosResponse } from 'axios';
import { uniq } from 'lodash';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import type { SubAccountExternalDemandEvent } from '@evenfinancial/finance-client';

import { client } from '@portal/api-client';
import { getPortalError } from '@portal/common';
import { richAccessTokenByUuidRequestAction } from '@portal/store/dist/user';

import { ImportedChannelEventsActionType } from '@/store/imported-channel-events/types';

import * as actions from './actions';

function* getImportedChannelEventsAll() {
  try {
    const { data: response }: AxiosResponse<SubAccountExternalDemandEvent[]> =
      yield call([client, 'get'], '/finance/imported-events/channel');

    const accessTokenUuids = uniq(response.map((c) => c.createdBy));
    const { data: rats } = yield call(
      [client, 'post'],
      '/auth/rich_access_tokens',
      {
        accessTokenUuids,
      }
    );

    yield put(richAccessTokenByUuidRequestAction.success(rats));

    yield put(
      actions.getImportedChannelEventsAll.success({
        response,
      })
    );
  } catch (err: any) {
    const portalError = getPortalError(err);

    yield put(actions.getImportedChannelEventsAll.failure(portalError));
  }
}

function* watchGetAllRequest() {
  yield takeEvery(
    ImportedChannelEventsActionType.GET_ALL_IMPORTED_CHANNEL_EVENTS_REQUEST,
    getImportedChannelEventsAll
  );
}

export function* importedChannelEventsSaga() {
  yield all([fork(watchGetAllRequest)]);
}
