import * as React from 'react';

import { PerformanceSummaryMenuItem } from '@/components/side-nav/menu-items/self-service-channels';
import PortalItemGroup from '@/components/side-nav/portal-item-group';
import PortalMainMenu from '@/components/side-nav/portal-main-menu';

const ChannelsSection: React.FC = (props) => (
  <PortalItemGroup title="Channels" {...props}>
    <PerformanceSummaryMenuItem />
  </PortalItemGroup>
);

const CrmUserMenu: React.FC = () => (
  <PortalMainMenu>
    <ChannelsSection />
  </PortalMainMenu>
);

export default CrmUserMenu;
