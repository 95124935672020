import type { ActionType } from 'typesafe-actions';

import type { PartnerPageAlooma, PartnerPageFull } from '@portal/api-models';

import type * as actions from './actions';

export type PartnerPageAction = ActionType<typeof actions>;
export enum PartnerPageActionType {
  BY_KEY_FAILURE = '@@partner_page/BY_KEY_FAILURE',
  BY_KEY_REQUEST = '@@partner_page/BY_KEY_REQUEST',
  BY_KEY_SUCCESS = '@@partner_page/BY_KEY_SUCCESS',

  CLOSE_UPGRADE_TO_GROWTH_MODAL = '@@partner_page/CLOSE_UPGRADE_TO_GROWTH_MODAL',

  CREATE_FAILURE_CLIENT = '@@partner_page/CREATE_FAILURE_CLIENT',

  CREATE_INTEGRATION_FAILURE = '@partner_page/CREATE_INTEGRATION_FAILURE',
  CREATE_INTEGRATION_REQUEST = '@partner_page/CREATE_INTEGRATION_REQUEST',
  CREATE_INTEGRATION_SUCCESS = '@partner_page/CREATE_INTEGRATION_SUCCESS',

  CREATE_PARTNER_PAGE_FAILURE = '@@partner_page/CREATE_PARTNER_PAGE_FAILURE',
  CREATE_PARTNER_PAGE_REQUEST = '@@partner_page/CREATE_PARTNER_PAGE_REQUEST',
  CREATE_PARTNER_PAGE_SUCCESS = '@@partner_page/CREATE_PARTNER_PAGE_SUCCESS',

  CREATE_REQUEST_CLIENT = '@@partner_page/CREATE_REQUEST_CLIENT',
  CREATE_SUCCESS_CLIENT = '@@partner_page/CREATE_SUCCESS_CLIENT',
  DELETE_FAILURE_CLIENT = '@@partner_page/DELETE_FAILURE_CLIENT',

  DELETE_REQUEST_CLIENT = '@@partner_page/DELETE_REQUEST_CLIENT',
  DELETE_SUCCESS_CLIENT = '@@partner_page/DELETE_SUCCESS_CLIENT',

  GET_ALL_DETAILS_DATA_FAILURE = '@@partner_page/GET_ALL_DETAILS_DATA_FAILURE',
  GET_ALL_DETAILS_DATA_REQUEST = '@@partner_page/GET_ALL_DETAILS_DATA_REQUEST',
  GET_ALL_DETAILS_DATA_SUCCESS = '@@partner_page/GET_ALL_DETAILS_DATA_SUCCESS',

  GET_ALL_FAILURE = '@partner_page/GET_ALL_FAILURE',
  GET_ALL_REQUEST = '@partner_page/GET_ALL_REQUEST',
  GET_ALL_SUCCESS = '@partner_page/GET_ALL_SUCCESS',

  GET_ALL_WIZARD_DATA_FAILURE = '@@partner_page/GET_ALL_WIZARD_DATA_FAILURE',
  GET_ALL_WIZARD_DATA_REQUEST = '@@partner_page/GET_ALL_WIZARD_DATA_REQUEST',
  GET_ALL_WIZARD_DATA_SUCCESS = '@@partner_page/GET_ALL_WIZARD_DATA_SUCCESS',

  GET_BY_ID_FAILURE = '@partner_page/GET_BY_ID_FAILURE',
  GET_BY_ID_REQUEST = '@partner_page/GET_BY_ID_REQUEST',
  GET_BY_ID_SUCCESS = '@partner_page/GET_BY_ID_SUCCESS',

  INIT_SELF_SERVICE_INTEGRATIONS_PAGE = '@@partner_page/INIT_SELF_SERVICE_INTEGRATIONS_PAGE',

  OPEN_UPGRADE_TO_GROWTH_MODAL = '@@partner_page/OPEN_UPGRADE_TO_GROWTH_MODAL',
  PARTNER_PAGE_KEY_VALIDATION_FAILURE = '@partner_page/PARTNER_PAGE_KEY_VALIDATION_FAILURE',
  PARTNER_PAGE_KEY_VALIDATION_REQUEST = '@partner_page/PARTNER_PAGE_KEY_VALIDATION_REQUEST',

  PARTNER_PAGE_KEY_VALIDATION_SUCCESS = '@partner_page/PARTNER_PAGE_KEY_VALIDATION_SUCCESS',
  REPLACE_IMAGE_FAILURE_CLIENT = '@@partner_page/REPLACE_IMAGE_FAILURE_CLIENT',
  REPLACE_IMAGE_REQUEST_CLIENT = '@@partner_page/REPLACE_IMAGE_REQUEST_CLIENT',

  REPLACE_IMAGE_SUCCESS_CLIENT = '@@partner_page/REPLACE_IMAGE_SUCCESS_CLIENT',
  TOGGLE_CREATE = '@@partner_page/TOGGLE_CREATE',
  TOGGLE_DUPLICATE = '@@partner_page/TOGGLE_DUPLICATE',

  TOGGLE_INTEGRATION_CREATE = '@@partner_page/TOGGLE_INTEGRATION_CREATE',

  UPDATE_FAILURE_CLIENT = '@@partner_page/UPDATE_FAILURE_CLIENT',
  UPDATE_INTEGRATION_STATUS_FAILURE_CLIENT = '@@partner_page/UPDATE_INTEGRATION_STATUS_FAILURE_CLIENT',
  UPDATE_INTEGRATION_STATUS_REQUEST_CLIENT = '@@partner_page/UPDATE_INTEGRATION_STATUS_REQUEST_CLIENT',

  UPDATE_INTEGRATION_STATUS_SUCCESS_CLIENT = '@@partner_page/UPDATE_INTEGRATION_STATUS_SUCCESS_CLIENT',
  UPDATE_REQUEST_CLIENT = '@@partner_page/UPDATE_REQUEST_CLIENT',

  UPDATE_SUCCESS_CLIENT = '@@partner_page/UPDATE_SUCCESS_CLIENT',
}

export type byPartnerKeyIdType = Record<string, PartnerPageFull>;

export interface PartnerPageState {
  byPartnerKeyId: byPartnerKeyIdType;
  createDrawerVisible: boolean;
  duplicateId: string;
  haveAllBeenRequested: boolean;
  integrationCreateVisible: boolean;
  loadingWizardData: boolean;
  partnerPageKeyValid?: boolean;
  readonly rows: PartnerPageAlooma[];
  showUpgradeToGrowthModal: boolean;
  uploadingPartnerKeyImage: boolean;
}
