import * as React from 'react';
import { useDispatch } from 'react-redux';

import Button from 'antd/lib/button';

import ConfirmableAction from '@/components/confirmable-action';
import type { PortalStepAction } from '@/components/steps';

export interface ExpandedRowDetailsProps {
  action?: PortalStepAction & { disabled?: boolean };
  subtitle: string | JSX.Element;
  title: string | JSX.Element;
}

const ExpandedRowDetails: React.FC<ExpandedRowDetailsProps> = ({
  title,
  subtitle,
  action,
}) => {
  const dispatch = useDispatch();

  const button = action ? (
    <Button
      className="integration-expanded-row-action-button"
      disabled={action.disabled}
      type="primary"
      onClick={action.confirmable ? () => {} : () => action?.onClick(dispatch)}
    >
      {action.title}
    </Button>
  ) : (
    <></>
  );

  return (
    <div className="integration-expanded-row">
      <div className="integration-expanded-row-content">
        <h4 className="integration-expanded-row-title">{title}</h4>
        <div className="integration-expanded-row-subtitle">{subtitle}</div>
      </div>
      <div className="integration-expanded-row-action">
        {action?.confirmable ? (
          <ConfirmableAction
            {...action.confirmable}
            action={() => action?.onClick(dispatch)}
          >
            {button}
          </ConfirmableAction>
        ) : (
          button
        )}
      </div>
    </div>
  );
};

export default ExpandedRowDetails;
