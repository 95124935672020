"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleCombinations = exports.Roles = void 0;
var Roles;
(function (Roles) {
    Roles["Analytics"] = "Analytics";
    Roles["CRM"] = "Crm";
    Roles["ComplianceJiraWebhook"] = "Compliance Jira Webhook";
    Roles["DemandUser"] = "Demand User";
    Roles["EvencomApi"] = "Evencom Api";
    Roles["ExternalUser"] = "External User";
    Roles["Growth"] = "Growth";
    Roles["InternalUser"] = "Internal User";
    Roles["InternalViewer"] = "Internal Viewer";
    Roles["Manager"] = "Manager";
    Roles["Reporter"] = "Reporter";
    Roles["Scale"] = "Scale";
    Roles["ScaleAPI"] = "Scale API";
    Roles["SelfService"] = "Self Service";
    Roles["Starter"] = "Starter";
    Roles["StarterLegacy"] = "Starter Legacy";
    Roles["SuperAdministrator"] = "Super Administrator";
    Roles["SupplyUser"] = "Supply User";
})(Roles = exports.Roles || (exports.Roles = {}));
exports.RoleCombinations = {
    CRM: [Roles.SupplyUser, Roles.SelfService, Roles.CRM],
    Demand: [Roles.ExternalUser, Roles.DemandUser, Roles.Reporter],
    DemandPlusScale: [
        Roles.ExternalUser,
        Roles.DemandUser,
        Roles.Reporter,
        Roles.Scale,
    ],
    Growth: [Roles.SupplyUser, Roles.SelfService, Roles.Growth],
    InternalManager: [Roles.InternalUser, Roles.Manager],
    InternalViewer: [Roles.InternalUser, Roles.InternalViewer],
    Scale: [Roles.Scale],
    ScaleAPI: [Roles.ScaleAPI],
    StarterFree: [Roles.Starter],
    StarterLegacy: [Roles.SupplyUser, Roles.SelfService, Roles.Starter],
    SupplyLite: [Roles.ExternalUser, Roles.SupplyUser, Roles.Reporter],
};
