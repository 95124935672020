"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cmsReducer = exports.cmsReducerInitialState = void 0;
var immer_1 = require("immer");
var typesafe_actions_1 = require("typesafe-actions");
var hydrate_1 = require("../hydrate");
var actions_1 = require("./actions");
exports.cmsReducerInitialState = {
    legalAgreements: {
        loading: false,
        rows: [],
    },
    walkthrough: {
        item: {},
        screens: [],
    },
};
exports.cmsReducer = (0, typesafe_actions_1.createReducer)(exports.cmsReducerInitialState)
    .handleAction(hydrate_1.nextHydrate, function (state, _a) {
    var cms = _a.payload.cms;
    return (0, immer_1.produce)(state, function (draft) {
        draft.walkthrough = cms.walkthrough;
        draft.legalAgreements = cms.legalAgreements;
    });
})
    .handleAction(actions_1.cmsWalkthroughRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draft) {
        draft.walkthrough.item = payload;
    });
})
    .handleAction(actions_1.cmsWalkthroughScreensRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draft) {
        draft.walkthrough.screens = payload;
    });
})
    .handleAction(actions_1.cmsLegalAgreementLoaderAction, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draft) {
        draft.legalAgreements.loading = payload;
    });
})
    .handleAction(actions_1.cmsLegalAgreementRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draft) {
        var existing = state.legalAgreements.rows.find(function (agreement) { return agreement.id === payload.id; });
        if (!existing) {
            draft.legalAgreements.rows = draft.legalAgreements.rows.concat(payload);
        }
    });
});
