"use strict";
/* @ts-nocheck */
/* eslint-disable */
/**
 * Dynamic API Specification
 * This is a dynamically generated API specification for all endpoints existing on the current project.
 *
 * The version of the OpenAPI document: 9.0.0-rc.91
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelationsApiAxiosParamCreator = exports.ItemsPortalWalkthroughsApi = exports.ItemsPortalWalkthroughsApiFactory = exports.ItemsPortalWalkthroughsApiFp = exports.ItemsPortalWalkthroughsApiAxiosParamCreator = exports.ItemsPortalWalkthroughScreensApi = exports.ItemsPortalWalkthroughScreensApiFactory = exports.ItemsPortalWalkthroughScreensApiFp = exports.ItemsPortalWalkthroughScreensApiAxiosParamCreator = exports.ItemsPortalTiersApi = exports.ItemsPortalTiersApiFactory = exports.ItemsPortalTiersApiFp = exports.ItemsPortalTiersApiAxiosParamCreator = exports.ItemsLegalAgreementsApi = exports.ItemsLegalAgreementsApiFactory = exports.ItemsLegalAgreementsApiFp = exports.ItemsLegalAgreementsApiAxiosParamCreator = exports.ItemsApi = exports.ItemsApiFactory = exports.ItemsApiFp = exports.ItemsApiAxiosParamCreator = exports.FoldersApi = exports.FoldersApiFactory = exports.FoldersApiFp = exports.FoldersApiAxiosParamCreator = exports.FilesApi = exports.FilesApiFactory = exports.FilesApiFp = exports.FilesApiAxiosParamCreator = exports.FieldsApi = exports.FieldsApiFactory = exports.FieldsApiFp = exports.FieldsApiAxiosParamCreator = exports.ExtensionsApi = exports.ExtensionsApiFactory = exports.ExtensionsApiFp = exports.ExtensionsApiAxiosParamCreator = exports.CollectionsApi = exports.CollectionsApiFactory = exports.CollectionsApiFp = exports.CollectionsApiAxiosParamCreator = exports.AuthenticationApi = exports.AuthenticationApiFactory = exports.AuthenticationApiFp = exports.AuthenticationApiAxiosParamCreator = exports.AssetsApi = exports.AssetsApiFactory = exports.AssetsApiFp = exports.AssetsApiAxiosParamCreator = exports.InlineObjectModeEnum = void 0;
exports.UtilitiesApi = exports.UtilitiesApiFactory = exports.UtilitiesApiFp = exports.UtilitiesApiAxiosParamCreator = exports.ServerApi = exports.ServerApiFactory = exports.ServerApiFp = exports.ServerApiAxiosParamCreator = exports.RelationsApi = exports.RelationsApiFactory = exports.RelationsApiFp = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-nocheck
const common_1 = require("./common");
// @ts-nocheck
const base_1 = require("./base");
/**
    * @export
    * @enum {string}
    */
var InlineObjectModeEnum;
(function (InlineObjectModeEnum) {
    InlineObjectModeEnum["Json"] = "json";
    InlineObjectModeEnum["Cookie"] = "cookie";
})(InlineObjectModeEnum = exports.InlineObjectModeEnum || (exports.InlineObjectModeEnum = {}));
/**
 * AssetsApi - axios parameter creator
 * @export
 */
const AssetsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Image typed files can be dynamically resized and transformed to fit any need.
         * @summary Get an Asset
         * @param {string | number} id The id of the file.
         * @param {string} [key] The key of the asset size configured in settings.
         * @param {string} [transforms] A JSON array of image transformations
         * @param {boolean} [download] Download the asset to your computer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset: (id, key, transforms, download, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getAsset', 'id', id);
            const localVarPath = `/assets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }
            if (transforms !== undefined) {
                localVarQueryParameter['transforms'] = transforms;
            }
            if (download !== undefined) {
                localVarQueryParameter['download'] = download;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.AssetsApiAxiosParamCreator = AssetsApiAxiosParamCreator;
/**
 * AssetsApi - functional programming interface
 * @export
 */
const AssetsApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.AssetsApiAxiosParamCreator)(configuration);
    return {
        /**
         * Image typed files can be dynamically resized and transformed to fit any need.
         * @summary Get an Asset
         * @param {string | number} id The id of the file.
         * @param {string} [key] The key of the asset size configured in settings.
         * @param {string} [transforms] A JSON array of image transformations
         * @param {boolean} [download] Download the asset to your computer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset(id, key, transforms, download, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAsset(id, key, transforms, download, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.AssetsApiFp = AssetsApiFp;
/**
 * AssetsApi - factory interface
 * @export
 */
const AssetsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.AssetsApiFp)(configuration);
    return {
        /**
         * Image typed files can be dynamically resized and transformed to fit any need.
         * @summary Get an Asset
         * @param {string | number} id The id of the file.
         * @param {string} [key] The key of the asset size configured in settings.
         * @param {string} [transforms] A JSON array of image transformations
         * @param {boolean} [download] Download the asset to your computer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset(id, key, transforms, download, options) {
            return localVarFp.getAsset(id, key, transforms, download, options).then((request) => request(axios, basePath));
        },
    };
};
exports.AssetsApiFactory = AssetsApiFactory;
/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
class AssetsApi extends base_1.BaseAPI {
    /**
     * Image typed files can be dynamically resized and transformed to fit any need.
     * @summary Get an Asset
     * @param {string | number} id The id of the file.
     * @param {string} [key] The key of the asset size configured in settings.
     * @param {string} [transforms] A JSON array of image transformations
     * @param {boolean} [download] Download the asset to your computer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    getAsset(id, key, transforms, download, options) {
        return (0, exports.AssetsApiFp)(this.configuration).getAsset(id, key, transforms, download, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AssetsApi = AssetsApi;
/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
const AuthenticationApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Retrieve a Temporary Access Token
         * @summary Retrieve a Temporary Access Token
         * @param {InlineObject} [inlineObject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: (inlineObject, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inlineObject, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Log Out
         * @summary Log Out
         * @param {InlineObject2} [inlineObject2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: (inlineObject2, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inlineObject2, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * List configured OAuth providers.
         * @summary List OAuth Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauth: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/auth/oauth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Start OAuth flow using the specified provider
         * @summary Authenticated using an OAuth provider
         * @param {string} provider Key of the activated OAuth provider.
         * @param {string} [redirect] Where to redirect on successful login.&lt;br/&gt;If set the authentication details are set inside cookies otherwise a JSON is returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthProvider: (provider, redirect, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'provider' is not null or undefined
            (0, common_1.assertParamExists)('oauthProvider', 'provider', provider);
            const localVarPath = `/auth/oauth/{provider}`
                .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (redirect !== undefined) {
                localVarQueryParameter['redirect'] = redirect;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Request a reset password email to be send.
         * @summary Request a Password Reset
         * @param {InlineObject3} [inlineObject3]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRequest: (inlineObject3, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/auth/password/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inlineObject3, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * The request a password reset endpoint sends an email with a link to the admin app which in turn uses this endpoint to allow the user to reset their password.
         * @summary Reset a Password
         * @param {InlineObject4} [inlineObject4]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordReset: (inlineObject4, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/auth/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inlineObject4, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Refresh a Temporary Access Token.
         * @summary Refresh Token
         * @param {InlineObject1} [inlineObject1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh: (inlineObject1, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inlineObject1, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.AuthenticationApiAxiosParamCreator = AuthenticationApiAxiosParamCreator;
/**
 * AuthenticationApi - functional programming interface
 * @export
 */
const AuthenticationApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.AuthenticationApiAxiosParamCreator)(configuration);
    return {
        /**
         * Retrieve a Temporary Access Token
         * @summary Retrieve a Temporary Access Token
         * @param {InlineObject} [inlineObject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(inlineObject, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.login(inlineObject, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Log Out
         * @summary Log Out
         * @param {InlineObject2} [inlineObject2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(inlineObject2, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.logout(inlineObject2, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * List configured OAuth providers.
         * @summary List OAuth Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauth(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.oauth(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Start OAuth flow using the specified provider
         * @summary Authenticated using an OAuth provider
         * @param {string} provider Key of the activated OAuth provider.
         * @param {string} [redirect] Where to redirect on successful login.&lt;br/&gt;If set the authentication details are set inside cookies otherwise a JSON is returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthProvider(provider, redirect, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.oauthProvider(provider, redirect, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Request a reset password email to be send.
         * @summary Request a Password Reset
         * @param {InlineObject3} [inlineObject3]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRequest(inlineObject3, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.passwordRequest(inlineObject3, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * The request a password reset endpoint sends an email with a link to the admin app which in turn uses this endpoint to allow the user to reset their password.
         * @summary Reset a Password
         * @param {InlineObject4} [inlineObject4]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordReset(inlineObject4, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.passwordReset(inlineObject4, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Refresh a Temporary Access Token.
         * @summary Refresh Token
         * @param {InlineObject1} [inlineObject1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(inlineObject1, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.refresh(inlineObject1, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.AuthenticationApiFp = AuthenticationApiFp;
/**
 * AuthenticationApi - factory interface
 * @export
 */
const AuthenticationApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.AuthenticationApiFp)(configuration);
    return {
        /**
         * Retrieve a Temporary Access Token
         * @summary Retrieve a Temporary Access Token
         * @param {InlineObject} [inlineObject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(inlineObject, options) {
            return localVarFp.login(inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * Log Out
         * @summary Log Out
         * @param {InlineObject2} [inlineObject2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(inlineObject2, options) {
            return localVarFp.logout(inlineObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * List configured OAuth providers.
         * @summary List OAuth Providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauth(options) {
            return localVarFp.oauth(options).then((request) => request(axios, basePath));
        },
        /**
         * Start OAuth flow using the specified provider
         * @summary Authenticated using an OAuth provider
         * @param {string} provider Key of the activated OAuth provider.
         * @param {string} [redirect] Where to redirect on successful login.&lt;br/&gt;If set the authentication details are set inside cookies otherwise a JSON is returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthProvider(provider, redirect, options) {
            return localVarFp.oauthProvider(provider, redirect, options).then((request) => request(axios, basePath));
        },
        /**
         * Request a reset password email to be send.
         * @summary Request a Password Reset
         * @param {InlineObject3} [inlineObject3]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRequest(inlineObject3, options) {
            return localVarFp.passwordRequest(inlineObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * The request a password reset endpoint sends an email with a link to the admin app which in turn uses this endpoint to allow the user to reset their password.
         * @summary Reset a Password
         * @param {InlineObject4} [inlineObject4]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordReset(inlineObject4, options) {
            return localVarFp.passwordReset(inlineObject4, options).then((request) => request(axios, basePath));
        },
        /**
         * Refresh a Temporary Access Token.
         * @summary Refresh Token
         * @param {InlineObject1} [inlineObject1]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(inlineObject1, options) {
            return localVarFp.refresh(inlineObject1, options).then((request) => request(axios, basePath));
        },
    };
};
exports.AuthenticationApiFactory = AuthenticationApiFactory;
/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
class AuthenticationApi extends base_1.BaseAPI {
    /**
     * Retrieve a Temporary Access Token
     * @summary Retrieve a Temporary Access Token
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    login(inlineObject, options) {
        return (0, exports.AuthenticationApiFp)(this.configuration).login(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Log Out
     * @summary Log Out
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    logout(inlineObject2, options) {
        return (0, exports.AuthenticationApiFp)(this.configuration).logout(inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * List configured OAuth providers.
     * @summary List OAuth Providers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    oauth(options) {
        return (0, exports.AuthenticationApiFp)(this.configuration).oauth(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Start OAuth flow using the specified provider
     * @summary Authenticated using an OAuth provider
     * @param {string} provider Key of the activated OAuth provider.
     * @param {string} [redirect] Where to redirect on successful login.&lt;br/&gt;If set the authentication details are set inside cookies otherwise a JSON is returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    oauthProvider(provider, redirect, options) {
        return (0, exports.AuthenticationApiFp)(this.configuration).oauthProvider(provider, redirect, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Request a reset password email to be send.
     * @summary Request a Password Reset
     * @param {InlineObject3} [inlineObject3]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    passwordRequest(inlineObject3, options) {
        return (0, exports.AuthenticationApiFp)(this.configuration).passwordRequest(inlineObject3, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * The request a password reset endpoint sends an email with a link to the admin app which in turn uses this endpoint to allow the user to reset their password.
     * @summary Reset a Password
     * @param {InlineObject4} [inlineObject4]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    passwordReset(inlineObject4, options) {
        return (0, exports.AuthenticationApiFp)(this.configuration).passwordReset(inlineObject4, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Refresh a Temporary Access Token.
     * @summary Refresh Token
     * @param {InlineObject1} [inlineObject1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    refresh(inlineObject1, options) {
        return (0, exports.AuthenticationApiFp)(this.configuration).refresh(inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AuthenticationApi = AuthenticationApi;
/**
 * CollectionsApi - axios parameter creator
 * @export
 */
const CollectionsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Retrieves the details of a single collection.
         * @summary Retrieve a Collection
         * @param {string | number} id Collection id
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollection: (id, meta, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getCollection', 'id', id);
            const localVarPath = `/collections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Returns a list of the collections available in the project.
         * @summary List Collections
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollections: (offset, meta, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.CollectionsApiAxiosParamCreator = CollectionsApiAxiosParamCreator;
/**
 * CollectionsApi - functional programming interface
 * @export
 */
const CollectionsApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.CollectionsApiAxiosParamCreator)(configuration);
    return {
        /**
         * Retrieves the details of a single collection.
         * @summary Retrieve a Collection
         * @param {string | number} id Collection id
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollection(id, meta, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCollection(id, meta, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Returns a list of the collections available in the project.
         * @summary List Collections
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollections(offset, meta, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCollections(offset, meta, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.CollectionsApiFp = CollectionsApiFp;
/**
 * CollectionsApi - factory interface
 * @export
 */
const CollectionsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.CollectionsApiFp)(configuration);
    return {
        /**
         * Retrieves the details of a single collection.
         * @summary Retrieve a Collection
         * @param {string | number} id Collection id
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollection(id, meta, options) {
            return localVarFp.getCollection(id, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of the collections available in the project.
         * @summary List Collections
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollections(offset, meta, options) {
            return localVarFp.getCollections(offset, meta, options).then((request) => request(axios, basePath));
        },
    };
};
exports.CollectionsApiFactory = CollectionsApiFactory;
/**
 * CollectionsApi - object-oriented interface
 * @export
 * @class CollectionsApi
 * @extends {BaseAPI}
 */
class CollectionsApi extends base_1.BaseAPI {
    /**
     * Retrieves the details of a single collection.
     * @summary Retrieve a Collection
     * @param {string | number} id Collection id
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionsApi
     */
    getCollection(id, meta, options) {
        return (0, exports.CollectionsApiFp)(this.configuration).getCollection(id, meta, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns a list of the collections available in the project.
     * @summary List Collections
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionsApi
     */
    getCollections(offset, meta, options) {
        return (0, exports.CollectionsApiFp)(this.configuration).getCollections(offset, meta, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.CollectionsApi = CollectionsApi;
/**
 * ExtensionsApi - axios parameter creator
 * @export
 */
const ExtensionsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * List all installed custom displays.
         * @summary List Displays
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisplays: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/extensions/displays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * List all installed custom interfaces.
         * @summary List Interfaces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterfaces: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/extensions/interfaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * List all installed custom layouts.
         * @summary List Layouts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayouts: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/extensions/layouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * List all installed custom modules.
         * @summary List Modules
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModules: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/extensions/modules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.ExtensionsApiAxiosParamCreator = ExtensionsApiAxiosParamCreator;
/**
 * ExtensionsApi - functional programming interface
 * @export
 */
const ExtensionsApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.ExtensionsApiAxiosParamCreator)(configuration);
    return {
        /**
         * List all installed custom displays.
         * @summary List Displays
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisplays(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDisplays(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * List all installed custom interfaces.
         * @summary List Interfaces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterfaces(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getInterfaces(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * List all installed custom layouts.
         * @summary List Layouts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayouts(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getLayouts(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * List all installed custom modules.
         * @summary List Modules
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModules(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getModules(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.ExtensionsApiFp = ExtensionsApiFp;
/**
 * ExtensionsApi - factory interface
 * @export
 */
const ExtensionsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.ExtensionsApiFp)(configuration);
    return {
        /**
         * List all installed custom displays.
         * @summary List Displays
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisplays(options) {
            return localVarFp.getDisplays(options).then((request) => request(axios, basePath));
        },
        /**
         * List all installed custom interfaces.
         * @summary List Interfaces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterfaces(options) {
            return localVarFp.getInterfaces(options).then((request) => request(axios, basePath));
        },
        /**
         * List all installed custom layouts.
         * @summary List Layouts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLayouts(options) {
            return localVarFp.getLayouts(options).then((request) => request(axios, basePath));
        },
        /**
         * List all installed custom modules.
         * @summary List Modules
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModules(options) {
            return localVarFp.getModules(options).then((request) => request(axios, basePath));
        },
    };
};
exports.ExtensionsApiFactory = ExtensionsApiFactory;
/**
 * ExtensionsApi - object-oriented interface
 * @export
 * @class ExtensionsApi
 * @extends {BaseAPI}
 */
class ExtensionsApi extends base_1.BaseAPI {
    /**
     * List all installed custom displays.
     * @summary List Displays
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtensionsApi
     */
    getDisplays(options) {
        return (0, exports.ExtensionsApiFp)(this.configuration).getDisplays(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * List all installed custom interfaces.
     * @summary List Interfaces
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtensionsApi
     */
    getInterfaces(options) {
        return (0, exports.ExtensionsApiFp)(this.configuration).getInterfaces(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * List all installed custom layouts.
     * @summary List Layouts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtensionsApi
     */
    getLayouts(options) {
        return (0, exports.ExtensionsApiFp)(this.configuration).getLayouts(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * List all installed custom modules.
     * @summary List Modules
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtensionsApi
     */
    getModules(options) {
        return (0, exports.ExtensionsApiFp)(this.configuration).getModules(options).then((request) => request(this.axios, this.basePath));
    }
}
exports.ExtensionsApi = ExtensionsApi;
/**
 * FieldsApi - axios parameter creator
 * @export
 */
const FieldsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Retrieves the details of a single field in a given collection.
         * @summary Retrieve a Field
         * @param {string} collection Collection name
         * @param {string | number} id Id of the field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionField: (collection, id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'collection' is not null or undefined
            (0, common_1.assertParamExists)('getCollectionField', 'collection', collection);
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getCollectionField', 'id', id);
            const localVarPath = `/fields/{collection}/{id}`
                .replace(`{${"collection"}}`, encodeURIComponent(String(collection)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Returns a list of the fields available in the given collection.
         * @summary List Fields in Collection
         * @param {string} collection Collection
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionFields: (collection, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'collection' is not null or undefined
            (0, common_1.assertParamExists)('getCollectionFields', 'collection', collection);
            const localVarPath = `/fields/{collection}`
                .replace(`{${"collection"}}`, encodeURIComponent(String(collection)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (sort) {
                localVarQueryParameter['sort'] = sort.join(base_1.COLLECTION_FORMATS.csv);
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Returns a list of the fields available in the project.
         * @summary List All Fields
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFields: (limit, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort.join(base_1.COLLECTION_FORMATS.csv);
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.FieldsApiAxiosParamCreator = FieldsApiAxiosParamCreator;
/**
 * FieldsApi - functional programming interface
 * @export
 */
const FieldsApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.FieldsApiAxiosParamCreator)(configuration);
    return {
        /**
         * Retrieves the details of a single field in a given collection.
         * @summary Retrieve a Field
         * @param {string} collection Collection name
         * @param {string | number} id Id of the field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionField(collection, id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCollectionField(collection, id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Returns a list of the fields available in the given collection.
         * @summary List Fields in Collection
         * @param {string} collection Collection
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionFields(collection, sort, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCollectionFields(collection, sort, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Returns a list of the fields available in the project.
         * @summary List All Fields
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFields(limit, sort, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getFields(limit, sort, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.FieldsApiFp = FieldsApiFp;
/**
 * FieldsApi - factory interface
 * @export
 */
const FieldsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.FieldsApiFp)(configuration);
    return {
        /**
         * Retrieves the details of a single field in a given collection.
         * @summary Retrieve a Field
         * @param {string} collection Collection name
         * @param {string | number} id Id of the field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionField(collection, id, options) {
            return localVarFp.getCollectionField(collection, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of the fields available in the given collection.
         * @summary List Fields in Collection
         * @param {string} collection Collection
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionFields(collection, sort, options) {
            return localVarFp.getCollectionFields(collection, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of the fields available in the project.
         * @summary List All Fields
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFields(limit, sort, options) {
            return localVarFp.getFields(limit, sort, options).then((request) => request(axios, basePath));
        },
    };
};
exports.FieldsApiFactory = FieldsApiFactory;
/**
 * FieldsApi - object-oriented interface
 * @export
 * @class FieldsApi
 * @extends {BaseAPI}
 */
class FieldsApi extends base_1.BaseAPI {
    /**
     * Retrieves the details of a single field in a given collection.
     * @summary Retrieve a Field
     * @param {string} collection Collection name
     * @param {string | number} id Id of the field
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldsApi
     */
    getCollectionField(collection, id, options) {
        return (0, exports.FieldsApiFp)(this.configuration).getCollectionField(collection, id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns a list of the fields available in the given collection.
     * @summary List Fields in Collection
     * @param {string} collection Collection
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldsApi
     */
    getCollectionFields(collection, sort, options) {
        return (0, exports.FieldsApiFp)(this.configuration).getCollectionFields(collection, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns a list of the fields available in the project.
     * @summary List All Fields
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldsApi
     */
    getFields(limit, sort, options) {
        return (0, exports.FieldsApiFp)(this.configuration).getFields(limit, sort, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.FieldsApi = FieldsApi;
/**
 * FilesApi - axios parameter creator
 * @export
 */
const FilesApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Retrieve a single file by unique identifier.
         * @summary Retrieve a Files
         * @param {string | number} id File id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getFile', 'id', id);
            const localVarPath = `/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * List the files.
         * @summary List Files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiles: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.FilesApiAxiosParamCreator = FilesApiAxiosParamCreator;
/**
 * FilesApi - functional programming interface
 * @export
 */
const FilesApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.FilesApiAxiosParamCreator)(configuration);
    return {
        /**
         * Retrieve a single file by unique identifier.
         * @summary Retrieve a Files
         * @param {string | number} id File id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getFile(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * List the files.
         * @summary List Files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiles(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getFiles(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.FilesApiFp = FilesApiFp;
/**
 * FilesApi - factory interface
 * @export
 */
const FilesApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.FilesApiFp)(configuration);
    return {
        /**
         * Retrieve a single file by unique identifier.
         * @summary Retrieve a Files
         * @param {string | number} id File id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile(id, options) {
            return localVarFp.getFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List the files.
         * @summary List Files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiles(options) {
            return localVarFp.getFiles(options).then((request) => request(axios, basePath));
        },
    };
};
exports.FilesApiFactory = FilesApiFactory;
/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
class FilesApi extends base_1.BaseAPI {
    /**
     * Retrieve a single file by unique identifier.
     * @summary Retrieve a Files
     * @param {string | number} id File id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    getFile(id, options) {
        return (0, exports.FilesApiFp)(this.configuration).getFile(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * List the files.
     * @summary List Files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    getFiles(options) {
        return (0, exports.FilesApiFp)(this.configuration).getFiles(options).then((request) => request(this.axios, this.basePath));
    }
}
exports.FilesApi = FilesApi;
/**
 * FoldersApi - axios parameter creator
 * @export
 */
const FoldersApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Retrieve a single folder by unique identifier.
         * @summary Retrieve a Folder
         * @param {string | number} id Folder id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolder: (id, fields, meta, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getFolder', 'id', id);
            const localVarPath = `/folders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * List the folders.
         * @summary List Folders
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolders: (fields, limit, offset, sort, filter, search, meta, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/folders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }
            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.FoldersApiAxiosParamCreator = FoldersApiAxiosParamCreator;
/**
 * FoldersApi - functional programming interface
 * @export
 */
const FoldersApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.FoldersApiAxiosParamCreator)(configuration);
    return {
        /**
         * Retrieve a single folder by unique identifier.
         * @summary Retrieve a Folder
         * @param {string | number} id Folder id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolder(id, fields, meta, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getFolder(id, fields, meta, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * List the folders.
         * @summary List Folders
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolders(fields, limit, offset, sort, filter, search, meta, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getFolders(fields, limit, offset, sort, filter, search, meta, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.FoldersApiFp = FoldersApiFp;
/**
 * FoldersApi - factory interface
 * @export
 */
const FoldersApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.FoldersApiFp)(configuration);
    return {
        /**
         * Retrieve a single folder by unique identifier.
         * @summary Retrieve a Folder
         * @param {string | number} id Folder id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolder(id, fields, meta, options) {
            return localVarFp.getFolder(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * List the folders.
         * @summary List Folders
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolders(fields, limit, offset, sort, filter, search, meta, options) {
            return localVarFp.getFolders(fields, limit, offset, sort, filter, search, meta, options).then((request) => request(axios, basePath));
        },
    };
};
exports.FoldersApiFactory = FoldersApiFactory;
/**
 * FoldersApi - object-oriented interface
 * @export
 * @class FoldersApi
 * @extends {BaseAPI}
 */
class FoldersApi extends base_1.BaseAPI {
    /**
     * Retrieve a single folder by unique identifier.
     * @summary Retrieve a Folder
     * @param {string | number} id Folder id
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoldersApi
     */
    getFolder(id, fields, meta, options) {
        return (0, exports.FoldersApiFp)(this.configuration).getFolder(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * List the folders.
     * @summary List Folders
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
     * @param {Array<string>} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoldersApi
     */
    getFolders(fields, limit, offset, sort, filter, search, meta, options) {
        return (0, exports.FoldersApiFp)(this.configuration).getFolders(fields, limit, offset, sort, filter, search, meta, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.FoldersApi = FoldersApi;
/**
 * ItemsApi - axios parameter creator
 * @export
 */
const ItemsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * List the legal_agreements items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsLegalAgreements: (fields, limit, meta, offset, sort, filter, search, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/items/legal_agreements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Auth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration);
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }
            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * List the portal_tiers items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPortalTiers: (fields, limit, meta, offset, sort, filter, search, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/items/portal_tiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Auth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration);
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }
            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * List the portal_walkthrough_screens items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPortalWalkthroughScreens: (fields, limit, meta, offset, sort, filter, search, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/items/portal_walkthrough_screens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Auth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration);
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }
            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * List the portal_walkthroughs items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPortalWalkthroughs: (fields, limit, meta, offset, sort, filter, search, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/items/portal_walkthroughs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Auth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration);
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }
            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieve a single legal_agreements item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Legal agreement id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsLegalAgreements: (id, fields, meta, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('readSingleItemsLegalAgreements', 'id', id);
            const localVarPath = `/items/legal_agreements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieve a single portal_tiers item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Portal tier id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPortalTiers: (id, fields, meta, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('readSingleItemsPortalTiers', 'id', id);
            const localVarPath = `/items/portal_tiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieve a single portal_walkthrough_screens item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Portal walkthrough screen id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPortalWalkthroughScreens: (id, fields, meta, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('readSingleItemsPortalWalkthroughScreens', 'id', id);
            const localVarPath = `/items/portal_walkthrough_screens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieve a single portal_walkthroughs item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Portal walkthrough id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPortalWalkthroughs: (id, fields, meta, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('readSingleItemsPortalWalkthroughs', 'id', id);
            const localVarPath = `/items/portal_walkthroughs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.ItemsApiAxiosParamCreator = ItemsApiAxiosParamCreator;
/**
 * ItemsApi - functional programming interface
 * @export
 */
const ItemsApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.ItemsApiAxiosParamCreator)(configuration);
    return {
        /**
         * List the legal_agreements items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsLegalAgreements(fields, limit, meta, offset, sort, filter, search, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readItemsLegalAgreements(fields, limit, meta, offset, sort, filter, search, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * List the portal_tiers items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPortalTiers(fields, limit, meta, offset, sort, filter, search, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readItemsPortalTiers(fields, limit, meta, offset, sort, filter, search, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * List the portal_walkthrough_screens items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPortalWalkthroughScreens(fields, limit, meta, offset, sort, filter, search, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readItemsPortalWalkthroughScreens(fields, limit, meta, offset, sort, filter, search, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * List the portal_walkthroughs items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPortalWalkthroughs(fields, limit, meta, offset, sort, filter, search, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readItemsPortalWalkthroughs(fields, limit, meta, offset, sort, filter, search, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Retrieve a single legal_agreements item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Legal agreement id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsLegalAgreements(id, fields, meta, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readSingleItemsLegalAgreements(id, fields, meta, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Retrieve a single portal_tiers item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Portal tier id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPortalTiers(id, fields, meta, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readSingleItemsPortalTiers(id, fields, meta, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Retrieve a single portal_walkthrough_screens item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Portal walkthrough screen id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPortalWalkthroughScreens(id, fields, meta, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readSingleItemsPortalWalkthroughScreens(id, fields, meta, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Retrieve a single portal_walkthroughs item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Portal walkthrough id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPortalWalkthroughs(id, fields, meta, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readSingleItemsPortalWalkthroughs(id, fields, meta, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.ItemsApiFp = ItemsApiFp;
/**
 * ItemsApi - factory interface
 * @export
 */
const ItemsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.ItemsApiFp)(configuration);
    return {
        /**
         * List the legal_agreements items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsLegalAgreements(fields, limit, meta, offset, sort, filter, search, options) {
            return localVarFp.readItemsLegalAgreements(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * List the portal_tiers items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPortalTiers(fields, limit, meta, offset, sort, filter, search, options) {
            return localVarFp.readItemsPortalTiers(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * List the portal_walkthrough_screens items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPortalWalkthroughScreens(fields, limit, meta, offset, sort, filter, search, options) {
            return localVarFp.readItemsPortalWalkthroughScreens(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * List the portal_walkthroughs items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPortalWalkthroughs(fields, limit, meta, offset, sort, filter, search, options) {
            return localVarFp.readItemsPortalWalkthroughs(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single legal_agreements item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Legal agreement id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsLegalAgreements(id, fields, meta, options) {
            return localVarFp.readSingleItemsLegalAgreements(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single portal_tiers item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Portal tier id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPortalTiers(id, fields, meta, options) {
            return localVarFp.readSingleItemsPortalTiers(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single portal_walkthrough_screens item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Portal walkthrough screen id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPortalWalkthroughScreens(id, fields, meta, options) {
            return localVarFp.readSingleItemsPortalWalkthroughScreens(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single portal_walkthroughs item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Portal walkthrough id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPortalWalkthroughs(id, fields, meta, options) {
            return localVarFp.readSingleItemsPortalWalkthroughs(id, fields, meta, options).then((request) => request(axios, basePath));
        },
    };
};
exports.ItemsApiFactory = ItemsApiFactory;
/**
 * ItemsApi - object-oriented interface
 * @export
 * @class ItemsApi
 * @extends {BaseAPI}
 */
class ItemsApi extends base_1.BaseAPI {
    /**
     * List the legal_agreements items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
     * @param {Array<string>} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    readItemsLegalAgreements(fields, limit, meta, offset, sort, filter, search, options) {
        return (0, exports.ItemsApiFp)(this.configuration).readItemsLegalAgreements(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * List the portal_tiers items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
     * @param {Array<string>} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    readItemsPortalTiers(fields, limit, meta, offset, sort, filter, search, options) {
        return (0, exports.ItemsApiFp)(this.configuration).readItemsPortalTiers(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * List the portal_walkthrough_screens items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
     * @param {Array<string>} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    readItemsPortalWalkthroughScreens(fields, limit, meta, offset, sort, filter, search, options) {
        return (0, exports.ItemsApiFp)(this.configuration).readItemsPortalWalkthroughScreens(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * List the portal_walkthroughs items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
     * @param {Array<string>} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    readItemsPortalWalkthroughs(fields, limit, meta, offset, sort, filter, search, options) {
        return (0, exports.ItemsApiFp)(this.configuration).readItemsPortalWalkthroughs(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Retrieve a single legal_agreements item by unique identifier.
     * @summary Retrieve an Item
     * @param {string | number} id Legal agreement id
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    readSingleItemsLegalAgreements(id, fields, meta, options) {
        return (0, exports.ItemsApiFp)(this.configuration).readSingleItemsLegalAgreements(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Retrieve a single portal_tiers item by unique identifier.
     * @summary Retrieve an Item
     * @param {string | number} id Portal tier id
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    readSingleItemsPortalTiers(id, fields, meta, options) {
        return (0, exports.ItemsApiFp)(this.configuration).readSingleItemsPortalTiers(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Retrieve a single portal_walkthrough_screens item by unique identifier.
     * @summary Retrieve an Item
     * @param {string | number} id Portal walkthrough screen id
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    readSingleItemsPortalWalkthroughScreens(id, fields, meta, options) {
        return (0, exports.ItemsApiFp)(this.configuration).readSingleItemsPortalWalkthroughScreens(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Retrieve a single portal_walkthroughs item by unique identifier.
     * @summary Retrieve an Item
     * @param {string | number} id Portal walkthrough id
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    readSingleItemsPortalWalkthroughs(id, fields, meta, options) {
        return (0, exports.ItemsApiFp)(this.configuration).readSingleItemsPortalWalkthroughs(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.ItemsApi = ItemsApi;
/**
 * ItemsLegalAgreementsApi - axios parameter creator
 * @export
 */
const ItemsLegalAgreementsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * List the legal_agreements items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsLegalAgreements: (fields, limit, meta, offset, sort, filter, search, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/items/legal_agreements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Auth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration);
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }
            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieve a single legal_agreements item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Legal agreement id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsLegalAgreements: (id, fields, meta, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('readSingleItemsLegalAgreements', 'id', id);
            const localVarPath = `/items/legal_agreements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.ItemsLegalAgreementsApiAxiosParamCreator = ItemsLegalAgreementsApiAxiosParamCreator;
/**
 * ItemsLegalAgreementsApi - functional programming interface
 * @export
 */
const ItemsLegalAgreementsApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.ItemsLegalAgreementsApiAxiosParamCreator)(configuration);
    return {
        /**
         * List the legal_agreements items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsLegalAgreements(fields, limit, meta, offset, sort, filter, search, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readItemsLegalAgreements(fields, limit, meta, offset, sort, filter, search, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Retrieve a single legal_agreements item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Legal agreement id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsLegalAgreements(id, fields, meta, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readSingleItemsLegalAgreements(id, fields, meta, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.ItemsLegalAgreementsApiFp = ItemsLegalAgreementsApiFp;
/**
 * ItemsLegalAgreementsApi - factory interface
 * @export
 */
const ItemsLegalAgreementsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.ItemsLegalAgreementsApiFp)(configuration);
    return {
        /**
         * List the legal_agreements items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsLegalAgreements(fields, limit, meta, offset, sort, filter, search, options) {
            return localVarFp.readItemsLegalAgreements(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single legal_agreements item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Legal agreement id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsLegalAgreements(id, fields, meta, options) {
            return localVarFp.readSingleItemsLegalAgreements(id, fields, meta, options).then((request) => request(axios, basePath));
        },
    };
};
exports.ItemsLegalAgreementsApiFactory = ItemsLegalAgreementsApiFactory;
/**
 * ItemsLegalAgreementsApi - object-oriented interface
 * @export
 * @class ItemsLegalAgreementsApi
 * @extends {BaseAPI}
 */
class ItemsLegalAgreementsApi extends base_1.BaseAPI {
    /**
     * List the legal_agreements items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
     * @param {Array<string>} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsLegalAgreementsApi
     */
    readItemsLegalAgreements(fields, limit, meta, offset, sort, filter, search, options) {
        return (0, exports.ItemsLegalAgreementsApiFp)(this.configuration).readItemsLegalAgreements(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Retrieve a single legal_agreements item by unique identifier.
     * @summary Retrieve an Item
     * @param {string | number} id Legal agreement id
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsLegalAgreementsApi
     */
    readSingleItemsLegalAgreements(id, fields, meta, options) {
        return (0, exports.ItemsLegalAgreementsApiFp)(this.configuration).readSingleItemsLegalAgreements(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.ItemsLegalAgreementsApi = ItemsLegalAgreementsApi;
/**
 * ItemsPortalTiersApi - axios parameter creator
 * @export
 */
const ItemsPortalTiersApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * List the portal_tiers items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPortalTiers: (fields, limit, meta, offset, sort, filter, search, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/items/portal_tiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Auth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration);
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }
            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieve a single portal_tiers item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Portal tier id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPortalTiers: (id, fields, meta, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('readSingleItemsPortalTiers', 'id', id);
            const localVarPath = `/items/portal_tiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.ItemsPortalTiersApiAxiosParamCreator = ItemsPortalTiersApiAxiosParamCreator;
/**
 * ItemsPortalTiersApi - functional programming interface
 * @export
 */
const ItemsPortalTiersApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.ItemsPortalTiersApiAxiosParamCreator)(configuration);
    return {
        /**
         * List the portal_tiers items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPortalTiers(fields, limit, meta, offset, sort, filter, search, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readItemsPortalTiers(fields, limit, meta, offset, sort, filter, search, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Retrieve a single portal_tiers item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Portal tier id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPortalTiers(id, fields, meta, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readSingleItemsPortalTiers(id, fields, meta, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.ItemsPortalTiersApiFp = ItemsPortalTiersApiFp;
/**
 * ItemsPortalTiersApi - factory interface
 * @export
 */
const ItemsPortalTiersApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.ItemsPortalTiersApiFp)(configuration);
    return {
        /**
         * List the portal_tiers items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPortalTiers(fields, limit, meta, offset, sort, filter, search, options) {
            return localVarFp.readItemsPortalTiers(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single portal_tiers item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Portal tier id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPortalTiers(id, fields, meta, options) {
            return localVarFp.readSingleItemsPortalTiers(id, fields, meta, options).then((request) => request(axios, basePath));
        },
    };
};
exports.ItemsPortalTiersApiFactory = ItemsPortalTiersApiFactory;
/**
 * ItemsPortalTiersApi - object-oriented interface
 * @export
 * @class ItemsPortalTiersApi
 * @extends {BaseAPI}
 */
class ItemsPortalTiersApi extends base_1.BaseAPI {
    /**
     * List the portal_tiers items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
     * @param {Array<string>} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsPortalTiersApi
     */
    readItemsPortalTiers(fields, limit, meta, offset, sort, filter, search, options) {
        return (0, exports.ItemsPortalTiersApiFp)(this.configuration).readItemsPortalTiers(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Retrieve a single portal_tiers item by unique identifier.
     * @summary Retrieve an Item
     * @param {string | number} id Portal tier id
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsPortalTiersApi
     */
    readSingleItemsPortalTiers(id, fields, meta, options) {
        return (0, exports.ItemsPortalTiersApiFp)(this.configuration).readSingleItemsPortalTiers(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.ItemsPortalTiersApi = ItemsPortalTiersApi;
/**
 * ItemsPortalWalkthroughScreensApi - axios parameter creator
 * @export
 */
const ItemsPortalWalkthroughScreensApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * List the portal_walkthrough_screens items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPortalWalkthroughScreens: (fields, limit, meta, offset, sort, filter, search, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/items/portal_walkthrough_screens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Auth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration);
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }
            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieve a single portal_walkthrough_screens item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Portal walkthrough screen id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPortalWalkthroughScreens: (id, fields, meta, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('readSingleItemsPortalWalkthroughScreens', 'id', id);
            const localVarPath = `/items/portal_walkthrough_screens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.ItemsPortalWalkthroughScreensApiAxiosParamCreator = ItemsPortalWalkthroughScreensApiAxiosParamCreator;
/**
 * ItemsPortalWalkthroughScreensApi - functional programming interface
 * @export
 */
const ItemsPortalWalkthroughScreensApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.ItemsPortalWalkthroughScreensApiAxiosParamCreator)(configuration);
    return {
        /**
         * List the portal_walkthrough_screens items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPortalWalkthroughScreens(fields, limit, meta, offset, sort, filter, search, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readItemsPortalWalkthroughScreens(fields, limit, meta, offset, sort, filter, search, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Retrieve a single portal_walkthrough_screens item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Portal walkthrough screen id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPortalWalkthroughScreens(id, fields, meta, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readSingleItemsPortalWalkthroughScreens(id, fields, meta, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.ItemsPortalWalkthroughScreensApiFp = ItemsPortalWalkthroughScreensApiFp;
/**
 * ItemsPortalWalkthroughScreensApi - factory interface
 * @export
 */
const ItemsPortalWalkthroughScreensApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.ItemsPortalWalkthroughScreensApiFp)(configuration);
    return {
        /**
         * List the portal_walkthrough_screens items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPortalWalkthroughScreens(fields, limit, meta, offset, sort, filter, search, options) {
            return localVarFp.readItemsPortalWalkthroughScreens(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single portal_walkthrough_screens item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Portal walkthrough screen id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPortalWalkthroughScreens(id, fields, meta, options) {
            return localVarFp.readSingleItemsPortalWalkthroughScreens(id, fields, meta, options).then((request) => request(axios, basePath));
        },
    };
};
exports.ItemsPortalWalkthroughScreensApiFactory = ItemsPortalWalkthroughScreensApiFactory;
/**
 * ItemsPortalWalkthroughScreensApi - object-oriented interface
 * @export
 * @class ItemsPortalWalkthroughScreensApi
 * @extends {BaseAPI}
 */
class ItemsPortalWalkthroughScreensApi extends base_1.BaseAPI {
    /**
     * List the portal_walkthrough_screens items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
     * @param {Array<string>} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsPortalWalkthroughScreensApi
     */
    readItemsPortalWalkthroughScreens(fields, limit, meta, offset, sort, filter, search, options) {
        return (0, exports.ItemsPortalWalkthroughScreensApiFp)(this.configuration).readItemsPortalWalkthroughScreens(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Retrieve a single portal_walkthrough_screens item by unique identifier.
     * @summary Retrieve an Item
     * @param {string | number} id Portal walkthrough screen id
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsPortalWalkthroughScreensApi
     */
    readSingleItemsPortalWalkthroughScreens(id, fields, meta, options) {
        return (0, exports.ItemsPortalWalkthroughScreensApiFp)(this.configuration).readSingleItemsPortalWalkthroughScreens(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.ItemsPortalWalkthroughScreensApi = ItemsPortalWalkthroughScreensApi;
/**
 * ItemsPortalWalkthroughsApi - axios parameter creator
 * @export
 */
const ItemsPortalWalkthroughsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * List the portal_walkthroughs items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPortalWalkthroughs: (fields, limit, meta, offset, sort, filter, search, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/items/portal_walkthroughs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Auth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "Authorization", configuration);
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }
            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieve a single portal_walkthroughs item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Portal walkthrough id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPortalWalkthroughs: (id, fields, meta, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('readSingleItemsPortalWalkthroughs', 'id', id);
            const localVarPath = `/items/portal_walkthroughs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.ItemsPortalWalkthroughsApiAxiosParamCreator = ItemsPortalWalkthroughsApiAxiosParamCreator;
/**
 * ItemsPortalWalkthroughsApi - functional programming interface
 * @export
 */
const ItemsPortalWalkthroughsApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.ItemsPortalWalkthroughsApiAxiosParamCreator)(configuration);
    return {
        /**
         * List the portal_walkthroughs items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPortalWalkthroughs(fields, limit, meta, offset, sort, filter, search, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readItemsPortalWalkthroughs(fields, limit, meta, offset, sort, filter, search, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Retrieve a single portal_walkthroughs item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Portal walkthrough id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPortalWalkthroughs(id, fields, meta, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readSingleItemsPortalWalkthroughs(id, fields, meta, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.ItemsPortalWalkthroughsApiFp = ItemsPortalWalkthroughsApiFp;
/**
 * ItemsPortalWalkthroughsApi - factory interface
 * @export
 */
const ItemsPortalWalkthroughsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.ItemsPortalWalkthroughsApiFp)(configuration);
    return {
        /**
         * List the portal_walkthroughs items.
         * @summary List Items
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {string} [meta] What metadata to return in the response.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsPortalWalkthroughs(fields, limit, meta, offset, sort, filter, search, options) {
            return localVarFp.readItemsPortalWalkthroughs(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a single portal_walkthroughs item by unique identifier.
         * @summary Retrieve an Item
         * @param {string | number} id Portal walkthrough id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleItemsPortalWalkthroughs(id, fields, meta, options) {
            return localVarFp.readSingleItemsPortalWalkthroughs(id, fields, meta, options).then((request) => request(axios, basePath));
        },
    };
};
exports.ItemsPortalWalkthroughsApiFactory = ItemsPortalWalkthroughsApiFactory;
/**
 * ItemsPortalWalkthroughsApi - object-oriented interface
 * @export
 * @class ItemsPortalWalkthroughsApi
 * @extends {BaseAPI}
 */
class ItemsPortalWalkthroughsApi extends base_1.BaseAPI {
    /**
     * List the portal_walkthroughs items.
     * @summary List Items
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {string} [meta] What metadata to return in the response.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
     * @param {Array<string>} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsPortalWalkthroughsApi
     */
    readItemsPortalWalkthroughs(fields, limit, meta, offset, sort, filter, search, options) {
        return (0, exports.ItemsPortalWalkthroughsApiFp)(this.configuration).readItemsPortalWalkthroughs(fields, limit, meta, offset, sort, filter, search, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Retrieve a single portal_walkthroughs item by unique identifier.
     * @summary Retrieve an Item
     * @param {string | number} id Portal walkthrough id
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsPortalWalkthroughsApi
     */
    readSingleItemsPortalWalkthroughs(id, fields, meta, options) {
        return (0, exports.ItemsPortalWalkthroughsApiFp)(this.configuration).readSingleItemsPortalWalkthroughs(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.ItemsPortalWalkthroughsApi = ItemsPortalWalkthroughsApi;
/**
 * RelationsApi - axios parameter creator
 * @export
 */
const RelationsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Retrieve a single relation by unique identifier.
         * @summary Retrieve a Relation
         * @param {string | number} id Relations id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelation: (id, fields, meta, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getRelation', 'id', id);
            const localVarPath = `/relations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * List the relations.
         * @summary List Relations
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelations: (fields, limit, offset, meta, sort, filter, search, page, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/relations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }
            if (meta !== undefined) {
                localVarQueryParameter['meta'] = meta;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort.join(base_1.COLLECTION_FORMATS.csv);
            }
            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }
            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.RelationsApiAxiosParamCreator = RelationsApiAxiosParamCreator;
/**
 * RelationsApi - functional programming interface
 * @export
 */
const RelationsApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.RelationsApiAxiosParamCreator)(configuration);
    return {
        /**
         * Retrieve a single relation by unique identifier.
         * @summary Retrieve a Relation
         * @param {string | number} id Relations id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelation(id, fields, meta, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getRelation(id, fields, meta, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * List the relations.
         * @summary List Relations
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelations(fields, limit, offset, meta, sort, filter, search, page, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getRelations(fields, limit, offset, meta, sort, filter, search, page, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.RelationsApiFp = RelationsApiFp;
/**
 * RelationsApi - factory interface
 * @export
 */
const RelationsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.RelationsApiFp)(configuration);
    return {
        /**
         * Retrieve a single relation by unique identifier.
         * @summary Retrieve a Relation
         * @param {string | number} id Relations id
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {string} [meta] What metadata to return in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelation(id, fields, meta, options) {
            return localVarFp.getRelation(id, fields, meta, options).then((request) => request(axios, basePath));
        },
        /**
         * List the relations.
         * @summary List Relations
         * @param {Array<string>} [fields] Control what fields are being returned in the object.
         * @param {number} [limit] A limit on the number of objects that are returned.
         * @param {number} [offset] How many items to skip when fetching data.
         * @param {string} [meta] What metadata to return in the response.
         * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
         * @param {Array<string>} [filter] Select items in collection by given conditions.
         * @param {string} [search] Filter by items that contain the given search query in one of their fields.
         * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelations(fields, limit, offset, meta, sort, filter, search, page, options) {
            return localVarFp.getRelations(fields, limit, offset, meta, sort, filter, search, page, options).then((request) => request(axios, basePath));
        },
    };
};
exports.RelationsApiFactory = RelationsApiFactory;
/**
 * RelationsApi - object-oriented interface
 * @export
 * @class RelationsApi
 * @extends {BaseAPI}
 */
class RelationsApi extends base_1.BaseAPI {
    /**
     * Retrieve a single relation by unique identifier.
     * @summary Retrieve a Relation
     * @param {string | number} id Relations id
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {string} [meta] What metadata to return in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationsApi
     */
    getRelation(id, fields, meta, options) {
        return (0, exports.RelationsApiFp)(this.configuration).getRelation(id, fields, meta, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * List the relations.
     * @summary List Relations
     * @param {Array<string>} [fields] Control what fields are being returned in the object.
     * @param {number} [limit] A limit on the number of objects that are returned.
     * @param {number} [offset] How many items to skip when fetching data.
     * @param {string} [meta] What metadata to return in the response.
     * @param {Array<string>} [sort] How to sort the returned items. &#x60;sort&#x60; is a CSV of fields used to sort the fetched items. Sorting defaults to ascending (ASC) order but a minus sign (&#x60; - &#x60;) can be used to reverse this to descending (DESC) order. Fields are prioritized by their order in the CSV. You can also use a &#x60; ? &#x60; to sort randomly.
     * @param {Array<string>} [filter] Select items in collection by given conditions.
     * @param {string} [search] Filter by items that contain the given search query in one of their fields.
     * @param {number} [page] Cursor for use in pagination. Often used in combination with limit.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationsApi
     */
    getRelations(fields, limit, offset, meta, sort, filter, search, page, options) {
        return (0, exports.RelationsApiFp)(this.configuration).getRelations(fields, limit, offset, meta, sort, filter, search, page, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.RelationsApi = RelationsApi;
/**
 * ServerApi - axios parameter creator
 * @export
 */
const ServerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Ping, pong. Ping.. pong.
         * @summary Ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/server/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Perform a system status check and return the options.
         * @summary System Info
         * @param {number} superAdminToken The first time you create a project, the provided token will be saved and required for subsequent project installs. It can also be found and configured in &#x60;/config/__api.json&#x60; on your server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serverInfo: (superAdminToken, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'superAdminToken' is not null or undefined
            (0, common_1.assertParamExists)('serverInfo', 'superAdminToken', superAdminToken);
            const localVarPath = `/server/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (superAdminToken !== undefined) {
                localVarQueryParameter['super_admin_token'] = superAdminToken;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.ServerApiAxiosParamCreator = ServerApiAxiosParamCreator;
/**
 * ServerApi - functional programming interface
 * @export
 */
const ServerApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.ServerApiAxiosParamCreator)(configuration);
    return {
        /**
         * Ping, pong. Ping.. pong.
         * @summary Ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.ping(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Perform a system status check and return the options.
         * @summary System Info
         * @param {number} superAdminToken The first time you create a project, the provided token will be saved and required for subsequent project installs. It can also be found and configured in &#x60;/config/__api.json&#x60; on your server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serverInfo(superAdminToken, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.serverInfo(superAdminToken, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.ServerApiFp = ServerApiFp;
/**
 * ServerApi - factory interface
 * @export
 */
const ServerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.ServerApiFp)(configuration);
    return {
        /**
         * Ping, pong. Ping.. pong.
         * @summary Ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options) {
            return localVarFp.ping(options).then((request) => request(axios, basePath));
        },
        /**
         * Perform a system status check and return the options.
         * @summary System Info
         * @param {number} superAdminToken The first time you create a project, the provided token will be saved and required for subsequent project installs. It can also be found and configured in &#x60;/config/__api.json&#x60; on your server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serverInfo(superAdminToken, options) {
            return localVarFp.serverInfo(superAdminToken, options).then((request) => request(axios, basePath));
        },
    };
};
exports.ServerApiFactory = ServerApiFactory;
/**
 * ServerApi - object-oriented interface
 * @export
 * @class ServerApi
 * @extends {BaseAPI}
 */
class ServerApi extends base_1.BaseAPI {
    /**
     * Ping, pong. Ping.. pong.
     * @summary Ping
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServerApi
     */
    ping(options) {
        return (0, exports.ServerApiFp)(this.configuration).ping(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Perform a system status check and return the options.
     * @summary System Info
     * @param {number} superAdminToken The first time you create a project, the provided token will be saved and required for subsequent project installs. It can also be found and configured in &#x60;/config/__api.json&#x60; on your server.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServerApi
     */
    serverInfo(superAdminToken, options) {
        return (0, exports.ServerApiFp)(this.configuration).serverInfo(superAdminToken, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.ServerApi = ServerApi;
/**
 * UtilitiesApi - axios parameter creator
 * @export
 */
const UtilitiesApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Returns a random string of given length.
         * @summary Get a Random String
         * @param {number} [length] Length of the random string.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        random: (length, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/utils/random/string`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (length !== undefined) {
                localVarQueryParameter['length'] = length;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Re-sort items in collection based on start and to value of item
         * @summary Sort Items
         * @param {string} collection Collection identifier
         * @param {InlineObject5} [inlineObject5]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sort: (collection, inlineObject5, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'collection' is not null or undefined
            (0, common_1.assertParamExists)('sort', 'collection', collection);
            const localVarPath = `/utils/sort/{collection}`
                .replace(`{${"collection"}}`, encodeURIComponent(String(collection)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inlineObject5, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.UtilitiesApiAxiosParamCreator = UtilitiesApiAxiosParamCreator;
/**
 * UtilitiesApi - functional programming interface
 * @export
 */
const UtilitiesApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.UtilitiesApiAxiosParamCreator)(configuration);
    return {
        /**
         * Returns a random string of given length.
         * @summary Get a Random String
         * @param {number} [length] Length of the random string.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        random(length, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.random(length, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Re-sort items in collection based on start and to value of item
         * @summary Sort Items
         * @param {string} collection Collection of which you want to retrieve the items from.
         * @param {InlineObject5} [inlineObject5]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sort(collection, inlineObject5, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.sort(collection, inlineObject5, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.UtilitiesApiFp = UtilitiesApiFp;
/**
 * UtilitiesApi - factory interface
 * @export
 */
const UtilitiesApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.UtilitiesApiFp)(configuration);
    return {
        /**
         * Returns a random string of given length.
         * @summary Get a Random String
         * @param {number} [length] Length of the random string.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        random(length, options) {
            return localVarFp.random(length, options).then((request) => request(axios, basePath));
        },
        /**
         * Re-sort items in collection based on start and to value of item
         * @summary Sort Items
         * @param {string} collection Collection of which you want to retrieve the items from.
         * @param {InlineObject5} [inlineObject5]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sort(collection, inlineObject5, options) {
            return localVarFp.sort(collection, inlineObject5, options).then((request) => request(axios, basePath));
        },
    };
};
exports.UtilitiesApiFactory = UtilitiesApiFactory;
/**
 * UtilitiesApi - object-oriented interface
 * @export
 * @class UtilitiesApi
 * @extends {BaseAPI}
 */
class UtilitiesApi extends base_1.BaseAPI {
    /**
     * Returns a random string of given length.
     * @summary Get a Random String
     * @param {number} [length] Length of the random string.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    random(length, options) {
        return (0, exports.UtilitiesApiFp)(this.configuration).random(length, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Re-sort items in collection based on start and to value of item
     * @summary Sort Items
     * @param {string} collection Collection of which you want to retrieve the items from.
     * @param {InlineObject5} [inlineObject5]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    sort(collection, inlineObject5, options) {
        return (0, exports.UtilitiesApiFp)(this.configuration).sort(collection, inlineObject5, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.UtilitiesApi = UtilitiesApi;
