import { ProductType } from '@evenfinancial/finance-client';
import { SummaryGroupBy } from '@evenfinancial/supply-reporting-client';

// TODO add group by Week and Month when available
const defaultGroupByOptions = [
  SummaryGroupBy.Day,
  SummaryGroupBy.LeadClientTag,
];

export const earningsGroupByConfig = (productType: ProductType) => {
  switch (productType) {
    case ProductType.Loan:
      return [...defaultGroupByOptions, SummaryGroupBy.Referrer];

    case ProductType.Savings:
      return defaultGroupByOptions.filter(
        (go) => go !== SummaryGroupBy.LeadClientTag
      );

    default:
      return defaultGroupByOptions;
  }
};

export const funnelGroupByConfig = (productType: ProductType) => {
  switch (productType) {
    case ProductType.Loan:
      return [
        ...defaultGroupByOptions,
        SummaryGroupBy.Referrer,
        SummaryGroupBy.CreditRating,
        SummaryGroupBy.LoanPurpose,
      ];

    case ProductType.Savings:
      return defaultGroupByOptions.filter(
        (go) => go !== SummaryGroupBy.LeadClientTag
      );

    case ProductType.CreditCard:
      return [
        ...defaultGroupByOptions,
        // TODO add group by VerifiedCreditBucket and Issuer when available
      ];

    case ProductType.LifeInsurance:
      return [
        ...defaultGroupByOptions,
        // TODO add group by PolicyType when available
      ];

    default:
      return defaultGroupByOptions;
  }
};
