"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStaticOfferInventoryCaps = void 0;
var client_1 = require("../client");
var baseURL = '/inventory/staticOfferInventoryCaps';
var getStaticOfferInventoryCaps = function (params) {
    return client_1.client
        .get(baseURL, { params: params })
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.getStaticOfferInventoryCaps = getStaticOfferInventoryCaps;
