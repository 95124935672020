import {
  resolveFeatureFlags,
  resolveRoles,
  resolveScopes,
} from '@/guards/helpers';

import type { AuthRoute } from '../../routes-with-auth';

/**
 * Determine if a user has access to a given path based on their roles, scopes
 * and feature flags.
 *
 * @returns Returns true if user can access page.
 */
export const verifyUserAccess = (
  route: AuthRoute,
  userRoles: string[] = [],
  userScopes: string[] = [],
  userFeatureFlags: string[] = [],
  passesRouteGuardsBool: boolean
): boolean => {
  const { roles, scopes, featureFlags, canAccess } = route;

  const verified =
    resolveRoles(userRoles, roles) &&
    resolveScopes(userScopes, scopes) &&
    resolveFeatureFlags(userFeatureFlags, featureFlags) &&
    (!canAccess ||
      canAccess({
        featureFlags: userFeatureFlags,
        roles: userRoles,
        scopes: userScopes,
      })) &&
    passesRouteGuardsBool;

  return verified;
};
