import type { ApplicationState } from '@/store';

export const selectNotifications = (state: ApplicationState) =>
  state.notifications.rows;

export const selectUnreadNotificationsCount = (state: ApplicationState) =>
  state.notifications.rows.filter(({ read }) => !read)?.length;

export const selectNotificationsDrawerVisible = (state: ApplicationState) =>
  state.notifications.drawerVisible;

export const selectNotificationsFilter = (state: ApplicationState) =>
  state.notifications.filter;
