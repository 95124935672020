"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Scopes = void 0;
var Scopes;
(function (Scopes) {
    Scopes["AccessTokensCreate"] = "access_tokens:create";
    Scopes["AccessTokensDelete"] = "access_tokens:delete";
    Scopes["AccessTokensRead"] = "access_tokens:read";
    Scopes["AccountAdjustmentsDelete"] = "account_adjustments:delete";
    Scopes["AccountAdjustmentsRead"] = "account_adjustments:read";
    Scopes["AccountDeactivationsRead"] = "account_deactivations:read";
    Scopes["AccountMetadataRead"] = "account_metadata:read";
    Scopes["AccountMetadataUpdate"] = "account_metadata:update";
    Scopes["AccountsCreate"] = "accounts:create";
    Scopes["AccountsRead"] = "accounts:read";
    Scopes["AccountsUpdate"] = "accounts:update";
    Scopes["ApplicationsRead"] = "applications:read";
    Scopes["BenchmarkReportsRead"] = "benchmark_reports:read";
    Scopes["BillingCyclesCreate"] = "billing_cycles:create";
    Scopes["BillingCyclesDelete"] = "billing_cycles:delete";
    Scopes["BillingCyclesRead"] = "billing_cycles:read";
    Scopes["BillingCyclesUpdate"] = "billing_cycles:update";
    Scopes["CommunicationEligibilitiesRead"] = "communication_eligibilities:read";
    Scopes["CompaniesRead"] = "companies:read";
    Scopes["ComplianceRead"] = "compliance:read";
    Scopes["CurrentUserAccountRead"] = "current_user_account:read";
    Scopes["DeactivationsCreate"] = "deactivations:create";
    Scopes["DeactivationsDelete"] = "deactivations:delete";
    Scopes["DeactivationsRead"] = "deactivations:read";
    Scopes["DeduplicationConfigurationsCreate"] = "deduplication_configurations:create";
    Scopes["DeduplicationConfigurationsDelete"] = "deduplication_configurations:delete";
    Scopes["DeduplicationConfigurationsRead"] = "deduplication_configurations:read";
    Scopes["DemandApisRead"] = "demand_apis:read";
    Scopes["DemandContractsCreate"] = "demand_contracts:create";
    Scopes["DemandContractsDelete"] = "demand_contracts:delete";
    Scopes["DemandContractsRead"] = "demand_contracts:read";
    Scopes["DemandDataImportsRead"] = "demand_data_imports:read";
    Scopes["DemandSubAccountInventoryCapsCreate"] = "demand_sub_account_inventory_caps:create";
    Scopes["DemandSubAccountInventoryCapsDelete"] = "demand_sub_account_inventory_caps:delete";
    Scopes["DemandSubAccountInventoryCapsRead"] = "demand_sub_account_inventory_caps:read";
    Scopes["DemandSubAccountInventoryCapsUpdate"] = "demand_sub_account_inventory_caps:update";
    Scopes["DuplicateSummariesRead"] = "duplicate_summaries:read";
    Scopes["EmailDemandContactUs"] = "email:demand-contact-us";
    Scopes["ExpectedEarningsShareCreate"] = "expected_earnings_share:create";
    Scopes["ExpectedEarningsShareRead"] = "expected_earnings_share:read";
    Scopes["FeatureFlagsCreate"] = "feature_flags:create";
    Scopes["FeatureFlagsDelete"] = "feature_flags:delete";
    Scopes["FeatureFlagsRead"] = "feature_flags:read";
    Scopes["FeatureFlagsUpdate"] = "feature_flags:update";
    Scopes["FeedbacksCreate"] = "feedbacks:create";
    Scopes["HotjarRead"] = "hotjar:read";
    Scopes["ImportedLeadEventsRead"] = "imported_lead_events:read";
    Scopes["LeadsCreate"] = "leads:create";
    Scopes["LeadsRead"] = "leads:read";
    Scopes["LedgerItemsCreate"] = "ledger_items:create";
    Scopes["LedgerItemsDelete"] = "ledger_items:delete";
    Scopes["LedgerItemsRead"] = "ledger_items:read";
    Scopes["LedgerItemsUpdate"] = "ledger_items:update";
    Scopes["MciReportsRead"] = "mci_reports:read";
    Scopes["OfferCatalogOffersCreate"] = "offer_catalog:offers:create";
    Scopes["OfferCatalogOffersRead"] = "offer_catalog:offers:read";
    Scopes["OfferCatalogProductOffersCreate"] = "offer_catalog:product_offers:create";
    Scopes["OfferCatalogProductUpdate"] = "offer_catalog:products:update";
    Scopes["OfferCatalogProductVersionsRead"] = "offer_catalog:product_versions:read";
    Scopes["OfferCatalogProductsCreate"] = "offer_catalog:products:create";
    Scopes["OfferCatalogProductsRead"] = "offer_catalog:products:read";
    Scopes["OfferRulesCreate"] = "offer_rules:create";
    Scopes["OfferRulesRead"] = "offer_rules:read";
    Scopes["OfferRulesUpdate"] = "offer_rules:update";
    Scopes["OffersRead"] = "offers:read";
    Scopes["PartnerPagesCreate"] = "partner_pages:create";
    Scopes["PartnerPagesDelete"] = "partner_pages:delete";
    Scopes["PartnerPagesRead"] = "partner_pages:read";
    Scopes["PartnerPagesUpdate"] = "partner_pages:update";
    Scopes["PermissionConfigCheckRead"] = "permission_config_check:read";
    Scopes["PreSelectRulesRead"] = "preselect_rules:read";
    Scopes["PremiumLendersCreate"] = "premium_lenders:create";
    Scopes["PremiumLendersDelete"] = "premium_lenders:delete";
    Scopes["PremiumLendersRead"] = "premium_lenders:read";
    Scopes["PremiumLendersUpdate"] = "premium_lenders:update";
    Scopes["PricingControlsRead"] = "pricing_controls:read";
    Scopes["RateTablesRead"] = "rate_tables:read";
    Scopes["RevenueReportsRead"] = "revenue_reports:read";
    Scopes["RichAccessTokensRead"] = "rich_access_tokens:read";
    Scopes["RuleDemandApiFilterCreate"] = "rule_demand_api_filter:create";
    Scopes["RuleDemandApiFilterDelete"] = "rule_demand_api_filter:delete";
    Scopes["RuleDemandApiFilterRead"] = "rule_demand_api_filter:read";
    Scopes["SelfServiceUserCreate"] = "self_service_user:create";
    Scopes["StateExclusionsRead"] = "state_exclusions:read";
    Scopes["StateExclusionsUpdate"] = "state_exclusions:update";
    Scopes["SubAccountsCreate"] = "sub_accounts:create";
    Scopes["SubAccountsRead"] = "sub_accounts:read";
    Scopes["SubAccountsUpdate"] = "sub_accounts:update";
    Scopes["SupplyContractsCreate"] = "supply_contracts:create";
    Scopes["SupplyContractsDelete"] = "supply_contracts:delete";
    Scopes["SupplyContractsRead"] = "supply_contracts:read";
    Scopes["SupplyDemandInclusionsRead"] = "supply_demand_inclusions:read";
    Scopes["SupplyDemandInclusionsUpdate"] = "supply_demand_inclusions:update";
    Scopes["SupplyDemandSubAccountInventoryCapsCreate"] = "supply_demand_sub_account_inventory_caps:create";
    Scopes["SupplyDemandSubAccountInventoryCapsDelete"] = "supply_demand_sub_account_inventory_caps:delete";
    Scopes["SupplyDemandSubAccountInventoryCapsRead"] = "supply_demand_sub_account_inventory_caps:read";
    Scopes["SupplyDemandSubAccountInventoryCapsUpdate"] = "supply_demand_sub_account_inventory_caps:update";
    Scopes["SupplyInclusionsTemplatesCreate"] = "supply_inclusions_templates:create";
    Scopes["SupplyInclusionsTemplatesDelete"] = "supply_inclusions_templates:delete";
    Scopes["SupplyInclusionsTemplatesRead"] = "supply_inclusions_templates:read";
    Scopes["SupplyInclusionsTemplatesUpdate"] = "supply_inclusions_templates:update";
    Scopes["SupplyMigrationCreate"] = "supply_migration:create";
    Scopes["SupplyMigrationRead"] = "supply_migration:read";
    Scopes["SupplyMigrationUpdate"] = "supply_migration:update";
    Scopes["SupplySummariesRead"] = "supply_summaries:read";
    Scopes["SupplyTemplateApplicationCreate"] = "supply_template_application:create";
    Scopes["SupplyTemplateApplicationRead"] = "supply_template_application:read";
    Scopes["SupplyTemplatesCreate"] = "supply_templates:create";
    Scopes["SupplyTemplatesLendersRead"] = "supply_templates_lenders:read";
    Scopes["SupplyTemplatesRead"] = "supply_templates:read";
    Scopes["SupplyTemplatesUpdate"] = "supply_templates:update";
    Scopes["UserDeactivationsCreate"] = "user_deactivations:create";
    Scopes["UserDeactivationsDelete"] = "user_deactivations:delete";
    Scopes["UserDeactivationsRead"] = "user_deactivations:read";
    Scopes["UsersCreate"] = "users:create";
    Scopes["UsersRead"] = "users:read";
    Scopes["UsersUpdate"] = "users:update";
    Scopes["WhitelistCreate"] = "whitelist:create";
    Scopes["WhitelistDelete"] = "whitelist:delete";
    Scopes["WhitelistRead"] = "whitelist:read";
    Scopes["WhitelistUpdate"] = "whitelist:update";
})(Scopes = exports.Scopes || (exports.Scopes = {}));
