import { uniqBy } from 'lodash';

import { matchPlanFilter } from '@/resources/self-service/payment/util-methods';
import { selectIsImplementationFeeWaived } from '@/resources/self-service/selects';
import type { ApplicationState } from '@/store';

export const selectSaasUserTrial = (state: ApplicationState) =>
  state.saas.userTrial;

export const selectMatchingSaasPlans = (state: ApplicationState) =>
  state.saas.plans.filter((plan) =>
    matchPlanFilter(plan, selectIsImplementationFeeWaived(state))
  );

export const selectSaasPlans = (state: ApplicationState) => {
  return state.saas.plans;
};

export const selectSaasPlansForAccountSubscription = (
  state: ApplicationState
) => {
  const plans = selectSaasPlans(state);

  if (uniqBy(plans, 'tier').length > 1) {
    return plans;
  }

  return [...plans, ...selectMatchingSaasPlans(state)];
};

export const selectCurrentSaasPlan = (state: ApplicationState) =>
  state.saas.plan;

export const selectSaasSubscription = (state: ApplicationState) =>
  state.saas.subscription;

export const selectHasPurchasedAnySubscription = (state: ApplicationState) =>
  Boolean(state.saas.lastPurchasedSubscription);

export const selectLastPurchasedSubscription = (state: ApplicationState) =>
  state.saas.lastPurchasedSubscription;

export const selectSaasSubscriptionPreview = (state: ApplicationState) =>
  state.saas.subscriptionPreview;

export const selectSaasSubscriptionError = (state: ApplicationState) =>
  state.saas.subscriptionCancelError;

export const selectSaasShowGettingStartedModal = (state: ApplicationState) =>
  state.saas.showGettingStartedModal;
