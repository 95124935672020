"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectSupplySubAccountToDuplicate = exports.selectHasBothSubAccountsTypes = exports.selectHasDemandSubAccounts = exports.selectHasSupplySubAccounts = exports.selectAllSubAccountsKeyedByUuid = exports.selectAllSubAccounts = exports.selectSupplySubAccounts = exports.selectDemandSubAccounts = void 0;
var lodash_1 = require("lodash");
var reselect_1 = require("reselect");
var selectDemandSubAccounts = function (state) {
    return state.subAccounts.demand;
};
exports.selectDemandSubAccounts = selectDemandSubAccounts;
var selectSupplySubAccounts = function (state) {
    return state.subAccounts.supply;
};
exports.selectSupplySubAccounts = selectSupplySubAccounts;
exports.selectAllSubAccounts = (0, reselect_1.createSelector)([exports.selectDemandSubAccounts, exports.selectSupplySubAccounts], function (demandSubAccounts, supplySubAccounts) {
    return demandSubAccounts.concat(supplySubAccounts);
});
exports.selectAllSubAccountsKeyedByUuid = (0, reselect_1.createSelector)([exports.selectAllSubAccounts], function (subAccounts) { return (0, lodash_1.keyBy)(subAccounts, 'uuid'); });
var selectHasSupplySubAccounts = function (state) {
    return Boolean(state.subAccounts.supply.length);
};
exports.selectHasSupplySubAccounts = selectHasSupplySubAccounts;
var selectHasDemandSubAccounts = function (state) {
    return Boolean(state.subAccounts.demand.length);
};
exports.selectHasDemandSubAccounts = selectHasDemandSubAccounts;
exports.selectHasBothSubAccountsTypes = (0, reselect_1.createSelector)([exports.selectHasSupplySubAccounts, exports.selectHasDemandSubAccounts], function (hasSupplySubAccounts, hasDemandSubAccounts) {
    return hasSupplySubAccounts && hasDemandSubAccounts;
});
var selectSupplySubAccountToDuplicate = function (_a) {
    var subAccounts = _a.subAccounts;
    return subAccounts.supply.find(function (_a) {
        var uuid = _a.uuid;
        return uuid === subAccounts.duplicateUuid;
    });
};
exports.selectSupplySubAccountToDuplicate = selectSupplySubAccountToDuplicate;
