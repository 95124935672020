import type { AxiosResponse } from 'axios';
import uniq from 'lodash/uniq';
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import type { ActionType } from 'typesafe-actions';

import type { RichAccessToken } from '@evenfinancial/auth-client';

import { client, getSupplyContracts } from '@portal/api-client';
import type { PortalSupplyContract } from '@portal/api-models';
import { getPortalError } from '@portal/common';
import { richAccessTokenByUuidRequestAction } from '@portal/store/dist/user';

import * as actions from './actions';
import { SupplyContractsActionType } from './types';

export function* getSupplyContractsAll() {
  try {
    const supplyContracts: PortalSupplyContract[] = yield call(
      getSupplyContracts
    );

    const accessTokenUuids = uniq(supplyContracts.map((c) => c.createdBy));

    const { data: rats }: AxiosResponse<Record<string, RichAccessToken>> =
      yield call([client, 'post'], '/auth/rich_access_tokens', {
        accessTokenUuids,
      });

    yield put(richAccessTokenByUuidRequestAction.success(rats));

    yield put(
      actions.getSupplyContractsAll.success({
        response: supplyContracts,
      })
    );
  } catch (err: unknown) {
    const portalError = getPortalError(err);

    yield put(actions.getSupplyContractsAll.failure(portalError));
  }
}

export function* getSupplyContractsBySubaccountUuid(
  action: ActionType<
    typeof actions.getSupplyContractsBySubaccountUuidAction.request
  >
) {
  try {
    const supplyContracts: PortalSupplyContract[] = yield call(
      getSupplyContracts,
      {
        subAccountUuid: action.payload.uuid,
      }
    );

    const accessTokenUuids = supplyContracts.map((c) => c.createdBy);

    const { data: rats }: AxiosResponse<Record<string, RichAccessToken>> =
      yield call([client, 'post'], '/auth/rich_access_tokens', {
        accessTokenUuids,
      });

    yield put(richAccessTokenByUuidRequestAction.success(rats));

    yield put(
      actions.getSupplyContractsBySubaccountUuidAction.success({
        response: supplyContracts,
        uuid: action.payload.uuid,
      })
    );
  } catch (err: unknown) {
    const portalError = getPortalError(err);

    yield put(
      actions.getSupplyContractsBySubaccountUuidAction.failure(portalError)
    );
  }
}

function* watchGetAllRequest() {
  yield takeLatest(
    SupplyContractsActionType.GET_ALL_REQUEST,
    getSupplyContractsAll
  );
}

function* watchGetBySubAccountUuidRequest() {
  yield takeEvery(
    SupplyContractsActionType.GET_SUPPLY_CONTRACTS_BY_SUBACCOUNT_UUID_REQUEST,
    getSupplyContractsBySubaccountUuid
  );
}

export function* supplyContractsSaga() {
  yield all([fork(watchGetAllRequest), fork(watchGetBySubAccountUuidRequest)]);
}
