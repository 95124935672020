import { call, put, takeEvery } from 'redux-saga/effects';
import type { ActionType } from 'typesafe-actions';

import { client } from '@portal/api-client';
import type { PortalRuleDemandApiFilter } from '@portal/api-models';
import { getPortalError } from '@portal/common';
import { richAccessTokenByUuidRequestAction } from '@portal/store/dist/user';
import { Toaster } from '@portal/ui-lib';

import { OriginatorActionType } from '../actionTypes';
import * as actions from '../actions';

const basePath = '/originator/admin/rule-demand-api-filters';

const getAccessTokensUuidsFromRuleDemandApiFilters = (
  ruleDemandApiFilters: PortalRuleDemandApiFilter[]
) => {
  const accessTokenUuidMap = ruleDemandApiFilters.reduce(
    (accum: Record<string, ''>, { createdBy, deletedBy }) => {
      if (createdBy && !accum?.[createdBy]) {
        accum[createdBy] = '';
      }

      if (deletedBy && !accum?.[deletedBy]) {
        accum[deletedBy] = '';
      }

      return accum;
    },
    {}
  );

  return Object.keys(accessTokenUuidMap);
};

export function* getRuleDemandAPIFiltersBySubAccountUuid(
  action: ActionType<
    typeof actions.ruleDemandApiFiltersBySubAccountUuidRequestAction.request
  >
) {
  try {
    const { demandSubAccountUuid } = action.payload;

    const activeItems: PortalRuleDemandApiFilter[] = yield call(async () =>
      client
        .get(`${basePath}`, {
          params: { demandSubAccountUuid, isDeleted: false },
        })
        .then(({ data }) => data)
    );

    const inactiveItems: PortalRuleDemandApiFilter[] = yield call(async () =>
      client
        .get(`${basePath}`, {
          params: { demandSubAccountUuid, isDeleted: true },
        })
        .then(({ data }) => data)
    );

    const uuids = getAccessTokensUuidsFromRuleDemandApiFilters(
      activeItems.concat(inactiveItems)
    );

    yield put(
      richAccessTokenByUuidRequestAction.request({
        uuids,
      })
    );

    yield put(
      actions.ruleDemandApiFiltersBySubAccountUuidRequestAction.success({
        activeItems,
        demandSubAccountUuid,
        inactiveItems,
      })
    );
  } catch (error: any) {
    yield put(
      actions.ruleDemandApiFiltersBySubAccountUuidRequestAction.failure(error)
    );
  }
}

export function* createRuleDemandAPIFilter(
  action: ActionType<typeof actions.createRuleDemandAPIFilter.request>
) {
  try {
    const { ruleDemandAPIFilter } = action.payload;
    const { demandSubAccountUuid } = ruleDemandAPIFilter;

    const createdRuleDemandAPIFilter: PortalRuleDemandApiFilter = yield call(
      async () =>
        client
          .post<PortalRuleDemandApiFilter>(basePath, ruleDemandAPIFilter)
          .then(({ data }) => data)
    );

    yield put(
      richAccessTokenByUuidRequestAction.request({
        uuids: getAccessTokensUuidsFromRuleDemandApiFilters([
          createdRuleDemandAPIFilter,
        ]),
      })
    );
    yield put(
      actions.createRuleDemandAPIFilter.success({
        createdRuleDemandAPIFilter,
        demandSubAccountUuid,
      })
    );

    yield call(
      Toaster.success,
      'Lender provided knockout criteria created successfully'
    );

    yield put(
      actions.ruleDemandApiFiltersBySubAccountUuidRequestAction.request({
        demandSubAccountUuid,
      })
    );
  } catch (error: any) {
    const portalError = getPortalError(error);

    yield put(actions.createRuleDemandAPIFilter.failure(portalError));
  }
}

export function* deleteRuleDemandAPIFilter(
  action: ActionType<typeof actions.deleteRuleDemandAPIFilter.request>
) {
  try {
    const { id, demandSubAccountUuid } = action.payload;

    const deletedRuleDemandAPIFilter: PortalRuleDemandApiFilter = yield call(
      async () => client.delete(`${basePath}/${id}/`).then(({ data }) => data)
    );

    yield put(
      actions.deleteRuleDemandAPIFilter.success({
        deletedRuleDemandAPIFilter,
        demandSubAccountUuid,
      })
    );
  } catch (error: any) {
    yield put(actions.deleteRuleDemandAPIFilter.failure(error));
  }
}

export function* watchRuleDemandAPIFiltersBySubAccountUuid() {
  yield takeEvery(
    OriginatorActionType.RULE_DEMAND_API_FILTERS_BY_SUB_ACCOUNT_UUID_REQUEST,
    getRuleDemandAPIFiltersBySubAccountUuid
  );
}

export function* watchCreateRuleDemandAPIFilter() {
  yield takeEvery(
    OriginatorActionType.CREATE_RULE_DEMAND_API_FILTER_REQUEST_CLIENT,
    createRuleDemandAPIFilter
  );
}

export function* watchDeleteRuleDemandAPIFilter() {
  yield takeEvery(
    OriginatorActionType.DELETE_RULE_DEMAND_API_FILTERS_REQUEST,
    deleteRuleDemandAPIFilter
  );
}
