import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import SyntaxHighlighter from 'react-syntax-highlighter';

import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, message } from 'antd';

import { getIsProductionEnv } from '@portal/store/dist/configuration';

import {
  formToEmbedUrl,
  genEmbedBaseUrl,
  genEmbedSnippet,
} from '@/resources/partner-page/query-string-mapper';
import { getPartnerPagesByPartnerKeyById } from '@/resources/partner-page/selectors';
import type { ApplicationState } from '@/store';

export interface CopyEmbedCodeModalProvidedProps {
  dismissModal: () => void;
  integrationId?: string;
  visible: boolean;
}

type CopyEmbedCodeModalProps = CopyEmbedCodeModalProvidedProps;

const onCopy = () => message.success('Copied to clipboard!', 1);

const CopyEmbedCodeModal: React.FC<CopyEmbedCodeModalProps> = ({
  visible,
  dismissModal,
  integrationId = '',
}) => {
  const integrationDetails = useSelector((state) =>
    getPartnerPagesByPartnerKeyById(state as ApplicationState, {
      id: Number.parseInt(integrationId),
    })
  );

  const isProductionEnv = useSelector(getIsProductionEnv);

  if (!integrationDetails) {
    return null;
  }

  const url = formToEmbedUrl(
    genEmbedBaseUrl(isProductionEnv || false, integrationDetails.version),
    {
      access_token: integrationDetails['credentials.access_token'],
      company_uuid: integrationDetails['credentials.company_uuid'],
      product_type: integrationDetails.productType,
      pw_fiona: integrationDetails.version === '8' ? 'true' : null,
    }
  );
  const snippet = genEmbedSnippet(
    url,
    genEmbedBaseUrl(isProductionEnv || false, integrationDetails.version)
  );

  return (
    <Modal
      className="copy-embed-code-modal"
      footer={[
        <Button
          key="copy-embed-code-modal-button"
          type="primary"
          onClick={dismissModal}
        >
          OK
        </Button>,
      ]}
      title="Copy Embed Code"
      visible={visible}
      width={700}
      onCancel={dismissModal}
      onOk={dismissModal}
    >
      <div className="copy-embed-code-modal-actions">
        <strong>Copy to clipboard</strong>
        <CopyToClipboard text={snippet} onCopy={onCopy}>
          <Button
            icon={<FontAwesomeIcon icon={faCopy} />}
            size="small"
            type="link"
          />
        </CopyToClipboard>
      </div>
      <SyntaxHighlighter
        wrapLines
        className="copy-embed-code-modal-highlighter"
        language="html"
      >
        {snippet}
      </SyntaxHighlighter>
    </Modal>
  );
};

export default CopyEmbedCodeModal;
