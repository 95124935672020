import Router from 'next/router';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import type { ActionType } from 'typesafe-actions';

import { client } from '@portal/api-client';
import { ComplianceStatus } from '@portal/api-models';
import type { ComplianceItem } from '@portal/api-models';

import { orderCompliancesById } from '@/resources/compliance/utils';
import { IntegrationDataSelector } from '@/resources/integrations/selectors';
import { ImplementationFeePaymentStatus } from '@/resources/integrations/types';
import type { IntegrationRow } from '@/resources/integrations/types';
import { getPartnerPagesByPartnerKeyById } from '@/resources/partner-page/selectors';
import { selectIsPaid } from '@/resources/self-service/selects';
import { isPermanentSelector } from '@/resources/supply-migration/selectors';
import {
  ChannelsActionType,
  ComplianceActionType,
  CreditCardsActionType,
  PersonalLoansActionType,
  SavingsActionType,
} from '@/store/compliance/types';
import type { SubmitForReviewRequestPayload } from '@/store/compliance/types';

import type { ApplicationState } from '..';

import * as actions from './actions';

export function* getPersonalLoans() {
  try {
    const personalLoans: ComplianceItem[] = yield call(async () =>
      client
        .get<ComplianceItem[]>('/compliance/personal_loans')
        .then((res) => res.data)
    );

    yield put(actions.getPersonalLoansAction.success(personalLoans));
  } catch (err: any) {
    yield put(actions.getPersonalLoansAction.failure(err));
  }
}

export function* getCreditCards() {
  try {
    const creditCards: ComplianceItem[] = yield call(async () =>
      client
        .get<ComplianceItem[]>('/compliance/credit_cards')
        .then((res) => res.data)
    );

    yield put(actions.getCreditCardsAction.success(creditCards));
  } catch (err: any) {
    yield put(actions.getCreditCardsAction.failure(err));
  }
}

export function* getSavings() {
  try {
    const savings: ComplianceItem[] = yield call(async () =>
      client
        .get<ComplianceItem[]>('/compliance/savings')
        .then((res) => res.data)
    );

    yield put(actions.getSavingsAction.success(savings));
  } catch (err: any) {
    yield put(actions.getSavingsAction.failure(err));
  }
}

export function* getChannels() {
  try {
    const complianceItems: ComplianceItem[] = yield call(async () =>
      client
        .get<ComplianceItem[]>('/compliance/channels')
        .then((res) => res.data)
    );

    yield put(actions.getChannelsComplianceAction.success(complianceItems));
  } catch (err: any) {
    yield put(actions.getChannelsComplianceAction.failure(err));
  }
}

function* getMyComplianceItems() {
  try {
    const complianceItems: ComplianceItem[] = yield call(async () => {
      return client
        .get<ComplianceItem[]>('/compliance/account')
        .then((res) => res.data);
    });

    yield put(
      actions.myAccountComplianceRequestAction.success(complianceItems)
    );
  } catch (err: any) {
    yield put(actions.myAccountComplianceRequestAction.failure(err));
  }
}

export function* submitForReview(
  action: ActionType<typeof actions.submitForReviewAction.request>
) {
  const { integration } = yield select((state: ApplicationState) =>
    IntegrationDataSelector(state, action.payload.integrationId)
  );

  const feePaymentStatus = (integration as IntegrationRow)
    .implementationFeePaymentStatus;

  if (
    feePaymentStatus === ImplementationFeePaymentStatus.Paid ||
    feePaymentStatus === ImplementationFeePaymentStatus.Waived
  ) {
    yield call(submitForReviewInternal, action.payload);
  } else {
    yield put(actions.submitForReviewRequestPause());
    yield call(redirectToPurchasePlanOrImplementationFee, integration);
  }
}

export function* redirectToPurchasePlanOrImplementationFee(
  integrationRow: IntegrationRow
) {
  const isPaid: boolean = yield select(selectIsPaid);
  const isPermanent: boolean = yield select(isPermanentSelector);

  if (isPaid || isPermanent) {
    yield call(
      [Router, 'push'],
      `/implementation-fee?id=${integrationRow.id}&submit=true`
    );
  } else {
    yield call(
      [Router, 'push'],
      `/account/subscription?id=${integrationRow.id}&submit=true`
    );
  }
}

export function* submitForReviewInternal(
  payload: SubmitForReviewRequestPayload
) {
  const {
    approvedChanges,
    updatedChanges,
    existingCompliance,
    subAccountUuid,
    integrationId,
  } = payload;

  const { integration } = yield select((state: ApplicationState) =>
    IntegrationDataSelector(state, integrationId)
  );
  const existing = yield select((state: ApplicationState) =>
    getPartnerPagesByPartnerKeyById(state, {
      id: Number.parseInt(integrationId),
    })
  );

  try {
    const existingComplianceItem: ComplianceItem = existingCompliance
      ? existingCompliance
      : yield select(({ compliance }: ApplicationState) =>
          orderCompliancesById(compliance.rows).find(
            (row) =>
              row.supplyPartnerSubaccountCompanyUuid ===
              existing?.['credentials.company_uuid']
          )
        );

    if (
      existingComplianceItem &&
      existingComplianceItem.status !== ComplianceStatus.closed
    ) {
      if (updatedChanges) {
        yield call(async () =>
          client
            .post(`/compliance/update/${existingComplianceItem.id}`, {
              updatedChanges,
            })
            .then((res) => res.data)
        );
      }

      yield put(
        actions.submitForReviewAction.success([existingComplianceItem])
      );
    } else {
      const response: ComplianceItem[] = yield call(async () =>
        client
          .post(`/compliance/${subAccountUuid}/submit`, {
            approvedChanges,
            integrationId,
            integrationUrl: integration?.integrationUrl,
            updatedChanges,
          })
          .then((res) => res.data)
      );

      yield put(actions.submitForReviewAction.success(response));
    }
  } catch (err: any) {
    yield put(actions.submitForReviewAction.failure(err));
  }
}

function* submitForLenderReview(
  action: ActionType<typeof actions.submitForLenderReviewAction.request>
) {
  const subAccoutnUuid = action.payload.subAccountUuid;
  const demandSubAccountUuid = action.payload.demandSubAccountUuid;
  const epicKey = action.payload.epicKey;

  const integrationId = action.payload.integrationId;

  try {
    const response: ComplianceItem[] = yield call(async () =>
      client
        .post(
          `/compliance/${subAccoutnUuid}/lenders/${demandSubAccountUuid}/submit/`,
          { epicKey, integrationId }
        )
        .then((res) => res.data)
    );

    yield put(actions.submitForLenderReviewAction.success(response));
  } catch (err: any) {
    yield put(actions.submitForLenderReviewAction.failure(err));
  }
}

function* watchGetPersonalLoans() {
  yield takeEvery(PersonalLoansActionType.GET_REQUEST, getPersonalLoans);
}

function* watchGetCreditCards() {
  yield takeEvery(CreditCardsActionType.GET_REQUEST, getCreditCards);
}

function* watchGetSavings() {
  yield takeEvery(SavingsActionType.GET_REQUEST, getSavings);
}

function* watchGetChannels() {
  yield takeEvery(ChannelsActionType.GET_REQUEST, getChannels);
}

function* watchGetMyComplianceItems() {
  yield takeEvery(
    ComplianceActionType.COMPLIANCE_ITEMS_MY_ACCOUNT_REQUEST,
    getMyComplianceItems
  );
}

function* watchSubmitForReview() {
  yield takeEvery(
    ComplianceActionType.SUBMIT_FOR_REVIEW_REQUEST,
    submitForReview
  );
}

function* watchSubmitForLenderReview() {
  yield takeEvery(
    ComplianceActionType.SUBMIT_FOR_LENDER_REVIEW_REQUEST,
    submitForLenderReview
  );
}

export function* complianceSaga() {
  yield all([
    fork(watchGetPersonalLoans),
    fork(watchGetCreditCards),
    fork(watchGetSavings),
    fork(watchGetChannels),
    fork(watchGetMyComplianceItems),
    fork(watchSubmitForReview),
    fork(watchSubmitForLenderReview),
  ]);
}
