import { HYDRATE } from 'next-redux-wrapper';
import type { Reducer } from 'redux';

import type { HydrateAction } from '../actions';
import { reconcileState } from '../utils';

import { PreSelectRuleActionType } from './types';
import type { PreSelectRuleAction, PreSelectRuleState } from './types';

export const initialState: PreSelectRuleState = {
  haveAllPreSelectRulesBeenRequested: false,
  rows: [],
};

export const reducer: Reducer<
  PreSelectRuleState,
  PreSelectRuleAction | HydrateAction
> = (
  state: PreSelectRuleState = initialState,
  action: PreSelectRuleAction | HydrateAction
) => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...reconcileState(action.payload.preSelectRules, initialState),
      };
    }

    case PreSelectRuleActionType.GET_ALL_SUCCESS: {
      return {
        ...state,
        haveAllPreSelectRulesBeenRequested: true,
        rows: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
