import * as React from 'react';

import { faCircleDollar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import numeral from 'numeral';

import { ClicksIcon, LeadsIcon } from '@portal/ui-lib';

import type { TopTotalsPanelItem } from '@/components/reporting/top-totals-panel';

export const savingsFunnelPanelConfig: TopTotalsPanelItem[] = [
  {
    icon: <LeadsIcon />,
    key: 'numLeads',
    title: 'Leads',
    tooltip: 'Count of users that enter a deposit amount',
  },
  {
    icon: <ClicksIcon />,
    key: 'numClicks',
    title: 'Clicked Offers',
    tooltip: 'Total count of unique clicks for savings offers',
  },
  {
    formatter: (value) => numeral(value).format('$0,0.00'),
    icon: <FontAwesomeIcon icon={faCircleDollar} />,
    key: 'totalPayout',
    title: 'Attributed Earnings',
    tooltip:
      'Estimated value of payout based on the leads attributed to the dates in the report. Please note that Attributed Earnings will vary from your Total Earnings value because savings applications often take several days for a consumer to fund. You can view your actual payout at any time in the Earnings Report under “Total Earnings”',
  },
];
