"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FinancialPartnerStatus = void 0;
var FinancialPartnerStatus;
(function (FinancialPartnerStatus) {
    FinancialPartnerStatus["Approved"] = "Approved";
    FinancialPartnerStatus["Draft"] = "Draft";
    FinancialPartnerStatus["IdentifiedViolation"] = "Identified Violation";
    FinancialPartnerStatus["PendingComplianceReview"] = "Pending Compliance Review";
    FinancialPartnerStatus["Rejected"] = "Rejected";
    FinancialPartnerStatus["RequiresLenderApproval"] = "Requires Lender Approval";
    FinancialPartnerStatus["WaitingOnSupplyPartner"] = "Changes Requested";
})(FinancialPartnerStatus = exports.FinancialPartnerStatus || (exports.FinancialPartnerStatus = {}));
