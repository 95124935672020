import * as React from 'react';

import {
  faCircleExclamation,
  faCircleInfo,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AntTooltip from 'antd/lib/tooltip';
import classNames from 'classnames';
import get from 'lodash/get';

import {
  PortalFormFieldType,
  TooltipPosition,
} from '@/components/form/portal-form/types';
import type { PortalFormField } from '@/components/form/portal-form/types';

export interface FieldTooltipProps<T extends {}> {
  field: PortalFormField<T>;
  position: TooltipPosition;
  showSmallTooltipIcon?: boolean;
}

const FieldTooltip = <T extends {}>({
  field,
  position,
  showSmallTooltipIcon,
}: FieldTooltipProps<T>) => {
  const { showLabel, type, tooltip: tooltipText } = field;

  const isCheckboxType = type === PortalFormFieldType.Checkbox;

  const enforcedPosition =
    isCheckboxType && !showLabel
      ? TooltipPosition.Right
      : get(field, 'tooltipPosition', TooltipPosition.Top);

  if (tooltipText && position === enforcedPosition) {
    return (
      <AntTooltip overlay={tooltipText} title="">
        {showSmallTooltipIcon ? (
          <FontAwesomeIcon
            className={classNames(
              'portal-form-field-tooltip',
              'small-tooltip',
              enforcedPosition,
              { checkbox: isCheckboxType }
            )}
            icon={faCircleExclamation}
          />
        ) : (
          <FontAwesomeIcon
            className={classNames(
              'portal-form-field-tooltip',
              enforcedPosition,
              {
                checkbox: isCheckboxType,
              }
            )}
            icon={faCircleInfo}
          />
        )}
      </AntTooltip>
    );
  }

  return null;
};

export default FieldTooltip;
