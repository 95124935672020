"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasFeatures = exports.hasScopes = exports.hasRoles = exports.hasAccess = void 0;
const lodash_1 = require("lodash");
const hasAccess = (provisioned, requesting, hasAll = true) => {
    if (!requesting || !requesting.length) {
        return true;
    }
    const intersectionMatches = (0, lodash_1.intersection)(requesting, provisioned);
    if (hasAll) {
        return intersectionMatches.length === requesting.length;
    }
    return Boolean(intersectionMatches.length);
};
exports.hasAccess = hasAccess;
const hasRoles = (provisionedRoles, roles, hasAll = true) => (0, exports.hasAccess)(provisionedRoles, roles, hasAll);
exports.hasRoles = hasRoles;
const hasScopes = (provisionedScopes, scopes, hasAll = true) => (0, exports.hasAccess)(provisionedScopes, scopes, hasAll);
exports.hasScopes = hasScopes;
const hasFeatures = (provisionedFeatureFlags, featureFlags, hasAll = true) => (0, exports.hasAccess)(provisionedFeatureFlags, featureFlags, hasAll);
exports.hasFeatures = hasFeatures;
