"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductVersions = exports.updateCurrentProduct = exports.updateProduct = exports.updateOffer = exports.createOfferCatalogProduct = exports.createOfferCatalogProductOffer = exports.getOfferCatalogProductVersion = exports.getOfferCatalogProducts = exports.getOfferCatalogOffer = exports.getOfferCatalogOffers = void 0;
var react_query_1 = require("react-query");
var client_1 = require("../client");
var baseUrl = '/ews/offer-catalog';
var offersEndpoint = "".concat(baseUrl, "/offers");
var productsEndpoint = "".concat(baseUrl, "/products");
var getOfferCatalogOffers = function (params) {
    return client_1.client
        .get(offersEndpoint, { params: params })
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.getOfferCatalogOffers = getOfferCatalogOffers;
var getOfferCatalogOffer = function (_a) {
    var uuid = _a.uuid, params = __rest(_a, ["uuid"]);
    return client_1.client
        .get("".concat(offersEndpoint, "/").concat(uuid), { params: params })
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.getOfferCatalogOffer = getOfferCatalogOffer;
var getOfferCatalogProducts = function () {
    return client_1.client
        .get(productsEndpoint)
        .then(function (_a) {
        var data = _a.data;
        return data.sort(function (a, b) { return a.displayName.localeCompare(b.displayName); });
    });
};
exports.getOfferCatalogProducts = getOfferCatalogProducts;
var getOfferCatalogProductVersion = function (_a) {
    var type = _a.type, version = _a.version;
    return client_1.client
        .get("".concat(productsEndpoint, "/").concat(type, "/versions/").concat(version))
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.getOfferCatalogProductVersion = getOfferCatalogProductVersion;
var createOfferCatalogProductOffer = function (_a) {
    var type = _a.type, createData = _a.createData;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            return [2, client_1.client
                    .post("".concat(productsEndpoint, "/").concat(type, "/offers"), createData)
                    .then(function (_a) {
                    var data = _a.data;
                    return data;
                })];
        });
    });
};
exports.createOfferCatalogProductOffer = createOfferCatalogProductOffer;
var createOfferCatalogProduct = function (createData) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2, client_1.client.post(productsEndpoint, createData).then(function (_a) {
            var data = _a.data;
            return data;
        })];
}); }); };
exports.createOfferCatalogProduct = createOfferCatalogProduct;
var updateOffer = function (_a) {
    var uuid = _a.uuid, updateData = _a.updateData, currentVersion = _a.currentVersion;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            return [2, client_1.client
                    .put("".concat(offersEndpoint, "/").concat(uuid, "/versions/").concat(currentVersion), updateData)
                    .then(function (_a) {
                    var data = _a.data;
                    return data;
                })];
        });
    });
};
exports.updateOffer = updateOffer;
var updateProduct = function (_a) {
    var type = _a.type, currentVersion = _a.currentVersion, updateData = _a.updateData;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            return [2, client_1.client
                    .put("".concat(productsEndpoint, "/").concat(type, "/versions/").concat(currentVersion), updateData)
                    .then(function (_a) {
                    var data = _a.data;
                    return data;
                })];
        });
    });
};
exports.updateProduct = updateProduct;
var updateCurrentProduct = function (_a) { return __awaiter(void 0, void 0, void 0, function () {
    var type = _a.type, updateRequest = __rest(_a, ["type"]);
    return __generator(this, function (_b) {
        return [2, client_1.client
                .post("".concat(productsEndpoint, "/").concat(type, "/versions"), updateRequest)
                .then(function (_a) {
                var data = _a.data;
                return data;
            })];
    });
}); };
exports.updateCurrentProduct = updateCurrentProduct;
var useProductVersions = function (type, versions) {
    return (0, react_query_1.useQueries)(versions.map(function (version) { return ({
        queryFn: function () { return (0, exports.getOfferCatalogProductVersion)({ type: type, version: version }); },
        queryKey: ['offerCatalogProductVersion', { type: type, version: version }],
    }); }));
};
exports.useProductVersions = useProductVersions;
