"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPermissions = void 0;
const defaults_1 = require("../permissions/defaults");
const get_flag_permissions_1 = require("./get-flag-permissions");
const get_role_permissions_1 = require("./get-role-permissions");
const get_scope_permissions_1 = require("./get-scope-permissions");
const get_tier_permissions_1 = require("./get-tier-permissions");
const get_whitelist_config_1 = require("./get-whitelist-config");
const getPermissions = ({ roles, featureFlags, scopes, whitelist, }) => {
    const rolesSet = new Set(roles);
    const flagsSet = new Set(featureFlags);
    const scopesSet = new Set(scopes);
    const whitelistSet = new Set(whitelist);
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, defaults_1.permissionConfigDefaults), (0, get_role_permissions_1.getRolePermissions)(rolesSet)), (0, get_scope_permissions_1.getScopePermissions)(scopesSet)), (0, get_tier_permissions_1.getTierPermissions)(rolesSet)), (0, get_flag_permissions_1.getFlagPermissions)(flagsSet)), (0, get_whitelist_config_1.getWhitelistConfig)(whitelistSet));
};
exports.getPermissions = getPermissions;
