import isEmpty from 'lodash/isEmpty';

import type { ProfileMeta } from '@portal/api-models';
import { selectPermissions } from '@portal/store/dist/user/selectors';

import { hasTrialExpired } from '@/resources/saas/helpers';
import type { ApplicationState } from '@/store';

export enum RouteGuards {
  MigrationTrial = 'check_user_migration_trial',
  OnboardingFlow = 'check_onboarding_flow',
  PlanTrial = 'check_user_trial',
  RestrictCRM = 'restrict_crm',
}

/**
 * Returns true if access is allowed and false if access is denied.
 */
export type RouteGuardFunc = (payload: {
  profileMeta: ProfileMeta;
  state: ApplicationState;
}) => boolean;

/**
 * Restrict users whose trial has expired or do not have a subscription.
 */
export const planTrialGuard: RouteGuardFunc = ({ state }) => {
  const { hasScaleUserRole } = selectPermissions(state);

  if (hasScaleUserRole) {
    return true;
  }

  const {
    saas: { userTrial, subscription },
  } = state;

  return !hasTrialExpired(userTrial?.endDate) || Boolean(subscription);
};

/**
 * Restrict migration users whose trial has expired or do not have a subscription.
 */
export const migrationTrialGuard: RouteGuardFunc = ({ state, profileMeta }) => {
  const { supplyMigration } = profileMeta;

  if (!isEmpty(supplyMigration)) {
    return planTrialGuard({ profileMeta, state });
  }

  return true;
};

/**
 * Restrict non-migration users who do not have an active subscription.
 */
export const onboardingFlowGuard: RouteGuardFunc = ({ state, profileMeta }) => {
  const {
    saas: { subscription },
    supplyMigration: { settings },
  } = state;

  const permissions = selectPermissions(state);

  if (permissions.hasCrmUserRole) {
    return true;
  }

  if (permissions.hasScaleUserRole) {
    return true;
  }

  const supplyMigrationExists = !isEmpty(profileMeta?.supplyMigration);

  if (!supplyMigrationExists && !settings) {
    return Boolean(subscription);
  }

  return true;
};

/**
 * Restrict access to CRM users.
 */
export const restrictCRMGuard: RouteGuardFunc = ({ state }) => {
  const permissions = selectPermissions(state);

  return !permissions.hasCrmUserRole;
};

export const passesRouteGuards = (
  state: ApplicationState,
  profileMeta: ProfileMeta,
  guards?: RouteGuards[],
  passesAll: boolean = true
) => {
  if (!guards || !profileMeta) {
    return true;
  }

  const evaluateGuard = (guard: RouteGuards) => {
    switch (guard) {
      case RouteGuards.PlanTrial:
        return planTrialGuard({ profileMeta, state });

      case RouteGuards.MigrationTrial:
        return migrationTrialGuard({ profileMeta, state });

      case RouteGuards.OnboardingFlow:
        return onboardingFlowGuard({ profileMeta, state });

      case RouteGuards.RestrictCRM:
        return restrictCRMGuard({ profileMeta, state });

      default:
        return true;
    }
  };

  if (passesAll) {
    return guards.every(evaluateGuard);
  }

  return guards.some(evaluateGuard);
};
