import { HYDRATE } from 'next-redux-wrapper';
import type { Reducer } from 'redux';

import type { HydrateAction } from '@/store/actions';
import { reconcileState } from '@/store/utils';

import { DemandConfigurationsActionType } from './types';
import type {
  DemandConfigurationsAction,
  DemandConfigurationsState,
} from './types';

export const initialState: DemandConfigurationsState = {};

export const reducer: Reducer<
  DemandConfigurationsState,
  DemandConfigurationsAction | HydrateAction
> = (
  state: DemandConfigurationsState = initialState,
  action: DemandConfigurationsAction | HydrateAction
) => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...reconcileState(action.payload.demandConfigurations, initialState),
      };
    }

    case DemandConfigurationsActionType.GET_BY_PRODUCT_TYPE_SUCCESS: {
      return {
        ...state,
        [action.payload.productType]: action.payload.response,
      };
    }

    default: {
      return state;
    }
  }
};
