import {
  CreditCardStep,
  DepositsStep,
  LifeInsuranceStep,
  LoanStep,
  MortgageStep,
} from '@evenfinancial/even-ts-static';

export enum CreditCardFlows {
  Redirect = 'redirect',
  Remarketing = 'remarketing',
  SinglePagePreSelect = 'sp-pre-select',
  StepByStepPreselect = 'sbs-pre-select',
}

export const ProductStep = {
  ...CreditCardStep,
  ...DepositsStep,
  ...LifeInsuranceStep,
  ...LoanStep,
  ...MortgageStep,
};
