"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInternalUserType = void 0;
const INTERNAL_EMAIL = ['@engine.tech', '@moneylion.com'];
const LEGACY_EVEN_EMAIL = ['@evenfinancial.com', '@fiona.com'];
const CONTRACTOR_EMAIL = ['@contractors.engine.tech'];
const isInternalUserType = (email = '') => INTERNAL_EMAIL.concat(LEGACY_EVEN_EMAIL)
    .concat(CONTRACTOR_EMAIL)
    .some((emailPart) => email.endsWith(emailPart));
exports.isInternalUserType = isInternalUserType;
