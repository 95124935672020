import { createReducer } from 'typesafe-actions';

import { nextHydrate } from '@/store/actions';
import type { HydrateAction } from '@/store/actions';
import { reconcileState } from '@/store/utils';

import {
  demandContractLoadingByIdAction,
  getAllDemandContracts,
  getDemandContractById,
} from './actions';
import type { DemandContractsAction, DemandContractsState } from './types';

export const initialState: DemandContractsState = {
  loadingContractById: false,
  rows: [],
};

export const demandReducer = createReducer<
  DemandContractsState,
  DemandContractsAction | HydrateAction
>(initialState)
  .handleAction(nextHydrate, (state, { payload }) => ({
    ...state,
    ...reconcileState(payload.demandContracts, initialState),
  }))

  .handleAction(getAllDemandContracts.success, (state, { payload: rows }) => ({
    ...state,
    rows: [...state.rows, ...rows],
  }))
  .handleAction(getDemandContractById.success, (state, { payload }) => {
    const [newContract] = payload;

    const exists = state.rows.find(
      (contract) => contract.id === newContract?.id
    );

    if (!exists && newContract) {
      return { ...state, rows: [...state.rows, newContract] };
    }

    return state;
  })
  .handleAction(
    demandContractLoadingByIdAction,
    (state, { payload: loadingContractById }) => ({
      ...state,
      loadingContractById,
    })
  );
