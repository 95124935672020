import { orderBy } from 'lodash';

import type { ComplianceItem } from '@portal/api-models';

export const EVEN_INTERNAL_PARTNER_NAME = 'Even Internal';

export const orderCompliancesById = (compliances: ComplianceItem[]) => {
  return orderBy(
    compliances,
    (compliance) => {
      return parseInt(compliance.id.slice(4));
    },
    'desc'
  );
};

export const getComplianceByPartnerName = (
  companyUuid: string,
  demandPartnerName: string,
  compliances: ComplianceItem[]
) => {
  return orderCompliancesById(compliances).find(
    (compliance) =>
      compliance.supplyPartnerSubaccountCompanyUuid === companyUuid &&
      compliance.demandPartnerName === demandPartnerName
  );
};

export const getComplianceInternal = (
  companyUuid: string = '',
  compliances: ComplianceItem[]
) =>
  getComplianceByPartnerName(
    companyUuid,
    EVEN_INTERNAL_PARTNER_NAME,
    compliances
  );

export const getComplianceByPartnerCompanyUuid = (
  companyUuid: string,
  demandPartnerSubaccountCompanyUuid: string,
  compliances: ComplianceItem[]
) => {
  return orderCompliancesById(compliances).find(
    (compliance) =>
      compliance.supplyPartnerSubaccountCompanyUuid === companyUuid &&
      compliance.demandPartnerSubaccountCompanyUuid ===
        demandPartnerSubaccountCompanyUuid
  );
};
