"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.payableEventsByIdRequestAction = exports.toggleCreateOneOffLedgerItem = exports.updateAddAdjustmentsForm = exports.toggleAddAdjustmentsForm = exports.payableAddAdjustmentAction = exports.createOneOffLedgerItemRequestAction = exports.settleLedgerItemsRequestAction = exports.regenerateLedgerItemsRequestAction = exports.deletePayableAction = exports.payableAdjustmentDeleteAction = exports.payableAdjustmentsByLedgerItemIdRequestAction = exports.payableAdjustmentsRequestAction = exports.payableIgnoreAction = exports.payableByIdRequestAction = exports.payableRequestAction = exports.payableDetailPage = void 0;
var typesafe_actions_1 = require("typesafe-actions");
var actionTypes_1 = require("./actionTypes");
exports.payableDetailPage = (0, typesafe_actions_1.createAction)(actionTypes_1.PayableActionType.GET_DETAIL_PAGE)();
exports.payableRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.PayableActionType.GET_ALL_REQUEST, actionTypes_1.PayableActionType.GET_ALL_SUCCESS, actionTypes_1.PayableActionType.GET_ALL_FAILURE)();
exports.payableByIdRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.PayableActionType.GET_BY_ID_REQUEST, actionTypes_1.PayableActionType.GET_BY_ID_SUCCESS, actionTypes_1.PayableActionType.GET_BY_ID_FAILURE)();
exports.payableIgnoreAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.PayableActionType.IGNORE_PAYABLE_REQUEST, actionTypes_1.PayableActionType.IGNORE_PAYABLE_SUCCESS, actionTypes_1.PayableActionType.IGNORE_PAYABLE_FAILURE)();
exports.payableAdjustmentsRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.PayableActionType.GET_ALL_ADJUSTMENTS_REQUEST, actionTypes_1.PayableActionType.GET_ALL_ADJUSTMENTS_SUCCESS, actionTypes_1.PayableActionType.GET_ALL_ADJUSTMENTS_FAILURE)();
exports.payableAdjustmentsByLedgerItemIdRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.PayableActionType.GET_ADJUSTMENTS_BY_LEDGER_ITEM_ID_REQUEST, actionTypes_1.PayableActionType.GET_ADJUSTMENTS_BY_LEDGER_ITEM_ID_SUCCESS, actionTypes_1.PayableActionType.GET_ADJUSTMENTS_BY_LEDGER_ITEM_ID_FAILURE)();
exports.payableAdjustmentDeleteAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.PayableActionType.DELETE_ADJUSTMENT_REQUEST, actionTypes_1.PayableActionType.DELETE_ADJUSTMENT_SUCCESS, actionTypes_1.PayableActionType.DELETE_ADJUSTMENT_FAILURE)();
exports.deletePayableAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.PayableActionType.DELETE_PAYABLE_REQUEST, actionTypes_1.PayableActionType.DELETE_PAYABLE_SUCCESS, actionTypes_1.PayableActionType.DELETE_PAYABLE_FAILURE)();
exports.regenerateLedgerItemsRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.PayableActionType.REGENERATE_LEDGER_ITEMS_REQUEST, actionTypes_1.PayableActionType.REGENERATE_LEDGER_ITEMS_SUCCESS, actionTypes_1.PayableActionType.REGENERATE_LEDGER_ITEMS_FAILURE)();
exports.settleLedgerItemsRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.PayableActionType.SETTLE_LEDGER_ITEMS_REQUEST, actionTypes_1.PayableActionType.SETTLE_LEDGER_ITEMS_SUCCESS, actionTypes_1.PayableActionType.SETTLE_LEDGER_ITEMS_FAILURE)();
exports.createOneOffLedgerItemRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.PayableActionType.CREATE_ONE_OFF_LEDGER_ITEM_REQUEST, actionTypes_1.PayableActionType.CREATE_ONE_OFF_LEDGER_ITEM_SUCCESS, actionTypes_1.PayableActionType.CREATE_ONE_OFF_LEDGER_ITEM_FAILURE)();
exports.payableAddAdjustmentAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.PayableActionType.ADD_ADJUSTMENT_REQUEST, actionTypes_1.PayableActionType.ADD_ADJUSTMENT_SUCCESS, actionTypes_1.PayableActionType.ADD_ADJUSTMENT_FAILURE)();
exports.toggleAddAdjustmentsForm = (0, typesafe_actions_1.createAction)(actionTypes_1.PayableActionType.TOGGLE_ADD_ADJUSTMENTS_FORM)();
exports.updateAddAdjustmentsForm = (0, typesafe_actions_1.createAction)(actionTypes_1.PayableActionType.UPDATE_ADD_ADJUSTMENTS_FORM)();
exports.toggleCreateOneOffLedgerItem = (0, typesafe_actions_1.createAction)(actionTypes_1.PayableActionType.TOGGLE_CREATE_ONE_OFF_LEDGER_ITEM)();
exports.payableEventsByIdRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.PayableActionType.GET_EVENTS_BY_ID_REQUEST, actionTypes_1.PayableActionType.GET_EVENTS_BY_ID_SUCCESS, actionTypes_1.PayableActionType.GET_EVENTS_BY_ID_FAILURE)();
