"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleDuplicateOpen = exports.toggleDuplicateUser = exports.toggleCreateUser = exports.sendUserCreationLimitChangeRequestEmailAction = exports.sendUserDemandContactUsRequestEmailAction = exports.deleteUserDeactivationAction = exports.createUserDeactivationsAction = exports.userDeactivationRequestAction = exports.deleteAccessTokenByUuid = exports.richAccessTokenByIdRequestAction = exports.richAccessTokenByUuidRequestAction = exports.userCreateRequestAction = exports.setProfileMeta = exports.setClaims = exports.updateUserRequestAction = exports.userAccessByUuidRequestAction = exports.userVerificationAction = exports.userPwResetAction = exports.userByUuidRequestAction = exports.userAccessRequestAction = exports.userRequestAction = void 0;
var typesafe_actions_1 = require("typesafe-actions");
var actionTypes_1 = require("./actionTypes");
exports.userRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.UserActionType.GET_ALL_REQUEST, actionTypes_1.UserActionType.GET_ALL_SUCCESS, actionTypes_1.UserActionType.GET_ALL_FAILURE)();
exports.userAccessRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.UserActionType.GET_ACCESS_ALL_REQUEST, actionTypes_1.UserActionType.GET_ACCESS_ALL_SUCCESS, actionTypes_1.UserActionType.GET_ACCESS_ALL_FAILURE)();
exports.userByUuidRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.UserActionType.GET_BY_UUID_REQUEST, actionTypes_1.UserActionType.GET_BY_UUID_SUCCESS, actionTypes_1.UserActionType.GET_BY_UUID_FAILURE)();
exports.userPwResetAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.UserActionType.PW_RESET_REQUEST, actionTypes_1.UserActionType.PW_RESET_SUCCESS, actionTypes_1.UserActionType.PW_RESET_FAILURE)();
exports.userVerificationAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.UserActionType.GET_VERIFICATION_REQUEST, actionTypes_1.UserActionType.GET_VERIFICATION_SUCCESS, actionTypes_1.UserActionType.GET_VERIFICATION_FAILURE)();
exports.userAccessByUuidRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.UserActionType.GET_ACCESS_BY_UUID_REQUEST, actionTypes_1.UserActionType.GET_ACCESS_BY_UUID_SUCCESS, actionTypes_1.UserActionType.GET_ACCESS_BY_UUID_FAILURE)();
exports.updateUserRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.UserActionType.UPDATE_REQUEST, actionTypes_1.UserActionType.UPDATE_SUCCESS, actionTypes_1.UserActionType.UPDATE_FAILURE)();
exports.setClaims = (0, typesafe_actions_1.createAction)(actionTypes_1.UserActionType.SET_CLAIMS)();
exports.setProfileMeta = (0, typesafe_actions_1.createAction)(actionTypes_1.UserActionType.SET_PROFILE_META)();
exports.userCreateRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.UserActionType.CREATE_REQUEST_CLIENT, actionTypes_1.UserActionType.CREATE_SUCCESS_CLIENT, actionTypes_1.UserActionType.CREATE_FAILURE_CLIENT)();
exports.richAccessTokenByUuidRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.UserActionType.GET_RICH_TOKEN_BY_UUID_REQUEST, actionTypes_1.UserActionType.GET_RICH_TOKEN_BY_UUID_SUCCESS, actionTypes_1.UserActionType.GET_RICH_TOKEN_BY_UUID_FAILURE)();
exports.richAccessTokenByIdRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.UserActionType.GET_RICH_TOKEN_BY_ID_REQUEST, actionTypes_1.UserActionType.GET_RICH_TOKEN_BY_ID_SUCCESS, actionTypes_1.UserActionType.GET_RICH_TOKEN_BY_ID_FAILURE)();
exports.deleteAccessTokenByUuid = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.UserActionType.DELETE_TOKEN_BY_UUID_REQUEST, actionTypes_1.UserActionType.DELETE_TOKEN_BY_UUID_SUCCESS, actionTypes_1.UserActionType.DELETE_TOKEN_BY_UUID_FAILURE)();
exports.userDeactivationRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.UserActionType.GET_ALL_USER_DEACTIVATION_REQUEST, actionTypes_1.UserActionType.GET_ALL_USER_DEACTIVATION_SUCCESS, actionTypes_1.UserActionType.GET_ALL_USER_DEACTIVATION_FAILURE)();
exports.createUserDeactivationsAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.UserActionType.CREATE_USER_DEACTIVATIONS_REQUEST, actionTypes_1.UserActionType.CREATE_USER_DEACTIVATIONS_SUCCESS, actionTypes_1.UserActionType.CREATE_USER_DEACTIVATIONS_FAILURE)();
exports.deleteUserDeactivationAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.UserActionType.DELETE_USER_DEACTIVATIONS_REQUEST, actionTypes_1.UserActionType.DELETE_USER_DEACTIVATIONS_SUCCESS, actionTypes_1.UserActionType.DELETE_USER_DEACTIVATIONS_FAILURE)();
exports.sendUserDemandContactUsRequestEmailAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.UserActionType.SEND_DEMAND_USER_CONTACT_US_REQUEST_EMAIL_REQUEST, actionTypes_1.UserActionType.SEND_DEMAND_USER_CONTACT_US_REQUEST_EMAIL_SUCCESS, actionTypes_1.UserActionType.SEND_DEMAND_USER_CONTACT_US_REQUEST_EMAIL_FAILURE)();
exports.sendUserCreationLimitChangeRequestEmailAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.UserActionType.SEND_USER_CREATION_LIMIT_CHANGE_REQUEST_EMAIL_REQUEST, actionTypes_1.UserActionType.SEND_USER_CREATION_LIMIT_CHANGE_REQUEST_EMAIL_SUCCESS, actionTypes_1.UserActionType.SEND_USER_CREATION_LIMIT_CHANGE_REQUEST_EMAIL_FAILURE)();
exports.toggleCreateUser = (0, typesafe_actions_1.createAction)(actionTypes_1.UserActionType.TOGGLE_CREATE)();
exports.toggleDuplicateUser = (0, typesafe_actions_1.createAction)(actionTypes_1.UserActionType.TOGGLE_DUPLICATE)();
exports.toggleDuplicateOpen = (0, typesafe_actions_1.createAction)(actionTypes_1.UserActionType.TOGGLE_DUPLICATE_OPEN)();
