import * as React from 'react';
import { useContext } from 'react';

import type { SubmitCallback } from '@/components/form/portal-form/hoc';
import type { ValidationMap } from '@/components/form/portal-form/lib/validate';
import type {
  OnChange,
  OnError,
  OnErrorCount,
  OnValidate,
  PortalFormField,
  ValidationResult,
} from '@/components/form/portal-form/types';

export interface IPortalFormContext<T extends {}> {
  /** @deprecated Use onErrorCount */
  addErrorCounter: (callback: OnErrorCount) => void;
  /** @deprecated Use onValidate */
  addValidator: (callback: OnValidate) => void;
  fields: PortalFormField<T>[];
  getDefaultValue: (path: string) => unknown;
  getError: (path: string) => string | null;
  getErrorCount: () => Promise<number>;

  getValue: (path: string) => unknown;
  onChange: (callback: OnChange<T>) => void;
  onError: (callback: OnError) => void;
  onErrorCount: (callback: OnErrorCount) => void;

  onValidate: (callback: OnValidate) => void;
  /** @deprecated Use removeOnErrorCount */
  removeErrorCounter: (callback: OnErrorCount) => void;
  removeOnChange: (callback: OnChange<T>) => void;
  removeOnError: (callback: OnError) => void;

  removeOnErrorCount: (callback: OnErrorCount) => void;
  removeOnValidate: (callback: OnValidate) => void;
  /** @deprecated Use removeOnValidate */
  removeValidator: (callback: OnValidate) => void;
  reset: () => void;

  setDefaultValues: (defaultValues: T) => void;
  setError: (path: string, message: string | null) => void;
  setFields: (fields: PortalFormField<T>[]) => void;
  setValue: (path: string, value: unknown, triggerValidation?: boolean) => void;
  setValues: (values: T) => void;
  submit: SubmitCallback<T>;
  validate: () => Promise<ValidationResult<T>>;
  validationMap: ValidationMap<T>;
  values: T;
}

export const PortalFormContext = React.createContext<IPortalFormContext<any>>({
  addErrorCounter: () => {},
  addValidator: () => {},
  fields: [],
  getDefaultValue: () => {},
  getError: () => null,
  getErrorCount: async () => 0,

  getValue: () => {},
  onChange: () => {},
  onError: () => {},
  onErrorCount: () => {},

  onValidate: () => {},
  removeErrorCounter: () => {},
  removeOnChange: () => {},
  removeOnError: () => {},

  removeOnErrorCount: () => {},
  removeOnValidate: () => {},
  removeValidator: () => {},
  reset: () => {},

  setDefaultValues: () => {},
  setError: () => {},
  setFields: () => {},
  setValue: () => {},
  setValues: () => {},
  submit: async () => {},
  validate: async () => ({ errorCount: 0, errorMap: {}, values: {} }),
  validationMap: {},
  values: {},
});

export const useForm = () => useContext(PortalFormContext);
