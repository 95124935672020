"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeedbackType = void 0;
var FeedbackType;
(function (FeedbackType) {
    FeedbackType["Idea"] = "idea";
    FeedbackType["Praise"] = "praise";
    FeedbackType["Problem"] = "problem";
    FeedbackType["Question"] = "question";
})(FeedbackType = exports.FeedbackType || (exports.FeedbackType = {}));
