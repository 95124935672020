"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureFlagActionType = void 0;
var FeatureFlagActionType;
(function (FeatureFlagActionType) {
    FeatureFlagActionType["CREATE_FEATURE_FLAG_FAILURE"] = "@@feature-flags/CREATE_FEATURE_FLAG_FAILURE_CLIENT";
    FeatureFlagActionType["CREATE_FEATURE_FLAG_REQUEST"] = "@@feature-flags/CREATE_FEATURE_FLAG_REQUEST_CLIENT";
    FeatureFlagActionType["CREATE_FEATURE_FLAG_SUCCESS"] = "@@feature-flags/CREATE_FEATURE_FLAG_SUCCESS_CLIENT";
    FeatureFlagActionType["DELETE_FEATURE_FLAG_FAILURE"] = "@@feature-flags/DELETE_FEATURE_FLAG_FAILURE";
    FeatureFlagActionType["DELETE_FEATURE_FLAG_REQUEST"] = "@@feature-flags/DELETE_FEATURE_FLAG_REQUEST";
    FeatureFlagActionType["DELETE_FEATURE_FLAG_SUCCESS"] = "@@feature-flags/DELETE_FEATURE_FLAG_SUCCESS";
    FeatureFlagActionType["DISABLE_FEATURE_FLAG_FAILURE"] = "@@feature-flags/DISABLE_FEATURE_FLAG_FAILURE";
    FeatureFlagActionType["DISABLE_FEATURE_FLAG_REQUEST"] = "@@feature-flags/DISABLE_FEATURE_FLAG_REQUEST";
    FeatureFlagActionType["DISABLE_FEATURE_FLAG_SUCCESS"] = "@@feature-flags/DISABLE_FEATURE_FLAG_SUCCESS";
    FeatureFlagActionType["ENABLE_FEATURE_FLAG_FAILURE"] = "@@feature-flags/ENABLE_FEATURE_FLAG_FAILURE";
    FeatureFlagActionType["ENABLE_FEATURE_FLAG_REQUEST"] = "@@feature-flags/ENABLE_FEATURE_FLAG_REQUEST";
    FeatureFlagActionType["ENABLE_FEATURE_FLAG_SUCCESS"] = "@@feature-flags/ENABLE_FEATURE_FLAG_SUCCESS";
    FeatureFlagActionType["GET_ALL_FEATURE_FLAGS_FAILURE"] = "@@feature-flags/GET_ALL_FEATURE_FLAGS_FAILURE";
    FeatureFlagActionType["GET_ALL_FEATURE_FLAGS_REQUEST"] = "@@feature-flags/GET_ALL_FEATURE_FLAGS_REQUEST";
    FeatureFlagActionType["GET_ALL_FEATURE_FLAGS_SUCCESS"] = "@@feature-flags/GET_ALL_FEATURE_FLAGS_SUCCESS";
    FeatureFlagActionType["GET_FEATURE_FLAG_BY_UUID_FAILURE"] = "@@feature-flags/GET_ALL_FEATURE_FLAGS_FAILURE";
    FeatureFlagActionType["GET_FEATURE_FLAG_BY_UUID_REQUEST"] = "@@feature-flags/GET_FEATURE_FLAG_BY_UUID_REQUEST";
    FeatureFlagActionType["GET_FEATURE_FLAG_BY_UUID_SUCCESS"] = "@@feature-flags/GET_FEATURE_FLAG_BY_UUID_SUCCESS";
    FeatureFlagActionType["TOGGLE_CREATE"] = "@@feature-flags/TOGGLE_CREATE";
    FeatureFlagActionType["UPDATE_FEATURE_FLAG_FAILURE"] = "@@feature-flags/UPDATE_FEATURE_FLAG_FAILURE";
    FeatureFlagActionType["UPDATE_FEATURE_FLAG_REQUEST"] = "@@feature-flags/UPDATE_FEATURE_FLAG_REQUEST";
    FeatureFlagActionType["UPDATE_FEATURE_FLAG_SUCCESS"] = "@@feature-flags/UPDATE_FEATURE_FLAG_SUCCESS";
})(FeatureFlagActionType = exports.FeatureFlagActionType || (exports.FeatureFlagActionType = {}));
