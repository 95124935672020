"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deactivateConfig = void 0;
exports.deactivateConfig = {
    canSelectOfferRows: true,
    canSelectSubAccountRows: true,
    canUseWithActivateDeactivate: true,
    hasDeactivateFeatureFlag: true,
    showAccountActivateDeactivateCard: true,
    showActivateDeactivateIndicator: true,
    showChangeOfferStatusButton: true,
    showChangeSubAccountStatusButton: true,
    showSelfServiceUserDetailsActivateDeactivateIndicator: true,
    showSubAccountActivateDeactivateCard: true,
    showUserActivateDeactivateCard: true,
    showUserDetailsActivateDeactivateIndicator: true,
    showWithActivateDeactivateStatusAction: true,
};
