import React from 'react';

import isEqual from 'lodash/isEqual';
import { useRouter } from 'next/router';

import type { PortalSubAccount } from '@portal/api-models';
import { ViewMoreButton } from '@portal/ui-lib';

import { CardTable } from '@/components/card-table';
import PanelCard from '@/components/panel-card/panel-card';
import PerformanceSummaryStatBarReport from '@/components/performance-summary/stat-bar-report';
import EmptyPlaceholder from '@/components/table/empty-placeholder';
import { loansFunnelPanelConfig } from '@/resources/channels/performance-summary/panel-configs/loans/funnel';
import type { IntegrationRow } from '@/resources/integrations/types';
import { ContractApplicationType } from '@/store/demand-contracts/types';

export interface PerformanceSummaryCardProps {
  disabled: boolean;
  integrations: IntegrationRow[];
  subAccounts: PortalSubAccount[];
  viewMoreRoute?: string;
}

const PerformanceSummaryCard: React.FC<PerformanceSummaryCardProps> = ({
  disabled,
  subAccounts,
  integrations,
  viewMoreRoute = '/performance-summary',
}) => {
  const { push } = useRouter();

  let reports = subAccounts.map((subAccount: PortalSubAccount) => {
    const integrationsOfSubAccount = integrations.filter(
      (integration: IntegrationRow) =>
        integration.subAccountUuid === subAccount.uuid
    );

    return integrationsOfSubAccount.length > 0 && !disabled ? (
      <PerformanceSummaryStatBarReport
        key={subAccount.id}
        integrations={integrationsOfSubAccount}
        subAccount={subAccount}
      />
    ) : null;
  });

  const isNoReports = !reports.some((report) => report !== null);

  if (isNoReports) {
    const emptyColumns = loansFunnelPanelConfig(
      ContractApplicationType.SubmittedApplication
    );

    reports = [
      <CardTable
        key={'card-table-empty-columns'}
        showHeader
        columns={emptyColumns.map(({ title }) => ({ title }))}
        dataSource={[]}
        locale={{
          emptyText: (
            <EmptyPlaceholder
              description={
                !disabled
                  ? 'Create your first integration to see reporting'
                  : 'Activate your plan to see reporting'
              }
            />
          ),
        }}
      />,
    ];
  }

  return (
    <PanelCard
      className="performance-summary-card"
      disabled={disabled}
      extra={<ViewMoreButton onClick={async () => push(viewMoreRoute)} />}
      title="Last 30 Days Performance Summary Highlights"
    >
      {reports}
    </PanelCard>
  );
};

export default React.memo(PerformanceSummaryCard, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.subAccounts, nextProps.subAccounts) &&
    isEqual(prevProps.integrations, nextProps.integrations)
  );
});
