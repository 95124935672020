"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scaleConfig = void 0;
exports.scaleConfig = {
    canReadIntegrations: true,
    canRequestPartnerKeys: true,
    canRequestSelfServiceData: true,
    hasScaleUserRole: true,
    hidePartnerPageStatuses: true,
    shouldForceCurrentUserRolesOnUserCreation: true,
    shouldOverrideNavLocks: true,
    shouldSkipTrialExpiredPage: true,
    shouldUseSelfServiceEntrypointOnIndexPage: true,
    showControlCenterHome: true,
    showExportButton: true,
    showExternalSupplySubaccountDetails: true,
    showFeedbackHeaderItem: true,
    showFootnote: true,
    showHelpCenterHeaderItemFeatureFlag: true,
    showHelpCenterHeaderItemIfFeatureFlag: true,
    showIntegrationCopyOverride: true,
    showMockComplianceRows: true,
    showNotificationsHeaderItemIfFeatureFlag: true,
    showRebrandGuideAndAssetsLink: true,
    showScaleUsersPage: true,
    showSelfServiceUserDetails: true,
    showSelfServiceUserSideNavbar: true,
    showSelfServiceUsersPage: true,
    userCreateLimit: 5,
};
