import { datadogLogs } from '@datadog/browser-logs';
import type { Logger } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum-slim';

import type { UserWithRoles } from '@portal/api-models';

import { getDDBaseConfig } from '@/services/datadog';
import type { ConfigState } from '@/store/config';
import { LogLevel } from '@/utils/logger/logger';
import type { ParsedLog } from '@/utils/logger/parser';
import { Transport } from '@/utils/logger/transport';
import type { TransportOptions } from '@/utils/logger/transport';

let isReady = false;

const readyCallbacks: Function[] = [];

interface InitializeDataDogOptions {
  config: ConfigState;
  context: {
    roles?: string[];
    user?: UserWithRoles;
  };
}

export const initializeDataDog = async (options: InitializeDataDogOptions) => {
  const { config, context } = options;

  const datadogLoggerConfig = getDDBaseConfig(config);

  if (typeof window === 'undefined') {
    return;
  }

  datadogLogs.init(datadogLoggerConfig);

  datadogLogs.addLoggerGlobalContext('engine.user.roles', context.roles);

  if (context.user) {
    datadogLogs.addLoggerGlobalContext('engine.user.id', context.user.id);
    datadogLogs.addLoggerGlobalContext('engine.user.uuid', context.user.uuid);
    datadogLogs.addLoggerGlobalContext(
      'even.user.finance_account_id',
      context.user.financeAccountId
    );
  }

  await new Promise<void>((resolve) => {
    datadogLogs.onReady(() => {
      isReady = true;
      readyCallbacks.forEach((cb) => cb());
      resolve();
    });
  });
};

export class DataDogTransport extends Transport {
  private datadogLogger: Logger | undefined;

  constructor(options: TransportOptions) {
    super(options);

    if (!isReady) {
      readyCallbacks.push(() => this.init());
    }
  }

  private init() {
    const { name } = this.options;

    this.datadogLogger = datadogLogs.createLogger(name);
    this.datadogLogger.addContext('logger', name);

    this.executeQueue();
  }

  public info(parsed: ParsedLog) {
    if (!isReady) {
      this.queue.push([LogLevel.Info, parsed]);

      return;
    }

    this.datadogLogger?.info(parsed.message, parsed.context);
  }

  public debug(parsed: ParsedLog) {
    if (!isReady) {
      this.queue.push([LogLevel.Debug, parsed]);

      return;
    }

    this.datadogLogger?.debug(parsed.message, parsed.context);
  }

  public warn(parsed: ParsedLog) {
    if (!isReady) {
      this.queue.push([LogLevel.Warn, parsed]);

      return;
    }

    this.datadogLogger?.warn(parsed.message, parsed.context);
  }

  public error(parsed: ParsedLog) {
    if (!isReady) {
      this.queue.push([LogLevel.Error, parsed]);

      return;
    }

    const { message, context } = parsed;

    datadogRum.addError(message, { ...context, message });
    this.datadogLogger?.error(message, context);
  }
}
