import { action, createAsyncAction } from 'typesafe-actions';

import type { PortalSaasMigrationSetting } from '@portal/api-models';

import { SupplyMigrationActionType } from '@/store/supply-migration/types';
import type {
  GetByFinanceAccountIdRequestPayload,
  StartMigrationRequestPayload,
  UpdateSupplyMigrationRequestPayload,
} from '@/store/supply-migration/types';
import type { IsomorphicRequestPayload } from '@/store/types';

interface SupplyMigrationSuccessPayload {
  financeAccountId: number;
  settings: PortalSaasMigrationSetting;
}

export const getSettingsByAuthContextRequestAction = createAsyncAction(
  SupplyMigrationActionType.GET_BY_AUTH_CONTEXT_REQUEST,
  SupplyMigrationActionType.GET_BY_AUTH_CONTEXT_SUCCESS,
  SupplyMigrationActionType.GET_BY_AUTH_CONTEXT_FAILURE
)<IsomorphicRequestPayload, PortalSaasMigrationSetting, Error>();

export const getSettingsByFinanceAccountIdRequestAction = createAsyncAction(
  SupplyMigrationActionType.GET_BY_FINANCE_ACCOUNT_ID_REQUEST,
  SupplyMigrationActionType.GET_BY_FINANCE_ACCOUNT_ID_SUCCESS,
  SupplyMigrationActionType.GET_BY_FINANCE_ACCOUNT_ID_FAILURE
)<GetByFinanceAccountIdRequestPayload, SupplyMigrationSuccessPayload, Error>();

export const updateMigrationRequestAction = createAsyncAction(
  SupplyMigrationActionType.UPDATE_REQUEST,
  SupplyMigrationActionType.UPDATE_SUCCESS,
  SupplyMigrationActionType.UPDATE_FAILURE
)<UpdateSupplyMigrationRequestPayload, SupplyMigrationSuccessPayload, Error>();

export const startMigration = createAsyncAction(
  SupplyMigrationActionType.START_REQUEST,
  SupplyMigrationActionType.START_SUCCESS,
  SupplyMigrationActionType.START_FAILURE
)<StartMigrationRequestPayload, null, Error>();

export const saasMigrationSettingsByAuthContextSave = (
  settings: PortalSaasMigrationSetting
) => action(SupplyMigrationActionType.GET_BY_AUTH_CONTEXT_SUCCESS, settings);
