import type { ConfigProductType } from '@evenfinancial/even-ts-static';
import type { ProductSubType } from '@evenfinancial/lead-client';

import type {
  ComplianceStatus,
  IntegrationStatus,
  OnboardingStatus,
  PartnerPageAlooma,
} from '@portal/api-models';

import type { PartnerKeyRow } from '@/resources/partner-keys/selectors';
import type { IntegrationCategory } from '@/resources/sub-accounts-shared/utils';

export enum ImplementationFeePaymentStatus {
  Paid = 'Paid',
  Unknown = 'Unknown',
  Unpaid = 'Unpaid',
  Waived = 'Waived',
}

/**
 * TODO: Would probably be better to eventually rename integrationType -> integrationSubType
 * and integrationCategory -> integrationType.
 */
export interface Integration extends PartnerPageAlooma {
  accessTokenUuid?: string;
  complianceStatus?: ComplianceStatus;
  complianceStatusUser?: string;
  implementationFeePaymentStatus: ImplementationFeePaymentStatus;
  integrationCategory: IntegrationCategory;
  /** Merges product type and sub product type into one. */
  integrationProduct: ConfigProductType;
  integrationStatus: IntegrationStatus;
  integrationUrl: string;
  isImplementationFeeWaived: boolean;
  isInMigration: boolean;
  onboardingStatus?: OnboardingStatus;
  productSubType?: ProductSubType;
}

export type IntegrationRow = Integration & PartnerKeyRow;
