import type { ActionType } from 'typesafe-actions';

import type { PortalDemandContract } from '@portal/api-models';

import type * as actions from './actions';

export type DemandContractsAction = ActionType<typeof actions>;

export enum DemandContractsActionType {
  DEMAND_CONTRACT_LOADING_BY_ID_CHANGE = '@@demand-contracts/loading-by-id',

  GET_ALL_CONTRACTS_FAILURE = '@@demand-contracts/GET_ALL_CONTRACTS_FAILURE',
  GET_ALL_CONTRACTS_REQUEST = '@@demand-contracts/GET_ALL_CONTRACTS_REQUEST',
  GET_ALL_CONTRACTS_SUCCESS = '@@demand-contracts/GET_ALL_CONTRACTS_SUCCESS',

  GET_DEMAND_CONTRACT_BY_ID_FAILURE = '@@demand-contracts/GET_DEMAND_CONTRACT_BY_ID_FAILURE',
  GET_DEMAND_CONTRACT_BY_ID_REQUEST = '@@demand-contracts/GET_DEMAND_CONTRACT_BY_ID_REQUEST',
  GET_DEMAND_CONTRACT_BY_ID_SUCCESS = '@@demand-contracts/GET_DEMAND_CONTRACT_BY_ID_SUCCESS',

  INIT_DEMAND_CONTRACT_DETAILS_PAGE = '@@demand-contracts/INIT_DEMAND_CONTRACT_DETAILS_PAGE',
}

export enum ContractApplicationType {
  CompletedApplication = 'completed',
  None = 'none',
  SubmittedApplication = 'submitted',
}

export interface DemandContractsState {
  loadingContractById: boolean;
  rows: PortalDemandContract[];
}
