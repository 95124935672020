import * as React from 'react';
import { useDispatch } from 'react-redux';

import { faComment } from '@fortawesome/pro-solid-svg-icons';

import { toggleFeedbackDrawer } from '@portal/store/dist/feedback';
import { SidebarIcon } from '@portal/ui-lib';

import PortalItemGroup from '@/components/side-nav/portal-item-group';
import { RouterActionMenuItem } from '@/components/side-nav/router-action-menu-item';

export interface HelpCenterSectionProps {
  sidenavCollapsed?: boolean;
}

export const HelpCenterSection: React.FC<HelpCenterSectionProps> = (props) => {
  const dispatch = useDispatch();

  return (
    <PortalItemGroup title="Help Center" {...props}>
      <RouterActionMenuItem
        key="menu-item-feedback"
        eventKey="feedback"
        icon={<SidebarIcon icon={faComment} />}
        text="Feedback & Bugs"
        onClick={() => dispatch(toggleFeedbackDrawer())}
      />
    </PortalItemGroup>
  );
};

export default HelpCenterSection;
