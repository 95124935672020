import type { ReactFragment } from 'react';
import * as React from 'react';

import { Typography } from 'antd';
import Card from 'antd/lib/card';
import classNames from 'classnames';

const { Text } = Typography;

export interface PanelCardProps extends React.PropsWithChildren<{}> {
  className?: string;
  disabled?: boolean;
  extra?: ReactFragment;
  title: string;
  titleDescription?: string;
}

const PanelCard: React.FC<PanelCardProps> = ({
  className,
  title,
  titleDescription,
  extra,
  disabled = false,
  children,
}) => {
  const titleElement = (
    <>
      <div className="panel-card-title">
        <strong>{title}</strong>
      </div>
      {titleDescription && (
        <Text className="panel-card-title-description">{titleDescription}</Text>
      )}
    </>
  );

  return (
    <Card
      className={classNames('panel-card', className, {
        'panel-card-disabled': disabled,
        'panel-card-hide-children': !children,
      })}
      extra={extra}
      title={titleElement}
    >
      {children}
    </Card>
  );
};

PanelCard.defaultProps = {
  className: '',
  disabled: false,
};

export default PanelCard;
