"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateCommunication = exports.getCommunications = void 0;
var client_1 = require("../client");
var baseUrl = '/partner-data/communications';
var getCommunications = function () {
    return client_1.client.get(baseUrl).then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.getCommunications = getCommunications;
var updateCommunication = function (data) {
    return client_1.client.patch(baseUrl, data).then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.updateCommunication = updateCommunication;
