import { createAsyncAction } from 'typesafe-actions';

import type { SubAccountExternalDemandEvent } from '@evenfinancial/finance-client';

import type { PortalError } from '@portal/common';

import type { IsomorphicRequestPayload } from '@/store/types';

import { ImportedChannelEventsActionType } from './types';

interface GetAllResponsePayload {
  response: SubAccountExternalDemandEvent[];
}

export const getImportedChannelEventsAll = createAsyncAction(
  ImportedChannelEventsActionType.GET_ALL_IMPORTED_CHANNEL_EVENTS_REQUEST,
  ImportedChannelEventsActionType.GET_ALL_IMPORTED_CHANNEL_EVENTS_SUCCESS,
  ImportedChannelEventsActionType.GET_ALL_IMPORTED_CHANNEL_EVENTS_FAILURE
)<IsomorphicRequestPayload, GetAllResponsePayload, PortalError>();
