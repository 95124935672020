import { createAsyncAction } from 'typesafe-actions';

import type { ProductType } from '@evenfinancial/finance-client';

import type { PortalSubAccount } from '@portal/api-models';
import type { PortalError } from '@portal/common';

import type { IsomorphicRequestPayload } from '../types';

import { DemandConfigurationsActionType } from './types';

interface GetByProductTypeRequestPayload extends IsomorphicRequestPayload {
  productType: ProductType;
}

interface GetByProductTypeResponsePayload {
  productType: ProductType;
  response: PortalSubAccount[];
}

export const getDemandConfigurationsByProductType = createAsyncAction(
  DemandConfigurationsActionType.GET_BY_PRODUCT_TYPE_REQUEST,
  DemandConfigurationsActionType.GET_BY_PRODUCT_TYPE_SUCCESS,
  DemandConfigurationsActionType.GET_BY_PRODUCT_TYPE_FAILURE
)<
  GetByProductTypeRequestPayload,
  GetByProductTypeResponsePayload,
  PortalError
>();
