import * as React from 'react';

import { Tag } from 'antd';
import type { ColumnProps } from 'antd/lib/table';

import {
  ComplianceIntegrationType,
  ComplianceStatus,
} from '@portal/api-models';
import { externalUrlFormatter } from '@portal/common';

import { StatusDotCompliance } from '@/components/status-dot-compliance';
import {
  filterIcon,
  textFilterDropdown,
} from '@/components/table/text-filter-dropdown';
import {
  mapEnumFilters,
  mapFilters,
  mapNumberFilters,
  onFilterForArrayByKey,
  onFilterForKey,
  sorterForKey,
} from '@/components/table/util-methods';
import type { ComplianceRow } from '@/resources/compliance/compliance-row';
import { Link } from '@/routes';

export const channelColumn: ColumnProps<ComplianceRow> = {
  dataIndex: 'supplyPartnerName',
  filterDropdown: textFilterDropdown('Search...'),
  filterIcon,
  key: 'supplyPartnerName',
  onFilter: onFilterForKey('supplyPartnerName', false),
  render: (partnerName, compliance) => {
    const partnerUuid = compliance.supplyPartnerSubaccountUuid;

    return partnerUuid ? (
      <Link route={`/supply-sub-accounts/${partnerUuid}`}>
        <a>{partnerName}</a>
      </Link>
    ) : (
      partnerName
    );
  },
  sorter: sorterForKey('supplyPartnerName'),
  title: 'Channel',
};

export const integrationTypeColumn: ColumnProps<ComplianceRow> = {
  dataIndex: 'epicIntegrationTypes',
  filters: mapEnumFilters(ComplianceIntegrationType),
  key: 'epicIntegrationTypes',
  onFilter: onFilterForArrayByKey('epicIntegrationTypes'),
  render: (types) => {
    return types?.length
      ? types.map((type: ComplianceIntegrationType) => (
          <Tag key={type}>{type}</Tag>
        ))
      : '';
  },
  sorter: sorterForKey('epicIntegrationTypes'),
  title: 'Integration Types',
};

export const urlColumn: ColumnProps<ComplianceRow> = {
  dataIndex: 'urls',
  filterDropdown: textFilterDropdown('Search...'),
  key: 'urls',
  onFilter: onFilterForKey('urls', false),
  render: (urls) => {
    return urls?.length
      ? urls.map((url: string) => (
          <a
            key={url}
            href={externalUrlFormatter(url)}
            rel="noreferrer"
            target="_blank"
          >
            {url}
          </a>
        ))
      : '';
  },
  sorter: sorterForKey('urls'),
  title: 'Integration URLs',
};

export const payoutStructureColumn = (
  complianceItems: ComplianceRow[]
): ColumnProps<ComplianceRow> => ({
  dataIndex: 'payoutStructure',
  filters: mapFilters(complianceItems, 'payoutStructure'),
  key: 'payoutStructure',
  onFilter: onFilterForKey('payoutStructure'),
  render: (payoutStructure) => {
    return payoutStructure?.length ? <Tag>{payoutStructure}</Tag> : '';
  },
  sorter: sorterForKey('payoutStructure'),
  title: 'Payout Structure',
});

export const assigneeColumn = (
  complianceItems: ComplianceRow[]
): ColumnProps<ComplianceRow> => ({
  dataIndex: 'assigneeName',
  filters: mapFilters(complianceItems, 'assigneeName'),
  key: 'assigneeName',
  onFilter: onFilterForKey('assigneeName'),
  sorter: sorterForKey('assigneeName'),
  title: 'Assignee',
});

export const daysColumn = (
  complianceItems: ComplianceRow[]
): ColumnProps<ComplianceRow> => ({
  dataIndex: 'daysDifference',
  filters: mapNumberFilters(complianceItems, 'daysDifference'),
  key: 'daysDifference',
  onFilter: onFilterForKey('daysDifference'),
  sorter: sorterForKey('daysDifference'),
  title: 'Days',
});

export const reviewerColumn: ColumnProps<ComplianceRow> = {
  dataIndex: 'demandPartnerName',
  filterDropdown: textFilterDropdown('Search...'),
  filterIcon,
  key: 'demandPartnerName',
  onFilter: onFilterForKey('demandPartnerName', false),
  render: (partnerName, compliance) => {
    const partnerUuid = compliance.demandPartnerSubaccountUuid;

    return partnerUuid ? (
      <Link route={`/demand-sub-accounts/${partnerUuid}`}>
        <a>{partnerName}</a>
      </Link>
    ) : (
      partnerName
    );
  },
  sorter: sorterForKey('demandPartnerName'),
  title: 'Reviewer',
};

export const getComplianceStatusLabel = (status?: ComplianceStatus) => {
  if (!status) {
    return 'Draft';
  }

  switch (status) {
    case ComplianceStatus.closed:
      return 'Approved';
    case ComplianceStatus.rejected:
      return 'Rejected';
    case ComplianceStatus.identifiedViolation:
      return 'Identified Violation';
    case ComplianceStatus.waitingOnSupplyPartner:
      return 'Changes Requested';
    case ComplianceStatus.draft:
      return 'Draft';
    default:
      return 'Pending Compliance Review';
  }
};

export const statusColumn = (
  complianceItems: ComplianceRow[]
): ColumnProps<ComplianceRow> => ({
  dataIndex: 'status',
  filterIcon,
  filters: mapFilters(complianceItems, 'status'),
  key: 'status',
  onFilter: onFilterForKey('status', false),
  render: (status) => {
    return <StatusDotCompliance status={status} />;
  },
  sorter: sorterForKey('status'),
  title: 'Status',
});

export const requestChangesColumn: ColumnProps<ComplianceRow> = {
  dataIndex: 'complianceChanges',
  key: 'complianceChanges',
  title: 'Requested Changes',
};
