import * as React from 'react';
import { useSelector } from 'react-redux';

import { pathToRegexp } from 'path-to-regexp';

import { selectProfileMeta } from '@portal/store/dist/user/selectors';

import { useDebounce } from '@/utils/hooks/use-debounce';
import { nonAuthenticatedRoutes } from '@/utils/non-authenticated-routes';

import { Router } from '../routes';
import routes from '../routes-with-auth';
import type { ApplicationState } from '../store';

import { resolveGuards } from './helpers';
import { verifyUserAccess } from './helpers/verify-user-access';
import type { RouteGuards } from './route-guards';

function findMatchingAuthRoute(asPath: string | undefined) {
  const pathWithoutQS = asPath?.split(/[?#]/)[0];

  if (pathWithoutQS) {
    return routes.find(({ name, pattern }) =>
      pathToRegexp(pattern ?? name).test(pathWithoutQS)
    );
  }
}

/**
 * This guard utilizes the role, scopes and guards set in route-with-auth.js
 * to determine if a user has access to a route.
 */
export const AccessGuard: React.FC = ({ children }) => {
  const isNonAuthenticatedRoute = nonAuthenticatedRoutes.has(Router.pathname);

  const [allowRender, setAllowRender] = React.useState(false);

  const state = useSelector((state: ApplicationState) => state);
  const profileMeta = useSelector(selectProfileMeta);

  const { asPath } = Router;
  const { roles } = profileMeta ?? {};

  // If roles update before router path, it can cause the page to throw a
  // forbidden error prematurely.
  //
  // This is especially relevant when a multi user toggles between experiences.
  // To resolve this, we debounce triggerAccessGuard to ensure the roles and
  // router path have finished their re-renders before re-evaluating a routes access.

  const triggerAccessGuard = useDebounce(
    JSON.stringify({ asPath, isNonAuthenticatedRoute, roles }),
    1000
  );

  React.useEffect(() => {
    if (isNonAuthenticatedRoute) {
      setAllowRender(true);

      return;
    }

    if (!profileMeta) {
      return;
    }

    const matchingAuthRoute = findMatchingAuthRoute(Router.asPath);

    const passesRouteGuardGuardsBool = resolveGuards(
      state,
      profileMeta,
      matchingAuthRoute?.guards as RouteGuards[]
    );

    if (
      matchingAuthRoute &&
      !verifyUserAccess(
        matchingAuthRoute,
        profileMeta.roles,
        profileMeta.scopes,
        profileMeta.featureFlags,
        passesRouteGuardGuardsBool
      )
    ) {
      Router.pushRoute('/forbidden');
    } else {
      setAllowRender(true);
    }
  }, [triggerAccessGuard]);

  if (allowRender) {
    return <>{children}</>;
  }

  return null;
};
