import * as React from 'react';

import classNames from 'classnames';

import { withPortalForm } from '@/components/form/portal-form/hoc';
import type { WithPortalFormProps } from '@/components/form/portal-form/hoc';
import type { IPortalFormContext } from '@/components/form/portal-form/lib/portal-form-context';
import type { OnChange } from '@/components/form/portal-form/types';

import PortalFormActions from './components/form-actions';
import type { PortalFormActionsProps } from './components/form-actions';
import PortalFormFieldList from './components/form-field-list';
import type { PortalFormFieldListProvidedProps } from './components/form-field-list';

type ComposedFieldListProps<T extends object> = Pick<
  PortalFormFieldListProvidedProps<T>,
  'fields' | 'layout'
>;

type ComposedActionsProps = Pick<
  PortalFormActionsProps,
  | 'type'
  | 'onCancel'
  | 'actionText'
  | 'afterActions'
  | 'beforeActions'
  | 'confirmationProps'
  | 'enableConfirmation'
  | 'disableSubmit'
  | 'hideSubmit'
>;

export interface PortalFormProvidedProps<T extends object>
  extends ComposedFieldListProps<T>,
    ComposedActionsProps {
  className?: string;
  defaultValues?: T;
  form?: IPortalFormContext<T>;
  inlineLabels?: boolean;
  onChange?(rawValues: T): void;
  onChangeTransform?(rawValues: T): T;
  onSubmit?(populatedValues: T, rawValues: T): void;
  onValidationFailure?(): void;
  validateAllOnChange?: boolean;
}

export type PortalFormProps<T extends object> = PortalFormProvidedProps<T> &
  WithPortalFormProps<T>;

export * from './lib/portal-form-context';
export * from './lib/portal-form-field-context';
export * from './lib/predicate';
export * from './lib/use-controllable';
export * from './lib/use-field-list';
export * from './lib/validate';
export * from './helpers';

/**
 * @deprecated
 * Use react-hook-forms instead for forms.
 */
class PortalFormWithoutHOC<T extends object> extends React.Component<
  PortalFormProps<T>
> {
  constructor(props: PortalFormProps<T>) {
    super(props);

    const { form } = props;

    if (form) {
      form.onChange(this.handleOnChange);
    }
  }

  private onSubmit = async () => {
    const {
      onSubmit,
      onValidationFailure,
      form: { submit },
    } = this.props;

    await submit(onSubmit, onValidationFailure);
  };

  private handleOnChange: OnChange<T> = ({ values }) => {
    const { onChange, onChangeTransform } = this.props;
    const transformed = onChangeTransform?.(values) ?? values;

    onChange?.(transformed);
  };

  public render() {
    const {
      onCancel,
      className,
      children,
      fields,
      inlineLabels,
      layout,
      actionText,
      type,
      beforeActions,
      afterActions,
      enableConfirmation,
      confirmationProps,
      defaultValues,
      form,
      form: { values },
      disableSubmit,
      hideSubmit,
    } = this.props;

    if (values === undefined) {
      return null;
    }

    return (
      <form className={classNames('portal-form', className)}>
        <PortalFormFieldList
          defaultValues={defaultValues}
          fields={fields}
          form={form}
          inlineLabels={inlineLabels}
          layout={layout}
        />
        {children && <div className="portal-form-content">{children}</div>}
        <PortalFormActions
          actionText={actionText}
          afterActions={afterActions}
          beforeActions={beforeActions}
          confirmationProps={confirmationProps}
          disableSubmit={disableSubmit}
          enableConfirmation={enableConfirmation}
          hideSubmit={hideSubmit}
          type={type}
          onCancel={onCancel}
          onSubmit={this.onSubmit}
        />
      </form>
    );
  }
}

/**
 * @deprecated
 * Use react-hook-forms instead for forms.
 */
const PortalForm = withPortalForm(PortalFormWithoutHOC) as new <
  T extends object
>(
  props: PortalFormProps<T>
) => React.Component<PortalFormProvidedProps<T>>;

export default PortalForm;
