"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterSupplyTemplates = exports.mapProductTypesToSupplyTemplateProductTypes = exports.mapSupplyTemplateProductTypesToProductTypes = exports.getInvertedObject = exports.supplyTemplateProductTypeToProductTypeMap = void 0;
const originator_client_1 = require("@evenfinancial/originator-client");
exports.supplyTemplateProductTypeToProductTypeMap = new Map([
    [originator_client_1.SupplyTemplateProductType.AutomobileRefinance, originator_client_1.ProductType.Loan],
    [originator_client_1.SupplyTemplateProductType.CreditCard, originator_client_1.ProductType.CreditCard],
    [originator_client_1.SupplyTemplateProductType.LifeInsurance, originator_client_1.ProductType.LifeInsurance],
    [originator_client_1.SupplyTemplateProductType.PersonalLoan, originator_client_1.ProductType.Loan],
    [originator_client_1.SupplyTemplateProductType.Savings, originator_client_1.ProductType.Savings],
    [originator_client_1.SupplyTemplateProductType.StudentLoanRefinance, originator_client_1.ProductType.Loan],
]);
const productTypeToSupplyTemplateProductTypesMap = {
    [originator_client_1.ProductType.Loan]: [
        originator_client_1.SupplyTemplateProductType.PersonalLoan,
        originator_client_1.SupplyTemplateProductType.StudentLoanRefinance,
        originator_client_1.SupplyTemplateProductType.AutomobileRefinance,
    ],
    [originator_client_1.ProductType.LifeInsurance]: [originator_client_1.SupplyTemplateProductType.LifeInsurance],
    [originator_client_1.ProductType.CreditCard]: [originator_client_1.SupplyTemplateProductType.CreditCard],
    [originator_client_1.ProductType.Savings]: [originator_client_1.SupplyTemplateProductType.Savings],
};
function getInvertedObject(obj) {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
        const values = obj[key];
        values.forEach((val) => {
            newObj[val] = key;
        });
    });
    return newObj;
}
exports.getInvertedObject = getInvertedObject;
function mapSupplyTemplateProductTypesToProductTypes(supplyTemplateProductTypes) {
    const productTypeMap = getInvertedObject(productTypeToSupplyTemplateProductTypesMap);
    return supplyTemplateProductTypes.map((type) => productTypeMap[type]);
}
exports.mapSupplyTemplateProductTypesToProductTypes = mapSupplyTemplateProductTypesToProductTypes;
function mapProductTypesToSupplyTemplateProductTypes(productTypes) {
    return productTypes.flatMap((val) => {
        const mapping = productTypeToSupplyTemplateProductTypesMap[val];
        return mapping || [];
    });
}
exports.mapProductTypesToSupplyTemplateProductTypes = mapProductTypesToSupplyTemplateProductTypes;
const filterSupplyTemplates = (payload) => {
    const { supplyProductTypes, supplyTemplates, tier } = payload;
    if (!supplyTemplates) {
        return [];
    }
    const allowedTemplates = mapProductTypesToSupplyTemplateProductTypes(supplyProductTypes || []);
    return supplyTemplates
        .filter((template) => {
        let include = true;
        if (template.deletedAt || template.deactivatedAt) {
            include = false;
        }
        if (supplyProductTypes &&
            !allowedTemplates.includes(template.productType)) {
            include = false;
        }
        if (tier && template.tier !== tier) {
            include = false;
        }
        return include;
    })
        .sort((a, b) => a.name.localeCompare(b.name));
};
exports.filterSupplyTemplates = filterSupplyTemplates;
