import keyBy from 'lodash/keyBy';

import type { PortalSubAccount } from '@portal/api-models';

import { selectSupplySubAccounts } from '@/resources/supply-sub-accounts/internal/selectors';
import type { ApplicationState } from '@/store';

export type SelectSubAccount = (
  state: ApplicationState
) => PortalSubAccount | undefined;

export const selectSupplySubAccountsKeyedByCompanyUuid = (
  state: ApplicationState
): Record<string, PortalSubAccount> =>
  keyBy(selectSupplySubAccounts(state), 'companyUuid');

export const getSelectSupplySubAccountByCompanyUuid =
  (companyUuid: string): SelectSubAccount =>
  (state) =>
    selectSupplySubAccountsKeyedByCompanyUuid(state)?.[companyUuid];
