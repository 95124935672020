import getConfig from 'next/config';

import { getRuntimeConfig } from '@portal/common/dist/runtime-config';
import type { Environment } from '@portal/common/dist/runtime-config';

export const getEnv = (): Environment => {
  const config = getConfig();

  return config?.publicRuntimeConfig ?? getRuntimeConfig();
};
