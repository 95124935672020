"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectCurrentSecondLookOfferConfigurationBySupplySubAccountId = exports.selectSecondLookOfferConfigurationsBySupplySubAccountId = exports.selectDemandApisBySubAccountUuid = exports.selectDemandApisByProductType = exports.selectDemandApiRows = exports.selectSecondLookOfferConfigurationSlice = exports.selectDemandApiSlice = void 0;
var reselect_1 = require("reselect");
var getId = function (_state, id) { return id; };
var getUuid = function (_state, uuid) { return uuid; };
var getProductType = function (_state, _a) {
    var productType = _a.productType;
    return productType;
};
var selectDemandApiSlice = function (state) {
    return state.originatorExternal.originator.demandApi;
};
exports.selectDemandApiSlice = selectDemandApiSlice;
var selectSecondLookOfferConfigurationSlice = function (state) { return state.originatorExternal.originator.secondLookOfferConfiguration; };
exports.selectSecondLookOfferConfigurationSlice = selectSecondLookOfferConfigurationSlice;
exports.selectDemandApiRows = (0, reselect_1.createSelector)([exports.selectDemandApiSlice], function (_a) {
    var rows = _a.rows;
    return rows;
});
exports.selectDemandApisByProductType = (0, reselect_1.createSelector)([getProductType, exports.selectDemandApiRows], function (productType, demandApis) {
    return demandApis.filter(function (api) { return api.subAccountProductType === productType; });
});
exports.selectDemandApisBySubAccountUuid = (0, reselect_1.createSelector)([getUuid, exports.selectDemandApiSlice], function (uuid, _a) {
    var byDemandSubAccountUuid = _a.byDemandSubAccountUuid;
    return byDemandSubAccountUuid === null || byDemandSubAccountUuid === void 0 ? void 0 : byDemandSubAccountUuid[uuid];
});
exports.selectSecondLookOfferConfigurationsBySupplySubAccountId = (0, reselect_1.createSelector)([getId, exports.selectSecondLookOfferConfigurationSlice], function (id, _a) {
    var bySupplySubAccountId = _a.bySupplySubAccountId;
    return bySupplySubAccountId === null || bySupplySubAccountId === void 0 ? void 0 : bySupplySubAccountId[id];
});
exports.selectCurrentSecondLookOfferConfigurationBySupplySubAccountId = (0, reselect_1.createSelector)([exports.selectSecondLookOfferConfigurationsBySupplySubAccountId], function (secondLookOfferConfigurations) {
    if (secondLookOfferConfigurations === undefined) {
        return undefined;
    }
    else if (secondLookOfferConfigurations.length === 0) {
        return null;
    }
    else {
        var sortedSecondLookOfferConfigurations = __spreadArray([], secondLookOfferConfigurations, true).sort(function (a, b) {
            var defaultDate = new Date(0);
            var dateA = a.createdAt ? new Date(a.createdAt) : defaultDate;
            var dateB = b.createdAt ? new Date(b.createdAt) : defaultDate;
            return dateB.getTime() - dateA.getTime();
        });
        return sortedSecondLookOfferConfigurations[0];
    }
});
