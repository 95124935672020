// https://github.com/fridays/next-routes/issues/133#issuecomment-491485701

/**
 * @typedef { import("next-routes").Registry } Registry}
 */

/**
 * @type {Registry}
 */
const registry = require('next-routes')();

const routes = require('./routes-with-auth');

routes.forEach(({ name, pattern, page }) => {
  registry.add(name, pattern, page);
});

module.exports = registry;
