import type { Router } from 'next-routes';
import type { ActionType } from 'typesafe-actions';

import type {
  AccountTierType,
  ProductType,
} from '@evenfinancial/finance-client';
import type { DeduplicationConfiguration } from '@evenfinancial/lead-client';
import type {
  DemandApi,
  OfferRule,
  SupplyTemplate,
  SupplyTemplateApplication,
  SupplyTemplateCreateData,
  SupplyTemplateProductType,
  SupplyTemplateUpdateData,
} from '@evenfinancial/originator-client';
import type {
  SupplyDemandInclusion,
  SupplyDemandInclusionCreateData,
  SupplyDemandInclusionDelete,
} from '@evenfinancial/partner-data-client';

import type {
  PartnerMapping,
  PortalRateTable,
  PortalRuleDemandApiFilter,
  PortalRuleDemandApiFilterCreateData,
  PortalSupplyTemplateApplicationCreateData,
  StateExclusion,
} from '@portal/api-models';
import type { OfferRuleProduct } from '@portal/common/dist/originator/models';
import type { IsomorphicRequestPayload } from '@portal/store/dist/types';
import type { DemandEnablement } from '@portal/ui-lib';

import type * as originatorActions from './actions';
import type * as demandInclusionActions from './demand-inclusion/actions';

// so we can easily see which is indexed by UUID
type UUID = string;

export type OriginatorAction = ActionType<
  typeof originatorActions | typeof demandInclusionActions
>;

export enum RecordStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  WORKING = 'working',
}

export interface RecordsByUsage {
  [RecordStatus.ACTIVE]?: PortalRuleDemandApiFilter[];
  [RecordStatus.INACTIVE]?: PortalRuleDemandApiFilter[];
  [RecordStatus.WORKING]?: PortalRuleDemandApiFilterCreateData;
}

export interface LoadableRows<T> {
  haveAllRowsBeenRequested: boolean;
  rows: T[];
}

export interface PortalRuleDemandApiFilters
  extends LoadableRows<PortalRuleDemandApiFilter> {
  bySubAccountUuid: Record<UUID, RecordsByUsage>;
  showCreateForm: boolean;
}

type TemplateInfo = {
  templateDetails: string | undefined;
  templateLastUpdated?: Date;
  templateName?: string;
  templateUuid?: string;
};

export type HydratedSupplyTemplateApplication = Array<
  SupplyTemplateApplication & TemplateInfo
>;

export interface OriginatorState {
  deduplicationConfigurations: DeduplicationConfiguration[];
  demandEnablement: DemandEnablement | null;
  demandOfferCounts: Record<string, number>;
  haveSupplyTemplatesBeenRequested: boolean;
  offerRulesBySubaccountId: OfferRule[];
  offerRulesProductByProductType: Partial<
    Record<ProductType, LoadableRows<OfferRuleProduct>>
  >;
  partnerMappingsBySubAccountUuid: Record<UUID, PartnerMapping | undefined>;
  rateTablesByLeadUuid: Record<UUID, LoadableRows<PortalRateTable>>;
  ruleDemandApiFilters: PortalRuleDemandApiFilters;
  stateExclusionsBySubAccountUuid: Record<UUID, StateExclusion[] | undefined>;
  supplyDemandInclusionCounts: Record<string, number>;
  supplyDemandInclusions: SupplyDemandInclusion[];
  supplyTemplateApplications: Record<
    UUID,
    HydratedSupplyTemplateApplication | undefined
  >;
  supplyTemplates: Record<UUID, SupplyTemplate> | null;
}

export interface RateTablesData {
  rateTables: PortalRateTable[];
}

export interface UUIDOnlyPayload {
  uuid: UUID;
}

export type IsomorphicUUIDOnlyPayload = IsomorphicRequestPayload &
  UUIDOnlyPayload;

export interface ResponseWithUUID<T> extends UUIDOnlyPayload {
  response?: T;
}

export interface DeduplicationConfigurationBySubAccountUuidRequest
  extends IsomorphicRequestPayload {
  id?: number;
  supplySubAccountUuid?: string;
}

export interface UpdateStateExclusionsRequestPayload
  extends IsomorphicRequestPayload,
    UUIDOnlyPayload {
  stateCodes: string[];
}
export interface GetSupplyTemplatesRequestPayload
  extends IsomorphicRequestPayload {
  query: {
    productType?: SupplyTemplateProductType[];
    tier?: AccountTierType[];
    uuid?: string[];
  };
}

export interface DemandEnablementRequestPayload {
  supplySubAccountUuid: string;
}

export interface DemandEnablementUpdatePayload
  extends IsomorphicRequestPayload {
  query: SupplyDemandInclusionCreateData;
}

export interface DemandEnablementDeletePayload
  extends IsomorphicRequestPayload {
  query: SupplyDemandInclusionDelete & { id: number };
}

export interface SupplyTemplateCreatePayload extends IsomorphicRequestPayload {
  query: SupplyTemplateCreateData;
  router: Router;
}

export interface SupplyTemplateUpdatePayload extends IsomorphicRequestPayload {
  query: SupplyTemplateUpdateData;
  router: Router;
}

export interface RuleDemandAPIFiltersRequestPayload
  extends IsomorphicRequestPayload {
  demandSubAccountUuid: string;
}

export interface RuleDemandAPIFiltersSuccessPayload {
  activeItems: PortalRuleDemandApiFilter[];
  demandSubAccountUuid: string;
  inactiveItems: PortalRuleDemandApiFilter[];
}

export interface CreateRuleDemandAPIFilterRequestPayload
  extends IsomorphicRequestPayload {
  ruleDemandAPIFilter: PortalRuleDemandApiFilterCreateData;
}

export interface CreateRuleDemandAPIFilterSuccessPayload {
  createdRuleDemandAPIFilter: PortalRuleDemandApiFilter;
  demandSubAccountUuid: string;
}

export interface DeleteRuleDemandAPIFilterPayload
  extends IsomorphicRequestPayload {
  demandSubAccountUuid: string;
  id: number;
}

export interface DeleteRuleDemandAPIFilterSuccessPayload {
  deletedRuleDemandAPIFilter: PortalRuleDemandApiFilter;
  demandSubAccountUuid: string;
}
export interface OfferRuleProductResponsePayload {
  productType: ProductType;
  response: OfferRuleProduct[];
}

export interface GetOfferRulesByProductTypeRequestPayload
  extends IsomorphicRequestPayload {
  productType: ProductType;
}

export interface CreateSupplyTemplateApplicationPayload
  extends IsomorphicRequestPayload {
  data: PortalSupplyTemplateApplicationCreateData;
  subAccountUuid?: string;
}

export interface DemandApiWithStatus extends DemandApi {
  isActive: boolean;
}
