"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stateNameToAbbreviation = exports.UsStatesEnum = exports.LeadFilterRuleType = void 0;
var LeadFilterRuleType;
(function (LeadFilterRuleType) {
    LeadFilterRuleType["ApprovalBiasedRuleCombinator"] = "approval_biased_rule_combinator";
    LeadFilterRuleType["RejectIfBooleanMatches"] = "reject_if_boolean_matches";
    LeadFilterRuleType["RejectIfGreaterThan"] = "reject_if_greater_than";
    LeadFilterRuleType["RejectIfInRange"] = "reject_if_in_range";
    LeadFilterRuleType["RejectIfInSet"] = "reject_if_in_set";
    LeadFilterRuleType["RejectIfLessThan"] = "reject_if_less_than";
    LeadFilterRuleType["RejectIfNotInSet"] = "reject_if_not_in_set";
    LeadFilterRuleType["RejectionBiasedRuleCombinator"] = "rejection_biased_rule_combinator";
})(LeadFilterRuleType = exports.LeadFilterRuleType || (exports.LeadFilterRuleType = {}));
var UsStatesEnum;
(function (UsStatesEnum) {
    UsStatesEnum["AK"] = "Alaska";
    UsStatesEnum["AL"] = "Alabama";
    UsStatesEnum["AR"] = "Arkansas";
    UsStatesEnum["AS"] = "American Samoa";
    UsStatesEnum["AZ"] = "Arizona";
    UsStatesEnum["CA"] = "California";
    UsStatesEnum["CO"] = "Colorado";
    UsStatesEnum["CT"] = "Connecticut";
    UsStatesEnum["DC"] = "District Of Columbia";
    UsStatesEnum["DE"] = "Delaware";
    UsStatesEnum["FL"] = "Florida";
    UsStatesEnum["FM"] = "Federated States Of Micronesia";
    UsStatesEnum["GA"] = "Georgia";
    UsStatesEnum["GU"] = "Guam";
    UsStatesEnum["HI"] = "Hawaii";
    UsStatesEnum["IA"] = "Iowa";
    UsStatesEnum["ID"] = "Idaho";
    UsStatesEnum["IL"] = "Illinois";
    UsStatesEnum["IN"] = "Indiana";
    UsStatesEnum["KS"] = "Kansas";
    UsStatesEnum["KY"] = "Kentucky";
    UsStatesEnum["LA"] = "Louisiana";
    UsStatesEnum["MA"] = "Massachusetts";
    UsStatesEnum["MD"] = "Maryland";
    UsStatesEnum["ME"] = "Maine";
    UsStatesEnum["MH"] = "Marshall Islands";
    UsStatesEnum["MI"] = "Michigan";
    UsStatesEnum["MN"] = "Minnesota";
    UsStatesEnum["MO"] = "Missouri";
    UsStatesEnum["MP"] = "Northern Mariana Islands";
    UsStatesEnum["MS"] = "Mississippi";
    UsStatesEnum["MT"] = "Montana";
    UsStatesEnum["NC"] = "North Carolina";
    UsStatesEnum["ND"] = "North Dakota";
    UsStatesEnum["NE"] = "Nebraska";
    UsStatesEnum["NH"] = "New Hampshire";
    UsStatesEnum["NJ"] = "New Jersey";
    UsStatesEnum["NM"] = "New Mexico";
    UsStatesEnum["NV"] = "Nevada";
    UsStatesEnum["NY"] = "New York";
    UsStatesEnum["OH"] = "Ohio";
    UsStatesEnum["OK"] = "Oklahoma";
    UsStatesEnum["OR"] = "Oregon";
    UsStatesEnum["PA"] = "Pennsylvania";
    UsStatesEnum["PR"] = "Puerto Rico";
    UsStatesEnum["PW"] = "Palau";
    UsStatesEnum["RI"] = "Rhode Island";
    UsStatesEnum["SC"] = "South Carolina";
    UsStatesEnum["SD"] = "South Dakota";
    UsStatesEnum["TN"] = "Tennessee";
    UsStatesEnum["TX"] = "Texas";
    UsStatesEnum["UT"] = "Utah";
    UsStatesEnum["VA"] = "Virginia";
    UsStatesEnum["VI"] = "Virgin Islands";
    UsStatesEnum["VT"] = "Vermont";
    UsStatesEnum["WA"] = "Washington";
    UsStatesEnum["WI"] = "Wisconsin";
    UsStatesEnum["WV"] = "West Virginia";
    UsStatesEnum["WY"] = "Wyoming";
})(UsStatesEnum = exports.UsStatesEnum || (exports.UsStatesEnum = {}));
exports.stateNameToAbbreviation = Object.fromEntries(Object.entries(UsStatesEnum).map(function (_a) {
    var key = _a[0], value = _a[1];
    return [value, key];
}));
