"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStaticOffersWithRules = exports.getStaticOfferWithRules = exports.deleteOfferRule = exports.updateOfferRule = exports.createOfferRule = exports.getOfferRules = exports.getStaticOffers = exports.getStaticOffer = void 0;
var lodash_1 = require("lodash");
var client_1 = require("../client");
var legacyBaseUrl = '/originator/admin';
var offerRulesEndpoint = "".concat(legacyBaseUrl, "/offerRules");
var getStaticOffer = function (_a) {
    var offerUuid = _a.offerUuid, productType = _a.productType;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            return [2, client_1.client
                    .get("".concat(legacyBaseUrl, "/staticOffers"), {
                    params: { offerUuid: offerUuid, productType: productType },
                })
                    .then(function (_a) {
                    var data = _a.data;
                    return data;
                })];
        });
    });
};
exports.getStaticOffer = getStaticOffer;
var getStaticOffers = function (_a) {
    var offerUuids = _a.offerUuids, productType = _a.productType;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            return [2, client_1.client
                    .get("".concat(legacyBaseUrl, "/staticOffers"), {
                    params: { limit: 1e5, offerUuid: offerUuids, productType: productType },
                })
                    .then(function (_a) {
                    var data = _a.data;
                    return data;
                })];
        });
    });
};
exports.getStaticOffers = getStaticOffers;
var getOfferRules = function (_a) {
    var offerRulesReadData = _a.offerRulesReadData;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            return [2, client_1.client
                    .get("".concat(legacyBaseUrl, "/offerRules"), {
                    params: offerRulesReadData,
                })
                    .then(function (_a) {
                    var data = _a.data;
                    return data;
                })];
        });
    });
};
exports.getOfferRules = getOfferRules;
var createOfferRule = function (_a) {
    var offerRuleWriteData = _a.offerRuleWriteData;
    return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_b) {
        return [2, client_1.client.post(offerRulesEndpoint, offerRuleWriteData).then(function (_a) {
                var data = _a.data;
                return data;
            })];
    }); });
};
exports.createOfferRule = createOfferRule;
var updateOfferRule = function (_a) {
    var rootId = _a.rootId, updateData = _a.updateData;
    return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_b) {
        return [2, client_1.client.put("".concat(offerRulesEndpoint, "/").concat(rootId), updateData)];
    }); });
};
exports.updateOfferRule = updateOfferRule;
var deleteOfferRule = function (_a) {
    var rootId = _a.rootId;
    return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_b) {
        return [2, client_1.client.delete("".concat(offerRulesEndpoint, "/").concat(rootId))];
    }); });
};
exports.deleteOfferRule = deleteOfferRule;
var getStaticOfferWithRules = function (_a) {
    var offerUuid = _a.offerUuid;
    return __awaiter(void 0, void 0, void 0, function () {
        var staticOffer, offerRules;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4, (0, exports.getStaticOffer)({ offerUuid: offerUuid })];
                case 1:
                    staticOffer = _b.sent();
                    return [4, (0, exports.getOfferRules)({
                            offerRulesReadData: {
                                excludeDeleted: false,
                                staticOfferId: [staticOffer.id],
                            },
                        })];
                case 2:
                    offerRules = _b.sent();
                    return [2, {
                            offerRules: offerRules,
                            staticOffer: staticOffer,
                        }];
            }
        });
    });
};
exports.getStaticOfferWithRules = getStaticOfferWithRules;
var getStaticOffersWithRules = function (_a) {
    var offerUuids = _a.offerUuids;
    return __awaiter(void 0, void 0, void 0, function () {
        var CHUNK_SIZE, offerUuidsChunks, staticOffersChunks, staticOffers, staticOfferIds, offerRulesChunks, offerRules, offerRulesByStaticOfferId;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    CHUNK_SIZE = 50;
                    offerUuidsChunks = (0, lodash_1.chunk)(offerUuids, CHUNK_SIZE);
                    return [4, Promise.all(offerUuidsChunks.map(function (chunkedOfferUuids) {
                            return (0, exports.getStaticOffers)({ offerUuids: chunkedOfferUuids });
                        }))];
                case 1:
                    staticOffersChunks = _b.sent();
                    staticOffers = staticOffersChunks.flat();
                    staticOfferIds = staticOffers.map(function (offer) { return offer.id; });
                    return [4, Promise.all((0, lodash_1.chunk)(staticOfferIds, CHUNK_SIZE).map(function (chunkedOfferIds) {
                            return (0, exports.getOfferRules)({
                                offerRulesReadData: {
                                    excludeDeleted: false,
                                    staticOfferId: chunkedOfferIds,
                                },
                            });
                        }))];
                case 2:
                    offerRulesChunks = _b.sent();
                    offerRules = offerRulesChunks.flat();
                    offerRulesByStaticOfferId = new Map();
                    offerRules.forEach(function (offerRule) {
                        var staticOfferId = offerRule.staticOfferId;
                        if (staticOfferId) {
                            var rules = offerRulesByStaticOfferId.get(staticOfferId) || [];
                            rules.push(offerRule);
                            offerRulesByStaticOfferId.set(staticOfferId, rules);
                        }
                    });
                    return [2, staticOffers.map(function (staticOffer) { return ({
                            offerRules: offerRulesByStaticOfferId.get(staticOffer.offerId) || [],
                            staticOffer: staticOffer,
                        }); })];
            }
        });
    });
};
exports.getStaticOffersWithRules = getStaticOffersWithRules;
