import { ProductType } from '@evenfinancial/finance-client';

import type { PortalSupplyContract } from '@portal/api-models';
import { PayoutClauseType, getActiveSupplyContract } from '@portal/common';

import { ContractApplicationType } from '@/store/demand-contracts/types';

export const ContractTypeApplicationTypeMap = (
  contract?: PortalSupplyContract
): ContractApplicationType => {
  switch (contract?.payoutClauses[0].key) {
    case PayoutClauseType.Bidder:
    case PayoutClauseType.CostPerLead:
    case PayoutClauseType.CostPerLeadByCreditRating:
    case PayoutClauseType.CostPerLeadBySubId:
    case PayoutClauseType.CostPerLeadByCreditRatingAndDeviceType:
    case PayoutClauseType.CostPerLeadByCreditRatingBySubId:
    case PayoutClauseType.CostPerLeadByDeviceType:
    case PayoutClauseType.BidderDemandExclusions:
    case PayoutClauseType.RevenueShareLoan:
    case PayoutClauseType.RevenueShareLoanByDemand:
    case PayoutClauseType.RevenueShareOther:
    case PayoutClauseType.CostPerUniqueLoanClick:
    case PayoutClauseType.CostPerUniqueFundedLoan:
      return ContractApplicationType.SubmittedApplication;

    case PayoutClauseType.CostPerCompletedApplication:
    case PayoutClauseType.CostPerCompletedApplicationByCreditRating:
    case PayoutClauseType.CostPerCompletedApplicationBySubId:
    case PayoutClauseType.CostPerCompletedApplication60ByCreditRating:
      return ContractApplicationType.CompletedApplication;

    default:
      return ContractApplicationType.None;
  }
};

export const getContractType = (
  productType: ProductType,
  supplyContracts: PortalSupplyContract[] = []
) => {
  const activeContract = getActiveSupplyContract(supplyContracts);

  switch (productType) {
    case ProductType.Loan:
      return ContractTypeApplicationTypeMap(activeContract);

    default:
      return ContractApplicationType.None;
  }
};
