import * as React from 'react';
import { useMemo } from 'react';

import { Tooltip } from 'antd';
import Select from 'antd/lib/select';
import type { SelectProps } from 'antd/lib/select';
import lowerCase from 'lodash/lowerCase';
import type { OptionData, OptionGroupData } from 'rc-select/lib/interface';

import { createMemoizedSerialize, serialize } from '@portal/common/dist/utils';

import { multiFilter } from '@/components/form/util-methods';
import { sorter } from '@/components/table/util-methods';

export type ComposedRecordDropdownProps = {
  disabledMessage?: string;
  multipleselectmode?: boolean;
} & Omit<
  SelectProps<string | string[]>,
  | 'records'
  | 'allowClear'
  | 'showSearch'
  | 'filterOption'
  | 'optionFilterProp'
  | 'getPopupContainer'
>;

type KeyOrCallback<T extends {}> = keyof T | ((record: T) => string);

export type RecordDropdownProps<T extends {}> = {
  disabledMessage?: string;
  filters?: Array<(record: T) => boolean>;
  id: string;
  optionKey: keyof T;
  optionLabel: KeyOrCallback<T>;
  optionValue: KeyOrCallback<T>;
  records: T[];
} & ComposedRecordDropdownProps;

const memoizedSerialize = createMemoizedSerialize();

const getKeyOrCallbackValue = <T extends {}>(
  keyOrCallback: KeyOrCallback<T>,
  record: T
): string => {
  if (typeof keyOrCallback === 'string') {
    return record[keyOrCallback] as unknown as string;
  }

  if (typeof keyOrCallback === 'function') {
    return keyOrCallback(record);
  }

  return '';
};

/**
 * @param {string} input
 * @param {(OptionData | OptionGroupData)} [option]
 * @returns
 */
const onFilterRecord = (
  input: string,
  option?: OptionData | OptionGroupData
) => {
  if (option?.props) {
    return option.props['data-label-serial'].includes(memoizedSerialize(input));
  }

  return false;
};

const RecordDropdown = <T extends {}>(props: RecordDropdownProps<T>) => {
  const {
    id,
    records,
    optionKey,
    optionValue,
    optionLabel,
    filters = [],
    multipleselectmode,
    placeholder = 'Please select',
    disabledMessage,
    ...selectProps
  } = props;

  const options = useMemo(
    () =>
      records
        .filter((record: T) => multiFilter(record, ...filters))
        .sort((recordA: T, recordB: T) =>
          sorter(
            lowerCase(getKeyOrCallbackValue(optionLabel, recordA)),
            lowerCase(getKeyOrCallbackValue(optionLabel, recordB))
          )
        )
        .map((record: T) => {
          const key = record[optionKey] as unknown as string;
          const value = getKeyOrCallbackValue(optionValue, record);
          const label = getKeyOrCallbackValue(optionLabel, record);

          return (
            <Select.Option
              key={`record-dropdown-${id}-${key}`}
              data-label-serial={serialize(label)}
              value={value}
            >
              <Tooltip title={label}>
                <div style={{ width: '100%' }}>
                  <div
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {label}
                  </div>
                </div>
              </Tooltip>
            </Select.Option>
          );
        }),
    [records, filters]
  );

  const disabled = options.length === 0;

  return (
    <div className="width-100">
      <Select
        allowClear
        showSearch
        className={`record-dropdown-select record-dropdown-select-${id}`}
        disabled={disabled}
        filterOption={onFilterRecord}
        getPopupContainer={(triggerNode) =>
          triggerNode.parentNode as HTMLElement
        }
        mode={multipleselectmode ? 'multiple' : undefined}
        optionFilterProp="children"
        placeholder={placeholder}
        {...selectProps}
      >
        {options}
      </Select>
      {disabled && disabledMessage ? (
        <p className="portal-form-field-extra">{disabledMessage}</p>
      ) : null}
    </div>
  );
};

export default RecordDropdown;
