"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.internalManagerScopes = void 0;
var enum_1 = require("../enum");
exports.internalManagerScopes = [
    enum_1.Scopes.AccessTokensCreate,
    enum_1.Scopes.AccessTokensDelete,
    enum_1.Scopes.AccessTokensRead,
    enum_1.Scopes.AccessTokensRead,
    enum_1.Scopes.AccountAdjustmentsDelete,
    enum_1.Scopes.AccountAdjustmentsRead,
    enum_1.Scopes.AccountDeactivationsRead,
    enum_1.Scopes.AccountMetadataRead,
    enum_1.Scopes.AccountMetadataUpdate,
    enum_1.Scopes.AccountsCreate,
    enum_1.Scopes.AccountsRead,
    enum_1.Scopes.AccountsUpdate,
    enum_1.Scopes.ApplicationsRead,
    enum_1.Scopes.BenchmarkReportsRead,
    enum_1.Scopes.BillingCyclesCreate,
    enum_1.Scopes.BillingCyclesDelete,
    enum_1.Scopes.BillingCyclesRead,
    enum_1.Scopes.BillingCyclesUpdate,
    enum_1.Scopes.CommunicationEligibilitiesRead,
    enum_1.Scopes.CompaniesRead,
    enum_1.Scopes.ComplianceRead,
    enum_1.Scopes.DeactivationsCreate,
    enum_1.Scopes.DeactivationsDelete,
    enum_1.Scopes.DeactivationsRead,
    enum_1.Scopes.DeduplicationConfigurationsCreate,
    enum_1.Scopes.DeduplicationConfigurationsDelete,
    enum_1.Scopes.DeduplicationConfigurationsRead,
    enum_1.Scopes.DemandApisRead,
    enum_1.Scopes.DemandContractsCreate,
    enum_1.Scopes.DemandContractsDelete,
    enum_1.Scopes.DemandContractsRead,
    enum_1.Scopes.DemandDataImportsRead,
    enum_1.Scopes.DemandSubAccountInventoryCapsCreate,
    enum_1.Scopes.DemandSubAccountInventoryCapsDelete,
    enum_1.Scopes.DemandSubAccountInventoryCapsRead,
    enum_1.Scopes.DemandSubAccountInventoryCapsUpdate,
    enum_1.Scopes.DuplicateSummariesRead,
    enum_1.Scopes.ExpectedEarningsShareCreate,
    enum_1.Scopes.ExpectedEarningsShareRead,
    enum_1.Scopes.FeatureFlagsCreate,
    enum_1.Scopes.FeatureFlagsDelete,
    enum_1.Scopes.FeatureFlagsRead,
    enum_1.Scopes.FeatureFlagsUpdate,
    enum_1.Scopes.FeedbacksCreate,
    enum_1.Scopes.ImportedLeadEventsRead,
    enum_1.Scopes.LeadsCreate,
    enum_1.Scopes.LeadsRead,
    enum_1.Scopes.LedgerItemsCreate,
    enum_1.Scopes.LedgerItemsDelete,
    enum_1.Scopes.LedgerItemsRead,
    enum_1.Scopes.LedgerItemsUpdate,
    enum_1.Scopes.MciReportsRead,
    enum_1.Scopes.OfferCatalogOffersCreate,
    enum_1.Scopes.OfferCatalogOffersRead,
    enum_1.Scopes.OfferCatalogProductOffersCreate,
    enum_1.Scopes.OfferCatalogProductsCreate,
    enum_1.Scopes.OfferCatalogProductsRead,
    enum_1.Scopes.OfferCatalogProductUpdate,
    enum_1.Scopes.OfferCatalogProductVersionsRead,
    enum_1.Scopes.OfferRulesCreate,
    enum_1.Scopes.OfferRulesRead,
    enum_1.Scopes.OfferRulesUpdate,
    enum_1.Scopes.OffersRead,
    enum_1.Scopes.PartnerPagesCreate,
    enum_1.Scopes.PartnerPagesDelete,
    enum_1.Scopes.PartnerPagesRead,
    enum_1.Scopes.PartnerPagesUpdate,
    enum_1.Scopes.PermissionConfigCheckRead,
    enum_1.Scopes.PremiumLendersCreate,
    enum_1.Scopes.PremiumLendersDelete,
    enum_1.Scopes.PremiumLendersRead,
    enum_1.Scopes.PremiumLendersUpdate,
    enum_1.Scopes.PreSelectRulesRead,
    enum_1.Scopes.PricingControlsRead,
    enum_1.Scopes.RateTablesRead,
    enum_1.Scopes.RevenueReportsRead,
    enum_1.Scopes.RichAccessTokensRead,
    enum_1.Scopes.RuleDemandApiFilterCreate,
    enum_1.Scopes.RuleDemandApiFilterDelete,
    enum_1.Scopes.RuleDemandApiFilterRead,
    enum_1.Scopes.SelfServiceUserCreate,
    enum_1.Scopes.StateExclusionsRead,
    enum_1.Scopes.StateExclusionsUpdate,
    enum_1.Scopes.SubAccountsCreate,
    enum_1.Scopes.SubAccountsRead,
    enum_1.Scopes.SubAccountsUpdate,
    enum_1.Scopes.SupplyContractsCreate,
    enum_1.Scopes.SupplyContractsDelete,
    enum_1.Scopes.SupplyContractsRead,
    enum_1.Scopes.SupplyDemandInclusionsRead,
    enum_1.Scopes.SupplyDemandInclusionsUpdate,
    enum_1.Scopes.SupplyDemandSubAccountInventoryCapsCreate,
    enum_1.Scopes.SupplyDemandSubAccountInventoryCapsDelete,
    enum_1.Scopes.SupplyDemandSubAccountInventoryCapsRead,
    enum_1.Scopes.SupplyDemandSubAccountInventoryCapsUpdate,
    enum_1.Scopes.SupplyInclusionsTemplatesCreate,
    enum_1.Scopes.SupplyInclusionsTemplatesDelete,
    enum_1.Scopes.SupplyInclusionsTemplatesRead,
    enum_1.Scopes.SupplyInclusionsTemplatesUpdate,
    enum_1.Scopes.SupplyMigrationCreate,
    enum_1.Scopes.SupplyMigrationRead,
    enum_1.Scopes.SupplyMigrationUpdate,
    enum_1.Scopes.SupplySummariesRead,
    enum_1.Scopes.SupplyTemplateApplicationCreate,
    enum_1.Scopes.SupplyTemplateApplicationRead,
    enum_1.Scopes.SupplyTemplatesCreate,
    enum_1.Scopes.SupplyTemplatesRead,
    enum_1.Scopes.SupplyTemplatesUpdate,
    enum_1.Scopes.UserDeactivationsCreate,
    enum_1.Scopes.UserDeactivationsDelete,
    enum_1.Scopes.UserDeactivationsRead,
    enum_1.Scopes.UserDeactivationsRead,
    enum_1.Scopes.UsersCreate,
    enum_1.Scopes.UsersRead,
    enum_1.Scopes.UsersUpdate,
    enum_1.Scopes.WhitelistCreate,
    enum_1.Scopes.WhitelistDelete,
    enum_1.Scopes.WhitelistRead,
    enum_1.Scopes.WhitelistUpdate,
];
