import { HYDRATE } from 'next-redux-wrapper';
import type { Reducer } from 'redux';
import type { ActionType } from 'typesafe-actions';

import type { SupplyMigrationState } from '@portal/store/dist';

import type { HydrateAction } from '@/store/actions';
import { reconcileState } from '@/store/utils';

import type * as Actions from './actions';
import { SupplyMigrationActionType } from './types';

type SupplyMigrationAction = ActionType<typeof Actions>;

export const initialState: SupplyMigrationState = {
  byFinanceAccountId: {},
};

export const reducer: Reducer<
  SupplyMigrationState,
  SupplyMigrationAction | HydrateAction
> = (
  state: SupplyMigrationState = initialState,
  action: SupplyMigrationAction | HydrateAction
) => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...reconcileState(action.payload.supplyMigration, initialState),
      };
    }

    case SupplyMigrationActionType.GET_BY_FINANCE_ACCOUNT_ID_SUCCESS:
    case SupplyMigrationActionType.UPDATE_REQUEST: {
      return {
        ...state,
        byFinanceAccountId: {
          ...state.byFinanceAccountId,
          [action.payload.financeAccountId]: action.payload.settings,
        },
      };
    }

    case SupplyMigrationActionType.GET_BY_AUTH_CONTEXT_SUCCESS: {
      return {
        ...state,
        settings: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
