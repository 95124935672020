"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.financeQueries = void 0;
var demand_contracts_1 = require("./demand-contracts");
exports.financeQueries = {
    demandContracts: function (params) { return ({
        queryFn: function () { return (0, demand_contracts_1.getDemandContracts)(params); },
        queryKey: ['demandContracts', params],
    }); },
};
