"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notificationReducer = exports.notificationInitialState = void 0;
var immer_1 = require("immer");
var typesafe_actions_1 = require("typesafe-actions");
var utils_1 = require("../utils");
var actions_1 = require("./actions");
var types_1 = require("./types");
exports.notificationInitialState = {
    drawerVisible: false,
    filter: types_1.NotificationFilter.UNREAD,
    rows: [],
};
exports.notificationReducer = (0, typesafe_actions_1.createReducer)(exports.notificationInitialState)
    .handleAction(actions_1.notificationsRequestAction.success, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.rows = payload;
    });
})
    .handleAction(actions_1.updateNotificationReadAction.success, function (state, _a) {
    var notifications = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.rows = (0, utils_1.replaceByUuid)(state.rows, notifications);
    });
})
    .handleAction(actions_1.setFilter, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        draftState.filter = payload;
    });
})
    .handleAction(actions_1.toggleDrawer, function (state, _a) {
    var payload = _a.payload;
    return (0, immer_1.produce)(state, function (draftState) {
        var _a;
        draftState.drawerVisible = payload.visible;
        draftState.filter = (_a = payload.filter) !== null && _a !== void 0 ? _a : state.filter;
    });
});
