import * as React from 'react';
import { useSelector } from 'react-redux';

import Menu from 'antd/lib/menu';
import classNames from 'classnames';

import { FontAwesomeKitIcon, KitIconNames } from '@portal/ui-lib';

import { selectSidenavCollapsed } from '@/resources/interface/selects';

const PortalMainMenu: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const sidenavCollapsed = useSelector(selectSidenavCollapsed);

  return (
    <Menu
      className={classNames('portal-sider-menu', {
        'portal-sider-menu-collapsed': sidenavCollapsed,
      })}
      defaultOpenKeys={[]}
      mode="inline"
      selectedKeys={[]}
      theme="dark"
    >
      <Menu.Item
        className={sidenavCollapsed ? 'portal-logo-collapsed' : 'portal-logo'}
      >
        {/* React has a hard time swapping these out,
        so the next best thing is to modify their css */}
        <span style={{ display: sidenavCollapsed ? 'none' : 'inline' }}>
          <FontAwesomeKitIcon
            icon={KitIconNames.CompanyLogoHorizontal}
            size="3x"
          />
        </span>

        <span style={{ display: sidenavCollapsed ? 'inline' : 'none' }}>
          <FontAwesomeKitIcon
            icon={KitIconNames.CompanyLogoVertical}
            size="2x"
          />
        </span>
      </Menu.Item>
      {children}
    </Menu>
  );
};

export default PortalMainMenu;
