import { call, put, takeLatest } from 'redux-saga/effects';

import type { DemandDataImportSummary } from '@evenfinancial/finance-client';

import { client } from '@portal/api-client';
import { fetchAll, getPortalError } from '@portal/common';

import * as actions from './actions';
import { DemandDataImportActionType } from './types';

export function* getAllDemandDataImportSummaries() {
  try {
    const response: DemandDataImportSummary[] = yield call(
      fetchAll,
      async ({ limit, offset }) =>
        client
          .get<DemandDataImportSummary[]>(
            `/finance/demand_data_imports?limit=${limit}&offset=${offset}`
          )
          .then((res) => res.data),
      1e4
    );

    yield put(actions.getDemandDataImportSummariesAll.success(response));
  } catch (err: any) {
    const portalError = getPortalError(err);

    yield put(actions.getDemandDataImportSummariesAll.failure(portalError));
  }
}

export function* watchGetAllDemandDataImportSummaries() {
  yield takeLatest(
    DemandDataImportActionType.GET_ALL_DEMAND_DATA_IMPORT_SUMMARIES_REQUEST,
    getAllDemandDataImportSummaries
  );
}
