"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OriginatorActionType = void 0;
var OriginatorActionType;
(function (OriginatorActionType) {
    OriginatorActionType["CREATE_SLOC_FAILURE"] = "@@originator/CREATE_SLOC_FAILURE";
    OriginatorActionType["CREATE_SLOC_REQUEST"] = "@@originator/CREATE_SLOC_REQUEST";
    OriginatorActionType["CREATE_SLOC_SUCCESS"] = "@@originator/CREATE_SLOC_SUCCESS";
    OriginatorActionType["GET_DEMAND_APIS_BY_SUBACCOUNT_UUID_FAILURE"] = "@@originator/GET_DEMAND_APIS_BY_SUBACCOUNT_UUID_FAILURE";
    OriginatorActionType["GET_DEMAND_APIS_BY_SUBACCOUNT_UUID_REQUEST"] = "@@originator/GET_DEMAND_APIS_BY_SUBACCOUNT_UUID_REQUEST";
    OriginatorActionType["GET_DEMAND_APIS_BY_SUBACCOUNT_UUID_SUCCESS"] = "@@originator/GET_DEMAND_APIS_BY_SUBACCOUNT_UUID_SUCCESS";
    OriginatorActionType["GET_DEMAND_APIS_FAILURE"] = "@@originator/GET_DEMAND_APIS_FAILURE";
    OriginatorActionType["GET_DEMAND_APIS_REQUEST"] = "@@originator/GET_DEMAND_APIS_REQUEST";
    OriginatorActionType["GET_DEMAND_APIS_SUCCESS"] = "@@originator/GET_DEMAND_APIS_SUCCESS";
    OriginatorActionType["GET_SLOCS_BY_SUPPLY_SUB_ACCOUNT_ID_FAILURE"] = "@@originator/GET_SLOCS_BY_SUPPLY_SUB_ACCOUNT_ID_FAILURE";
    OriginatorActionType["GET_SLOCS_BY_SUPPLY_SUB_ACCOUNT_ID_REQUEST"] = "@@originator/GET_SLOCS_BY_SUPPLY_SUB_ACCOUNT_ID_REQUEST";
    OriginatorActionType["GET_SLOCS_BY_SUPPLY_SUB_ACCOUNT_ID_SUCCESS"] = "@@originator/GET_SLOCS_BY_SUPPLY_SUB_ACCOUNT_ID_SUCCESS";
})(OriginatorActionType = exports.OriginatorActionType || (exports.OriginatorActionType = {}));
