"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadingReducer = exports.initialLoadingState = void 0;
var typesafe_actions_1 = require("typesafe-actions");
var actions_1 = require("./actions");
var types_1 = require("./types");
var populateIsLoadingByResource = function () {
    return Object.keys(types_1.Resource).reduce(function (accum, resource) {
        accum[resource] = false;
        return accum;
    }, {});
};
exports.initialLoadingState = {
    isLoadingByResource: populateIsLoadingByResource(),
};
exports.loadingReducer = (0, typesafe_actions_1.createReducer)(exports.initialLoadingState).handleAction(actions_1.setIsLoadingAction, function (state, _a) {
    var _b;
    var _c = _a.payload, resource = _c[0], isLoading = _c[1];
    return __assign(__assign({}, state), { isLoadingByResource: __assign(__assign({}, state.isLoadingByResource), (_b = {}, _b[resource] = isLoading, _b)) });
});
