import type { ApplicationState } from '@/store';

export const getSlice = (state: ApplicationState) => state.receivables;

export const getReceivablesById = (state: ApplicationState) =>
  state.receivables.byId;

export const getReceivableEventsById = (state: ApplicationState) =>
  state.receivables.eventsById;

export const getReceivableAdjustments = (state: ApplicationState) =>
  state.receivables.adjustments;
