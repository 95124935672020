import * as React from 'react';

import type { FieldComponentProps } from '@/components/form/portal-form/types';

type ExpectedComponentProps<ValueType> = FieldComponentProps<ValueType> & {
  forwardedRef?: React.RefObject<any> | null;
};

export type CustomProps<ValueType = unknown, ComponentProps = {}> = {
  Component: React.ComponentType<
    ExpectedComponentProps<ValueType> & ComponentProps
  >;
  className?: string;
  disabled?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
  placeholder?: string;
  props?: ComponentProps;
} & ExpectedComponentProps<ValueType>;

const Custom: React.FC<CustomProps> = ({
  Component,
  props: providedProps = {},
  ...props
}) => {
  return <Component {...{ ...providedProps, ...props }} />;
};

export default Custom;
