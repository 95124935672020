import isEqual from 'lodash/isEqual';

export const replaceById = <T extends { id: number | string }>(
  rows: T[],
  ...newElements: T[]
) => {
  const newRows = [...rows];

  for (const element of newElements) {
    const existing = newRows.findIndex((n) => n.id === element.id);

    if (existing === -1) {
      continue;
    }

    newRows[existing] = element;
  }

  return newRows;
};

export const replaceByIdFromArray = <T extends { id: number | string }>(
  rows: T[],
  newElements: T[]
) => {
  const newRows = [...rows];

  for (const element of newElements) {
    const existing = newRows.findIndex((n) => n.id === element.id);

    if (existing === -1) {
      continue;
    }

    newRows[existing] = element;
  }

  return newRows;
};

const replaceByUuidFull = <T extends { uuid: string }>(
  rows: T[],
  newElements: T[]
): { indexes: number[]; rows: T[] } => {
  const newRows = [...rows];
  const updatedIndexes: number[] = [];

  for (const element of newElements) {
    const existing = newRows.findIndex((n) => n.uuid === element.uuid);

    if (existing > -1) {
      updatedIndexes.push(existing);
      newRows[existing] = element;
    } else {
      updatedIndexes.push(newRows.length);
      newRows.push(element);
    }
  }

  return { indexes: updatedIndexes, rows: newRows };
};

export const replaceByUuid = <T extends { uuid: string }>(
  rows: T[],
  newElements: T[]
): T[] => {
  return replaceByUuidFull(rows, newElements).rows;
};

export const replaceByUuidWithIndexes = <T extends { uuid: string }>(
  rows: T[],
  newElements: T[]
): { indexes: number[]; rows: T[] } => {
  return replaceByUuidFull(rows, newElements);
};

/**
 * Compare a given state with a given initial state and return an object with
 * initial state values removed. This ensures proper merging of hydrated store.
 *
 * @see https://github.com/kirill-konshin/next-redux-wrapper#app-and-getserversideprops-or-getstaticprops-at-page-level
 *
 * @param {{ [key: string]: any}} state
 * @param {{ [key: string]: any}} initialState
 */
export const reconcileState = (
  state: Record<string, any>,
  initialState: Record<string, any>
) => {
  const newState = { ...state };

  for (const prop in newState) {
    if (isEqual(newState[prop], initialState[prop])) {
      delete newState[prop];
    }
  }

  return newState;
};
