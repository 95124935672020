import { RepeatableError } from './interface';
import type {
  RepeatableItemData,
  RepeatableValidatorOptions,
} from './interface';

/**
 * Convert an array of values into an an array of items.
 *
 * @template ValueType
 * @param {ValueType[]} [values]
 * @return {Item<ValueType>[]}
 */
export const getItemsFromValues = <ValueType extends any>(
  values?: ValueType[]
): RepeatableItemData<ValueType>[] => {
  if (values) {
    return values.map((value, index) => ({ id: String(index), value }));
  }

  return [];
};

/**
 * Convert an array of items into an array of values.
 *
 * @template ValueType
 * @param {Item<ValueType>[]} items
 * @return {ValueType[]}
 */
export const getValuesFromItems = <ValueType extends any>(
  items: RepeatableItemData<ValueType>[]
): ValueType[] => items.map((item) => item.value);

/**
 * Replace item value by its index.
 *
 * @template ItemType
 * @template ValueType
 * @param {ItemType[]} items
 * @param {ValueType} nextValue
 * @param {number} index
 * @return {ItemType[]}
 */
export const replaceItemValueByIndex = <ItemType, ValueType>(
  items: ItemType[],
  nextValue: ValueType,
  index: number
): ItemType[] => [
  ...items.slice(0, index),
  { ...items[index], value: nextValue },
  ...items.slice(index + 1),
];

/**
 * @template ValueType
 * @param {RepeatableValidatorOptions} options
 * @return {*}  {(null|string)}
 */
export const repeatableValidator = <ValueType>(
  options: RepeatableValidatorOptions<ValueType>
): null | string => {
  const { items, min, max } = options;

  let nextError: string | null = null;

  const isBelowMin = min && items.length < min;
  const isAboveMax = max && items.length > max;

  if (isBelowMin) {
    nextError = RepeatableError.BelowMin.replace('{0}', `${min}`);
  } else if (isAboveMax) {
    nextError = RepeatableError.ExceedMax.replace('{0}', `${max}`);
  }

  return nextError;
};

/**
 * @template ValueType
 * @param {RepeatableValidatorOptions} options
 * @return {*} {number}
 */
export const repeatableErrorCounter = <ValueType>(
  options: RepeatableValidatorOptions<ValueType>
): number => {
  if (repeatableValidator(options)) {
    return 1;
  }

  return 0;
};
