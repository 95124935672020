import * as React from 'react';
import { Component } from 'react';

import { Drawer } from 'antd';
import classNames from 'classnames';

import LegacyPortalForm from '@/components/form/legacy-portal-form';
import type { LegacyPortalFormProps } from '@/components/form/legacy-portal-form';
import PortalForm from '@/components/form/portal-form';
import type { PortalFormProvidedProps } from '@/components/form/portal-form';

interface DFProps {
  afterVisibleChange?: (visible: boolean) => void;
  className?: string;
  maskClosable?: boolean;
  onCancel?: () => void;
  preview?: React.ReactFragment;
  title: string;
  toggleDrawer: () => void;
  validateAllOnChange?: boolean;
  visible: boolean;
}

export type DrawerFormProps<T extends object = any> =
  | (LegacyPortalFormProps<T> & DFProps & { isLegacyPortalForm: true })
  | (PortalFormProvidedProps<T> & DFProps & { isLegacyPortalForm: false });

/**
 * A wrapper around the antd form and drawer elements, with
 * common styles.
 *
 * @export
 * @class DrawerForm
 * @extends {Component<DrawerFormProps<TFormType>, TStateType>}
 * @template TFormType
 * @template TStateType
 * @deprecated Please use <DrawerForm /> from @portal/ui-lib instead
 */
export class DrawerForm<TFormType extends object> extends Component<
  DrawerFormProps<TFormType>
> {
  constructor(props: DrawerFormProps<TFormType>) {
    super(props);
  }

  private onCancel = () => {
    const { toggleDrawer, onCancel } = this.props;

    toggleDrawer();
    onCancel?.();
  };

  public render() {
    const {
      afterVisibleChange,
      className,
      preview,
      title,
      visible,
      maskClosable = true,
    } = this.props;

    return (
      <Drawer
        destroyOnClose
        afterVisibleChange={afterVisibleChange}
        className={classNames('portal-drawer-form', className)}
        height="100%"
        maskClosable={maskClosable}
        title={title}
        visible={visible}
        width={this.width}
        onClose={this.onCancel}
      >
        <div className="portal-drawer-form-container">
          <div className="portal-drawer-form-preview">{preview}</div>
          {this.props.isLegacyPortalForm ? (
            <LegacyPortalForm {...this.props} onCancel={this.onCancel} />
          ) : (
            <PortalForm {...this.props} onCancel={this.onCancel} />
          )}
        </div>
      </Drawer>
    );
  }

  private get width() {
    return this.props.preview ? '100%' : 400;
  }
}
