import * as React from 'react';

import type { ComplianceStatus } from '@portal/api-models';
import { StatusDot, StatusToIntent } from '@portal/ui-lib';

import { getComplianceStatusLabel } from '@/components/table/compliance-columns';

export interface StatusDotComplianceInterface {
  className?: string;
  status?: ComplianceStatus;
}

export const StatusDotCompliance: React.FC<StatusDotComplianceInterface> = ({
  status,
  className,
}) => {
  const statusLabel = getComplianceStatusLabel(status);
  const statusIntent = StatusToIntent[statusLabel.toLowerCase()];

  return (
    <StatusDot className={className} status={statusIntent}>
      {statusLabel}
    </StatusDot>
  );
};
