import * as React from 'react';
import { useSelector } from 'react-redux';

import type { Roles } from '@portal/api-models';
import {
  getSubRoleObjects,
  mapEvenRolesToRoles,
} from '@portal/common/dist/access-control';
import {
  selectPermissions,
  selectUserRoles,
} from '@portal/store/dist/user/selectors';

const FooterLink = ({ url, children }) => (
  <a href={url} rel="noreferrer" target="_blank">
    {children}
  </a>
);

const Footnote: React.FC = () => {
  const accountRoles = useSelector(selectUserRoles);
  const metaRoles = mapEvenRolesToRoles(accountRoles) as Roles[];
  const { demandRoleObject, supplyRoleObject } =
    getSubRoleObjects(metaRoles) ?? {};
  const isMulti = Boolean(demandRoleObject) && Boolean(supplyRoleObject);

  const { hasScaleUserRole } = useSelector(selectPermissions);

  const footerElements: JSX.Element[] = [
    <FooterLink key="tos" url="https://engine.tech/control-center/legal/terms">
      Terms of Use
    </FooterLink>,
    <FooterLink key="pp" url="https://engine.tech/about/legal">
      Privacy Policy
    </FooterLink>,
  ];

  if (hasScaleUserRole || isMulti) {
    footerElements.push(
      <FooterLink
        key="msa"
        url="https://controls.engine.tech/docs/master-services-agreement-supply-2024-05.docx"
      >
        MSA
      </FooterLink>
    );
  }

  const renderFooterElements = () =>
    footerElements.map((element, index) => (
      <React.Fragment key={index}>
        {element}
        {index < footerElements.length - 2 ? <span>&nbsp;,&nbsp;</span> : null}
        {index === footerElements.length - 2 ? (
          <span>&nbsp;and&nbsp;</span>
        ) : null}
      </React.Fragment>
    ));

  return (
    <div className="portal-content-footnote">
      Click here to see {renderFooterElements()}
    </div>
  );
};

export default Footnote;
