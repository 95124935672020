import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faEnvelope,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'antd/lib/tooltip';
import classNames from 'classnames';

import type { PartnerPageAlooma } from '@portal/api-models';
import { NotificationTopic, NotificationType } from '@portal/api-models';

import NotificationMessage from '@/components/notification/notification-message';
import { Router } from '@/routes';
import type { ApplicationState } from '@/store';
import { formatDate } from '@/utils/date-time-formatters';

export const notificationIcon = (type: string) => {
  switch (type) {
    case NotificationType.Info:
      return (
        <FontAwesomeIcon
          className="notification-info ant-notification-notice-icon ant-notification-notice-icon-info"
          icon={faCircleInfo}
        />
      );

    case NotificationType.Warning:
      return (
        <FontAwesomeIcon
          className="notification-warning ant-notification-notice-icon ant-notification-notice-icon-warning"
          icon={faTriangleExclamation}
        />
      );

    case NotificationType.Error:
      return (
        <FontAwesomeIcon
          className="notification-error ant-notification-notice-icon ant-notification-notice-icon-error"
          icon={faCircleExclamation}
        />
      );

    case NotificationType.Success:
      return (
        <FontAwesomeIcon
          className="notification-success ant-notification-notice-icon ant-notification-notice-icon-success"
          icon={faCircleCheck}
        />
      );
  }
};

export interface NotificationItemProps {
  message?: string;
  onReadAction?: () => void;
  read: boolean;
  time: number | string | Date;
  title: string;
  topic: NotificationTopic;
  type: string;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  type,
  read,
  title,
  message,
  topic,
  time,
  onReadAction,
}) => {
  const partnerPages = useSelector<ApplicationState, PartnerPageAlooma[]>(
    ({ partnerPages: { rows } }) => rows
  );
  let actionIcon;

  const readAction: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.stopPropagation();
    onReadAction && onReadAction();
  };

  if (read) {
    actionIcon = (
      <a className="ant-notification-notice-close unread" onClick={readAction}>
        <span className="ant-notification-close-x">
          <Tooltip placement="left" title="Mark as unread">
            <FontAwesomeIcon
              className="ant-notification-close-x"
              icon={faEnvelope}
            />
          </Tooltip>
        </span>
      </a>
    );
  } else {
    actionIcon = (
      <a
        className={classNames('ant-notification-notice-close', 'read')}
        onClick={readAction}
      >
        <span className="ant-notification-close-x">
          <Tooltip placement="left" title="Mark as read">
            <FontAwesomeIcon
              className="ant-notification-close-x"
              icon={faEnvelope}
            />
          </Tooltip>
        </span>
      </a>
    );
  }

  const handleBodyAction = useCallback(() => {
    if (!read && onReadAction) {
      onReadAction();
    }

    switch (topic) {
      case NotificationTopic.awaitingBankingInformation:
        Router.push('/account/payout');
        break;
      case NotificationTopic.planActivationReminder:
      case NotificationTopic.planPurchase:
      case NotificationTopic.trialExpireReminder:
        Router.push('/account/subscription');
        break;
      case NotificationTopic.submittedForInternalReview:
        Router.push('/integrations');
        break;
      case NotificationTopic.submitForInternalReview: {
        const partnerKey = message?.split(' ')[2];

        const partnerPage = partnerPages.find(
          (pp) => pp.partner_key === partnerKey
        );

        partnerPage && Router.push(`/integrations/${partnerPage?.id}/wizard`);
        break;
      }
      case NotificationTopic.migrationUpgrade:
        Router.push('/account/upgrade');
        break;
      default:
        break;
    }
  }, [topic]);

  const isClickable = useMemo(() => {
    if (topic !== NotificationTopic.default) {
      return true;
    }

    return false;
  }, [topic]);

  return (
    <div
      className={classNames('notification-item', {
        clickable: isClickable,
      })}
      onClick={handleBodyAction}
    >
      <div className="ant-notification">
        <div className="ant-notification-notice ant-notification-notice-closable">
          <div className="ant-notification-notice-content">
            <div className="ant-notification-notice-with-icon" role="alert">
              {notificationIcon(type)}
              {title && (
                <div
                  className={classNames('ant-notification-notice-message', {
                    read,
                  })}
                >
                  {title}
                </div>
              )}
              <NotificationMessage message={message} />
            </div>
          </div>
          {actionIcon}
        </div>
      </div>

      <div className="notification-item-time">{formatDate(time)}</div>
    </div>
  );
};

export default NotificationItem;
