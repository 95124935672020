import * as React from 'react';

import Empty from 'antd/lib/empty';

export interface EmptyPlaceholderProps {
  description: string;
  image?: React.ReactNode;
}

const EmptyPlaceholder: React.FC<EmptyPlaceholderProps> = ({
  image,
  description,
}) => <Empty description={description} image={image} />;

EmptyPlaceholder.defaultProps = {
  image: Empty.PRESENTED_IMAGE_SIMPLE,
};

export default EmptyPlaceholder;
