import type { Dispatch } from 'react';
import * as React from 'react';

import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Router from 'next/router';
import type { Action } from 'typesafe-actions';

import type { SaasMigrationSetting } from '@evenfinancial/finance-client';

import { ComplianceStatus } from '@portal/api-models';

import { SubmitForReviewMessage } from '@/components/self-service/submit-for-review-message';
import { StatusDotCompliance } from '@/components/status-dot-compliance';
import type { PortalStep } from '@/components/steps';
import { ImplementationFeePaymentStatus } from '@/resources/integrations/types';
import type { IntegrationRow } from '@/resources/integrations/types';
import { GettingStartedBoxSteps } from '@/resources/self-service/getting-started/getting-started-box-steps';
import * as complianceAction from '@/store/compliance/actions';

export const getBeforePlanPurchasedActiveStep = (
  integration?: IntegrationRow
) => {
  if (!integration) {
    return GettingStartedBoxSteps.CREATE_FIRST_INTEGRATION;
  }

  if (
    !integration.complianceStatus ||
    integration.complianceStatus === ComplianceStatus.draft
  ) {
    return GettingStartedBoxSteps.SUBMIT_FOR_REVIEW;
  }

  return GettingStartedBoxSteps.ACTIVATE_PLAN;
};

export const getBeforPlanPurchasedSteps = (
  integration?: IntegrationRow,
  supplyMigration?: SaasMigrationSetting
): PortalStep[] => {
  return [
    {
      action: {
        onClick: () => {},
        title: 'Done',
      },
      alwaysShowAction: true,
      description: 'Register and login to Control Center',
      hideTail: true,
      icon: <FontAwesomeIcon icon={faCheck} />,
      id: GettingStartedBoxSteps.SIGN_UP.toString(),
      title: 'Sign Up',
    },
    {
      action: {
        onClick: async () => Router.push('/integrations'),
        title: 'Create',
      },
      alwaysShowAction: true,
      description:
        'Set up a Partner Page for your customers to get access to financial products',
      icon: <FontAwesomeIcon icon={faCheck} />,
      id: GettingStartedBoxSteps.CREATE_FIRST_INTEGRATION.toString(),
      title: 'Create Your First Integration',
    },
    {
      action: {
        confirmable:
          integration?.implementationFeePaymentStatus ===
            ImplementationFeePaymentStatus.Waived ||
          integration?.implementationFeePaymentStatus ===
            ImplementationFeePaymentStatus.Paid
            ? {
                cancelText: 'Cancel',
                message: (
                  <SubmitForReviewMessage integrationTypeLabel="partner page" />
                ),
                okText: 'Submit',
                title: 'Submit for Review',
              }
            : undefined,
        onClick: (dispatch: Dispatch<Action>) => {
          dispatch(
            complianceAction.submitForReviewAction.request({
              integrationId: integration?.id!,
              subAccountUuid: integration?.subAccountUuid!,
            })
          );
        },
        title: 'Submit',
      },
      alwaysShowAction: true,
      customActionContent: integration?.complianceStatus ? (
        <StatusDotCompliance
          className="margin-none"
          status={integration?.complianceStatus}
        />
      ) : undefined,
      description: 'Submit your partner page for compliance review',
      hideTail: true,
      icon: <FontAwesomeIcon icon={faCheck} />,
      id: GettingStartedBoxSteps.SUBMIT_FOR_REVIEW.toString(),
      title: 'Compliance Submission',
    },
    {
      action: {
        onClick: async () => {
          return supplyMigration
            ? Router.push('/account/upgrade')
            : Router.push('/account/subscription');
        },
        title: 'Purchase Now',
      },
      alwaysActive: true,
      description:
        'Sign MSA, & Control Center Addendum, purchase a plan, and publish your integration to go live on Even network',
      icon: <FontAwesomeIcon icon={faCheck} />,
      id: GettingStartedBoxSteps.ACTIVATE_PLAN.toString(),
      title: 'Activate Your Plan',
    },
  ];
};
