/* eslint-disable redux-saga/yield-effects */
import { all, put, select, takeLatest } from 'redux-saga/effects';
import type { PutEffect } from 'redux-saga/effects';
import { createAction, getType } from 'typesafe-actions';
import type { ActionType } from 'typesafe-actions';

import type { PermissionConfig } from '@portal/common/dist/access-control';
import * as accountActions from '@portal/store/dist/account/actions';
import * as userActions from '@portal/store/dist/user/actions';
import { viewContextSelector } from '@portal/store/dist/view-context/selectors';

import * as partnerPageActions from '@/store/partner-page/actions';
import * as saasActions from '@/store/saas/actions';
import * as subAccountActions from '@/store/subaccount/actions';

enum UserPagesActionType {
  USER_DETAILS_PAGE_REQUEST_CLIENT = '@@pages/USER_DETAILS_PAGE_REQUEST_CLIENT',
  USER_INDEX_PAGE_REQUEST_CLIENT = '@@pages/USER_INDEX_PAGE_REQUEST_CLIENT',
}

interface UserDetailsPageRequestPayload {
  uuid: string;
}

export const requestUserIndexPageAction = createAction(
  UserPagesActionType.USER_INDEX_PAGE_REQUEST_CLIENT
)();

export const requestUserDetailsPageAction = createAction(
  UserPagesActionType.USER_DETAILS_PAGE_REQUEST_CLIENT
)<UserDetailsPageRequestPayload>();

export function* requestUserIndexPage() {
  const {
    canDeactivateUsers,
    shouldGetAllSaasSettingsOnUserIndexPage,
    shouldGetDemandSubAccountsOnUserIndexPage,
    shouldGetPartnerKeysOnUserIndexPage,
    shouldGetSupplySubAccountsOnUserIndexPage,
  }: PermissionConfig = yield select(viewContextSelector);

  const conditionalEffects: Array<PutEffect<any>> = [];

  if (shouldGetDemandSubAccountsOnUserIndexPage) {
    conditionalEffects.push(
      put(subAccountActions.demandSubAccountRequestAction.request({}))
    );
  }

  if (shouldGetSupplySubAccountsOnUserIndexPage) {
    conditionalEffects.push(
      put(subAccountActions.supplySubAccountRequestAction.request({}))
    );
  }

  if (shouldGetPartnerKeysOnUserIndexPage) {
    conditionalEffects.push(
      put(partnerPageActions.partnerKeyRequestAction.request({}))
    );
  }

  if (shouldGetAllSaasSettingsOnUserIndexPage) {
    conditionalEffects.push(
      put(saasActions.saasAllRequestAction.request({ skipFees: true }))
    );
  }

  if (canDeactivateUsers) {
    conditionalEffects.push(
      put(userActions.userDeactivationRequestAction.request({}))
    );
  }

  yield all([
    put(userActions.userRequestAction.request({})),
    put(userActions.userAccessRequestAction.request({})),
    put(accountActions.accountRequestAction.request({})),
    ...conditionalEffects,
  ]);
}

export function* requestUserDetailsPage(
  action: ActionType<typeof requestUserDetailsPageAction>
) {
  const { uuid } = action.payload;
  const {
    shouldGetUserDeactivationsOnUserDetailsPage,
    shouldGetSupplySubAccountsOnUserDetailsPage,
  } = yield select(viewContextSelector);

  const conditionalEffects: Array<PutEffect<any>> = [];

  if (shouldGetUserDeactivationsOnUserDetailsPage) {
    conditionalEffects.push(
      put(userActions.userDeactivationRequestAction.request({}))
    );
  }

  if (shouldGetSupplySubAccountsOnUserDetailsPage) {
    conditionalEffects.push(
      put(subAccountActions.supplySubAccountRequestAction.request({}))
    );
  }

  yield all([
    // Common
    put(userActions.userByUuidRequestAction.request({ uuid })),
    put(userActions.userAccessByUuidRequestAction.request({ uuid })),
    put(accountActions.accountRequestAction.request({})),
    put(subAccountActions.demandSubAccountRequestAction.request({})),
    ...conditionalEffects,
  ]);
}

export function* userPagesSaga() {
  yield takeLatest(getType(requestUserIndexPageAction), requestUserIndexPage);
  yield takeLatest(
    getType(requestUserDetailsPageAction),
    requestUserDetailsPage
  );
}
