"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scaleScopes = void 0;
var enum_1 = require("../enum");
exports.scaleScopes = [
    enum_1.Scopes.AccessTokensCreate,
    enum_1.Scopes.AccessTokensRead,
    enum_1.Scopes.AccountsRead,
    enum_1.Scopes.ApplicationsRead,
    enum_1.Scopes.CompaniesRead,
    enum_1.Scopes.ComplianceRead,
    enum_1.Scopes.DeactivationsCreate,
    enum_1.Scopes.DeactivationsDelete,
    enum_1.Scopes.DeactivationsRead,
    enum_1.Scopes.FeedbacksCreate,
    enum_1.Scopes.HotjarRead,
    enum_1.Scopes.LedgerItemsRead,
    enum_1.Scopes.PartnerPagesCreate,
    enum_1.Scopes.PartnerPagesDelete,
    enum_1.Scopes.PartnerPagesRead,
    enum_1.Scopes.PartnerPagesUpdate,
    enum_1.Scopes.RevenueReportsRead,
    enum_1.Scopes.RichAccessTokensRead,
    enum_1.Scopes.SubAccountsCreate,
    enum_1.Scopes.SubAccountsRead,
    enum_1.Scopes.SubAccountsUpdate,
    enum_1.Scopes.SupplyContractsCreate,
    enum_1.Scopes.SupplyContractsRead,
    enum_1.Scopes.SupplyMigrationRead,
    enum_1.Scopes.SupplyTemplateApplicationCreate,
    enum_1.Scopes.SupplyTemplateApplicationRead,
    enum_1.Scopes.SupplyTemplatesLendersRead,
    enum_1.Scopes.SupplyTemplatesRead,
    enum_1.Scopes.UserDeactivationsRead,
    enum_1.Scopes.UsersCreate,
    enum_1.Scopes.UsersRead,
    enum_1.Scopes.UsersUpdate,
];
