"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WhitelistActionType = void 0;
var WhitelistActionType;
(function (WhitelistActionType) {
    WhitelistActionType["CREATE_MULTIPLE_WHITELIST_FAILURE"] = "@@whitelist/CREATE_MULTIPLE_WHITELIST_FAILURE_CLIENT";
    WhitelistActionType["CREATE_MULTIPLE_WHITELIST_REQUEST"] = "@@whitelist/CREATE_MULTIPLE_WHITELIST_REQUEST";
    WhitelistActionType["CREATE_MULTIPLE_WHITELIST_SUCCESS"] = "@@whitelist/CREATE_MULTIPLE_WHITELIST_SUCCESS";
    WhitelistActionType["CREATE_WHITELIST_FAILURE"] = "@@whitelist/CREATE_WHITELIST_FAILURE_CLIENT";
    WhitelistActionType["CREATE_WHITELIST_REQUEST"] = "@@whitelist/CREATE_WHITELIST_REQUEST";
    WhitelistActionType["CREATE_WHITELIST_SUCCESS"] = "@@whitelist/CREATE_WHITELIST_SUCCESS";
    WhitelistActionType["DELETE_WHITELIST_FAILURE"] = "@@whitelist/DELETE_WHITELIST_FAILURE";
    WhitelistActionType["DELETE_WHITELIST_REQUEST"] = "@@whitelist/DELETE_WHITELIST_REQUEST";
    WhitelistActionType["DELETE_WHITELIST_SUCCESS"] = "@@whitelist/DELETE_WHITELIST_SUCCESS";
    WhitelistActionType["GET_ALL_WHITELIST_FAILURE"] = "@@whitelist/GET_ALL_WHITELIST_FAILURE";
    WhitelistActionType["GET_ALL_WHITELIST_REQUEST"] = "@@whitelist/GET_ALL_WHITELIST_REQUEST";
    WhitelistActionType["GET_ALL_WHITELIST_SUCCESS"] = "@@whitelist/GET_ALL_WHITELIST_SUCCESS";
    WhitelistActionType["TOGGLE_CREATE"] = "@@whitelist/TOGGLE_CREATE";
})(WhitelistActionType = exports.WhitelistActionType || (exports.WhitelistActionType = {}));
