"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSelectAccountByUuid = exports.getAccounts = exports.selectDeactivateAccounts = exports.getHaveAccountsBeenLoaded = exports.selectFirstAccountUuid = exports.selectFirstAccountId = exports.selectFirstAccount = exports.selectAccountsKeyedById = exports.selectAccountsKeyedByUuid = exports.selectAccounts = exports.getSlice = void 0;
var lodash_1 = require("lodash");
var reselect_1 = require("reselect");
var utils_1 = require("../utils");
var getSlice = function (state) { return state.accounts; };
exports.getSlice = getSlice;
var selectAccounts = function (state) {
    return state.accounts.rows;
};
exports.selectAccounts = selectAccounts;
exports.selectAccountsKeyedByUuid = (0, reselect_1.createSelector)([exports.selectAccounts], function (accounts) { return (0, lodash_1.keyBy)(accounts, 'uuid'); });
exports.selectAccountsKeyedById = (0, reselect_1.createSelector)([exports.selectAccounts], function (accounts) { return (0, lodash_1.keyBy)(accounts, 'id'); });
exports.selectFirstAccount = (0, reselect_1.createSelector)([exports.selectAccounts], function (accounts) { return accounts === null || accounts === void 0 ? void 0 : accounts[0]; });
exports.selectFirstAccountId = (0, reselect_1.createSelector)([exports.selectAccounts], function (accounts) { var _a; return (_a = accounts === null || accounts === void 0 ? void 0 : accounts[0]) === null || _a === void 0 ? void 0 : _a.id; });
exports.selectFirstAccountUuid = (0, reselect_1.createSelector)([exports.selectAccounts], function (accounts) { var _a; return (_a = accounts === null || accounts === void 0 ? void 0 : accounts[0]) === null || _a === void 0 ? void 0 : _a.uuid; });
var getHaveAccountsBeenLoaded = function (state) {
    return state.accounts.haveAllAccountsBeenRequested;
};
exports.getHaveAccountsBeenLoaded = getHaveAccountsBeenLoaded;
var selectDeactivateAccounts = function (state) {
    return state.accounts.deactivationRows;
};
exports.selectDeactivateAccounts = selectDeactivateAccounts;
exports.getAccounts = (0, reselect_1.createSelector)([exports.getSlice], function (accts) { return accts === null || accts === void 0 ? void 0 : accts.rows; });
exports.getSelectAccountByUuid = (0, reselect_1.createSelector)([utils_1.getUUIDfromProps, exports.selectAccounts], function (uuid, accts) { return accts.find(function (account) { return account.uuid === uuid; }); });
