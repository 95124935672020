"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.viewContextSelector = void 0;
var reselect_1 = require("reselect");
var selectors_1 = require("../user/selectors");
var header_selector_1 = require("./header-selector");
var sidenav_selector_1 = require("./sidenav-selector");
exports.viewContextSelector = (0, reselect_1.createSelector)([sidenav_selector_1.sidenavSelector, header_selector_1.headerSelector, selectors_1.selectPermissions], function (sidenav, header, permissions) {
    return __assign(__assign(__assign({}, sidenav), header), permissions);
});
