import { HYDRATE } from 'next-redux-wrapper';
import type { Reducer } from 'redux';

import type { HydrateAction } from '@/store/actions';
import { reconcileState } from '@/store/utils';

import { ImportedLeadEventsActionType } from './types';
import type {
  ImportedLeadEventsAction,
  ImportedLeadEventsState,
} from './types';

export const initialState: ImportedLeadEventsState = {
  haveAllImportedLeadEventsBeenRequested: false,
  rows: [],
};

export const reducer: Reducer<
  ImportedLeadEventsState,
  ImportedLeadEventsAction | HydrateAction
> = (
  state: ImportedLeadEventsState = initialState,
  action: ImportedLeadEventsAction | HydrateAction
) => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...reconcileState(action.payload.importedLeadEvents, initialState),
      };
    }

    case ImportedLeadEventsActionType.GET_ALL_IMPORTED_LEAD_EVENTS_SUCCESS: {
      return {
        ...state,
        haveAllImportedLeadEventsBeenRequested: true,
        rows: action.payload.response,
      };
    }
    default: {
      return state;
    }
  }
};
