"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserField = void 0;
var UserField;
(function (UserField) {
    UserField["Account"] = "account";
    UserField["AllAccounts"] = "allAccounts";
    UserField["AllDemandSubAccounts"] = "allDemandSubAccounts";
    UserField["AllSupplySubAccounts"] = "allSupplySubAccounts";
    UserField["DemandRole"] = "demandRole";
    UserField["DemandSubAccounts"] = "demandSubAccounts";
    UserField["EmailAddress"] = "emailAddress";
    UserField["FirstName"] = "firstName";
    UserField["LastName"] = "lastName";
    UserField["PartnerType"] = "partnerType";
    UserField["RoleObject"] = "roleObject";
    UserField["SubAccounts"] = "subAccounts";
    UserField["SupplyRole"] = "supplyRole";
    UserField["SupplySubAccounts"] = "supplySubAccounts";
})(UserField = exports.UserField || (exports.UserField = {}));
