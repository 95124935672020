import * as React from 'react';

import Card from 'antd/lib/card';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import classNames from 'classnames';

interface MessageContainerProps extends React.PropsWithChildren<{}> {
  className?: string;
  title: string;
}

const MessageContainer: React.FC<MessageContainerProps> = ({
  title,
  className,
  children,
}) => {
  return (
    <Row
      className={classNames('message-container', className)}
      justify="center"
    >
      <Col lg={14} md={16} sm={18} xs={20}>
        <Card title={title}>{children}</Card>
      </Col>
    </Row>
  );
};

export default MessageContainer;
