import { createAction, createAsyncAction } from 'typesafe-actions';

import type { PortalDemandContract } from '@portal/api-models';
import type { PortalError } from '@portal/common';

import type { IsomorphicRequestPayload } from '../types';

import { DemandContractsActionType } from './types';

interface GetDemandContractByIdPayload extends IsomorphicRequestPayload {
  id: string;
}
interface InitDemandContractDetailsPagePayload
  extends IsomorphicRequestPayload {
  id: string | string[];
}

export const demandContractLoadingByIdAction = createAction(
  DemandContractsActionType.DEMAND_CONTRACT_LOADING_BY_ID_CHANGE
)<boolean>();

export const initDemandContractDetailsPage = createAction(
  DemandContractsActionType.INIT_DEMAND_CONTRACT_DETAILS_PAGE
)<InitDemandContractDetailsPagePayload>();

export const getAllDemandContracts = createAsyncAction(
  DemandContractsActionType.GET_ALL_CONTRACTS_REQUEST,
  DemandContractsActionType.GET_ALL_CONTRACTS_SUCCESS,
  DemandContractsActionType.GET_ALL_CONTRACTS_FAILURE
)<IsomorphicRequestPayload, PortalDemandContract[], PortalError>();

export const getDemandContractById = createAsyncAction(
  DemandContractsActionType.GET_DEMAND_CONTRACT_BY_ID_REQUEST,
  DemandContractsActionType.GET_DEMAND_CONTRACT_BY_ID_SUCCESS,
  DemandContractsActionType.GET_DEMAND_CONTRACT_BY_ID_FAILURE
)<GetDemandContractByIdPayload, PortalDemandContract[], PortalError>();
