import { initialize, pageview } from 'react-ga';

import { useRouter } from 'next/router';

import { useTracker } from './use-tracker';

export const useGoogleAnalytics = () => {
  const { events } = useRouter();

  useTracker(({ engineUser }) => {
    initialize('UA-57421938-19', {
      gaOptions: { userId: engineUser.emailAddress },
    });

    events.on('routeChangeComplete', pageview);
  });
};
