import { init } from '@sentry/browser';

import { useTracker } from './use-tracker';

export const useSentry = () => {
  useTracker(({ config }) => {
    init({
      dsn: config.SENTRY_DSN,
      environment: config.SENTRY_ENV,
    });
  });
};
