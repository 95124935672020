"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isBetween = void 0;
const lodash_1 = require("lodash");
const isBetween = (search, min, max) => {
    const parsedMin = (0, lodash_1.toNumber)(min);
    const parsedMax = (0, lodash_1.toNumber)(max);
    const parsedSearch = (0, lodash_1.toNumber)(search);
    return parsedSearch >= parsedMin && parsedSearch <= parsedMax;
};
exports.isBetween = isBetween;
