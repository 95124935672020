import * as React from 'react';

import { useRouter } from 'next/router';

import { ViewMoreButton } from '@portal/ui-lib';

import { CardTable } from '@/components/card-table';
import PanelCard from '@/components/panel-card/panel-card';
import EmptyPlaceholder from '@/components/table/empty-placeholder';
import type { InvoiceRow } from '@/resources/latest-invoices/selectors';
import { tableConfig } from '@/resources/self-service/home/latest-invoices-table-config';

export interface LatestInvoicesCardInterface {
  disabled: boolean;
  invoices: InvoiceRow[];
}

const LatestInvoicesCard: React.FC<LatestInvoicesCardInterface> = ({
  disabled,
  invoices,
}) => {
  const { push } = useRouter();

  return (
    <PanelCard
      className="latest-invoices-card"
      disabled={disabled}
      extra={<ViewMoreButton onClick={async () => push('/invoices')} />}
      title="Latest Invoices"
    >
      <CardTable
        showHeader
        columns={tableConfig(invoices)}
        dataSource={invoices}
        locale={{
          emptyText: (
            <EmptyPlaceholder
              description={
                !disabled
                  ? 'Your latest invoices will show here'
                  : 'Activate your plan to get paid'
              }
            />
          ),
        }}
        pagination={false}
        rowKey="id"
      />
    </PanelCard>
  );
};

export default LatestInvoicesCard;
