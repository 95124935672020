import type { Dispatch } from 'react';
import * as React from 'react';

import lowerCase from 'lodash/lowerCase';
import Router from 'next/router';
import type { Action } from 'typesafe-actions';

import {
  ComplianceIntegrationType,
  ComplianceStatus,
  IntegrationStatus,
  OnboardingStatus,
} from '@portal/api-models';

import { ImplementationFeeWaived } from '@/components/self-service/implementation-fee-waived';
import PublishIntegrationInstructions from '@/components/self-service/integrations/publish-integration-instructions';
import { SubmitForReviewMessage } from '@/components/self-service/submit-for-review-message';
import { ImplementationFeePaymentStatus } from '@/resources/integrations/types';
import type { IntegrationRow } from '@/resources/integrations/types';
import { isFeePaymentPreApprovalRequired } from '@/resources/self-service/payment/util-methods';
import * as complianceAction from '@/store/compliance/actions';
import * as partnerPageAction from '@/store/partner-page/actions';

export const getIntegrationTypeLabel = (integration: IntegrationRow) =>
  integration.integrationType === ComplianceIntegrationType.embed
    ? 'Embed'
    : 'Partner Page';

export const getIntegrationRowRenderProps = (
  row: IntegrationRow,
  isPaid?: boolean,
  displayPublishSuccess: boolean = false
) => {
  const integrationTypeLabel = getIntegrationTypeLabel(row);

  switch (row.onboardingStatus) {
    case OnboardingStatus.Draft:
      return {
        action: {
          confirmable:
            row.implementationFeePaymentStatus ===
              ImplementationFeePaymentStatus.Waived ||
            row.implementationFeePaymentStatus ===
              ImplementationFeePaymentStatus.Paid
              ? {
                  cancelText: 'Cancel',
                  message: (
                    <SubmitForReviewMessage
                      integrationTypeLabel={lowerCase(integrationTypeLabel)}
                      isPaid={isPaid}
                    />
                  ),
                  okText: 'Submit',
                  title: 'Submit for Compliance Review',
                }
              : undefined,
          onClick: (dispatch: Dispatch<Action>) => {
            dispatch(
              complianceAction.submitForReviewAction.request({
                integrationId: row.id,
                subAccountUuid: row.subAccountUuid!,
              })
            );
          },
          title: 'Submit',
        },
        subtitle: `Please submit your ${integrationTypeLabel} for compliance review`,
        title: `Submit your ${lowerCase(integrationTypeLabel)} for review`,
      };

    case OnboardingStatus.SubmittedForReview:
    case OnboardingStatus.IntegrationFeeUnpaid:
      return isPaid
        ? {
            action: {
              disabled: isFeePaymentPreApprovalRequired()
                ? row.complianceStatus !== ComplianceStatus.closed
                : false,
              onClick: () => {
                Router.push(`/implementation-fee?id=${row.id}`);
              },
              title: 'Pay now',
            },
            subtitle: `Please pay your one time implementation fee for the ${lowerCase(
              integrationTypeLabel
            )} to go live`,
            title: 'Pay your implementation fee to go live',
          }
        : {
            action: {
              onClick: () => {
                Router.push('/account/subscription');
              },
              title: 'Purchase plan',
            },
            subtitle:
              'In order to enable financial providers for your integration and to go live on your website, please publish your integration. If you implement your integration on your website without publishing, the integration will show dummy offers',
            title: 'Purchase a plan to publish your integration',
          };

    case OnboardingStatus.SubmittedForReviewPaid:
      return {
        action: {
          disabled: true,
          onClick: () => {},
          title: 'Publish',
        },
        subtitle: `We are waiting for compliance approval. You will be able to publish your ${integrationTypeLabel} as soon as you are approved`,
        title: 'Compliance approval required to go live',
      };

    case OnboardingStatus.Ready:
      return {
        action: {
          confirmable: isPaid
            ? {
                cancelText: 'Cancel',
                message:
                  row.integrationType === ComplianceIntegrationType.embed
                    ? 'Your Embed will be live on Even network as soon as you click on Confirm. Please cancel if you have not implemented the iframe into your website html.'
                    : 'Your Partner page will be live on Even network as soon as you click on Confirm.',
                okText: 'Confirm',
                title: 'Ready to go Live?',
              }
            : undefined,
          onClick: (dispatch: Dispatch<Action>) => {
            isPaid
              ? dispatch(
                  partnerPageAction.partnerKeyUpdateIntegrationStatusAction.request(
                    {
                      displaySuccess: displayPublishSuccess,
                      id: row.id,
                      key: row.partner_key,
                      status: IntegrationStatus.Live,
                    }
                  )
                )
              : Router.push('/account/subscription');
          },
          title: isPaid ? 'Publish' : 'Purchase Plan',
        },
        subtitle: isPaid ? (
          <PublishIntegrationInstructions
            integrationType={row.integrationType as ComplianceIntegrationType}
            integrationUrl={row.integrationUrl}
          />
        ) : (
          `In order to publish the ${integrationTypeLabel} on your website, you need to purchase a plan first`
        ),
        title: (
          <div>
            {row.implementationFeePaymentStatus ===
            ImplementationFeePaymentStatus.Waived ? (
              <div>
                <ImplementationFeeWaived />
              </div>
            ) : (
              ''
            )}
            {isPaid
              ? `Implement your ${integrationTypeLabel} to go live`
              : 'Purchase your plan to go live'}
          </div>
        ),
      };

    case OnboardingStatus.Live:
    default:
      return null;
  }
};
