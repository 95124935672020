"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LegalAgreementType = void 0;
var LegalAgreementType;
(function (LegalAgreementType) {
    LegalAgreementType[LegalAgreementType["ControlCenterAddendum"] = 14] = "ControlCenterAddendum";
    LegalAgreementType[LegalAgreementType["ControlCenterTermsOfUse"] = 9] = "ControlCenterTermsOfUse";
    LegalAgreementType[LegalAgreementType["MasterServiceAgreement"] = 16] = "MasterServiceAgreement";
    LegalAgreementType[LegalAgreementType["PrivacyPolicy"] = 2] = "PrivacyPolicy";
})(LegalAgreementType || (LegalAgreementType = {}));
exports.LegalAgreementType = LegalAgreementType;
