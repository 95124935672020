import * as React from 'react';

import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Tooltip } from 'antd';
import classNames from 'classnames';

export interface StatBarItem {
  label: string;
  tooltip?: string;
  value?: React.ReactNode;
}

export interface StatBarProps {
  className?: string;
  showTooltipsOnLabels?: boolean;
  stats: StatBarItem[];
}

const StatBar: React.FC<StatBarProps> = ({
  stats,
  className,
  showTooltipsOnLabels,
}) => {
  return (
    <div className="stat-bar-container">
      <Card className={`stat-bar ${className || ''}`}>
        {stats.map(({ label, value, tooltip }: StatBarItem, index) => {
          const labelNode = (
            <div
              className={classNames('stat-bar-item-label', {
                'stat-bar-item-label-with-tooltip':
                  showTooltipsOnLabels && tooltip,
              })}
            >
              <span>{label}</span>
            </div>
          );

          const labelItem =
            tooltip && showTooltipsOnLabels ? (
              <Tooltip className="stat-bar-item-tooltip" title={tooltip}>
                {labelNode}
              </Tooltip>
            ) : (
              labelNode
            );

          return (
            <Card.Grid
              key={index}
              className={`stat-bar-item ${tooltip && 'has-tooltip'}`}
              hoverable={false}
            >
              {labelItem}
              <div className="stat-bar-item-value">{value}</div>
              {tooltip && !showTooltipsOnLabels && (
                <Tooltip className="stat-bar-item-tooltip" title={tooltip}>
                  <FontAwesomeIcon icon={faCircleInfo} />
                </Tooltip>
              )}
            </Card.Grid>
          );
        })}
      </Card>
    </div>
  );
};

export default StatBar;
