import { action, createAction } from 'typesafe-actions';

import { MessageActionType } from './types';
import type { OverlayLoaderType } from './types';

export const error = createAction(MessageActionType.ERROR)<{
  customError?: string;
}>();

export const success = createAction(MessageActionType.SUCCESS)<string>();

export const updateText = (text: string) =>
  action(MessageActionType.UPDATE_TEXT, { text });

export const setOverlayLoading = (overlay?: OverlayLoaderType) =>
  action(MessageActionType.OVERLAY_LOADER, { overlay });

export const loadingStart = () => action(MessageActionType.LOADING_START);
export const loadingEnd = () => action(MessageActionType.LOADING_END);
