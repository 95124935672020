"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteCommunicationEligibilityMutation = exports.useCreateCommunicationEligibilityMutation = exports.useGetCommunicationEligibilitiesQuery = exports.deleteCommunicationEligibility = exports.createCommunicationEligibility = exports.getCommunicationEligibilities = void 0;
var react_query_1 = require("react-query");
var client_1 = require("./client");
var types_1 = require("./types");
var getCommunicationEligibilities = function (params) {
    return client_1.client
        .get('/campaign/communication_eligibility', {
        params: params,
    })
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.getCommunicationEligibilities = getCommunicationEligibilities;
var createCommunicationEligibility = function (data) { return client_1.client.post('/campaign/communication_eligibility', data); };
exports.createCommunicationEligibility = createCommunicationEligibility;
var deleteCommunicationEligibility = function (data) { return client_1.client.delete('/campaign/communication_eligibility', { data: data }); };
exports.deleteCommunicationEligibility = deleteCommunicationEligibility;
var useGetCommunicationEligibilitiesQuery = function (params, options) {
    return (0, react_query_1.useQuery)([types_1.QueryKey.CommunicationEligibilities, params], function () { return (0, exports.getCommunicationEligibilities)(params); }, options);
};
exports.useGetCommunicationEligibilitiesQuery = useGetCommunicationEligibilitiesQuery;
var useCreateCommunicationEligibilityMutation = function () {
    return (0, react_query_1.useMutation)(exports.createCommunicationEligibility);
};
exports.useCreateCommunicationEligibilityMutation = useCreateCommunicationEligibilityMutation;
var useDeleteCommunicationEligibilityMutation = function () {
    return (0, react_query_1.useMutation)(exports.deleteCommunicationEligibility);
};
exports.useDeleteCommunicationEligibilityMutation = useDeleteCommunicationEligibilityMutation;
