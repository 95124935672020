import type { FormatPortalLog } from '@portal/common/dist/logging/format-portal-log';

import { LogLevel } from '@/utils/logger/logger';
import type { ParsedLog } from '@/utils/logger/parser';

export interface TransportOptions {
  format?: FormatPortalLog;
  name: string;
}

export abstract class Transport<
  Options extends TransportOptions = TransportOptions
> {
  protected options: Options;
  protected queue: [LogLevel, ParsedLog][] = [];

  constructor(options: Options) {
    this.options = options;
  }

  public abstract info(parsed: ParsedLog): void;
  public abstract debug(parsed: ParsedLog): void;
  public abstract warn(parsed: ParsedLog): void;
  public abstract error(parsed: ParsedLog): void;

  public executeQueue() {
    this.queue.forEach(([level, parsed]) => {
      switch (level) {
        case LogLevel.Info:
          return this.info(parsed);

        case LogLevel.Debug:
          return this.debug(parsed);

        case LogLevel.Warn:
          return this.warn(parsed);

        case LogLevel.Error:
          return this.error(parsed);
      }
    });
  }
}
