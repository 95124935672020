"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.supplyRoleObjectArray = exports.demandRoleObjectArray = exports.internalRoleObjectArray = exports.UserAccessNonInternalRolesArray = exports.UserAccessRolesArray = exports.UserAccessTierRolesArray = exports.UserAccessExternalRolesArray = exports.UserAccessInternalRolesArray = exports.UserPartnerRolesArray = exports.UserTypeNonInternalRolesArray = exports.UserTypeRolesArray = void 0;
const api_models_1 = require("@portal/api-models");
exports.UserTypeRolesArray = [
    api_models_1.Roles.InternalUser,
    api_models_1.Roles.ExternalUser,
    api_models_1.Roles.SelfService,
];
exports.UserTypeNonInternalRolesArray = [
    api_models_1.Roles.ExternalUser,
    api_models_1.Roles.SelfService,
];
exports.UserPartnerRolesArray = [api_models_1.Roles.DemandUser, api_models_1.Roles.SupplyUser];
exports.UserAccessInternalRolesArray = [
    api_models_1.Roles.InternalViewer,
    api_models_1.Roles.Manager,
    api_models_1.Roles.SuperAdministrator,
];
exports.UserAccessExternalRolesArray = [api_models_1.Roles.Reporter];
exports.UserAccessTierRolesArray = [
    api_models_1.Roles.CRM,
    api_models_1.Roles.Growth,
    api_models_1.Roles.Scale,
    api_models_1.Roles.ScaleAPI,
    api_models_1.Roles.Starter,
    api_models_1.Roles.StarterLegacy,
];
exports.UserAccessRolesArray = [
    ...exports.UserAccessInternalRolesArray,
    ...exports.UserAccessExternalRolesArray,
    ...exports.UserAccessTierRolesArray,
];
exports.UserAccessNonInternalRolesArray = [
    ...exports.UserAccessExternalRolesArray,
    ...exports.UserAccessTierRolesArray,
];
exports.internalRoleObjectArray = [
    {
        flatRole: api_models_1.FlatRole.InternalViewer,
        roles: api_models_1.RoleCombinations.InternalViewer,
    },
    {
        flatRole: api_models_1.FlatRole.InternalManager,
        roles: api_models_1.RoleCombinations.InternalManager,
    },
];
exports.demandRoleObjectArray = [
    {
        flatRole: api_models_1.FlatRole.Demand,
        roles: api_models_1.RoleCombinations.Demand,
    },
];
exports.supplyRoleObjectArray = [
    {
        flatRole: api_models_1.FlatRole.SupplyLite,
        roles: api_models_1.RoleCombinations.SupplyLite,
    },
    {
        flatRole: api_models_1.FlatRole.StarterLegacy,
        roles: api_models_1.RoleCombinations.StarterLegacy,
    },
    {
        flatRole: api_models_1.FlatRole.StarterFree,
        roles: api_models_1.RoleCombinations.StarterFree,
    },
    {
        flatRole: api_models_1.FlatRole.Growth,
        roles: api_models_1.RoleCombinations.Growth,
    },
    {
        flatRole: api_models_1.FlatRole.Scale,
        roles: api_models_1.RoleCombinations.Scale,
    },
    {
        flatRole: api_models_1.FlatRole.ScaleAPI,
        roles: api_models_1.RoleCombinations.ScaleAPI,
    },
    {
        flatRole: api_models_1.FlatRole.CRM,
        roles: api_models_1.RoleCombinations.CRM,
    },
];
