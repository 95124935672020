"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toPortionOf100 = exports.toPortionOf1 = void 0;
const toPortionOf1 = (value) => {
    if (!value) {
        return '0.0';
    }
    return Math.min(Math.max(Number(value) / 100, 0), 1).toFixed(4);
};
exports.toPortionOf1 = toPortionOf1;
const toPortionOf100 = (value) => {
    if (!value) {
        return '0.0';
    }
    return Math.min(Math.max(Number(value) * 100, 0), 100).toFixed(2);
};
exports.toPortionOf100 = toPortionOf100;
