import { message } from 'antd';
import type { AxiosResponse } from 'axios';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import {
  all,
  call,
  fork,
  put,
  putResolve,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import type { ActionType } from 'typesafe-actions';

import { IntegrationType } from '@evenfinancial/auth-client';
import {
  ConfigProductToProduct,
  ConfigProductType,
  FeatureFlag,
  NonFlatConfigParameterKeys,
} from '@evenfinancial/even-ts-static';
import type { ProductStep as ProductStepType } from '@evenfinancial/even-ts-static';
import {
  AccountTierType,
  ProductType,
  SubAccountType,
} from '@evenfinancial/finance-client';
import type { SaasPlan, SaasTrial } from '@evenfinancial/finance-client';
import { SupplyTemplateApplicationStrategy } from '@evenfinancial/originator-client';
import type { SupplyTemplate } from '@evenfinancial/originator-client';

import { client } from '@portal/api-client';
import type {
  ComplianceItem,
  PartnerPageAlooma,
  PortalSubAccount,
  PortalSubAccountCreateData,
} from '@portal/api-models';
import {
  CommunicationChannel,
  DataShareType,
  OnboardingStatus,
} from '@portal/api-models';
import {
  getPortalError,
  getSupplyTemplateProductTypeFromPartnerPage,
} from '@portal/common';
import { getTutorialVideos, selectIsInMigration } from '@portal/store/dist';
import * as accountActions from '@portal/store/dist/account/actions';
import { TutorialVideoSeriesType } from '@portal/store/dist/tutorials/types';
import { selectPermissions } from '@portal/store/dist/user/selectors';
import { viewContextSelector } from '@portal/store/dist/view-context/selectors';

import { orderCompliancesById } from '@/resources/compliance/utils';
import { IntegrationsTableDataSelector } from '@/resources/integrations/selectors';
import type { IntegrationRow } from '@/resources/integrations/types';
import { selectHasPartnerPages } from '@/resources/partner-keys/selects';
import { saasUserPlanSelector } from '@/resources/saas/selectors';
import {
  selectCurrentSaasPlan,
  selectSaasUserTrial,
} from '@/resources/saas/selects';
import type { IntegrationCreateForm } from '@/resources/self-service/integrations/create-form';
import {
  selectSupplySubAccountUuid,
  selectSupplyTemplateProductType,
} from '@/resources/self-service/integrations/selects';
import { integrationProductReachedLimit } from '@/resources/self-service/integrations/util';
import { Router } from '@/routes';
import { getSupplyTemplates } from '@/services/originator/supply-templates';
import * as complianceActions from '@/store/compliance/actions';
import * as originatorActions from '@/store/originator/actions';
import { createSupplyTemplateApplicationAction } from '@/store/originator/actions';
import { findPremiumLendersAction } from '@/store/premium-lenders';
import { saasAllRequestAction } from '@/store/saas/actions';
import { TrustedPartnersActionType } from '@/store/trusted-partners/types';
import { getBase64 } from '@/utils/base64-unmarshal';
import { EvenDateFormat, formatDate } from '@/utils/date-time-formatters';

import type { ApplicationState } from '..';
import { ProductStep } from '../../resources/partner-page/fields/constants';
import { mapCreditCardFlowFromForm } from '../../resources/partner-page/fields/credit-cards';
import {
  flatten,
  getConfigData,
  isConfigChanged,
} from '../../resources/partner-page/query-string-mapper';
import * as subAccountActions from '../subaccount/actions';
import * as trustedPartnerActions from '../trusted-partners/actions';
import {
  getAvailableTrustedPartnersByCompanyUuid,
  getFeaturedTrustedPartnersByCompanyUuid,
  setFeaturedTrustedPartnersForCompanyUuid,
} from '../trusted-partners/sagas';

import * as partnerPageActions from './actions';
import { PartnerPageActionType } from './types';

function* createPartnerPage(
  action: ActionType<
    typeof partnerPageActions.partnerPageCreateRequestAction.request
  >
) {
  const formParams = action.payload;

  try {
    let subAccountResource: PortalSubAccount = yield select(
      ({ subAccounts }: ApplicationState) =>
        subAccounts.byUuid[action.payload.supplySubAccountUuid]
    );

    const fromPartnerKeyIndexPage =
      !subAccountResource || !subAccountResource.companyUuid;

    if (!subAccountResource || fromPartnerKeyIndexPage) {
      subAccountResource = yield call(async () =>
        client
          .get<PortalSubAccount>(
            `/finance/subaccounts/${action.payload.supplySubAccountUuid}`
          )
          .then((res) => res.data)
      );
    }

    let step: ProductStepType | undefined;

    if (formParams.productType === ConfigProductType.CreditCard) {
      step = ProductStep.Intro;
    } else if (formParams.productType === ConfigProductType.Loan) {
      step = ProductStep.Application;
    }

    const productType =
      formParams?.productType === ConfigProductType.Deposits
        ? ConfigProductType.Deposits
        : ConfigProductToProduct[formParams.productType];

    let productSubType;

    if (productType !== formParams.productType) {
      productSubType = formParams.productType;
    }

    let partnerPageCreateData = {
      ['credentials.company_uuid']: subAccountResource.companyUuid,
      credit_card_rewards_enabled: false,
      deviceType: formParams.deviceType,
      partner: formParams.partner,
      partnerDisplayName: formParams.partner,
      productSubType,
      productType,
      step,
      version: formParams.version,
    };

    if (formParams.duplicateId !== undefined) {
      const duplicatedPartnerPage = yield* getPartnerPageById(
        formParams.duplicateId
      );

      if (duplicatedPartnerPage.productType === formParams.productType) {
        partnerPageCreateData = {
          ...duplicatedPartnerPage,
          ...partnerPageCreateData,
        };
      } else {
        partnerPageCreateData = {
          ...pickBy(duplicatedPartnerPage, (_value, key) =>
            key.includes('theme')
          ),
          ...partnerPageCreateData,
        };
      }
    }

    const newPartnerPage = yield call(async () =>
      client
        .post('/partner_page', partnerPageCreateData)
        .then((res) => res.data)
    );

    yield put(
      partnerPageActions.partnerPageCreateRequestAction.success(newPartnerPage)
    );
    yield put(
      subAccountActions.subAccountApplicationsByUuidAction.request({
        uuid: subAccountResource.uuid,
      })
    );

    if (fromPartnerKeyIndexPage) {
      yield put(partnerPageActions.partnerKeyRequestAction.request({}));
    }

    yield put(partnerPageActions.toggleCreate());
    setTimeout(
      () => message.success('The partner key was created successfully.'),
      100
    );
    Router.pushRoute(`/partner-keys/${newPartnerPage.id}`);
  } catch (err: any) {
    const response = JSON.parse(err.request.response);
    let customError;

    switch (response.statusCode) {
      case 422:
        customError = response.message;
        break;
      default:
        break;
    }

    yield put(
      partnerPageActions.partnerPageCreateRequestAction.failure({
        ...err,
        customError,
      })
    );
  }
}

function* createFirstPartnerPageSelfService(
  action: ActionType<
    typeof partnerPageActions.partnerPageCreateSelfServiceRequestAction.request
  >
) {
  const logo = action.payload.partnerLogo as FileList;
  const integrationForm = {
    ...action.payload,
    integrationType: IntegrationType.MpsPartnerPageEven,
    partnerLogo: { file: logo[0], fileList: Array.from(logo) },
  } as IntegrationCreateForm;

  yield put(
    partnerPageActions.partnerPageCreateIntegrationRequestAction.request(
      integrationForm
    )
  );

  yield put(
    partnerPageActions.partnerPageCreateSelfServiceRequestAction.success({})
  );
}

function* createPartnerPageSelfService(
  action: ActionType<
    typeof partnerPageActions.partnerPageCreateIntegrationRequestAction.request
  >
) {
  const formParams = action.payload;

  const productType =
    (formParams?.productType as unknown as ConfigProductType) ===
    ConfigProductType.Deposits
      ? ConfigProductType.Deposits
      : ConfigProductToProduct[formParams.productType];

  let productSubType;

  if (productType !== formParams.productType) {
    productSubType = formParams.productType;
  }

  const userPlan: SaasPlan | undefined = yield select(
    (state: ApplicationState) => saasUserPlanSelector(state)
  );
  const userTrial: SaasTrial | undefined = yield select(
    (state: ApplicationState) => selectSaasUserTrial(state)
  );

  const integrations: IntegrationRow[] = yield select(
    (state: ApplicationState) => IntegrationsTableDataSelector(state)
  );

  const limitReached = integrationProductReachedLimit(
    integrations,
    formParams.integrationType,
    userPlan,
    userTrial
  );

  const isDrawerVisible: boolean = yield select(
    (state: ApplicationState) => state.partnerPages.integrationCreateVisible
  );

  if (limitReached) {
    if (isDrawerVisible) {
      yield putResolve(partnerPageActions.toggleIntegrationCreate());
    }

    yield put(
      partnerPageActions.partnerPageCreateIntegrationRequestAction.success({})
    );

    return;
  }

  try {
    yield call([client, 'get'], '/partner_page/key/validation', {
      params: {
        partnerPageKey: kebabCase(formParams.partner),
        throwError: true,
      },
    });

    yield putResolve(
      partnerPageActions.partnerPageKeyValidationRequestAction.success(
        undefined
      )
    );

    const subAccountParams: PortalSubAccountCreateData = {
      accountUuid: '00000000-0000-0000-0000-000000000000',
      coBrand: false,
      communicationEligibilities: [
        CommunicationChannel.remarketingEmail,
        CommunicationChannel.transactionalEmail,
      ],
      dataShare: DataShareType.Eligible,
      displayName: formParams.partner,
      integrationType: formParams.integrationType,
      name: formParams.partner,
      roles: ['leadgen-with-pii', 'leadgen-without-pii'],
      subAccountType: SubAccountType.SupplySource,
      supplyProductTypes: [productType],
    };

    const { data: newSubAccount }: AxiosResponse<PortalSubAccount> = yield call(
      client.post,
      '/finance/subaccounts',
      subAccountParams
    );

    const imageAsBase64 = yield call(async () =>
      getBase64(formParams.partnerLogo.file)
        .then((res) => res)
        .catch((err) => {
          throw err;
        })
    );

    yield put(
      subAccountActions.subAccountApplicationsByUuidAction.request({
        uuid: newSubAccount.uuid,
      })
    );
    const newPartnerPageConfigObj: { [k: string]: any } = {
      ['credentials.company_uuid']: newSubAccount.companyUuid,
      onboardingStatus: OnboardingStatus.Draft,
      partner: kebabCase(formParams.partner),
      partnerDisplayName: kebabCase(formParams.partner),
      partnerLocationUrl: formParams.partnerLocationUrl,
      productSubType,
      productType,
      ['theme.partnerLogo']: imageAsBase64,
    };

    if (
      formParams.integrationType === IntegrationType.MpsPartnerPageEven &&
      productType === ProductType.Loan
    ) {
      newPartnerPageConfigObj.featureFlags = FeatureFlag.SingleColumnLayout;
    }

    const newPartnerPage = yield call(async () =>
      client
        .post('/partner_page', newPartnerPageConfigObj)
        .then((res) => res.data)
    );

    const contractParams = {
      effectiveAt: formatDate(
        new Date(newSubAccount.createdAt!).getTime(),
        EvenDateFormat
      ),
      payoutClauses: [{ key: 'bidder' }],
      subAccountUuid: newSubAccount.uuid,
    };

    yield call(client.post, '/finance/supply-contracts', contractParams);

    const supplyTemplateProductType =
      getSupplyTemplateProductTypeFromPartnerPage(newPartnerPage);

    if (supplyTemplateProductType) {
      const [template]: SupplyTemplate[] = yield call(
        getSupplyTemplates,
        client,
        {
          productType: [supplyTemplateProductType],
          tier: [AccountTierType.Trial],
        }
      );

      if (template) {
        yield put(
          createSupplyTemplateApplicationAction.request({
            data: {
              strategy: SupplyTemplateApplicationStrategy.AddOnly,
              supplySubAccountId: newSubAccount.id,
              supplyTemplateId: template.id,
            },
          })
        );
      }
    }

    yield put(
      partnerPageActions.partnerPageCreateIntegrationRequestAction.success(
        newPartnerPage
      )
    );

    if (isDrawerVisible) {
      yield putResolve(partnerPageActions.toggleIntegrationCreate());
    }

    Router.pushRoute(`/integrations/${newPartnerPage.id}/wizard`);
  } catch (err) {
    const error = getPortalError(err);
    const { customError } = error;

    if (customError) {
      yield call([message, 'error'], customError);
    }

    yield put(
      partnerPageActions.partnerPageCreateIntegrationRequestAction.failure(
        error
      )
    );
  }
}

function* deletePartnerPageById(
  action: ActionType<
    typeof partnerPageActions.partnerPageDeleteRequestAction.request
  >
) {
  try {
    const response: any = yield call(async () => {
      return client
        .delete(`/partner_page/partner/${action.payload.id}`)
        .then((res) => res.data);
    });

    yield put(
      partnerPageActions.partnerPageDeleteRequestAction.success(response)
    );

    let subAccountResource: PortalSubAccount = yield select(
      ({ subAccounts }: ApplicationState) =>
        subAccounts.byUuid[action.payload.supplySubAccountUuid]
    );

    const fromPartnerKeyIndexPage =
      !subAccountResource || !subAccountResource.companyUuid;

    if (!subAccountResource || fromPartnerKeyIndexPage) {
      subAccountResource = yield call(async () =>
        client
          .get<PortalSubAccount>(
            `/finance/subaccounts/${action.payload.supplySubAccountUuid}`
          )
          .then((res) => res.data)
      );
    }

    yield put(
      subAccountActions.subAccountApplicationsByUuidAction.request({
        uuid: subAccountResource.uuid,
      })
    );

    if (fromPartnerKeyIndexPage) {
      yield put(partnerPageActions.partnerKeyRequestAction.request({}));
    }

    setTimeout(
      () => message.success('The partner key was deleted successfully.'),
      100
    );
  } catch (err: any) {
    yield put(partnerPageActions.partnerPageDeleteRequestAction.failure(err));
  }
}

function* updatePartnerPage(
  action: ActionType<
    typeof partnerPageActions.partnerPageUpdateRequestAction.request
  >
) {
  const existing = yield select(
    ({ partnerPages }: ApplicationState) =>
      partnerPages.byPartnerKeyId[action.payload.id]
  );

  const { featured_trusted_partners, ...formParams } = action.payload;
  const subAccountUuid = formParams.subAccountUuid;

  delete formParams.subAccountUuid;

  const noRedirection = formParams.noRedirection;

  delete formParams.noRedirection;

  const existingCompliance: ComplianceItem = yield select(
    ({ compliance }: ApplicationState) =>
      orderCompliancesById(compliance.rows).find(
        (row) =>
          row.supplyPartnerSubaccountCompanyUuid ===
          existing?.['credentials.company_uuid']
      )
  );

  const isInMigration: boolean = yield select((state: ApplicationState) =>
    selectIsInMigration(state, existing.partner)
  );

  const formParamsFlat = omit(formParams, NonFlatConfigParameterKeys);
  let formParamsNonFlat = {};

  NonFlatConfigParameterKeys.forEach((key) => {
    formParamsNonFlat = {
      ...formParamsNonFlat,
      [key]: get(formParams, key),
    };
  });

  let partnerConfig = {
    ...existing,
    ...flatten(formParamsFlat),
    ...formParamsNonFlat,
  };

  try {
    const { shouldSetFeaturedTrustedPartnersOnPartnerPageUpdate } =
      yield select(selectPermissions);

    if (shouldSetFeaturedTrustedPartnersOnPartnerPageUpdate) {
      yield* setFeaturedTrustedPartnersForCompanyUuid({
        payload: {
          companyUuid: partnerConfig['credentials.company_uuid'],
          cookie: action.payload.cookie,
          demandSubAccountIds: featured_trusted_partners,
        },
        type: TrustedPartnersActionType.SET_FEATURED_REQUEST,
      });
    }
  } catch (err: any) {
    yield put(
      trustedPartnerActions.setFeaturedTrustedPartnersAction.failure(err)
    );
  }

  delete partnerConfig.partnerLogoBase64Image;
  delete partnerConfig.clientTags;

  if (partnerConfig.loanPurposeOptions?.length === 0) {
    delete partnerConfig.loanPurposeOptions;
  }

  if (partnerConfig['theme.partnerLogoWidth']?.length === 0) {
    delete partnerConfig['theme.partnerLogoWidth'];
  }

  partnerConfig.eventHandlers = partnerConfig.eventHandlers.filter(
    (eventHandler: any) => eventHandler.payload
  );
  partnerConfig = mapCreditCardFlowFromForm(partnerConfig);

  try {
    const updatedPartnerPage = yield call(async () =>
      client
        .post(`/partner_page/partner/${formParams.id}`, partnerConfig)
        .then((res) => res.data)
    );

    yield putResolve(
      partnerPageActions.partnerPageByKeyAction.request({
        id: updatedPartnerPage.id || formParams.id,
      })
    );

    yield putResolve(
      partnerPageActions.partnerPageUpdateRequestAction.success(true)
    );

    const { shouldSubmitForComplianceReviewOnPartnerPageUpdate } = yield select(
      selectPermissions
    );

    if (
      shouldSubmitForComplianceReviewOnPartnerPageUpdate &&
      isConfigChanged(existing, flatten(formParamsFlat)) &&
      (Boolean(existingCompliance) || (!existingCompliance && isInMigration))
    ) {
      yield put(
        complianceActions.submitForReviewAction.request({
          approvedChanges: getConfigData(existing),
          doNotRedirect: true,
          existingCompliance,
          integrationId: updatedPartnerPage.id,
          subAccountUuid,
          updatedChanges: getConfigData(flatten(formParamsFlat)),
        })
      );
    }

    const { shouldReplaceRouteParamPartnerPageWizard } = yield select(
      selectPermissions
    );

    if (shouldReplaceRouteParamPartnerPageWizard) {
      if (noRedirection) {
        yield put(partnerPageActions.partnerKeyRequestAction.request({}));
      } else {
        Router.replaceRoute(`/integrations/${updatedPartnerPage.id}/wizard`);
      }
    } else {
      Router.replaceRoute(`/partner-keys/${updatedPartnerPage.id}/settings`);
    }

    setTimeout(
      () => message.success('The partner key was updated successfully.'),
      100
    );
  } catch (err: any) {
    if (JSON.parse(err.request.response).statusCode === 400) {
      setTimeout(
        () => message.error(JSON.parse(err.request.response).message, 10),
        100
      );
    }

    yield put(partnerPageActions.partnerPageUpdateRequestAction.failure(err));
  }
}

function* getPartnerPageById(id: string | number) {
  return yield call(async () =>
    client.get<any>(`/partner_page/${id}`).then((res) => res.data)
  );
}

function* getByKey(
  action: ActionType<typeof partnerPageActions.partnerPageByKeyAction.request>
) {
  const { id, cookie, reloadPartnerPage } = action.payload;

  try {
    const response = yield* getPartnerPageById(id);

    yield putResolve(
      partnerPageActions.partnerPageByKeyAction.success(response)
    );

    yield* getAvailableTrustedPartnersByCompanyUuid({
      payload: {
        companyUuid: response['credentials.company_uuid'],
        cookie,
      },
      type: TrustedPartnersActionType.GET_AVAILABLE_REQUEST,
    });

    yield* getFeaturedTrustedPartnersByCompanyUuid({
      payload: {
        companyUuid: response['credentials.company_uuid'],
        cookie,
      },
      type: TrustedPartnersActionType.GET_FEATURED_REQUEST,
    });

    if (reloadPartnerPage) {
      const { shouldReplaceRouteParamPartnerPageWizard } = yield select(
        selectPermissions
      );

      if (shouldReplaceRouteParamPartnerPageWizard) {
        yield call([Router, 'replaceRoute'], `/integrations/${id}/wizard`);
      } else {
        yield call([Router, 'replaceRoute'], `/partner-keys/${id}/theme`);
      }
    }
  } catch (err: any) {
    yield call(Router.pushRoute, '/404');
    yield put(partnerPageActions.partnerPageByKeyAction.failure(err));
  }
}

export function* getPartnerKeys(
  action?: ActionType<typeof partnerPageActions.partnerKeyRequestAction.request>
) {
  try {
    const response: PartnerPageAlooma[] = yield call(async () => {
      return client
        .get<PartnerPageAlooma[]>('/partner_page')
        .then((res) => res.data);
    });

    if (action?.payload?.getAllByKey) {
      for (let i = 0; i < response.length; i++) {
        yield* getByKey(
          partnerPageActions.partnerPageByKeyAction.request({
            cookie: action.payload.cookie,
            id: response[i].id,
          })
        );
      }
    }

    yield put(partnerPageActions.partnerKeyRequestAction.success(response));
  } catch (err: any) {
    yield put(partnerPageActions.partnerKeyRequestAction.failure(err));
  }
}

function* getPartnerKeyById(
  action: ActionType<
    typeof partnerPageActions.partnerKeyByIdRequestAction.request
  >
) {
  try {
    const response = yield* getPartnerPageById(action.payload.id);

    yield putResolve(
      partnerPageActions.partnerPageByKeyAction.success(response)
    );
    const partnerPageAlooma = {
      companyUuid: response['credentials.company_uuid'],
      id: response.id,
      partner_key: response.partner,
      product: response.productType,
      version: response.version,
    } as PartnerPageAlooma;

    yield putResolve(
      partnerPageActions.partnerKeyByIdRequestAction.success([
        partnerPageAlooma,
      ])
    );
  } catch (err: any) {
    yield put(partnerPageActions.partnerKeyByIdRequestAction.failure(err));
  }
}

function* replacePartnerKeyImage(
  action: ActionType<
    typeof partnerPageActions.partnerKeyReplaceImageAction.request
  >
) {
  try {
    const updatedPartnerPage = yield call(async () =>
      client
        .post(`/partner_page/partner/${action.payload.key}/image`, {
          payload: action.payload.file,
        })
        .then((res) => res.data)
    );

    yield put(
      partnerPageActions.partnerPageByKeyAction.request({
        id: updatedPartnerPage.id,
        reloadPartnerPage: true,
      })
    );
  } catch (err: any) {
    yield put(partnerPageActions.partnerKeyReplaceImageAction.failure(err));
  }
}

function* updatePartnerKeyIntegrationStatus(
  action: ActionType<
    typeof partnerPageActions.partnerKeyUpdateIntegrationStatusAction.request
  >
) {
  try {
    const response = yield call(async () =>
      client
        .post(
          `/partner_page/partner/${action.payload.id}/integration-status/${action.payload.status}`,
          {}
        )
        .then((res) => res.data)
    );

    yield put(
      partnerPageActions.partnerKeyUpdateIntegrationStatusAction.success({
        resp: response,
        updatePartnerKeyId: action.payload.id,
      })
    );

    const redirectPath = action.payload.redirectPath;

    if (redirectPath) {
      Router.push(redirectPath);
    }

    const displaySuccess = action.payload.displaySuccess;

    if (displaySuccess) {
      Router.replace(
        {
          pathname: Router.pathname,
          query: {
            publishSuccess: true,
          },
        },
        Router.asPath
      );
    }
  } catch (err: any) {
    yield put(
      partnerPageActions.partnerKeyUpdateIntegrationStatusAction.failure(err)
    );
  }
}

function* partnerPageKeyValidation(
  action: ActionType<
    typeof partnerPageActions.partnerPageKeyValidationRequestAction.request
  >
) {
  try {
    const {
      payload: { partnerPageKey },
    } = action;

    const { data: valid }: AxiosResponse<boolean> = yield call(
      client.get,
      '/partner_page/key/validation',
      {
        params: {
          partnerPageKey,
        },
      }
    );

    yield putResolve(
      partnerPageActions.partnerPageKeyValidationRequestAction.success(valid)
    );
  } catch (err) {
    const error = getPortalError(err);

    yield put(
      partnerPageActions.partnerPageKeyValidationRequestAction.failure(error)
    );
  }
}

function* getAllWizardData(
  action: ActionType<typeof partnerPageActions.getAllWizardData.request>
) {
  const cookiePayload = { cookie: action.payload.cookie };

  yield all([
    put(
      partnerPageActions.partnerKeyByIdRequestAction.request({
        id: parseInt(action.payload.id),
      })
    ),
    put(saasAllRequestAction.request(cookiePayload)),
    put(subAccountActions.supplySubAccountRequestAction.request(cookiePayload)),
    put(
      complianceActions.myAccountComplianceRequestAction.request(cookiePayload)
    ),
    put(accountActions.accountRequestAction.request({})),
  ]);

  yield take([PartnerPageActionType.GET_BY_ID_SUCCESS]);
  yield putResolve(partnerPageActions.getAllWizardData.success());
}

function* getAllDetailsData(
  action: ActionType<typeof partnerPageActions.getAllDetailsData.request>
) {
  const { partnerPageId } = action.payload;
  const supplySubAccountUuid = yield select((state: ApplicationState) =>
    selectSupplySubAccountUuid(state, partnerPageId)
  );
  const supplyTemplateProductType = yield select((state: ApplicationState) =>
    selectSupplyTemplateProductType(state, partnerPageId)
  );
  const plan = yield select(selectCurrentSaasPlan);

  const isInMigration = yield select((state: ApplicationState) =>
    selectIsInMigration(state, partnerPageId)
  );

  yield putResolve(
    partnerPageActions.partnerKeyRequestAction.request({ getAllByKey: true })
  );
  yield putResolve(subAccountActions.supplySubAccountRequestAction.request({}));
  yield putResolve(accountActions.accountRequestAction.request({}));

  if (isInMigration) {
    yield put(
      subAccountActions.migratedLenderDemandSubAccountRequestAction.request(
        supplySubAccountUuid
      )
    );
  } else if (!isInMigration) {
    yield all([
      put(
        subAccountActions.lenderDemandSubAccountRequestAction.request({
          query: {
            productType: supplyTemplateProductType,
          },
        })
      ),
      findPremiumLendersAction.request({
        data: {
          productType: supplyTemplateProductType,
        },
      }),
    ]);
  }

  yield all([
    put(complianceActions.myAccountComplianceRequestAction.request({})),
    put(
      saasAllRequestAction.request({
        skipFee: true,
        skipTrial: true,
      })
    ),
    put(
      originatorActions.supplyTemplatesRequestAction.request({
        query: {
          productType: supplyTemplateProductType,
          tier: plan?.tier ? [plan?.tier] : undefined,
        },
      })
    ),
  ]);

  yield take([PartnerPageActionType.GET_BY_ID_SUCCESS]);
  yield putResolve(partnerPageActions.getAllDetailsData.success());
}

function* initSelfServiceIntegrationsPage() {
  const { showGettingStartedTutorial, showVideoTutorials } = yield select(
    viewContextSelector
  );

  yield put(
    partnerPageActions.partnerKeyRequestAction.request({ getAllByKey: true })
  );

  if (showGettingStartedTutorial && showVideoTutorials) {
    yield all([
      put(
        getTutorialVideos.request({
          type: TutorialVideoSeriesType.PARTNER_PAGE_VIDEOS,
        })
      ),
      put(
        getTutorialVideos.request({
          type: TutorialVideoSeriesType.EMBED_VIDEOS,
        })
      ),
    ]);
  }

  // We will freeze here untill the partnerPageAction emits success.
  yield take(getType(partnerPageActions.partnerKeyRequestAction.success));

  const hasPartnerPages = yield select(selectHasPartnerPages);

  if (hasPartnerPages) {
    yield all([
      put(accountActions.accountRequestAction.request({})),
      put(subAccountActions.supplySubAccountRequestAction.request({})),
      put(complianceActions.myAccountComplianceRequestAction.request({})),
    ]);
  }
}

function* watchInitSelfServiceIntegrationsPage() {
  yield takeEvery(
    PartnerPageActionType.INIT_SELF_SERVICE_INTEGRATIONS_PAGE,
    initSelfServiceIntegrationsPage
  );
}

function* watchGetAllDetailsData() {
  yield takeLatest(
    PartnerPageActionType.GET_ALL_DETAILS_DATA_REQUEST,
    getAllDetailsData
  );
}

function* watchGetAllWizardData() {
  yield takeLatest(
    PartnerPageActionType.GET_ALL_WIZARD_DATA_REQUEST,
    getAllWizardData
  );
}

function* watchCreatePartnerPage() {
  yield takeLatest(
    PartnerPageActionType.CREATE_REQUEST_CLIENT,
    createPartnerPage
  );
}

function* watchCreateFirstPartnerPageSelfService() {
  yield takeLatest(
    PartnerPageActionType.CREATE_PARTNER_PAGE_REQUEST,
    createFirstPartnerPageSelfService
  );
}

function* watchCreatePartnerPageSelfService() {
  yield takeLatest(
    PartnerPageActionType.CREATE_INTEGRATION_REQUEST,
    createPartnerPageSelfService
  );
}

function* watchGetByKey() {
  yield takeLatest(PartnerPageActionType.BY_KEY_REQUEST, getByKey);
}

function* watchPartnerPageUpdate() {
  yield takeLatest(
    PartnerPageActionType.UPDATE_REQUEST_CLIENT,
    updatePartnerPage
  );
}

function* watchDeletePartnerPage() {
  yield takeLatest(
    PartnerPageActionType.DELETE_REQUEST_CLIENT,
    deletePartnerPageById
  );
}

function* watchGetAllRequest() {
  yield takeLatest(PartnerPageActionType.GET_ALL_REQUEST, getPartnerKeys);
}

function* watchGetPartnerKeyById() {
  yield takeLatest(PartnerPageActionType.GET_BY_ID_REQUEST, getPartnerKeyById);
}

function* watchPartnerKeyReplaceImage() {
  yield takeLatest(
    PartnerPageActionType.REPLACE_IMAGE_REQUEST_CLIENT,
    replacePartnerKeyImage
  );
}

function* watchUpdatePartnerKeyIntegrationStatus() {
  yield takeLatest(
    PartnerPageActionType.UPDATE_INTEGRATION_STATUS_REQUEST_CLIENT,
    updatePartnerKeyIntegrationStatus
  );
}

function* watchPartnerPageKeyValidation() {
  yield takeLatest(
    PartnerPageActionType.PARTNER_PAGE_KEY_VALIDATION_REQUEST,
    partnerPageKeyValidation
  );
}

export function* partnerPageSaga() {
  yield all([
    fork(watchCreatePartnerPage),
    fork(watchCreateFirstPartnerPageSelfService),
    fork(watchCreatePartnerPageSelfService),
    fork(watchGetByKey),
    fork(watchPartnerPageUpdate),
    fork(watchDeletePartnerPage),
    fork(watchGetAllRequest),
    fork(watchGetPartnerKeyById),
    fork(watchPartnerKeyReplaceImage),
    fork(watchUpdatePartnerKeyIntegrationStatus),
    fork(watchPartnerPageKeyValidation),
    fork(watchGetAllWizardData),
    fork(watchGetAllDetailsData),
    fork(watchInitSelfServiceIntegrationsPage),
  ]);
}
