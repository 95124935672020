import * as React from 'react';
import { useSelector } from 'react-redux';

import { message as antMessage } from 'antd';
import Modal from 'antd/lib/modal';
import type { ModalFuncProps } from 'antd/lib/modal';
import classNames from 'classnames';

import type { OverlayLoaderType } from '@/store/message/types';

import {
  getMessageLoading,
  getMessageOverlay,
  getMessageText,
} from '../store/message/selectors';

let modal: undefined | { destroy: () => void };

const GlobalLoader: React.FC = () => {
  const overlay = useSelector(getMessageOverlay);
  const text = useSelector(getMessageText);
  const loading = useSelector(getMessageLoading);

  const [messageVisible, setMessageVisible] = React.useState(false);

  const hideMessage = () => {
    antMessage.destroy();
    setMessageVisible(false);
  };

  const generateModal = (overlay: OverlayLoaderType): ModalFuncProps => {
    const title = overlay.title || 'Please wait...';
    const className = classNames(
      'global-loader-overlay-modal',
      overlay.className
    );

    return {
      ...overlay,
      className,
      keyboard: false,
      okCancel: false,
      title,
    };
  };

  React.useEffect(() => {
    if (overlay) {
      hideMessage();
      modal = Modal.info(generateModal(overlay));
    } else {
      modal?.destroy();
    }
  }, [overlay]);

  React.useEffect(() => {
    if (messageVisible) {
      if (loading < 1) {
        hideMessage();

        return;
      }

      return;
    }

    if (loading > 0 && !overlay) {
      antMessage.loading(text, 1);
      setMessageVisible(true);
    }
  }, [loading]);

  return null;
};

export default GlobalLoader;
