import type { ActionType } from 'typesafe-actions';

import type * as actions from './actions';

export type DemandDataImportAction = ActionType<typeof actions>;

export enum DemandDataImportActionType {
  GET_ALL_DEMAND_DATA_IMPORT_SUMMARIES_FAILURE = '@@data-imports/GET_ALL_DEMAND_DATA_IMPORT_SUMMARIES_FAILURE',
  // all summaries
  GET_ALL_DEMAND_DATA_IMPORT_SUMMARIES_REQUEST = '@@data-imports/GET_ALL_DEMAND_DATA_IMPORT_SUMMARIES_REQUEST',
  GET_ALL_DEMAND_DATA_IMPORT_SUMMARIES_SUCCESS = '@@data-imports/GET_ALL_DEMAND_DATA_IMPORT_SUMMARIES_SUCCESS',
}
