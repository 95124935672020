import { useMemo } from 'react';
import type React from 'react';
import { useSelector } from 'react-redux';

import { groupBy } from 'lodash';

import type { PortalSubAccount } from '@portal/api-models';

import type { ApplicationState } from '@/store';

type UseSubAccounts = (options: {
  accountIds?: number[];
  accountUuids?: string[];
  demandOnly?: boolean;
  supplyOnly?: boolean;
}) => PortalSubAccount[];

/**
 * @param {React.Key[]} keys
 * @param {Record<React.Key, SubAccount[]>} map
 * @return {SubAccount[]}
 */
const findSubAccountsByGroupKeys = (
  keys: React.Key[],
  map: Record<React.Key, PortalSubAccount[]>
): PortalSubAccount[] => {
  return keys.reduce((accum: PortalSubAccount[], key) => {
    if (map[key]) {
      return accum.concat(map[key]);
    }

    return accum;
  }, []);
};

export const useSubAccounts: UseSubAccounts = ({
  supplyOnly,
  demandOnly,
  accountUuids,
  accountIds,
}) => {
  const subAccounts = useSelector<ApplicationState, PortalSubAccount[]>(
    ({ subAccounts: { supply, demand } }) =>
      (supplyOnly && supply) || (demandOnly && demand) || supply.concat(demand)
  );

  const subAccountsByAccountUuid = useMemo(
    () => accountUuids && groupBy(subAccounts, 'accountUuid'),
    [subAccounts]
  );

  const subAccountsByAccountId = useMemo(
    () => accountIds && groupBy(subAccounts, 'accountId'),
    [subAccounts]
  );

  if (accountUuids && subAccountsByAccountUuid) {
    return findSubAccountsByGroupKeys(accountUuids, subAccountsByAccountUuid);
  }

  if (accountIds && subAccountsByAccountId) {
    return findSubAccountsByGroupKeys(accountIds, subAccountsByAccountId);
  }

  return subAccounts;
};
