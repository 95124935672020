import * as React from 'react';

import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

import { getFormValues, resolvePredicate } from '..';
import type { IPortalFormContext } from '..';
import { withPortalForm } from '../hoc';
import type { WithPortalFormProps } from '../hoc';
import { PortalFormFieldType } from '../types';
import type {
  ErrorMap,
  FieldListErrorCallback,
  OnChange,
  PortalFormField,
} from '../types';

import PortalFormFieldItem from './form-field-item';
import type { Layout } from './form-field-item';

export interface PortalFormFieldListProvidedProps<T extends object> {
  className?: string;
  defaultValues?: T;
  fields: PortalFormField<T>[];
  form?: IPortalFormContext<T>;
  inlineLabels?: boolean;
  layout?: Layout;
  onChange?: OnChange<T>;
  onError?: FieldListErrorCallback;
  values?: T;
}

type PortalFormFieldListProps<T extends {}> =
  PortalFormFieldListProvidedProps<T> & WithPortalFormProps<T>;

interface PortalFormFieldListState<T extends object> {
  errorCount: number;
  errorMap: ErrorMap;
  fields: PortalFormField<T>[];
  values: T;
}

class PortalFormFieldListUnwrapped<T extends object> extends React.Component<
  PortalFormFieldListProps<T>,
  PortalFormFieldListState<T>
> {
  private prevRowSize: number = 12;
  private nextRowSize: number | null = null;
  private nextColSize: number | null = null;

  static getFieldKey<T extends object>(field: PortalFormField<T>): string {
    return field.type === PortalFormFieldType.Element
      ? field.key
      : field.fieldKey || field.name;
  }

  constructor(props: PortalFormFieldListProps<T>) {
    super(props);

    const { form, fields, values, defaultValues } = props;

    if (form) {
      if (defaultValues) {
        form.setDefaultValues(defaultValues);
      }

      form.setFields(fields);
      form.setValues(values ? values : getFormValues<T>(defaultValues, fields));

      form.onChange(this.handleOnChange);
      form.onError(this.handleOnError);
    }
  }

  public componentDidUpdate(prevProps: PortalFormFieldListProps<T>) {
    const { form, fields, values } = this.props;

    if (values && !isEqual(values, prevProps.values)) {
      form.setValues(values);
    }

    if (fields && !isEqual(fields, prevProps.fields)) {
      form.setFields(fields);
    }
  }

  private get visibleFields(): PortalFormField<T>[] {
    const { fields, values } = this.props.form;

    return fields.filter(({ predicate }) => {
      if (predicate !== undefined) {
        return resolvePredicate(predicate, values);
      }

      return true;
    });
  }

  public get fieldRows(): JSX.Element[] {
    const { layout } = this.props;

    let currentRowIndex = 0;

    this.prevRowSize = 12;
    this.nextRowSize = null;
    this.nextColSize = null;

    const rows: Array<React.ReactNode[]> = this.visibleFields.reduce(
      (accum, field, index) => {
        const key = PortalFormFieldListUnwrapped.getFieldKey(field);

        if (this.isFirstInRow(index)) {
          currentRowIndex++;
        }

        if (!accum[currentRowIndex]) {
          accum[currentRowIndex] = [];
        }

        accum[currentRowIndex].push(
          <PortalFormFieldItem
            key={key}
            field={
              {
                ...field,
                inlineLabel: field.inlineLabel ?? this.props.inlineLabels,
              } as PortalFormField<T>
            }
            layout={layout}
          />
        );

        return accum;
      },
      [] as Array<React.ReactNode[]>
    );

    return rows.map((fields, index) => (
      <div key={index} className="portal-form-row">
        {fields}
      </div>
    ));
  }

  private calculateColSize(index: number): number {
    return this.visibleFields[index]?.size ?? 12;
  }

  private calculateRowSize(prevRowSize: number, colSize: number): number {
    return prevRowSize < 12 ? prevRowSize + colSize : colSize;
  }

  private isFirstInRow(index: number): boolean {
    const colSize = this.nextColSize ?? this.calculateColSize(index);
    const rowSize =
      this.nextRowSize ?? this.calculateRowSize(this.prevRowSize, colSize);

    const nextColSize = this.calculateColSize(index + 1);
    const nextRowSize = this.calculateRowSize(rowSize, nextColSize);

    const isFirstInRow: boolean = colSize === 12 || this.prevRowSize >= 12;

    this.prevRowSize = nextRowSize > 12 ? nextRowSize : rowSize;
    this.nextColSize = nextColSize;
    this.nextRowSize = nextRowSize;

    return isFirstInRow;
  }

  private handleOnChange = (payload: any) => {
    const { onChange } = this.props;

    onChange?.(payload);
  };

  private handleOnError = (payload: any) => {
    const { onError } = this.props;

    onError?.(payload);
  };

  public componentWillUnmount() {
    const { form } = this.props;

    if (form) {
      form.removeOnChange(this.handleOnChange);
      form.removeOnError(this.handleOnError);
    }
  }

  public validate = async () => this.props.form!.validate();

  public getErrorCount = async () => this.props.form!.getErrorCount();

  public render() {
    const { className } = this.props;

    return (
      <div className={classNames('portal-form-field-list', className)}>
        {this.fieldRows}
      </div>
    );
  }
}

/**
 * @deprecated
 * Use react-hook-forms instead for forms.
 */
const PortalFormFieldList = withPortalForm(
  PortalFormFieldListUnwrapped as any
) as unknown as new <T extends object>(
  props: PortalFormFieldListProps<T>
) => React.Component<PortalFormFieldListProvidedProps<T>>;

export default PortalFormFieldList;
