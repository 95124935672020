export interface NewFeatureOptions {
  /** The specific date time, in milliseconds, in which a feature is no longer new. */
  dateTimeUntilExpires?: number;
  /* The amount of time, in milliseconds, until a feature is no longer new. */
  timeUntilExpires?: number;
  /** The amount of views until a feature is no longer new. */
  viewsUntilExpires?: number;
}

type NewFeatureMetadata = NewFeatureOptions & {
  createdAt: string;
  views: number;
};

class NewFeatureMapStatic {
  static STORAGE_KEY = 'new_features';

  /**
   * @static
   * @return {*}  {Record<string, NewFeatureMetadata>}
   * @memberof NewFeatureMapStatic
   */
  static getMap(): Record<string, NewFeatureMetadata> {
    if (typeof window !== 'undefined') {
      const json = window.localStorage.getItem(NewFeatureMapStatic.STORAGE_KEY);

      if (json) {
        return JSON.parse(json);
      }
    }

    return {};
  }

  /**
   * @static
   * @param {Record<string, NewFeatureMetadata>} map
   * @memberof NewFeatureMapStatic
   */
  static setMap(map: Record<string, NewFeatureMetadata>): void {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(
        NewFeatureMapStatic.STORAGE_KEY,
        JSON.stringify(map)
      );
    }
  }

  /**
   * @static
   * @param {string} name
   * @return {*}  {(NewFeatureMetadata | undefined)}
   * @memberof NewFeatureMapStatic
   */
  static getEntry(name: string): NewFeatureMetadata | undefined {
    return NewFeatureMapStatic.getMap()?.[name];
  }

  /**
   * @static
   * @param {string} name
   * @param {NewFeatureMetadata} data
   * @memberof NewFeatureMapStatic
   */
  static setEntry(name: string, data: NewFeatureMetadata): void {
    NewFeatureMapStatic.setMap({
      ...NewFeatureMapStatic.getMap(),
      [name]: data,
    });
  }

  /**
   *
   * @static
   * @param {string} name
   * @memberof NewFeatureMapStatic
   */
  static deleteEntry(name: string): void {
    const map = NewFeatureMapStatic.getMap();

    delete map?.[name];
    NewFeatureMapStatic.setMap(map);
  }
}

export class NewFeatureStatic {
  /**
   * @static
   * @param {string | string[]} nameOrNames
   * @param {NewFeatureOptions} options
   * @memberof NewFeatureStatic
   */
  public static add(
    nameOrNames: string | string[],
    options: NewFeatureOptions
  ): void {
    const names = Array.isArray(nameOrNames) ? nameOrNames : [nameOrNames];

    names.forEach((name) => {
      NewFeatureMapStatic.setEntry(name, {
        ...options,
        createdAt: new Date().toISOString(),
        views: 0,
      });
    });
  }

  /**
   * @static
   * @param {string} name
   * @return {*}  {(NewFeatureMetadata | undefined)}
   * @memberof NewFeatureStatic
   */
  public static get(name: string): NewFeatureMetadata | undefined {
    return NewFeatureMapStatic.getEntry(name);
  }

  /**
   * @static
   * @param {string} name
   * @memberof NewFeatureStatic
   */
  public static remove(name: string): void {
    NewFeatureMapStatic.deleteEntry(name);
  }

  /**
   * @static
   * @param {string} name
   * @memberof NewFeatureStatic
   */
  public static incrementView(name: string): void {
    const entry = NewFeatureMapStatic.getEntry(name);

    if (entry) {
      NewFeatureMapStatic.setEntry(name, {
        ...entry,
        views: entry.views + 1,
      });
    }
  }

  /**
   * @static
   * @param {string} name
   * @return {*}  {boolean}
   * @memberof NewFeatureStatic
   */
  public static isExpired(name: string): boolean {
    const entry = NewFeatureMapStatic.getEntry(name);

    if (entry) {
      const {
        views,
        createdAt,
        viewsUntilExpires,
        timeUntilExpires,
        dateTimeUntilExpires,
      } = entry;

      const todayDate = new Date();
      const createdAtDate = new Date(createdAt);

      if (
        (viewsUntilExpires && views >= viewsUntilExpires) ||
        (timeUntilExpires &&
          todayDate.getTime() - createdAtDate.getTime() >= timeUntilExpires) ||
        (dateTimeUntilExpires && todayDate.getTime() >= dateTimeUntilExpires)
      ) {
        return true;
      }
    }

    return false;
  }
}
