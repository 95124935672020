import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Layout } from 'antd';

import {
  BasicNav,
  DemandOfferManagementItemGroup,
  SideNav as NewSideNav,
  SideNavContext,
} from '@portal/ui-lib';

import { HelpCenterSection } from '@/components/side-nav/help-center-section';
import { toggleSidenav } from '@/store/interface/actions';

import { Router } from '../../routes';

import { ExternalUserSideNav } from './external';
import ExternalSelfServiceUserSideNav from './external-self-service';
import { InternalUserSideNav } from './internal';
import CrmUserMenu from './menu-crm';
import { sideNavStateSelector } from './selectors';

const { Sider } = Layout;

interface SideNavProps {
  showBenchmarkReportsMenuItem: boolean;
  showCrmUserSideNav: boolean;
  showDemandUserSideNav: boolean;
  showExternalUserSideNav: boolean;
  showInternalUserSideNav: boolean;
  showMCIReportsMenuItem: boolean;
  showSelfServiceUserSideNav: boolean;
}

const SideNav: React.FC<SideNavProps> = ({
  showBenchmarkReportsMenuItem,
  showCrmUserSideNav,
  showDemandUserSideNav,
  showExternalUserSideNav,
  showInternalUserSideNav,
  showSelfServiceUserSideNav,
  showMCIReportsMenuItem,
}) => {
  const dispatch = useDispatch();

  const { accountUuid, fullscreenMode, sidenavCollapsed } =
    useSelector(sideNavStateSelector);

  const handleToggleSideNav = () => dispatch(toggleSidenav());

  const handleRouteChange = (route: string) => {
    Router.pushRoute(route);
  };

  const breakpointCallback = useCallback((broken: any) => {
    if (broken) {
      handleToggleSideNav();
    }
  }, []);

  const sideNavContext = {
    isCollapsed: sidenavCollapsed,
    onRouteChange: handleRouteChange,
  };

  if (showDemandUserSideNav) {
    return (
      <NewSideNav
        collapsed={sidenavCollapsed}
        defaultOpenKeys={['financial-services']}
      >
        <BasicNav accountUuid={accountUuid} onRouteChange={handleRouteChange} />
        <DemandOfferManagementItemGroup
          showBenchmarkReportsMenuItem={showBenchmarkReportsMenuItem}
          showMCIReportsMenuItem={showMCIReportsMenuItem}
          onRouteChange={handleRouteChange}
        />
        <HelpCenterSection />
      </NewSideNav>
    );
  }

  return (
    <Sider
      collapsible
      breakpoint="md"
      className={fullscreenMode ? 'portal-sider-hidden' : 'portal-sider'}
      collapsed={sidenavCollapsed}
      collapsedWidth={fullscreenMode ? 0 : 90}
      id="sidenav"
      trigger={null}
      width={240}
      onBreakpoint={breakpointCallback}
      onCollapse={handleToggleSideNav}
    >
      <SideNavContext.Provider value={sideNavContext}>
        {showInternalUserSideNav && <InternalUserSideNav />}

        {showExternalUserSideNav && (
          <ExternalUserSideNav
            accountUuid={accountUuid}
            sidenavCollapsed={sidenavCollapsed}
          />
        )}

        {showCrmUserSideNav && <CrmUserMenu />}

        {showSelfServiceUserSideNav && <ExternalSelfServiceUserSideNav />}
      </SideNavContext.Provider>
    </Sider>
  );
};

export default SideNav;
