import { produce } from 'immer';
import { createReducer } from 'typesafe-actions';

import { nextHydrate } from '@/store/actions';
import type { HydrateAction } from '@/store/actions';
import { togglePremiumLenderCreateAction } from '@/store/premium-lenders';

import { replaceByIdFromArray } from '../utils';

import {
  createPremiumLenderAction,
  deletePremiumLenderAction,
  findPremiumLendersAction,
  getPremiumLenderByIdAction,
  togglePremiumLenderUpdateAction,
  updatePremiumLenderAction,
} from './actions';
import type { PremiumLendersAction, PremiumLendersState } from './types';

export const initialState: PremiumLendersState = {
  createFormVisible: false,
  rows: [],
  updateFormVisible: null,
};

export const premiumLendersReducer = createReducer<
  PremiumLendersState,
  PremiumLendersAction | HydrateAction
>(initialState)
  .handleAction(createPremiumLenderAction.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.rows.push(payload);
    })
  )
  .handleAction(updatePremiumLenderAction.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.rows = replaceByIdFromArray(state.rows, [payload]);
    })
  )
  .handleAction(
    deletePremiumLenderAction.success,
    (state, { payload: { id } }) =>
      produce(state, (draft) => {
        draft.rows = state.rows.filter((item) => item.id !== id);
      })
  )
  .handleAction(findPremiumLendersAction.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.rows = payload;
    })
  )
  .handleAction(getPremiumLenderByIdAction.success, (state, { payload }) =>
    produce(state, (draft) => {
      const exists = draft.rows.map((item) => item.id).includes(payload.id);

      if (exists) {
        draft.rows = replaceByIdFromArray(state.rows, [payload]);
      } else {
        draft.rows = draft.rows.concat(payload);
      }
    })
  )
  .handleAction(togglePremiumLenderCreateAction, (state) =>
    produce(state, (draft) => {
      draft.createFormVisible = !state.createFormVisible;
    })
  )
  .handleAction(togglePremiumLenderUpdateAction, (state, { payload }) =>
    produce(state, (draft) => {
      if (payload) {
        draft.updateFormVisible = payload;
      } else {
        draft.updateFormVisible = null;
      }
    })
  )
  .handleAction(
    nextHydrate,
    (
      state,
      {
        payload: {
          premiumLenders: { rows },
        },
      }
    ) =>
      produce(state, (draft) => {
        draft.rows = rows;
      })
  );
