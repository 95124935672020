import * as React from 'react';
import { useState } from 'react';

import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';

interface AgreementModalProps extends React.PropsWithChildren<{}> {
  hideCancel?: boolean;
  onAgree?: () => void;
  onCancel?: () => void;
  title: string;
  visible: boolean;
}

const AgreementModal: React.FC<AgreementModalProps> = ({
  visible,
  title,
  children,
  onCancel,
  onAgree,
  hideCancel = false,
}) => {
  const [isAgreeEnabled, setIsAgreeEnabled] = useState(false);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;

    // Math.abs() is used for cases when user is zoomed in/out
    const bottom = Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1;

    bottom ? setIsAgreeEnabled(true) : setIsAgreeEnabled(false);
  };

  const controls = [
    <Button
      key="agree"
      disabled={!isAgreeEnabled}
      type="primary"
      onClick={onAgree}
    >
      Agree &amp; Continue
    </Button>,
  ];

  if (!hideCancel) {
    controls.unshift(
      <Button key="cancel" onClick={onCancel}>
        Cancel
      </Button>
    );
  }

  return (
    <Modal
      className="agreement-modal"
      closable={false}
      footer={controls}
      visible={visible}
      width={1175}
    >
      <h1 className="agreement-modal-title">{title}</h1>
      <div className="agreement-modal-content" onScroll={handleScroll}>
        {children}
      </div>
    </Modal>
  );
};

export default AgreementModal;
