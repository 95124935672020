import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  faCarSide,
  faClipboardCheck,
  faCreditCard,
  faGraduationCap,
  faPiggyBank,
  faSquareUser,
  faTags,
  faUmbrella,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { Menu } from 'antd';

import { selectPermissions } from '@portal/store/dist/user/selectors';
import { SidebarIcon } from '@portal/ui-lib';

import {
  AccountMenuItem,
  HomeMenuItem,
  InvoicesMenuItem,
  UsersMenuItem,
} from '@/components/side-nav/menu-items/self-service';
import {
  IntegrationsMenuItem,
  PerformanceSummaryMenuItem,
} from '@/components/side-nav/menu-items/self-service-channels';
import PortalItemGroup from '@/components/side-nav/portal-item-group';
import PortalMainMenu from '@/components/side-nav/portal-main-menu';
import { RouterAwareMenuItem } from '@/components/side-nav/router-aware-menu-item';
import { selfServiceDataSelector } from '@/resources/self-service/selectors';

const { SubMenu } = Menu;

const ExternalSelfServiceUserSideNav: React.FC = () => {
  const {
    accountDisabled,
    autoLoanRefDisabled,
    complianceDisabled,
    creditCardsDisabled,
    customOffersDisabled,
    integrationsDisabled,
    invoicesDisabled,
    lifeInsuranceDisabled,
    performanceSummaryDisabled,
    personalLoanDisabled,
    savingsDisabled,
    studentLoanRefDisabled,
    usersDisabled,
  } = useSelector(selfServiceDataSelector);

  const { showComplianceNavbarItem } = useSelector(selectPermissions);

  const ChannelsSection: React.FC = (props) => (
    <PortalItemGroup className="self-service-nav" title="Channels" {...props}>
      <IntegrationsMenuItem
        key="menu-item-channels-integrations"
        disabled={integrationsDisabled}
      />
      {showComplianceNavbarItem && (
        <RouterAwareMenuItem
          key="menu-item-channels-compliance"
          disabled={complianceDisabled}
          href="/compliance"
          icon={<SidebarIcon icon={faClipboardCheck} />}
          showDisabledIcon={complianceDisabled}
          text="Compliance"
        />
      )}
      <PerformanceSummaryMenuItem
        key="menu-item-channels-performance-summary"
        disabled={performanceSummaryDisabled}
      />
    </PortalItemGroup>
  );

  return (
    <PortalMainMenu>
      <HomeMenuItem />
      <AccountMenuItem disabled={accountDisabled} />
      <UsersMenuItem disabled={usersDisabled} />
      <InvoicesMenuItem disabled={invoicesDisabled} />

      <ChannelsSection />

      {/* BEGIN CONTROL CENTER LITE (SUPPLY) OFFER MANAGEMENT SUBNAV */}
      <PortalItemGroup className="self-service-nav" title="Offer Management">
        <SubMenu
          key="menu-item-offer-management-product-offers"
          className="nav-menu-submenu"
          disabled={
            personalLoanDisabled &&
            lifeInsuranceDisabled &&
            creditCardsDisabled &&
            savingsDisabled &&
            studentLoanRefDisabled &&
            autoLoanRefDisabled
          }
          title={
            <span>
              <SidebarIcon icon={faSquareUser} />
              <span>Financial Services</span>
            </span>
          }
        >
          <RouterAwareMenuItem
            key="menu-item-offer-management-product-offers-personal-loans"
            disabled={personalLoanDisabled}
            href="/financial-services/personal-loans"
            icon={<SidebarIcon icon={faUser} />}
            showDisabledIcon={personalLoanDisabled}
            text="Personal Loans"
          />
          <RouterAwareMenuItem
            key="menu-item-offer-management-product-offers-student-loan-ref"
            disabled={autoLoanRefDisabled}
            href="/financial-services/student-loan-refi"
            icon={<SidebarIcon icon={faGraduationCap} />}
            showDisabledIcon={autoLoanRefDisabled}
            text="Student Loan Refi"
          />
          <RouterAwareMenuItem
            key="menu-item-offer-management-product-offers-auto-loan-ref"
            disabled={studentLoanRefDisabled}
            href="/financial-services/auto-loan-refi"
            icon={<SidebarIcon icon={faCarSide} />}
            showDisabledIcon={studentLoanRefDisabled}
            text="Auto Loan Refi"
          />
          <RouterAwareMenuItem
            key="menu-item-offer-management-product-offers-savings"
            disabled={savingsDisabled}
            href="/financial-services/savings"
            icon={<SidebarIcon icon={faPiggyBank} />}
            showDisabledIcon={savingsDisabled}
            text="Savings"
          />
          <RouterAwareMenuItem
            key="menu-item-offer-management-product-offers-life-insurance"
            disabled={lifeInsuranceDisabled}
            href="/financial-services/life-insurance"
            icon={<SidebarIcon icon={faUmbrella} />}
            showDisabledIcon={lifeInsuranceDisabled}
            text="Life Insurance"
          />
          <RouterAwareMenuItem
            key="menu-item-offer-management-product-offers-credit-cards"
            disabled={creditCardsDisabled}
            href="/financial-services/credit-cards"
            icon={<SidebarIcon icon={faCreditCard} />}
            showDisabledIcon={creditCardsDisabled}
            text="Credit Cards"
          />
        </SubMenu>
        <RouterAwareMenuItem
          key="menu-item-custom-offers"
          disabled={customOffersDisabled}
          href="/financial-services/custom-offers"
          icon={<SidebarIcon icon={faTags} />}
          showDisabledIcon={customOffersDisabled}
          text="Custom Offers"
        />
      </PortalItemGroup>
      {/* END CONTROL CENTER LITE (SUPPLY) OFFER MANAGEMENT SUBNAV */}
    </PortalMainMenu>
  );
};

export default ExternalSelfServiceUserSideNav;
