"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteBulkDemandSubAccountInventoryCap = exports.updateDemandSubAccountInventoryCap = exports.createBulkDemandSubAccountInventoryCap = exports.getDemandSubAccountInventoryCaps = void 0;
var lodash_1 = require("lodash");
var client_1 = require("../client");
var baseURL = '/inventory/demandSubAccountInventoryCaps';
var getDemandSubAccountInventoryCaps = function (params) {
    return client_1.client
        .get(baseURL, { params: params })
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.getDemandSubAccountInventoryCaps = getDemandSubAccountInventoryCaps;
var createBulkDemandSubAccountInventoryCap = function (body) { return client_1.client.post("".concat(baseURL, "/bulk"), body).then(function (_a) {
    var data = _a.data;
    return data;
}); };
exports.createBulkDemandSubAccountInventoryCap = createBulkDemandSubAccountInventoryCap;
var updateDemandSubAccountInventoryCap = function (body) {
    return client_1.client
        .put("".concat(baseURL, "/").concat(body.id), (0, lodash_1.omit)(body, 'id'))
        .then(function (_a) {
        var data = _a.data;
        return data;
    });
};
exports.updateDemandSubAccountInventoryCap = updateDemandSubAccountInventoryCap;
var deleteBulkDemandSubAccountInventoryCap = function (params) { return client_1.client.delete("".concat(baseURL, "/bulk"), { params: params }).then(function (_a) {
    var data = _a.data;
    return data;
}); };
exports.deleteBulkDemandSubAccountInventoryCap = deleteBulkDemandSubAccountInventoryCap;
