import * as React from 'react';

import type { ColumnProps } from 'antd/lib/table/Column';
import { get } from 'lodash';
import type { PropertyPath } from 'lodash';
import startCase from 'lodash/startCase';

import { StatusDot, StatusToIntent } from '@portal/ui-lib';

import { dateRangeFilterDropdown } from '@/components/table/date-range-filter-dropdown';
import {
  renderBoolean,
  renderCurrency,
  renderDate,
  renderLink,
  renderNumericRange,
  renderPercent,
  renderTags,
} from '@/components/table/lib/column-renderers';
import type {
  RenderBooleanOptions,
  RenderDateOptions,
  RenderLinkOptions,
} from '@/components/table/lib/column-renderers/interface';
import { UserAvatar } from '@/components/user-avatar';

import { filterIcon, textFilterDropdown } from './text-filter-dropdown';
import {
  mapFilters,
  mapNumberFilters,
  onFilterForKey,
  onFilterForTimestampRange,
  sorterForKey,
  sorterForNumericKey,
} from './util-methods';

export enum ColumnType {
  Date,
  Dropdown,
  Link,
  Searchable,
  Boolean,
}

type BaseColumnProps<T extends {}> = {
  filterKey?: PropertyPath;
  hidden?: boolean;
  key: PropertyPath;
  sorterKey?: PropertyPath;
  title?: string;
} & Omit<ColumnProps<T>, 'title' | 'key'>;

type DropdownColumnProps<T extends {}> = {
  rows: T[];
};

type SearchableColumnProps = {
  exact?: boolean;
  searchText?: string;
};
type LinkColumnProps = SearchableColumnProps & RenderLinkOptions;

type BooleanColumnProps = RenderBooleanOptions;

export type ColumnCreatorProps<
  T extends {},
  C extends ColumnType | undefined = undefined
> = BaseColumnProps<T> &
  (C extends ColumnType.Date ? RenderDateOptions : {}) &
  (C extends ColumnType.Dropdown ? DropdownColumnProps<T> : {}) &
  (C extends ColumnType.Link ? LinkColumnProps : {}) &
  (C extends ColumnType.Searchable ? SearchableColumnProps : {}) &
  (C extends ColumnType.Boolean ? BooleanColumnProps : {});

type ColumnCreator<C extends ColumnType | undefined = undefined> = <
  T extends {} = {}
>(
  props: ColumnCreatorProps<T, C>
) => ColumnProps<T>;

const createBaseColumn: ColumnCreator = ({ key, title, hidden, ...props }) =>
  !hidden
    ? {
        dataIndex: key as string,
        key: key as string,
        title,
        ...props,
      }
    : {};

export const createNumericColumn: ColumnCreator<ColumnType.Searchable> = ({
  key,
  sorterKey = key,
  filterKey = key,
  searchText = 'Search...',
  exact = true,
  ...props
}) =>
  createBaseColumn({
    filterDropdown: textFilterDropdown(searchText),
    filterIcon,
    key,
    onFilter: onFilterForKey(filterKey, exact),
    sorter: sorterForNumericKey(sorterKey),
    ...props,
  });

export const createNumericRangeColumn: ColumnCreator<ColumnType.Searchable> = (
  props
) =>
  createNumericColumn({
    render: renderNumericRange,
    ...props,
  });

export const createAmountColumn: ColumnCreator<ColumnType.Searchable> = (
  props
) =>
  createNumericColumn({
    render: renderCurrency,
    ...props,
  });

export const createPercentColumn: ColumnCreator<ColumnType.Searchable> = (
  props
) =>
  createNumericColumn({
    render: renderPercent,
    ...props,
  });

export const createBooleanColumn: ColumnCreator<ColumnType.Boolean> = ({
  key,
  filterKey = key,
  sorterKey = key,
  text = { false: 'False', true: 'True' },
  ...props
}) =>
  createBaseColumn({
    filters: [
      { text: text.true, value: true },
      { text: text.false, value: false },
    ],
    key,
    onFilter: onFilterForKey(filterKey),
    render: renderBoolean({ text }),
    sorter: sorterForKey(sorterKey),
    ...props,
  });

export const createStringColumn: ColumnCreator<ColumnType.Searchable> = ({
  key,
  sorterKey = key,
  filterKey = key,
  searchText = 'Search...',
  exact = false,
  ...props
}) =>
  createBaseColumn({
    filterDropdown: textFilterDropdown(searchText),
    filterIcon,
    key,
    onFilter: onFilterForKey(filterKey, exact),
    sorter: sorterForKey(sorterKey),
    ...props,
  });

export const createDateColumn: ColumnCreator<ColumnType.Date> = ({
  key,
  sorterKey = key,
  filterKey = key,
  format,
  ...props
}) =>
  createBaseColumn({
    filterDropdown: dateRangeFilterDropdown,
    key,
    onFilter: onFilterForTimestampRange(filterKey),
    render: renderDate({ format }),
    sorter: sorterForKey(sorterKey),
    ...props,
  });

export const createDateRangeColumn: ColumnCreator<ColumnType.Date> = ({
  format,
  ...props
}) =>
  createDateColumn({
    render: renderDate({ format }),
    ...props,
  });

export const createDropdownColumn: ColumnCreator<ColumnType.Dropdown> = ({
  key,
  sorterKey = key,
  filterKey = key,
  rows,
  ...props
}) =>
  createBaseColumn({
    filters: mapFilters(rows, filterKey),
    key,
    onFilter: onFilterForKey(filterKey),
    render: (value) => startCase(value),
    sorter: sorterForKey(sorterKey),
    ...props,
  });

export const createIdColumn: ColumnCreator<ColumnType.Searchable> = ({
  title = 'ID',
  searchText = 'Search by ID',
  ...props
}) =>
  createNumericColumn({
    searchText,
    title,
    ...props,
  });

export const createUserColumn: ColumnCreator<ColumnType.Dropdown> = (props) =>
  createDropdownColumn({
    render: (_value, record) => (
      <UserAvatar withTooltip name={get(record, props.key)} />
    ),
    ...props,
  });

export const createActionsColumn = ({ title = 'Actions', ...props }) => ({
  key: 'actions',
  title,
  ...props,
});

export const createStatusColumn: ColumnCreator<ColumnType.Dropdown> = (props) =>
  createDropdownColumn({
    render: (status: string) => {
      return (
        <StatusDot
          className={props.className}
          status={StatusToIntent?.[status.toLowerCase()]}
        >
          {startCase(status)}
        </StatusDot>
      );
    },
    ...props,
  });

export const createTagColumn: ColumnCreator<ColumnType.Dropdown> = (props) =>
  createDropdownColumn({
    render: renderTags,
    ...props,
  });

export const createLinkColumn: ColumnCreator<ColumnType.Link> = ({
  baseUrl,
  isExternalUrl,
  urlKey,
  key,
  urlSuffix,
  ...props
}) =>
  createStringColumn({
    key,
    render: renderLink({ baseUrl, isExternalUrl, key, urlKey, urlSuffix }),
    ...props,
  });

export const createCountColumn: ColumnCreator<ColumnType.Dropdown> = ({
  key,
  sorterKey = key,
  filterKey = key,
  rows,
  ...props
}) =>
  createBaseColumn({
    filters: mapNumberFilters(rows, filterKey),
    key,
    onFilter: onFilterForKey(filterKey),
    sorter: sorterForNumericKey(sorterKey),
    ...props,
  });
