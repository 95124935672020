import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'antd';

import type { IntegrationStatus, OnboardingStatus } from '@portal/api-models';

import {
  formatActionModalData,
  formatCallback,
} from '@/resources/self-service/home/action-modal-utils';
import { selectIsPaid } from '@/resources/self-service/selects';
import type { IntegrationCategory } from '@/resources/sub-accounts-shared/utils';
import { submitForReviewAction } from '@/store/compliance/actions';
import { partnerKeyUpdateIntegrationStatusAction } from '@/store/partner-page/actions';
import { addParamToQuery } from '@/utils/router';

export interface ActionModalProps {
  closeCallback(): void;
  id?: string;
  integrationType?: IntegrationCategory;
  onboardingStatus?: OnboardingStatus;
  opened: boolean;
  partnerKey?: string;
  subAccountUuid?: string;
}

const ActionModal: React.FC<ActionModalProps> = ({
  closeCallback,
  id,
  integrationType,
  onboardingStatus,
  opened,
  partnerKey,
  subAccountUuid,
}) => {
  const dispatch = useDispatch();
  const submitForReview = (subAccountUuid: string, integrationId: string) => {
    dispatch(
      submitForReviewAction.request({
        integrationId,
        subAccountUuid,
      })
    );
  };
  const updateIntegrationStatus = (
    id: string,
    key: string,
    status: IntegrationStatus
  ) => {
    dispatch(
      partnerKeyUpdateIntegrationStatusAction.request({
        id,
        key,
        redirectPath:
          window.location.pathname +
          addParamToQuery(window.location.search, 'publishSuccess', true),
        status,
      })
    );
  };

  const isPaid = useSelector(selectIsPaid);
  const {
    title,
    body,
    buttons: { ok, cancel },
  } = formatActionModalData(isPaid, onboardingStatus, integrationType);

  const callback = formatCallback(
    submitForReview,
    updateIntegrationStatus,
    closeCallback,
    id!,
    partnerKey!,
    subAccountUuid!,
    onboardingStatus
  );

  return (
    <Modal
      cancelText={cancel}
      className="integration-item-action-modal"
      okText={ok}
      title={title}
      visible={opened}
      width={600}
      onCancel={closeCallback}
      onOk={callback}
    >
      {body}
    </Modal>
  );
};

export default ActionModal;
