import type { ReactNode } from 'react';
import * as React from 'react';

import type { DatumValue } from '@nivo/line';

interface ChartTooltipProps {
  label: DatumValue;
  legend?: ReactNode | string;
  value: DatumValue | ReactNode;
}

const ChartTooltip: React.FC<ChartTooltipProps> = ({
  label,
  value,
  legend,
}) => (
  <div className="chart-tooltip">
    <div className="chart-tooltip-label">{label}</div>
    <div className="chart-tooltip-value">
      <div className="chart-tooltip-value-legend">{legend}</div>
      <span className="chart-tooltip-value-text">{value}</span>
    </div>
  </div>
);

export default ChartTooltip;
