"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.leadSummariesUtilStateChangeAction = exports.leadsUtilStateChangeAction = exports.getLeadSummaryByUuidAction = exports.getLeadSummariesAction = exports.getLeadByUuidAction = exports.getLeadsAction = void 0;
var typesafe_actions_1 = require("typesafe-actions");
var actionTypes_1 = require("./actionTypes");
exports.getLeadsAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.LeadActionType.GET_LEADS_REQUEST, actionTypes_1.LeadActionType.GET_LEADS_SUCCESS, actionTypes_1.LeadActionType.GET_LEADS_FAILURE)();
exports.getLeadByUuidAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.LeadActionType.GET_LEAD_BY_UUID_REQUEST, actionTypes_1.LeadActionType.GET_LEAD_BY_UUID_SUCCESS, actionTypes_1.LeadActionType.GET_LEAD_BY_UUID_FAILURE)();
exports.getLeadSummariesAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.LeadActionType.GET_LEAD_SUMMARIES_REQUEST, actionTypes_1.LeadActionType.GET_LEAD_SUMMARIES_SUCCESS, actionTypes_1.LeadActionType.GET_LEAD_SUMMARIES_FAILURE)();
exports.getLeadSummaryByUuidAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.LeadActionType.GET_LAED_SUMMARY_BY_UUID_REQUEST, actionTypes_1.LeadActionType.GET_LAED_SUMMARY_BY_UUID_SUCCESS, actionTypes_1.LeadActionType.GET_LAED_SUMMARY_BY_UUID_FAILURE)();
exports.leadsUtilStateChangeAction = (0, typesafe_actions_1.createAction)(actionTypes_1.LeadActionType.LEADS_UTIL_STATE_CHANGE)();
exports.leadSummariesUtilStateChangeAction = (0, typesafe_actions_1.createAction)(actionTypes_1.LeadActionType.LEAD_SUMMARIES_UTIL_STATE_CHANGE)();
