import { createSelector } from 'reselect';

import type { PortalSubAccount } from '@portal/api-models';
import { selectPermissions } from '@portal/store/dist/user/selectors';
import { getIDfromProps } from '@portal/store/dist/utils';

import type { ApplicationState } from '../../store';

const ClientTagPrefix = 'clientTags.';

export const partnerPageProprietaryDataSelector = createSelector(
  [(state: ApplicationState) => state.partnerPages],
  ({ integrationCreateVisible, partnerPageKeyValid }) => ({
    integrationCreateVisible,
    partnerPageKeyValid,
  })
);

export const updateSelector = createSelector(
  [
    ({ partnerPages }: ApplicationState, id: string) => {
      const partnerPageConfig = partnerPages.byPartnerKeyId[id];

      if (!partnerPageConfig) {
        return undefined;
      }

      return Object.keys(partnerPageConfig).reduce(
        (accum: any, key) => {
          if (key.includes(ClientTagPrefix)) {
            accum.clientTags[key.split('.')[1]] = partnerPageConfig[key];
          } else {
            accum[key] = partnerPageConfig[key];
          }

          return accum;
        },
        { clientTags: {} }
      );
    },
    ({ config }: ApplicationState) => config.PORTAL_API_URL,
    ({ config }: ApplicationState) => config.PARTNER_PAGE_BASE_URLS,
    ({ partnerPages, subAccounts }: ApplicationState, id: string) => {
      const config: any = partnerPages.byPartnerKeyId[id];

      if (!config) {
        return;
      }

      return subAccounts.supply.find(
        (sa) => sa.companyUuid === config['credentials.company_uuid']
      );
    },
    ({ subAccounts }: ApplicationState) => subAccounts.supply,
    ({ user }: ApplicationState) => user.roles,
    ({ config }: ApplicationState) => config.PARTNER_PAGE_BASE_URLS,
    (
      { trustedPartners, partnerPages, subAccounts }: ApplicationState,
      id: string
    ) => {
      const config: any = partnerPages.byPartnerKeyId[id];

      if (!config) {
        return [];
      }

      const companyUuid = config['credentials.company_uuid'];
      const availableTrustedPartners =
        trustedPartners.availableByCompanyUuid[companyUuid];
      const demandSubAccounts = subAccounts.demand;

      if (!availableTrustedPartners) {
        return [];
      }

      return availableTrustedPartners.map((atp) => {
        const dsa = demandSubAccounts.find(
          (dsa) => dsa.id === atp.demandSubAccountId
        );

        atp.demandSubAccountName = dsa?.name ?? '';

        return atp;
      });
    },
    (
      { trustedPartners, partnerPages, subAccounts }: ApplicationState,
      id: string
    ) => {
      const config: any = partnerPages.byPartnerKeyId[id];

      if (!config) {
        return [];
      }

      const companyUuid = config['credentials.company_uuid'];
      const featuredTrustedPartners =
        trustedPartners.featuredByCompanyUuid[companyUuid];

      if (!featuredTrustedPartners) {
        return [];
      }

      const demandSubAccounts = subAccounts.demand;

      return featuredTrustedPartners.map((ftp) => {
        const dsa = demandSubAccounts.find(
          (dsa) => dsa.id === ftp.demandSubAccountId
        );

        ftp.demandSubAccountName = dsa?.name ?? '';

        return ftp;
      });
    },
    selectPermissions,
  ],
  (
    partnerPageConfig,
    apiUrl,
    partnerPageBaseUrls,
    currentSubAccount,
    subAccounts,
    userRoles,
    baseUrls,
    availableTrustedPartners,
    featuredTrustedPartners,
    permissions
  ) => ({
    apiUrl,
    availableTrustedPartners,
    baseUrls,
    currentSubAccount: currentSubAccount as PortalSubAccount,
    featuredTrustedPartners,
    partnerPageBaseUrls,
    partnerPageConfig,
    permissions,
    subAccounts,
    userRoles,
  })
);

export const getPartnerPageSlice = ({ partnerPages }: ApplicationState) =>
  partnerPages;

export const getPartnerPagesByPartnerKey = ({
  partnerPages,
}: ApplicationState) => partnerPages.byPartnerKeyId;

export const getPartnerPagesByPartnerKeyById = createSelector(
  [getIDfromProps, getPartnerPageSlice],
  (id, pp) => pp.byPartnerKeyId?.[id]
);
