"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stopPollingNofications = exports.startPollingNofications = exports.updateNotificationReadAction = exports.notificationsRequestAction = exports.toggleDrawer = exports.setFilter = void 0;
var typesafe_actions_1 = require("typesafe-actions");
var actionTypes_1 = require("./actionTypes");
exports.setFilter = (0, typesafe_actions_1.createAction)(actionTypes_1.NotificationActionType.SET_FILTER)();
exports.toggleDrawer = (0, typesafe_actions_1.createAction)(actionTypes_1.NotificationActionType.TOGGLE_DRAWER)();
exports.notificationsRequestAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.NotificationActionType.GET_ALL_REQUEST, actionTypes_1.NotificationActionType.GET_ALL_SUCCESS, actionTypes_1.NotificationActionType.GET_ALL_FAILURE)();
exports.updateNotificationReadAction = (0, typesafe_actions_1.createAsyncAction)(actionTypes_1.NotificationActionType.UPDATE_READ_REQUEST, actionTypes_1.NotificationActionType.UPDATE_READ_SUCCESS, actionTypes_1.NotificationActionType.UPDATE_READ_FAILURE)();
exports.startPollingNofications = (0, typesafe_actions_1.createAction)(actionTypes_1.NotificationActionType.WATCH_START)();
exports.stopPollingNofications = (0, typesafe_actions_1.createAction)(actionTypes_1.NotificationActionType.WATCH_STOP)();
