export enum RepeatableError {
  BelowMin = 'The number of items is below the required minimum of {0}',
  ExceedMax = 'The number of items exceeds the maximum of {0}',
}

export interface RepeatableItemData<ValueType> {
  id: string;
  value: ValueType;
}

export interface RepeatableValidatorOptions<ValueType> {
  items: RepeatableItemData<ValueType>[];
  max?: number;
  min?: number;
}
