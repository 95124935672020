import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useOktaAuth } from '@okta/okta-react';

import type { Experience } from '@portal/api-models';
import * as feedbackActions from '@portal/store/dist/feedback';
import * as notificationsActions from '@portal/store/dist/notifications/actions';
import { NotificationFilter } from '@portal/store/dist/notifications/types';
import { selectHasMultipleUserExperiences } from '@portal/store/dist/user/selectors';
import { viewContextSelector } from '@portal/store/dist/view-context/selectors';
import { SiteHeader, TrialBar } from '@portal/ui-lib';

import { NotificationsDrawer } from '@/components/notifications-drawer';
import { NotificationsList } from '@/components/notifications-list';
import {
  selectFullscreenMode,
  selectSidenavCollapsed,
} from '@/resources/interface/selects';
import { notificationsSelector } from '@/resources/notifications/selectors';
import { hideTrialExpirationSelector } from '@/resources/saas/selectors';
import { isPermanentSelector } from '@/resources/supply-migration/selectors';
import {
  nameSelector,
  trialExpirationDaysSelector,
} from '@/resources/users/selectors';
import { setExperience } from '@/store/authentication';
import * as inferfaceActions from '@/store/interface/actions';

const Header: React.FC = () => {
  const dispatch = useDispatch();

  const {
    showGiveFeedbackHeaderItem,
    showHelpCenterHeaderItem,
    showNotificationsHeaderItem,
    shouldHideTrialExpirationBar,
    showTrialExpirationBar,
  } = useSelector(viewContextSelector);
  const fullscreenMode = useSelector(selectFullscreenMode);
  const hideTrialExpiration = useSelector(hideTrialExpirationSelector);
  const isPermanent = useSelector(isPermanentSelector);
  const name = useSelector(nameSelector);
  const trialExpirationDays = useSelector(trialExpirationDaysSelector);
  const sidenavCollapsed = useSelector(selectSidenavCollapsed);
  const {
    drawerVisible: notificationsDrawerVisible,
    filter,
    notifications,
    unreadNotificationsCount,
  } = useSelector(notificationsSelector);

  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (showNotificationsHeaderItem) {
      dispatch(notificationsActions.notificationsRequestAction.request({}));
      dispatch(notificationsActions.startPollingNofications());
    }
  }, []);

  const setRead = (uuid: string, read?: boolean) =>
    dispatch(
      notificationsActions.updateNotificationReadAction.request({ read, uuid })
    );

  const setFilter = (filter: NotificationFilter) =>
    dispatch(notificationsActions.setFilter(filter));

  const toggleFeedbackDrawer = () =>
    dispatch(feedbackActions.toggleFeedbackDrawer());

  const toggleNotificationsDrawer = (
    visible: boolean,
    filter?: NotificationFilter
  ) => dispatch(notificationsActions.toggleDrawer({ filter, visible }));

  const toggleSidenav = () => dispatch(inferfaceActions.toggleSidenav());

  const setExperienceWrapper = (experience: Experience): void => {
    dispatch(setExperience(experience));
  };
  const hasMultipleUserExperiences = useSelector(
    selectHasMultipleUserExperiences
  );
  const showExperienceSelect = hasMultipleUserExperiences ?? false;

  return (
    <>
      <SiteHeader
        {...{
          fullscreenMode,
          showGiveFeedbackHeaderItem,
          showHelpCenterHeaderItem,
          showNotificationsHeaderItem,
          showTrialBar: showTrialExpirationBar && !shouldHideTrialExpirationBar,
          sidenavCollapsed,
          unreadNotificationsCount,
        }}
        avatarColor={'#45ae35'}
        minimal={false}
        showExperienceSelect={showExperienceSelect}
        trialBar={
          !hideTrialExpiration ? (
            <TrialBar {...{ isPermanent, trialExpirationDays }} />
          ) : (
            <React.Fragment />
          )
        }
        userFullName={name}
        userHeaderAvatar={<React.Fragment />}
        onExperienceSelect={setExperienceWrapper}
        onFeedbackClick={toggleFeedbackDrawer}
        onHelpCenterClick={() => {
          window.location.assign('https://engine.tech/control-center/docs');
        }}
        onNotificationsClick={() =>
          toggleNotificationsDrawer(
            !notificationsDrawerVisible,
            NotificationFilter.UNREAD
          )
        }
        onSideNavClick={toggleSidenav}
        onSignOutClick={() => {
          oktaAuth.signOut();
        }}
      />
      {showNotificationsHeaderItem && (
        <NotificationsDrawer
          drawerVisible={notificationsDrawerVisible}
          filter={filter}
          setFilter={setFilter}
          toggleDrawer={toggleNotificationsDrawer}
        >
          <NotificationsList
            filter={filter}
            notifications={notifications}
            setRead={setRead}
          />
        </NotificationsDrawer>
      )}
    </>
  );
};

export default Header;
